import { SettingOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import { Widget } from 'client/api/backend/schemas';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import * as React from 'react';
import { useCallback } from 'react';
import { useDeskContext } from '../../desk/context/DeskContext';

export interface IWidgetConfigurationButtonProps extends ButtonProps {
  widget: Widget;
  type?: 'primary' | 'default';
  textButton?: string;
}

/**
 * Apre la finestra di configurazione di un singolo Widget tramite
 * il `useConfigureWidgetReducer`
 */
export function WidgetConfigurationButton(
  props: IWidgetConfigurationButtonProps
) {
  const { widget, type, textButton, ...otherProps } = props;
  const { configureWidget } = useDeskContext();

  const handleShow = useCallback(() => {
    configureWidget.dispatch({ type: 'open-configuration', widget });
  }, [configureWidget.dispatch, widget]);

  return textButton ? (
    <Button
      {...otherProps}
      type={props.type}
      icon={<SettingOutlined />}
      onClick={handleShow}
      children={props.textButton}
    />
  ) : (
    <ButtonIcon
      {...otherProps}
      type={props.type}
      icon={<SettingOutlined />}
      onClick={handleShow}
    />
  );
}

import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { yup } from 'common/validation/initYup';
import { deleteDocumentaryAssignmentsIdDelete } from 'client/api/backend/documentary-assignments/documentary-assignments';
import { InferType } from 'yup';
import { message } from 'antd';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { CloseOutlined } from '@ant-design/icons';
import { AssignmentToolkitObject } from 'client/components/widget-repository/objects/assignments/AssignmentToolkitObject';
import { DeskAssignment } from 'client/api/backend/schemas';
import { AssignmentType } from 'client/components/widget-repository/objects/assignments/mapIdType';
import { AssignmentState } from 'client/components/widget-repository/objects/assignments/mapIdState';
import { isAssignmentStale } from 'client/components/widget-repository/objects/assignments/isAssignmentStale';
import { isAssignmentActionAllowed } from 'client/components/widget-repository/objects/assignments/isAssignmentActionAllowed';
import { invalidateAssignmentQueries } from '../invalidateAssignmentQueries';

export const WidgetActionDeleteAssignment = createToolkitAction({
  name: 'Cancella',
  code: 'delete-assignment',
  icon: <CloseOutlined />,
  arguments: [
    {
      name: 'Assegnazione da Cancellare',
      type: [AssignmentToolkitObject],
      allows: (obj: ToolkitObject<DeskAssignment>) =>
        obj.data.idType?.toString() === AssignmentType.Competenza &&
        obj.data.idState?.toString() === AssignmentState.Restituita &&
        !isAssignmentStale(obj.data) &&
        isAssignmentActionAllowed(obj)
    }
  ],
  async execute(ctx: IToolkitActionContext, assignmentObject: ToolkitObject) {
    let assignment = AssignmentToolkitObject.get(assignmentObject)!;

    await onConfirm(ctx, {}, assignment);
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: InferType<typeof DeleteSchema>,
  assignment: ToolkitObject<DeskAssignment>
) {
  try {
    await deleteDocumentaryAssignmentsIdDelete(assignment.data.id!, {
      widgetId: assignment.sourceWidgetId
    });

    // Invalido la cache
    invalidateAssignmentQueries();

    message.success('Assegnazione cancellata');
  } catch (e: any) {
    message.error(
      getNetworkErrorMessage(e, 'Errore durante la cancellazione.')
    );
    console.error(e);
    return false;
  }
}

const DeleteSchema = yup.object({});

import { useGetDocumentaryDossiersStates } from 'client/api/backend/documentary-dossiers/documentary-dossiers';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import React from 'react';

interface IDossierStatesSelectInputProps extends SelectInputProps<any> {
  widgetId: number;
}

export function DossierStatesSelectInput(
  props: IDossierStatesSelectInputProps
) {
  const { widgetId, ...otherProps } = props;

  const statesResult = useGetDocumentaryDossiersStates({
    widgetId: widgetId
  });

  return (
    <SelectInput
      allowClear
      showSearch
      loading={statesResult.isLoading}
      options={statesResult.data?.map(state => {
        return { value: state.value ?? '', label: state.label ?? '' };
      })}
      {...otherProps}
    />
  );
}

import {
  DeskMail,
  DeskMailElement,
  MailProtocolRequestDTO,
  Widget
} from 'client/api/backend/schemas';
import { WidgetStepPanel } from 'client/components/toolkit/components/speps/WidgetStepPanel';
import { WidgetSteps } from 'client/components/toolkit/components/speps/WidgetSteps';
import { ToolkitObjectInput } from 'client/components/toolkit/objects/input/ToolkitObjectInput';
import { AssignmentModelToolkitObject } from 'client/components/widget-repository/objects/assignment-models/AssignmentModelToolkitObject';
import { AssignmentPriorityRadioInput } from 'client/components/widget-repository/objects/assignments/AssignmentPriorityRadioInput';
import { AssignmentTypeSelectInput } from 'client/components/widget-repository/objects/assignments/AssignmentTypeSelectInput';
import { ClassificationNodeToolkitObject } from 'client/components/widget-repository/objects/classifications/ClassificationNodeToolkitObject';
import { ClassificationParamsType } from 'client/components/widget-repository/objects/classifications/ClassificationParamsType';
import { CorrespondentInput } from 'client/components/widget-repository/objects/correspondents/input/CorrespondentInput';
import {
  CorrespondentKey,
  getCorrespondentDefaults
} from 'client/components/widget-repository/objects/correspondents/input/CorrespondentType';
import { CorrespondentTypeSelectInput } from 'client/components/widget-repository/objects/correspondents/input/CorrespondentTypeSelectInput';
import { DocumentConfidentialityInput } from 'client/components/widget-repository/objects/documents/DocumentConfidentialityInput';
import { OfficeToolkitObject } from 'client/components/widget-repository/objects/offices/OfficeToolkitObject';
import { UserToolkitObject } from 'client/components/widget-repository/objects/users/UserToolkitObject';
import { FormErrorsNotification } from 'client/ui/form/errors/FormErrorsNotification';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikForm } from 'client/ui/form/FormikForm';
import { useFormikFieldChange } from 'client/ui/form/hooks/useFormikFieldChange';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { FullBodyForm } from 'client/ui/full-body-form/FullBodyForm';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

export interface IWidgetMailProtocolAssignmentFormProps {
  widget: Widget;
  data?: DeskMail;
  mailElements: DeskMailElement[];
}

export function WidgetMailProtocolAssignmentForm(
  props: IWidgetMailProtocolAssignmentFormProps
) {
  const { widget, data, mailElements } = props;
  const formik = useFormikContext<MailProtocolRequestDTO>();

  useFormikFieldChange(
    (formik, [type]) => {
      const mailAddress = data?.emailSender;

      console.log(type, data);

      if (type === CorrespondentKey.Person) {
        formik.setFieldValue('sender', {
          ...getCorrespondentDefaults(type),
          person: { email: mailAddress }
        });
      }

      if (type === CorrespondentKey.Organization) {
        formik.setFieldValue('sender', {
          ...getCorrespondentDefaults(type),
          corporate: { address: { email: mailAddress } }
        });
      }

      if (
        type === CorrespondentKey.PublicAdministration ||
        type === CorrespondentKey.ForeignPublicAdministration
      ) {
        formik.setFieldValue('sender', {
          ...getCorrespondentDefaults(type),
          administration: { address: { email: mailAddress } }
        });
      }
    },
    ['sender.type'],
    { listenInitialValues: false, dependencies: [data] }
  );

  return (
    <FullBodyForm>
      <FormErrorsNotification />
      <FormikForm layout="vertical" editable helpInTooltips>
        <WidgetSteps
          cancelButtonProps={{ onClick: () => formik.resetForm() }}
          finishButtonProps={{
            children: 'Protocolla',
            htmlType: 'submit',
            disabled: !formik.dirty
          }}
        >
          <WidgetStepPanel title="Protocollo" stepKey="protocol">
            <FormFieldsContainer removeTopTitleSpacing columns={2} gutter={8}>
              <FormFieldItem
                colSpan={2}
                size="middle"
                component={TextInput}
                name="subject"
                label="Oggetto"
                placeholder="Oggetto"
              />
              <FormFieldItem
                colSpan={2}
                component={CorrespondentTypeSelectInput}
                name={'sender.type'}
                label="Tipo corrispondente"
              />
            </FormFieldsContainer>
            <CorrespondentInput
              item={formik.values.sender}
              widget={widget}
              fieldNamePrefix="sender"
            />
            <FormFieldsContainer removeTopTitleSpacing columns={1} gutter={8}>
              <FormFieldItem
                size="large"
                component={ToolkitObjectInput}
                objectType={ClassificationNodeToolkitObject}
                fetchObjectParams={{ type: ClassificationParamsType.Document }}
                widgetId={widget.id!}
                mode="multiple"
                name="classifications"
                label="Classificazione"
                placeholder="Classificazione"
              />
            </FormFieldsContainer>
            <FormFieldItem
              component={CheckboxInput}
              name="addMailNotes"
              label="Includere nota mail"
            />
            <FormFieldsContainer removeTopTitleSpacing columns={1} gutter={8}>
              <FormFieldItem
                component={SelectInput}
                name="elementPath"
                label="Documento Principale"
                options={mailElements?.map(e => {
                  return {
                    value: e.elementPath ?? '',
                    label: e.elementName ?? ''
                  };
                })}
              />
            </FormFieldsContainer>
          </WidgetStepPanel>

          <WidgetStepPanel title="Assegnazione" stepKey="assignment">
            <FormFieldItem
              colSpan={2}
              size="large"
              component={ToolkitObjectInput}
              objectType={[
                UserToolkitObject,
                OfficeToolkitObject,
                AssignmentModelToolkitObject
              ]}
              widgetId={widget.id!}
              mode="multiple"
              name="assignment.assignees"
              label="Assegnatario"
              placeholder="Assegnatario"
            />
            <FormFieldItem
              size="large"
              component={AssignmentTypeSelectInput}
              widgetId={widget.id!}
              label={null}
              name="assignment.typeId"
              placeholder="Modalità per l'assegnatario"
            />
            <FormFieldItem
              size="large"
              component={AssignmentPriorityRadioInput}
              label="Priorità"
              name="assignment.priorityId"
              widgetId={widget.id!}
            />
            <FormFieldItem
              size="large"
              component={DatePickerInput}
              type="unix"
              label={null}
              name="assignment.expiresAt"
              placeholder="Data Scadenza"
            />
            <FormFieldItem
              size="large"
              component={TextAreaInput}
              label={null}
              name="assignment.notes"
              placeholder="Note"
            />
          </WidgetStepPanel>
        </WidgetSteps>
      </FormikForm>
    </FullBodyForm>
  );
}

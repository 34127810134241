import {
  DeskDossier,
  DeskExtendedAttributeType,
  DossierSaveRequestDTO,
  Widget
} from 'client/api/backend/schemas';
import { FormikForm } from 'client/ui/form/FormikForm';
import React, { useEffect } from 'react';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextInput } from 'client/ui/form/input/TextInput';
import { WidgetSteps } from 'client/components/toolkit/components/speps/WidgetSteps';
import { WidgetStepPanel } from 'client/components/toolkit/components/speps/WidgetStepPanel';
import { useFormikContext } from 'formik';
import { FormErrorsNotification } from 'client/ui/form/errors/FormErrorsNotification';
import { ToolkitObjectInput } from 'client/components/toolkit/objects/input/ToolkitObjectInput';
import { ClassificationNodeToolkitObject } from 'client/components/widget-repository/objects/classifications/ClassificationNodeToolkitObject';
import { FullBodyForm } from 'client/ui/full-body-form/FullBodyForm';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { GrantInput } from 'client/components/widget-repository/objects/grants/GrantInput';
import { ReferenceInput } from 'client/components/widget-repository/objects/references/ReferenceInput';
import { DossierTypeInput } from 'client/components/widget-repository/objects/dossiers/DossierTypeInput';
import { DossierStateInput } from 'client/components/widget-repository/objects/dossiers/DossierStateInput';
import { CorrespondentsInput } from 'client/components/widget-repository/objects/correspondents/input/CorrespondentsInput';
import { ProceedingInput } from 'client/components/widget-repository/objects/proceedings/ProceedingInput';
import { DossierType } from 'client/components/widget-repository/objects/dossiers/DossierTypes';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { CorrespondentInput } from 'client/components/widget-repository/objects/correspondents/input/CorrespondentInput';
import { CorrespondentTypeSelectInput } from 'client/components/widget-repository/objects/correspondents/input/CorrespondentTypeSelectInput';
import {
  CorrespondentKey,
  getCorrespondentDefaults
} from 'client/components/widget-repository/objects/correspondents/input/CorrespondentType';
import { DossierSubtypeInput } from 'client/components/widget-repository/objects/dossiers/DossierSubtypeInput';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { OfficeToolkitObject } from 'client/components/widget-repository/objects/offices/OfficeToolkitObject';
import { DossierSubtypeMetadataFormInput } from 'client/components/widget-repository/objects/dossiers/DossierSubtypeMetadataFormInput';
import { useFormikFieldChange } from 'client/ui/form/hooks/useFormikFieldChange';
import { ClassificationParamsType } from 'client/components/widget-repository/objects/classifications/ClassificationParamsType';

export interface IWidgetDossierCreateFormProps {
  widget: Widget;
  isEdit: boolean;
  dossierAttributeTypes: DeskExtendedAttributeType[];
  setDossierAttributeTypes: (values: DeskExtendedAttributeType[]) => void;
}

export function WidgetDossierCreateForm(props: IWidgetDossierCreateFormProps) {
  const { widget, isEdit, dossierAttributeTypes, setDossierAttributeTypes } =
    props;
  const formik = useFormikContext<DeskDossier>();

  useFormikFieldChange(
    (formik, [type]) => {
      if (type) {
        formik.setFieldValue('petitioner', {
          ...getCorrespondentDefaults(type)
        });
      }
    },
    ['petitioner.type'],
    { listenInitialValues: false }
  );

  return (
    <FullBodyForm>
      <FormErrorsNotification />
      <FormikForm layout="vertical" editable helpInTooltips>
        <WidgetSteps
          cancelButtonProps={{ onClick: () => formik.resetForm() }}
          finishButtonProps={{
            children: isEdit ? 'Modifica' : 'Crea',
            htmlType: 'submit',
            disabled: !formik.dirty
          }}
        >
          <WidgetStepPanel title="Profilo" stepKey="profile">
            <FormFieldsContainer>
              <FormFieldItem
                size="middle"
                component={TextInput}
                name="name"
                label="Oggetto"
                placeholder="Oggetto"
              />
              <FormFieldItem
                size="middle"
                component={TextInput}
                name="description"
                label="Descrizione"
                placeholder="Descrizione"
              />
              <FormFieldItem
                size="middle"
                component={DossierTypeInput}
                widgetId={widget.id!}
                name="idType"
                label="Tipo"
                placeholder="Tipo"
              />
              <FormFieldItem
                size="middle"
                component={DossierSubtypeInput}
                widgetId={widget.id!}
                name="idSubtype"
                label="Sottotipo"
                placeholder="Sottotipo"
              />
            </FormFieldsContainer>
            <FormFieldsContainer removeTopTitleSpacing columns={1} gutter={8}>
              <FormFieldItem
                size="large"
                component={ToolkitObjectInput}
                objectType={ClassificationNodeToolkitObject}
                fetchObjectParams={{ type: ClassificationParamsType.Dossier }}
                widgetId={widget.id!}
                name="classification"
                label="Classificazione"
                placeholder="Classificazione"
              />
            </FormFieldsContainer>
            <FormFieldsContainer>
              <FormFieldItem
                size="middle"
                component={GrantInput}
                widget={widget}
                name="grant.id"
                label="Lista di Competenza"
                placeholder="Lista di Competenza"
              />
            </FormFieldsContainer>
            <FormFieldsContainer>
              <FormFieldItem
                size="middle"
                component={TextInput}
                name="manager"
                label="Responsabile"
                placeholder="Responsabile"
              />
            </FormFieldsContainer>
            <FormFieldItem
              size="large"
              component={ToolkitObjectInput}
              objectType={OfficeToolkitObject}
              widgetId={widget.id!}
              name="ownerOffice"
              label="Ufficio Proprietario del Fascicolo"
              placeholder="Ufficio Proprietario del Fascicolo"
            />
            <FormFieldsContainer>
              <FormFieldItem
                size="middle"
                component={TextAreaInput}
                name="notes"
                label="Note"
                placeholder="Note"
              />
            </FormFieldsContainer>
            <FormFieldsContainer columns={2}>
              <FormFieldItem
                size="middle"
                component={DossierStateInput}
                widgetId={widget.id!}
                name="stateId"
                label="Stato"
                placeholder="Stato"
              />
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                name="dateOpen"
                type="from"
                label="Data Apertura"
                placeholder="Data Apertura"
              />
            </FormFieldsContainer>
            <FormFieldsContainer columns={2}>
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                name="dateExpiry"
                type="date-only"
                label="Data Scadenza"
                placeholder="Data Scadenza"
              />
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                name="dateClose"
                type="date-only"
                label="Data Chiusura"
                placeholder="Data Chiusura"
              />
            </FormFieldsContainer>
            <FormFieldsContainer>
              <FormFieldItem
                size="middle"
                component={TextInput}
                name="physicalPosition"
                label="Posizione Fisica"
                placeholder="Posizione Fisica"
              />
            </FormFieldsContainer>
            <FormFieldsContainer columns={2}>
              <FormFieldItem
                size="middle"
                component={NumberInput}
                name="stagingTime"
                label="Tempo di Staging"
                placeholder="Tempo di Staging"
              />
              <FormFieldItem
                size="middle"
                component={NumberInput}
                name="preservingTime"
                label="Tempo di Conservazione"
                placeholder="Tempo di Conservazione"
              />
            </FormFieldsContainer>
          </WidgetStepPanel>
          {!!formik.values.idSubtype && (
            <WidgetStepPanel title="Sottotipi" stepKey="subtypes">
              <DossierSubtypeMetadataFormInput
                widgetId={widget.id!}
                dossierAttributeTypes={dossierAttributeTypes}
                setDossierAttributeTypes={setDossierAttributeTypes}
              />
            </WidgetStepPanel>
          )}
          {formik.values.idType?.toString() ===
            DossierType.ProcedimentoAmministrativo && (
            <WidgetStepPanel
              title="Procedimento Amministrativo"
              stepKey="proceeding"
            >
              <ProceedingInput widgetId={widget.id!} />
              {/* name="proceeding" */}
            </WidgetStepPanel>
          )}
          <WidgetStepPanel title="Soggetti" stepKey="subjects">
            <FormFieldsContainer>
              <FormFieldItem
                component={CorrespondentTypeSelectInput}
                name={'petitioner.type'}
                label="Richiedente"
                placeholder="Richiedente"
              />
            </FormFieldsContainer>
            <CorrespondentInput
              item={formik.values.petitioner}
              widget={widget}
              fieldNamePrefix="petitioner"
            />
            <CorrespondentsInput
              displayName="Amministrazione Titolare/Partecipante"
              name="subjects"
              allowedTypes={[
                CorrespondentKey.PublicAdministration,
                CorrespondentKey.ForeignPublicAdministration
              ]}
            />
          </WidgetStepPanel>
          <WidgetStepPanel title="Riferimenti" stepKey="reference">
            <FormFieldsContainer removeTopTitleSpacing columns={1} gutter={8}>
              <FormFieldItem
                size="middle"
                component={ReferenceInput}
                widget={widget}
                name="references.id"
                label="Tipo Riferimento"
                placeholder="Tipo Riferimento"
              />
              <FormFieldItem
                size="middle"
                component={TextInput}
                name="references.description"
                label={null}
                placeholder="Riferimento"
              />
            </FormFieldsContainer>
          </WidgetStepPanel>
        </WidgetSteps>
      </FormikForm>
    </FullBodyForm>
  );
}

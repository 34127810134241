import DOMPurify from 'dompurify';

/**
 * Ritorna una versione sanificata dell'HTML
 */
export function sanitizeHtml(html?: string) {
  if (!html) {
    return '';
  }

  return DOMPurify.sanitize(html);
}

import { logger } from 'client/core/logger/logger';
import moment from 'moment';
import * as React from 'react';

export const DynamicDateRanges = [
  { label: 'Oggi', value: 'today' },
  { label: 'Ieri', value: 'yesterday' },
  { label: '7 giorni fa', value: 'last7days' },
  { label: '30 giorni fa', value: 'last30days' },
  { label: '3 mesi fa', value: 'last3months' },
  { label: 'Un anno fa', value: 'lastYear' }
];

export function applyDynamicDateRange(range: string) {
  switch (range) {
    case 'today':
      return moment().startOf('day');
    case 'yesterday':
      return moment().subtract(1, 'days').startOf('day');
    case 'last7days':
      return moment().subtract(7, 'days').startOf('day');
    case 'last30days':
      return moment().subtract(30, 'days').startOf('day');
    case 'last3months':
      return moment().subtract(3, 'months').startOf('day');
    case 'lastYear':
      return moment().subtract(1, 'years').startOf('day');
    default:
      logger.warn('Unknown dynamic date range', range);
      return null;
  }
}

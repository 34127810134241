import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetActionMonitorMultipleDams } from './actions/WidgetActionMonitorMultipleDams';
import { WidgetDamMonitoring } from './WidgetMultipleDamsMonitoring';
import { FvIconDamSync } from 'client/ui/icon/FvIconsDam';
import { WidgetActionRemoveDamFromMonitoring } from './actions/WidgetActionRemoveDamFromMonitoring';

export const WidgetMultipleDamsMonitoring: IWidgetType = {
  code: 'dam-mon-multiple',
  name: 'Monitoraggio Multiplo Dighe',
  icon: <FvIconDamSync />,
  component: WidgetDamMonitoring,
  description: `Permette il monitoraggio di più dighe contemporaneamente`,
  actions: [WidgetActionMonitorMultipleDams],
  internalActions: [WidgetActionRemoveDamFromMonitoring]
};

WidgetRepository.register(WidgetMultipleDamsMonitoring);

import React from 'react';
import styled, { css } from 'styled-components';

const MenuLineWrapper = styled.div`
  flex: 0 0 auto;
  border-bottom: solid 1px ${props => props.theme.borderColorBase};
`;
const MenuLineRow = styled.div`
  display: flex;
  align-items: stretch;
  border-top: solid 1px ${props => props.theme.borderColorBase};
`;

interface MenuRowProps {
  children: React.ReactNode;
}

export function MenuRow(props: MenuRowProps) {
  return (
    <MenuLineWrapper>
      <MenuLineRow>{props.children}</MenuLineRow>
    </MenuLineWrapper>
  );
}

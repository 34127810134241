import * as React from 'react';
import clx from 'classnames';
import { useState } from 'react';
import styled from 'styled-components';
import { CloseOutlined, FilterOutlined } from '@ant-design/icons/lib/icons';
import { WidgetFilterButtonBar } from './WidgetFilterButtonBar';
import { WidgetDrawer } from '../../../schema/widget/card/body/WidgetDrawer';
import { DrawerTitle } from 'client/ui/drawer/DrawerTitle';
import { StyledMenuBar } from 'client/ui/menu-bar/StyledMenuBar';

export interface WidgetFilterDrawerProps {
  children?: React.ReactNode;
  filters?: React.ReactNode[];
  title?: string;
  open: [open: boolean, setOpen: (open: boolean) => void];
  rightButton?: React.ReactNode;
}

/**
 * //TODO
 */
export function WidgetFilterDrawer(props: WidgetFilterDrawerProps) {
  const {
    open: [open, setOpen]
  } = props;
  return (
    <StyledMenuBar>
      <WidgetDrawer
        title={
          <DrawerTitle
            onClose={() => setOpen(false)}
            children={props.title ?? 'Ricerca'}
          />
        }
        closable={false}
        height="100%"
        placement="top"
        onClose={() => setOpen(false)}
        visible={open}
      >
        {props.children}
      </WidgetDrawer>
      <WidgetFilterButtonBar
        icon={<FilterOutlined />}
        onClick={() => setOpen(true)}
        filters={props.filters}
        rightButton={props.rightButton}
      />
    </StyledMenuBar>
  );
}

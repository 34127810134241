import { AuditOutlined, ContainerOutlined } from '@ant-design/icons';
import { DeskDocument } from 'client/api/backend/schemas';
import { IDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { DeskWidgetsLogic } from 'client/components/schema/desk/logic/DeskWidgetsLogic';
import {
  createToolkitAction,
  ToolkitAction
} from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import {
  getToolkitObjectOfType,
  ToolkitObject
} from 'client/components/toolkit/objects/ToolkitObject';
import { DocumentToolkitObject } from 'client/components/widget-repository/objects/documents/DocumentToolkitObject';
import React from 'react';
import { WidgetDocumentProtocolType } from '../WidgetDocumentProtocol.type';
import { WidgetDocumentProtocolProfile } from '../WidgetDocumentProtocolProfile';

export const WidgetActionProtocolDocument = createToolkitAction({
  name: 'Protocollazione',
  code: 'protocol-document',
  icon: <AuditOutlined />,
  showInBar: true, // Necessario per "allowClickToStart"
  allowClickToStart: false, // Il primo argument sarà `null`
  arguments: [
    {
      name: 'Documento da Protocollare',
      type: [DocumentToolkitObject],
      allows: (document: ToolkitObject<DeskDocument>) =>
        document.data.protocolNumber == null
    }
  ],
  async execute(ctx: IToolkitActionContext, object: ToolkitObject) {
    const document = DocumentToolkitObject.get(object);

    if (!document) {
      await ctx.deskContext.createWidget(
        {
          type: 'floating',
          expanded: true,
          order: DeskWidgetsLogic.getFloatingNextOrder(
            ctx.deskContext.floatingWidgets
          )
        },
        WidgetDocumentProtocolType,
        { quickClose: true }
      );
      return;
    }

    // Se esiste il documento, lo apriamo
    const data: WidgetDocumentProtocolProfile = {
      documentId: document.id as number,
      documentSubject: document.data.subject
    };

    await ctx.deskContext.cloneWidget(
      {
        type: 'floating',
        expanded: true,
        order: DeskWidgetsLogic.getFloatingNextOrder(
          ctx.deskContext.floatingWidgets
        )
      },
      WidgetDocumentProtocolType,
      ctx.sourceWidget!,
      { quickClose: true, data }
    );
  }
});

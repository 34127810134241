import { useGetDocumentaryDossiersTypes } from 'client/api/backend/documentary-dossiers/documentary-dossiers';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import * as React from 'react';

interface IDossierTypeInputProps extends SelectInputProps<any> {
  widgetId: number;
}

export function DossierTypeInput(props: IDossierTypeInputProps) {
  const { widgetId, ...otherProps } = props;
  const typesResult = useGetDocumentaryDossiersTypes({
    widgetId: props.widgetId
  });

  return (
    <SelectInput
      allowClear
      showSearch
      loading={typesResult.isLoading}
      options={[
        ...(typesResult.data?.map(mode => ({
          label: mode.label!,
          value: Number(mode.value!)
        })) ?? [])
      ]}
      {...otherProps}
    />
  );
}

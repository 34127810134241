import { Widget } from 'client/api/backend/schemas';
import * as React from 'react';
import { WidgetComponent } from '../WidgetComponent';

export interface IWidgetDraggingContainerProps
  extends React.DOMAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  widget: Widget;
}

/**
 * Contenitore adattato per `react-grid-layout` che ne gestisce le componenti
 * visive per il Drag&Drop.
 */
export const WidgetDraggingContainer = React.forwardRef<
  HTMLDivElement,
  IWidgetDraggingContainerProps
>((props: IWidgetDraggingContainerProps, ref) => {
  const { widget, ...divProps } = props;
  return (
    <div {...divProps} ref={ref}>
      <WidgetComponent widget={widget} />
      {divProps.children}
    </div>
  );
});

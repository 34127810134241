import * as React from 'react';
import clx from 'classnames';
import styled from 'styled-components';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';

// const FloatWidgetContainerWrapper = styled.div.attrs(
//   (props: FloatWidgetContainerProps) => ({
//     widgetExpanded: props.widgetExpanded
//   })
// )<FloatWidgetContainerProps>`
// height: ${props => (props.widgetExpanded ? `500px` : `56px`)};
// width: ${props => (props.widgetExpanded ? `380px` : `100px`)};
// `

const FloatWidgetSizeContainer = styled.div`
  /* position: fixed;
  bottom: 0;
  right: 0; */
  pointer-events: auto;
  z-index: 9;

  max-width: 100%;
  max-height: 90%;
  max-height: calc(100vh - 100px);
  overflow: hidden;

  transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1),
    width 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0.1s,
    height 0.3s cubic-bezier(0.23, 1, 0.32, 1);

  width: 230px;
  height: 56px;

  height: 66px;
  margin-bottom: -10px;

  &.expanded {
    z-index: 20;

    height: 500px;
    width: 380px;

    transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1),
      width 0.2s cubic-bezier(0.23, 1, 0.32, 1),
      height 0.3s cubic-bezier(0.23, 1, 0.32, 1) 0.1s;

    &.mobile {
      width: 100vw;
      max-width: 380px;
    }
  }
  &.fullscreen,
  &.fullscreen.expanded {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 52px;
    max-height: 100%;
    height: calc(100vh - 48px);
    padding-bottom: 12px;
    &.mobile {
      width: 100vw;
      max-width: 100%;
    }
  }
`;

export interface FloatWidgetSizeProps {
  children?: React.ReactNode;
  widgetExpanded?: boolean;
  fullScreen?: boolean;
}

export function FloatWidgetSize(props: FloatWidgetSizeProps) {
  const isMobile = useBreakpoint(down('sm'));

  return (
    <FloatWidgetSizeContainer
      className={clx({
        expanded: props.widgetExpanded,
        fullscreen: props.fullScreen,
        mobile: isMobile
      })}
    >
      {props.children}
    </FloatWidgetSizeContainer>
  );
}

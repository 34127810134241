import { FileAddOutlined } from '@ant-design/icons';
import { IDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { DeskWidgetsLogic } from 'client/components/schema/desk/logic/DeskWidgetsLogic';
import {
  createToolkitAction,
  ToolkitAction
} from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import {
  getToolkitObjectOfType,
  ToolkitObject
} from 'client/components/toolkit/objects/ToolkitObject';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { DocumentToolkitObject } from 'client/components/widget-repository/objects/documents/DocumentToolkitObject';
import { set } from 'client/core/data/set';
import { FvIconFileView } from 'client/ui/icon/FvIcons';
import React from 'react';
import { WidgetDocumentViewerType } from '../WidgetDocumentViewer.type';
import { WidgetDocumentViewerProfile } from '../WidgetDocumentViewerProfile';

export const WidgetActionOpenDocument = createToolkitAction({
  name: 'Apri Documento',
  code: 'open-document',
  icon: <FvIconFileView />,
  showInBar: true,
  arguments: [
    {
      type: [DocumentToolkitObject],
      name: 'Documento da Aprire'
    },
    {
      type: [WidgetToolkitObject],
      allows: (object: ToolkitObject) =>
        WidgetToolkitObject.matches(object, WidgetDocumentViewerType),
      name: 'Widget Visualizza Documento'
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    object: ToolkitObject,
    targetObject: ToolkitObject | null
  ) {
    const document = DocumentToolkitObject.get(object)!;
    const target = WidgetToolkitObject.get(targetObject);

    // const document = documentObject.data as DeskDocument;
    const data: WidgetDocumentViewerProfile = {
      documentId: document.id as number,
      documentSubject: document.data.subject ?? document.data.filename
    };

    if (target) {
      const widget = ctx.deskContext.widgets.find(w => w.id === target.id)!;
      await ctx.deskContext.updateWidget(set(widget, 'profile.data', data));
      return;
    }

    await ctx.deskContext.cloneWidget(
      {
        type: 'floating',
        expanded: true,
        order: DeskWidgetsLogic.getFloatingNextOrder(
          ctx.deskContext.floatingWidgets
        )
      },
      WidgetDocumentViewerType,
      ctx.sourceWidget!,
      {
        data,
        quickClose: true,
        initializeInFullScreen:
          ctx.sourceWidget?.profile?.showChildWidgetInFullScreen ?? false
      }
    );
  }
});

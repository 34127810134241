import React from 'react';
import { LoaderSpinProps, LoaderSpin } from './LoaderSpin';
import { CorePageLayout } from '../layout/CorePageLayout';

export interface LoadingPageProps extends LoaderSpinProps {}

/**
 * Pagina di caricamento.
 */
export function LoadingPage(props: LoadingPageProps) {
  const { ...otherProps } = props;

  return (
    <CorePageLayout>
      <LoaderSpin {...otherProps} transparent full />
    </CorePageLayout>
  );
}

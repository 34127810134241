import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import clx from 'classnames';
import { Button, Space, Typography } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
const { Text, Title } = Typography;

const ActionArrowLineWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const IconContainer = styled.div`
  flex: 0 0 auto;
  min-width: 0;
  padding-bottom: 10px;
  margin-right: -4px;
  z-index: 2;
`;
const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  border: solid 1px ${props => props.theme.inputBorderColor};
  border-radius: 4px 0 0 4px;
  background-color: ${props => props.theme.borderColorSplit};
  font-size: 20px;
  color: ${props => props.theme.inputPlaceholderColor};
`;

const ContentContainer = styled.div`
  flex: 1 1 auto;
  min-width: 0;
`;

export interface ActionArrowLineProps {
  children?: React.ReactElement;
  /** Permette di gestire la linea dinamicamente */
  hide?: boolean;
  style?: React.CSSProperties;
}

/**
 * Linea per gli elementi secondary con una freccia
 */
export function ActionArrowLine(props: ActionArrowLineProps) {
  if (props.hide) return null;

  return (
    <div {...props}>
      <IconBox>
        <ArrowRightOutlined />
      </IconBox>
    </div>
  );
}

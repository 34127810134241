import { DeskDam } from 'client/api/backend/schemas';
import { mapDamAddresses } from '../../dam-viewer/convert/convertDamToWidgetListElement';

export function convertDamGeoregistryToTable(dam?: DeskDam) {
  if (!dam) {
    return null;
  }

  return {
    registry: [
      {
        label: 'Archivio',
        value: dam.archive
      },
      { label: 'Sub', value: dam.subArchive },
      { label: 'RID', value: dam.rid },
      {
        label: 'Nome',
        value: dam.name
      },
      {
        label: 'Altra denominazione',
        value: dam.alternateNames?.join(' | ')
      },
      {
        label: 'Status',
        value: mapStatuses(dam.statuses)
      },
      {
        label: 'Ufficio periferico',
        value: dam.office
      },
      {
        label: 'Utilizzazione',
        value: dam.usages?.map(s => s.value)?.join(' | ')
      },
      { label: 'Vol. tot. invaso L.584/94', value: dam.totalReservoir },
      { label: 'H L.584/94', value: dam.height },
      {
        label: 'Concessionari',
        value: dam.dealers?.join(' | ')
      },
      {
        label: 'Gestori',
        value: dam.operators?.join(' | ')
      },
      {
        label: 'Ingegneri responsabili',
        value: dam.engineers?.map(s => s.name)?.join(' | ')
      },
      {
        label: 'Sostituti ingegneri',
        value: dam.substituteEngineers?.map(s => s.name)?.join(' | ')
      }
    ],
    location: [
      { label: 'Indirizzi', value: mapDamAddresses(dam.addresses) },
      { label: 'Latitudine', value: dam.latitude },
      { label: 'Longitudine', value: dam.longitude }
    ],
    hydrography: [
      { label: 'Dighe invaso', value: dam.reservoirDams?.join(' | ') },
      { label: 'Lago', value: dam.lake },
      { label: 'Bacino L. 183/89', value: dam.lagoon },
      { label: 'Fiume sbarrato', value: dam.river },
      { label: 'Fiumi a valle', value: dam.tributaries?.join(' | ') }
    ]
  };
}

function mapStatuses(statuses: DeskDam['statuses']) {
  return statuses
    ?.filter(s => s.key?.trim() !== '')
    ?.map(s => `${s.key} - ${s.value}`)
    .join(' | ');
}

import { Tag } from 'antd';
import React from 'react';

interface RoleTagProps {
  roleName?: React.ReactNode;
}

export function RoleTag(props: RoleTagProps) {
  return <Tag color="blue">{props.roleName ?? 'N.A.'}</Tag>;
}

import { message } from 'antd';
import {
  ConfigurationField,
  ConfigurationFieldResponse,
  ConfigurationRequest,
  ConfigurationResponse,
  ConfigurationTemplate,
  Widget
} from 'client/api/backend/schemas';
import { usePostWidgetsIdConfigurationFieldsField } from 'client/api/backend/widgets/widgets';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { useFormikFieldChange } from 'client/ui/form/hooks/useFormikFieldChange';
import { SelectInput, SelectOption } from 'client/ui/form/input/SelectInput';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { dependencies } from 'webpack';
import {
  ConfigurationTemplates,
  getConfigurationTemplateLabel
} from '../configuration-templates/ConfigurationTemplates';

export interface IFormWidgetConfigurationFieldItemProps {
  widget: Widget;
  template: ConfigurationTemplate;
  dependencies: string[] | undefined;
  field: ConfigurationField;
  index: number;
  reloadCount: number;
  incrementReloadCount: () => void;
}

export function FormWidgetConfigurationFieldItem(
  props: IFormWidgetConfigurationFieldItemProps
) {
  const {
    dependencies,
    template,
    widget,
    field: templateField,
    index,
    reloadCount
  } = props;

  const fieldOptionsQuery = usePostWidgetsIdConfigurationFieldsField();
  const [fieldOptions, setFieldOptions] = useState<SelectOption[]>([]);
  const formik = useFormikContext<ConfigurationRequest>();

  const fieldName = `fields.${index}.value`;

  /**
   * Query per ottenere le opzioni da valorizzare per il campo di selezione.
   */
  const loadFieldOptions = useCallback(async (values: ConfigurationRequest) => {
    try {
      // console.log('loadFieldOptions.values', values, `field=${field.type}`);
      const result = (await fieldOptionsQuery.mutateAsync({
        id: widget.id!,
        field: templateField.type!,
        data: {
          // Passo solamente i campi precedenti
          fields: values.fields?.slice(0, index) ?? []
        }
      })) as ConfigurationFieldResponse;

      setFieldOptions(
        result.fields?.map(option => ({
          label: option.label!,
          value: option.value!
        })) ?? []
      );

      const preselectedField = result.fields?.find(f => f.preselect);

      const fieldValue = values.fields?.[index]?.value;
      const desiredValue =
        fieldValue != null && result.fields?.some(f => f.value === fieldValue)
          ? fieldValue
          : null;

      if (
        result.fields?.length === 1 &&
        (!desiredValue || desiredValue !== result.fields[0].value)
      ) {
        // console.log('setting field', fieldName, result.fields[0].value);
        formik.setFieldValue(`fields.${index}`, {
          type: templateField.type,
          value: result.fields[0].value
        });
      } else if (preselectedField && !desiredValue) {
        // Preseleziona il campo solo se non è già stato valorizzato
        formik.setFieldValue(`fields.${index}`, {
          type: templateField.type,
          value: preselectedField.value
        });
      } else if (desiredValue == null && fieldValue != null) {
        // Se il valore desiderato non è presente e il campo è valorizzato, lo rimuovo
        formik.setFieldValue(`fields.${index}`, {
          type: templateField.type,
          value: null
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    const templateFieldType = template.fields?.[index].type;
    const updatedField = formik.values.fields?.[index];

    // Se il template e i field non sono allineati, non fare nulla
    if (
      templateFieldType != null &&
      updatedField?.type != null &&
      templateFieldType !== updatedField?.type
    ) {
      return;
    }

    loadFieldOptions(formik.values);
  }, [reloadCount, formik.values.fields]);

  const label = getConfigurationTemplateLabel(template, templateField.type!);

  return (
    <FormFieldItem
      name={fieldName}
      component={SelectInput}
      label={label}
      size="large"
      placeholder={label}
      skipFormChange
      onChange={(value: any) => {
        formik.setFieldValue(`fields.${index}`, {
          type: templateField.type,
          value
        });
        // Quando modifichiamo il campo, inviavamo direttamente il form per
        // permettere alle query successive di funzionare correttamente...
        // await formik.submitForm();

        // ..Ora non è più necessario perché le API di reload delle opzioni
        // sono scollegate, quindi basta rifetcharle.
        props.incrementReloadCount();
      }}
      loading={fieldOptionsQuery.isLoading}
      options={fieldOptions}
    />
  );
}

/**
 * Generato automaticamente.
 * DeskUI REST API
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  RoleResponseDTO,
  RoleCreateDTO,
  WidgetType,
  AssociateRoleWidgetsDTO,
  PanelCopyResultDTO
} from '.././schemas';
import {
  apiInstance,
  ErrorType
} from '../../../core/network/mutators/apiClient';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Ritorna la lista dei ruoli presenti
 */
export const getRoles = () => {
  return apiInstance<RoleResponseDTO[]>({
    url: `/api/v1/roles`,
    method: 'get'
  });
};

export const getGetRolesQueryKey = () => [`/api/v1/roles`];

export type GetRolesQueryResult = NonNullable<AsyncReturnType<typeof getRoles>>;
export type GetRolesQueryError = ErrorType<void>;

export const useGetRoles = <
  TData = AsyncReturnType<typeof getRoles>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<AsyncReturnType<typeof getRoles>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetRolesQueryKey();

  const queryFn: QueryFunction<AsyncReturnType<typeof getRoles>> = () =>
    getRoles();

  const query = useQuery<AsyncReturnType<typeof getRoles>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Inserisce un ruolo
 */
export const postRoles = (roleCreateDTO: RoleCreateDTO) => {
  return apiInstance<RoleResponseDTO | void>({
    url: `/api/v1/roles`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: roleCreateDTO
  });
};

export type PostRolesMutationResult = NonNullable<
  AsyncReturnType<typeof postRoles>
>;
export type PostRolesMutationBody = RoleCreateDTO;
export type PostRolesMutationError = ErrorType<unknown>;

export const usePostRoles = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postRoles>,
    TError,
    { data: RoleCreateDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postRoles>,
    { data: RoleCreateDTO }
  > = props => {
    const { data } = props || {};

    return postRoles(data);
  };

  return useMutation<
    AsyncReturnType<typeof postRoles>,
    TError,
    { data: RoleCreateDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Aggiorna un ruolo
 */
export const putRoles = (roleResponseDTO: RoleResponseDTO) => {
  return apiInstance<RoleResponseDTO | void>({
    url: `/api/v1/roles`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: roleResponseDTO
  });
};

export type PutRolesMutationResult = NonNullable<
  AsyncReturnType<typeof putRoles>
>;
export type PutRolesMutationBody = RoleResponseDTO;
export type PutRolesMutationError = ErrorType<unknown>;

export const usePutRoles = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof putRoles>,
    TError,
    { data: RoleResponseDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof putRoles>,
    { data: RoleResponseDTO }
  > = props => {
    const { data } = props || {};

    return putRoles(data);
  };

  return useMutation<
    AsyncReturnType<typeof putRoles>,
    TError,
    { data: RoleResponseDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Ritorna i dati del ruolo identificato
 */
export const getRolesId = (id: number) => {
  return apiInstance<RoleResponseDTO>({
    url: `/api/v1/roles/${id}`,
    method: 'get'
  });
};

export const getGetRolesIdQueryKey = (id: number) => [`/api/v1/roles/${id}`];

export type GetRolesIdQueryResult = NonNullable<
  AsyncReturnType<typeof getRolesId>
>;
export type GetRolesIdQueryError = ErrorType<void>;

export const useGetRolesId = <
  TData = AsyncReturnType<typeof getRolesId>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getRolesId>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetRolesIdQueryKey(id);

  const queryFn: QueryFunction<AsyncReturnType<typeof getRolesId>> = () =>
    getRolesId(id);

  const query = useQuery<AsyncReturnType<typeof getRolesId>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  );

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Cancella il ruolo identificato
 */
export const deleteRolesId = (id: number) => {
  return apiInstance<void>({ url: `/api/v1/roles/${id}`, method: 'delete' });
};

export type DeleteRolesIdMutationResult = NonNullable<
  AsyncReturnType<typeof deleteRolesId>
>;

export type DeleteRolesIdMutationError = ErrorType<unknown>;

export const useDeleteRolesId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteRolesId>,
    TError,
    { id: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deleteRolesId>,
    { id: number }
  > = props => {
    const { id } = props || {};

    return deleteRolesId(id);
  };

  return useMutation<
    AsyncReturnType<typeof deleteRolesId>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Associa widgets al ruolo
 */
export const postRolesIdWidgets = (
  id: number,
  associateRoleWidgetsDTO: AssociateRoleWidgetsDTO
) => {
  return apiInstance<WidgetType[] | void>({
    url: `/api/v1/roles/${id}/widgets`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: associateRoleWidgetsDTO
  });
};

export type PostRolesIdWidgetsMutationResult = NonNullable<
  AsyncReturnType<typeof postRolesIdWidgets>
>;
export type PostRolesIdWidgetsMutationBody = AssociateRoleWidgetsDTO;
export type PostRolesIdWidgetsMutationError = ErrorType<unknown>;

export const usePostRolesIdWidgets = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postRolesIdWidgets>,
    TError,
    { id: number; data: AssociateRoleWidgetsDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postRolesIdWidgets>,
    { id: number; data: AssociateRoleWidgetsDTO }
  > = props => {
    const { id, data } = props || {};

    return postRolesIdWidgets(id, data);
  };

  return useMutation<
    AsyncReturnType<typeof postRolesIdWidgets>,
    TError,
    { id: number; data: AssociateRoleWidgetsDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Copia le scrivanie dell'utente {userId} a tutti gli utenti che hanno il ruolo {roleId}
 */
export const postRolesRoleIdPanelsCopyUserId = (
  roleId: number,
  userId: number
) => {
  return apiInstance<PanelCopyResultDTO | void>({
    url: `/api/v1/roles/${roleId}/panels/copy/${userId}`,
    method: 'post'
  });
};

export type PostRolesRoleIdPanelsCopyUserIdMutationResult = NonNullable<
  AsyncReturnType<typeof postRolesRoleIdPanelsCopyUserId>
>;

export type PostRolesRoleIdPanelsCopyUserIdMutationError = ErrorType<unknown>;

export const usePostRolesRoleIdPanelsCopyUserId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postRolesRoleIdPanelsCopyUserId>,
    TError,
    { roleId: number; userId: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postRolesRoleIdPanelsCopyUserId>,
    { roleId: number; userId: number }
  > = props => {
    const { roleId, userId } = props || {};

    return postRolesRoleIdPanelsCopyUserId(roleId, userId);
  };

  return useMutation<
    AsyncReturnType<typeof postRolesRoleIdPanelsCopyUserId>,
    TError,
    { roleId: number; userId: number },
    TContext
  >(mutationFn, mutationOptions);
};

import { FileSearchOutlined } from '@ant-design/icons';
import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetDocumentsSearchFilterForm } from './filters/WidgetDocumentsSearchFilterForm';
import { WidgetDocumentsSearch } from './WidgetDocumentsSearch';

export const WidgetDocumentsSearchType: IWidgetType = {
  code: 'documents-search',
  name: 'Ricerca Protocolli',
  icon: <FileSearchOutlined />,
  component: WidgetDocumentsSearch,
  filterComponent: WidgetDocumentsSearchFilterForm,
  description: `Ricerca i documenti protocollati all'interno dei documentale`
};

WidgetRepository.register(WidgetDocumentsSearchType);

import {
  ApiTwoTone,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  SettingTwoTone
} from '@ant-design/icons';
import { Empty } from 'antd';
import { Widget } from 'client/api/backend/schemas';
import { WidgetRepository } from 'client/components/widget-repository/WidgetRepository';
import React, { useContext } from 'react';
import { useMemo } from 'react';
import { useDeskContext } from '../../desk/context/DeskContext';
import { WidgetFullBodyResult } from '../card/body/WidgetFullBodyResult';
import { WidgetConfigurationButton } from '../configure/WidgetConfigurationButton';
import { WidgetDisplayLogic } from '../logic/WidgetDisplayLogic';

interface WidgetTypeContentProps {
  widget: Widget;
}

/**
 * Effettua il rendering del contenuto del Widget in base al WidgetType.
 */
export const WidgetTypeContent = React.memo(
  (props: WidgetTypeContentProps) => {
    const { widget } = props;
    const { desk } = useDeskContext();

    const widgetType = WidgetRepository.find(widget.profile?.typeCode);
    if (!widgetType) {
      return (
        <WidgetFullBodyResult
          result={{
            subTitle: 'Widget non trovato',
            icon: <QuestionCircleOutlined style={{ color: '#97a9b2' }} />
          }}
        />
      );
    }

    if (!WidgetDisplayLogic.isConfigured(widget)) {
      return (
        <WidgetFullBodyResult
          result={{
            subTitle: 'Configura il Widget per iniziare ad usarlo',
            icon: <ApiTwoTone />,
            extra: (
              <WidgetConfigurationButton
                className="no-drag"
                type="primary"
                textButton="Configura"
                widget={widget}
              />
            )
          }}
        />
      );
    }

    const WidgetContent = widgetType.component;
    return <WidgetContent widget={widget} desk={desk!} />;
  },
  (prevProps, nextProps) => prevProps.widget === nextProps.widget
);

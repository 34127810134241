import * as React from 'react';
import {
  ApiOutlined,
  BankOutlined,
  ProfileOutlined,
  UserOutlined
} from '@ant-design/icons/lib/icons';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import { useCurrentMenuKey } from 'client/core/router/breadcrumb/BreadcrumbContext';

const MenuStyle = styled(Menu)`
  background-color: transparent;
  height: 100%;
  padding-top: 12px;
`;

const VersionStyle = styled.div`
  font-size: 12px;
`;

export interface AdminMenuProps {}

export function AdminMenu(props: AdminMenuProps) {
  const currentPathKey = useCurrentMenuKey();

  return (
    <>
      <MenuStyle selectedKeys={currentPathKey} mode="inline">
        <Menu.Item icon={<UserOutlined />} key="admin/users">
          <Link to="/admin/users">Utenti</Link>
        </Menu.Item>
        <Menu.Item icon={<ProfileOutlined />} key="admin/profiles">
          <Link to="/admin/profiles">Profili</Link>
        </Menu.Item>
        <Menu.Item icon={<ApiOutlined />} key="admin/endpoints">
          <Link to="/admin/endpoints">Endpoints</Link>
        </Menu.Item>
      </MenuStyle>
      <VersionStyle>v{process.env.VERSION}</VersionStyle>
    </>
  );
}

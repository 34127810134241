import styled from 'styled-components';

export const FullBodyForm = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  border-radius: 0;
  border-bottom-left-radius: ${props => props.theme.cardRadius};
  border-bottom-right-radius: ${props => props.theme.cardRadius};
  .ant-form {
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
  }
`;

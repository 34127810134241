import * as React from 'react';
import clx from 'classnames';
import styled from 'styled-components';
import {
  ArrowLeftOutlined,
  FolderOpenOutlined,
  LeftOutlined
} from '@ant-design/icons/lib/icons';
import { Typography, Space, Tooltip, ButtonProps } from 'antd';

const FilterLineWrapper = styled.a`
  display: flex;
  align-items: center;
  padding: 8px ${props => props.theme.cardPaddingBase};
  min-height: 39px;

  /* transition: all 0.01s cubic-bezier(0.645, 0.045, 0.355, 1); */
  transition: background-color 0.6s ease;

  &:hover {
    background-color: ${props => props.theme.borderColorSplit};
    &:active {
      background-color: ${props => props.theme.bodyBackground};
    }
  }
`;
const IconCol = styled.div`
  flex: 0 0 14px;
  min-width: 14px;
  font-size: 14px;
  height: 15px;
`;
const DescriptionWrapperCol = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  display: flex;
  align-items: flex-start;
  padding-left: 16px;
  padding-top: 1px;
`;
const DescriptionIconCol = styled.div`
  flex: 0 0 16px;
  min-width: 16px;
  font-size: 16px;
  height: 16px;
`;
const DescriptionCol = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  font-weight: 500;
  line-height: 1.3;
  padding-left: 6px;
`;

export interface ListNavigationBackButtonProps
  extends Omit<React.HTMLAttributes<HTMLAnchorElement>, 'onClick'> {
  /**
   * Nome elemento padre per navigazione back (cartella)
   */
  name: React.ReactNode;
  /**
   * Di default l'icona rappresentata è una cartella aperta
   * è possibile passare una icona diversa valorizzando la proprietà icon
   */
  icon?: React.ReactNode;
  onClick?: () => void;
}

/**
 * Pulsante di back per la WidgetList per tornare all'elemento padre (cartella)
 */
export function ListNavigationBackButton(props: ListNavigationBackButtonProps) {
  const { name, icon, onClick } = props;

  return (
    <FilterLineWrapper onClick={onClick}>
      <IconCol>
        <ArrowLeftOutlined />
      </IconCol>
      <DescriptionWrapperCol>
        <DescriptionIconCol>
          {icon ?? <FolderOpenOutlined />}
        </DescriptionIconCol>
        <DescriptionCol>{name}</DescriptionCol>
      </DescriptionWrapperCol>
    </FilterLineWrapper>
  );
}

import { FileOutlined, PaperClipOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import { DeskActivity } from 'client/api/backend/schemas';
import React from 'react';

export const PRINCIPAL_ACTIVITY = 'principal';
export const PRINCIPAL_ACTIVITY_TEXT = 'principal-text';
export const ATTACHMENT_ACTIVITY = 'attachment';

export type ActivityType = 'principal' | 'principal-text' | 'attachment';

type ActivityMenuItem = ItemType & {
  activityType?: ActivityType;
  withText?: boolean;
};

export function convertActivityToAttachmentMenuItems(
  activity?: DeskActivity
): ActivityMenuItem[] {
  const principal: ActivityMenuItem[] = [
    {
      key: convertIdToKey(activity?.documentId, 'principal'),
      icon: <FileOutlined />,
      label: 'Atto',
      activityType: 'principal',
      withText: false
    }
  ];

  const principalWithText: ActivityMenuItem[] = [
    {
      key: convertIdToKey(activity?.documentId, 'principal-text'),
      icon: <FileOutlined />,
      label: 'Testo Atto',
      activityType: 'principal-text',
      withText: true
    }
  ];

  const attachments: ActivityMenuItem[] =
    activity?.attachments?.map((attachment, index) => ({
      key: convertIdToKey(attachment.id, 'attachment'),
      icon: <PaperClipOutlined />,
      label: attachment.name ?? `Allegato #${attachment.id}`,
      activityType: 'attachment'
    })) ?? [];

  if (attachments && attachments.length > 0) {
    return [
      ...principal,
      ...principalWithText,
      {
        key: 'divider',
        type: 'divider'
      },
      ...attachments
    ];
  }

  return [...principal, ...principalWithText];
}

export function convertIdToKey(id?: number, type?: ActivityType) {
  return `${type}-${id}`;
}

export function getIdFromKey(key: string, type: ActivityType) {
  return parseInt(key.replace(`${type}-`, ''), 10);
}

import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { RetweetOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { DeskActivity } from 'client/api/backend/schemas';
import { ActivityToolkitObject } from 'client/components/widget-repository/objects/activities/ActivityToolkitObject';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { yup } from 'common/validation/initYup';
import { InferType } from 'yup';
import { ActivityCompletion } from 'client/components/widget-repository/objects/activities/ActivityCompletions';
import { executeCompleteActivity } from './complete/executeCompleteActivity';

export const WidgetActionRequestChangesActivity = createToolkitAction({
  name: 'Richiedi Modifiche',
  code: 'request-changes-activity',
  icon: <RetweetOutlined />,
  arguments: [
    {
      name: 'Attività su cui Richiedere modifiche',
      type: [ActivityToolkitObject],
      allows: (activityObject: ToolkitObject<DeskActivity>) =>
        activityObject.data.responses?.includes(
          ActivityCompletion.RichiestaModifiche
        ) ?? false
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    activityObject: ToolkitObject<DeskActivity>
  ) {
    ctx.deskContext.actionMessages.prompt({
      ctx,
      action: WidgetActionRequestChangesActivity,
      args: [activityObject],
      confirmText: 'Richiedi Modifiche',
      schema: RequestChangesActivitySchema,
      onConfirm,
      form: (
        <>
          <FormFieldsContainer>
            <FormFieldItem
              size="large"
              component={TextAreaInput}
              label={null}
              name="note"
              placeholder="Note"
            />
          </FormFieldsContainer>
        </>
      )
    });
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: RequestChangesActivitySchemaType,
  activityObject: ToolkitObject<DeskActivity>
) {
  try {
    await executeCompleteActivity(activityObject, {
      type: ActivityCompletion.RichiestaModifiche,
      note: values.note
    });

    message.success('Modifiche richieste con successo');
  } catch (e: any) {
    message.error(
      getNetworkErrorMessage(e, 'Errore durante la richiesta di modifiche.')
    );
    console.error(e);
    return false;
  }
}

const RequestChangesActivitySchema = yup.object({
  note: yup.string().required('Note')
});

type RequestChangesActivitySchemaType = InferType<
  typeof RequestChangesActivitySchema
>;

import { AxiosInstance } from 'axios';
import { store } from 'client/core/store/configureReduxStore';
import { AuthActions } from 'client/components/auth/AuthModule';
import { AuthApi } from 'client/components/auth/AuthApi';
import { message } from 'antd';
import { logger } from 'client/core/logger/logger';
import {
  getAuthenticationRefreshToken,
  postAuthenticationAzureLogin,
  postAuthenticationIamLogin,
  postAuthenticationSiteminderLogin
} from 'client/api/backend/authentication/authentication';
import { DeskActions } from 'client/components/schema/desk/DeskModule';
import { appConfig } from 'client/core/appConfig';
import {
  AuthMethod,
  AutoLoginAuthMethods
} from 'client/components/auth/AuthMethods';

type Subscriber = (err: null | Error) => void;

export function refreshTokenInterceptor(axios: AxiosInstance) {
  let isRefreshing = false;

  /**
   * Insieme delle richieste in attesa.
   */
  let subscribers: Subscriber[] = [];

  const subscribeTokenRefresh = (sub: Subscriber) => subscribers.push(sub);
  const onTokenRefreshed = (err: null | Error) => {
    subscribers.forEach(subscriber => subscriber(err));
    subscribers = [];
    isRefreshing = false;
  };

  /**
   * Autenticazione di base. Tramite questo interceptor inseriamo le credenziali
   * nelle chiamate API in base a quanto salvato su Redux.
   */
  axios.interceptors.request.use(config => {
    config.headers = config.headers || {};
    const authToken = store.getState().auth.token;
    if (authToken) {
      config.headers['x-auth'] = authToken;
    }

    return config;
  });

  axios.interceptors.response.use(
    response => {
      /**
       * Quando viene ritornato il token di autenticazione (header), lo salviamo
       * localmente (e poi in `localStorage`) per utilizzarlo nelle chiamate
       * successive.
       */
      if (response.headers['x-auth']) {
        store.dispatch(AuthActions.setToken(response.headers['x-auth']));
      }

      return response;
    },
    /**
     * Gestione degli errori di tipo 401 (unauthorized). Quando scade il token,
     * proviamo prima a fare un refresh tramite l'API dedicata.
     * In caso non sia più valido neanche il refresh-token, invalidiamo la
     * sessione.
     */
    error => {
      const { config, response } = error;
      const originalConfig = config;

      if (response == null || response.status !== 401) {
        return Promise.reject(error);
      }

      if (
        config.url.endsWith('/authentication/logout') ||
        config.url.endsWith('/authentication/refresh-token')
      ) {
        return Promise.reject(error);
      }

      if (response.headers?.['x-no-auth']) {
        return Promise.reject(error);
      }

      /**
       * Gestione autenticazione con sistemi di login alternativi,
       * iam, siteminder, sso.
       * Azure è gestito in maniera diversa, tramite login iniziato dal FE.
       */
      if (shouldAutoLogin(appConfig.ssoMethod)) {
        if (!ssoSupportsReload(appConfig.ssoMethod)) {
          store.dispatch(AuthActions.logout(true));
          store.dispatch(DeskActions.reset());
          return Promise.reject(new Error("L'utente non è autenticato"));
        }

        if (store.getState().auth.isLogoutForced) {
          return Promise.reject(new Error("L'utente non è autenticato"));
        }

        /**
         * Registriamo il token come scaduto all'interno dello store.
         */
        store.dispatch(AuthActions.tokenExpired());

        authenticationLoginMethod(appConfig.ssoMethod)
          .then(res => {
            onTokenRefreshed(null);
          })
          .catch(err => {
            console.error('FORCED LOGOUT', err);
            /**
             * Se il refreshToken non è più valido effettuiamo il logout.
             */
            message.error(
              `La sessione è scaduta. Si prega di effettuare nuovamente l'accesso.`
            );
            store.dispatch(AuthActions.logout(true));
            store.dispatch(DeskActions.reset());

            if (!err.response.data.redirect) {
              console.error('Indirizzo di redirect non presente', err);
              return Promise.reject(error);
            }

            window.location.href = err.response.data.redirect.startsWith('http')
              ? err.response.data.redirect
              : `https://${err.response.data.redirect}`;

            return Promise.reject(error);
          });
      }
      /**
       * Se non si è loggati, non si può tentare il refresh. Allo stesso modo,
       * se l'autenticazione è via Windows, non c'è un token.
       */
      const {
        isLogged,
        current: currentUser,
        strategy
      } = store.getState().auth;
      logger.log(`[Auth/refresh] Utente ${currentUser?.username ?? '<NA>'} - tentativo di refresh del token (401)`); // prettier-ignore

      if (!isLogged) {
        return Promise.reject(error);
      }

      /**
       * In caso di errore per utente non autenticato, significa che l'`accessToken`
       * è scaduto. In questo caso, prima di mostrare nuovamente la pagina di login
       * all'utente andiamo a tentare l'aggiornamento mediante il `refreshToken`.
       */
      if (!isRefreshing) {
        isRefreshing = true;

        /**
         * Registriamo il token come scaduto all'interno dello store.
         * Al momento non viene utilizzato, ma potrebbe essere utile.
         * Nota: non effettuiamo il logout perché altrimenti verrebbero
         * applicati i redirect di `react-router`
         */
        store.dispatch(AuthActions.tokenExpired());

        getAuthenticationRefreshToken()
          .then(res => {
            /**
             * L'aggiornamento del token ha avuto successo.
             * Registro il nonce sullo store di redux, per sapere in generale
             * che l'utente è ora autenticato.
             */
            store.dispatch(AuthActions.tokenRefreshed('jwt', ''));

            /**
             * Aggiorno il token per le richiesta con il token scaduto.
             */
            onTokenRefreshed(null);
          })
          .catch(err => {
            console.error('FORCED LOGOUT', err);
            /**
             * Se il refreshToken non è più valido effettuiamo il logout.
             */
            message.error(
              `La sessione è scaduta. Si prega di effettuare nuovamente l'accesso.`
            );
            store.dispatch(AuthActions.logout(false));
            store.dispatch(DeskActions.reset());

            onTokenRefreshed(err);
          });
      }

      /**
       * Inseriamo la richiesta nella coda.
       */
      return new Promise((resolve, reject) => {
        subscribeTokenRefresh(err => {
          if (err) return reject(err);

          return resolve(axios({ ...config }));
        });
      });
    }
  );
}

function authenticationLoginMethod(ssoMethod: string) {
  switch (ssoMethod) {
    case AuthMethod.Siteminder:
      return postAuthenticationSiteminderLogin();
    default:
      throw new Error(`Metodo di autenticazione ${ssoMethod} non supportato`);
  }
}

function shouldAutoLogin(ssoMethod: AuthMethod) {
  return ssoMethod != null && AutoLoginAuthMethods.includes(ssoMethod);
}

function ssoSupportsReload(ssoMethod: AuthMethod) {
  return ssoMethod !== AuthMethod.Sso;
}

import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { yup } from 'common/validation/initYup';
import { InferType } from 'yup';
import { message } from 'antd';
import { WidgetAssignmentModelsProfile } from '../../WidgetAssignmentModelsProfile';
import {
  AssignmentModelToolkitObject,
  IAssignmentModel
} from 'client/components/widget-repository/objects/assignment-models/AssignmentModelToolkitObject';
import { set } from 'client/core/data/set';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { EditOutlined } from '@ant-design/icons';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextInput } from 'client/ui/form/input/TextInput';

export const WidgetActionRenameAssignmentModel = createToolkitAction({
  name: 'Rinomina Modello di assegnazione',
  code: 'rename-assignment-model',
  icon: <EditOutlined />,
  arguments: [
    {
      name: 'Modello di Assegnazione',
      type: [AssignmentModelToolkitObject]
    }
  ],
  async execute(ctx: IToolkitActionContext, modelObject: ToolkitObject) {
    const model = AssignmentModelToolkitObject.get(modelObject)!;

    // Apriamo sempre il prompt per conferma.
    ctx.deskContext.actionMessages.prompt({
      ctx,
      args: [model],
      hideArgs: true,
      action: WidgetActionRenameAssignmentModel,
      title: 'Rinomina modello di assegnazione',
      confirmText: 'Rinomina',
      initialValues: { label: model.data.label },
      schema: RenameAssignmentModelSchema,
      onConfirm,
      form: (
        <FormFieldsContainer>
          <FormFieldItem
            size="large"
            component={TextInput}
            label={null}
            name="label"
            placeholder="Nome del modello"
          />
        </FormFieldsContainer>
      )
    });
  }
});

/**
 * Modifica il nome del modello di assegnazione dal widget selezionato.
 */
async function onConfirm(
  ctx: IToolkitActionContext,
  values: RenameAssignmentModelSchemaType,
  model: ToolkitObject<IAssignmentModel>
) {
  try {
    const widget = ctx.deskContext.widgets.find(
      w => w.id === model.sourceWidgetId
    );
    const profile = widget?.profile;
    const data = profile?.data as WidgetAssignmentModelsProfile | null;
    const index = data?.models.findIndex(d => d.uuid === model.data.uuid);

    await ctx.deskContext.updateWidget(
      set(widget!, `profile.data.models.${index}.label`, values.label)
    );

    message.success('Modello rinominato con successo');
  } catch (e) {
    message.error(
      getNetworkErrorMessage(e, 'Impossibile rinominare il modello')
    );
    console.error(e);
    return false;
  }
}

const RenameAssignmentModelSchema = yup.object({
  label: yup.string().required().label('Nome del modello')
});

type RenameAssignmentModelSchemaType = InferType<
  typeof RenameAssignmentModelSchema
>;

import { FvIconFileView } from 'client/ui/icon/FvIcons';
import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetActionViewDamGeoregistry } from './actions/WidgetActionViewDamGeoregistry';
import { WidgetDamGeoregistryViewer } from './WidgetDamGeoregistryViewer';
import { WidgetDamGeoregistryViewerProfile } from './WidgetDamGeoregistryViewerProfile';
import { FvIconDamEye } from 'client/ui/icon/FvIconsDam';

export const WidgetDamGeoregistryViewerType: IWidgetType = {
  code: 'dam-viewer',
  name: 'Geoanagrafica Diga',
  getTitle: widget => {
    const data = widget.profile?.data as
      | WidgetDamGeoregistryViewerProfile
      | undefined;
    return data?.damDescription;
  },
  icon: <FvIconDamEye />,
  component: WidgetDamGeoregistryViewer,
  description: `Mostra la geoanagrafica della Diga`,
  actions: [WidgetActionViewDamGeoregistry]
};

WidgetRepository.register(WidgetDamGeoregistryViewerType);

import * as React from 'react';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';

export const WidgetGridWrapper = styled.div`
  padding: ${props => props.theme.widgetGridPadding};
  padding-bottom: 8px;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: -8px;

  // Per supportare il WidgetAddPlaceholder con posizionamento "absolute"
  position: relative;

  ${down('lg')} {
    padding-right: 0;
    padding-left: 0;
  }

  min-height: calc(100vh - ${props => props.theme.widgetGridMinusHeigh});

  flex: 1 1 auto;
  min-width: 1px;
  min-height: 1px;
  /* min-height: 0; */
  display: flex;
`;

export interface WidgetContainerProps {
  children?: React.ReactNode;
}

export const WidgetContainer = React.forwardRef<
  HTMLDivElement,
  WidgetContainerProps
>((props: WidgetContainerProps, ref) => {
  return (
    <WidgetGridWrapper ref={ref as any}>{props.children}</WidgetGridWrapper>
  );
});

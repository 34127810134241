import * as React from 'react';
import { PageHeader, Space } from 'antd';
import { AdminLayout } from '../../../ui/layout/AdminLayout';
import { AdminMenu } from '../menu/AdminMenu';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { RouteComponentProps } from 'react-router';
import { ColumnProps } from 'antd/lib/table';
import { RoleResponseDTO } from 'client/api/backend/schemas';
import { TableDataScroll } from 'client/ui/table/TableDataScroll';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { Link } from 'react-router-dom';
import { LinkButton } from 'client/ui/link/LinkButton';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { useGetRoles } from 'client/api/backend/roles/roles';
import { ProfileColumnDeleteButton } from './ProfileColumnDeleteButton';

const columns: ColumnProps<RoleResponseDTO>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '10%'
  },
  {
    title: 'Nome',
    dataIndex: 'name',
    key: 'name'
  },
  {
    dataIndex: 'actions',
    key: 'actions',
    width: '86px',
    render: (_, record) => (
      <Space>
        <ProfileColumnDeleteButton id={record.id!} name={record.name} />
        <Link to={`/admin/profiles/${record.id}`}>
          <ButtonIcon icon={<EditOutlined />} />
        </Link>
      </Space>
    )
  }
];

export interface ProfileListPageProps extends RouteComponentProps {}

/**
 * Pagina dei Profili
 */
export function ProfileListPage(props: ProfileListPageProps) {
  useBreadcrumbItem({
    path: props.location.pathname,
    title: 'Profili'
  });

  const { data, error, isLoading } = useGetRoles();

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco dei profili. Riprovare."
        error={error}
      />
    );
  }

  return (
    <AdminLayout menu={<AdminMenu />}>
      <PageHeader
        title="Profili"
        extra={[
          <LinkButton
            ghost
            type="primary"
            to="/admin/profiles/create"
            icon={<PlusOutlined />}
          >
            Nuovo
          </LinkButton>
        ]}
      />
      <TableDataScroll<RoleResponseDTO>
        loading={isLoading}
        dataSource={data ?? []}
        columns={columns}
      />
    </AdminLayout>
  );
}

import * as React from 'react';
import { useDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { FloatWidgetContainer } from 'client/ui/grid/FloatWidgetContainer';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';
import { FloatWidget } from '../FloatWidget';
import { useTransition, animated, config as springConfig } from 'react-spring';
import { useDesk } from '../../desk/DeskModule';
import { Widget } from 'client/api/backend/schemas';
import { Button, Dropdown, Menu } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { WidgetDisplayLogic } from '../logic/WidgetDisplayLogic';
import styled from 'styled-components';
import { set } from 'client/core/data/set';
import { DeskWidgetsLogic } from '../../desk/logic/DeskWidgetsLogic';
import { WidgetPositionHandler } from '../logic/WidgetPositionHandler';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useEffect, useState } from 'react';

const DropdownWrapper = styled.div`
  pointer-events: auto;
  margin-bottom: 8px;
  .ant-btn {
    box-shadow: ${props => props.theme.shadowBase};
  }
`;

const MAX_MOBILE_WIDGETS = 1;
const MAX_DESKTOP_WIDGETS = 4;

export interface IFloatingWidgetAreaProps {
  handleRestore: (widget: Widget) => void;
  setDroppingItem: (widget: Widget | undefined) => void;
}

interface FloatingAreaState {
  /** Widget visualizzabili */
  floating: Widget[];
  /** Voci di menu (collapsed) dei Widget */
  collapsed: ItemType[];
}

/**
 * Gestisce i Floating Widget
 */
export function FloatingWidgetArea(props: IFloatingWidgetAreaProps) {
  const { handleRestore, setDroppingItem } = props;

  const [{ floating, collapsed }, setAreaState] = useState<FloatingAreaState>({
    floating: [],
    collapsed: []
  });

  const deskState = useDesk();
  const deskContext = useDeskContext();
  const isMobile = useBreakpoint(down('sm'));

  /**
   * Quando si clicca su uno dei Widget nascosti, lo portiamo in primo piano
   * modificandone l'`order`. Inoltre lo espandiamo automaticamente.
   */
  const showAsFirst = (item: Widget) => {
    const widget = deskContext.floatingWidgets.find(w => w.id === item.id)!;
    deskContext.updateWidget(
      set(widget, 'profile.position', {
        ...WidgetPositionHandler.floatingPosition(widget),
        expanded: true,
        order: DeskWidgetsLogic.getFloatingNextOrder(
          deskContext.floatingWidgets
        )
      })
    );
  };

  /**
   * Gestiamo dinamicamente il "massimo" numero di widget visibili
   * contemporaneamente in basso, in base alla larghezza del monitor.
   */
  useEffect(() => {
    const floatingMax = isMobile ? MAX_MOBILE_WIDGETS : MAX_DESKTOP_WIDGETS;

    const count = deskContext.floatingWidgets.length;

    // I primi (da destra) rimangono visibili
    const floating = deskContext.floatingWidgets.slice(-floatingMax);
    // Gli altri vengono converti in menu.
    const collapsedWidgets =
      count >= floatingMax
        ? deskContext.floatingWidgets.slice(0, count - floatingMax)
        : [];

    const collapsed = collapsedWidgets.map(widget => {
      const titleProps = WidgetDisplayLogic.getTitleProps(widget);
      return {
        key: widget.id,
        label: titleProps.title,
        icon: titleProps.icon,
        onClick: () => {
          showAsFirst(widget);
        }
      } as ItemType;
    });

    setAreaState({ floating, collapsed });
  }, [isMobile, deskContext.floatingWidgets]);

  // Animazione dei componenti
  const transitions = useTransition(floating, {
    from: { opacity: 0, width: 0 },
    enter: [
      { opacity: 1, width: 230 },
      { opacity: 1, width: 'auto' }
    ],
    leave: { opacity: 0, width: 0 },
    keys: widget => widget.id!.toString(),
    config: {
      ...springConfig.gentle,
      tension: 180,
      friction: 20
    }
    // onRest: () => setItems([])
  });

  const collapsedWidgetsMenuButton = (
    <DropdownWrapper>
      <Dropdown
        overlay={<Menu items={collapsed} />}
        placement="topLeft"
        trigger={['click']}
      >
        <Button
          icon={<EllipsisOutlined />}
          shape="circle"
          type="primary"
          size="large"
        />
      </Dropdown>
    </DropdownWrapper>
  );

  return (
    <FloatWidgetContainer>
      {collapsed.length > 0 && collapsedWidgetsMenuButton}
      {transitions((props, widget) => (
        <animated.div
          style={props}
          key={widget.id + '-parked'}
          draggable={deskState.isEditable}
          unselectable="on"
          onDragStart={() => handleRestore(widget)}
          onDragEnd={() => setDroppingItem(undefined)}
        >
          <FloatWidget widget={widget} />
        </animated.div>
      ))}
    </FloatWidgetContainer>
  );
}

import React from 'react';
import styled from 'styled-components';
import { Tooltip } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import clx from 'classnames';
import { IoEllipse } from 'react-icons/io5';
import { getHexColorById } from 'client/components/schema/colors/Colors';
import { DeskActions, useDesk } from 'client/components/schema/desk/DeskModule';
import { DeskButtonStyle } from './DeskButtonStyle';
import { Panel } from 'client/api/backend/schemas';
import { useDispatch } from 'react-redux';
import { IconDeskMenu } from './IconDeskMenu';

const ColorIcon = styled(IconDeskMenu)<{ color: string }>`
  color: ${props => props.color};
`;

interface DeskButtonProps
  extends Omit<ButtonProps, 'shape' | 'icon' | 'color'> {
  desk: Panel;
  isMobile?: boolean | null;
}

export function DeskButton(props: DeskButtonProps) {
  const deskState = useDesk();
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(DeskActions.select(props.desk.id!));
  };

  const { desk, isMobile } = props;

  return (
    <Tooltip
      title={isMobile ? undefined : desk.profile?.description}
      mouseEnterDelay={0.8}
    >
      <DeskButtonStyle
        onClick={handleClick}
        className={clx({
          selected: desk.id === deskState.selectedId,
          edit: deskState.isEditable && desk.id === deskState.selectedId
        })}
        shape="round"
        type="ghost"
        icon={
          <ColorIcon
            icon={<IoEllipse />}
            color={getHexColorById(desk.profile?.colorId)}
          />
        }
      >
        {desk.name}
      </DeskButtonStyle>
    </Tooltip>
  );
}

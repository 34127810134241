import { ISODate } from 'client/ui/display/date/ISODate';
import React from 'react';
import styled, { css } from 'styled-components';

function textElement(text?: string | Date): string | React.ReactNode {
  return text instanceof Date ? (
    <ISODate date={text} format={'DD/MM/YYYY HH:mm'} />
  ) : (
    text
  );
}

const Extend = styled.span<{ bold?: boolean }>`
  font-weight: ${({ bold }) => (bold ? 600 : 'normal')};
`;
const ExtendColor = styled(Extend)<{ color?: string }>`
  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `};
`;

export interface ExtendTextProps {
  text?: string | Date;
  bold?: boolean;
  color?: string;
}

/**
 * visualizza un testo esteso
 */
export function ExtendText(props: ExtendTextProps) {
  const { text, bold, color } = props;
  if (!text) {
    return null;
  }

  return <ExtendColor bold={bold} color={color} children={textElement(text)} />;
}

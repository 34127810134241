import { DeskAssignor } from 'client/api/backend/schemas';

/**
 * La description è del tipo "nome.cognome - Nome Cognome" oppure "UFFICIO - Nome Ufficio"
 */
export function formatAssignmentEntityDescription(entity?: DeskAssignor) {
  if (!entity) return '';

  if (entity.description) {
    return formatAssignmentEntityName(entity.description);
  }

  if (entity.officeDescription) {
    return entity.officeDescription.split(' - ').join(' ');
  }

  return '';
}

export function formatAssignmentEntityName(description?: string) {
  if (!description) return '';

  const [pre, post] = description.split(' - ');

  return post ?? pre;
}

import * as React from 'react';
import { WidgetTitle } from './header/title/WidgetTitle';
import { WidgetExtra } from './header/buttons/WidgetExtra';
import { FullScreenButton } from 'client/components/schema/widget/header/buttons/FullScreenButton';
import { SyncButton } from 'client/components/schema/widget/header/buttons/SyncButton';
import { FloatWidgetCard } from './card/floating/FloatWidgetCard';
import { CloseFloatButton } from './header/buttons/CloseFloatButton';
import { ExpandFloatButton } from './header/buttons/ExpandFloatButton';
import { useEffect, useState } from 'react';
import { DeskActions, useDesk } from '../desk/DeskModule';
import { useDispatch } from 'react-redux';
import { Widget } from 'client/api/backend/schemas';
import { WidgetTypeContent } from './dynamic/WidgetTypeContent';
import { useWidgetMutations } from './logic/useWidgetMutations';
import { WidgetConfigurationButton } from './configure/WidgetConfigurationButton';
import { WidgetPositionHandler } from './logic/WidgetPositionHandler';
import { set } from 'client/core/data/set';
import { WidgetContextProvider } from './context/WidgetContext';
import { WidgetChooseDeskButton } from './floating/WidgetChooseDeskButton';

export interface FloatWidgetProps {
  widget: Widget;
}

/**
 * Struttura base del Widget
 */
export function FloatWidget(props: FloatWidgetProps) {
  const [fullScreen, setFullScreen] = useState(false);

  const deskState = useDesk();
  const dispatch = useDispatch();

  const { widget } = props;
  const position = WidgetPositionHandler.floatingPosition(widget);

  const toggleFullScreen = () => {
    const isFullscreen = !fullScreen;
    setFullScreen(isFullscreen);
    dispatch(DeskActions.fullScreen(isFullscreen));
  };

  useEffect(() => {
    if (widget.profile?.initializeInFullScreen) {
      setFullScreen(true);
    }
  }, [widget.profile?.initializeInFullScreen]);

  const widgetMutations = useWidgetMutations();

  const closeButton = (
    <CloseFloatButton
      type="primary"
      skipConfirm={widget.profile?.quickClose}
      loading={widgetMutations.removeState.isLoading}
      onClick={async () => {
        await widgetMutations.remove(widget);
      }}
    />
  );

  return (
    <WidgetContextProvider widget={widget}>
      <FloatWidgetCard
        widgetExpanded={position.expanded}
        fullScreen={fullScreen}
        title={<WidgetTitle widget={widget} float />}
        extra={
          <WidgetExtra>
            {deskState.isEditable ? (
              <>
                <WidgetConfigurationButton type="primary" widget={widget} />
                <WidgetChooseDeskButton widget={widget} />
                {closeButton}
              </>
            ) : (
              <>
                {!fullScreen && (
                  <ExpandFloatButton
                    type="primary"
                    onClick={() =>
                      widgetMutations.update(
                        set(
                          widget,
                          'profile.position.expanded',
                          !position.expanded
                        )
                      )
                    }
                    widgetExpanded={position.expanded}
                    disabled={fullScreen}
                  />
                )}
                <FullScreenButton
                  type="primary"
                  onClick={toggleFullScreen}
                  fullScreen={fullScreen}
                />
                {(position.expanded || fullScreen) && (
                  <SyncButton type="primary" />
                )}
                {!fullScreen && closeButton}
              </>
            )}
          </WidgetExtra>
        }
      >
        <WidgetTypeContent widget={widget} />
      </FloatWidgetCard>
    </WidgetContextProvider>
  );
}

/**
 * Variabili Less del Thema convertite per utilizzarle con Styled Component
 *
 * // TODO: Gestire allineamento con thema less
 */
export const lessThemeConverted = {
  // inserite al momento manualmente
  primaryColor: '#0080FF',
  successColor: '#00cf86',
  infoColor: '#0b00d9',
  warningColor: '#ff9700',
  errorColor: '#f83e5a',
  bodyBackground: '#e7ecee',
  textColor: '#54646d',
  textColorSecondary: '#97a9b2',

  borderColorBase: '#e7ecee',
  borderColorSplit: '#f5f7f8',
  componentBackground: '#fff',

  // Font Base
  // ---
  fontSizeBase: '14px',
  fontSizeSm: '10px',
  lineHeightBase: 1.3,

  // Card
  // ---
  cardPaddingBase: '12px',
  cardRadius: '10px',

  // Form
  // ---
  formItemLabelFontSize: '12px',

  // Input
  // ---
  inputBorderColor: '#c0ccd2',
  inputPlaceholderColor: '#b0c0d1',

  // Layout
  // ---
  layoutHeaderHeight: '48px',
  layoutHeaderPadding: '0 10px'
};

import React from 'react';
import { ToolkitObject } from '../ToolkitObject';

export const toolkitObjectOptionValue = (object: ToolkitObject | null) =>
  object ? `${object.type}::${object.id}` : null;

// createOption
export function createToolkitObjectSelectOption(o: ToolkitObject) {
  return {
    // Il dato potrebbe essere salvato sul DB com react element, quindi
    // al momento in cui viene ritornato sul client è solo un oggetto e non
    // un elemento React. Per questo motivo, se non è un elemento valido
    // lo ignoriamo. Lato rendering non è un problema perché questa situazione
    // è momentanea e si verifica quando non sono ancora caricate le opzioni lato server.
    label: (
      <span>
        {React.isValidElement(o.icon) && o.icon} {o.name}
      </span>
    ),
    title: o.name,
    value: toolkitObjectOptionValue(o)!,
    object: o
  };
}

/**
 * Generato automaticamente.
 * DeskUI REST API
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  DeskActivity,
  GetDocumentaryActivityParams,
  DeskAttachment,
  GetDocumentaryActivityAttachmentsIdAttachmentParams,
  ActivityCompleteRequest,
  PostDocumentaryActivityCompleteParams,
  DeskKeyValue,
  GetDocumentaryActivityCompletionsParams,
  GetDocumentaryActivityIdParams,
  GetDocumentaryActivityIdActParams,
  SignRequestBody,
  PostDocumentaryActivityIdSignParams
} from '.././schemas';
import {
  apiInstance,
  ErrorType
} from '../../../core/network/mutators/apiClient';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Ritorna la lista delle attività secondo i filtri richiesti
 */
export const getDocumentaryActivity = (
  params?: GetDocumentaryActivityParams
) => {
  return apiInstance<DeskActivity[]>({
    url: `/api/v1/documentary/activity`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryActivityQueryKey = (
  params?: GetDocumentaryActivityParams
) => [`/api/v1/documentary/activity`, ...(params ? [params] : [])];

export type GetDocumentaryActivityQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryActivity>
>;
export type GetDocumentaryActivityQueryError = ErrorType<void>;

export const useGetDocumentaryActivity = <
  TData = AsyncReturnType<typeof getDocumentaryActivity>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryActivityParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryActivity>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryActivityQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryActivity>
  > = () => getDocumentaryActivity(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryActivity>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Recupera l'allegato dell'attività identificata
 */
export const getDocumentaryActivityAttachmentsIdAttachment = (
  idAttachment: number,
  params?: GetDocumentaryActivityAttachmentsIdAttachmentParams
) => {
  return apiInstance<DeskAttachment>({
    url: `/api/v1/documentary/activity/attachments/${idAttachment}`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryActivityAttachmentsIdAttachmentQueryKey = (
  idAttachment: number,
  params?: GetDocumentaryActivityAttachmentsIdAttachmentParams
) => [
  `/api/v1/documentary/activity/attachments/${idAttachment}`,
  ...(params ? [params] : [])
];

export type GetDocumentaryActivityAttachmentsIdAttachmentQueryResult =
  NonNullable<
    AsyncReturnType<typeof getDocumentaryActivityAttachmentsIdAttachment>
  >;
export type GetDocumentaryActivityAttachmentsIdAttachmentQueryError =
  ErrorType<void>;

export const useGetDocumentaryActivityAttachmentsIdAttachment = <
  TData = AsyncReturnType<typeof getDocumentaryActivityAttachmentsIdAttachment>,
  TError = ErrorType<void>
>(
  idAttachment: number,
  params?: GetDocumentaryActivityAttachmentsIdAttachmentParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryActivityAttachmentsIdAttachment>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryActivityAttachmentsIdAttachmentQueryKey(
      idAttachment,
      params
    );

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryActivityAttachmentsIdAttachment>
  > = () => getDocumentaryActivityAttachmentsIdAttachment(idAttachment, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryActivityAttachmentsIdAttachment>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!idAttachment, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Completa un'attività
 */
export const postDocumentaryActivityComplete = (
  activityCompleteRequest: ActivityCompleteRequest,
  params?: PostDocumentaryActivityCompleteParams
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/activity/complete`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: activityCompleteRequest,
    params
  });
};

export type PostDocumentaryActivityCompleteMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryActivityComplete>
>;
export type PostDocumentaryActivityCompleteMutationBody =
  ActivityCompleteRequest;
export type PostDocumentaryActivityCompleteMutationError = ErrorType<unknown>;

export const usePostDocumentaryActivityComplete = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryActivityComplete>,
    TError,
    {
      data: ActivityCompleteRequest;
      params?: PostDocumentaryActivityCompleteParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryActivityComplete>,
    {
      data: ActivityCompleteRequest;
      params?: PostDocumentaryActivityCompleteParams;
    }
  > = props => {
    const { data, params } = props || {};

    return postDocumentaryActivityComplete(data, params);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryActivityComplete>,
    TError,
    {
      data: ActivityCompleteRequest;
      params?: PostDocumentaryActivityCompleteParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Recupera la lista dei completamenti delle attività
 */
export const getDocumentaryActivityCompletions = (
  params?: GetDocumentaryActivityCompletionsParams
) => {
  return apiInstance<DeskKeyValue[]>({
    url: `/api/v1/documentary/activity/completions`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryActivityCompletionsQueryKey = (
  params?: GetDocumentaryActivityCompletionsParams
) => [`/api/v1/documentary/activity/completions`, ...(params ? [params] : [])];

export type GetDocumentaryActivityCompletionsQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryActivityCompletions>
>;
export type GetDocumentaryActivityCompletionsQueryError = ErrorType<void>;

export const useGetDocumentaryActivityCompletions = <
  TData = AsyncReturnType<typeof getDocumentaryActivityCompletions>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryActivityCompletionsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryActivityCompletions>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryActivityCompletionsQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryActivityCompletions>
  > = () => getDocumentaryActivityCompletions(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryActivityCompletions>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Recupera l'attività identificata
 */
export const getDocumentaryActivityId = (
  id: number,
  params?: GetDocumentaryActivityIdParams
) => {
  return apiInstance<DeskActivity>({
    url: `/api/v1/documentary/activity/${id}`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryActivityIdQueryKey = (
  id: number,
  params?: GetDocumentaryActivityIdParams
) => [`/api/v1/documentary/activity/${id}`, ...(params ? [params] : [])];

export type GetDocumentaryActivityIdQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryActivityId>
>;
export type GetDocumentaryActivityIdQueryError = ErrorType<void>;

export const useGetDocumentaryActivityId = <
  TData = AsyncReturnType<typeof getDocumentaryActivityId>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetDocumentaryActivityIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryActivityId>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryActivityIdQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryActivityId>
  > = () => getDocumentaryActivityId(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryActivityId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Recupera l'atto di un attività
 */
export const getDocumentaryActivityIdAct = (
  id: number,
  params?: GetDocumentaryActivityIdActParams
) => {
  return apiInstance<DeskAttachment>({
    url: `/api/v1/documentary/activity/${id}/act`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryActivityIdActQueryKey = (
  id: number,
  params?: GetDocumentaryActivityIdActParams
) => [`/api/v1/documentary/activity/${id}/act`, ...(params ? [params] : [])];

export type GetDocumentaryActivityIdActQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryActivityIdAct>
>;
export type GetDocumentaryActivityIdActQueryError = ErrorType<void>;

export const useGetDocumentaryActivityIdAct = <
  TData = AsyncReturnType<typeof getDocumentaryActivityIdAct>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetDocumentaryActivityIdActParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryActivityIdAct>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryActivityIdActQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryActivityIdAct>
  > = () => getDocumentaryActivityIdAct(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryActivityIdAct>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Firma una attività
 */
export const postDocumentaryActivityIdSign = (
  id: number,
  signRequestBody: SignRequestBody,
  params?: PostDocumentaryActivityIdSignParams
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/activity/${id}/sign`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: signRequestBody,
    params
  });
};

export type PostDocumentaryActivityIdSignMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryActivityIdSign>
>;
export type PostDocumentaryActivityIdSignMutationBody = SignRequestBody;
export type PostDocumentaryActivityIdSignMutationError = ErrorType<unknown>;

export const usePostDocumentaryActivityIdSign = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryActivityIdSign>,
    TError,
    {
      id: number;
      data: SignRequestBody;
      params?: PostDocumentaryActivityIdSignParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryActivityIdSign>,
    {
      id: number;
      data: SignRequestBody;
      params?: PostDocumentaryActivityIdSignParams;
    }
  > = props => {
    const { id, data, params } = props || {};

    return postDocumentaryActivityIdSign(id, data, params);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryActivityIdSign>,
    TError,
    {
      id: number;
      data: SignRequestBody;
      params?: PostDocumentaryActivityIdSignParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};

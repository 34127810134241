import { FvIconFolderSearch } from 'client/ui/icon/FvIcons';
import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetActionDeleteDossier } from './actions/WidgetActionDeleteDossier';
import { WidgetActionCreateDossier } from '../dossier-create/actions/WidgetActionCreateDossier';
import { WidgetDossiersSearch } from './WidgetDossiersSearch';
import { WidgetDossiersSearchFilterForm } from './filters/WidgetDossiersSearchFilterForm';

export const WidgetDossiersSearchType: IWidgetType = {
  code: 'dossiers-search',
  name: 'Ricerca Fascicoli',
  icon: <FvIconFolderSearch />,
  component: WidgetDossiersSearch,
  filterComponent: WidgetDossiersSearchFilterForm,
  description: `Permette la ricerca sui fascicoli presenti nel documentale`,
  actions: [WidgetActionDeleteDossier, WidgetActionCreateDossier]
};

WidgetRepository.register(WidgetDossiersSearchType);

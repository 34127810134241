import { DeskDamObservation } from 'client/api/backend/schemas';

export interface IObservationParameterValue {
  date?: number;
  [key: string]: string | number | undefined;
}

/**
 * Converte le osservazioni in una mappa di dati da mostrare nella tabella.
 * Dalle API riceviamo un array che contiene un'osservazione per ogni data e per ogni parametro.
 * Questa funzione converte l'array in una mappa che ha come chiave la data e come valore tutte le osservazioni di quella data.
 */
export function convertObservationsToTableData(data: DeskDamObservation[]) {
  const convertedObservations = new Map<number, IObservationParameterValue>();

  data.forEach(observation => {
    const date = observation.date ?? 0;
    const parameter = observation.parameter;
    const value = observation.value;
    const unitMeasure = observation.unitMeasure;

    const parameterData = {
      [parameter!]: value
    };

    if (convertedObservations.has(date)) {
      convertedObservations.set(date, {
        ...convertedObservations.get(date),
        ...parameterData
      });
    } else {
      convertedObservations.set(date, {
        date,
        ...parameterData
      });
    }
  });

  return convertedObservations;
}

import { MailOutlined } from '@ant-design/icons';
import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetActionAnnotateMail } from './actions/WidgetActionAnnotateMail';
import { WidgetActionDeleteMail } from './actions/WidgetActionDeleteMail';
import { WidgetMailsSearchFilterForm } from './filters/WidgetMailsSearchFilterForm';
import { WidgetMails } from './WidgetMails';

export const WidgetMailsType: IWidgetType = {
  code: 'mails',
  name: 'Email da lavorare',
  icon: <MailOutlined />,
  component: WidgetMails,
  filterComponent: WidgetMailsSearchFilterForm,
  description: `Permette di consultare le email da lavorare`,
  actions: [WidgetActionDeleteMail, WidgetActionAnnotateMail]
};

WidgetRepository.register(WidgetMailsType);

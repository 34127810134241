import { DeskCorrespondent } from 'client/api/backend/schemas';

export function formatCorrespondentNames(correspondents?: DeskCorrespondent[]) {
  if (!correspondents) return undefined;

  return correspondents.map(correspondent =>
    convertCorrespondentName(correspondent)
  );
}

export function convertCorrespondentName(
  correspondent?: DeskCorrespondent
): string {
  if (!correspondent) return '';

  const transport = correspondent.transport?.description
    ? ` - ${correspondent.transport.description}`
    : '';

  if (correspondent.person) {
    return `${correspondent.person.name ?? ''} ${
      correspondent.person.surname ?? ''
    }${transport}`;
  }

  if (correspondent.corporate) {
    return `${correspondent.corporate.name ?? ''}${transport}`;
  }

  if (correspondent.administration) {
    return `${correspondent.administration.name ?? ''}${transport}`;
  }

  return '';
}

/**
 * Generato automaticamente.
 * DeskUI REST API
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  WidgetResponseDTO,
  WidgetCreateDTO,
  WidgetUpdateRequestDTO,
  WidgetType,
  ConfigurationResponse,
  ConfigurationRequest,
  ConfigurationFieldResponse,
  ConfigurationFieldRequest,
  WidgetCopyRequestDTO
} from '.././schemas';
import {
  apiInstance,
  ErrorType
} from '../../../core/network/mutators/apiClient';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Ritorna la lista dei widget presenti
 */
export const getWidgets = () => {
  return apiInstance<WidgetResponseDTO[]>({
    url: `/api/v1/widgets`,
    method: 'get'
  });
};

export const getGetWidgetsQueryKey = () => [`/api/v1/widgets`];

export type GetWidgetsQueryResult = NonNullable<
  AsyncReturnType<typeof getWidgets>
>;
export type GetWidgetsQueryError = ErrorType<void>;

export const useGetWidgets = <
  TData = AsyncReturnType<typeof getWidgets>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<AsyncReturnType<typeof getWidgets>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetWidgetsQueryKey();

  const queryFn: QueryFunction<AsyncReturnType<typeof getWidgets>> = () =>
    getWidgets();

  const query = useQuery<AsyncReturnType<typeof getWidgets>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Inserisce un widget
 */
export const postWidgets = (widgetCreateDTO: WidgetCreateDTO) => {
  return apiInstance<WidgetResponseDTO | void>({
    url: `/api/v1/widgets`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: widgetCreateDTO
  });
};

export type PostWidgetsMutationResult = NonNullable<
  AsyncReturnType<typeof postWidgets>
>;
export type PostWidgetsMutationBody = WidgetCreateDTO;
export type PostWidgetsMutationError = ErrorType<unknown>;

export const usePostWidgets = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postWidgets>,
    TError,
    { data: WidgetCreateDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postWidgets>,
    { data: WidgetCreateDTO }
  > = props => {
    const { data } = props || {};

    return postWidgets(data);
  };

  return useMutation<
    AsyncReturnType<typeof postWidgets>,
    TError,
    { data: WidgetCreateDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Aggiorna un widget
 */
export const putWidgets = (widgetUpdateRequestDTO: WidgetUpdateRequestDTO) => {
  return apiInstance<WidgetResponseDTO | void>({
    url: `/api/v1/widgets`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: widgetUpdateRequestDTO
  });
};

export type PutWidgetsMutationResult = NonNullable<
  AsyncReturnType<typeof putWidgets>
>;
export type PutWidgetsMutationBody = WidgetUpdateRequestDTO;
export type PutWidgetsMutationError = ErrorType<unknown>;

export const usePutWidgets = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof putWidgets>,
    TError,
    { data: WidgetUpdateRequestDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof putWidgets>,
    { data: WidgetUpdateRequestDTO }
  > = props => {
    const { data } = props || {};

    return putWidgets(data);
  };

  return useMutation<
    AsyncReturnType<typeof putWidgets>,
    TError,
    { data: WidgetUpdateRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Lista dei tipi di widget filtrata per profilo
 */
export const getWidgetsTypes = () => {
  return apiInstance<WidgetType[] | void>({
    url: `/api/v1/widgets/types`,
    method: 'get'
  });
};

export const getGetWidgetsTypesQueryKey = () => [`/api/v1/widgets/types`];

export type GetWidgetsTypesQueryResult = NonNullable<
  AsyncReturnType<typeof getWidgetsTypes>
>;
export type GetWidgetsTypesQueryError = ErrorType<unknown>;

export const useGetWidgetsTypes = <
  TData = AsyncReturnType<typeof getWidgetsTypes>,
  TError = ErrorType<unknown>
>(options?: {
  query?: UseQueryOptions<
    AsyncReturnType<typeof getWidgetsTypes>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetWidgetsTypesQueryKey();

  const queryFn: QueryFunction<AsyncReturnType<typeof getWidgetsTypes>> = () =>
    getWidgetsTypes();

  const query = useQuery<
    AsyncReturnType<typeof getWidgetsTypes>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna i dati del widget identificato
 */
export const getWidgetsId = (id: number) => {
  return apiInstance<WidgetResponseDTO>({
    url: `/api/v1/widgets/${id}`,
    method: 'get'
  });
};

export const getGetWidgetsIdQueryKey = (id: number) => [
  `/api/v1/widgets/${id}`
];

export type GetWidgetsIdQueryResult = NonNullable<
  AsyncReturnType<typeof getWidgetsId>
>;
export type GetWidgetsIdQueryError = ErrorType<void>;

export const useGetWidgetsId = <
  TData = AsyncReturnType<typeof getWidgetsId>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getWidgetsId>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetWidgetsIdQueryKey(id);

  const queryFn: QueryFunction<AsyncReturnType<typeof getWidgetsId>> = () =>
    getWidgetsId(id);

  const query = useQuery<AsyncReturnType<typeof getWidgetsId>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  );

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Cancella il widget identificato
 */
export const deleteWidgetsId = (id: number) => {
  return apiInstance<void>({ url: `/api/v1/widgets/${id}`, method: 'delete' });
};

export type DeleteWidgetsIdMutationResult = NonNullable<
  AsyncReturnType<typeof deleteWidgetsId>
>;

export type DeleteWidgetsIdMutationError = ErrorType<unknown>;

export const useDeleteWidgetsId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteWidgetsId>,
    TError,
    { id: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deleteWidgetsId>,
    { id: number }
  > = props => {
    const { id } = props || {};

    return deleteWidgetsId(id);
  };

  return useMutation<
    AsyncReturnType<typeof deleteWidgetsId>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Configura un widget
 */
export const postWidgetsIdConfiguration = (
  id: number,
  configurationRequest: ConfigurationRequest
) => {
  return apiInstance<ConfigurationResponse | void>({
    url: `/api/v1/widgets/${id}/configuration`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: configurationRequest
  });
};

export type PostWidgetsIdConfigurationMutationResult = NonNullable<
  AsyncReturnType<typeof postWidgetsIdConfiguration>
>;
export type PostWidgetsIdConfigurationMutationBody = ConfigurationRequest;
export type PostWidgetsIdConfigurationMutationError = ErrorType<unknown>;

export const usePostWidgetsIdConfiguration = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postWidgetsIdConfiguration>,
    TError,
    { id: number; data: ConfigurationRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postWidgetsIdConfiguration>,
    { id: number; data: ConfigurationRequest }
  > = props => {
    const { id, data } = props || {};

    return postWidgetsIdConfiguration(id, data);
  };

  return useMutation<
    AsyncReturnType<typeof postWidgetsIdConfiguration>,
    TError,
    { id: number; data: ConfigurationRequest },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Modifica un campo della configurazione del widget
 */
export const postWidgetsIdConfigurationFieldsField = (
  id: number,
  field: string,
  configurationFieldRequest: ConfigurationFieldRequest
) => {
  return apiInstance<ConfigurationFieldResponse | void>({
    url: `/api/v1/widgets/${id}/configuration/fields/${field}`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: configurationFieldRequest
  });
};

export type PostWidgetsIdConfigurationFieldsFieldMutationResult = NonNullable<
  AsyncReturnType<typeof postWidgetsIdConfigurationFieldsField>
>;
export type PostWidgetsIdConfigurationFieldsFieldMutationBody =
  ConfigurationFieldRequest;
export type PostWidgetsIdConfigurationFieldsFieldMutationError =
  ErrorType<unknown>;

export const usePostWidgetsIdConfigurationFieldsField = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postWidgetsIdConfigurationFieldsField>,
    TError,
    { id: number; field: string; data: ConfigurationFieldRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postWidgetsIdConfigurationFieldsField>,
    { id: number; field: string; data: ConfigurationFieldRequest }
  > = props => {
    const { id, field, data } = props || {};

    return postWidgetsIdConfigurationFieldsField(id, field, data);
  };

  return useMutation<
    AsyncReturnType<typeof postWidgetsIdConfigurationFieldsField>,
    TError,
    { id: number; field: string; data: ConfigurationFieldRequest },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Copia i widget
 */
export const postWidgetsIdCopy = (
  id: number,
  widgetCopyRequestDTO: WidgetCopyRequestDTO
) => {
  return apiInstance<WidgetResponseDTO | void>({
    url: `/api/v1/widgets/${id}/copy`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: widgetCopyRequestDTO
  });
};

export type PostWidgetsIdCopyMutationResult = NonNullable<
  AsyncReturnType<typeof postWidgetsIdCopy>
>;
export type PostWidgetsIdCopyMutationBody = WidgetCopyRequestDTO;
export type PostWidgetsIdCopyMutationError = ErrorType<unknown>;

export const usePostWidgetsIdCopy = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postWidgetsIdCopy>,
    TError,
    { id: number; data: WidgetCopyRequestDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postWidgetsIdCopy>,
    { id: number; data: WidgetCopyRequestDTO }
  > = props => {
    const { id, data } = props || {};

    return postWidgetsIdCopy(id, data);
  };

  return useMutation<
    AsyncReturnType<typeof postWidgetsIdCopy>,
    TError,
    { id: number; data: WidgetCopyRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};

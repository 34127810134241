import {
  GroupOutlined,
  TeamOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';
import { Widget } from 'client/api/backend/schemas';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import React from 'react';
import {
  AssignmentModelToolkitObject,
  IAssignmentModel,
  IAssignmentModelItem
} from './AssignmentModelToolkitObject';

export function convertAssignmentModelToListElement(
  model: IAssignmentModel,
  widget: Widget,
  onNavigate: (model: IWidgetListElement) => void
): IWidgetListElement {
  return {
    name: model.label,
    description: model.assignees
      .map(assignee => getAssigneeName(assignee))
      .join(', '),
    type: 'group',
    onNavigate,
    object: AssignmentModelToolkitObject.create({
      name: model.label,
      icon: <TeamOutlined />,
      id: model.uuid,
      data: model,
      sourceWidgetId: widget.id!
    })
  };
}

/**
 * Restituisce nome e cognome se è un utente altrimenti solo il nome se è un ufficio
 */
function getAssigneeName(assignee: IAssignmentModelItem) {
  return assignee.assignee.type === 'user'
    ? `${assignee.assignee.data.name ?? ''} ${
        assignee.assignee.data.surname ?? ''
      }`
    : assignee.assignee.data.name ?? '';
}

import { PieChartOutlined } from '@ant-design/icons';
import React from 'react';
import { AssignmentType } from '../../objects/assignments/mapIdType';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetMonitorOfficeAssignmentsFilterForm } from './filters/WidgetMonitorOfficeAssignmentsFilterForm';
import { WidgetMonitorOfficeAssignments } from './WidgetMonitorOfficeAssignments';

export const WidgetMonitorOfficeAssignmentsType: IWidgetType = {
  code: 'monitor-office-assignments',
  name: 'Monitoraggio Assegnazioni Uffici',
  icon: <PieChartOutlined />,
  component: WidgetMonitorOfficeAssignments,
  filterComponent: WidgetMonitorOfficeAssignmentsFilterForm,
  defaultFilters: {
    sent: false,
    type: AssignmentType.Competenza
  },
  description: `Monitoraggio delle assegnazioni in entrata`
};

WidgetRepository.register(WidgetMonitorOfficeAssignmentsType);

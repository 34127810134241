import { useDroppable } from '@dnd-kit/core';
import { useDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { useDesk } from 'client/components/schema/desk/DeskModule';
import * as React from 'react';
import { ToolkitObject, toolkitObjectUniqId } from './ToolkitObject';
import { useDroppableToolkitObject } from './useDroppableToolkitObject';

export interface IToolkitObjectZoneProps {
  object: ToolkitObject | null;
  children: React.ReactNode;
}

/**
 * Area sulla quale effettuare il Drop di un ToolkitObject.
 */
export function ToolkitObjectZone(props: IToolkitObjectZoneProps) {
  const { object } = props;
  const deskState = useDesk();

  const {
    objectId,
    isOver,
    setNodeRef: setDroppableRef
  } = useDroppableToolkitObject(object!);

  if (!deskState.draggingItem) return null;

  return (
    <div
      className={`${isOver && 'toolkit-object-zone-active'}`}
      ref={setDroppableRef}
    >
      {props.children}
    </div>
  );
}

import React from 'react';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import { useGetWidgetsTypes } from 'client/api/backend/widgets/widgets';
import { useField } from 'formik';
import { WidgetType } from 'client/api/backend/schemas';

interface IWidgetTypeSelectInputProps extends SelectInputProps<any> {}

export function WidgetTypeSelectInput(props: IWidgetTypeSelectInputProps) {
  const widgetTypesResult = useGetWidgetsTypes();
  const [field, , helpers] = useField<WidgetType[]>(props.name);

  const onChange = (values: number[]) => {
    helpers.setValue(values.map(id => ({ id })));
  };

  return (
    <SelectInput
      allowClear
      loading={widgetTypesResult.isLoading}
      options={widgetTypesResult.data?.map(r => ({
        value: r.id!,
        label: r.name
      }))}
      value={field.value?.map(v => v.id) ?? []}
      onChange={onChange as any}
      mode="multiple"
      {...props}
    />
  );
}

import { Widget } from 'client/api/backend/schemas';
import { ToolkitObjectInput } from 'client/components/toolkit/objects/input/ToolkitObjectInput';
import { DocumentMode } from 'client/components/widget-repository/objects/documents/DocumentMode';
import { mapDocumentModeToOfficeVisibility } from 'client/components/widget-repository/objects/documents/logic/mapDocumentModeToOfficeVisibility';
import { OfficeToolkitObject } from 'client/components/widget-repository/objects/offices/OfficeToolkitObject';
import { SenderReceiverOfficeType } from 'client/components/widget-repository/objects/offices/SenderReceiverOffice';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { useField } from 'formik';
import * as React from 'react';
import { SenderReceiverOfficeInput } from './SenderReceiverOfficeInput';

export interface IDocumentProtocolOfficesFormProps {
  widget: Widget;
  disabled?: boolean;
}

export function DocumentProtocolOfficesForm(
  props: IDocumentProtocolOfficesFormProps
) {
  const { widget } = props;
  const [modeField] = useField<string | null>('mode');
  const mode = modeField.value;

  const visibilities = mapDocumentModeToOfficeVisibility(mode);

  return (
    <div>
      <FormFieldsContainer columns={2}>
        {/** Date */}
        <FormFieldItem
          colSpan={2}
          size="middle"
          component={DatePickerInput}
          name="info.dateReceived"
          type="from-utc"
          label="Data ricezione"
          placeholder="Data ricezione"
        />
        {/* {mode === DocumentMode.Internal && (
          <FormFieldItem
            size="middle"
            component={DatePickerInput}
            name="info.dateProtocol"
            type="unix"
            label="Data protocollo"
            placeholder="Data protocollo"
          />
        )} */}
        <FormFieldItem
          colSpan={2}
          size="middle"
          component={DatePickerInput}
          name="info.dateSend"
          type="from-utc"
          label="Data spedizione"
          placeholder="Data spedizione"
        />
      </FormFieldsContainer>
      {/** Uffici */}
      {visibilities.receivers && (
        <FormFieldsContainer>
          <SenderReceiverOfficeInput
            disabled
            name="info.receivers"
            type={SenderReceiverOfficeType.Receiver}
          />
        </FormFieldsContainer>
      )}
      {visibilities.senders && (
        <FormFieldsContainer>
          <SenderReceiverOfficeInput
            disabled
            name="info.senders"
            type={SenderReceiverOfficeType.Sender}
          />
        </FormFieldsContainer>
      )}
    </div>
  );
}

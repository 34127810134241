/**
 * Generato automaticamente.
 * DeskUI REST API
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  PageUserResponseDTO,
  GetUsersParams,
  UserResponseDTO,
  UserCreateDTO,
  UserUpdateDTO,
  UserProfileResponseDTO,
  PanelCopyResultDTO,
  UserPanelCopyRequestDTO
} from '.././schemas';
import {
  apiInstance,
  ErrorType
} from '../../../core/network/mutators/apiClient';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Ritorna la lista degli utenti
 */
export const getUsers = (params?: GetUsersParams) => {
  return apiInstance<PageUserResponseDTO>({
    url: `/api/v1/users`,
    method: 'get',
    params
  });
};

export const getGetUsersQueryKey = (params?: GetUsersParams) => [
  `/api/v1/users`,
  ...(params ? [params] : [])
];

export type GetUsersQueryResult = NonNullable<AsyncReturnType<typeof getUsers>>;
export type GetUsersQueryError = ErrorType<void>;

export const useGetUsers = <
  TData = AsyncReturnType<typeof getUsers>,
  TError = ErrorType<void>
>(
  params?: GetUsersParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getUsers>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetUsersQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof getUsers>> = () =>
    getUsers(params);

  const query = useQuery<AsyncReturnType<typeof getUsers>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Inserisce un utente
 */
export const postUsers = (userCreateDTO: UserCreateDTO) => {
  return apiInstance<UserResponseDTO | void>({
    url: `/api/v1/users`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: userCreateDTO
  });
};

export type PostUsersMutationResult = NonNullable<
  AsyncReturnType<typeof postUsers>
>;
export type PostUsersMutationBody = UserCreateDTO;
export type PostUsersMutationError = ErrorType<unknown>;

export const usePostUsers = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postUsers>,
    TError,
    { data: UserCreateDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postUsers>,
    { data: UserCreateDTO }
  > = props => {
    const { data } = props || {};

    return postUsers(data);
  };

  return useMutation<
    AsyncReturnType<typeof postUsers>,
    TError,
    { data: UserCreateDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Aggiorna un utente
 */
export const putUsers = (userUpdateDTO: UserUpdateDTO) => {
  return apiInstance<UserResponseDTO | void>({
    url: `/api/v1/users`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: userUpdateDTO
  });
};

export type PutUsersMutationResult = NonNullable<
  AsyncReturnType<typeof putUsers>
>;
export type PutUsersMutationBody = UserUpdateDTO;
export type PutUsersMutationError = ErrorType<unknown>;

export const usePutUsers = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof putUsers>,
    TError,
    { data: UserUpdateDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof putUsers>,
    { data: UserUpdateDTO }
  > = props => {
    const { data } = props || {};

    return putUsers(data);
  };

  return useMutation<
    AsyncReturnType<typeof putUsers>,
    TError,
    { data: UserUpdateDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Ritorna la lista degli utenti collegati
 */
export const getUsersLogged = () => {
  return apiInstance<string[]>({ url: `/api/v1/users/logged`, method: 'get' });
};

export const getGetUsersLoggedQueryKey = () => [`/api/v1/users/logged`];

export type GetUsersLoggedQueryResult = NonNullable<
  AsyncReturnType<typeof getUsersLogged>
>;
export type GetUsersLoggedQueryError = ErrorType<void>;

export const useGetUsersLogged = <
  TData = AsyncReturnType<typeof getUsersLogged>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    AsyncReturnType<typeof getUsersLogged>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetUsersLoggedQueryKey();

  const queryFn: QueryFunction<AsyncReturnType<typeof getUsersLogged>> = () =>
    getUsersLogged();

  const query = useQuery<AsyncReturnType<typeof getUsersLogged>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna il profilo dell'utente identificato
 */
export const getUsersProfile = () => {
  return apiInstance<UserProfileResponseDTO>({
    url: `/api/v1/users/profile`,
    method: 'get'
  });
};

export const getGetUsersProfileQueryKey = () => [`/api/v1/users/profile`];

export type GetUsersProfileQueryResult = NonNullable<
  AsyncReturnType<typeof getUsersProfile>
>;
export type GetUsersProfileQueryError = ErrorType<void>;

export const useGetUsersProfile = <
  TData = AsyncReturnType<typeof getUsersProfile>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    AsyncReturnType<typeof getUsersProfile>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetUsersProfileQueryKey();

  const queryFn: QueryFunction<AsyncReturnType<typeof getUsersProfile>> = () =>
    getUsersProfile();

  const query = useQuery<
    AsyncReturnType<typeof getUsersProfile>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna i dati dell'utente identificato
 */
export const getUsersId = (id: number) => {
  return apiInstance<UserResponseDTO>({
    url: `/api/v1/users/${id}`,
    method: 'get'
  });
};

export const getGetUsersIdQueryKey = (id: number) => [`/api/v1/users/${id}`];

export type GetUsersIdQueryResult = NonNullable<
  AsyncReturnType<typeof getUsersId>
>;
export type GetUsersIdQueryError = ErrorType<void>;

export const useGetUsersId = <
  TData = AsyncReturnType<typeof getUsersId>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getUsersId>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetUsersIdQueryKey(id);

  const queryFn: QueryFunction<AsyncReturnType<typeof getUsersId>> = () =>
    getUsersId(id);

  const query = useQuery<AsyncReturnType<typeof getUsersId>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  );

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Cancella l'utente identificato
 */
export const deleteUsersId = (id: number) => {
  return apiInstance<void>({ url: `/api/v1/users/${id}`, method: 'delete' });
};

export type DeleteUsersIdMutationResult = NonNullable<
  AsyncReturnType<typeof deleteUsersId>
>;

export type DeleteUsersIdMutationError = ErrorType<unknown>;

export const useDeleteUsersId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteUsersId>,
    TError,
    { id: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deleteUsersId>,
    { id: number }
  > = props => {
    const { id } = props || {};

    return deleteUsersId(id);
  };

  return useMutation<
    AsyncReturnType<typeof deleteUsersId>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Copia le scrivanie dell'utente
 */
export const postUsersIdPanelsCopy = (
  id: number,
  userPanelCopyRequestDTO: UserPanelCopyRequestDTO
) => {
  return apiInstance<PanelCopyResultDTO | void>({
    url: `/api/v1/users/${id}/panels/copy`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: userPanelCopyRequestDTO
  });
};

export type PostUsersIdPanelsCopyMutationResult = NonNullable<
  AsyncReturnType<typeof postUsersIdPanelsCopy>
>;
export type PostUsersIdPanelsCopyMutationBody = UserPanelCopyRequestDTO;
export type PostUsersIdPanelsCopyMutationError = ErrorType<unknown>;

export const usePostUsersIdPanelsCopy = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postUsersIdPanelsCopy>,
    TError,
    { id: number; data: UserPanelCopyRequestDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postUsersIdPanelsCopy>,
    { id: number; data: UserPanelCopyRequestDTO }
  > = props => {
    const { id, data } = props || {};

    return postUsersIdPanelsCopy(id, data);
  };

  return useMutation<
    AsyncReturnType<typeof postUsersIdPanelsCopy>,
    TError,
    { id: number; data: UserPanelCopyRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Reset delle scrivanie dell'utente
 */
export const getUsersIdPanelsReset = (id: number) => {
  return apiInstance<void>({
    url: `/api/v1/users/${id}/panels/reset`,
    method: 'get'
  });
};

export const getGetUsersIdPanelsResetQueryKey = (id: number) => [
  `/api/v1/users/${id}/panels/reset`
];

export type GetUsersIdPanelsResetQueryResult = NonNullable<
  AsyncReturnType<typeof getUsersIdPanelsReset>
>;
export type GetUsersIdPanelsResetQueryError = ErrorType<unknown>;

export const useGetUsersIdPanelsReset = <
  TData = AsyncReturnType<typeof getUsersIdPanelsReset>,
  TError = ErrorType<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getUsersIdPanelsReset>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetUsersIdPanelsResetQueryKey(id);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getUsersIdPanelsReset>
  > = () => getUsersIdPanelsReset(id);

  const query = useQuery<
    AsyncReturnType<typeof getUsersIdPanelsReset>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

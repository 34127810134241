import { message, Skeleton } from 'antd';
import {
  getGetDocumentaryDossiersSearchQueryKey,
  useGetDocumentaryDossiersId,
  usePostDocumentaryDossiers
} from 'client/api/backend/documentary-dossiers/documentary-dossiers';
import { useGetDocumentaryOrganizationOfficesId } from 'client/api/backend/documentary-organization/documentary-organization';
import { DeskExtendedAttributeType } from 'client/api/backend/schemas';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { omit } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { ClassificationNodeToolkitObject } from '../../objects/classifications/ClassificationNodeToolkitObject';
import { OfficeToolkitObject } from '../../objects/offices/OfficeToolkitObject';
import { useMemo, useState } from 'react';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { DossierCreateSchema } from './form/DossierCreateSchema';
import { WidgetDossierCreateForm } from './form/WidgetDossierCreateForm';
import { WidgetDossierEditProfile } from './WidgetDossierEditProfile';
import { dateInputUtils } from 'client/ui/form/input/dateInputUtils';
import {
  extendedAttributesFromPairs,
  extendedAttributesToPairs
} from '../../objects/extended-attributes/transformExtendedAttributes';
import { DeskActions } from 'client/components/schema/desk/DeskModule';
import { useDispatch } from 'react-redux';
import { useDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { queryClient } from 'client/core/network/queryClient';

export interface IWidgetDossierCreateProps
  extends IWidgetComponentToolkitProps {}

export function WidgetDossierCreate(props: IWidgetDossierCreateProps) {
  const { widget } = props;
  const create = usePostDocumentaryDossiers();
  const ctx = useDeskContext();
  const dispatch = useDispatch();

  const [dossierAttributeTypes, setDossierAttributeTypes] = useState<
    DeskExtendedAttributeType[]
  >([]);

  const profile = widget.profile?.data as WidgetDossierEditProfile | null;
  const isEdit = profile?.dossierId != null;

  const profileDossier = useGetDocumentaryDossiersId(
    profile?.dossierId!,
    { widgetId: widget.id! },
    {
      query: {
        enabled: isEdit,
        staleTime: Infinity
      }
    }
  );
  const ownerOffice = useGetDocumentaryOrganizationOfficesId(
    profileDossier.data?.idOwnerOffice!,
    { widgetId: widget.id! },
    { query: { enabled: isEdit && profileDossier.data?.idOwnerOffice != null } }
  );

  // Stato default "Aperta"
  const existingValues = useMemo(() => {
    if (!isEdit) {
      return {
        stateId: 1,
        dateOpen: dateInputUtils.toString('from', moment())
      };
    }

    const attributeValues = profileDossier.data?.extendedAttributes ?? [];

    return {
      ...profileDossier.data,
      classification: ClassificationNodeToolkitObject.create({
        id: profileDossier.data?.classification?.id!,
        name: `${profileDossier.data?.classification?.code} - ${
          profileDossier.data?.classification?.description ?? 'N.A.'
        }`,
        data: profileDossier.data?.classification!,
        sourceWidgetId: widget.id!
      }),
      localExtendedAttributes: extendedAttributesFromPairs(attributeValues),
      ownerOffice: ownerOffice.data
        ? OfficeToolkitObject.create({
            id: ownerOffice.data.id!,
            name: ownerOffice.data.name!,
            data: ownerOffice.data,
            sourceWidgetId: widget.id!
          })
        : null
    };
  }, [isEdit, profileDossier.data, ownerOffice.data]);

  if (isEdit && profileDossier.isLoading) return <Skeleton active />;

  return (
    <FormikAugmented<any>
      validationSchema={DossierCreateSchema(dossierAttributeTypes)}
      validationContext={{}}
      initialValues={existingValues}
      enableReinitialize
      onSubmit={async (values, helpers) => {
        try {
          const petitioner =
            values.petitioner?.type == null ? null : values.petitioner;

          const classification = values.classification as ToolkitObject | null;
          const ownerOffice = values.ownerOffice as ToolkitObject | null;
          const extendedAttributes = extendedAttributesToPairs(
            values.localExtendedAttributes
          );

          const result = await create.mutateAsync({
            data: {
              dossier: {
                ...omit(values, ['ownerOffice', 'localExtendedAttributes']),
                extendedAttributes,
                petitioner,
                classification: { id: classification?.id as number },
                idOwnerOffice: ownerOffice?.id as number
              },
              widgetId: widget.id
            }
          });

          // Invalido la cache
          const queryKey = getGetDocumentaryDossiersSearchQueryKey()[0];

          queryClient.invalidateQueries({
            predicate: query => {
              return Boolean(
                query.queryKey &&
                  Array.isArray(query.queryKey) &&
                  query.queryKey[0] === queryKey
              );
            }
          });

          message.success(
            `Il fascicolo ${result ? `n.${result.number} ` : ''} è stato ${
              isEdit ? 'modificato' : 'creato'
            } con successo.`
          );

          // Rimuviamo il widget dalla modalità di modifica
          if (profile?.dossierId) {
            // Disabilitiamo la modalità fullscreen
            dispatch(DeskActions.fullScreen(false));
            await ctx.remove(widget);
          }

          helpers.resetForm();
        } catch (error) {
          message.error(
            getNetworkErrorMessage(
              error,
              `Si è verificato un errore durante la ${
                isEdit ? 'modifica' : 'creazione'
              } del fascicolo.`
            )
          );
          console.error(error);
        }
      }}
    >
      <WidgetCardBody>
        <WidgetDossierCreateForm
          widget={widget}
          isEdit={isEdit}
          dossierAttributeTypes={dossierAttributeTypes}
          setDossierAttributeTypes={setDossierAttributeTypes}
        />
      </WidgetCardBody>
    </FormikAugmented>
  );
}

import { usePostDocumentaryUsersImportConfigurationIdFieldsField } from 'client/api/backend/documentary-users/documentary-users';
import {
  ConfigurationField,
  ConfigurationFieldResponse,
  ConfigurationRequest,
  ConfigurationTemplate,
  ConfigurationTemplateField,
  Widget
} from 'client/api/backend/schemas';
import { getConfigurationTemplateLabel } from 'client/components/schema/widget/configure/configuration-templates/ConfigurationTemplates';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { SelectInput, SelectOption } from 'client/ui/form/input/SelectInput';
import { useFormikContext } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

export interface IImportUsersFormFieldItemProps {
  template: ConfigurationTemplate;
  field: ConfigurationTemplateField;
  index: number;
  reloadCount: number;
  setReloadCount: (reloadCount: number) => void;
}

export function ImportUsersFormFieldItem(
  props: IImportUsersFormFieldItemProps
) {
  const { template, field, index, reloadCount } = props;

  const fieldOptionsQuery =
    usePostDocumentaryUsersImportConfigurationIdFieldsField();
  const [fieldOptions, setFieldOptions] = useState<SelectOption[]>([]);
  const formik = useFormikContext<ConfigurationRequest>();

  const fieldName = `fields.${index}.value`;

  const isInputTypeDate = field.inputType === 'date';

  /**
   * Query per ottenere le opzioni da valorizzare per il campo di selezione.
   */
  const loadFieldOptions = useCallback(async (values: ConfigurationRequest) => {
    try {
      const result = await fieldOptionsQuery.mutateAsync({
        id: formik.values.endpointId!,
        field: field.type!,
        data: values.fields?.slice(0, index) ?? []
      });

      setFieldOptions(
        result?.map(option => ({
          label: option.label!,
          value: option.value!
        })) ?? []
      );

      if (
        result?.length === 1 &&
        (!values.fields?.[index]?.value ||
          values.fields?.[index]?.value !== result[0].value)
      ) {
        formik.setFieldValue(`fields.${index}`, {
          type: field.type,
          value: result[0].value
        });
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (!isInputTypeDate) {
      loadFieldOptions(formik.values);
    }
  }, [reloadCount, formik.values.endpointId]);

  const label = getConfigurationTemplateLabel(template, field.type!);

  // TODO: Gestire in maniera apposita i tipo di componenti a seconda dell'inputType
  return isInputTypeDate ? (
    <FormFieldItem
      name={fieldName}
      component={DatePickerInput}
      type="unix"
      label={label}
      size="large"
      placeholder={label}
      skipFormChange
      onChange={async (value: any) => {
        formik.setFieldValue(`fields.${index}`, {
          type: field.type,
          value:
            value == null ? null : value.startOf('day').valueOf().toString()
        });
        props.setReloadCount(props.reloadCount + 1);
      }}
    />
  ) : (
    <FormFieldItem
      name={fieldName}
      component={SelectInput}
      label={label}
      size="large"
      placeholder={label}
      showSearch
      optionFilterProp="label"
      skipFormChange
      onChange={async (value: any) => {
        formik.setFieldValue(`fields.${index}`, { type: field.type, value });
        props.setReloadCount(props.reloadCount + 1);
      }}
      loading={fieldOptionsQuery.isLoading}
      options={fieldOptions}
    />
  );
}

import {
  getGetPanelsQueryKey,
  useDeletePanelsId,
  usePostPanels,
  usePutPanels
} from 'client/api/backend/panels/panels';
import { Panel } from 'client/api/backend/schemas';
import { queryClient } from 'client/core/network/queryClient';
import { omit } from 'lodash';
import { useCallback } from 'react';

export const DeskMarshaller = {
  marshall(desk: Panel) {
    return {
      ...omit(desk, ['profile']),
      configuration: JSON.stringify(desk.profile)
    };
  },
  unmarshall(desk: Panel): Panel {
    return {
      ...omit(desk, ['configuration']),
      profile: JSON.parse(desk.configuration ?? '{}')
    };
  }
};

/**
 * Utilizziamo un wrapper della mutation di aggiornamento e/o creazione della singola
 * scrivania per gestire la conversione in stringa del JSON di configurazione.
 */
export function useDeskMutations() {
  /** Aggiornamento */
  const updateMutation = usePutPanels({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetPanelsQueryKey());
      }
    }
  });
  const update = useCallback(
    async (data: Panel) => {
      return await updateMutation.mutateAsync({
        data: DeskMarshaller.marshall(data)
      });
    },
    [updateMutation.mutateAsync]
  );

  /** Creazione */
  const createMutation = usePostPanels({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetPanelsQueryKey());
      }
    }
  });
  const create = useCallback(
    async (data: Panel) => {
      return await createMutation.mutateAsync({
        data: DeskMarshaller.marshall(data)
      });
    },
    [createMutation.mutateAsync]
  );

  /**
   * Eliminazione
   */
  const removeMutation = useDeletePanelsId({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetPanelsQueryKey());
      }
    }
  });
  const remove = useCallback(
    async (data: Panel) => {
      return await removeMutation.mutateAsync({
        id: data.id!
      });
    },
    [removeMutation.mutateAsync]
  );

  return {
    update,
    updateState: updateMutation,
    create,
    createState: createMutation,
    remove,
    removeState: removeMutation
  };
}

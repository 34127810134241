import { Form as AntForm } from 'antd';
import React from 'react';
import { FormProps } from 'antd/lib/form';
import { useFormikContext } from 'formik';
import { get, omitBy, isNil } from 'lodash';
import { FormikFormContext, IFormikFormContext } from './FormikFormContext';
import { FormErrorsNotification } from './errors/FormErrorsNotification';
import { FormikPreventNavigation } from './prevent/FormikPreventNavigation';
// import { hasAsyncErrors } from 'client/core/validation/asyncValidationErrors';

interface Props extends FormProps, IFormikFormContext {
  disableEnterSubmit?: boolean;
  nested?: boolean;
  disabled?: boolean;
  editable?: boolean;
  /**
   * Mostra gli errori all'Invio del form e previene la navigazione
   * tramite un Prompt in caso ci siano modifiche non salvate
   */
  showVisualFeedback?: boolean;
}

const emptyHandleSubmit = (e: React.FormEvent<any>) => e.preventDefault();

/**
 * Wrapper per il Form di antd per integrarlo con Formik
 */
export function FormikForm(props: Props) {
  const {
    disableEnterSubmit,
    nested,
    hideSuccess,
    helpInTooltips,
    tooltipPlacement,
    disabled,
    children,
    editable,
    showVisualFeedback,
    ...otherProps
  } = props;

  const formik = useFormikContext();

  return (
    <AntForm
      component={nested ? 'div' : 'form'}
      onSubmitCapture={
        formik.handleSubmit
        // hasAsyncErrors(formik) ? emptyHandleSubmit : formik.handleSubmit
      }
      onKeyPress={
        disableEnterSubmit
          ? e => {
              // In caso di `Invio` disabilitiamo l'invio del Form. Se è una
              // TextArea, è comunque valido perché l'invio crea un accapo non
              // l'invio del form.
              if (
                e.key === 'Enter' &&
                !(e.target instanceof HTMLTextAreaElement)
              ) {
                e.preventDefault();
              }
            }
          : undefined
      }
      onReset={formik.handleReset}
      {...otherProps}
    >
      <FormikFormContext.Provider
        value={{
          hideSuccess: hideSuccess,
          helpInTooltips: helpInTooltips,
          tooltipPlacement: tooltipPlacement,
          disabled: disabled,
          editable: editable
        }}
      >
        <>
          {showVisualFeedback && (
            <>
              <FormErrorsNotification />
              <FormikPreventNavigation />
            </>
          )}
          {children}
        </>
      </FormikFormContext.Provider>
    </AntForm>
  );
}

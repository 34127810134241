import * as React from 'react';
import { Card, CardProps } from 'antd';
import clx from 'classnames';
import styled from 'styled-components';
import { BaseCard } from './base/BaseCard';
import { WidgetWrapper } from './base/WidgetWrapper';

export interface WidgetCardProps extends Omit<CardProps, 'bordered'> {
  ghost?: boolean;
  fullScreen?: boolean;
  /**
   * Previene la visualizzazione dei Drawer aperti nel widget corrente
   * sopra un'altro widget in modalità full screen
   */
  hideAllDrawer?: boolean;
}

/**
 * Card stilizzata del Widget
 */
export function WidgetCard(props: WidgetCardProps) {
  const { fullScreen, hideAllDrawer, ...otherProps } = props;
  return (
    <WidgetWrapper
      className={clx({
        fullscreen: props.fullScreen,
        'hide-all-drawer': props.hideAllDrawer
      })}
    >
      <BaseCard {...otherProps} />
    </WidgetWrapper>
  );
}

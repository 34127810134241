import { ToolkitObject } from '../ToolkitObject';

export class ToolkitObjectStoreBag {
  private existingIds: {
    [key: string]: Set<any>;
  } = {};

  items: ToolkitObject[] = [];

  add<T>(item: ToolkitObject<T>) {
    if (this.existingIds[item.type]?.has(item.id)) {
      // Esiste già, non lo riaggiungiamo nello store.
      return item;
    }

    this.existingIds[item.type] = this.existingIds[item.type] ?? new Set();
    this.existingIds[item.type].add(item.id);

    this.items.push(item);
    return item;
  }
}

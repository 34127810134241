import { logger } from 'client/core/logger/logger';
import { useEffect, useMemo, useState } from 'react';
import {
  IToolkitObjectFetchOptions,
  ToolkitObjectType
} from '../registerToolkitObjectType';
import { ToolkitObject } from '../ToolkitObject';

export interface ToolkitObjectTypeSearch {
  objectType: ToolkitObjectType;
  objects: ToolkitObject[];
}

/**
 * Effettua una ricerca sui ToolkitObject specificati per gestirne la
 * selezione all'interno delle azioni (ActionMessage), in caso non siano
 * presenti sulla scrivania.
 */
export function useToolkitObjectInputSearch(
  objectTypes: ToolkitObjectType[],
  options: IToolkitObjectFetchOptions
) {
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<any>();
  const [searchResults, setSearchResults] = useState<ToolkitObjectTypeSearch[]>(
    []
  );

  const searchableTypes = useMemo(
    () => objectTypes.filter(o => o.fetchObjects != null),
    [objectTypes]
  );

  useEffect(() => {
    async function load() {
      try {
        setLoading(true);
        const results = await Promise.all(
          searchableTypes.map(async objectType => ({
            objectType,
            objects: await objectType.fetchObjects!(options)
          }))
        );
        logger.log(`[ToolkitObject] Fetching objects len=${results.length} search=${options.search} wId=${options.widgetId}`); // prettier-ignore
        setSearchResults(results.filter(r => r.objects.length > 0));
      } catch (e) {
        setError(e);
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    if (searchableTypes.length > 0) {
      load();
    }
  }, [searchableTypes, options.widgetId, options.search]);

  return { searchResults, searchableTypes, error, loading };
}

import {
  CheckOutlined,
  DownOutlined,
  ProfileOutlined,
  UserOutlined
} from '@ant-design/icons';
import {
  Button,
  Drawer,
  Dropdown,
  Menu,
  message,
  notification,
  Space,
  Tooltip
} from 'antd';
import { postRolesRoleIdPanelsCopyUserId } from 'client/api/backend/roles/roles';
import { CloseOutlined, CopyOutlined } from '@ant-design/icons';
import {
  PanelCopyResultDTO,
  UserResponseDTO
} from 'client/api/backend/schemas';
import { postUsersIdPanelsCopy } from 'client/api/backend/users/users';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { AdminFormContainer } from 'client/ui/form-container/AdminFormContainer';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { assertNever } from 'common/utils/assertNever';
import { yup } from 'common/validation/initYup';
import React, { useState } from 'react';
import styled from 'styled-components';
import { DeskCopyForm } from './DeskCopyForm';
import { DeskCopyToProfileForm } from './DeskCopyToProfileForm';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { MessageParagraph } from 'client/ui/message/MessageParagraph';
import { NotificationInstance } from 'antd/lib/notification';

const CopyDeskButtonsWrapper = styled.div`
  margin: -8px 0;
  padding: 7px;
  border: 1px solid ${props => props.theme.primaryColor};
  border-radius: 4px;
`;

interface DeskCopyDTO {
  userId: number;
  userIds: number[];
}

interface DeskCopyToProfileDTO {
  userId: number;
  roleId: number;
}

enum DeskCopyType {
  User = 'users-selection',
  Profile = 'profile-selection'
}

interface ButtonDeskCopyDrawerProps {
  userSelectionVisible: boolean;
  setUserSelectionVisible: (userSelectionVisible: boolean) => void;
  selectedUsers: UserResponseDTO[];
  setSelectedUsers: (selectedUsers: UserResponseDTO[]) => void;
}

export function ButtonDeskCopyDrawer(props: ButtonDeskCopyDrawerProps) {
  const {
    userSelectionVisible,
    setUserSelectionVisible,
    selectedUsers,
    setSelectedUsers
  } = props;

  const [deskCopyDrawerVisible, setDeskCopyDrawerVisible] = useState(false);
  const [deskCopyToProfileDrawerVisible, setDeskCopyToProfileDrawerVisible] =
    useState(false);

  const handleClose = () => {
    setUserSelectionVisible(false);
    setSelectedUsers([]);
  };

  const [notificationApi, contextHolder] = notification.useNotification();

  const menuItems = [
    {
      key: DeskCopyType.User,
      icon: <UserOutlined />,
      label: 'Seleziona utenti destinatari della copia'
    },
    {
      key: DeskCopyType.Profile,
      icon: <ProfileOutlined />,
      label: 'Seleziona un profilo utente destinatario della copia'
    }
  ];

  const AddMenu = (
    <Menu
      onClick={e => {
        if (e.key === DeskCopyType.User) {
          setUserSelectionVisible(true);
        } else if (e.key === DeskCopyType.Profile) {
          setDeskCopyToProfileDrawerVisible(true);
        } else {
          console.warn(`[Desk Copy] type ${e.key} not handled`);
        }
      }}
      items={menuItems}
    />
  );

  return (
    <>
      {contextHolder}
      {userSelectionVisible ? (
        <CopyDeskButtonsWrapper>
          <Space>
            <Tooltip
              title="Seleziona almeno un utente per avviare la copia"
              visible={selectedUsers.length > 0 ? false : undefined}
            >
              <Button
                ghost
                type="primary"
                icon={<CopyOutlined />}
                onClick={() => setDeskCopyDrawerVisible(true)}
                disabled={selectedUsers.length === 0}
              >
                Avvia Copia Scrivania
              </Button>
            </Tooltip>
            <ButtonIcon
              tooltip="Annulla Copia Scrivania"
              tooltipProps={{ placement: 'topRight' }}
              icon={<CloseOutlined />}
              onClick={handleClose}
            />
          </Space>
        </CopyDeskButtonsWrapper>
      ) : (
        <Dropdown overlay={AddMenu}>
          <div>
            <Button ghost type="primary">
              <CopyOutlined />
              Copia Scrivania
              <DownOutlined />
            </Button>
          </div>
        </Dropdown>
      )}
      {/* Copia Scrivania su utenti selezionati */}
      <Drawer
        placement="bottom"
        height="70%"
        title="Copia Scrivania"
        visible={deskCopyDrawerVisible}
        onClose={() => setDeskCopyDrawerVisible(false)}
      >
        <AdminFormContainer>
          <FormikAugmented<DeskCopyDTO>
            initialValues={{} as any}
            enableReinitialize
            onSubmit={async (values, formik) => {
              try {
                const result = await postUsersIdPanelsCopy(values.userId, {
                  userIds: selectedUsers.map(user => user.id!)
                });

                showNotificationMessage(result, notificationApi);

                setDeskCopyDrawerVisible(false);
                setUserSelectionVisible(false);
                setSelectedUsers([]);
              } catch (e) {
                message.error(
                  getNetworkErrorMessage(
                    e,
                    'Si è verificato un errore durante la copia della scrivania'
                  )
                );
                return;
              }
            }}
            validationSchema={yup.object()}
            validationContext={{}}
          >
            <DeskCopyForm
              selectedUsers={selectedUsers}
              onClose={() => setDeskCopyDrawerVisible(false)}
            />
          </FormikAugmented>
        </AdminFormContainer>
      </Drawer>
      {/* Copia Scrivania su utenti con un profilo specifico */}
      <Drawer
        placement="bottom"
        height="70%"
        title="Copia Scrivania"
        visible={deskCopyToProfileDrawerVisible}
        onClose={() => setDeskCopyToProfileDrawerVisible(false)}
      >
        <AdminFormContainer>
          <FormikAugmented<DeskCopyToProfileDTO>
            initialValues={{} as any}
            enableReinitialize
            onSubmit={async (values, formik) => {
              try {
                const result = await postRolesRoleIdPanelsCopyUserId(
                  values.roleId,
                  values.userId
                );

                showNotificationMessage(result, notificationApi);

                setDeskCopyToProfileDrawerVisible(false);
              } catch (e) {
                message.error(
                  getNetworkErrorMessage(
                    e,
                    'Si è verificato un errore durante la copia della scrivania'
                  )
                );
                return;
              }
            }}
            validationSchema={yup.object()}
            validationContext={{}}
          >
            <DeskCopyToProfileForm
              onClose={() => setDeskCopyToProfileDrawerVisible(false)}
            />
          </FormikAugmented>
        </AdminFormContainer>
      </Drawer>
    </>
  );
}

function showNotificationMessage(
  result: PanelCopyResultDTO | void,
  notificationApi: NotificationInstance
) {
  if (!result) {
    return 'Copia scrivania effettuata con successo';
  }

  const description = (
    <>
      <MessageParagraph>Scrivanie copiate: {result.panels}</MessageParagraph>
      <MessageParagraph>Widget copiati: {result.widgets}</MessageParagraph>
      <MessageParagraph>
        Utenti destinatari della copia: {result.users}
      </MessageParagraph>
      <MessageParagraph>Errori riscontrati: {result.errors}</MessageParagraph>
    </>
  );

  if (result.errors && result.errors > 0) {
    return notificationApi.warning({
      message: 'Copia scrivania completata con errori',
      description,
      placement: 'top'
    });
  }

  return notificationApi.success({
    message: 'Copia scrivania completata con successo',
    description,
    placement: 'top'
  });
}

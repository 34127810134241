/**
 * Generato automaticamente.
 * DeskUI REST API
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  DeskDossier,
  DossierSaveRequestDTO,
  DeskGrant,
  GetDocumentaryDossiersAclParams,
  DeskClassificationNode,
  GetDocumentaryDossiersClassificationsParams,
  GetDocumentaryDossiersNameNameParams,
  GetDocumentaryDossiersPersonalParams,
  DeskProceeding,
  GetDocumentaryDossiersProceedingsParams,
  OptionItem,
  GetDocumentaryDossiersReferencesTypesParams,
  GetDocumentaryDossiersSearchParams,
  GetDocumentaryDossiersStatesParams,
  GetDocumentaryDossiersSubtypesParams,
  DeskExtendedType,
  GetDocumentaryDossiersSubtypesIdParams,
  GetDocumentaryDossiersTypesParams,
  DeleteDocumentaryDossiersDossierIdChildrenChildrenIdParams,
  GetDocumentaryDossiersIdParams,
  DeleteDocumentaryDossiersIdParams,
  GetDocumentaryDossiersIdAclParams,
  DeskFolder,
  GetDocumentaryDossiersIdChildrenParams,
  PostDocumentaryDossiersIdChildrenParams,
  DossierMoveDocumentRequestDTO,
  DeskEvent,
  GetDocumentaryDossiersIdEventsParams,
  DeskDocument,
  GetDocumentaryDossiersIdFilesParams,
  DossierAddDocumentRequest
} from '.././schemas';
import {
  apiInstance,
  ErrorType
} from '../../../core/network/mutators/apiClient';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Crea o aggiorna un fascicolo
 */
export const postDocumentaryDossiers = (
  dossierSaveRequestDTO: DossierSaveRequestDTO
) => {
  return apiInstance<DeskDossier | void>({
    url: `/api/v1/documentary/dossiers`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: dossierSaveRequestDTO
  });
};

export type PostDocumentaryDossiersMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryDossiers>
>;
export type PostDocumentaryDossiersMutationBody = DossierSaveRequestDTO;
export type PostDocumentaryDossiersMutationError = ErrorType<unknown>;

export const usePostDocumentaryDossiers = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryDossiers>,
    TError,
    { data: DossierSaveRequestDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryDossiers>,
    { data: DossierSaveRequestDTO }
  > = props => {
    const { data } = props || {};

    return postDocumentaryDossiers(data);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryDossiers>,
    TError,
    { data: DossierSaveRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Ritorna le ACL
 */
export const getDocumentaryDossiersAcl = (
  params?: GetDocumentaryDossiersAclParams
) => {
  return apiInstance<DeskGrant[]>({
    url: `/api/v1/documentary/dossiers/acl`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDossiersAclQueryKey = (
  params?: GetDocumentaryDossiersAclParams
) => [`/api/v1/documentary/dossiers/acl`, ...(params ? [params] : [])];

export type GetDocumentaryDossiersAclQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDossiersAcl>
>;
export type GetDocumentaryDossiersAclQueryError = ErrorType<void>;

export const useGetDocumentaryDossiersAcl = <
  TData = AsyncReturnType<typeof getDocumentaryDossiersAcl>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDossiersAclParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDossiersAcl>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryDossiersAclQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDossiersAcl>
  > = () => getDocumentaryDossiersAcl(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDossiersAcl>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Classifiche dei fascicoli
 */
export const getDocumentaryDossiersClassifications = (
  params?: GetDocumentaryDossiersClassificationsParams
) => {
  return apiInstance<DeskClassificationNode[]>({
    url: `/api/v1/documentary/dossiers/classifications`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDossiersClassificationsQueryKey = (
  params?: GetDocumentaryDossiersClassificationsParams
) => [
  `/api/v1/documentary/dossiers/classifications`,
  ...(params ? [params] : [])
];

export type GetDocumentaryDossiersClassificationsQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDossiersClassifications>
>;
export type GetDocumentaryDossiersClassificationsQueryError = ErrorType<void>;

export const useGetDocumentaryDossiersClassifications = <
  TData = AsyncReturnType<typeof getDocumentaryDossiersClassifications>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDossiersClassificationsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDossiersClassifications>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDossiersClassificationsQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDossiersClassifications>
  > = () => getDocumentaryDossiersClassifications(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDossiersClassifications>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Recupera un fascicolo per nome
 */
export const getDocumentaryDossiersNameName = (
  name: string,
  params?: GetDocumentaryDossiersNameNameParams
) => {
  return apiInstance<DeskDossier>({
    url: `/api/v1/documentary/dossiers/name/${name}`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDossiersNameNameQueryKey = (
  name: string,
  params?: GetDocumentaryDossiersNameNameParams
) => [`/api/v1/documentary/dossiers/name/${name}`, ...(params ? [params] : [])];

export type GetDocumentaryDossiersNameNameQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDossiersNameName>
>;
export type GetDocumentaryDossiersNameNameQueryError = ErrorType<void>;

export const useGetDocumentaryDossiersNameName = <
  TData = AsyncReturnType<typeof getDocumentaryDossiersNameName>,
  TError = ErrorType<void>
>(
  name: string,
  params?: GetDocumentaryDossiersNameNameParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDossiersNameName>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDossiersNameNameQueryKey(name, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDossiersNameName>
  > = () => getDocumentaryDossiersNameName(name, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDossiersNameName>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!name, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna la cartella personale
 */
export const getDocumentaryDossiersPersonal = (
  params?: GetDocumentaryDossiersPersonalParams
) => {
  return apiInstance<DeskDossier>({
    url: `/api/v1/documentary/dossiers/personal`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDossiersPersonalQueryKey = (
  params?: GetDocumentaryDossiersPersonalParams
) => [`/api/v1/documentary/dossiers/personal`, ...(params ? [params] : [])];

export type GetDocumentaryDossiersPersonalQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDossiersPersonal>
>;
export type GetDocumentaryDossiersPersonalQueryError = ErrorType<void>;

export const useGetDocumentaryDossiersPersonal = <
  TData = AsyncReturnType<typeof getDocumentaryDossiersPersonal>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDossiersPersonalParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDossiersPersonal>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryDossiersPersonalQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDossiersPersonal>
  > = () => getDocumentaryDossiersPersonal(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDossiersPersonal>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna la lista dei procedimenti
 */
export const getDocumentaryDossiersProceedings = (
  params?: GetDocumentaryDossiersProceedingsParams
) => {
  return apiInstance<DeskProceeding[]>({
    url: `/api/v1/documentary/dossiers/proceedings`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDossiersProceedingsQueryKey = (
  params?: GetDocumentaryDossiersProceedingsParams
) => [`/api/v1/documentary/dossiers/proceedings`, ...(params ? [params] : [])];

export type GetDocumentaryDossiersProceedingsQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDossiersProceedings>
>;
export type GetDocumentaryDossiersProceedingsQueryError = ErrorType<void>;

export const useGetDocumentaryDossiersProceedings = <
  TData = AsyncReturnType<typeof getDocumentaryDossiersProceedings>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDossiersProceedingsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDossiersProceedings>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDossiersProceedingsQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDossiersProceedings>
  > = () => getDocumentaryDossiersProceedings(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDossiersProceedings>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna le tipologie di riferimenti
 */
export const getDocumentaryDossiersReferencesTypes = (
  params?: GetDocumentaryDossiersReferencesTypesParams
) => {
  return apiInstance<OptionItem[]>({
    url: `/api/v1/documentary/dossiers/references/types`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDossiersReferencesTypesQueryKey = (
  params?: GetDocumentaryDossiersReferencesTypesParams
) => [
  `/api/v1/documentary/dossiers/references/types`,
  ...(params ? [params] : [])
];

export type GetDocumentaryDossiersReferencesTypesQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDossiersReferencesTypes>
>;
export type GetDocumentaryDossiersReferencesTypesQueryError = ErrorType<void>;

export const useGetDocumentaryDossiersReferencesTypes = <
  TData = AsyncReturnType<typeof getDocumentaryDossiersReferencesTypes>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDossiersReferencesTypesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDossiersReferencesTypes>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDossiersReferencesTypesQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDossiersReferencesTypes>
  > = () => getDocumentaryDossiersReferencesTypes(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDossiersReferencesTypes>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ricerca dossiers
 */
export const getDocumentaryDossiersSearch = (
  params?: GetDocumentaryDossiersSearchParams
) => {
  return apiInstance<DeskDossier[]>({
    url: `/api/v1/documentary/dossiers/search`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDossiersSearchQueryKey = (
  params?: GetDocumentaryDossiersSearchParams
) => [`/api/v1/documentary/dossiers/search`, ...(params ? [params] : [])];

export type GetDocumentaryDossiersSearchQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDossiersSearch>
>;
export type GetDocumentaryDossiersSearchQueryError = ErrorType<void>;

export const useGetDocumentaryDossiersSearch = <
  TData = AsyncReturnType<typeof getDocumentaryDossiersSearch>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDossiersSearchParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDossiersSearch>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryDossiersSearchQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDossiersSearch>
  > = () => getDocumentaryDossiersSearch(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDossiersSearch>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Stati dei fascicoli
 */
export const getDocumentaryDossiersStates = (
  params?: GetDocumentaryDossiersStatesParams
) => {
  return apiInstance<OptionItem[]>({
    url: `/api/v1/documentary/dossiers/states`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDossiersStatesQueryKey = (
  params?: GetDocumentaryDossiersStatesParams
) => [`/api/v1/documentary/dossiers/states`, ...(params ? [params] : [])];

export type GetDocumentaryDossiersStatesQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDossiersStates>
>;
export type GetDocumentaryDossiersStatesQueryError = ErrorType<void>;

export const useGetDocumentaryDossiersStates = <
  TData = AsyncReturnType<typeof getDocumentaryDossiersStates>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDossiersStatesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDossiersStates>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryDossiersStatesQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDossiersStates>
  > = () => getDocumentaryDossiersStates(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDossiersStates>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna la lista sottotipi di fascicolo
 */
export const getDocumentaryDossiersSubtypes = (
  params?: GetDocumentaryDossiersSubtypesParams
) => {
  return apiInstance<OptionItem[]>({
    url: `/api/v1/documentary/dossiers/subtypes`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDossiersSubtypesQueryKey = (
  params?: GetDocumentaryDossiersSubtypesParams
) => [`/api/v1/documentary/dossiers/subtypes`, ...(params ? [params] : [])];

export type GetDocumentaryDossiersSubtypesQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDossiersSubtypes>
>;
export type GetDocumentaryDossiersSubtypesQueryError = ErrorType<void>;

export const useGetDocumentaryDossiersSubtypes = <
  TData = AsyncReturnType<typeof getDocumentaryDossiersSubtypes>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDossiersSubtypesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDossiersSubtypes>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryDossiersSubtypesQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDossiersSubtypes>
  > = () => getDocumentaryDossiersSubtypes(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDossiersSubtypes>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna il singolo sottotipo di fascicolo
 */
export const getDocumentaryDossiersSubtypesId = (
  id: number,
  params?: GetDocumentaryDossiersSubtypesIdParams
) => {
  return apiInstance<DeskExtendedType>({
    url: `/api/v1/documentary/dossiers/subtypes/${id}`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDossiersSubtypesIdQueryKey = (
  id: number,
  params?: GetDocumentaryDossiersSubtypesIdParams
) => [
  `/api/v1/documentary/dossiers/subtypes/${id}`,
  ...(params ? [params] : [])
];

export type GetDocumentaryDossiersSubtypesIdQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDossiersSubtypesId>
>;
export type GetDocumentaryDossiersSubtypesIdQueryError = ErrorType<void>;

export const useGetDocumentaryDossiersSubtypesId = <
  TData = AsyncReturnType<typeof getDocumentaryDossiersSubtypesId>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetDocumentaryDossiersSubtypesIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDossiersSubtypesId>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDossiersSubtypesIdQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDossiersSubtypesId>
  > = () => getDocumentaryDossiersSubtypesId(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDossiersSubtypesId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna i tipi di fascicolo
 */
export const getDocumentaryDossiersTypes = (
  params?: GetDocumentaryDossiersTypesParams
) => {
  return apiInstance<OptionItem[]>({
    url: `/api/v1/documentary/dossiers/types`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDossiersTypesQueryKey = (
  params?: GetDocumentaryDossiersTypesParams
) => [`/api/v1/documentary/dossiers/types`, ...(params ? [params] : [])];

export type GetDocumentaryDossiersTypesQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDossiersTypes>
>;
export type GetDocumentaryDossiersTypesQueryError = ErrorType<void>;

export const useGetDocumentaryDossiersTypes = <
  TData = AsyncReturnType<typeof getDocumentaryDossiersTypes>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDossiersTypesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDossiersTypes>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryDossiersTypesQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDossiersTypes>
  > = () => getDocumentaryDossiersTypes(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDossiersTypes>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Cancella una cartella di un fascicolo
 */
export const deleteDocumentaryDossiersDossierIdChildrenChildrenId = (
  dossierId: number,
  childrenId: number,
  params?: DeleteDocumentaryDossiersDossierIdChildrenChildrenIdParams
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/dossiers/${dossierId}/children/${childrenId}`,
    method: 'delete',
    params
  });
};

export type DeleteDocumentaryDossiersDossierIdChildrenChildrenIdMutationResult =
  NonNullable<
    AsyncReturnType<typeof deleteDocumentaryDossiersDossierIdChildrenChildrenId>
  >;

export type DeleteDocumentaryDossiersDossierIdChildrenChildrenIdMutationError =
  ErrorType<unknown>;

export const useDeleteDocumentaryDossiersDossierIdChildrenChildrenId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<
      typeof deleteDocumentaryDossiersDossierIdChildrenChildrenId
    >,
    TError,
    {
      dossierId: number;
      childrenId: number;
      params?: DeleteDocumentaryDossiersDossierIdChildrenChildrenIdParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<
      typeof deleteDocumentaryDossiersDossierIdChildrenChildrenId
    >,
    {
      dossierId: number;
      childrenId: number;
      params?: DeleteDocumentaryDossiersDossierIdChildrenChildrenIdParams;
    }
  > = props => {
    const { dossierId, childrenId, params } = props || {};

    return deleteDocumentaryDossiersDossierIdChildrenChildrenId(
      dossierId,
      childrenId,
      params
    );
  };

  return useMutation<
    AsyncReturnType<
      typeof deleteDocumentaryDossiersDossierIdChildrenChildrenId
    >,
    TError,
    {
      dossierId: number;
      childrenId: number;
      params?: DeleteDocumentaryDossiersDossierIdChildrenChildrenIdParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Recupera un fascicolo
 */
export const getDocumentaryDossiersId = (
  id: number,
  params?: GetDocumentaryDossiersIdParams
) => {
  return apiInstance<DeskDossier>({
    url: `/api/v1/documentary/dossiers/${id}`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDossiersIdQueryKey = (
  id: number,
  params?: GetDocumentaryDossiersIdParams
) => [`/api/v1/documentary/dossiers/${id}`, ...(params ? [params] : [])];

export type GetDocumentaryDossiersIdQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDossiersId>
>;
export type GetDocumentaryDossiersIdQueryError = ErrorType<void>;

export const useGetDocumentaryDossiersId = <
  TData = AsyncReturnType<typeof getDocumentaryDossiersId>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetDocumentaryDossiersIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDossiersId>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryDossiersIdQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDossiersId>
  > = () => getDocumentaryDossiersId(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDossiersId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Cancella un fascicolo
 */
export const deleteDocumentaryDossiersId = (
  id: number,
  params?: DeleteDocumentaryDossiersIdParams
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/dossiers/${id}`,
    method: 'delete',
    params
  });
};

export type DeleteDocumentaryDossiersIdMutationResult = NonNullable<
  AsyncReturnType<typeof deleteDocumentaryDossiersId>
>;

export type DeleteDocumentaryDossiersIdMutationError = ErrorType<unknown>;

export const useDeleteDocumentaryDossiersId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteDocumentaryDossiersId>,
    TError,
    { id: number; params?: DeleteDocumentaryDossiersIdParams },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deleteDocumentaryDossiersId>,
    { id: number; params?: DeleteDocumentaryDossiersIdParams }
  > = props => {
    const { id, params } = props || {};

    return deleteDocumentaryDossiersId(id, params);
  };

  return useMutation<
    AsyncReturnType<typeof deleteDocumentaryDossiersId>,
    TError,
    { id: number; params?: DeleteDocumentaryDossiersIdParams },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Ritorna le ACL di un fascicolo
 */
export const getDocumentaryDossiersIdAcl = (
  id: number,
  params?: GetDocumentaryDossiersIdAclParams
) => {
  return apiInstance<DeskGrant[]>({
    url: `/api/v1/documentary/dossiers/${id}/acl`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDossiersIdAclQueryKey = (
  id: number,
  params?: GetDocumentaryDossiersIdAclParams
) => [`/api/v1/documentary/dossiers/${id}/acl`, ...(params ? [params] : [])];

export type GetDocumentaryDossiersIdAclQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDossiersIdAcl>
>;
export type GetDocumentaryDossiersIdAclQueryError = ErrorType<void>;

export const useGetDocumentaryDossiersIdAcl = <
  TData = AsyncReturnType<typeof getDocumentaryDossiersIdAcl>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetDocumentaryDossiersIdAclParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDossiersIdAcl>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDossiersIdAclQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDossiersIdAcl>
  > = () => getDocumentaryDossiersIdAcl(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDossiersIdAcl>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Recupera le sottocartelle del fascicolo
 */
export const getDocumentaryDossiersIdChildren = (
  id: number,
  params?: GetDocumentaryDossiersIdChildrenParams
) => {
  return apiInstance<DeskFolder[]>({
    url: `/api/v1/documentary/dossiers/${id}/children`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDossiersIdChildrenQueryKey = (
  id: number,
  params?: GetDocumentaryDossiersIdChildrenParams
) => [
  `/api/v1/documentary/dossiers/${id}/children`,
  ...(params ? [params] : [])
];

export type GetDocumentaryDossiersIdChildrenQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDossiersIdChildren>
>;
export type GetDocumentaryDossiersIdChildrenQueryError = ErrorType<void>;

export const useGetDocumentaryDossiersIdChildren = <
  TData = AsyncReturnType<typeof getDocumentaryDossiersIdChildren>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetDocumentaryDossiersIdChildrenParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDossiersIdChildren>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDossiersIdChildrenQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDossiersIdChildren>
  > = () => getDocumentaryDossiersIdChildren(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDossiersIdChildren>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Crea una cartella in un fascicolo
 */
export const postDocumentaryDossiersIdChildren = (
  id: number,
  params?: PostDocumentaryDossiersIdChildrenParams
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/dossiers/${id}/children`,
    method: 'post',
    params
  });
};

export type PostDocumentaryDossiersIdChildrenMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryDossiersIdChildren>
>;

export type PostDocumentaryDossiersIdChildrenMutationError = ErrorType<unknown>;

export const usePostDocumentaryDossiersIdChildren = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryDossiersIdChildren>,
    TError,
    { id: number; params?: PostDocumentaryDossiersIdChildrenParams },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryDossiersIdChildren>,
    { id: number; params?: PostDocumentaryDossiersIdChildrenParams }
  > = props => {
    const { id, params } = props || {};

    return postDocumentaryDossiersIdChildren(id, params);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryDossiersIdChildren>,
    TError,
    { id: number; params?: PostDocumentaryDossiersIdChildrenParams },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Sposta una cartella in un fascicolo
 */
export const postDocumentaryDossiersIdChildrenMove = (
  id: number,
  dossierMoveDocumentRequestDTO: DossierMoveDocumentRequestDTO
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/dossiers/${id}/children/move`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: dossierMoveDocumentRequestDTO
  });
};

export type PostDocumentaryDossiersIdChildrenMoveMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryDossiersIdChildrenMove>
>;
export type PostDocumentaryDossiersIdChildrenMoveMutationBody =
  DossierMoveDocumentRequestDTO;
export type PostDocumentaryDossiersIdChildrenMoveMutationError =
  ErrorType<unknown>;

export const usePostDocumentaryDossiersIdChildrenMove = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryDossiersIdChildrenMove>,
    TError,
    { id: number; data: DossierMoveDocumentRequestDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryDossiersIdChildrenMove>,
    { id: number; data: DossierMoveDocumentRequestDTO }
  > = props => {
    const { id, data } = props || {};

    return postDocumentaryDossiersIdChildrenMove(id, data);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryDossiersIdChildrenMove>,
    TError,
    { id: number; data: DossierMoveDocumentRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Ritorna gli eventi di un fascicolo
 */
export const getDocumentaryDossiersIdEvents = (
  id: number,
  params?: GetDocumentaryDossiersIdEventsParams
) => {
  return apiInstance<DeskEvent[]>({
    url: `/api/v1/documentary/dossiers/${id}/events`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDossiersIdEventsQueryKey = (
  id: number,
  params?: GetDocumentaryDossiersIdEventsParams
) => [`/api/v1/documentary/dossiers/${id}/events`, ...(params ? [params] : [])];

export type GetDocumentaryDossiersIdEventsQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDossiersIdEvents>
>;
export type GetDocumentaryDossiersIdEventsQueryError = ErrorType<void>;

export const useGetDocumentaryDossiersIdEvents = <
  TData = AsyncReturnType<typeof getDocumentaryDossiersIdEvents>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetDocumentaryDossiersIdEventsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDossiersIdEvents>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDossiersIdEventsQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDossiersIdEvents>
  > = () => getDocumentaryDossiersIdEvents(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDossiersIdEvents>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Recupera i files di una cartella di fascicolo
 */
export const getDocumentaryDossiersIdFiles = (
  id: number,
  params?: GetDocumentaryDossiersIdFilesParams
) => {
  return apiInstance<DeskDocument[]>({
    url: `/api/v1/documentary/dossiers/${id}/files`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDossiersIdFilesQueryKey = (
  id: number,
  params?: GetDocumentaryDossiersIdFilesParams
) => [`/api/v1/documentary/dossiers/${id}/files`, ...(params ? [params] : [])];

export type GetDocumentaryDossiersIdFilesQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDossiersIdFiles>
>;
export type GetDocumentaryDossiersIdFilesQueryError = ErrorType<void>;

export const useGetDocumentaryDossiersIdFiles = <
  TData = AsyncReturnType<typeof getDocumentaryDossiersIdFiles>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetDocumentaryDossiersIdFilesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDossiersIdFiles>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDossiersIdFilesQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDossiersIdFiles>
  > = () => getDocumentaryDossiersIdFiles(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDossiersIdFiles>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Fascicola documenti
 */
export const postDocumentaryDossiersIdFiles = (
  id: number,
  dossierAddDocumentRequest: DossierAddDocumentRequest
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/dossiers/${id}/files`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: dossierAddDocumentRequest
  });
};

export type PostDocumentaryDossiersIdFilesMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryDossiersIdFiles>
>;
export type PostDocumentaryDossiersIdFilesMutationBody =
  DossierAddDocumentRequest;
export type PostDocumentaryDossiersIdFilesMutationError = ErrorType<unknown>;

export const usePostDocumentaryDossiersIdFiles = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryDossiersIdFiles>,
    TError,
    { id: number; data: DossierAddDocumentRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryDossiersIdFiles>,
    { id: number; data: DossierAddDocumentRequest }
  > = props => {
    const { id, data } = props || {};

    return postDocumentaryDossiersIdFiles(id, data);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryDossiersIdFiles>,
    TError,
    { id: number; data: DossierAddDocumentRequest },
    TContext
  >(mutationFn, mutationOptions);
};

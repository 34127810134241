import React from 'react';
import { FolderAddOutlined, UserOutlined } from '@ant-design/icons';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import {
  getToolkitObjectOfType,
  ToolkitObject
} from 'client/components/toolkit/objects/ToolkitObject';
import { DeskDossier, DeskFolder } from 'client/api/backend/schemas';
import { FolderToolkitObject } from 'client/components/widget-repository/objects/folders/FolderToolkitObject';
import { PersonalDossierToolkitObject } from '../../../personal-dossier/objects/PersonalDossierToolkitObject';
import { DossierToolkitObject } from 'client/components/widget-repository/objects/dossiers/DossierToolkitObject';
import { yup } from 'common/validation/initYup';
import { InferType } from 'yup';
import {
  getGetDocumentaryDossiersIdChildrenQueryKey,
  postDocumentaryDossiersIdChildren
} from 'client/api/backend/documentary-dossiers/documentary-dossiers';
import { queryClient } from 'client/core/network/queryClient';
import { message } from 'antd';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextInput } from 'client/ui/form/input/TextInput';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

export const WidgetActionAddSubfolder = createToolkitAction({
  name: 'Crea Sottofascicolo',
  code: 'add-subfolder',
  icon: <FolderAddOutlined />,
  arguments: [
    {
      name: 'Cartella',
      type: [
        FolderToolkitObject,
        PersonalDossierToolkitObject,
        DossierToolkitObject
      ]
    }
  ],
  async execute(ctx: IToolkitActionContext, folderObject: ToolkitObject) {
    const folder: ToolkitObject<DeskFolder | DeskDossier> =
      getToolkitObjectOfType(folderObject, [
        FolderToolkitObject.code,
        PersonalDossierToolkitObject.code,
        DossierToolkitObject.code
      ])!;

    ctx.deskContext.actionMessages.prompt({
      ctx,
      args: [folder],
      action: WidgetActionAddSubfolder,
      confirmText: 'Crea',
      schema: AddSubfolderSchema,
      onConfirm,
      form: (
        <>
          <FormFieldsContainer>
            <FormFieldItem
              size="large"
              component={TextInput}
              label={null}
              name="folderName"
              placeholder="Nome della cartella"
            />
          </FormFieldsContainer>
        </>
      )
    });
  }
});

/**
 * Aggiunge la sottocartella, gestendo dinamicamente il caso in cui sia
 * la cartella personale oppure un `Folder` o un `Dossier`.
 */
async function onConfirm(
  ctx: IToolkitActionContext,
  values: AddSubfolderSchemaType,
  folder: ToolkitObject
) {
  try {
    await createSubfolder(values, folder);

    message.success('Sottocartella creata con successo');
  } catch (error) {
    message.error(
      getNetworkErrorMessage(error, 'Impossibile creare la cartella')
    );
    console.error(error);
    return false;
  }
}

async function createSubfolder(
  values: AddSubfolderSchemaType,
  folder: ToolkitObject
) {
  await postDocumentaryDossiersIdChildren(
    // TODO: In realtà dovremmo usare l'ID fascicolo, ma per Folium
    // questo parametro non è rilevante
    folder.id as number,
    {
      folderName: values.folderName,
      parentId: folder.id as number,
      widgetId: folder.sourceWidgetId
    }
  );

  // Invalido la cache
  const queryKey = getGetDocumentaryDossiersIdChildrenQueryKey(
    folder.id as number
  )[0];

  queryClient.invalidateQueries({
    predicate: query => {
      return Boolean(
        query.queryKey &&
          Array.isArray(query.queryKey) &&
          query.queryKey[0] === queryKey
      );
    }
  });
}

const AddSubfolderSchema = yup.object({
  folderName: yup.string().required().label('Nome Sottocartella')
});

type AddSubfolderSchemaType = InferType<typeof AddSubfolderSchema>;

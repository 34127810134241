import * as React from 'react';
import { Layout } from 'react-grid-layout';
import { useDeskContext } from '../../desk/context/DeskContext';
import {
  calculateGridPosition,
  IGridParams
} from '../../desk/space/grid/GridPositioner';
import { WidgetCard } from '../card/WidgetCard';
import { WidgetAddPlusIcon } from './WidgetAddPlusIcon';

export interface IWidgetAddPlaceholderBaseProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'slot'> {
  slot: Layout;
}

export function WidgetAddPlaceholderBase(
  props: IWidgetAddPlaceholderBaseProps
) {
  const deskContext = useDeskContext();
  return (
    <WidgetCard
      ghost
      children={<WidgetAddPlusIcon />}
      onClick={() => {
        deskContext.addWidget.dispatch({
          type: 'set-position',
          position: {
            type: 'grid',
            x: props.slot.x,
            y: props.slot.y,
            w: props.slot.w,
            h: props.slot.h,
            minW: props.slot.minW!,
            minH: props.slot.minH!
          }
        });
      }}
    />
  );
}

export interface IWidgetAddPlaceholderProps
  extends IWidgetAddPlaceholderBaseProps {
  gridParams: IGridParams;
}

export const WidgetAddPlaceholder = React.forwardRef<
  HTMLDivElement,
  IWidgetAddPlaceholderProps
>((props: IWidgetAddPlaceholderProps, ref) => {
  const { slot, gridParams, ...divProps } = props;
  const { x, y, width, height } = calculateGridPosition(gridParams, slot);

  return (
    <div
      {...divProps}
      ref={ref}
      // Supportiamo le stesse animazioni di react-grid-layout
      className="react-grid-item cssTransforms"
      style={{
        position: 'absolute',
        transform: `translate(${x}px, ${y}px)`,
        width,
        height
      }}
    >
      <WidgetAddPlaceholderBase slot={slot} />
      {divProps.children}
    </div>
  );
});

import * as React from 'react';
import SyncOutlined from '@ant-design/icons/lib/icons/SyncOutlined';
import { ButtonIcon, ButtonIconProps } from 'client/ui/button/ButtonIcon';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { DeleteOutlined } from '@ant-design/icons';
import { PopconfirmProps } from 'antd';

export interface CloseFloatButtonProps
  extends Omit<ButtonIconProps, 'icon' | 'onClick'> {
  onClick?: PopconfirmProps['onConfirm'];
  skipConfirm?: boolean;
}

/**
 * Pulsante sincronizzare manualmente il Widget al backend
 */
export function CloseFloatButton(props: CloseFloatButtonProps) {
  const { onClick, skipConfirm, ...rest } = props;
  return (
    <ButtonDeleteConfirm
      title={
        <span>
          Sei sicuro di voler eliminare
          <br />
          il Widget da questa scrivania?
        </span>
      }
      placement="left"
      cancelText="Annulla"
      onConfirm={onClick}
      buttonType="default"
      skipConfirm={skipConfirm}
    >
      <ButtonIcon
        // danger
        icon={skipConfirm ? <CloseOutlined /> : <DeleteOutlined />}
        {...rest}
      />
    </ButtonDeleteConfirm>
  );
}

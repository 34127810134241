import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { AuditOutlined } from '@ant-design/icons';
import { WidgetMailProtocolAssignment } from './WidgetMailProtocolAssignment';
import { WidgetActionProtocolAndAssignMail } from './actions/WidgetActionProtocolAndAssignMail';

export const WidgetMailProtocolAssignmentType: IWidgetType = {
  code: 'mail-protocol-assignment',
  name: 'Protocolla e assegna mail',
  isHidden: true,
  icon: <AuditOutlined />,
  component: WidgetMailProtocolAssignment,
  description: `Protocollazione e assegnazione della mail`,
  actions: [WidgetActionProtocolAndAssignMail]
};

WidgetRepository.register(WidgetMailProtocolAssignmentType);

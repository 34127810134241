import { postDocumentaryAssignmentsMassiveSingle } from 'client/api/backend/documentary-assignments/documentary-assignments';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { AssignmentToolkitObject } from 'client/components/widget-repository/objects/assignments/AssignmentToolkitObject';
import { DocumentToolkitObject } from 'client/components/widget-repository/objects/documents/DocumentToolkitObject';
import { OfficeToolkitObject } from 'client/components/widget-repository/objects/offices/OfficeToolkitObject';
import { UserToolkitObject } from 'client/components/widget-repository/objects/users/UserToolkitObject';
import { InferType } from 'yup';
import { AssignSchema } from '../schema/AssignSchema';

/**
 * Funzione che si occupa di chiamare la API per l'assegnazione di un documento
 */
export async function executeAssignDocument(
  values: InferType<typeof AssignSchema>,
  assignee: ToolkitObject,
  entityToAssignObjects: ToolkitObject[] | ToolkitObject
) {
  const documents = DocumentToolkitObject.getMany(entityToAssignObjects)!;
  const assignments = AssignmentToolkitObject.getMany(entityToAssignObjects);

  const assignBody = documents.map((document, i) => ({
    assignment: {
      document: {
        id: document.data.id
      },
      assegnee: {
        id:
          assignee!.type === UserToolkitObject.code ? assignee!.data.id : null,
        officeId:
          assignee!.type === OfficeToolkitObject.code ? assignee!.data.id : null
      },
      idType: values.modeId,
      idPriority: values.priorityId,
      expireDate: values.expiresAt?.toString(),
      note: values.notes
    },
    widgetId: assignee!.sourceWidgetId,
    widgetIdFrom: document.sourceWidgetId,
    // Se presente prendo l'id dell'assegnazione che sto riassegnando altrimenti
    // lo prendo dai values. Questo perchè se sto riassegnando un documento
    // l'id dell'assegnazione precedente viene ottenuto tramite `checkIfAssignmentPossible`
    // e inserito nei values
    fromAssignmentId:
      assignments[i]?.data.id ??
      (values.fromAssignmentIds[i] as number | undefined)
  }));

  await postDocumentaryAssignmentsMassiveSingle(assignBody);
}

import * as React from 'react';
import { useMemo, useState } from 'react';
import { GetDocumentaryActivityParams } from 'client/api/backend/schemas';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import { WidgetList } from 'client/components/toolkit/components/list/WidgetList';
import { PaddedSkeleton } from 'client/ui/skeleton/PaddedSkeleton';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import {
  InfiniteActivitiesSearchPageStart,
  useInfiniteActivitiesSearch
} from './api/useInfiniteActivitiesSearch';
import { WidgetActivitiesSearchForm } from './filters/WidgetActivitiesSearchForm';
import { useWidgetNetworkQueries } from 'client/components/schema/widget/context/useWidgetNetworkEffect';
import { convertActivityToWidgetListElement } from '../activity-viewer/convert/convertActivityToWidgetListElement';

const ActivitiesPageSize = 20;

export interface IWidgetActivitiesSearchProps
  extends IWidgetComponentToolkitProps {}

export function WidgetActivitiesSearch(props: IWidgetActivitiesSearchProps) {
  const { widget } = props;

  // Ricerca locale, per la durata della sessione.
  const [search, setSearch] = useState<Partial<GetDocumentaryActivityParams>>(
    {}
  );
  const apiSearch = useMemo(
    () => ({
      ...search,
      pageNumber: InfiniteActivitiesSearchPageStart,
      pageSize: ActivitiesPageSize,
      widgetId: widget.id!
    }),
    [search, widget.profile?.filters]
  );

  const activitiesResult = useInfiniteActivitiesSearch(apiSearch, {
    query: {
      getNextPageParam: (lastPage, pages) =>
        lastPage.length < ActivitiesPageSize
          ? undefined
          : pages.length + InfiniteActivitiesSearchPageStart
    }
  });

  const activities = useMemo<IWidgetListElement[]>(() => {
    if (!activitiesResult.data) return [];

    return activitiesResult.data.pages
      .flat()
      .map(activity => convertActivityToWidgetListElement(activity, widget));
  }, [activitiesResult.data]);

  useWidgetNetworkQueries([activitiesResult]);

  return (
    <WidgetCardBody
      top={
        <WidgetActivitiesSearchForm
          widget={widget}
          search={search}
          setSearch={setSearch}
        />
      }
    >
      <PaddedSkeleton loading={activitiesResult.isLoading} active>
        <WidgetList
          list={activities}
          infinite={{ query: activitiesResult }}
          emptyMessage="Nessun Atto Disponibile"
        />
      </PaddedSkeleton>
    </WidgetCardBody>
  );
}

import * as React from 'react';
import styled from 'styled-components';

export interface WidgetStepPanelProps {
  children: React.ReactNode;
  /** Nome del Step */
  title: string;
  /** Icona da mostrare con tooltips in caso ci sia poco spazio  */
  icon?: React.ReactNode;
  /** Key per il Map */
  stepKey: string;
}
/**
 * Elemento contenitore di uno step di uno WidgetSteps
 */
export function WidgetStepPanel(props: WidgetStepPanelProps) {
  return <>{props.children}</>;
}

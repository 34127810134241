import { DocumentMode } from '../DocumentMode';

export function mapDocumentModeToOfficeVisibility(mode: string | null) {
  switch (mode) {
    //  entrambi per modalità Interna e Registrazione
    // I = INTERNA
    case DocumentMode.Internal:
    // R = REGISTRAZIONE
    case DocumentMode.Registration:
      return {
        senders: true,
        receivers: true
      };

    // E = ENTRATA
    // solo "Uffici Destinatari" per Entrata
    case DocumentMode.Incoming:
      return {
        senders: false,
        receivers: true
      };

    // U = USCITA
    // solo "Ufficio Mittente" per Uscita
    case DocumentMode.Outgoing:
      return {
        senders: true,
        receivers: false
      };

    default: {
      return {
        senders: true,
        receivers: true
      };
    }
  }
}

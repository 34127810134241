import * as React from 'react';
import { ButtonIcon, ButtonIconProps } from 'client/ui/button/ButtonIcon';
import { CgArrowsExpandDownRight, CgArrowsExpandUpLeft } from 'react-icons/cg';
import styled from 'styled-components';

const StyleButtonPrimary = styled(ButtonIcon)`
  &.ant-btn-primary[disabled] {
    background-color: transparent;
    border-color: transparent;
    color: #fff;
    opacity: 0.5;
  }
`;

export interface ExpandFloatButtonProps extends Omit<ButtonIconProps, 'icon'> {
  widgetExpanded?: boolean;
}

/**
 * Pulsante per espandere e ridurre il floating Widget
 */
export function ExpandFloatButton(props: ExpandFloatButtonProps) {
  const { widgetExpanded, ...othersProps } = props;

  return (
    <StyleButtonPrimary
      icon={
        props.widgetExpanded ? (
          <CgArrowsExpandDownRight />
        ) : (
          <CgArrowsExpandUpLeft />
        )
      }
      {...othersProps}
    />
  );
}

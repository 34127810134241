import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Tree } from 'antd';

export const StyledTree = styled(Tree)`
  .ant-tree-node-content-wrapper {
    padding: 0;
    display: flex;
  }
  .ant-tree-switcher-leaf-line {
    &:before {
      right: 14px;
      border-color: ${({ theme }) => theme.borderColorBase};
    }
    &:after {
      left: 14px;
      height: 14px;
      border-color: ${({ theme }) => theme.borderColorBase};
    }
  }
  .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
    height: 14px !important;
  }
`;

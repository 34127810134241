export enum ApiParamsType {
  'LivelloInvaso' = 'LivelloInvaso',
  'VolumeInvaso' = 'VolumeInvaso',
  'PortataScaricata' = 'PortataScaricata',
  'PortataAdduzione' = 'PortataAdduzione',
  'PortataDerivata' = 'PortataDerivata'
}

export enum ConstantDamParamsType {
  'QuotaMax' = 'quotaMax',
  'QuotaMaxRegulation' = 'quotaMaxRegulation'
}

export enum GraphViewType {
  'livelloInvaso' = 'livelloInvaso',
  'volumeInvaso' = 'volumeInvaso',
  'portataScaricata' = 'portataScaricata',
  'portataAdduzione' = 'portataAdduzione',
  'portataDerivata' = 'portataDerivata'
}

export function getParamColumnLabel(param: string) {
  switch (param) {
    case ApiParamsType.LivelloInvaso:
      return 'Livello Invaso (mslm)';
    case ApiParamsType.VolumeInvaso:
      return 'Volume Invaso (Mm3)';
    case ApiParamsType.PortataScaricata:
      return 'Portata Scaricata (m3/s)';
    case ApiParamsType.PortataAdduzione:
      return 'Portata Adduzione (m3/s)';
    case ApiParamsType.PortataDerivata:
      return 'Portata Derivata (m3/s)';
    case ConstantDamParamsType.QuotaMax:
      return 'Quota Max Invaso (mslm)';
    case ConstantDamParamsType.QuotaMaxRegulation:
      return 'Quota Max Regolazione (mslm)';
    default:
      return param;
  }
}

/**
 * Indica le configurazioni disponibili per visualizzare i parametri delle osservazioni di una diga.
 * Non è possibili visualizzare più di due parametri con unità di misura diverse.
 */
export const GraphViewConfigs = [
  {
    label: 'Livello Invaso',
    parameters: [GraphViewType.livelloInvaso]
  },
  { label: 'Volume Invaso', parameters: [GraphViewType.volumeInvaso] },
  {
    label: 'Portate',
    parameters: [
      GraphViewType.portataScaricata,
      GraphViewType.portataAdduzione,
      GraphViewType.portataDerivata
    ]
  },
  {
    label: 'Portate | Livello Invaso',
    parameters: [
      GraphViewType.portataScaricata,
      GraphViewType.portataAdduzione,
      GraphViewType.portataDerivata,
      GraphViewType.livelloInvaso
    ]
  },
  {
    label: 'Portate | Volume Invaso',
    parameters: [
      GraphViewType.portataScaricata,
      GraphViewType.portataAdduzione,
      GraphViewType.portataDerivata,
      GraphViewType.volumeInvaso
    ]
  },
  {
    label: 'Livello Invaso | Volume Invaso',
    parameters: [GraphViewType.livelloInvaso, GraphViewType.volumeInvaso]
  }
];

export const GraphViewSelectOptions = GraphViewConfigs.map((info, index) => ({
  label: info.label,
  value: index
}));

export const GraphViewParameters = {
  [GraphViewType.livelloInvaso]: {
    label: 'Livello Invaso',
    unit: 'mslm',
    codeApi: ApiParamsType.LivelloInvaso,
    hasConstantParameters: true
  },
  [GraphViewType.volumeInvaso]: {
    label: 'Volume Invaso',
    unit: 'Mm3',
    codeApi: ApiParamsType.VolumeInvaso
  },
  [GraphViewType.portataScaricata]: {
    label: 'Portata Scaricata',
    unit: 'm3/s',
    codeApi: ApiParamsType.PortataScaricata
  },
  [GraphViewType.portataAdduzione]: {
    label: 'Portata Adduzione',
    unit: 'm3/s',
    codeApi: ApiParamsType.PortataAdduzione
  },
  [GraphViewType.portataDerivata]: {
    label: 'Portata Derivata',
    unit: 'm3/s',
    codeApi: ApiParamsType.PortataDerivata
  }
};

export const ConstantGraphViewParameters = {
  [ConstantDamParamsType.QuotaMax]: {
    label: 'Quota Max Invaso',
    unit: 'mslm',
    codeApi: ConstantDamParamsType.QuotaMax
  },
  [ConstantDamParamsType.QuotaMaxRegulation]: {
    label: 'Quota Max Regolazione',
    unit: 'mslm',
    codeApi: ConstantDamParamsType.QuotaMaxRegulation
  }
};

export interface IGraphViewParameter {
  label: string;
  unit: string;
  codeApi: string;
  /**
   * Indica se ci sono valori costanti associati al parametro da visualizzare.
   */
  hasConstantParameters?: boolean;
}

import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';

export interface ISenderReceiverOffice {
  office?: ToolkitObject;
  mode?: SenderReceiverOfficeMode;
  /** informazione utile per la gestione disabilitazione in modifica protocollo */
  justAdded?: boolean;
}

export enum SenderReceiverOfficeType {
  Sender = 'sender',
  Receiver = 'receiver'
}

export enum SenderReceiverOfficeMode {
  Competenza = 'competenza',
  Conoscenza = 'conoscenza'
}

import { ColumnType } from 'antd/lib/table';
import { AlignType } from 'rc-table/lib/interface';
import { DeskDamObservation } from 'client/api/backend/schemas';
import { WidgetTable } from 'client/components/toolkit/components/table/WidgetTable';
import moment from 'moment';
import React from 'react';
import { getParamColumnLabel } from '../../objects/dams/DamParams';
import {
  convertObservationsToTableData,
  IObservationParameterValue
} from './convert/convertObservationsToTableData';

interface IWidgetDamMonitoringTableProps {
  data?: DeskDamObservation[];
  isLoading: boolean;
}

function monitoringColumns(
  parameters: string[]
): ColumnType<IObservationParameterValue>[] {
  const values = parameters.map((param, index) => {
    return {
      title: getParamColumnLabel(param),
      dataIndex: param,
      key: `${param}${index}`,
      align: 'right' as AlignType,
      render: (value: string, record: IObservationParameterValue) => {
        if (record[param] == null) {
          return '-';
        }
        return value;
      }
    };
  });

  return [
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      render: (date: number) => {
        return moment(date).format('DD/MM/YYYY HH:mm');
      }
    },
    ...values
  ];
}

export function WidgetDamMonitoringTable(
  props: IWidgetDamMonitoringTableProps
) {
  const { data, isLoading } = props;

  if (!data) {
    return null;
  }

  const parametersSet = new Set(data.map(d => d.parameter ?? ''));
  const parameters = Array.from(parametersSet);

  const convertedObservations = Array.from(
    convertObservationsToTableData(data).values()
  ).sort((a, b) => (a.date ?? 0) - (b.date ?? 0));

  return (
    <WidgetTable
      dataSource={convertedObservations}
      columns={monitoringColumns(parameters)}
      showHeader={true}
      pagination={false}
      loading={isLoading}
    />
  );
}

import { resolvePublicPath } from 'client/core/appConfig';
import { theme } from 'client/ui/theme/theme';
import React from 'react';
import styled from 'styled-components';

/**
 * Placeholder per il logo principale dell'applicativo
 *
 * Le dimensioni e il file del logo sono definite in configurazione.
 */

const LogoImg = styled.img`
  width: ${props => props.theme.loginLogoWidth};
  max-width: 100%;
  height: auto;
  max-height: ${props => props.theme.loginLogoHeight};
  border: none;
  margin: 0;
  padding: 0;
  vertical-align: top;
  /* filter: brightness(0) invert(1); */
`;

const LogoWrapper = styled.div`
  max-width: ${props => props.theme.loginLogoWidth};
  max-height: ${props => props.theme.loginLogoHeight};
  margin-bottom: 2em;
`;

export interface LoginLogoProps {
  alt?: string;
  ariaLabel?: string;
  className?: string;
}

/**
 * Logo proncipale dell'applicativo.
 * Le dimensioni e il file del logo sono definite in configurazione.
 */
export function LoginLogo(props: LoginLogoProps) {
  return (
    <LogoWrapper className={props.className}>
      <LogoImg
        src={resolvePublicPath(theme.loginLogoUrl)}
        alt={props.alt ?? 'Logo'}
        aria-label={props.ariaLabel ?? 'Logo'}
      />
    </LogoWrapper>
  );
}

import { MailOutlined, PaperClipOutlined } from '@ant-design/icons';
import { useGetDocumentaryMailsId } from 'client/api/backend/documentary-mails/documentary-mails';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { WidgetCardBodyTabs } from 'client/components/schema/widget/card/body/WidgetCardBodyTabs';
import { TabsSideMenu } from 'client/components/toolkit/components/tabs/TabsSideMenu';
import { WidgetTabPanel } from 'client/components/toolkit/components/tabs/WidgetTabPanel';
import { useRegisterToolkitObject } from 'client/components/toolkit/objects/store/useToolkitObjectStore';
import { ToolkitObjectBottomZone } from 'client/components/toolkit/objects/ToolkitObjectBottomZone';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { PaddedSkeleton } from 'client/ui/skeleton/PaddedSkeleton';
import React, { useState } from 'react';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { convertMailToAttachmentMenuItems } from './convert/convertMailToAttachmentMenuItems';
import { WidgetMailAttachment } from './WidgetMailAttachment';
import { WidgetMailBody } from './WidgetMailBody';
import { WidgetMailViewerProfile } from './WidgetMailViewerProfile';

export interface IWidgetMailViewerProps extends IWidgetComponentToolkitProps {}

export function WidgetMailViewer(props: IWidgetMailViewerProps) {
  const { widget } = props;
  const data = widget.profile?.data as WidgetMailViewerProfile | undefined;

  // Drag&drop
  const widgetObject = WidgetToolkitObject.from(widget);
  useRegisterToolkitObject(widgetObject);

  const mailResult = useGetDocumentaryMailsId(
    data?.mailId!,
    { widgetId: widget.id! },
    {
      query: {
        enabled: data?.mailId != null
      }
    }
  );

  const attachments = convertMailToAttachmentMenuItems(mailResult.data);

  const [selectedAttachmentKey, setSelectedAttachment] =
    useState<Maybe<string>>(null);

  const selectedKey =
    selectedAttachmentKey ?? (attachments?.[0]?.key as string);

  return (
    <WidgetCardBody
      scrollable={false}
      bottom={<ToolkitObjectBottomZone object={widgetObject} />}
    >
      <WidgetCardBodyTabs>
        <WidgetTabPanel
          title="Intestazione mail"
          icon={<MailOutlined />}
          type="main"
          tabKey="main"
          padded
        >
          <PaddedSkeleton loading={mailResult.isLoading} active>
            <WidgetMailBody mail={mailResult.data} />
          </PaddedSkeleton>
        </WidgetTabPanel>
        <WidgetTabPanel
          title="Allegati mail"
          icon={<PaperClipOutlined />}
          tabKey="attachments"
          scrollable={false}
        >
          <WidgetMailAttachment
            widgetId={widget.id!}
            attachments={mailResult.data?.attachments}
            selectedKey={selectedKey}
          />
        </WidgetTabPanel>
        <WidgetTabPanel
          title="Allegati"
          icon={<PaperClipOutlined />}
          type="side-menu"
          tabKey="side-menu"
        >
          <TabsSideMenu
            items={attachments}
            defaultSelectedKeys={selectedKey ? [selectedKey] : []}
            onClick={e => setSelectedAttachment(e.key)}
          />
        </WidgetTabPanel>
      </WidgetCardBodyTabs>
    </WidgetCardBody>
  );
}

import { Dropdown, DropdownProps, Menu } from 'antd';
import {
  IDeskContext,
  useDeskContext
} from 'client/components/schema/desk/context/DeskContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { WidgetRepository } from 'client/components/widget-repository/WidgetRepository';
import * as React from 'react';
import {
  ActionObjectInput,
  isToolkitActionSupportedBy
} from './isToolkitActionSupportedBy';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import {
  AppstoreOutlined,
  MenuOutlined,
  MoreOutlined,
  ThunderboltOutlined
} from '@ant-design/icons';
import { ToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { partition } from 'lodash';
import { useMemo } from 'react';
import { useWidgetRepository } from 'client/components/widget-repository/useWidgetRepository';

const ICON_ACTIONS_MENU =
  process.env.THEME_ICON_ACTIONS_MENU === 'menu' ? (
    <MenuOutlined />
  ) : (
    <MoreOutlined />
  );

export interface IToolkitObjectActionsMenuProps
  extends Omit<DropdownProps, 'overlay'> {
  object: Maybe<ActionObjectInput>;
  children?: React.ReactNode;
}

/**
 * Mostra l'insieme delle azioni disponibili a partire dal toolkit object
 * selezionato, sotto forma di Menù.
 */
export function ToolkitObjectActionsMenu(
  props: IToolkitObjectActionsMenuProps
) {
  const { object, children, ...otherProps } = props;

  if (!object) return null;

  const deskContext = useDeskContext();
  const widgetRepository = useWidgetRepository();
  const firstObject = Array.isArray(object) ? object[0] : object; // TODO un po' debole? In ogni caso il `sourceWidget` è comunque uno solo

  const sourceWidget = deskContext.widgets.find(
    w => w.id! === firstObject?.sourceWidgetId
  );

  const ctx: IToolkitActionContext = { deskContext, sourceWidget };

  const actions = useMemo(
    () =>
      widgetRepository.actions
        .filter(action => isToolkitActionSupportedBy(action, object))
        .map(action => mapActionToMenuItem(action, ctx, object)),
    [object, deskContext.widgets]
  );

  const internalActions = useMemo(
    () =>
      widgetRepository
        .findInternalActions(sourceWidget?.profile?.typeCode)
        ?.filter(action => isToolkitActionSupportedBy(action, object))
        .map(action => mapActionToMenuItem(action, ctx, object)) ?? [],
    [object, deskContext.widgets]
  );

  // Non mostriamo il menù se non ci sono elementi
  if (actions.length < 1 && !internalActions?.length) return null;

  const [preferentialActions, menuActions] = splitPreferentials(actions);
  const [preferentialInternalActions, menuInternalActions] =
    splitPreferentials(internalActions);

  const menu = (
    <Menu
      items={[
        ...menuActions,
        ...(menuInternalActions?.length && menuActions?.length
          ? [{ type: 'divider' as const, key: 'divider-internal' }]
          : []),
        ...(menuInternalActions ?? [])
      ]}
    />
  );

  const areMenuActionsPresent =
    menuActions.length > 0 || menuInternalActions.length > 0;

  return (
    <>
      {[...preferentialActions, ...preferentialInternalActions].map(a => (
        <ButtonIcon icon={a.icon} tooltip={a.label} onClick={a.onClick} />
      ))}
      {areMenuActionsPresent && (
        <Dropdown overlay={menu} placement="bottomRight" {...otherProps}>
          {children ?? <ButtonIcon size="small" icon={ICON_ACTIONS_MENU} />}
        </Dropdown>
      )}
    </>
  );
}

function splitPreferentials(actions: ActionMenuItem[]) {
  const [preferentialActions, menuActions] = partition(
    actions,
    a => a.isPreferential
  );

  return [preferentialActions, menuActions];
}

function mapActionToMenuItem(
  action: ToolkitAction<any>,
  ctx: IToolkitActionContext,
  object: ToolkitObject[] | ToolkitObject | null | undefined
) {
  return {
    key: action.code,
    icon: action.icon ?? <ThunderboltOutlined />,
    label: <span>{action.name}</span>,
    isPreferential: action.isPreferential,
    onClick: () => action.execute(ctx, object)
  };
}

type ActionMenuItem = ReturnType<typeof mapActionToMenuItem>;

/**
 * Generato automaticamente.
 * DeskUI REST API
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type { Documentary } from '.././schemas';
import {
  apiInstance,
  ErrorType
} from '../../../core/network/mutators/apiClient';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Ritorna la lista dei documentali presenti
 */
export const getDocumentaries = () => {
  return apiInstance<Documentary[]>({
    url: `/api/v1/documentaries`,
    method: 'get'
  });
};

export const getGetDocumentariesQueryKey = () => [`/api/v1/documentaries`];

export type GetDocumentariesQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaries>
>;
export type GetDocumentariesQueryError = ErrorType<void>;

export const useGetDocumentaries = <
  TData = AsyncReturnType<typeof getDocumentaries>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    AsyncReturnType<typeof getDocumentaries>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetDocumentariesQueryKey();

  const queryFn: QueryFunction<AsyncReturnType<typeof getDocumentaries>> = () =>
    getDocumentaries();

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaries>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna i dati del documentale identificato
 */
export const getDocumentariesId = (id: number) => {
  return apiInstance<Documentary>({
    url: `/api/v1/documentaries/${id}`,
    method: 'get'
  });
};

export const getGetDocumentariesIdQueryKey = (id: number) => [
  `/api/v1/documentaries/${id}`
];

export type GetDocumentariesIdQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentariesId>
>;
export type GetDocumentariesIdQueryError = ErrorType<void>;

export const useGetDocumentariesId = <
  TData = AsyncReturnType<typeof getDocumentariesId>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentariesId>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetDocumentariesIdQueryKey(id);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentariesId>
  > = () => getDocumentariesId(id);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentariesId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

import {
  GetDocumentaryDossiersSearchParams,
  Widget
} from 'client/api/backend/schemas';
import { applyDynamicDateRange } from 'client/components/toolkit/components/filter/dynamic/DynamicDateRanges';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';

/**
 * Intefaccia specifica dei filtri "di default" impostati da questo widget
 */
export interface IWidgetDossierSearchFilterData
  extends Pick<
    GetDocumentaryDossiersSearchParams,
    'subject' | 'description' | 'typeId' | 'subtypeId' | 'stateId' | 'owner'
  > {
  dynamicDateOpenFrom: string;
  dynamicDateOpenTo: string;
  dynamicDateClosedFrom: string;
  dynamicDateClosedTo: string;
  classification: ToolkitObject<any>;
}

export function applyWidgetDossierSearchDefaultFilters(
  widget: Widget
): Partial<GetDocumentaryDossiersSearchParams> {
  const filters = widget.profile
    ?.filters as IWidgetDossierSearchFilterData | null;
  if (!filters) return {};

  const {
    dynamicDateOpenFrom,
    dynamicDateOpenTo,
    dynamicDateClosedFrom,
    dynamicDateClosedTo,
    classification,
    ...standardParams
  } = filters;

  return {
    ...standardParams,
    classificationId: classification?.id as number,
    dateOpenFrom: applyDynamicDateRange(dynamicDateOpenFrom)?.valueOf(),
    dateOpenTo: applyDynamicDateRange(dynamicDateOpenTo)?.valueOf(),
    dateCloseFrom: applyDynamicDateRange(dynamicDateClosedFrom)?.valueOf(),
    dateCloseTo: applyDynamicDateRange(dynamicDateClosedTo)?.valueOf()
  };
}

import { BookOutlined } from '@ant-design/icons';
import { ToolkitObjectActionsMenu } from 'client/components/schema/widget/actions/interaction/ToolkitObjectActionsMenu';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import { WidgetList } from 'client/components/toolkit/components/list/WidgetList';
import { useRegisterToolkitObject } from 'client/components/toolkit/objects/store/useToolkitObjectStore';
import { ToolkitObjectBottomZone } from 'client/components/toolkit/objects/ToolkitObjectBottomZone';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { StyledMenuBar } from 'client/ui/menu-bar/StyledMenuBar';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { convertBookmarkToWidgetListElement } from './convert/convertBookmarkToWidgetListElement';
import { WidgetBookmarksProfile } from './WidgetBookmarksProfile';

export interface IWidgetBookmarksProps extends IWidgetComponentToolkitProps {}

/**
 * Preferiti
 */
export function WidgetBookmarks(props: IWidgetBookmarksProps) {
  const { widget } = props;
  const data = widget.profile?.data as WidgetBookmarksProfile | null;

  const bookmarks = useMemo<IWidgetListElement[]>(() => {
    if (!data?.bookmarks) return [];

    return data.bookmarks.map(b =>
      convertBookmarkToWidgetListElement(b, widget)
    );
  }, [data]);

  const widgetObject = WidgetToolkitObject.from(widget);

  useRegisterToolkitObject(widgetObject);

  return (
    <WidgetCardBody
      top={
        <StyledMenuBar>
          <ToolkitObjectActionsMenu object={widgetObject} />
        </StyledMenuBar>
      }
      bottom={<ToolkitObjectBottomZone object={widgetObject} />}
    >
      <WidgetList list={bookmarks} emptyMessage="Nessun preferito" />
    </WidgetCardBody>
  );
}

import {
  ConfigurationResponse,
  ConfigurationTemplate
} from 'client/api/backend/schemas';
import { get } from 'lodash';

export const ConfigurationTemplates = {
  'folium-multiente': {
    'doc.ente': 'Ente',
    'doc.ufficio': 'Ufficio',
    'doc.aoo': 'AOO',
    'doc.role': 'Ruolo',
    'date.from': 'Creato dopo il',
    'date.to': 'Creato prima del'
  },
  folium: {
    'doc.ufficio': 'Ufficio',
    'doc.aoo': 'AOO',
    'doc.role': 'Ruolo',
    'date.from': 'Creato dopo il',
    'date.to': 'Creato prima del'
  }
};

/**
 * Ottiene l'etichetta di un template di configurazione.
 * Per il momento sono gestiti staticamente.
 */
export function getConfigurationTemplateLabel(
  configurationTemplate: ConfigurationTemplate,
  field: string
) {
  if (!configurationTemplate) return field;

  const templateName =
    configurationTemplate.type as keyof typeof ConfigurationTemplates;

  const template = ConfigurationTemplates[templateName];
  if (!template) {
    console.error(`Nessun template trovato con codice "${templateName}"`);
    return field;
  }

  return get(template, field, `Campo ${field}`);
}

import { Empty, Table, TableProps } from 'antd';
import { tableLoaderSpin } from 'client/ui/loader/tableLoaderSpin';
import React from 'react';
import clx from 'classnames';

export interface WidgetTableProps<T> extends Omit<TableProps<T>, 'loading'> {
  loading?: boolean;
}

export function WidgetTable<T extends object>(props: WidgetTableProps<T>) {
  const { loading, dataSource, ...otherProps } = props;

  if (loading) {
    return <Table<T> loading={tableLoaderSpin(loading)} dataSource={[]} />;
  }

  if (dataSource == null || dataSource.length === 0) {
    return (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Nessun dato'} />
    );
  }

  return (
    <Table<T>
      loading={tableLoaderSpin(loading)}
      dataSource={dataSource}
      rowKey="id"
      size="small"
      {...otherProps}
      // className={clx(otherProps.className, {})}
    />
  );
}

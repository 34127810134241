import { FileOutlined, PaperClipOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
import { DeskDocument } from 'client/api/backend/schemas';
import React from 'react';

export function convertDocumentToAttachmentMenuItems(
  document?: DeskDocument
): MenuProps['items'] {
  const principal = [
    {
      key: `principal`,
      icon: <FileOutlined />,
      label: 'Documento Principale'
    }
  ];

  const attachments: MenuProps['items'] = document?.attachments?.map(
    (attachment, index) => ({
      key: attachment.id!,
      icon: <PaperClipOutlined />,
      label: attachment.name ?? `Allegato #${attachment.id}`
    })
  );

  if (attachments && attachments.length > 0) {
    return [
      ...principal,
      {
        key: 'divider',
        type: 'divider'
      },
      ...attachments
    ];
  }

  return [...principal];
}

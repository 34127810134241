import * as React from 'react';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextInput } from 'client/ui/form/input/TextInput';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { MailBoxesSelectInput } from './MailBoxesSelectInput';
import { DynamicDateRanges } from 'client/components/toolkit/components/filter/dynamic/DynamicDateRanges';

export interface IWidgetMailSearchFilterFormProps {
  widgetId: number;
  /** Di base è `profile.filters`. */
  prefix: string;
  filters: any;
}

export function WidgetMailsSearchFilterForm(
  props: IWidgetMailSearchFilterFormProps
) {
  const { filters, prefix } = props;

  return (
    <>
      <FormFieldsContainer>
        <FormFieldItem
          size="large"
          component={TextInput}
          label={'Parola chiave'}
          name={`${prefix}.keyword`}
          placeholder="Parola chiave"
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={2} gutter={8}>
        <FormFieldItem
          size="large"
          component={SelectInput}
          allowClear
          label={'Da..'}
          name={`${prefix}.dynamicBeginDate`}
          placeholder="Da.."
          options={DynamicDateRanges}
        />
        <FormFieldItem
          size="large"
          component={SelectInput}
          allowClear
          label={'A..'}
          name={`${prefix}.dynamicEndDate`}
          placeholder="A.."
          options={DynamicDateRanges}
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormFieldItem
          size="large"
          component={MailBoxesSelectInput}
          allowClear
          label={'Casella'}
          name={`${prefix}.mailboxId`}
          placeholder="Casella"
          widgetId={props.widgetId}
        />
      </FormFieldsContainer>
    </>
  );
}

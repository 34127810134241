import * as React from 'react';
import clx from 'classnames';
import styled from 'styled-components';
import { ControlOutlined } from '@ant-design/icons/lib/icons';
import { Typography, Space, Tooltip, ButtonProps } from 'antd';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { Widget } from 'client/api/backend/schemas';
import { useDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { WidgetRepository } from 'client/components/widget-repository/WidgetRepository';
import { useWidgetContext } from 'client/components/schema/widget/context/WidgetContext';

const { Text, Title } = Typography;

const Message = styled(Text)`
  font-size: 12px;
  padding-left: 2px;
`;

const FilterLineWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const FilterButton = styled.div`
  flex: 1 1 auto;
  min-width: 0;
`;
const DefaultFilterButton = styled.div`
  flex: 0 0 auto;
  min-width: 0;
`;

export interface WidgetFilterButtonBarProps
  extends Omit<ButtonProps, 'children' | 'icon'> {
  icon: React.ReactNode;
  filters?: React.ReactNode[];
  rightButton?: React.ReactNode;
}

/**
 * //TODO
 */
export function WidgetFilterButtonBar(props: WidgetFilterButtonBarProps) {
  const { filters, rightButton, ...othersProp } = props;

  const { widget } = useWidgetContext();
  const { configureWidget } = useDeskContext();
  const widgetType = WidgetRepository.fromWidget(widget);

  return (
    <FilterLineWrapper>
      <FilterButton>
        <ButtonIcon {...othersProp} />
        {(filters?.length ?? 0) < 1 && (
          <Message type="secondary">Nessun filtro applicato</Message>
        )}
        {filters?.map((filter, i) => (
          <span key={i}>{filter}</span>
        ))}
      </FilterButton>
      <DefaultFilterButton>
        {widgetType?.filterComponent && (
          <Tooltip
            title="Modifica i filtri di default del widget"
            placement="left"
          >
            <ButtonIcon
              icon={<ControlOutlined />}
              onClick={() =>
                configureWidget.dispatch({
                  type: 'open-configuration',
                  widget: widget!
                })
              }
            />
          </Tooltip>
        )}
        {rightButton}
      </DefaultFilterButton>
    </FilterLineWrapper>
  );
}

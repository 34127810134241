import * as React from 'react';
import { DeskAssignment } from 'client/api/backend/schemas';
import { convertDocumentToHistoryItems } from '../convert/convertDocumentToHistoryItems';
import { DocumentHistoryTimeline } from './DocumentHistoryTimeline';

export interface DocumentHistoryProps {
  documentAssignments?: DeskAssignment[];
}

/**
 * Contenuto del Tab Cronologia con la cronologia dei documenti
 */
export function DocumentHistory(props: DocumentHistoryProps) {
  const { documentAssignments } = props;
  if (!documentAssignments) {
    return null;
  }

  const assignmentHistory = convertDocumentToHistoryItems(documentAssignments);

  return <DocumentHistoryTimeline assignmentHistory={assignmentHistory} />;
}

import { Button, ButtonProps, Space } from 'antd';
import { useDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import * as React from 'react';

export interface IActionMessageButtonsProps {
  id: string;
  confirmText?: string;
  confirmProps?: ButtonProps;
}

export function ActionMessageButtons(props: IActionMessageButtonsProps) {
  const { id, confirmText, confirmProps } = props;
  const { state, close } = useDeskContext().actionMessages;

  const handleClose = () => close(id);

  return (
    <Space>
      <Button onClick={handleClose}>Annulla</Button>
      <FormikSendButton type="primary" {...confirmProps}>
        {confirmText ?? 'Conferma'}
      </FormikSendButton>
    </Space>
  );
}

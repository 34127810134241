import { FolderOutlined, PieChartOutlined } from '@ant-design/icons';
import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetMonitorAssignments } from '../monitor-assignments/WidgetMonitorAssignments';

export const WidgetMonitorSentAssignmentsType: IWidgetType = {
  code: 'monitor-sent-assignments',
  name: 'Monitoraggio Assegnazioni Inviate',
  icon: <PieChartOutlined />,
  component: WidgetMonitorAssignments,
  description: `Monitoraggio delle assegnazioni in uscita`
};

WidgetRepository.register(WidgetMonitorSentAssignmentsType);

import { UserDto } from 'common/dto/UserDto';

export class UserLogic {
  static canLogout(user: UserDto | null) {
    return true;
  }

  static getDisplayName(user: UserDto | null) {
    if (!user) return '';

    if (user.name && user.surname) {
      return `${user.name} ${user.surname}`;
    }
    return user.username;
  }
}

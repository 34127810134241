import { HighlightOutlined } from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import { useGetDocumentaryAssignments } from 'client/api/backend/documentary-assignments/documentary-assignments';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { useWidgetNetworkQueries } from 'client/components/schema/widget/context/useWidgetNetworkEffect';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import { WidgetList } from 'client/components/toolkit/components/list/WidgetList';
import { useRegisterToolkitObject } from 'client/components/toolkit/objects/store/useToolkitObjectStore';
import { ToolkitObjectBottomZone } from 'client/components/toolkit/objects/ToolkitObjectBottomZone';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { FilterWrapper } from 'client/ui/filter/FilterWrapper';
import { FvIconUserAssignmentHightLight } from 'client/ui/icon/FvIcons';
import { PaddedSkeleton } from 'client/ui/skeleton/PaddedSkeleton';
import React, { useMemo, useState } from 'react';
import { IHighlightedNotesSearch } from '../../objects/highlighted/IHighlightedNotesSearch';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import {
  InfiniteAssignmentsSearchPageStart,
  useInfiniteAssignmentsSearch
} from '../assignments/api/useInfiniteAssignmentSearch';
import { convertHighlightedAssignmentToWidgetListElement } from './convert/convertHighlightedAssignmentToListElement';
import { WidgetHighlightedAssignmentsProfile } from './WidgetHighlightedAssignmentsProfile';

export interface IWidgetHighlightedAssignmentsProps
  extends IWidgetComponentToolkitProps {}

// Al momento la paginazione non funziona lato BE se la ricerca è filtrata per id
const AssignmentsPageSize = 50;
const AssignmentsPageStart = 0;

/**
 * Assegnazioni in evidenza
 */
export function WidgetHighlightedAssignments(
  props: IWidgetHighlightedAssignmentsProps
) {
  const { widget } = props;
  const [search, setSearch] = useState<IHighlightedNotesSearch>({});
  const data = widget.profile
    ?.data as WidgetHighlightedAssignmentsProfile | null;

  const assignmentsResult = useGetDocumentaryAssignments(
    {
      ids: data?.assignments?.map(a => a.id!),
      pageNumber: AssignmentsPageStart,
      pageSize: AssignmentsPageSize,
      widgetId: widget.id!
    },
    {
      query: {
        enabled: !!data?.assignments?.length,
        keepPreviousData: true
      }
    }
  );

  const assignments = useMemo<IWidgetListElement[]>(() => {
    if (!assignmentsResult.data) return [];

    return assignmentsResult.data
      ?.map(assignment => {
        const localAssignment = data?.assignments?.find(
          a => a.id === assignment.idSearched
        );
        if (!localAssignment) return null;

        return convertHighlightedAssignmentToWidgetListElement(
          assignment,
          localAssignment!,
          widget
        );
      })
      .filter((a): a is IWidgetListElement => a != null);
  }, [assignmentsResult.data, widget]);

  const filteredAssignments = useMemo<IWidgetListElement[]>(() => {
    if (!search.text) return assignments;
    const searchText = search.text!.toLowerCase();

    return assignments.filter(a =>
      (a.details as string)?.toLowerCase().includes(searchText)
    );
  }, [search.text, assignments]);

  const highlightedObject = WidgetToolkitObject.from(widget);
  useRegisterToolkitObject(highlightedObject);

  useWidgetNetworkQueries([assignmentsResult]);

  return (
    <WidgetCardBody
      top={
        <FilterWrapper>
          <Search
            allowClear
            placeholder="Cerca tra le note..."
            enterButton
            onSearch={text => setSearch(s => ({ ...s, text }))}
          />
        </FilterWrapper>
      }
      bottom={<ToolkitObjectBottomZone object={highlightedObject} />}
    >
      <PaddedSkeleton active loading={assignmentsResult.isLoading}>
        <WidgetList
          list={filteredAssignments}
          emptyMessage="Nessuna assegnazione in evidenza"
        />
      </PaddedSkeleton>
    </WidgetCardBody>
  );
}

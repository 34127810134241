import { postDocumentaryActivityComplete } from 'client/api/backend/documentary-activity/documentary-activity';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { ActivityToolkitObject } from 'client/components/widget-repository/objects/activities/ActivityToolkitObject';
import { invalidateActivityQueries } from '../invalidateActivityQueries';

interface IExecuteCompleteActivityValues {
  type: string;
  note?: string;
}

/**
 * Funzione che si occupa della chiamata alla API per il completamento delle attività
 */
export async function executeCompleteActivity(
  activityObject: ToolkitObject,
  values: IExecuteCompleteActivityValues
) {
  const activity = ActivityToolkitObject.get(activityObject);

  const type = values.type;
  const note = values?.note;

  await postDocumentaryActivityComplete(
    {
      code: activity?.data.code,
      id: activity?.data.id,
      documentId: activity?.data.documentId,
      type: type,
      note: note
    },
    { widgetId: activity?.sourceWidgetId! }
  );

  // Invalido la cache
  invalidateActivityQueries();
}

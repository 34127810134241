import { UserOutlined } from '@ant-design/icons';
import { FvIconUserAssignmentHightLight } from 'client/ui/icon/FvIcons';
import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetActionAnnotateHighlightedAssignment } from './actions/WidgetActionAnnotateHighlightedAssignment';
import { WidgetActionDeleteHighlightedAssignment } from './actions/WidgetActionDeleteHighlighedAssignment';
import { WidgetActionHighlightAssignment } from './actions/WidgetActionHighlightAssignment';
import { WidgetHighlightedAssignments } from './WidgetHighlightedAssignments';

export const WidgetHighlightedAssignmentsType: IWidgetType = {
  code: 'highlighted-assignments',
  name: 'Assegnazioni in evidenza',
  icon: <FvIconUserAssignmentHightLight />,
  component: WidgetHighlightedAssignments,
  description: `Permette di mostrare le assegnazioni in evidenza`,
  actions: [WidgetActionHighlightAssignment],
  internalActions: [
    WidgetActionDeleteHighlightedAssignment,
    WidgetActionAnnotateHighlightedAssignment
  ]
};

WidgetRepository.register(WidgetHighlightedAssignmentsType);

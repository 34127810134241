import { DeskExtendedAttributeType } from 'client/api/backend/schemas';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import React from 'react';

interface IAttributeTypesFormFieldItemProps {
  metadata: DeskExtendedAttributeType;
  index: number;
}

export function AttributeTypesFormFieldItem(
  props: IAttributeTypesFormFieldItemProps
) {
  const { metadata, index } = props;

  const name = `localExtendedAttributes.${metadata.name}`;

  const { type, label, values, maxLength, isMultiple } = metadata;

  switch (type) {
    case 'date': {
      return (
        <FormFieldItem
          size="middle"
          component={DatePickerInput}
          name={name}
          type="italian-date"
          label={label}
          placeholder={label}
        />
      );
    }
    case 'integer': {
      return (
        <FormFieldItem
          size="middle"
          component={NumberInput}
          name={name}
          label={label}
          placeholder={label}
          maxLength={maxLength}
        />
      );
    }
    case 'string': {
      if ((values?.length ?? 0) < 1) {
        return (
          <FormFieldItem
            size="middle"
            component={TextInput}
            name={name}
            label={label}
            placeholder={label}
            maxLength={maxLength}
          />
        );
      }
      return (
        <FormFieldItem
          size="middle"
          component={SelectInput}
          mode={isMultiple ? 'multiple' : undefined}
          name={name}
          label={label}
          placeholder={label}
          allowClear
          showSearch
          options={[
            ...(values?.map((v: string) => {
              return { value: v ?? '', label: v ?? '' };
            }) ?? [])
          ]}
        />
      );
    }
    default: {
      return null;
    }
  }
}

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import useDimensions from 'react-cool-dimensions';
import { DeskArea, DeskOffice, DeskUser } from 'client/api/backend/schemas';
import { DataNode } from 'antd/lib/tree';
import { RiBuilding4Line, RiGovernmentLine } from 'react-icons/ri';
import { ImUser, ImOffice, ImHome2 } from 'react-icons/im';
import { IconTreeWrapper } from '../element/IconTreeWrapper';
import { TreeBaseNode } from '../element/TreeBaseNode';
import { OfficeToolkitObject } from 'client/components/widget-repository/objects/offices/OfficeToolkitObject';
import { UserToolkitObject } from 'client/components/widget-repository/objects/users/UserToolkitObject';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { ToolkitObjectStoreBag } from 'client/components/toolkit/objects/store/ToolkitObjectStoreBag';

function convertUsersToTreeData(
  list: DeskUser[],
  officeId: number,
  widgetId: number,
  store: ToolkitObjectStoreBag
): DataNode[] {
  return list.map((item, i) => {
    const object = store.add(
      UserToolkitObject.create({
        id: item.id!,
        data: item,
        name: item.name! + ' ' + item.surname!,
        icon: <ImUser />,
        sourceWidgetId: widgetId
      })
    );

    return {
      title: (
        <TreeBaseNode type="user" ellipsis object={object} parentId={officeId}>
          {item.name + ' ' + item.surname}
        </TreeBaseNode>
      ),
      icon: <IconTreeWrapper icon={<ImUser />} />,
      key: `user-${item.id}-office-${officeId}`,
      isLeaf: true
    };
  });
}

function convertOfficeToTreeData(
  parent: DeskOffice | DeskArea,
  list: DeskOffice[],
  widgetId: number,
  store: ToolkitObjectStoreBag
): DataNode[] {
  return list.map((item, i) => {
    const users = convertUsersToTreeData(
      item.utenti ?? [],
      item.id!,
      widgetId,
      store
    );
    const offices = convertOfficeToTreeData(
      item,
      item.children ?? [],
      widgetId,
      store
    );
    const children = [...users, ...offices];

    // Oggetto Ufficio
    const object = store.add(
      OfficeToolkitObject.create({
        id: item.id!,
        data: item,
        name: item.name!,
        icon: <RiBuilding4Line />,
        sourceWidgetId: widgetId
      })
    );

    return {
      title: (
        <TreeBaseNode
          type="office"
          ellipsis
          object={object}
          parentId={parent.id}
        >
          {item.name}
        </TreeBaseNode>
      ),
      icon: <IconTreeWrapper icon={<RiBuilding4Line />} />,
      key: `office-${item.id}`,
      isLeaf: children.length < 1,
      children: children
    };
  });
}

export function convertOrganizzationChartToTreeNodes(
  list: DeskArea[],
  widgetId: number,
  store: ToolkitObjectStoreBag = new ToolkitObjectStoreBag()
) {
  const nodes = list.map((item, i) => {
    const offices = convertOfficeToTreeData(
      item,
      item.offices ?? [],
      widgetId,
      store
    );

    return {
      title: (
        <TreeBaseNode type="area" ellipsis>
          {item.name}
        </TreeBaseNode>
      ),
      icon: <IconTreeWrapper icon={<RiGovernmentLine />} />,
      key: `area-${item.id}`,
      isLeaf: offices.length < 1,
      children: offices
    } as DataNode;
  });

  return { nodes, store };
}

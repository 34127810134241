import { DownOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import Search from 'antd/lib/input/Search';
import { DataNode } from 'antd/lib/tree';
import { useGetDocumentaryOrganizationChart } from 'client/api/backend/documentary-organization/documentary-organization';
import { useDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { useWidgetNetworkQueries } from 'client/components/schema/widget/context/useWidgetNetworkEffect';
import { FilterWrapper } from 'client/ui/filter/FilterWrapper';
import * as React from 'react';
import { Key, useEffect, useMemo, useRef, useState } from 'react';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { convertOrganizzationChartToTreeNodes } from './converter/convertOrganizzationChartToTreeNodes';
import { StyledTree } from './element/StyledTree';
import { TreeBaseNodeProps } from './element/TreeBaseNode';
import useDimensions from 'react-cool-dimensions';
import { ToolkitObjectStoreBag } from 'client/components/toolkit/objects/store/ToolkitObjectStoreBag';

export interface IWidgetOrganizzationChartProps
  extends IWidgetComponentToolkitProps {}

interface IOrganizationSearch {
  text?: string;
}

export function WidgetOrganizzationChart(
  props: IWidgetOrganizzationChartProps
) {
  const { widget } = props;
  const [search, setSearch] = useState<IOrganizationSearch>({});
  const { objectStore } = useDeskContext();

  const organizationChartResult = useGetDocumentaryOrganizationChart({
    widgetId: widget.id!
  });

  const localStoreRef = useRef<ToolkitObjectStoreBag>();

  const organizationChart = useMemo(() => {
    if (!organizationChartResult.data) return [];

    const { nodes, store } = convertOrganizzationChartToTreeNodes(
      organizationChartResult.data,
      widget.id!
    );

    localStoreRef.current = store;
    objectStore.register(store.items);

    return nodes;
  }, [
    organizationChartResult.data,
    organizationChartResult.isError,
    objectStore.register
  ]);

  // All'unmount del componente, rimuovo lo store locale dallo store globale
  useEffect(() => {
    return () => {
      if (localStoreRef.current) {
        objectStore.unregister(localStoreRef.current.items);
      }
    };
  }, [objectStore.unregister]);

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [autoExpandParent, setAutoExpandParent] = useState(false);

  const onExpand = (newExpandedKeys: React.Key[]) => {
    setExpandedKeys(newExpandedKeys);
    setAutoExpandParent(false);
  };

  const tree = useMemo(() => {
    if (!search.text) return organizationChart;
    const searchText = search.text!.toLowerCase();

    function filterTree(tree: DataNode[]): DataNode[] {
      return tree
        .map(child => {
          const shouldShow = (
            child.title as React.ReactElement<TreeBaseNodeProps>
          ).props.object?.name
            .toLowerCase()
            .includes(searchText);
          if (!child.children) return shouldShow ? child : undefined;
          const children = filterTree(child.children);
          return shouldShow || children.length > 0
            ? { ...child, children }
            : undefined;
        })
        .filter((n): n is DataNode => n != null);
    }

    return filterTree(organizationChart);
  }, [search.text, organizationChart]);

  useEffect(() => {
    function getExpandedKeys(tree: DataNode[] | undefined): React.Key[] {
      if (!tree) return [];
      return tree.flatMap(node =>
        node.children ? [node.key, ...getExpandedKeys(node.children)] : []
      );
    }

    setAutoExpandParent(true);
    setExpandedKeys(getExpandedKeys(tree));
  }, [tree]);

  const { observe, height: availableWidgetHeight } = useDimensions({});

  const handleSearch = (text: string) => {
    setSearch(s => ({ ...s, text }));
    setAutoExpandParent(true);
  };

  useWidgetNetworkQueries([organizationChartResult]);

  return (
    <WidgetCardBody
      padded={true}
      top={
        <FilterWrapper>
          <Search
            allowClear
            placeholder="Filtra.."
            enterButton
            onSearch={handleSearch}
          />
        </FilterWrapper>
      }
    >
      <div ref={observe} style={{ height: '100%' }}>
        {availableWidgetHeight > 0 && (
          <Skeleton loading={organizationChartResult.isLoading} active>
            <StyledTree
              showLine={{ showLeafIcon: false }}
              switcherIcon={<DownOutlined />}
              showIcon
              blockNode
              defaultExpandParent
              autoExpandParent={autoExpandParent}
              expandedKeys={expandedKeys}
              onExpand={onExpand}
              selectable={false}
              treeData={tree}
              height={availableWidgetHeight}
            />
          </Skeleton>
        )}
      </div>
    </WidgetCardBody>
  );
}

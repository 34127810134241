import { Widget } from 'client/api/backend/schemas';
import { useReducer } from 'react';

type ConfigureWidgetAction =
  // Imposta la posizione
  | { type: 'open-configuration'; widget: Widget }
  // Chiude il pannello
  | { type: 'close' };

export interface ConfigureWidgetState {
  widget?: Widget;
  visible?: boolean;
}

function reducer(
  state: ConfigureWidgetState,
  action: ConfigureWidgetAction
): ConfigureWidgetState {
  switch (action.type) {
    case 'open-configuration':
      return { ...state, visible: true, widget: action.widget };

    case 'close':
      return { ...state, visible: false };
  }
}

export function useConfigureWidgetReducer() {
  const [state, dispatch] = useReducer(reducer, {});
  return {
    state,
    dispatch
  };
}

import { ISODate } from 'client/ui/display/date/ISODate';
import React from 'react';
import styled from 'styled-components';
import { ExtendText } from './ExtendText';
import { ElementTextExtend } from './IWidgetListElement';

function textElement(
  text?: string | Date | JSX.Element
): string | React.ReactNode {
  return text instanceof Date ? (
    <ISODate date={text} format={'DD/MM/YYYY HH:mm'} />
  ) : (
    text
  );
}

const Separator = styled.span`
  margin: 0 4px;
`;
const TextContainer = styled.div`
  word-break: break-word;
`;

export interface ListElementTextProps {
  text?: string | Date | JSX.Element;
  extend?: ElementTextExtend;
}

/**
 * Renderizza un testo con eventuali estensioni.
 */
export function ListElementText(props: ListElementTextProps) {
  const { text, extend } = props;
  if (!text && !extend) {
    return null;
  }
  if (!extend) {
    return <TextContainer>{textElement(text)}</TextContainer>;
  }
  const { before, after, divider, bold, color, beforeColor, afterColor } =
    extend;

  return (
    <TextContainer>
      {before && (
        <ExtendText bold={bold} color={beforeColor ?? color} text={before} />
      )}
      {before && text && <Separator children={divider ?? '-'} />}
      {textElement(text)}
      {!text && after && before && <Separator children={divider ?? '-'} />}
      {text && after && <Separator children={divider ?? '-'} />}
      {after && (
        <ExtendText bold={bold} color={afterColor ?? color} text={after} />
      )}
    </TextContainer>
  );
}

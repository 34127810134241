import * as React from 'react';
import clx from 'classnames';
import styled from 'styled-components';
import { useDesk } from 'client/components/schema/desk/DeskModule';
import { WidgetLock } from './WidgetLock';
import { Alert, AlertProps, Result, ResultProps, Tabs } from 'antd';
import { CardBodyWrapper } from './CardBodyWrapper';
import { WidgetFullBodyResult } from './WidgetFullBodyResult';
import { WidgetTabs } from '../../../../toolkit/components/tabs/WidgetTabs';
import { WidgetTabPanelProps } from '../../../../toolkit/components/tabs/WidgetTabPanel';

const AlertBanner = styled(Alert)`
  margin-top: 1px;
`;

export interface WidgetCardBodyTabsProps
  extends React.HTMLAttributes<HTMLDivElement> {
  /** I children devono essere componenti <WidgetTabPanel /> */
  children:
    | React.ReactElement<WidgetTabPanelProps>[]
    | React.ReactElement<WidgetTabPanelProps>;
  /** Alert in testa al contenuto del widget */
  alert?: Pick<AlertProps, 'message' | 'type' | 'closable'>;
  /** Se valorizzato, in caso di errori, sostituisce il contenuto del widget
   * con il componente Result di Ant Design */
  result?: Pick<ResultProps, 'title' | 'subTitle' | 'extra' | 'status'>;
  /** Permette   */
  actionsMenu?: React.ReactNode;
  defaultKey?: string;
}

/**
 * Permette la divisione in Tabs del contenuto del widget.
 * I children devono essere Componenti WidgetTabPanel.
 *
 * Specificando un Tab (WidgetTabPanel) came "main"
 * verrà posizionato in una colonna dedicata in modalità fullsreen
 */
export function WidgetCardBodyTabs(props: WidgetCardBodyTabsProps) {
  const deskState = useDesk();

  const { children, alert, result, actionsMenu, defaultKey, ...otherProps } =
    props;

  if (result) {
    return <WidgetFullBodyResult result={result} />;
  }

  return (
    <CardBodyWrapper {...otherProps}>
      {alert && (
        <AlertBanner
          message={alert?.message ?? 'Codice messaggio: 8001'}
          banner
          closable
          type="warning"
          {...alert}
        />
      )}
      <WidgetTabs actionsMenu={actionsMenu} defaultKey={defaultKey}>
        {children}
      </WidgetTabs>
      {deskState.isEditable && <WidgetLock />}
    </CardBodyWrapper>
  );
}

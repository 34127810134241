import { appConfig } from 'client/core/appConfig';
import * as React from 'react';
import { Subject } from 'rxjs';
import { AuthMethod } from './AuthMethods';
import { AzureProvider } from './azure/AzureProvider';
import { OpenIDConnectProvider } from './openidconnect/OpenIDConnectProvider';

export interface ISsoContextProviderProps {
  children?: React.ReactNode;
}

export type AuthEvent = { type: 'logout' };
export const authSubject = new Subject<AuthEvent>();
export type AuthSubject = typeof authSubject;

/**
 * Sceglie il provider di autenticazione in base alla configurazione
 */
export function SsoContextProvider(props: ISsoContextProviderProps) {
  switch (appConfig.ssoMethod) {
    case AuthMethod.Azure:
      return (
        <AzureProvider authSubject={authSubject}>
          {props.children}
        </AzureProvider>
      );

    case AuthMethod.OpenIDConnect:
      return (
        <OpenIDConnectProvider authSubject={authSubject}>
          {props.children}
        </OpenIDConnectProvider>
      );
    default:
      return <>{props.children}</>;
  }
}

import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

export const DeskEditButtonStyle = styled(Button)`
  padding-left: 6px;
  padding-right: 12px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  vertical-align: -2px;

  &.ant-btn {
    &.selected {
      background-color: #fff;
      &:active,
      &:hover,
      &:focus {
        background-color: #fff;
      }
    }
  }

  // Menu Dropdown posizionati nel body (Desks Menu)
  .desk-menu-dropdown &,
  .ant-menu-submenu.ant-menu-submenu-popup & {
    background-color: transparent;
    margin-right: 12px;
  }
  &.selected,
  &:active,
  &:hover,
  &:focus {
    .desk-menu-dropdown &,
    .ant-menu-submenu.ant-menu-submenu-popup & {
      background-color: transparent;
    }
  }
`;

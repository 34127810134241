import { Widget } from 'client/api/backend/schemas';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import * as React from 'react';
import * as WidgetFilterSections from 'client/components/toolkit/components/filter/WidgetFilterSections';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextInput } from 'client/ui/form/input/TextInput';
import { Button, Space } from 'antd';
import { WidgetFilterDrawer } from 'client/components/toolkit/components/filter/WidgetFilterDrawer';
import { renderWidgetFilterActiveTags } from 'client/components/toolkit/components/filter/renderWidgetFilterActiveTags';
import { useState } from 'react';
import {
  WidgetDamMonitoringSearchSchema,
  WidgetDamMonitoringSearchSchemaType
} from './WidgetDamMonitoringSearchSchema';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { DamObservationsGraphViewParameterInput } from 'client/components/widget-repository/objects/dams/DamObservationsGraphViewParameterInput';
import { damMonitoringDefaultFilters } from '../WidgetDamMonitoringDefaultFilters';

export interface IWidgetDamMonitoringSearchFormProps {
  widget: Widget;
  search: Partial<WidgetDamMonitoringSearchSchemaType>;
  setSearch: (search: Partial<WidgetDamMonitoringSearchSchemaType>) => void;
}

export function WidgetDamMonitoringSearchForm(
  props: IWidgetDamMonitoringSearchFormProps
) {
  const { widget, search, setSearch } = props;
  const [open, setOpen] = useState(false);

  return (
    <FormikAugmented
      validationSchema={WidgetDamMonitoringSearchSchema}
      validationContext={{}}
      initialValues={search}
      onReset={() => {
        setSearch(damMonitoringDefaultFilters);
        setOpen(false);
      }}
      onSubmit={(values: any) => {
        setSearch(values);
        setOpen(false);
      }}
    >
      <WidgetFilterDrawer
        title="Ricerca"
        filters={renderWidgetFilterActiveTags({
          search,
          filters: widget.profile?.filters,
          onReset: () => setSearch(damMonitoringDefaultFilters)
        })}
        open={[open, setOpen]}
      >
        <FormikForm layout="vertical" editable helpInTooltips>
          <WidgetFilterSections.BodyWrapper>
            <FormFieldsContainer columns={2} gutter={8}>
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                type="unix"
                label={null}
                name="startDate"
                placeholder="Dal"
              />
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                type="unix"
                label={null}
                name="endDate"
                placeholder="Al"
              />
            </FormFieldsContainer>
            <FormFieldsContainer columns={1} gutter={8}>
              <FormFieldItem
                size="middle"
                component={DamObservationsGraphViewParameterInput}
                label="Opzioni di visualizzazione grafica"
                placeholder="Seleziona variabili per il grafico"
                name="selectedGraphView"
              />
            </FormFieldsContainer>
          </WidgetFilterSections.BodyWrapper>
          <WidgetFilterSections.ButtonsWrapper>
            <Space>
              <Button htmlType="reset">Pulisci</Button>
              <Button htmlType="submit" type="primary">
                Filtra
              </Button>
            </Space>
          </WidgetFilterSections.ButtonsWrapper>
        </FormikForm>
      </WidgetFilterDrawer>
    </FormikAugmented>
  );
}

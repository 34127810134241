import { MailOutlined } from '@ant-design/icons';
import { DeskMail, Widget } from 'client/api/backend/schemas';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import moment from 'moment';
import React from 'react';

export function convertMailToListElement(
  mail: DeskMail,
  widget: Widget
): IWidgetListElement {
  return {
    name: mail.emailSender ?? `Email #${mail.id!}`,
    descriptionExtend: { bold: true, before: mail.subject },
    description: moment(mail.date).format('lll'),
    type: 'mail',
    annotations: mail.note ?? undefined,
    annotationsExtend: mail.note
      ? { before: `Note:`, beforeColor: '#0080FF', bold: true, divider: '' }
      : undefined,
    object: {
      name: `${mail.emailSender} - ${mail.subject}`,
      type: 'mail',
      icon: <MailOutlined />,
      id: mail.id!,
      data: mail,
      sourceWidgetId: widget.id!
    }
  };
}

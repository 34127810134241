import { FolderOutlined, PieChartOutlined } from '@ant-design/icons';
import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetMonitorAssignments } from './WidgetMonitorAssignments';

export const WidgetMonitorAssignmentsType: IWidgetType = {
  code: 'monitor-assignments',
  name: 'Monitoraggio Assegnazioni Ricevute',
  icon: <PieChartOutlined />,
  component: WidgetMonitorAssignments,
  description: `Monitoraggio delle assegnazioni in entrata`
};

WidgetRepository.register(WidgetMonitorAssignmentsType);

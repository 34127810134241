import * as React from 'react';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import { WidgetList } from 'client/components/toolkit/components/list/WidgetList';
import { useCallback, useState } from 'react';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { convertDossierToWidgetList } from '../dossier-viewer/convert/convertDossierToWidgetList';
import { FolderOutlined } from '@ant-design/icons';
import { useDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { PaddedSkeleton } from 'client/ui/skeleton/PaddedSkeleton';
import { UseQueryOptions } from 'react-query';
import { useWidgetNetworkQueries } from 'client/components/schema/widget/context/useWidgetNetworkEffect';
import { PersonalDossierToolkitObject } from './objects/PersonalDossierToolkitObject';
import { ToolkitObjectBottomZone } from 'client/components/toolkit/objects/ToolkitObjectBottomZone';
import { WidgetCardBodyTabs } from 'client/components/schema/widget/card/body/WidgetCardBodyTabs';
import { ToolkitObjectActionsMenu } from 'client/components/schema/widget/actions/interaction/ToolkitObjectActionsMenu';
import { WidgetTabPanel } from 'client/components/toolkit/components/tabs/WidgetTabPanel';
import {
  useGetDocumentaryDossiersIdChildren,
  useGetDocumentaryDossiersIdFiles,
  useGetDocumentaryDossiersPersonal
} from 'client/api/backend/documentary-dossiers/documentary-dossiers';

export interface IWidgetPersonalDossierProps
  extends IWidgetComponentToolkitProps {}

export function WidgetPersonalDossier(props: IWidgetPersonalDossierProps) {
  const { widget } = props;

  const deskContext = useDeskContext();
  const [folderId, setFolderId] = useState<number | undefined>(undefined);

  const personalDossierResult = useGetDocumentaryDossiersPersonal(
    { widgetId: widget.id! },
    {
      query: {
        onSuccess: data => {
          // Dobbiamo impostarla esclusivamente la prima volta, altrimenti
          // se si fa "reload" la navigazione riparte dall'inizio (e in quel
          // caso, oltre a perdere la cartella attuale in cui siamo, bisognerebbe
          // resettare il `navigationStack` per rendere l'UI coerente)
          if (!folderId) {
            setFolderId(data.id);
          }
        }
      }
    }
  );

  const personalFolderId: number | undefined = personalDossierResult?.data?.id;
  const personalFolderObject = personalFolderId
    ? PersonalDossierToolkitObject.create({
        id: personalFolderId,
        sourceWidgetId: widget.id!,
        name: 'Cartella Personale',
        icon: <FolderOutlined />,
        data: personalDossierResult.data!
      })
    : null;

  // Scarico i children & i files
  const querySharedOptions: { query: UseQueryOptions<any> } = {
    query: { enabled: folderId != null }
  };

  const subfoldersResult = useGetDocumentaryDossiersIdChildren(
    folderId!,
    {
      widgetId: widget.id!,
      folderId: folderId!
    },
    querySharedOptions
  );

  const documentsResult = useGetDocumentaryDossiersIdFiles(
    folderId!,
    {
      widgetId: widget.id!,
      folderId: folderId!,
      pageNumber: 0,
      pageSize: 20
    },
    querySharedOptions
  );

  useWidgetNetworkQueries([
    personalDossierResult,
    subfoldersResult,
    documentsResult
  ]);

  const [navigationStack, setNavigationStack] = useState<IWidgetListElement[]>(
    []
  );

  const navigateToFolder = useCallback(
    (folder: IWidgetListElement) => {
      setFolderId(folder.object!.id as number);
      setNavigationStack(stack => [...stack, folder]);
    },
    [setFolderId, setNavigationStack]
  );

  const navigateBack = useCallback(() => {
    if (navigationStack.length === 0) return;
    const nextStack = navigationStack.slice(0, -1);
    setFolderId(
      nextStack.length === 0
        ? personalFolderId
        : (nextStack[nextStack.length - 1].object!.id as number)
    );
    setNavigationStack(nextStack);
  }, [setFolderId, navigationStack, setNavigationStack]);

  const folderObject =
    navigationStack.length === 0
      ? personalFolderObject!
      : navigationStack[navigationStack.length - 1].object!;

  return (
    <WidgetCardBody
      scrollable={false}
      bottom={<ToolkitObjectBottomZone object={folderObject} />}
    >
      <WidgetCardBodyTabs
        actionsMenu={<ToolkitObjectActionsMenu object={folderObject} />}
      >
        <WidgetTabPanel
          title="Cartella Personale"
          icon={<FolderOutlined />}
          type="main"
          // tabKey="main"
          scrollable={false}
        >
          <PaddedSkeleton
            loading={
              personalDossierResult.isLoading
              // subfoldersResult.isLoading ||
              // documentsResult.isLoading
            }
          >
            <WidgetList
              onBack={navigateBack}
              backElement={
                navigationStack.length > 0
                  ? navigationStack[navigationStack.length - 1]
                  : undefined
              }
              list={convertDossierToWidgetList(
                folderId!,
                subfoldersResult.data,
                'personal-dossier',
                documentsResult.data,
                widget,
                navigateToFolder
              )}
            />
          </PaddedSkeleton>
        </WidgetTabPanel>
      </WidgetCardBodyTabs>
    </WidgetCardBody>
  );
}

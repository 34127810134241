import {
  InteractionOutlined,
  SwapOutlined,
  SwitcherOutlined
} from '@ant-design/icons';
import { Button, Input, message, Popover } from 'antd';
import { Widget } from 'client/api/backend/schemas';
import { set } from 'client/core/data/set';
import { logger } from 'client/core/logger/logger';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { Formik } from 'formik';
import * as React from 'react';
import styled from 'styled-components';
import { useDeskContext } from '../../desk/context/DeskContext';
import { useDesk } from '../../desk/DeskModule';
import { useDesksQuery } from '../../desk/logic/useDesksQuery';

const FormContainer = styled.div`
  display: flex;
  width: 100%;
  .input-container {
    width: 100%;
    display: flex;
    .input-select {
      flex: 1 1 auto;
      min-width: 0;
      .ant-select-selector {
        height: 32px;
        .ant-select-selection-search-input {
          height: 32px;
        }
        .ant-select-selection-item {
          line-height: 30px;
        }
      }
    }
    .input-button {
      flex: 0 0 auto;
      min-width: 0;
    }
  }
`;

const PopoverTitle = styled.div`
  margin-top: 4px;
  height: 22px;
`;

export interface IWidgetChooseDeskButtonProps {
  widget: Widget;
}

/**
 * Pulsante, solo per i floating widget, per lo spostamento da una Scrivania
 * all'altra
 */
export function WidgetChooseDeskButton(props: IWidgetChooseDeskButtonProps) {
  const { widget } = props;
  const deskState = useDesk();
  const deskContext = useDeskContext();

  // TODO Al momento sfruttiamo il fatto che lo staleTime è "Infinity" quindi
  // non vengono refreshate le scrivanie (e di conseguenza non viene effettuato
  // il cambio con `selectIfNone`).
  const desksResult = useDesksQuery();

  const form = (
    <Formik
      initialValues={{ deskId: deskState.selectedId }}
      onSubmit={async values => {
        try {
          await deskContext.updateWidget(set(widget, 'panelId', values.deskId));
          message.success("Il Widget è stato spostato nella scrivania indicata."); // prettier-ignore
        } catch (error) {
          message.error("Impossibile spostare il Widget, si è verificato un errore."); // prettier-ignore
          console.error(error);
        }
      }}
    >
      <FormContainer>
        <Input.Group compact className="input-container">
          <SelectInput
            className="input-select"
            loading={desksResult.isLoading}
            name="deskId"
            placeholder="Scegli scrivania..."
            options={desksResult.panels.map(panel => ({
              label: panel.name,
              value: panel.id!
            }))}
            // style={{ width: '80%' }}
          />
          <FormikSendButton
            className="input-button"
            type="primary"
            children="Sposta"
            // style={{ width: '20%' }}
            icon={<SwitcherOutlined />}
          />
        </Input.Group>
      </FormContainer>
    </Formik>
  );

  return (
    <Popover
      content={form}
      title={<PopoverTitle>Cambia Scrivania</PopoverTitle>}
      trigger="click"
      placement="topRight"
      overlayStyle={{ width: '300px' }}
    >
      <ButtonIcon type="primary" icon={<SwapOutlined />} />
    </Popover>
  );
}

import React, { useState } from 'react';
import {
  BarsOutlined,
  FileOutlined,
  PaperClipOutlined
} from '@ant-design/icons';
import { WidgetCardBodyTabs } from 'client/components/schema/widget/card/body/WidgetCardBodyTabs';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { WidgetActivityViewerProfile } from './WidgetActivityViewerProfile';
import { WidgetTabPanel } from 'client/components/toolkit/components/tabs/WidgetTabPanel';
import { TabsSideMenu } from '../../../toolkit/components/tabs/TabsSideMenu';
import { PaddedSkeleton } from 'client/ui/skeleton/PaddedSkeleton';
import { useWidgetNetworkQueries } from 'client/components/schema/widget/context/useWidgetNetworkEffect';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { ToolkitObjectBottomZone } from 'client/components/toolkit/objects/ToolkitObjectBottomZone';
import { useRegisterToolkitObject } from 'client/components/toolkit/objects/store/useToolkitObjectStore';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import {
  useGetDocumentaryActivityAttachmentsIdAttachment,
  useGetDocumentaryActivityId,
  useGetDocumentaryActivityIdAct
} from 'client/api/backend/documentary-activity/documentary-activity';
import { WidgetDocumentPreview } from '../document-viewer/WidgetDocumentPreview';
import { ActivityMetadata } from './metadata/ActivityMetadata';
import {
  ATTACHMENT_ACTIVITY,
  convertIdToKey,
  convertActivityToAttachmentMenuItems,
  getIdFromKey,
  PRINCIPAL_ACTIVITY
} from './convert/convertActivityToAttachmentMenuItems';
import { ToolkitObjectActionsMenu } from 'client/components/schema/widget/actions/interaction/ToolkitObjectActionsMenu';
import { convertActivityToWidgetListElement } from './convert/convertActivityToWidgetListElement';

export interface IWidgetActivityViewerProps
  extends IWidgetComponentToolkitProps {}

export function WidgetActivityViewer(props: IWidgetActivityViewerProps) {
  const { widget } = props;
  const data = widget.profile?.data as WidgetActivityViewerProfile | undefined;

  const activityResult = useGetDocumentaryActivityId(
    data?.activityId!,
    {
      code: data?.activityCode!,
      documentId: data?.documentId!,
      widgetId: widget.id!
    },
    {
      query: {
        enabled: data != null
      }
    }
  );

  const activityObject = activityResult.data
    ? convertActivityToWidgetListElement(activityResult.data, widget).object
    : null;

  const [selectedAttachmentKey, setSelectedAttachmentKey] = useState<string>(
    convertIdToKey(data?.documentId, 'principal')
  );

  const menuAttachments = convertActivityToAttachmentMenuItems(
    activityResult.data
  );

  const selectedAttachment = menuAttachments?.find(
    item => item!.key === selectedAttachmentKey
  );

  const documentResult = useGetDocumentaryActivityIdAct(
    data?.activityId!,
    {
      code: data?.activityCode!,
      documentId: data?.documentId!,
      widgetId: widget.id!,
      withText: selectedAttachment?.withText ?? false
    },
    {
      query: {
        enabled:
          // Check su activityResult.data?.id per evitare che venga eseguita la query
          // quando non è ancora stata caricata l'activity
          activityResult.data?.id != null &&
          data != null &&
          selectedAttachment?.activityType !== ATTACHMENT_ACTIVITY
      }
    }
  );

  const attachmentResult = useGetDocumentaryActivityAttachmentsIdAttachment(
    getIdFromKey(selectedAttachmentKey, ATTACHMENT_ACTIVITY),
    { documentId: data?.documentId!, widgetId: widget.id! },
    {
      query: {
        enabled: selectedAttachment?.activityType === ATTACHMENT_ACTIVITY
      }
    }
  );

  useWidgetNetworkQueries([documentResult, attachmentResult, activityResult]);

  const content =
    selectedAttachment?.activityType !== ATTACHMENT_ACTIVITY
      ? documentResult.data?.content
      : attachmentResult.data?.content;

  const fileName =
    selectedAttachment?.activityType !== ATTACHMENT_ACTIVITY
      ? documentResult.data?.name
      : attachmentResult.data?.name;

  // Creiamo l'oggetto per il toolkit
  const widgetObject = WidgetToolkitObject.from(widget);
  useRegisterToolkitObject(widgetObject);

  return (
    <WidgetCardBody
      scrollable={false}
      bottom={<ToolkitObjectBottomZone object={widgetObject} />}
    >
      <WidgetCardBodyTabs
        actionsMenu={<ToolkitObjectActionsMenu object={activityObject} />}
        defaultKey="metadata"
      >
        <WidgetTabPanel
          title="Metadati"
          tabKey="metadata"
          icon={<BarsOutlined />}
          padded
        >
          <ActivityMetadata
            widgetId={widget.id!}
            activity={activityResult.data}
          />
        </WidgetTabPanel>
        <WidgetTabPanel
          title="Atto"
          type="main"
          icon={<FileOutlined />}
          tabKey="preview"
          scrollable={false}
        >
          <PaddedSkeleton
            loading={activityResult.isLoading || attachmentResult.isLoading}
            active
          >
            <WidgetDocumentPreview
              source={{
                type: 'content',
                content: content
              }}
              fileName={fileName}
            />
          </PaddedSkeleton>
        </WidgetTabPanel>

        <WidgetTabPanel
          title="Allegati"
          icon={<PaperClipOutlined />}
          type="side-menu"
          tabKey="side-menu"
        >
          <TabsSideMenu
            items={menuAttachments}
            defaultSelectedKeys={[
              convertIdToKey(data?.documentId!, PRINCIPAL_ACTIVITY)
            ]}
            onClick={e => setSelectedAttachmentKey(e.key)}
          />
        </WidgetTabPanel>
      </WidgetCardBodyTabs>
    </WidgetCardBody>
  );
}

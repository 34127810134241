import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import clx from 'classnames';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { Space, Button } from 'antd';
import { ActionContainer } from 'client/ui/grid/ActionContainer';
import { WidgetContainer } from 'client/ui/grid/WidgetContainer';
import * as React from 'react';
import {
  Layout,
  Layouts,
  Responsive as ResponsiveGridLayout
} from 'react-grid-layout';
import { useElementSize } from 'usehooks-ts';
import { FullScreenBackdrop } from '../../widget/card/base/FullScreenBackdrop';
import { WidgetDraggingContainer } from '../../widget/dragging/WidgetDraggingContainer';
import { WidgetAddPlaceholder } from '../../widget/placeholder/WidgetAddPlaceholder';
import { DeskActions, useDesk } from '../DeskModule';
import { useDeskContext } from '../context/DeskContext';
import { AddWidgetDrawer } from './add-widget/AddWidgetDrawer';
import { useCallback, useMemo, useRef, useState } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { Widget } from 'client/api/backend/schemas';
import { layoutToWidgetPosition } from '../../widget/logic/widgetDisplayUtils';
import { logger } from 'client/core/logger/logger';
import {
  CollisionDetection,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  PointerSensor,
  useSensor,
  useSensors,
  pointerWithin,
  rectIntersection
} from '@dnd-kit/core';
import { useDispatch } from 'react-redux';
import { WidgetRepository } from 'client/components/widget-repository/WidgetRepository';
import { ActionCard } from '../../widget/actions/ActionCard';
import { ToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { useTransition, animated, config as springConfig } from 'react-spring';
import { ElementDragPreview } from '../../widget/dragging/ElementDragPreview';
import {
  DraggedToolkitObject,
  ToolkitObject
} from 'client/components/toolkit/objects/ToolkitObject';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { down } from 'styled-breakpoints';
import { WidgetConfigurationDrawer } from '../../widget/configure/WidgetConfigurationDrawer';
import { set } from 'client/core/data/set';
import { DeskWidgetsLogic } from '../logic/DeskWidgetsLogic';
import { ActionMessagesHub } from '../../widget/actions/message-hub/ActionMessagesHub';
import { createConstantLayout } from '../logic/createConstantLayout';
import { WidgetPositionHandler } from '../../widget/logic/WidgetPositionHandler';
import { FloatingWidgetArea } from '../../widget/floating/FloatingWidgetArea';
import { DeskEmptyPlaceholder } from './DeskEmptyPlaceholder';
import { useWidgetRepository } from 'client/components/widget-repository/useWidgetRepository';

export interface IDeskSpaceProps {}

type DroppingItemType =
  | {
      i: string;
      w: number;
      h: number;
      isPlaceholder?: boolean;
    }
  | undefined;

function _ppos(pos: any | undefined) {
  return `x:${pos?.x},y:${pos?.y}`;
}

/**
 * Versione personalizzata dell'algoritmo di collisione.
 * Utilizza di default `pointerWithin` che è molto più preciso (vedi
 * caso d'uso D&D sull'organigramma)
 */
const deskCollisionDetection: CollisionDetection = args => {
  // First, let's see if there are any collisions with the pointer
  const pointerCollisions = pointerWithin(args);

  // Collision detection algorithms return an array of collisions
  if (pointerCollisions.length > 0) {
    return pointerCollisions;
  }

  // If there are no collisions with the pointer, return rectangle intersections
  return rectIntersection(args);
};

/**
 * Spazio della scrivania. Lo Spazio contiene:
 * - La griglia
 * - La action bar
 * - I floating widget
 * E coordina la loro attività tramite il `DeskContext`.
 */
export function DeskSpace(props: IDeskSpaceProps) {
  const [containerRef, { width, height }] = useElementSize();
  const colNumber = 6;
  const rowsNumber = 6;

  const dispatch = useDispatch();
  const deskState = useDesk();
  const deskContext = useDeskContext();
  const { desk, widgets, gridWidgets, setWidgets, isLoading } = deskContext;

  const widgetRepository = useWidgetRepository();

  const isMobile = useBreakpoint(down('lg'));
  const gridWidth = width - (isMobile ? 0 : 24);
  const gridHeight = height;

  /**
   * L'elemento che è attualmente in spostamento.
   */
  const [droppingItem, setDroppingItem] = useState(
    undefined as DroppingItemType
  );

  // const droppingWidget =
  //   droppingItem != null &&
  //   widgets.find(w => w.id?.toString() === droppingItem.i);

  /**
   * Sincronizzazione fra lo stato interno di react-grid-layout e lo stato dei
   * widget della scrivania. Questo avviene tramite la sincronizzazione dei
   * `layout` con gli elementi `widget`.
   *
   * Per evitare problemi di layout, dobbiamo ricordarci che ogni elemento
   * che appare come Children di ResponsiveGridLayout **deve** avere un Layout
   * `data-grid` impostato; questo viene utilizzato in caso non sia ancora presente
   * il Layout in `deskLayout`, altrimenti verrebbe posizionato di default a (0,6)
   * ovvero "oltre" la griglia con conseguente sfasatura.
   *
   * Provvede a spostare nella Floating Bar quei Widget che non "entrano" più
   * nella griglia (gli `outsideWidget`).
   */
  const handleLayoutChange = useCallback(
    (layout: Layout[], allLayouts: Layouts) => {
      if (layout === allLayouts['sm']) {
        return;
      }

      let nextWidgets = [...widgets];

      // Evitiamo sincronizzazioni fortuite se non siamo in modalità di modifica.
      if (!deskState.isEditable) return;

      logger.log(`layout.refreshFromGrid->layout:`, cloneDeep(layout), 'widgets:', cloneDeep(widgets)); // prettier-ignore
      let movedWidgetsCount = 0;
      layout.forEach(layout => {
        const index = nextWidgets.findIndex(w => w.id!.toString() === layout.i);
        const widget = nextWidgets[index];
        if (!widget) return;

        // Quando stiamo muovendo il placeholder, non stiamo veramente muovendo
        // il widget, quindi evitiamo di aggiornare veramente la posizione
        // altrimenti si "perde" quando si effettua il drop.
        if ((layout as any).isPlaceholder) {
          console.log(`layout.placeholderFound wid=${widget.id}`, layout);
          return;
        }

        // Aggiorniamo la posizione solo se è cambiata per evitare re-rendering.
        // Grazie alla funzione `layoutToWidgetPosition`, calcoliamo anche il caso
        // in cui in Widget vada "fuori" dalla griglia, trasformandolo in un
        // floating widget.
        const nextPosition = layoutToWidgetPosition(widget, layout, {
          rowsNumber,
          nextOrder: DeskWidgetsLogic.getFloatingNextOrder(widgets)
        });
        if (isEqual(nextPosition, widget.profile?.position)) return;

        movedWidgetsCount++;
        logger.log(`layout.movingWidget ${layout.i}`, `from ${_ppos(widget.profile?.position)} to ${_ppos(nextPosition)}`); // prettier-ignore
        nextWidgets[index] = set(widget, 'profile.position', nextPosition);
      });

      // Effettuiamo l'aggiornamento dei Widget solamente se almeno una è vera:
      // 1. Ci sono nuovi Widget che dopo lo spostamento diventano "floating"
      // 2. Almeno un Widget ha un layout diverso
      // Entrambi i casi sono gestiti con il `movedWidgetsCount` che conta
      // quanti widget hanno una `position` differente.
      if (movedWidgetsCount > 0) {
        logger.log(`movedWidgetsCount`, movedWidgetsCount); // prettier-ignore
        setWidgets(nextWidgets);
      }
    },
    [widgets, droppingItem, deskState.isEditable]
  );

  const handleRestore = useCallback(
    (widget: Widget) => {
      setDroppingItem({
        i: widget.id!.toString(),
        w: 2,
        h: 2,
        isPlaceholder: true
      });
    },
    [widgets]
  );

  const handleDrop = useCallback(
    (layouts: Layout[], layout: Layout, e: Event) => {
      logger.log(`drop.finish, i=${layout.i}`, layout);
      if (!layout.i) return;

      let nextWidgets = [...widgets];
      let widget = nextWidgets.find(w => w.id?.toString() === layout.i)!;
      let index = nextWidgets.indexOf(widget);

      nextWidgets[index] = set(
        widget,
        'profile.position',
        layoutToWidgetPosition(widget, layout, {
          rowsNumber,
          nextOrder: -1 // TODO Non viene mai usato ma andrebbe gestito in modo più chiaro
        })
      );

      logger.log('drop.finish-> new position', nextWidgets[index].profile?.position); // prettier-ignore
      setWidgets(nextWidgets);
      setDroppingItem(undefined);
    },
    [widgets, setWidgets, rowsNumber]
  );

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      console.log('dragging', event.active.data.current);
      const current = event.active.data.current as DraggedToolkitObject;
      dispatch(DeskActions.draggingItem(true, current));
    },
    [dispatch]
  );

  const handleDragEnd = useCallback(
    async (event: DragEndEvent) => {
      console.log('dragend', event.over);
      const active = event.active.data.current as DraggedToolkitObject;
      const over = event.over?.data.current;
      if (active == null || over == null) return;

      // Caso 0. I campi di input vengono gestiti all'interno del singolo
      // ToolkitObjectInput
      if (over.isInput) return;

      const object = active.object;
      dispatch(DeskActions.draggingItem(false, undefined));

      const sourceWidget = deskContext.widgets.find(
        w => w.id! === object.sourceWidgetId
      );

      // Caso 1. Over è una Action
      const overAction = over.action as ToolkitAction<any>;
      const action = widgetRepository.findAction(overAction?.code);

      if (action) {
        console.log('active', object, 'over', action);
        await action.execute({ deskContext, sourceWidget }, object);
      }

      // Caso 2. Target è un altro Object
      const overObject = over?.object as ToolkitObject<any>;
      if (overObject) {
        console.log('active', object, 'over', overObject);
        const action = widgetRepository.findPrimaryAction(object, overObject);
        await action?.execute(
          { deskContext, sourceWidget },
          action.arguments[0].isArray &&
            active.massiveSelected &&
            active.massiveSelected.length > 0
            ? active.massiveSelected
            : object,
          overObject
        );
      }
    },
    [dispatch, deskContext]
  );

  const isRendered = height > 0 && width > 0;

  // Memorizziamo i children per evitare il costo di re-layout di
  // react-grid-layout. Particolarmente utile quando cambiano soltanto i
  // floating widgets.
  // TODO: Avevamo tolto il `deskLayout` per evitare la doppia fonte di verità,
  // purtroppo è necessario altrimenti non viene aggiornato il tutto quando si
  // passa da Mobile a Desktop e viceversa.
  // Per evitare il conflict con il `data-grid` proviamo a rigenerarli nello
  // stesso punto.
  const gridChildren = useMemo(() => {
    return {
      layout: createConstantLayout(
        gridWidgets.map(w => ({
          ...WidgetPositionHandler.gridPosition(w),
          i: w.id!.toString()
        }))
      ),
      children: [
        ...gridWidgets.map(widget => (
          <WidgetDraggingContainer
            key={widget.id}
            widget={widget}
            data-grid={widget.profile?.position}
          />
        ))
      ]
    };
  }, [gridWidgets, rowsNumber, deskState.isEditable]);

  const gridFreeSlots = useMemo(
    () =>
      DeskWidgetsLogic.getFreeSlotsLayout(
        deskState.selectedId!,
        gridWidgets
      ).map(slot => {
        if (slot.y >= rowsNumber) return null;
        console.log('placeholder i: ', slot.i, `x:${slot.x},y:${slot.y}`);
        return (
          <WidgetAddPlaceholder
            key={slot.i}
            slot={slot}
            gridParams={{
              gridWidth,
              gridHeight,
              rowsNumber
            }}
          />
        );
      }),
    [gridWidgets, gridWidth, gridHeight, rowsNumber]
  );

  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 1 } }),
    useSensor(KeyboardSensor)
  );

  return (
    <>
      <DndContext
        collisionDetection={deskCollisionDetection}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        sensors={isMobile ? [] : sensors}
      >
        <DragOverlay modifiers={[snapCenterToCursor]}>
          {deskState.draggingItem && (
            <ElementDragPreview
              object={deskState.draggingItem.object}
              massiveSelected={deskState.draggingItem.massiveSelected}
            />
          )}
        </DragOverlay>
        <AddWidgetDrawer />
        <WidgetConfigurationDrawer />
        <WidgetContainer ref={containerRef}>
          {isRendered && (
            <ResponsiveGridLayout
              cols={{
                sm: 1,
                lg: colNumber
              }}
              breakpoints={{
                sm: 0,
                lg: 800
              }}
              maxRows={rowsNumber}
              width={gridWidth}
              rowHeight={gridHeight / rowsNumber}
              useCSSTransforms={!deskState.isFullScreen} // Il trasform css blocca il fullscreen del widget
              // preventCollision={true}
              margin={[0, 0]}
              droppingItem={droppingItem}
              resizeHandles={['se', 'e', 's']}
              draggableCancel=".no-drag"
              autoSize={false}
              compactType={'horizontal'}
              isResizable={deskState.isEditable}
              isDraggable={deskState.isEditable}
              isDroppable={deskState.isEditable}
              layouts={gridChildren.layout}
              onLayoutChange={handleLayoutChange}
              onDrop={handleDrop}
            >
              {gridChildren.children}
            </ResponsiveGridLayout>
          )}
          {deskState.isEditable && !droppingItem && gridFreeSlots}
        </WidgetContainer>
        {!deskState.isEditable &&
          desk != null &&
          gridWidgets.length === 0 &&
          !isLoading && <DeskEmptyPlaceholder />}
        <FullScreenBackdrop
          className={clx({
            fullscreen: deskState.isFullScreen
          })}
        />
        <ActionContainer>
          <Space size={12}>
            {widgetRepository.actions
              .filter(a => a.showInBar)
              .map(action => {
                return <ActionCard key={action.code} action={action} />;
              })}
            {/* {deskState.isEditable && (
              <Space size={8}>
                <Button shape="circle" icon={<EditOutlined />} />
                <Button shape="circle" icon={<PlusOutlined />} />
              </Space>
            )} */}
          </Space>
        </ActionContainer>
        <FloatingWidgetArea
          handleRestore={widget => handleRestore(widget)}
          setDroppingItem={() => setDroppingItem(undefined)}
        />
        {/* <FloatWidgetContainer>
          {transitions((props, widget) => (
            <animated.div
              style={props}
              key={widget.id + '-parked'}
              draggable={deskState.isEditable}
              unselectable="on"
              onDragStart={() => handleRestore(widget)}
              onDragEnd={() => setDroppingItem(undefined)}
            >
              <FloatWidget widget={widget} />
            </animated.div>
          ))}
        </FloatWidgetContainer> */}
        <ActionMessagesHub context={deskContext} />
      </DndContext>
    </>
  );
}

import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import {
  getToolkitObjectOfType,
  ToolkitObject
} from 'client/components/toolkit/objects/ToolkitObject';
import { DocumentToolkitObject } from 'client/components/widget-repository/objects/documents/DocumentToolkitObject';
import { UserToolkitObject } from 'client/components/widget-repository/objects/users/UserToolkitObject';
import { OfficeToolkitObject } from 'client/components/widget-repository/objects/offices/OfficeToolkitObject';
import { InferType } from 'yup';
import { message } from 'antd';
import { FvIconUserAssignment } from 'client/ui/icon/FvIcons';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { AssignmentToolkitObject } from 'client/components/widget-repository/objects/assignments/AssignmentToolkitObject';
import { getAllowedAssignmentTypes } from '../getAllowedAssignmentTypes';
import { AssignSchema } from '../schema/AssignSchema';
import { executeAssignDocument } from './executeAssignDocument';
import { isAssignmentStale } from 'client/components/widget-repository/objects/assignments/isAssignmentStale';
import { AssignmentPriorityRadioInput } from 'client/components/widget-repository/objects/assignments/AssignmentPriorityRadioInput';
import { AssignmentTypeSelectInput } from 'client/components/widget-repository/objects/assignments/AssignmentTypeSelectInput';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { HelpText } from 'client/ui/help-text/HelpText';
import { executeConcludeAssignment } from '../conclude/executeConcludeAssignment';
import {
  checkIfAssignmentPossible,
  ICheckIfAssignmentPossibleResult
} from './checkIfAssignmentPossible';
import { isAssignmentActionAllowed } from 'client/components/widget-repository/objects/assignments/isAssignmentActionAllowed';
import { isDocumentAccessible } from 'client/components/widget-repository/objects/documents/logic/isDocumentAccessible';

export const WidgetActionAssignDocument = createToolkitAction({
  name: 'Assegna',
  code: 'assign-document',
  icon: <FvIconUserAssignment />,
  showInBar: true,
  arguments: [
    {
      name: 'Documento da Assegnare',
      type: [DocumentToolkitObject, AssignmentToolkitObject],
      isArray: true,
      allows: (obj: ToolkitObject) =>
        !isAssignmentStale(obj.data) &&
        isAssignmentActionAllowed(obj) &&
        isDocumentAccessible(obj)
    },
    {
      name: 'Assegnato a',
      type: [UserToolkitObject, OfficeToolkitObject]
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    entityToAssignObjects: ToolkitObject[] | ToolkitObject,
    assigneeObject: ToolkitObject | null
  ) {
    let assignee = getToolkitObjectOfType(assigneeObject, [
      UserToolkitObject.code,
      OfficeToolkitObject.code
    ]);

    const assignments = AssignmentToolkitObject.getMany(entityToAssignObjects);
    const allowedAssignmentTypes = getAllowedAssignmentTypes(assignments);

    // È stato lasciato solo per retrocompatibilità, al momento non viene utilizzato
    let result: ICheckIfAssignmentPossibleResult | null =
      null as ICheckIfAssignmentPossibleResult | null;

    if (result?.type === 'error') return;

    const isAssigningFromAssignment =
      assignments != null && assignments.length > 0;

    // if (!isAssigningFromAssignment) {
    //   result = await checkIfAssignmentPossible(entityToAssignObjects);

    //   if (result.type === 'error') {
    //     return;
    //   }
    // }

    // Nel caso in cui parto da delle assegnazioni (assegnando a partire dal Widget delle assegnazioni)
    // verifico che tutte le assegnazioni abbiano un id
    // Nel caso in cui parto da un documento (assegnando a partire dal Widget protocolli)
    // devo aspettare il risultato del checkIfAssignmentPossible
    const isReassignment = isAssigningFromAssignment
      ? assignments.every(a => a.data.id != null)
      : result?.result.every(r => r.assignmentId != null) ?? false;

    const sourceWidgetId = Array.isArray(entityToAssignObjects)
      ? entityToAssignObjects[0].sourceWidgetId
      : entityToAssignObjects.sourceWidgetId;

    ctx.deskContext.actionMessages.prompt({
      ctx,
      action: WidgetActionAssignDocument,
      args: [entityToAssignObjects, assignee],
      confirmText: isReassignment ? 'Riassegna' : 'Assegna',
      schema: AssignSchema,
      initialValues: {
        fromAssignmentIds: result?.result.map(r => r.assignmentId) ?? [],
        priorityId: 3, // Media
        isReassignment
      },
      onConfirm,
      form: (
        <>
          <FormFieldsContainer>
            <FormFieldItem
              size="large"
              component={AssignmentTypeSelectInput}
              allowedTypes={allowedAssignmentTypes}
              widgetId={sourceWidgetId}
              label={null}
              name="modeId"
              placeholder="Tipo Assegnazione"
            />
            <FormFieldItem
              size="large"
              component={AssignmentPriorityRadioInput}
              label="Priorità"
              name="priorityId"
              widgetId={sourceWidgetId}
            />
            <FormFieldItem
              size="large"
              component={DatePickerInput}
              type="unix"
              label={null}
              name="expiresAt"
              placeholder="Data Scadenza"
            />
            <FormFieldItem
              size="large"
              component={TextAreaInput}
              label={null}
              name="notes"
              placeholder="Note"
            />
            {isReassignment && (
              <>
                <FormFieldItem
                  component={CheckboxInput}
                  label={null}
                  name="conclude"
                >
                  Concludi l'assegnazione
                </FormFieldItem>
                <HelpText style={{ paddingTop: '0px' }}>
                  Concludi l'assegnazione dopo averla riassegnata
                </HelpText>
              </>
            )}
          </FormFieldsContainer>
        </>
      )
    });
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: InferType<typeof AssignSchema>,
  entityToAssignObjects: ToolkitObject[] | ToolkitObject,
  assignee: ToolkitObject
) {
  const assignments = AssignmentToolkitObject.getMany(entityToAssignObjects);

  const isReassignment = values.isReassignment;

  try {
    await executeAssignDocument(values, assignee, entityToAssignObjects);
    message.success(
      `${
        isReassignment ? 'Riassegnazione' : 'Assegnazione'
      } effettuata con successo`
    );
  } catch (e: any) {
    message.error(
      getNetworkErrorMessage(
        e,
        `Errore durante ${
          isReassignment ? 'la riassegnazione' : "l'assegnazione"
        }.`
      )
    );
    console.error(e);
    return false;
  }

  if (isReassignment && values.conclude) {
    try {
      // Conclusione dell'assegnazione
      await executeConcludeAssignment(assignments);
      message.success('Conclusione effettuata con successo');
    } catch (e: any) {
      message.error(
        getNetworkErrorMessage(e, 'Errore durante la conclusione.')
      );
      console.error(e);
      return false;
    }
  }
}

import React from 'react';
import styled, { css } from 'styled-components';

export const MonitorScroller = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: 0;
  max-height: 100%;
  min-width: 0;
`;

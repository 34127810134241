import * as React from 'react';
import { Card, CardProps, Typography } from 'antd';
import styled from 'styled-components';
import clx from 'classnames';
import { LoginLogo } from './LoginLogo';
import { WidgetWrapper } from 'client/components/schema/widget/card/base/WidgetWrapper';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';

const { Title, Text, Paragraph } = Typography;

const WelcomeCard = styled(Card)`
  height: 100%;
  background-color: ${props =>
    props.theme.loginLogoBackground ?? props.theme.primaryColor};
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  .ant-card-body {
    padding: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .logo-wrapper {
      flex-grow: 1;
    }
    .body-wrapper {
      flex-grow: 0;
    }
    .ant-typography {
      color: #fff;
      max-width: 450px;
      &.small {
        font-size: 12px;
        text-transform: uppercase;
        padding-top: 24px;
        margin: 0;
      }
    }
  }

  &.logo-only {
    .ant-card-body {
      height: 100%;
      justify-content: center;
      align-items: center;
      .logo-wrapper {
        flex-grow: 0;
      }
    }
  }
`;

export interface LoginWelcomeMessageProps extends Omit<CardProps, 'bordered'> {
  showMessage?: boolean;
}

/**
 * Messaggio di Welcome del login
 */
export function LoginWelcomeMessage(props: LoginWelcomeMessageProps) {
  const isMobile = useBreakpoint(down('sm'));
  return (
    <WidgetWrapper>
      <WelcomeCard
        bordered={false}
        className={clx({
          'logo-only': !props.showMessage
        })}
      >
        <LoginLogo className="logo-wrapper" />

        {!isMobile && props.showMessage && (
          <div className="body-wrapper">
            <Title level={3}>Welcome</Title>
            <Paragraph>
              <Text>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. In erat
                mauris, faucibus quis pharetra sit amet, pretium ac libero.
                Etiam vehicula eleifend bibendum.
              </Text>
            </Paragraph>
            <Paragraph className="small">
              <Text>Lorem ipsum dolor sit amet</Text>
            </Paragraph>
          </div>
        )}
      </WelcomeCard>
    </WidgetWrapper>
  );
}

import {
  GetDocumentaryStatisticsAssignmentsParams,
  Widget
} from 'client/api/backend/schemas';
import { applyDynamicDateRange } from 'client/components/toolkit/components/filter/dynamic/DynamicDateRanges';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';

/**
 * Intefaccia specifica dei filtri "di default" impostati da questo widget
 */
export interface IWidgetMonitorOfficeAssignmentsFilterData
  extends Pick<
    GetDocumentaryStatisticsAssignmentsParams,
    'mode' | 'sent' | 'type' | 'officeId'
  > {
  dynamicStartDate: string;
  dynamicEndDate: string;
}

export function applyWidgetMonitorOfficeAssignmentsDefaultFilters(
  widget: Widget
): Partial<GetDocumentaryStatisticsAssignmentsParams> {
  const filters = widget.profile
    ?.filters as IWidgetMonitorOfficeAssignmentsFilterData | null;
  if (!filters) return {};

  const { dynamicStartDate, dynamicEndDate, ...standardParams } = filters;
  return {
    ...standardParams,
    dateStart: applyDynamicDateRange(dynamicStartDate)?.valueOf(),
    dateEnd: applyDynamicDateRange(dynamicEndDate)?.valueOf()
  };
}

import React from 'react';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import { useGetDocumentaryDocumentsSignDomains } from 'client/api/backend/documentary-documents/documentary-documents';

interface Props extends SelectInputProps<any> {
  widgetId: number;
}

export function DocumentSignDomainSelectInput(props: Props) {
  const { widgetId, ...otherProps } = props;

  const result = useGetDocumentaryDocumentsSignDomains({
    widgetId: props.widgetId
  });

  const domains = Array.isArray(result.data) ? result.data : [];

  const options = domains.map(t => ({
    label: t,
    value: t!
  }));

  return (
    <SelectInput allowClear showSearch options={options} {...otherProps} />
  );
}

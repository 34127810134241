import * as React from 'react';
import { useRelationEditor } from 'client/components/form/relations/useRelationEditor';
import { Divider, Dropdown, Menu, Space } from 'antd';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { VerticalSpace } from 'client/ui/space/VerticalSpace';
import { useWidgetContext } from 'client/components/schema/widget/context/WidgetContext';
import { DeskProceedingFase } from 'client/api/backend/schemas';
import { ProceedingPhaseInput } from './ProceedingPhaseInput';

export interface IProceedingPhasesInputProps {
  name: string;
}

export function ProceedingPhasesInput(props: IProceedingPhasesInputProps) {
  const { name } = props;
  const editor = useRelationEditor<DeskProceedingFase>(name);

  return (
    <div>
      <Space align="end">
        <div>
          <ButtonIcon
            size="small"
            icon={<PlusOutlined />}
            onClick={e => {
              editor.add({});
            }}
          >
            Aggiungi Fase
          </ButtonIcon>
        </div>
      </Space>
      <VerticalSpace size={8} />
      {editor.items.map((item, index) => {
        return (
          <div key={index}>
            <Divider
              orientation="left"
              children={
                <Space>
                  Fase {index + 1}
                  <ButtonIcon
                    icon={<DeleteOutlined />}
                    tooltip={`Rimuovi Fase`}
                    danger
                    onClick={() => editor.remove(item)}
                  />
                </Space>
              }
            />
            <ProceedingPhaseInput fieldNamePrefix={`${name}.${index}`} />
          </div>
        );
      })}
    </div>
  );
}

import { createGlobalStyle } from 'styled-components';

// Importato in Index.tsx
export const RichTextInputGlobalStyles = createGlobalStyle`

// RichTextInput
.ql-toolbar {
  line-height: initial;
}


`;

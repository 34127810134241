import { DeskCorrespondent } from 'client/api/backend/schemas';

export const CorrespondentKey = {
  Person: 'PF',
  Organization: 'PG',
  PublicAdministration: 'PAI',
  ForeignPublicAdministration: 'PAE'
};

export function getCorrespondentDefaults(key: string): DeskCorrespondent {
  switch (key) {
    case CorrespondentKey.Person:
      return { type: CorrespondentKey.Person, person: {} };
    case CorrespondentKey.Organization:
      return { type: CorrespondentKey.Organization, corporate: {} };
    case CorrespondentKey.PublicAdministration:
      return {
        type: CorrespondentKey.PublicAdministration,
        administration: {}
      };
    case CorrespondentKey.ForeignPublicAdministration:
      return {
        type: CorrespondentKey.ForeignPublicAdministration,
        administration: {}
      };
    default: {
      console.warn(`[Correspondent] type ${key} not handled`);
      return { type: key };
    }
  }
}

import * as React from 'react';
import clx from 'classnames';
import styled from 'styled-components';
import { Typography, Space, Button, Divider, Radio } from 'antd';
import { IColor } from 'client/components/schema/colors/Colors';
import { CheckOutlined } from '@ant-design/icons';

const BaseBlock = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 19px;
  margin: 0 auto;
  border: 4px solid #fff;
  transition: all 0.1s; // cubic-bezier(0.645, 0.045, 0.355, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  .anticon {
    display: block;
    opacity: 0;
    color: #fff;
  }
  &:hover {
    border-width: 2px;
  }
  .ant-radio-button-wrapper-checked & {
    border-radius: 0;
    border-width: 0;
    border-color: transparent;
    .anticon {
      opacity: 1;
      transition: opacity 0.15s;
    }
  }
`;

const Block = styled(BaseBlock)<{ color: string }>`
  background-color: ${props => props.color};
`;

const RadioButton = styled(Radio.Button)`
  /* flex-grow: 1; */
  padding: 0;
  border-color: #fff;
  &::before,
  &::after,
  &:first-child::before,
  &:not(:first-child)::before {
    background-color: #fff;
    display: none;
  }
  &:first-child,
  &:not([class*=' ant-radio-button-wrapper-disabled']),
  &.ant-radio-button-checked {
    border-color: #fff;
    box-shadow: none;
    &:focus-within,
    &:hover,
    &:active {
      border-color: #fff;
      box-shadow: none;
      &::before {
        background-color: #fff;
      }
    }
  }
  &.ant-radio-button-wrapper-checked {
    &:not(.ant-radio-button-wrapper-disabled),
    &:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
      color: transparent;
      border-color: #fff;
    }
  }
`;

export interface ColorOptionProps {
  color: IColor;
}

/**
 * //TODO
 */
export function ColorOption(props: ColorOptionProps) {
  const { color } = props;
  return (
    <RadioButton value={color.id}>
      <Block color={color.color}>
        <CheckOutlined />
      </Block>
    </RadioButton>
  );
}

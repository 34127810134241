import {
  ConstantDamParamsType,
  ApiParamsType,
  IGraphViewParameter
} from 'client/components/widget-repository/objects/dams/DamParams';

/**
 * Restituisce l'id dell'asse y a cui associare il parametro.
 * Nel caso in cui ci siano più unità di misura, restituisce 'y' o 'y1' in base al parametro.
 */
export function getParamYAxisId(
  param: IGraphViewParameter,
  selectedParams: IGraphViewParameter[]
): string {
  // Se c'è solo un unità di misura, restituiamo sempre 'y'
  const unitSet = new Set(selectedParams.map(p => p.unit));
  if (unitSet.size === 1) {
    return 'y';
  }

  switch (param.codeApi) {
    // Associamo il livello invaso e i parametri costanti ad esso associati a 'y'
    case ApiParamsType.LivelloInvaso:
    case ConstantDamParamsType.QuotaMax:
    case ConstantDamParamsType.QuotaMaxRegulation:
      return 'y';
    case ApiParamsType.VolumeInvaso:
      // Se il volume invaso è accoppiato ai valori di portata, lo associamo a 'y
      // altrimenti a 'y1'
      if (
        selectedParams.some(
          p =>
            p.codeApi === ApiParamsType.PortataAdduzione ||
            p.codeApi === ApiParamsType.PortataDerivata ||
            p.codeApi === ApiParamsType.PortataScaricata
        )
      ) {
        return 'y';
      }
      return 'y1';
    // Associamo le portate sempre a 'y1'
    case ApiParamsType.PortataScaricata:
    case ApiParamsType.PortataAdduzione:
    case ApiParamsType.PortataDerivata:
      return 'y1';
    default:
      return 'y';
  }
}

const yScale = {
  type: 'linear',
  display: true,
  position: 'left',
  id: 'y'
};

const y1Scale = {
  type: 'linear',
  display: true,
  position: 'right',
  id: 'y1',
  grid: {
    drawOnChartArea: false
  }
};

/**
 * Restituisce le scale dell'asse y a seconda dei datasets.
 */
export function getScalesForDatasets(parameters: IGraphViewParameter[]) {
  const unitSet = new Set(parameters.map(p => p.unit));

  // Se nei dataset ci sono più unità di misura, restituiamo entrambe le scale
  if (unitSet.size > 1) {
    return {
      y: yScale,
      y1: y1Scale
    };
  }

  return {
    y: yScale
  };
}

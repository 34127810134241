import * as React from 'react';
import styled from 'styled-components';
import Table, { ColumnProps, TableProps } from 'antd/lib/table';
import { TableData } from 'client/ui/table/TableData';
import useDimensions from 'react-cool-dimensions';
import { useState } from 'react';

const TableWrapper = styled.div`
  flex: 1 1 auto;
  min-height: 0;
  overflow-y: hidden;
  > div:first-child {
    background-color: transparent;
  }
  .ant-table {
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: ${props => props.theme.borderColorBase};
      margin-top: -1px;
    }
  }
`;

const BaseSize = {
  pagination: 24 + 32,
  headerSmall: 29,
  headerLarge: 45,
  addSpace: 4
};
const SpaceToSubctract =
  BaseSize.pagination + BaseSize.headerSmall + BaseSize.addSpace;

export interface TableDataScrollProps<T>
  extends Omit<TableProps<T>, 'scroll' | 'size' | 'loading'> {
  loading?: boolean;
}
/**
 * Tabella con stili e paginazione personalizzati e scroolabile
 */
export function TableDataScroll<T extends {}>(props: TableDataScrollProps<T>) {
  const [tableHeight, setTableHeignt] = useState(300);

  const { observe, unobserve, width, height, entry } = useDimensions({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      // Triggered whenever the size of the target is changed...

      console.log('resize', { observe, unobserve, width, height, entry });

      setTableHeignt(height - SpaceToSubctract);
      unobserve(); // To stop observing the current target element
      observe(); // To re-start observing the current target element
    }
  });

  return (
    <TableWrapper ref={observe}>
      <TableData<T> scroll={{ y: tableHeight }} size="small" {...props} />
    </TableWrapper>
  );
}

import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { appConfig } from 'client/core/appConfig';
import qs from 'qs';
// import { authInterceptor } from '../interceptors/authInterceptor';
import { errorsInterceptor } from '../interceptors/errorsInterceptor';
import { refreshTokenInterceptor } from '../interceptors/refreshTokenInterceptor';
import { retryInterceptor } from '../interceptors/retryInterceptor';
import { joinPath } from '../joinPath';
import { apiClientMockDevelopment } from './apiClientMockDevelopment';

export const apiClient = axios.create({
  baseURL: appConfig.remotePath,
  withCredentials: true,
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  }
});

refreshTokenInterceptor(apiClient);
errorsInterceptor(apiClient);

if (process.env.NODE_ENV === 'development') {
  apiClientMockDevelopment(apiClient);
}

/**
 * Esposizione ad `orval` di un metodo specifico per effettuare le chiamate
 * API tramite la nostra istanza personalizzata di `axios`.
 */
export const apiInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  return apiClient.request(config).then(({ data }) => data);
};

export type ErrorType<Error> = AxiosError<Error>;

import { FileSearchOutlined } from '@ant-design/icons';
import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetFullTextDocumentsSearch } from './WidgetFullTextDocumentsSearch';

export const WidgetFullTextDocumentsSearchType: IWidgetType = {
  code: 'full-text-documents-search',
  name: 'Ricerca Documenti Full-Text',
  icon: <FileSearchOutlined />,
  component: WidgetFullTextDocumentsSearch,
  description: `Ricerca i documenti protocollati all'interno dei documentale`
};

WidgetRepository.register(WidgetFullTextDocumentsSearchType);

import { Alert, Button, Space } from 'antd';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikForm } from 'client/ui/form/FormikForm';
import React from 'react';
import { RoleSelectInput } from '../../profile/RoleSelectInput';
import { UserSelectInput } from '../UserSelectInput';

interface DeskCopyToProfileFormProps {
  onClose?: () => void;
}

export function DeskCopyToProfileForm(props: DeskCopyToProfileFormProps) {
  return (
    <FormikForm editable helpInTooltips layout="vertical">
      <Alert
        message="Effettuando la copia delle scrivanie verranno eliminate tutte le scrivanie attualmente disponibili degli utenti con il Profilo selezionato."
        type="warning"
        showIcon
        closable
        style={{ marginBottom: '12px' }}
      />
      <FormFieldsContainer>
        <FormFieldItem
          component={UserSelectInput}
          name="userId"
          label="Utente da cui copiare le scrivanie"
          size="large"
          placeholder="Utente"
          showSearch
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormFieldItem
          component={RoleSelectInput}
          name="roleId"
          label="Profilo degli utenti su cui copiare le scrivanie"
          size="large"
          placeholder="Profilo"
          showSearch
        />
      </FormFieldsContainer>
      <Space
        style={{ marginTop: '12px', width: '100%', justifyContent: 'flex-end' }}
      >
        <Button size="large" onClick={props.onClose}>
          Annulla
        </Button>
        <FormikSendButton as={Button} title="Copia Scrivania" size="large">
          Avvia Copia
        </FormikSendButton>
      </Space>
    </FormikForm>
  );
}

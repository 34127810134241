import { useGetDocumentaryMailsBoxes } from 'client/api/backend/documentary-mails/documentary-mails';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import React from 'react';

interface IMailBoxesSelectInputProps extends SelectInputProps<any> {
  widgetId: number;
}

export function MailBoxesSelectInput(props: IMailBoxesSelectInputProps) {
  const { widgetId, ...otherProps } = props;

  const boxesResult = useGetDocumentaryMailsBoxes({
    widgetId: props.widgetId
  });

  return (
    <SelectInput
      allowClear
      showSearch
      loading={boxesResult.isLoading}
      options={boxesResult.data?.map(box => {
        return { value: box.id!, label: box.address ?? `Casella ID ${box.id}` };
      })}
      {...otherProps}
    />
  );
}

import { IHistoryTag } from 'client/components/toolkit/components/history/HistoryTag';

export function convertIdTypeToReasonTagComponents(
  idType?: string
): IHistoryTag {
  switch (idType) {
    case AssignmentType.Competenza:
      return { label: 'Competenza', color: '#ea5545' };
    case AssignmentType.Conoscenza:
      return { label: 'Conoscenza', color: '#47ae90' };
    case AssignmentType.Smistamento:
      return { label: 'Smistamento', color: '#ef9b20' };
    default:
      return { label: '', color: '', isHidden: true };
  }
}

export function mapIdType(idType?: string) {
  return convertIdTypeToReasonTagComponents(idType).label;
}

export enum AssignmentType {
  Competenza = '0',
  Conoscenza = '1',
  Smistamento = '2'
}

import { FolderAddOutlined } from '@ant-design/icons';
import { DeskWidgetsLogic } from 'client/components/schema/desk/logic/DeskWidgetsLogic';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import React from 'react';
import { WidgetDossiersSearchType } from '../../dossiers-search/WidgetDossiersSearch.type';
import { WidgetDossierCreateType } from '../WidgetDossierCreate.type';

export const WidgetActionCreateDossier = createToolkitAction({
  name: 'Crea nuovo Fascicolo',
  code: 'create-dossier',
  icon: <FolderAddOutlined />,
  arguments: [
    {
      type: [WidgetToolkitObject],
      name: 'Widget Ricerca Fascicoli',
      allows: (object: ToolkitObject) =>
        WidgetToolkitObject.matches(object, WidgetDossiersSearchType)
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    dossierSearchObject: ToolkitObject | null
  ) {
    const dossierSearch = WidgetToolkitObject.get(dossierSearchObject);

    if (!dossierSearch) {
      await ctx.deskContext.createWidget(
        {
          type: 'floating',
          expanded: true,
          order: DeskWidgetsLogic.getFloatingNextOrder(
            ctx.deskContext.floatingWidgets
          )
        },
        WidgetDossierCreateType,
        { quickClose: true }
      );
      return;
    }

    await ctx.deskContext.cloneWidget(
      {
        type: 'floating',
        expanded: true,
        order: DeskWidgetsLogic.getFloatingNextOrder(
          ctx.deskContext.floatingWidgets
        )
      },
      WidgetDossierCreateType,
      ctx.sourceWidget!,
      { quickClose: true }
    );
  }
});

import { registerToolkitObjectType } from 'client/components/toolkit/objects/registerToolkitObjectType';

export interface HighlightedDeskDossier {
  id?: number;
  highlighted?: {
    notes?: string;
  };
}

export const HighlightedDossierToolkitObject = registerToolkitObjectType<{}>(
  'highlighted-dossier'
);

import { Widget } from 'client/api/backend/schemas';
import { Layout } from 'react-grid-layout';
import { DeskWidgetsLogic } from '../../desk/logic/DeskWidgetsLogic';
import { GridConstants } from '../../desk/space/grid/GridConstants';
import { WidgetPosition, WidgetPositionGrid } from '../schema/WidgetPosition';

export function widgetToLayout(widget: Widget): Layout {
  if (!widget.profile?.position) {
    throw new Error(`Il widget non è configurato correttamente.`);
  }

  if (widget.profile.position.type !== 'grid') {
    throw new Error(`Un widget flottante non può essere visualizzato sulla griglia.`); // prettier-ignore
  }

  const position = widget.profile.position;
  return {
    i: widget.id!.toString(),
    x: position.x,
    y: position.y,
    w: position.w,
    h: position.h,
    minH: position.minH,
    minW: position.minW
  };
}

// TODO: Forse dobbiamo togliere lo spread?
export function layoutToWidgetPositionGrid(
  widget: Widget,
  layout: Layout
): WidgetPositionGrid {
  return {
    ...(widget.profile?.position! as WidgetPositionGrid),
    x: layout.x,
    y: layout.y,
    w: layout.w,
    h: layout.h
  };
}

interface WidgetLayoutInfo {
  rowsNumber: number;
  nextOrder: number;
}

/**
 * Calcoliamo la posizione a partire dal Layout di react-grid-layout.
 * Gestisce il caso in cui il widget sia "fuori" dalla griglia.
 */
export function layoutToWidgetPosition(
  widget: Widget,
  layout: Layout,
  info: WidgetLayoutInfo
): WidgetPosition {
  // Tutti i Widget con i > rows sono "fuori"
  // dalla griglia e devono essere spostati.
  if (layout.y >= info.rowsNumber) {
    if (widget.profile?.position?.type === 'floating') {
      return widget.profile.position;
    }

    return {
      type: 'floating',
      order: info.nextOrder,
      expanded: false,
      x: layout.x,
      y: layout.y,
      w: layout.w,
      h: layout.h,
      minW: widget.profile?.position?.minW,
      minH: widget.profile?.position?.minH
    };
  }

  return {
    type: 'grid',
    x: layout.x,
    y: layout.y,
    w: layout.w,
    h: layout.h,
    minW: widget.profile?.position?.minW ?? GridConstants.WidgetMinWidth,
    minH: widget.profile?.position?.minH ?? GridConstants.WidgetMinHeight
  };
}

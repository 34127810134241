import React from 'react';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import {
  SelectInput,
  SelectInputProps,
  SelectOption
} from 'client/ui/form/input/SelectInput';
import { stateOptions, StateTag } from 'client/ui/state/StateTag';
import { useField } from 'formik';

interface IAssignmentMonitoringTypeSelectInputProps
  extends SelectInputProps<any> {}

export function AssignmentMonitoringTypeSelectInput(
  props: IAssignmentMonitoringTypeSelectInputProps
) {
  const { name } = props;
  const [field, meta, helpers] = useField<string | null>(name);

  const assignmentTypologyOptions = [
    { label: 'Inviate', value: 'true' },
    { label: 'Ricevute', value: 'false' }
  ];

  return (
    <SelectInput
      allowClear
      options={assignmentTypologyOptions}
      value={field.value?.toString()}
      //   renderOption={(option: SelectOption) => (
      //     <StateTag enabled={option.value === 'true'} />
      //   )}
      {...props}
    />
  );
}

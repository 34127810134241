import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { BookmarkToolkitObject } from 'client/components/widget-repository/objects/bookmarks/BookmarksToolkitObject';
import { ExportOutlined } from '@ant-design/icons';

export const WidgetActionOpenBookmark = createToolkitAction({
  name: 'Apri Preferito',
  code: 'open-bookmark',
  icon: <ExportOutlined />,
  isPreferential: true,
  arguments: [
    {
      type: [BookmarkToolkitObject],
      name: 'Preferito'
    }
  ],
  async execute(ctx: IToolkitActionContext, bookmarkObject: ToolkitObject) {
    const bookmark = BookmarkToolkitObject.get(bookmarkObject)!;

    onConfirm(ctx, {}, bookmark);
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: any,
  bookmark: ToolkitObject
) {
  window.open(
    bookmark.data.url.startsWith('http')
      ? bookmark.data.url
      : `https://${bookmark.data.url}`,
    '_blank'
  );
}

import { DeskExtendedAttributeType } from 'client/api/backend/schemas';
import { yup } from 'common/validation/initYup';
import { fromPairs } from 'lodash';

export function getExtendedAttributesSchema(
  attributesTypes: DeskExtendedAttributeType[]
) {
  return yup.object(
    fromPairs(
      attributesTypes.map(type => {
        let baseSchema = getTypeSchema(type.type);

        let schema = type.isMultiple
          ? yup.array(baseSchema).nullable()
          : baseSchema.nullable();

        if (type.required) {
          schema = schema.required();
        } else {
          schema = schema.notRequired();
        }

        schema = schema.label(type.label ?? '');

        return [type.name, schema];
      })
    )
  );
}

function getTypeSchema(type?: string) {
  switch (type) {
    case 'string':
      return yup.string();
    case 'integer':
      return yup.mixed();
    case 'date':
      return yup.string();
    default:
      return yup.string();
  }
}

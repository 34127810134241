import * as React from 'react';
import { FormItem } from '../FormItem';
import styled from 'styled-components';

export const FormFieldItemStyle = styled(FormItem)`
  display: flex;
  flex-wrap: nowrap;
  border-bottom: solid 1px #f0f0f0;
  min-height: 34px;
  align-items: baseline;
  align-items: center;
  align-items: start;

  // indicatore visivo campo richiesto in sostituzione di quello di Ant
  label.ant-form-item-required {
    &::before {
      display: none !important;
    }
    &::after {
      display: inline-block;
      // margin-right: 0;
      color: #ff4d4f;
      // color: $color-negative;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      font-weight: 900;
      line-height: 1;
      content: '*';
    }
  }

  &.ghost {
    border-bottom: solid 1px transparent;
  }
  // in caso di posizionamento all'interno di un altro form-field
  // eliminimamo il padding in altezza
  &.nested {
    border-bottom-width: 0;
    > .ant-col {
      > label,
      > .ant-form-item-control-input {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
  .ant-form-item-control {
    flex: 1 1 auto;
    /* overflow: hidden; // aggiunto per gestire l'ellipsiss */
    .ant-form-item-control-input {
      padding: 4px 0;
      padding-left: 4px;
    }
  }
  &.editable {
    .ant-form-item-control {
      .ant-form-item-control-input {
        padding-left: 0;
      }
    }
  }

  // Fix altezza Tag in SelectInput
  .ant-select-selection-item {
    > .ant-tag {
      line-height: 16px;
      vertical-align: text-top;
    }
  }

  // Riduzione spazio verticale se si visualizzano i massaggi nei tooltip
  &.help-in-tooltips {
    margin-bottom: 8px;
  }

  .ant-form-item-label {
    flex-grow: 0;
    flex-shrink: 0;
    > label {
      font-size: 0.9em;
      color: ${props => props.theme.textColorSecondary};
      height: auto;
      min-height: 32px;
    }
  }
  // Form layout vertical
  &.ant-row.ant-form-item {
    .ant-col {
      &.ant-form-item-label,
      &.ant-form-item-control {
        .ant-form-vertical & {
          width: 100%;
          flex-basis: 100%;
          flex: 0 0 100%;
        }
        > label {
          .ant-form-vertical & {
            min-height: unset;
            padding-left: 4px;
          }
        }
      }
    }
  }

  &.width-inline {
    > .ant-form-item-label {
      width: auto;
      flex-basis: auto;
      padding-right: 2px;
    }
  }

  // Form layout horizontal
  &.width-70 {
    > .ant-form-item-label {
      width: 70px;
      flex-basis: 70px;
    }
  }
  &.width-100 {
    > .ant-form-item-label {
      width: 100px;
      flex-basis: 100px;
    }
  }
  &.width-120 {
    > .ant-form-item-label {
      width: 120px;
      flex-basis: 120px;
    }
  }
  &.width-140 {
    > .ant-form-item-label {
      width: 140px;
      flex-basis: 140px;
    }
  }
  &.width-160 {
    > .ant-form-item-label {
      width: 160px;
      flex-basis: 160px;
    }
  }
  &.width-180 {
    > .ant-form-item-label {
      width: 180px;
      flex-basis: 180px;
    }
  }
  &.width-200 {
    > .ant-form-item-label {
      width: 200px;
      flex-basis: 200px;
    }
  }
  &.width-220 {
    > .ant-form-item-label {
      width: 220px;
      flex-basis: 220px;
    }
  }
`;

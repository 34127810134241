import { DeskAssignment } from 'client/api/backend/schemas';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { AssignmentType } from 'client/components/widget-repository/objects/assignments/mapIdType';

/**
 * Restituisce i tipi di assegnazione ammessi a seconda del tipo di assegnazione
 * Assegnazione per Conoscenza -> riassegnazione solo per Conoscenza
 */
export function getAllowedAssignmentTypes(
  assignments: ToolkitObject<DeskAssignment>[] | null
) {
  const typeIds =
    assignments && assignments.map(a => a.data.idType?.toString());

  if (typeIds?.some(t => t === AssignmentType.Conoscenza)) {
    return [AssignmentType.Conoscenza];
  }

  return null;
}

import { TagOutlined } from '@ant-design/icons';
import { DeskClassificationNode } from 'client/api/backend/schemas';
import {
  IToolkitObjectFetchOptions,
  registerToolkitObjectType
} from 'client/components/toolkit/objects/registerToolkitObjectType';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { queryClient } from 'client/core/network/queryClient';
import React from 'react';
import memoizeOne from 'memoize-one';
import {
  getDocumentaryDossiersClassifications,
  getGetDocumentaryDossiersClassificationsQueryKey
} from 'client/api/backend/documentary-dossiers/documentary-dossiers';
import {
  getDocumentaryDocumentsClassifications,
  getGetDocumentaryDocumentsClassificationsQueryKey
} from 'client/api/backend/documentary-documents/documentary-documents';
import { ClassificationParamsType } from './ClassificationParamsType';

export const ClassificationNodeToolkitObject =
  registerToolkitObjectType<DeskClassificationNode>('classification-node', {
    fetchObjects
  });

async function fetchObjects({
  widgetId,
  search,
  params
}: IToolkitObjectFetchOptions): Promise<
  ToolkitObject<DeskClassificationNode>[]
> {
  let nodes: DeskClassificationNode[];

  switch (params?.type) {
    case ClassificationParamsType.Dossier: {
      nodes = await queryClient.fetchQuery(
        getGetDocumentaryDossiersClassificationsQueryKey({
          widgetId
        }),
        () => getDocumentaryDossiersClassifications({ widgetId }),
        { staleTime: 1_800_000 } // 30 Minuti
      );
      break;
    }
    case ClassificationParamsType.Document:
    default: {
      if (params?.type == null) {
        console.warn(
          'ClassificationNodeToolkitObject: type not recognized, using default'
        );
      }
      nodes = await queryClient.fetchQuery(
        getGetDocumentaryDocumentsClassificationsQueryKey({
          widgetId
        }),
        () => getDocumentaryDocumentsClassifications({ widgetId }),
        { staleTime: 1_800_000 } // 30 Minuti
      );
    }
  }

  const allNodes = parseNodes(widgetId, nodes);
  return filterNodes(allNodes, search);
}

const parseNodes = memoizeOne(
  (widgetId: number, nodes: DeskClassificationNode[]) =>
    nodes.map(node =>
      ClassificationNodeToolkitObject.create({
        id: node.id!,
        name: `${node.path ?? node.code} - ${node.description ?? 'N.A.'}`,
        icon: <TagOutlined />,
        data: node,
        sourceWidgetId: widgetId
      })
    )
);

const filterNodes = memoizeOne(
  (nodes: ToolkitObject<DeskClassificationNode>[], search?: string) => {
    // Lazy-evaluation per evitare di filtrare tutti i nodi, invece ci
    // fermiamo ai primi 20 risultati
    let filtered: ToolkitObject<DeskClassificationNode>[] = [];

    for (const node of nodes) {
      if (node.name.toLowerCase().includes((search ?? '').toLowerCase())) {
        filtered.push(node);
      }
    }

    return filtered;
  }
);

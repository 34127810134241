import React, { useState } from 'react';
import { Popconfirm, Button } from 'antd';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import { DeleteOutlined } from '@ant-design/icons';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { ButtonType } from 'antd/lib/button';

export interface ButtonDeleteConfirmProps extends PopconfirmProps {
  loading?: boolean;
  size?: SizeType;
  iconOnlyButton?: boolean;
  buttonType?: ButtonType;
  buttonIcon?: React.ReactNode;
  buttonDanger?: boolean;
  disableConfirmButton?: boolean;
  skipConfirm?: boolean;
}

export function ButtonDeleteConfirm(props: ButtonDeleteConfirmProps) {
  const {
    placement,
    okText,
    cancelText,
    loading,
    size,
    buttonType,
    buttonIcon,
    iconOnlyButton,
    buttonDanger,
    children,
    skipConfirm,
    ...otherProps
  } = props;

  const [visible, setVisible] = useState(false);

  /**
   * Se dobbiamo saltare il confirm (quindi è  "diretto"), quando Ant triggera
   * l'evento di visibilità, eseguiamo il comando al posto di cambiare la
   * visibilità.
   */
  const handleVisibleChange = (isVisible: boolean) => {
    // La chiusura è sempre gestita
    if (!isVisible) {
      setVisible(isVisible);
      return;
    }

    if (skipConfirm) {
      props.onConfirm?.(); // Saltiamo il popconfirm
    } else {
      setVisible(isVisible);
    }
  };

  return (
    <Popconfirm
      visible={visible}
      onVisibleChange={handleVisibleChange}
      placement={placement ?? 'topRight'}
      okText={okText ?? 'Elimina'}
      cancelText={cancelText ?? 'Annulla'}
      {...otherProps}
      okButtonProps={{
        danger: true,
        type: 'primary',
        disabled: props.disableConfirmButton
      }}
    >
      {children ?? (
        <Button
          disabled={props.disabled}
          type={buttonType ?? 'primary'}
          icon={buttonIcon ?? <DeleteOutlined />}
          size={size ?? 'small'}
          loading={loading}
          danger={buttonDanger}
        >
          {iconOnlyButton ? '' : okText ?? 'Elimina'}
        </Button>
      )}
    </Popconfirm>
  );
}

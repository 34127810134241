import { PublicClientApplication } from '@azure/msal-browser';
import { appConfig } from 'client/core/appConfig';

export const msalConfig = {
  auth: {
    clientId: appConfig.azureClientId,
    authority: `https://login.microsoftonline.com/${appConfig.azureTenantId}`, // URL (es. https://login.microsoftonline.com/<tenantId>)
    redirectUri: appConfig.azureRedirectUri
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: appConfig.azureAuthStateInCookie || false // Modificare a `true` se ci sono problemi su IE11
  }
};

// Scope per richiedere i dati dell'utente
export const loginRequest = {
  scopes: [`${appConfig.azureClientId}/.default`]
};

// export const graphConfig = {
//   graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
// };

export const msalInstance = new PublicClientApplication(msalConfig);

import { FvIconFileView } from 'client/ui/icon/FvIcons';
import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetActionClassifyDocument } from './actions/WidgetActionClassifyDocument';
import { WidgetActionOpenDocument } from './actions/WidgetActionOpenDocument';
import { WidgetDocumentViewer } from './WidgetDocumentViewer';
import { WidgetDocumentViewerProfile } from './WidgetDocumentViewerProfile';

export const WidgetDocumentViewerType: IWidgetType = {
  code: 'document-viewer',
  name: 'Visualizzatore Documento',
  icon: <FvIconFileView />,
  component: WidgetDocumentViewer,
  getTitle: widget => {
    const data = widget.profile?.data as
      | WidgetDocumentViewerProfile
      | undefined;
    return data?.documentSubject;
  },
  description: `Apre un documento per la sua visualizzazione`,
  actions: [WidgetActionOpenDocument]
};

WidgetRepository.register(WidgetDocumentViewerType);

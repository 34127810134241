import { useGetDocumentaryMailsAttachment } from 'client/api/backend/documentary-mails/documentary-mails';
import { DeskMailElement } from 'client/api/backend/schemas';
import React from 'react';
import { WidgetDocumentPreview } from '../document-viewer/WidgetDocumentPreview';

interface IWidgetMailAttachmentProps {
  widgetId: number;
  attachments?: DeskMailElement[];
  selectedKey: string;
}

export function WidgetMailAttachment(props: IWidgetMailAttachmentProps) {
  const { widgetId, attachments, selectedKey } = props;

  const selectedAttachment = attachments?.find(
    e => e.elementName === selectedKey
  );

  const attachmentResult = useGetDocumentaryMailsAttachment(
    {
      widgetId: widgetId,
      name: selectedAttachment?.elementName ?? '',
      path: selectedAttachment?.elementPath ?? '',
      mimeType: selectedAttachment?.mimeType ?? '',
      type: selectedAttachment?.elementType ?? ''
    },
    {
      query: {
        enabled: selectedKey != null
      }
    }
  );

  return (
    <WidgetDocumentPreview
      fileName={attachmentResult.data?.elementName}
      source={{
        type: 'content',
        content: attachmentResult.data?.content
      }}
    />
  );
}

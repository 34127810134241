import * as React from 'react';
import { IFixturesWidget } from 'client/fixtures/FixturesWidget';
import clx from 'classnames';
import styled from 'styled-components';
import { getHexColorById } from 'client/components/schema/colors/Colors';
import AppstoreOutlined from '@ant-design/icons/lib/icons/AppstoreOutlined';
import { useDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { DeskMarshaller } from 'client/components/schema/desk/logic/useDeskMutations';

const WidgetIconContainerBase = styled.div`
  height: 28px;
  width: 28px;
  min-width: 28px;
  float: left;
  margin-right: 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 1;
`;
const WidgetIconColor = styled(WidgetIconContainerBase)<{ color?: string }>`
  color: ${props => props.color ?? props.theme.primaryColor};
  &.float {
    color: ${props => props.theme.componentBackground};
    display: none;
  }
`;

export interface WidgetIconProps {
  icon?: React.ReactNode;
  float?: boolean;
}

/**
 * Icona del Widget stilizzato
 */
export function WidgetIcon(props: WidgetIconProps) {
  const { icon, float } = props;

  const { desk } = useDeskContext();
  const current = desk && DeskMarshaller.unmarshall(desk);

  return (
    <WidgetIconColor
      className={clx({ float })}
      color={getHexColorById(current?.profile?.colorId)}
    >
      {icon ?? <AppstoreOutlined />}
    </WidgetIconColor>
  );
}

import { cloneDeep } from 'lodash';
import { useEffect, useMemo } from 'react';
import { UseInfiniteQueryResult, UseQueryResult } from 'react-query';
import { useWidgetContext } from './WidgetContext';

export interface WidgetNetworkEvent {
  type: 'refetch';
}

/**
 * Hook low-level che permette di rimanere in ascolto sugli eventi del network
 * del Widget.
 */
export function useWidgetNetworkEffect(
  fn: (event: WidgetNetworkEvent) => void
) {
  const widgetContext = useWidgetContext();

  useEffect(() => {
    let subscription = widgetContext.networkSubject.current.subscribe(fn);
    return () => {
      subscription.unsubscribe();
    };
  }, [fn]);
}

/**
 * Versione semplificata di "useWidgetNetworkEffect" che gestisce automaticamente
 * la sincronizzazione dello stato delle singole query con lo stato globale
 * del Widget.
 */
export function useWidgetNetworkQueries(
  queries: Array<UseQueryResult | UseInfiniteQueryResult>
) {
  const { setIsFetching, setQueryCount, setQueryErrors } = useWidgetContext();

  // Numero di query registrate. Non dovrebbe mai cambiare, lo usiamo
  // per la prima registrazione per sapere se ci sono query da eseguire.
  useEffect(() => {
    setQueryCount(queries.length);
  }, [queries.length]);

  // Stato generale di caricamento
  useEffect(() => {
    setIsFetching(queries.some(q => q.isFetching));
  }, [...queries.map(q => q.isFetching)]);

  // Errori
  useEffect(() => {
    const errors = queries.map(q => q.error).filter(e => e != null) as Error[];
    setQueryErrors(errors.length > 0 ? cloneDeep(errors) : null);
  }, [...queries.map(q => q.error)]);

  // Refetch delle singole query
  useWidgetNetworkEffect(event => {
    switch (event.type) {
      case 'refetch': {
        queries.forEach(q => {
          // Se la query è in stato "idle" non ha senso rifetchare,
          // perché la query è stata inizializzata con stato `enabled: false`
          if (!q.isIdle) {
            q.refetch();
          }
        });
        break;
      }
    }
  });
}

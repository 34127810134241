import React, { useCallback } from 'react';
import { InputNumber, UploadProps, Upload } from 'antd';
import { FormikValues, useField } from 'formik';
import { useFormikFormContext } from '../FormikFormContext';
import { InboxOutlined } from '@ant-design/icons';
import { UploadInputWrapper } from './UploadInputWrapper';
import { DraggerProps } from 'antd/lib/upload/Dragger';
import styled from 'styled-components';

const DraggerIcon = styled.p`
  font-size: 32px;
  line-height: 1;
  color: ${props => props.theme.primaryColor};
`;
const DraggerText = styled.p`
  font-size: 14px;
`;

const { Dragger } = Upload;

export interface UploadDraggerInputProps<T extends FormikValues>
  extends DraggerProps {
  descriptionText?: string;
}

/**
 * Campo upload file con Dragger.
 */
export function UploadDraggerInput<T extends FormikValues>(
  props: UploadDraggerInputProps<T>
) {
  const { descriptionText, ...draggerProps } = props;

  return (
    <UploadInputWrapper>
      <Dragger {...draggerProps}>
        <DraggerIcon>
          <InboxOutlined />
        </DraggerIcon>
        <DraggerText>
          {descriptionText ??
            `Fai click o trascina un file in quest'area per caricare il documento.`}
        </DraggerText>
      </Dragger>
    </UploadInputWrapper>
  );
}

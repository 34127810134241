import * as React from 'react';
import styled from 'styled-components';
import { DragOutlined, PlusOutlined } from '@ant-design/icons';

export const WidgetLockWrapper = styled.div`
  position: absolute;
  top: 1px;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  border-bottom-left-radius: ${props => props.theme.cardRadius};
  border-bottom-right-radius: ${props => props.theme.cardRadius};
  padding: 40px;
  padding-top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HoverIntercept = styled.div`
  cursor: -webkit-grab;
  cursor: grab;
  &:active {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${props => props.theme.cardRadius};
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  .anticon {
    color: ${props => props.theme.textColor};
    background-color: rgba(255, 255, 255, 0);
    opacity: 0.2;
  }
  &:hover {
    .anticon {
      opacity: 0.6;
      color: ${props => props.theme.primaryColor};
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 0px 0px 50px 50px rgba(255, 255, 255, 1);
    }
  }
`;

const IconPlus = styled.div`
  width: 60px;
  height: 60px;
  max-width: 100%;
  max-height: 100%;
  .anticon,
  svg {
    width: 100%;
    height: 100%;
  }
`;

export interface WidgetLockProps {}

export function WidgetLock(props: WidgetLockProps) {
  return (
    <WidgetLockWrapper className="widget-lock">
      <HoverIntercept>
        {/* <IconPlus>
          <DragOutlined />
        </IconPlus> */}
      </HoverIntercept>
    </WidgetLockWrapper>
  );
}

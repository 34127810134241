import { Statistic } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';

export const BaseStatistic = styled(Statistic)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .ant-statistic-title {
    margin-bottom: 0;
    font-weight: 600;
    line-height: 1.1;
  }
  .ant-statistic-content {
    letter-spacing: -0.05em;
    word-spacing: -0.1em;
    line-height: 20px;
    padding-top: 6px;
  }
  .ant-statistic-content-suffix {
    font-size: 16px;
  }
`;
export const ColorStatistic = styled(BaseStatistic)<{ color?: string }>`
  ${props =>
    props.color &&
    css`
      .ant-statistic-title {
        color: ${props.color};
        opacity: 1;
        text-align: center;
      }
    `}
`;

import {
  BarsOutlined,
  FolderAddOutlined,
  FolderOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Space } from 'antd';
import {
  useGetDocumentaryDossiersId,
  useGetDocumentaryDossiersIdChildren,
  useGetDocumentaryDossiersIdFiles,
  useGetDocumentaryDossiersNameName
} from 'client/api/backend/documentary-dossiers/documentary-dossiers';
import { useDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { useDesk } from 'client/components/schema/desk/DeskModule';
import { ToolkitActionButton } from 'client/components/schema/widget/actions/button/ToolkitActionButton';
import { ToolkitObjectActionsMenu } from 'client/components/schema/widget/actions/interaction/ToolkitObjectActionsMenu';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { WidgetCardBodyTabs } from 'client/components/schema/widget/card/body/WidgetCardBodyTabs';
import { useWidgetNetworkQueries } from 'client/components/schema/widget/context/useWidgetNetworkEffect';
import { WidgetButtonsBar } from 'client/components/toolkit/components/buttons/WidgetButtonsBar';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import { WidgetList } from 'client/components/toolkit/components/list/WidgetList';
import { WidgetTabPanel } from 'client/components/toolkit/components/tabs/WidgetTabPanel';
import { useRegisterToolkitObject } from 'client/components/toolkit/objects/store/useToolkitObjectStore';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { ToolkitObjectBottomZone } from 'client/components/toolkit/objects/ToolkitObjectBottomZone';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { set } from 'client/core/data/set';
import { PaddedSkeleton } from 'client/ui/skeleton/PaddedSkeleton';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { DamToolkitObject } from '../../objects/dams/DamToolkitObject';
import { DossierToolkitObject } from '../../objects/dossiers/DossierToolkitObject';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { convertDossierToListElement } from '../dossiers-search/convert/convertDossierToListElement';
import { convertDossierToWidgetList } from './convert/convertDossierToWidgetList';
import { WidgetDossierMetadata } from './metadata/WidgetDossierMetadata';
import { WidgetDossierViewerProfile } from './WidgetDossierViewerProfile';

export interface IWidgetDossierViewerProps
  extends IWidgetComponentToolkitProps {}

export function WidgetDossierViewer(props: IWidgetDossierViewerProps) {
  const ctx = useDeskContext();

  const { draggingItem } = useDesk();

  const { widget } = props;
  const data = widget.profile?.data as WidgetDossierViewerProfile | undefined;

  const dossierFromNameResult = useGetDocumentaryDossiersNameName(
    data?.loadFromDossierName!,
    {
      widgetId: widget.id!
    },
    { query: { enabled: data?.loadFromDossierName != null } }
  );

  useEffect(() => {
    if (data?.loadFromDossierName && dossierFromNameResult.data) {
      ctx.updateWidget(
        set(widget!, 'profile.data', {
          ...data,
          dossierId: dossierFromNameResult.data?.id
        })
      );
    }
  }, [data?.loadFromDossierName, dossierFromNameResult.data]);

  const [folderId, setFolderId] = useState(data?.dossierId);

  useEffect(() => {
    setFolderId(data?.dossierId);
  }, [data?.dossierId]);

  // Per il drag&drop se non c'è nessun fascicolo selezionato
  const widgetObject = WidgetToolkitObject.from(widget);
  useRegisterToolkitObject(widgetObject);

  const dossierResult = useGetDocumentaryDossiersId(
    data?.dossierId!,
    { widgetId: widget.id! },
    { query: { enabled: data?.dossierId != null } }
  );
  // TODO Pulire questi metodi per avere sempre un converter -> toolkit object e un layer superiore per i WidgetListElement
  const dossierObject: ToolkitObject | null = dossierResult.data
    ? convertDossierToListElement(dossierResult.data, widget).object ?? null
    : null;

  // Scarico i children & i files
  const querySharedOptions = {
    query: { enabled: folderId != null }
  };

  const subfoldersResult = useGetDocumentaryDossiersIdChildren(
    folderId!,
    {
      widgetId: widget.id!,
      folderId: folderId!
    },
    querySharedOptions
  );

  const documentsResult = useGetDocumentaryDossiersIdFiles(
    folderId!,
    {
      widgetId: widget.id!,
      folderId: folderId!,
      pageNumber: 0,
      pageSize: 100
    },
    querySharedOptions
  );

  const [navigationStack, setNavigationStack] = useState<IWidgetListElement[]>(
    []
  );

  useWidgetNetworkQueries([
    dossierResult,
    subfoldersResult,
    documentsResult,
    dossierFromNameResult
  ]);

  const navigateToFolder = useCallback(
    (folder: IWidgetListElement) => {
      setFolderId(folder.object!.id as number);
      setNavigationStack(stack => [...stack, folder]);
    },
    [setFolderId, setNavigationStack]
  );

  const navigateBack = useCallback(() => {
    if (navigationStack.length === 0) return;
    const nextStack = navigationStack.slice(0, -1);
    setFolderId(
      nextStack.length === 0
        ? data!.dossierId
        : (nextStack[nextStack.length - 1].object!.id as number)
    );
    setNavigationStack(nextStack);
  }, [setFolderId, navigationStack, setNavigationStack]);

  // TODO Standardizzare il navigationStack
  const folderObject =
    navigationStack.length > 0
      ? navigationStack[navigationStack.length - 1].object ?? null
      : dossierObject;

  const isDraggingDossierOrDam =
    draggingItem?.object.type === DossierToolkitObject.code ||
    draggingItem?.object.type === DamToolkitObject.code ||
    data?.dossierId == null;

  return (
    <WidgetCardBody
      scrollable={false}
      bottom={
        <ToolkitObjectBottomZone
          object={isDraggingDossierOrDam ? widgetObject : folderObject}
          text={isDraggingDossierOrDam ? 'Apri Fascicolo' : undefined}
        />
      }
    >
      <WidgetCardBodyTabs
        actionsMenu={<ToolkitObjectActionsMenu object={folderObject} />}
      >
        <WidgetTabPanel
          title="Fascicolo"
          icon={<FolderOutlined />}
          type="main"
          tabKey="main"
          scrollable={false}
        >
          <PaddedSkeleton
            loading={dossierResult.isLoading || dossierFromNameResult.isLoading}
            active
          >
            <WidgetList
              onBack={navigateBack}
              backElement={
                navigationStack.length > 0
                  ? navigationStack[navigationStack.length - 1]
                  : undefined
              }
              list={convertDossierToWidgetList(
                folderId!,
                subfoldersResult.data,
                'dossier',
                documentsResult.data,
                widget,
                navigateToFolder
              )}
            />
          </PaddedSkeleton>
        </WidgetTabPanel>
        <WidgetTabPanel
          title="Metadati"
          icon={<BarsOutlined />}
          tabKey="metadata"
          // scrollable={false}
          padded
        >
          <WidgetDossierMetadata
            dossier={dossierResult.data}
            widgetId={widget.id!}
          />
        </WidgetTabPanel>
      </WidgetCardBodyTabs>
    </WidgetCardBody>
  );
}

import React from 'react';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import {
  SelectInput,
  SelectInputProps,
  SelectOption
} from 'client/ui/form/input/SelectInput';
import { RoleTag } from 'client/ui/role/RoleTag';
import { useGetRoles } from 'client/api/backend/roles/roles';

interface IRoleSelectInputProps extends SelectInputProps<any> {}

export function RoleSelectInput(props: IRoleSelectInputProps) {
  const rolesResult = useGetRoles();

  return (
    <SelectInput
      allowClear
      loading={rolesResult.isLoading}
      options={rolesResult.data?.map(r => ({
        value: r.id!,
        label: r.name
      }))}
      renderOption={(option: SelectOption) => (
        <RoleTag roleName={option.label} />
      )}
      {...props}
    />
  );
}

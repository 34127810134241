import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import * as React from 'react';

export interface IProceedingPhaseInputProps {
  fieldNamePrefix: string;
}

export function ProceedingPhaseInput(props: IProceedingPhaseInputProps) {
  const { fieldNamePrefix } = props;

  return (
    <FormFieldsContainer removeTopTitleSpacing columns={2} gutter={8}>
      <FormFieldItem
        size="middle"
        colSpan={2}
        component={TextInput}
        name={`${fieldNamePrefix}.name`}
        label="Tipo Fase"
        placeholder="Tipo Fase"
      />
      <FormFieldItem
        size="middle"
        colSpan={2}
        component={TextInput}
        name={`${fieldNamePrefix}.note`}
        label="Note"
        placeholder="Note"
      />
      <FormFieldItem
        size="middle"
        component={DatePickerInput}
        name={`${fieldNamePrefix}.dateStart`}
        type="unix"
        label="Data Inizio"
        placeholder="Data Inizio"
      />
      <FormFieldItem
        size="middle"
        component={DatePickerInput}
        name={`${fieldNamePrefix}.dateEnd`}
        type="unix"
        label="Data Fine"
        placeholder="Data Fine"
      />
    </FormFieldsContainer>
  );
}

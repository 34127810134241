import * as React from 'react';
import styled from 'styled-components';
import {
  IToolkitObjectZoneProps,
  ToolkitObjectZone
} from './ToolkitObjectZone';

export interface IToolkitObjectBottomZoneProps
  extends Omit<IToolkitObjectZoneProps, 'children'> {
  text?: React.ReactNode;
}

const DropZone = styled.div`
  margin: 4px;
  padding: 12px;
  border-radius: 0 0 8px 8px;
  border: 2px dashed ${props => props.theme.borderColorBase};
  color: ${props => props.theme.primaryColor};
  text-align: center;
  font-weight: 600;
  opacity: 0.7;
  text-transform: uppercase;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  .toolkit-object-zone-active & {
    opacity: 1;
    border-color: ${props => props.theme.primaryColor};
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

/**
 * Area di default sul quale effettare il Drop di un ToolkitObject, da
 * renderizzare nel `bottom` del WidgetCardBody.
 * Fa apparire una fascia inferiore con scritto "Trascina qui"
 */
export function ToolkitObjectBottomZone(props: IToolkitObjectBottomZoneProps) {
  const { text, ...otherProps } = props;

  return (
    <ToolkitObjectZone {...otherProps}>
      <DropZone>{text ?? 'Trascina qui'}</DropZone>
    </ToolkitObjectZone>
  );
}

import { FolderOutlined } from '@ant-design/icons';
import { DeskDossier, Widget } from 'client/api/backend/schemas';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import { DossierToolkitObject } from 'client/components/widget-repository/objects/dossiers/DossierToolkitObject';
import moment from 'moment';
import React from 'react';
import { convertDossierStateIdToText } from './convertDossierStateIdToText';

export function convertDossierToListElement(
  dossier: DeskDossier,
  widget: Widget
): IWidgetListElement {
  // [Fascicoli] Nell'elenco mettere in evidenza: Numero fascicolo, Tipo fascicolo, Oggetto fascicolo, Data apertura, Stato
  // TODO Tipo fascicolo (info mancante)
  const description =
    dossier.description +
    (dossier.dateOpen ? ' - ' + moment(dossier.dateOpen).format('ll') : '');

  return {
    name: dossier.name ?? `Fascicolo #${dossier.id!}`,
    nameExtend: {
      before: dossier.number?.toString()
    },
    description: dossier.description
      ? description
      : moment(dossier.dateOpen).format('ll'),
    descriptionExtend: {
      after: convertDossierStateIdToText(dossier.stateId),
      bold: true
    },

    type: 'folder',
    object: DossierToolkitObject.create({
      name: dossier.name ?? `Fascicolo #${dossier.id!}`,
      icon: <FolderOutlined />,
      id: dossier.id!,
      data: dossier,
      sourceWidgetId: widget.id!
    })
  };
}

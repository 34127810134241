import React from 'react';
import styled, { css } from 'styled-components';

export const MonitorTop = styled.div<{ fixed?: boolean }>`
  flex: 1 1 auto;
  min-height: 0;
  ${props =>
    props.fixed &&
    css`
      height: ${props => props.theme.miniBarHeight};
      flex: 0 0 ${props => props.theme.miniBarHeight};
    `}
`;

import * as React from 'react';
import clx from 'classnames';
import styled from 'styled-components';
import { useDesk } from 'client/components/schema/desk/DeskModule';

const ListBodyWrapper = styled.div`
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 0;
  border-bottom-left-radius: ${props => props.theme.cardRadius};
  border-bottom-right-radius: ${props => props.theme.cardRadius};
  overflow: hidden;
`;
const FixedTop = styled.div`
  flex: 0 0 auto;
  min-height: 0;
  border-bottom: solid 1px ${props => props.theme.borderColorBase};

  &:empty {
    display: none;
  }
`;
const ListScroller = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: 0;
  height: 100%;
  max-height: 100%;
  min-width: 0;

  border-radius: 0;
  /* border-bottom-left-radius: ${props => props.theme.cardRadius};
  border-bottom-right-radius: ${props => props.theme.cardRadius}; */
`;

export interface ListNavigationBodyProps
  extends React.HTMLAttributes<HTMLDivElement> {
  top?: React.ReactNode;
}

/**
 * Permette l'inserimento nella parte superiore della lista
 * di un pulsante di back per tornare all'elemento padre
 *
 */
export function ListNavigationBody(props: ListNavigationBodyProps) {
  const { children, top, ...otherProps } = props;

  return (
    <ListBodyWrapper {...otherProps}>
      {top && <FixedTop>{top}</FixedTop>}
      <ListScroller>{props.children}</ListScroller>
    </ListBodyWrapper>
  );
}

import { TableOutlined, LineChartOutlined } from '@ant-design/icons';
import {
  getDocumentaryDamIdObservations,
  getGetDocumentaryDamIdObservationsQueryKey,
  useGetDocumentaryDamId,
  useGetDocumentaryDamIdObservations,
  useGetDocumentaryDamObservationsParameters
} from 'client/api/backend/documentary-dam/documentary-dam';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { WidgetCardBodyTabs } from 'client/components/schema/widget/card/body/WidgetCardBodyTabs';
import { useWidgetNetworkQueries } from 'client/components/schema/widget/context/useWidgetNetworkEffect';
import { useWidgetContext } from 'client/components/schema/widget/context/WidgetContext';
import { WidgetTabPanel } from 'client/components/toolkit/components/tabs/WidgetTabPanel';
import { useRegisterToolkitObject } from 'client/components/toolkit/objects/store/useToolkitObjectStore';
import { ToolkitObjectBottomZone } from 'client/components/toolkit/objects/ToolkitObjectBottomZone';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import React, { useMemo, useState } from 'react';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { WidgetDamMonitoringProfile } from './WidgetDamMonitoringProfile';
import { WidgetDamMonitoringTable } from './WidgetDamMonitoringTable';
import { WidgetDamMonitoringLineChart } from './WidgetDamMonitoringLineChart';
import { GetDocumentaryDamIdObservationsParams } from 'client/api/backend/schemas';
import { WidgetDamMonitoringSearchForm } from './filters/WidgetDamMonitoringSearchForm';
import moment from 'moment';
import { useQueries } from 'react-query';
import { WidgetDamMonitoringSearchSchemaType } from './filters/WidgetDamMonitoringSearchSchema';
import {
  GraphViewConfigs,
  GraphViewParameters
} from '../../objects/dams/DamParams';
import { omit } from 'lodash';
import { applyWidgetDamMonitoringSearchDefaultFilters } from './filters/WidgetDamMonitoringSearchFilter';
import { damMonitoringDefaultFilters } from './WidgetDamMonitoringDefaultFilters';

export interface IWidgetDamMonitoringProps
  extends IWidgetComponentToolkitProps {}

export function WidgetDamMonitoring(props: IWidgetDamMonitoringProps) {
  const { widget } = props;
  const widgetContext = useWidgetContext();

  const data = widget.profile?.data as WidgetDamMonitoringProfile | undefined;
  const [search, setSearch] = useState<
    Partial<WidgetDamMonitoringSearchSchemaType>
  >({});

  const staticSearch = useMemo(
    () => ({
      ...damMonitoringDefaultFilters,
      ...applyWidgetDamMonitoringSearchDefaultFilters(widget),
      ...search,
      widgetId: widget.id!
    }),
    [search, widget.profile?.filters]
  );

  const apiSearch = omit(staticSearch, 'selectedGraphView');

  const paramsResult = useGetDocumentaryDamObservationsParameters({
    widgetId: widget.id!
  });

  const damResult = useGetDocumentaryDamId(
    data?.damId!,
    { widgetId: widget.id! },
    {
      query: {
        enabled: data?.damId != null
      }
    }
  );

  const selectedGraphView = staticSearch?.selectedGraphView;
  const graphViewSelectedConfig = GraphViewConfigs[selectedGraphView ?? 0];
  const selectedGraphParameters = graphViewSelectedConfig?.parameters.map(
    parameter => GraphViewParameters[parameter]
  );

  const queryBodies = paramsResult.data?.map(param => {
    return {
      queryKey: getGetDocumentaryDamIdObservationsQueryKey(data?.damId!, {
        ...apiSearch,
        parameter: param?.key!
      }),
      queryFn: () =>
        getDocumentaryDamIdObservations(data?.damId!, {
          ...apiSearch,
          parameter: param?.key!
        }),
      enabled: data?.damId != null && param.key != null
    };
  });

  const observationsResult = useQueries(queryBodies ?? []);

  const unsortedObservationsData = observationsResult
    .map(r => r.data ?? [])
    .flat();

  const observationData = unsortedObservationsData.sort(
    (a, b) => (a.date ?? 0) - (b.date ?? 0)
  );

  useWidgetNetworkQueries([...observationsResult, paramsResult]);

  // Creiamo l'oggetto per il toolkit
  const widgetObject = WidgetToolkitObject.from(widget);
  useRegisterToolkitObject(widgetObject);

  return (
    <WidgetCardBody
      top={
        <WidgetDamMonitoringSearchForm
          widget={widget}
          search={search}
          setSearch={setSearch}
        />
      }
      scrollable={false}
      bottom={<ToolkitObjectBottomZone object={widgetObject} />}
    >
      <WidgetCardBodyTabs>
        <WidgetTabPanel
          title="Grafico"
          tabKey="graph"
          icon={<LineChartOutlined />}
          type="main"
        >
          <WidgetDamMonitoringLineChart
            selectedParameters={selectedGraphParameters}
            rawData={observationData}
            dam={damResult.data}
          />
        </WidgetTabPanel>
        <WidgetTabPanel title="Tabella" tabKey="table" icon={<TableOutlined />}>
          <WidgetDamMonitoringTable
            isLoading={observationsResult.some(r => r.isLoading)}
            data={observationData}
          />
        </WidgetTabPanel>
      </WidgetCardBodyTabs>
    </WidgetCardBody>
  );
}

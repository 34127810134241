import {
  postDocumentaryDossiersIdFiles,
  getGetDocumentaryDossiersIdFilesQueryKey
} from 'client/api/backend/documentary-dossiers/documentary-dossiers';
import {
  DeskAssignment,
  DossierAddDocumentRequest
} from 'client/api/backend/schemas';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { ExtendedDeskDocument } from 'client/components/widget-repository/objects/documents/DocumentToolkitObject';
import { ExtendedDeskFolder } from 'client/components/widget-repository/objects/folders/FolderToolkitObject';
import { queryClient } from 'client/core/network/queryClient';

/**
 * Funzione che si occupa della chiamata alla API per l'inserimento di un documento in un fascicolo
 */
export async function executeInsertDocumentDossier(
  assignments: ToolkitObject[],
  documents: ToolkitObject[],
  folder: ToolkitObject<ExtendedDeskFolder>
) {
  await postDocumentaryDossiersIdFiles(folder!.id as number, {
    ...getTypeAndId(assignments, documents),
    widgetId: documents[0].sourceWidgetId!,
    widgetIdFrom: folder!.sourceWidgetId
  });

  // Invalido la cache
  const queryKey = getGetDocumentaryDossiersIdFilesQueryKey(
    folder.id as number
  )[0];

  queryClient.invalidateQueries({
    predicate: query => {
      return Boolean(
        query.queryKey &&
          Array.isArray(query.queryKey) &&
          query.queryKey[0] === queryKey
      );
    }
  });
}

function getTypeAndId(
  assignments: ToolkitObject<DeskAssignment>[] | null,
  documents: ToolkitObject<ExtendedDeskDocument>[]
): Partial<DossierAddDocumentRequest> {
  if (assignments == null || assignments.length === 0) {
    return { areDocuments: true, ids: documents.map(d => d.id as number) };
  }

  return { areDocuments: false, ids: assignments.map(a => a.id as number) };
}

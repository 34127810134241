import { DeleteOutlined } from '@ant-design/icons';
import { message } from 'antd';
import {
  deleteDocumentaryMailsId,
  getGetDocumentaryMailsQueryKey
} from 'client/api/backend/documentary-mails/documentary-mails';
import { DeskMail } from 'client/api/backend/schemas';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import {
  ToolkitObject,
  getToolkitObjectOfType
} from 'client/components/toolkit/objects/ToolkitObject';
import { MailToolkitObject } from 'client/components/widget-repository/objects/mails/MailToolkitObject';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { queryClient } from 'client/core/network/queryClient';
import { yup } from 'common/validation/initYup';
import React from 'react';
import { InferType } from 'yup';
import { WidgetMailViewerType } from '../../mail-viewer/WidgetMailViewer.type';
import { WidgetMailViewerProfile } from '../../mail-viewer/WidgetMailViewerProfile';

export const WidgetActionDeleteMail = createToolkitAction({
  name: 'Elimina Mail',
  code: 'delete-mail',
  icon: <DeleteOutlined />,
  arguments: [
    {
      name: 'Mail',
      type: [MailToolkitObject]
    }
  ],
  async execute(ctx: IToolkitActionContext, mailObject: ToolkitObject) {
    const mail: ToolkitObject<DeskMail> = getToolkitObjectOfType(mailObject, [
      MailToolkitObject.code
    ])!;

    ctx.deskContext.actionMessages.prompt({
      ctx,
      args: [mail],
      action: WidgetActionDeleteMail,
      confirmText: 'Elimina',
      confirmProps: { danger: true },
      schema: DeleteMailSchema,
      onConfirm
    });
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: DeleteMailSchemaType,
  mail: ToolkitObject
) {
  try {
    await deleteMail(values, mail);

    message.success('Mail eliminata con successo');
  } catch (error) {
    message.error(
      getNetworkErrorMessage(error, 'Impossibile eliminare la mail')
    );
    console.error(error);
    return false;
  }

  const mailViewerWidgets = ctx.deskContext.widgets.filter(
    w => w.profile?.typeCode === WidgetMailViewerType.code
  );

  mailViewerWidgets.forEach(w => {
    if ((w.profile?.data as WidgetMailViewerProfile).mailId === mail.id) {
      ctx.deskContext.remove(w);
    }
  });
}

async function deleteMail(
  values: DeleteMailSchemaType,
  mail: ToolkitObject<DeskMail>
) {
  await deleteDocumentaryMailsId(mail.id as number, {
    widgetId: mail.sourceWidgetId
  });

  // Invalido la cache
  const queryKey = getGetDocumentaryMailsQueryKey()[0];

  queryClient.invalidateQueries({
    predicate: query => {
      return Boolean(
        query.queryKey &&
          Array.isArray(query.queryKey) &&
          query.queryKey[0] === queryKey
      );
    }
  });
}

const DeleteMailSchema = yup.object({});

type DeleteMailSchemaType = InferType<typeof DeleteMailSchema>;

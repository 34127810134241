import {
  getDocumentaryOrganizationOffices,
  getGetDocumentaryOrganizationOfficesQueryKey
} from 'client/api/backend/documentary-organization/documentary-organization';
import { DeskOffice } from 'client/api/backend/schemas';
import {
  IToolkitObjectFetchOptions,
  registerToolkitObjectType
} from 'client/components/toolkit/objects/registerToolkitObjectType';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { queryClient } from 'client/core/network/queryClient';
import memoizeOne from 'memoize-one';
import React from 'react';
import { RiBuilding4Line } from 'react-icons/ri';

export const OfficeToolkitObject = registerToolkitObjectType<DeskOffice>(
  'office',
  {
    fetchObjects
  }
);

const MAX_RESULTS = 50;

async function fetchObjects({
  widgetId,
  search
}: IToolkitObjectFetchOptions): Promise<ToolkitObject<DeskOffice>[]> {
  const offices = await queryClient.fetchQuery(
    getGetDocumentaryOrganizationOfficesQueryKey({ widgetId, description: '' }),
    () => getDocumentaryOrganizationOffices({ widgetId, description: '' }),
    { staleTime: 1_800_000 } // 30 Minuti
  );

  const allOffices = parseOffices(widgetId, offices);

  return filterOffices(allOffices, search);
}

const parseOffices = memoizeOne((widgetId: number, offices: DeskOffice[]) =>
  offices.map(office =>
    OfficeToolkitObject.create({
      id: office.id!,
      name: office.name ?? 'N.A.',
      icon: <RiBuilding4Line />,
      data: office,
      sourceWidgetId: widgetId
    })
  )
);

const filterOffices = memoizeOne(
  (offices: ToolkitObject<DeskOffice>[], search?: string) => {
    // Lazy-evaluation per evitare di filtrare tutti gli uffici, invece ci
    // fermiamo ai primi 20 risultati
    let filtered: ToolkitObject<DeskOffice>[] = [];

    for (const office of offices) {
      if (office.name.toLowerCase().includes((search ?? '').toLowerCase())) {
        filtered.push(office);
      }
      if (filtered.length >= MAX_RESULTS) break;
    }

    return filtered;
  }
);

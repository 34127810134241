import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { AssignmentToolkitObject } from 'client/components/widget-repository/objects/assignments/AssignmentToolkitObject';
import { AssignmentType } from 'client/components/widget-repository/objects/assignments/mapIdType';
import { DocumentToolkitObject } from 'client/components/widget-repository/objects/documents/DocumentToolkitObject';

/**
 * È permesso fascicolare a partire da un'assegnazione solo se è di tipo "Competenza" o "Conoscenza"
 */
export function isInsertIntoDossierActionAllowed(
  toolkitObject: ToolkitObject<any> | null
) {
  const document = DocumentToolkitObject.get(toolkitObject);

  if (document != null && !document.isRelated) return true;

  const assignment = AssignmentToolkitObject.get(toolkitObject);

  if (
    assignment != null &&
    (assignment.data.idType?.toString() === AssignmentType.Competenza ||
      assignment.data.idType?.toString() === AssignmentType.Conoscenza)
  )
    return true;

  return false;
}

import { HighlightOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { DeskDossier } from 'client/api/backend/schemas';
import { DeskWidgetsLogic } from 'client/components/schema/desk/logic/DeskWidgetsLogic';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import {
  getToolkitObjectOfType,
  ToolkitObject
} from 'client/components/toolkit/objects/ToolkitObject';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { DossierToolkitObject } from 'client/components/widget-repository/objects/dossiers/DossierToolkitObject';
import { set } from 'client/core/data/set';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { yup } from 'common/validation/initYup';
import React from 'react';
import { InferType } from 'yup';
import { WidgetHighlightedDossiersType } from '../../WidgetHighlightedDossiers.type';
import { WidgetHighlightedDossiersProfile } from '../../WidgetHighlightedDossiersProfile';

export const WidgetActionHighlightDossier = createToolkitAction({
  name: 'Evidenzia Fascicolo',
  code: 'highlight-dossier',
  icon: <HighlightOutlined />,
  arguments: [
    {
      type: [DossierToolkitObject],
      name: 'Fascicolo'
    },
    {
      type: [WidgetToolkitObject],
      name: 'Widget Fascicoli in Evidenzia',
      allows: (object: ToolkitObject) =>
        WidgetToolkitObject.matches(object, WidgetHighlightedDossiersType)
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    dossierObject: ToolkitObject,
    highlightedObject: ToolkitObject | null
  ) {
    const dossier = DossierToolkitObject.get(dossierObject)!;
    const highlighted = WidgetToolkitObject.get(highlightedObject);

    // A. Se stiamo trascinando su un Widget già esistente..
    if (highlighted) {
      await onConfirm(ctx, {}, dossier, highlighted);
      return;
    }

    // B. Se esiste almeno un Widget di tipo Evidenzia Fascicolo..
    const hasHiglightWidgets = ctx.deskContext.widgets.some(
      w => w.profile?.typeCode === WidgetHighlightedDossiersType.code
    );
    if (hasHiglightWidgets) {
      ctx.deskContext.actionMessages.prompt({
        ctx,
        args: [dossier, highlighted],
        action: WidgetActionHighlightDossier,
        schema: HighlightDossierSchema,
        confirmText: 'Metti in Evidenza',
        onConfirm
      });
      return;
    }

    // C. Se non esiste almeno un Widget di tipo Evidenzia Fascicolo, lo creiamo
    const data: WidgetHighlightedDossiersProfile = {
      dossiers: [dossier.data]
    };

    await ctx.deskContext.cloneWidget(
      {
        type: 'floating',
        expanded: true,
        order: DeskWidgetsLogic.getFloatingNextOrder(
          ctx.deskContext.floatingWidgets
        )
      },
      WidgetHighlightedDossiersType,
      ctx.sourceWidget!,
      { data, quickClose: false }
    );
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: any,
  dossier: ToolkitObject,
  highlighted: ToolkitObject
) {
  try {
    const widget = ctx.deskContext.widgets.find(w => w.id === highlighted.id);
    const profile = widget?.profile;
    const data = profile?.data as WidgetHighlightedDossiersProfile | null;

    const addedDossier = dossier.data;

    if (data?.dossiers?.some(d => d.id === addedDossier.id)) {
      message.warning('Fascicolo già presente in evidenza');
      return false;
    }

    await ctx.deskContext.updateWidget(
      set(widget!, 'profile.data.dossiers', [
        ...(data?.dossiers ?? []),
        addedDossier
      ])
    );

    message.success('Fascicolo salvato in evidenza');
  } catch (e) {
    message.error(
      getNetworkErrorMessage(e, 'Impossibile evidenziare il fascicolo')
    );
    console.error(e);
    return false;
  }
}

const HighlightDossierSchema = yup.object({});
type HighlightDossierSchemaType = InferType<typeof HighlightDossierSchema>;

import { DeskDocument, Widget } from 'client/api/backend/schemas';
import { convertDocumentToWidgetListElement } from '../../document-viewer/convert/convertDocumentToWidgetListElement';
import {
  HighlightedDocumentToolkitObject,
  HighlightedDeskDocument
} from '../objects/HighlightedDocumentToolkitObject';

export function convertHighlightedDocumentToWidgetListElement(
  document: DeskDocument,
  localDocument: HighlightedDeskDocument,
  widget: Widget
) {
  const element = convertDocumentToWidgetListElement(document, widget);

  const documentName = document?.subject ?? document?.filename ?? 'N.A.';

  const notes = localDocument.highlighted?.notes?.trim() ?? '';

  element.annotations = notes;
  element.annotationsExtend = {
    before: notes ? 'Note:' : '',
    bold: true,
    divider: ''
  };

  element.object!.related = [
    HighlightedDocumentToolkitObject.create({
      id: document.id!,
      name: documentName,
      data: localDocument,
      sourceWidgetId: widget.id!
    })
  ];

  return element;
}

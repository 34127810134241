import * as React from 'react';
import { Button, ButtonProps } from 'antd';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  &.ant-btn-ghost:not(:hover) {
    border-color: transparent;
  }
`;

export interface ButtonGhostProps extends Omit<ButtonProps, 'type' | 'shape'> {
  shape?: 'circle' | 'round' | undefined;
}

/**
 * Pulsante stilizzato l'header dell'applicazione
 */
export function ButtonGhost(props: ButtonGhostProps) {
  return <StyledButton shape="circle" type="ghost" {...props} />;
}

/**
 * Generato automaticamente.
 * DeskUI REST API
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  DeskAOO,
  GetDocumentaryOrganizationAooParams,
  DeskArea,
  GetDocumentaryOrganizationChartParams,
  DeskOffice,
  GetDocumentaryOrganizationOfficesParams,
  GetDocumentaryOrganizationOfficesIdParams
} from '.././schemas';
import {
  apiInstance,
  ErrorType
} from '../../../core/network/mutators/apiClient';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Ritorna la lista delle AOO
 */
export const getDocumentaryOrganizationAoo = (
  params?: GetDocumentaryOrganizationAooParams
) => {
  return apiInstance<DeskAOO[]>({
    url: `/api/v1/documentary/organization/aoo`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryOrganizationAooQueryKey = (
  params?: GetDocumentaryOrganizationAooParams
) => [`/api/v1/documentary/organization/aoo`, ...(params ? [params] : [])];

export type GetDocumentaryOrganizationAooQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryOrganizationAoo>
>;
export type GetDocumentaryOrganizationAooQueryError = ErrorType<void>;

export const useGetDocumentaryOrganizationAoo = <
  TData = AsyncReturnType<typeof getDocumentaryOrganizationAoo>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryOrganizationAooParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryOrganizationAoo>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryOrganizationAooQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryOrganizationAoo>
  > = () => getDocumentaryOrganizationAoo(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryOrganizationAoo>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna l'organigramma
 */
export const getDocumentaryOrganizationChart = (
  params?: GetDocumentaryOrganizationChartParams
) => {
  return apiInstance<DeskArea[]>({
    url: `/api/v1/documentary/organization/chart`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryOrganizationChartQueryKey = (
  params?: GetDocumentaryOrganizationChartParams
) => [`/api/v1/documentary/organization/chart`, ...(params ? [params] : [])];

export type GetDocumentaryOrganizationChartQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryOrganizationChart>
>;
export type GetDocumentaryOrganizationChartQueryError = ErrorType<void>;

export const useGetDocumentaryOrganizationChart = <
  TData = AsyncReturnType<typeof getDocumentaryOrganizationChart>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryOrganizationChartParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryOrganizationChart>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryOrganizationChartQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryOrganizationChart>
  > = () => getDocumentaryOrganizationChart(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryOrganizationChart>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna lista uffici
 */
export const getDocumentaryOrganizationOffices = (
  params?: GetDocumentaryOrganizationOfficesParams
) => {
  return apiInstance<DeskOffice[]>({
    url: `/api/v1/documentary/organization/offices`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryOrganizationOfficesQueryKey = (
  params?: GetDocumentaryOrganizationOfficesParams
) => [`/api/v1/documentary/organization/offices`, ...(params ? [params] : [])];

export type GetDocumentaryOrganizationOfficesQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryOrganizationOffices>
>;
export type GetDocumentaryOrganizationOfficesQueryError = ErrorType<void>;

export const useGetDocumentaryOrganizationOffices = <
  TData = AsyncReturnType<typeof getDocumentaryOrganizationOffices>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryOrganizationOfficesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryOrganizationOffices>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryOrganizationOfficesQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryOrganizationOffices>
  > = () => getDocumentaryOrganizationOffices(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryOrganizationOffices>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna singolo ufficio
 */
export const getDocumentaryOrganizationOfficesId = (
  id: number,
  params?: GetDocumentaryOrganizationOfficesIdParams
) => {
  return apiInstance<DeskOffice>({
    url: `/api/v1/documentary/organization/offices/${id}`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryOrganizationOfficesIdQueryKey = (
  id: number,
  params?: GetDocumentaryOrganizationOfficesIdParams
) => [
  `/api/v1/documentary/organization/offices/${id}`,
  ...(params ? [params] : [])
];

export type GetDocumentaryOrganizationOfficesIdQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryOrganizationOfficesId>
>;
export type GetDocumentaryOrganizationOfficesIdQueryError = ErrorType<void>;

export const useGetDocumentaryOrganizationOfficesId = <
  TData = AsyncReturnType<typeof getDocumentaryOrganizationOfficesId>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetDocumentaryOrganizationOfficesIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryOrganizationOfficesId>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryOrganizationOfficesIdQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryOrganizationOfficesId>
  > = () => getDocumentaryOrganizationOfficesId(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryOrganizationOfficesId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

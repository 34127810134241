import React, { useEffect } from 'react';
import { Col, Row, Layout } from 'antd';
const { Content, Header } = Layout;
import { HeaderLogo } from './HeaderLogo';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';
import styled from 'styled-components';

const FlexHeader = styled(Header)`
  display: flex;
  height: ${props => props.theme.layoutHeaderHeight};
  align-items: center;
`;
const BaseCol = styled.div`
  display: flex;
  height: ${props => props.theme.layoutHeaderHeight};
  align-items: center;
  min-height: 0;
  min-width: 0;
`;
const ColLogo = styled.div`
  width: ${props => props.theme.logoWidth};
  height: ${props => props.theme.layoutHeaderHeight};
  display: flex;
  flex: 0 0 ${props => props.theme.logoWidth};
  min-width: 0;
  justify-content: center;
  align-items: center;
`;
const ColMainMenu = styled(BaseCol)`
  flex: 1 1 auto;
  padding: 0 18px;
  ${down('sm')} {
    padding: 0;
  }
`;
const ColRightMenu = styled.div`
  flex: 0 0 auto;
`;

export interface PageLayoutHeaderProps {
  mainMenu?: React.ReactNode;
  rightMenu?: React.ReactNode;
  hideHeader?: boolean;
}

/**
 * Fascia in alto della pagina che contiene il logo,
 * il menu delle scrivanie, il menu delle notifiche e quello dell'utente
 */
export function PageLayoutHeader(props: PageLayoutHeaderProps) {
  const { mainMenu, rightMenu, hideHeader } = props;
  const isMobile = useBreakpoint(down('sm'));

  if (hideHeader) {
    return null;
  }

  return (
    <FlexHeader>
      {/* <RowDiv> */}
      {!isMobile && (
        <ColLogo>
          <HeaderLogo />
        </ColLogo>
      )}
      <ColMainMenu>{mainMenu}</ColMainMenu>
      <ColRightMenu>{rightMenu}</ColRightMenu>
      {/* </RowDiv> */}
    </FlexHeader>
  );
}

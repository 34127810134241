import { Space } from 'antd';
import * as React from 'react';
import { down } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled from 'styled-components';

const ActionGridWrapper = styled.div`
  /* position: fixed; */
  /* top: ${props => props.theme.widgetGridFixedTop}; */
  /* bottom: 0;
  left: ${props => props.theme.widgetGridFixedSide};
  right: ${props => props.theme.widgetGridFixedSide}; */

  height: 48px;
  position: relative;
  padding: 0 24px 8px 24px;
  z-index: 2;
  display: flex;
  flex: 0 0 auto;
  min-width: 0;
  min-height: 0;

  ${down('lg')} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 12px;
  }
`;
const ActionWidgetWrapper = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  min-height: 0;
  display: flex;
`;

export interface ActionContainerProps {
  children?: React.ReactNode;
}

/**
 * Container per le azioni in basso a sinistra nella pagina
 *
 */
export function ActionContainer(props: ActionContainerProps) {
  const isMobile = useBreakpoint(down('sm'));
  // Su mobile nascondiamo le azioni
  if (isMobile) {
    return null;
  }

  return (
    <ActionGridWrapper>
      <ActionWidgetWrapper>{props.children}</ActionWidgetWrapper>
    </ActionGridWrapper>
  );
}

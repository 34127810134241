import { CloseOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Row, Typography } from 'antd';
const { Title, Text } = Typography;
import { Widget } from 'client/api/backend/schemas';
import { WidgetRepository } from 'client/components/widget-repository/WidgetRepository';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { VerticalSpace } from 'client/ui/space/VerticalSpace';
import * as React from 'react';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { useDeskContext } from '../../desk/context/DeskContext';
import { WidgetComponent } from '../WidgetComponent';
import { WidgetPreview } from './preview-info/WidgetPreview';
import { WidgetTopInfo } from './preview-info/WidgetTopInfo';
import { WidgetConfigurationForm } from './WidgetConfigurationForm';

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
    height: 100%;
    .widget-lock {
      display: none;
    }
  }
`;
const DrawerContentRow = styled(Row)`
  height: 100%;
`;
const FormCol = styled(Col)`
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 16px;
  .ant-collapse {
    .ant-collapse-header {
      color: ${props => props.theme.primaryColor};
    }
  }
`;

export interface IWidgetConfigurationDrawerProps {}

/**
 * Drawer di configurazione del Widget. Contiene un riepilogo del tipo
 * di Widget, nonché le opzioni sia di connessione che di estetica.
 */
export function WidgetConfigurationDrawer(
  props: IWidgetConfigurationDrawerProps
) {
  const { configureWidget, widgets } = useDeskContext();
  const { widget: targetWidget, visible } = configureWidget.state;

  const handleClose = useCallback(() => {
    configureWidget.dispatch({ type: 'close' });
  }, [configureWidget.dispatch]);

  const widget = widgets.find(w => w.id === targetWidget?.id);
  const widgetType = WidgetRepository.fromWidget(widget);

  return (
    <StyledDrawer
      placement="bottom"
      height="100%"
      onClose={handleClose}
      visible={visible}
      key="widget-configuration-drawer"
      closable={false}
      className="no-drag"
    >
      <DrawerContentRow>
        <WidgetPreview widget={widget} />
        <FormCol span={12}>
          <WidgetTopInfo widgetType={widgetType}>
            <ButtonIcon
              size="large"
              icon={<CloseOutlined />}
              onClick={handleClose}
            />
          </WidgetTopInfo>

          {widget && <WidgetConfigurationForm widget={widget} />}
        </FormCol>
      </DrawerContentRow>
    </StyledDrawer>
  );
}

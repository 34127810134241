import React, { useEffect, useRef, useState } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import Feature, { FeatureLike } from 'ol/Feature';
import * as layer from 'ol/layer';
import * as source from 'ol/source';
import * as geom from 'ol/geom';
import * as proj from 'ol/proj';
import { Zoom } from 'ol/control';
import TileLayer from 'ol/layer/Tile.js';
import OSM from 'ol/source/OSM.js';
import { IGeoLocation } from 'client/components/toolkit/components/map/IGeoLocation';
import { Style, Text, Circle, Fill, Stroke } from 'ol/style';

export interface WidgetMapProps {
  pointsColor: string;
  selectedCenter?: IGeoLocation;
  details: {
    label?: string;
    coordinates: number[];
  }[];
}

export function WidgetMap(props: WidgetMapProps) {
  const { pointsColor, selectedCenter, details } = props;

  const mapCenter = selectedCenter
    ? [selectedCenter.longitude, selectedCenter.latitude]
    : [12.4964, 41.9028];

  const mapZoom = selectedCenter ? 15 : 5;

  const mapTargetRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<Map | null>();

  const pointsLayerRef = useRef(
    new layer.Vector({ source: new source.Vector({}) })
  );

  useEffect(() => {
    const features = details.map(d => {
      return new Feature({
        name: d.label,
        geometry: new geom.Point(proj.fromLonLat(d.coordinates))
      });
    });

    function pointStyle(feature: FeatureLike, resolution: any) {
      const text = resolution > 1000 ? '' : feature.get('name');

      return new Style({
        image: new Circle({
          radius: 4,
          stroke: new Stroke({ color: pointsColor, width: 1 })
        }),
        text: new Text({
          text: text,
          stroke: new Stroke({ color: 'white', width: 4 }),
          fill: new Fill({ color: pointsColor }),
          textBaseline: 'top',
          offsetY: 7
        })
      });
    }

    pointsLayerRef.current.setStyle(pointStyle);

    pointsLayerRef.current.getSource()?.clear();
    pointsLayerRef.current.getSource()?.addFeatures(features);
  }, [details, pointsColor]);

  const initializeMap = () => {
    if (mapRef.current?.getTarget() != null) {
      mapRef.current.setTarget(mapTargetRef.current || '');
      return;
    }

    const zoomControl = new Zoom({});

    mapRef.current = new Map({
      layers: [new TileLayer({ source: new OSM() })],
      controls: [zoomControl],
      view: new View({
        center: proj.fromLonLat(mapCenter),
        zoom: mapZoom,
        minZoom: 0,
        maxZoom: 28
      })
    });

    mapRef.current.setTarget(mapTargetRef.current || '');
    mapRef.current.addLayer(pointsLayerRef.current);
  };

  useEffect(() => {
    mapRef.current?.setView(
      new View({
        center: proj.fromLonLat(mapCenter),
        zoom: mapZoom,
        minZoom: 0,
        maxZoom: 28
      })
    );
  }, [selectedCenter]);

  return (
    <div
      ref={divRef => {
        mapTargetRef.current = divRef;
        initializeMap();
      }}
      className="map"
      style={{ width: '100%', height: '100%' }}
    ></div>
  );
}

/**
 * Generato automaticamente.
 * DeskUI REST API
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  EndpointResponseDTO,
  GetEndpointsParams,
  Endpoint,
  EndpointCreateDTO,
  ConfigurationTemplate
} from '.././schemas';
import {
  apiInstance,
  ErrorType
} from '../../../core/network/mutators/apiClient';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Ritorna la lista degli endpoint presenti
 */
export const getEndpoints = (params?: GetEndpointsParams) => {
  return apiInstance<EndpointResponseDTO[]>({
    url: `/api/v1/endpoints`,
    method: 'get',
    params
  });
};

export const getGetEndpointsQueryKey = (params?: GetEndpointsParams) => [
  `/api/v1/endpoints`,
  ...(params ? [params] : [])
];

export type GetEndpointsQueryResult = NonNullable<
  AsyncReturnType<typeof getEndpoints>
>;
export type GetEndpointsQueryError = ErrorType<void>;

export const useGetEndpoints = <
  TData = AsyncReturnType<typeof getEndpoints>,
  TError = ErrorType<void>
>(
  params?: GetEndpointsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getEndpoints>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetEndpointsQueryKey(params);

  const queryFn: QueryFunction<AsyncReturnType<typeof getEndpoints>> = () =>
    getEndpoints(params);

  const query = useQuery<AsyncReturnType<typeof getEndpoints>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Inserisce un endpoint
 */
export const postEndpoints = (endpointCreateDTO: EndpointCreateDTO) => {
  return apiInstance<Endpoint | void>({
    url: `/api/v1/endpoints`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: endpointCreateDTO
  });
};

export type PostEndpointsMutationResult = NonNullable<
  AsyncReturnType<typeof postEndpoints>
>;
export type PostEndpointsMutationBody = EndpointCreateDTO;
export type PostEndpointsMutationError = ErrorType<unknown>;

export const usePostEndpoints = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postEndpoints>,
    TError,
    { data: EndpointCreateDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postEndpoints>,
    { data: EndpointCreateDTO }
  > = props => {
    const { data } = props || {};

    return postEndpoints(data);
  };

  return useMutation<
    AsyncReturnType<typeof postEndpoints>,
    TError,
    { data: EndpointCreateDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Aggiorna un endpoint
 */
export const putEndpoints = (endpoint: Endpoint) => {
  return apiInstance<Endpoint | void>({
    url: `/api/v1/endpoints`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: endpoint
  });
};

export type PutEndpointsMutationResult = NonNullable<
  AsyncReturnType<typeof putEndpoints>
>;
export type PutEndpointsMutationBody = Endpoint;
export type PutEndpointsMutationError = ErrorType<unknown>;

export const usePutEndpoints = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof putEndpoints>,
    TError,
    { data: Endpoint },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof putEndpoints>,
    { data: Endpoint }
  > = props => {
    const { data } = props || {};

    return putEndpoints(data);
  };

  return useMutation<
    AsyncReturnType<typeof putEndpoints>,
    TError,
    { data: Endpoint },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Ritorna i dati dell'endpoint identificato
 */
export const getEndpointsId = (id: number) => {
  return apiInstance<Endpoint>({
    url: `/api/v1/endpoints/${id}`,
    method: 'get'
  });
};

export const getGetEndpointsIdQueryKey = (id: number) => [
  `/api/v1/endpoints/${id}`
];

export type GetEndpointsIdQueryResult = NonNullable<
  AsyncReturnType<typeof getEndpointsId>
>;
export type GetEndpointsIdQueryError = ErrorType<void>;

export const useGetEndpointsId = <
  TData = AsyncReturnType<typeof getEndpointsId>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getEndpointsId>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetEndpointsIdQueryKey(id);

  const queryFn: QueryFunction<AsyncReturnType<typeof getEndpointsId>> = () =>
    getEndpointsId(id);

  const query = useQuery<AsyncReturnType<typeof getEndpointsId>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  );

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Cancella l' endpoint identificato
 */
export const deleteEndpointsId = (id: number) => {
  return apiInstance<void>({
    url: `/api/v1/endpoints/${id}`,
    method: 'delete'
  });
};

export type DeleteEndpointsIdMutationResult = NonNullable<
  AsyncReturnType<typeof deleteEndpointsId>
>;

export type DeleteEndpointsIdMutationError = ErrorType<unknown>;

export const useDeleteEndpointsId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteEndpointsId>,
    TError,
    { id: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deleteEndpointsId>,
    { id: number }
  > = props => {
    const { id } = props || {};

    return deleteEndpointsId(id);
  };

  return useMutation<
    AsyncReturnType<typeof deleteEndpointsId>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Ritorna il template dell' endpoint identificato
 */
export const getEndpointsIdTemplate = (id: number) => {
  return apiInstance<ConfigurationTemplate>({
    url: `/api/v1/endpoints/${id}/template`,
    method: 'get'
  });
};

export const getGetEndpointsIdTemplateQueryKey = (id: number) => [
  `/api/v1/endpoints/${id}/template`
];

export type GetEndpointsIdTemplateQueryResult = NonNullable<
  AsyncReturnType<typeof getEndpointsIdTemplate>
>;
export type GetEndpointsIdTemplateQueryError = ErrorType<void>;

export const useGetEndpointsIdTemplate = <
  TData = AsyncReturnType<typeof getEndpointsIdTemplate>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getEndpointsIdTemplate>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetEndpointsIdTemplateQueryKey(id);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getEndpointsIdTemplate>
  > = () => getEndpointsIdTemplate(id);

  const query = useQuery<
    AsyncReturnType<typeof getEndpointsIdTemplate>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

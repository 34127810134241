import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { DocumentToolkitObject } from 'client/components/widget-repository/objects/documents/DocumentToolkitObject';
import { yup } from 'common/validation/initYup';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { postDocumentaryAssignmentsMultiple } from 'client/api/backend/documentary-assignments/documentary-assignments';
import { InferType } from 'yup';
import { message } from 'antd';
import { FvIconUserAssignment } from 'client/ui/icon/FvIcons';
import {
  AssignmentModelToolkitObject,
  IAssignmentModel
} from 'client/components/widget-repository/objects/assignment-models/AssignmentModelToolkitObject';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { AssignmentPriorityRadioInput } from 'client/components/widget-repository/objects/assignments/AssignmentPriorityRadioInput';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { AssignmentToolkitObject } from 'client/components/widget-repository/objects/assignments/AssignmentToolkitObject';
import { DeskAssignment } from 'client/api/backend/schemas';
import { isAssignmentStale } from 'client/components/widget-repository/objects/assignments/isAssignmentStale';
import { isAssignmentActionAllowed } from 'client/components/widget-repository/objects/assignments/isAssignmentActionAllowed';
import { isDocumentAccessible } from 'client/components/widget-repository/objects/documents/logic/isDocumentAccessible';

export const WidgetActionAssignDocumentToModel = createToolkitAction({
  name: 'Assegnazione a Modello',
  code: 'assign-document-to-model',
  icon: <FvIconUserAssignment />,
  showInBar: false,
  arguments: [
    {
      name: 'Documento da Assegnare',
      type: [DocumentToolkitObject, AssignmentToolkitObject],
      allows: (obj: ToolkitObject) =>
        !isAssignmentStale(obj.data) &&
        isAssignmentActionAllowed(obj) &&
        isDocumentAccessible(obj)
    },
    {
      name: 'Assegnato a',
      type: [AssignmentModelToolkitObject]
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    entityToAssignObject: ToolkitObject,
    modelObject: ToolkitObject | null
  ) {
    ctx.deskContext.actionMessages.prompt({
      ctx,
      action: WidgetActionAssignDocumentToModel,
      args: [entityToAssignObject, modelObject],
      confirmText: 'Assegna al modello',
      schema: AssignSchema,
      initialValues: {},
      onConfirm,
      form: (
        <>
          <FormFieldsContainer>
            <FormFieldItem
              size="large"
              component={AssignmentPriorityRadioInput}
              label="Priorità"
              name="priorityId"
              widgetId={ctx.sourceWidget!.id!}
            />
            <FormFieldItem
              size="large"
              component={DatePickerInput}
              type="unix"
              label={null}
              name="expiresAt"
              placeholder="Data Scadenza"
            />
            <FormFieldItem
              size="large"
              component={TextAreaInput}
              label={null}
              name="notes"
              placeholder="Note"
            />
          </FormFieldsContainer>
        </>
      )
    });
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: InferType<typeof AssignSchema>,
  entityToAssignObject: ToolkitObject,
  modelObject: ToolkitObject | null
) {
  const model = AssignmentModelToolkitObject.get(modelObject);

  const document = DocumentToolkitObject.get(entityToAssignObject)!;
  const assignment = AssignmentToolkitObject.get(entityToAssignObject);

  try {
    await postDocumentaryAssignmentsMultiple({
      id: document.id as number,
      notes: values.notes,
      dateExpire: values.expiresAt,
      idPriority: values.priorityId,
      children: model?.data.assignees.map(item => ({
        entityId: item.assignee.data.ente,
        type: item.typeId,
        id: item.assignee.type !== 'office' ? item.assignee.data.id : undefined,
        officeId:
          item.assignee.type === 'office'
            ? item.assignee.data.id?.toString()
            : undefined
      })),
      widgetId: document.sourceWidgetId,
      fromAssignmentId: assignment?.data.id
    });
    message.success('Assegnazione al modello effettuata con successo');
  } catch (e) {
    message.error(getNetworkErrorMessage(e, "Errore durante l'assegnazione."));
    console.error(e);
    return false;
  }
}

const AssignSchema = yup.object({
  notes: yup.string().notRequired().label('Note'),
  priorityId: yup.number().notRequired().label('Priorità'),
  expiresAt: yup.number().notRequired().label('Data Scadenza')
});

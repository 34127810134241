import {
  DeskOffice,
  GetDocumentaryStatisticsAssignmentsParams,
  Widget
} from 'client/api/backend/schemas';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { yup } from 'common/validation/initYup';
import * as React from 'react';
import * as WidgetFilterSections from 'client/components/toolkit/components/filter/WidgetFilterSections';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { Button, Space } from 'antd';
import { DocumentProtocolModeInput } from 'client/components/widget-repository/objects/documents/DocumentProtocolModeInput';
import { WidgetFilterDrawer } from 'client/components/toolkit/components/filter/WidgetFilterDrawer';
import { renderWidgetFilterActiveTags } from 'client/components/toolkit/components/filter/renderWidgetFilterActiveTags';
import { useState } from 'react';
import { ToolkitObjectInput } from 'client/components/toolkit/objects/input/ToolkitObjectInput';
import { OfficeToolkitObject } from 'client/components/widget-repository/objects/offices/OfficeToolkitObject';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { omit } from 'lodash';
import { UserOfficeInput } from 'client/components/widget-repository/objects/offices/UserOfficeInput';

export interface IWidgetMonitorOfficeAssignmentsSearchFormProps {
  widget: Widget;
  search: Partial<GetDocumentaryStatisticsAssignmentsParams>;
  setSearch: (
    search: Partial<GetDocumentaryStatisticsAssignmentsParams>
  ) => void;
}

interface IWidgetMonitorOfficeAssignmentsSearchFormValues
  extends GetDocumentaryStatisticsAssignmentsParams {
  office: ToolkitObject;
}

export function WidgetMonitorOfficeAssignmentsSearchForm(
  props: IWidgetMonitorOfficeAssignmentsSearchFormProps
) {
  const { widget, search, setSearch } = props;
  const [open, setOpen] = useState(false);

  return (
    <FormikAugmented
      validationSchema={yup.object({})}
      validationContext={{}}
      initialValues={search}
      onReset={() => {
        setSearch({});
        setOpen(false);
      }}
      onSubmit={(
        rawValues: Partial<IWidgetMonitorOfficeAssignmentsSearchFormValues>
      ) => {
        setSearch(rawValues);
        setOpen(false);
      }}
    >
      <WidgetFilterDrawer
        title="Ricerca"
        filters={renderWidgetFilterActiveTags({
          search,
          filters: widget.profile?.filters,
          onReset: () => setSearch({})
        })}
        open={[open, setOpen]}
      >
        <FormikForm layout="vertical" editable helpInTooltips>
          <WidgetFilterSections.BodyWrapper>
            <FormFieldsContainer>
              <FormFieldItem
                size="middle"
                component={UserOfficeInput}
                widgetId={widget.id!}
                hierarchy={true}
                name="officeId"
                label={null}
                placeholder="Ufficio"
              />
            </FormFieldsContainer>
            <FormFieldsContainer gutter={8}>
              <FormFieldItem
                size="middle"
                component={DocumentProtocolModeInput}
                widgetId={widget.id!}
                label={null}
                name="mode"
                placeholder="Modalità"
              />
            </FormFieldsContainer>
            <FormFieldsContainer columns={2} gutter={8}>
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                type="unix"
                label={null}
                name="dateStart"
                placeholder="Dal"
              />
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                type="unix"
                label={null}
                name="dateEnd"
                placeholder="Al"
              />
            </FormFieldsContainer>
          </WidgetFilterSections.BodyWrapper>
          <WidgetFilterSections.ButtonsWrapper>
            <Space>
              <Button htmlType="reset">Pulisci</Button>
              <Button htmlType="submit" type="primary">
                Filtra
              </Button>
            </Space>
          </WidgetFilterSections.ButtonsWrapper>
        </FormikForm>
      </WidgetFilterDrawer>
    </FormikAugmented>
  );
}

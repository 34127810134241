import React from 'react';
import clx from 'classnames';
import styled from 'styled-components';
import { RightOutlined } from '@ant-design/icons';

const IconSelectedDopdownMenu = styled.span`
  display: none;
  color: ${props => props.theme.primaryColor};
  margin-right: 4px;
  .desk-menu-dropdown .selected &,
  .ant-menu-submenu.ant-menu-submenu-popup .selected & {
    display: inline;
  }
`;

export interface IconDeskMenuProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  icon?: React.ReactNode;
}
/**
 * Wrapper per le icone custom o react-icons per associare gli stili base di Ant
 */
export function IconDeskMenu(props: IconDeskMenuProps) {
  const { icon, children, className, ...otherProps } = props;

  return (
    <span role="img" className={clx('anticon', className)} {...otherProps}>
      <IconSelectedDopdownMenu children={<RightOutlined />} />
      {icon ?? children}
    </span>
  );
}

import { message } from 'antd';
import { usePostDocumentaryAssignmentsMultiple } from 'client/api/backend/documentary-assignments/documentary-assignments';
import {
  getDocumentaryMailsAttachment,
  getGetDocumentaryMailsQueryKey,
  useGetDocumentaryMailsId,
  usePostDocumentaryMailsProtocol
} from 'client/api/backend/documentary-mails/documentary-mails';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { omit } from 'lodash';
import React, { useMemo } from 'react';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { MailProtocolAssignmentSchema } from './form/MailProtocolAssignmentSchema';
import { WidgetMailProtocolAssignmentForm } from './form/WidgetMailProtocolAssignmentForm';
import { WidgetMailProtocolAssignmentProfile } from './WidgetMailProtocolAssignmentProfile';
import moment from 'moment';
import { useDeskContext } from 'client/components/schema/desk/context/DeskContext';
import {
  AssigneeToolKitObject,
  getAssigneeChildren
} from './getAssigneeChildren';
import { DocumentMode } from '../../objects/documents/DocumentMode';
import { useDispatch } from 'react-redux';
import { DeskActions } from 'client/components/schema/desk/DeskModule';
import { queryClient } from 'client/core/network/queryClient';

export interface IWidgetMailProtocolAssignmentProps
  extends IWidgetComponentToolkitProps {}

export function WidgetMailProtocolAssignment(
  props: IWidgetMailProtocolAssignmentProps
) {
  const deskContext = useDeskContext();
  const dispatch = useDispatch();

  const { widget } = props;
  const data = widget.profile?.data as
    | WidgetMailProtocolAssignmentProfile
    | undefined;

  const mailResult = useGetDocumentaryMailsId(
    data?.mail.id!,
    { widgetId: widget.id! },
    {
      query: {
        enabled: data?.mail.id != null
      }
    }
  );

  const mail = mailResult.data;

  const mailElements = [
    ...(mail?.attachments ?? []),
    ...(mail?.mailElements ?? [])
  ];

  const protocol = usePostDocumentaryMailsProtocol({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetDocumentaryMailsQueryKey());
      }
    }
  });
  const assign = usePostDocumentaryAssignmentsMultiple();

  const interoperableValues = useMemo(() => {
    if (!mail?.interoperable) {
      return {};
    }

    return {
      sender: mail?.sender,
      elementPath: mailElements.find(e => e.elementName === mail.contentName)
        ?.elementPath
    };
  }, [mailElements, data, mail]);

  return (
    <FormikAugmented<any>
      validationSchema={MailProtocolAssignmentSchema}
      validationContext={{}}
      initialValues={{
        ...interoperableValues,
        subject: data?.mail.subject,
        assignment: {
          assignees: data?.assignee ? [data.assignee] : []
        }
      }}
      enableReinitialize
      onSubmit={async (values, helpers) => {
        try {
          const classifications = values.classifications as
            | ToolkitObject[]
            | null;

          const selectedAttachment = mailElements?.find(
            a => a.elementPath === values.elementPath
          );

          // Protocollazione della mail
          const protocolResult = await protocol.mutateAsync({
            data: {
              ...omit(values, ['assignment', 'elementPath', 'addMailNotes']),
              contentName: selectedAttachment?.elementName,
              classifications: classifications?.map(c => ({
                id: c.id as number
              })),
              idMail: mail?.id as number,
              note: values.addMailNotes ? mail?.note : undefined,
              info: {
                dateReceived: moment(mail?.date).valueOf().toString(),
                type: DocumentMode.Incoming
              },
              widgetId: widget.id,
              widgetIdFrom: widget.id
            }
          });

          message.success(`La mail è stata protocollata con successo.`);

          try {
            if (
              protocolResult &&
              (values.assignment?.assignees?.length ?? 0) > 0
            ) {
              const assigneeChildren = (
                values.assignment?.assignees as AssigneeToolKitObject[]
              )?.flatMap(a => getAssigneeChildren(a, values.assignment.typeId));

              // Assegnazione della mail protocollata
              await assign.mutateAsync({
                data: {
                  id: protocolResult.id as number,
                  notes: values.assignment.notes,
                  dateExpire: values.assignment.expiresAt,
                  idPriority: values.assignment.priorityId,
                  children: assigneeChildren,
                  widgetId: widget.id
                }
              });

              message.success(
                `La mail protocollata è stata assegnata con successo.`
              );
            }
          } catch (error) {
            message.error(
              getNetworkErrorMessage(
                error,
                `Si è verificato un errore durante l'assegnazione della mail protocollata.`
              )
            );
            console.error(error);
          }

          // Disabilitiamo la modalità fullscreen
          dispatch(DeskActions.fullScreen(false));
          deskContext.remove(widget);
        } catch (error) {
          message.error(
            getNetworkErrorMessage(
              error,
              `Si è verificato un errore durante la protocollazione della mail.`
            )
          );
          console.error(error);
        }
      }}
    >
      <WidgetCardBody>
        <WidgetMailProtocolAssignmentForm
          widget={widget}
          data={mail}
          mailElements={mailElements}
        />
      </WidgetCardBody>
    </FormikAugmented>
  );
}

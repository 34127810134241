import moment from 'moment';
import { isNumeric } from 'voca';

export const dateInputUtils = {
  toMoment(
    type: 'from' | 'to' | 'date-only' | 'unix' | 'italian-date' | 'from-utc',
    raw: string | undefined
  ): moment.Moment | undefined {
    if (!raw) return undefined;

    if (type === 'from-utc') {
      return moment.utc(raw).local();
    }

    if (type === 'date-only') {
      return moment(raw, 'YYYY-MM-DD');
    }

    if (type === 'italian-date') {
      const date = moment(raw, 'DD/MM/YYYY');

      // Fallback come unix timestamp
      if (!date.isValid() && (isNumeric(raw) || typeof raw === 'number')) {
        return moment(Number(raw));
      }

      return date;
    }

    if (type === 'unix') {
      return moment(Number(raw));
    }
    const date = moment(raw, moment.ISO_8601);
    return date;
  },
  toString(
    type: 'from' | 'to' | 'date-only' | 'unix' | 'italian-date' | 'from-utc',
    raw: moment.Moment | undefined | null
  ): string | undefined {
    if (!raw) return undefined;

    // Convertiamo la data nella TimeZone di interesse per l'applicativo
    const date = moment(raw.format('DD/MM/YYYY'), 'DD/MM/YYYY');

    // console.log('tz', date.tz());
    // console.log(
    //   'date',
    //   raw.format('DD/MM/YYYY'),
    //   raw.toISOString(),
    //   type === 'start'
    //     ? date.startOf('day').toISOString()
    //     : date.endOf('day').toISOString()
    // );

    switch (type) {
      case 'from':
        return date.startOf('day').toISOString();
      case 'from-utc':
        return date.startOf('day').utc().toISOString();
      case 'to':
        return date.endOf('day').toISOString();
      case 'date-only':
        return date.format('YYYY-MM-DD');
      case 'unix':
        return date.startOf('day').valueOf().toString();
      case 'italian-date':
        return date.format('DD/MM/YYYY');
    }
  }
};

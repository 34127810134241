import { MailOutlined } from '@ant-design/icons';
import { DeskWidgetsLogic } from 'client/components/schema/desk/logic/DeskWidgetsLogic';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import {
  getToolkitObjectOfType,
  ToolkitObject
} from 'client/components/toolkit/objects/ToolkitObject';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { MailToolkitObject } from 'client/components/widget-repository/objects/mails/MailToolkitObject';
import { set } from 'client/core/data/set';
import { FvIconMailView } from 'client/ui/icon/FvIcons';
import React from 'react';
import { WidgetMailViewerType } from '../WidgetMailViewer.type';
import { WidgetMailViewerProfile } from '../WidgetMailViewerProfile';

export const WidgetActionOpenMail = createToolkitAction({
  name: 'Apri Email',
  code: 'open-mail',
  icon: <FvIconMailView />,
  arguments: [
    {
      type: [MailToolkitObject],
      name: 'Email da Aprire'
    },
    {
      type: [WidgetToolkitObject],
      allows: (object: ToolkitObject) =>
        WidgetToolkitObject.matches(object, WidgetMailViewerType),
      name: 'Widget Visualizza Email'
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    object: ToolkitObject,
    targetObject: ToolkitObject | null
  ) {
    const mailObject = getToolkitObjectOfType(object, 'mail')!;
    const target = WidgetToolkitObject.get(targetObject);

    const data: WidgetMailViewerProfile = {
      mailId: mailObject.id as number,
      mailSubject: mailObject.data.subject
    };

    if (target) {
      const widget = ctx.deskContext.widgets.find(w => w.id === target.id)!;
      await ctx.deskContext.updateWidget(set(widget, 'profile.data', data));
      return;
    }

    await ctx.deskContext.cloneWidget(
      {
        type: 'floating',
        expanded: true,
        order: DeskWidgetsLogic.getFloatingNextOrder(
          ctx.deskContext.floatingWidgets
        )
      },
      WidgetMailViewerType,
      ctx.sourceWidget!,
      {
        data,
        quickClose: true,
        initializeInFullScreen:
          ctx.sourceWidget?.profile?.showChildWidgetInFullScreen ?? false
      }
    );
  }
});

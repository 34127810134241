import * as React from 'react';
import { FileOutlined } from '@ant-design/icons';
import { DeskDocument, Widget } from 'client/api/backend/schemas';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import moment from 'moment';
import { DocumentToolkitObject } from 'client/components/widget-repository/objects/documents/DocumentToolkitObject';
import { convertDocumentModeToElementTextExtend } from './convertDocumentModeToElementTextExtend';

export function convertDocumentToWidgetListElement(
  document: DeskDocument,
  widget: Widget,
  folderId?: number
): IWidgetListElement {
  const documentName = !document.protocolNumber
    ? document.filename ?? `Documento #${document.id!}`
    : document.subject ?? document.filename ?? `Documento #${document.id!}`;

  const mode = convertDocumentModeToElementTextExtend(document.mode);

  return {
    name: documentName,
    nameExtend: {
      before: document.protocolNumber?.toString(),
      bold: true
    },
    description: document.protocolNumber
      ? document.shelfmark
      : document.subject,
    descriptionExtend: mode,
    typeColor: mode?.color ?? undefined,
    object: DocumentToolkitObject.create({
      icon: <FileOutlined />,
      id: document.id!,
      name: documentName,
      data: { folderId, ...document },
      sourceWidgetId: widget.id!
    })
  };
}

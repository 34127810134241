import * as React from 'react';
import styled from 'styled-components';
import { Typography, Timeline, TimelineItemProps, Tag, TagProps } from 'antd';
import { ISODate } from 'client/ui/display/date/ISODate';
import { HistoryMiniTag, IHistoryTag } from './HistoryTag';
const { Title, Text, Paragraph } = Typography;

const StyledTimelineItem = styled(Timeline.Item)`
  padding-bottom: 12px;

  .ant-timeline-item-content {
    margin-left: 20px;
  }
  // Le classi sono impostate nel componente DocumentHistoryTimeline
  // (is-child-item, is-last-item, has-children)
  &.is-child-item {
  }
  &.is-last-item:not(.has-children) {
    .ant-timeline-item-tail {
      display: none;
    }
  }
  // L'indentazione è gestita dal componente HistoryTimeline
  // (padding-left: 8px)
  &.has-children {
    .ant-timeline-item-tail {
      width: 8px;
      height: calc(100% - 4px);
      border-left: 2px solid #e7ecee;
      border-bottom: 2px solid #e7ecee;
    }
  }
`;

const FirstLineParagraph = styled(Paragraph)`
  &&& {
    font-size: 13px;
    margin-bottom: 0;
  }
`;
const SecondLineParagraph = styled(FirstLineParagraph)`
  margin-top: 2px;
  font-size: 12px;
`;

export interface HistoryItemProps {
  id?: number;
  parentId?: number;
  historyChildren?: HistoryItemProps[];
  /** RIGA 1 */
  /** Data operazione */
  date: Date | string;
  dateFormat?: string;
  /** Utente/Soggetto ricevente / Tipo di operazione */
  receiver: string;
  /** Motivo (visualizzato come Tag) */
  reasonTag?: IHistoryTag;
  /** Stato */
  stateTag?: IHistoryTag;

  /** RIGA 2 */
  /** Modalità operazione*/
  mode?: string;
  /** Utente che ha eseguito l'operazione */
  submitter: string;
  /** Ufficio dell'utente che ha eseguito l'operazione */
  submitterOffice?: string;
}
export interface IHistoryItemProps
  extends HistoryItemProps,
    TimelineItemProps {}

/**
 * Singolo nodo della Cronologia
 */
export function HistoryItem(props: IHistoryItemProps) {
  const {
    id,
    parentId,
    historyChildren,
    receiver,
    reasonTag,
    stateTag,
    date,
    dateFormat,
    mode,
    submitter,
    submitterOffice,
    ...timelineItemProps
  } = props;

  return (
    <StyledTimelineItem {...timelineItemProps}>
      <FirstLineParagraph>
        <ISODate date={date} format={dateFormat} />
        <Text strong> - {receiver} </Text>
        {reasonTag && !reasonTag.isHidden && (
          <HistoryMiniTag color={reasonTag.color ?? 'purple'}>
            {reasonTag.label}
          </HistoryMiniTag>
        )}
        {stateTag && !stateTag.isHidden && (
          <HistoryMiniTag color={stateTag.color}>
            {stateTag.label}
          </HistoryMiniTag>
        )}
      </FirstLineParagraph>
      <SecondLineParagraph>
        {mode && <Text type="secondary">{mode}: </Text>}
        <Text>{submitter} </Text>
        {submitterOffice && <Text type="secondary"> - {submitterOffice}</Text>}
      </SecondLineParagraph>
    </StyledTimelineItem>
  );
}

import { getDocumentaryAssignmentsMassiveConclude } from 'client/api/backend/documentary-assignments/documentary-assignments';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { AssignmentToolkitObject } from 'client/components/widget-repository/objects/assignments/AssignmentToolkitObject';
import { invalidateAssignmentQueries } from '../invalidateAssignmentQueries';

/**
 * Funzione che si occupa della chiamata alla API per la conclusione delle assegnazioni
 */
export async function executeConcludeAssignment(
  assignmentObjects: ToolkitObject | ToolkitObject[]
) {
  const assignments = AssignmentToolkitObject.getMany(assignmentObjects)!;

  await getDocumentaryAssignmentsMassiveConclude({
    ids: assignments.map(a => a.id as number),
    widgetId: assignments[0].sourceWidgetId
  });

  // Invalido la cache
  invalidateAssignmentQueries();
}

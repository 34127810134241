import React, { useState } from 'react';
import styled from 'styled-components';
import { UserButton } from './UserButton';
import { NotificationsButton } from './NotificationsButton';
import { Button, Space } from 'antd';
import { RightSideMenuPortalContainer } from './RightSideMenuPortal';

export interface RightSideMenuProps {
  extra?: React.ReactNode;
}

export function RightSideMenu(props: RightSideMenuProps) {
  return (
    <Space>
      <RightSideMenuPortalContainer />
      {/* Commentiamo il pannello notifiche 
      in attesa dello sviluppo della funzionalità */}
      {/* <NotificationsButton /> */}
      <UserButton />
    </Space>
  );
}

import styled from 'styled-components';

export const $GroupWrapper = styled.div`
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  border: 2px solid transparent;
  border-radius: 6px;
  // per allineamento label campo di input
  margin-top: -2px;
  &:hover {
    border-color: #abd5ff;
  }
  &.toolkit-object-input-active {
    transition: all none;
    box-shadow: ${props => props.theme.shadowBase};
    border: 2px solid ${props => props.theme.primaryColor};
    border-radius: 6px;
  }
  &.toolkit-object-input-invalid {
    border-color: ${props => props.theme.errorColor};
  }
`;

import * as React from 'react';
import { Card, CardProps, Drawer, DrawerProps } from 'antd';
import clx from 'classnames';
import styled from 'styled-components';

const DrawerInWidget = styled(Drawer)`
  position: absolute;
  .ant-drawer-mask {
    border-bottom-left-radius: ${props => props.theme.cardRadius};
    border-bottom-right-radius: ${props => props.theme.cardRadius};
  }
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      border-bottom-left-radius: ${props => props.theme.cardRadius};
      border-bottom-right-radius: ${props => props.theme.cardRadius};
      .ant-drawer-header {
        border-top: 1px solid ${props => props.theme.borderColorBase};
        padding: 10px;
      }
      .ant-drawer-title {
        line-height: 1.3;
      }
      .ant-drawer-body {
        padding: 0;
      }
    }
  }
`;
const DrawerInWidgetLight = styled(DrawerInWidget)`
  .ant-drawer-mask {
    background-color: rgba(255, 255, 255, 0.85);
  }
  .ant-drawer-content-wrapper {
    padding: 10px;
    border-top: 1px solid ${props => props.theme.borderColorBase};
    .ant-drawer-content {
      border-top: none;
      border-radius: ${props => props.theme.cardRadius};
      max-width: 378px;
      margin: 0 auto;
      box-shadow: 0 10px 40px 0 rgba(151, 169, 178, 0.5),
        0 5px 10px 0 rgba(0, 0, 0, 0.05), 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    }
  }
`;

export interface WidgetDrawerProps extends DrawerProps {
  children: React.ReactNode;
}

/**
 * //TODO
 */
export function WidgetDrawer(props: WidgetDrawerProps) {
  return (
    <DrawerInWidget
      width="100%"
      placement="right"
      getContainer={false}
      {...props}
    />
  );
}

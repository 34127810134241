import { EyeOutlined } from '@ant-design/icons';
import { DeskWidgetsLogic } from 'client/components/schema/desk/logic/DeskWidgetsLogic';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import {
  WidgetToolkitData,
  WidgetToolkitObject
} from 'client/components/toolkit/objects/WidgetToolkitObject';
import { AssignmentToolkitObject } from 'client/components/widget-repository/objects/assignments/AssignmentToolkitObject';
import { set } from 'client/core/data/set';
import { FvIconUserAssignmentView } from 'client/ui/icon/FvIcons';
import React from 'react';
import { WidgetAssignmentViewerType } from '../WidgetAssignmentViewer.type';
import { WidgetAssignmentViewerProfile } from '../WidgetAssignmentViewerProfile';

export const WidgetActionOpenAssignment = createToolkitAction({
  name: 'Visualizza Assegnazione',
  code: 'view-assignment',
  icon: <FvIconUserAssignmentView />,
  showInBar: false,
  arguments: [
    {
      type: [AssignmentToolkitObject],
      name: 'Assegnazione da Visualizzare'
    },
    {
      type: [WidgetToolkitObject],
      // Può essere aperto solo in un widget dedicato
      allows: (object: ToolkitObject<WidgetToolkitData>) =>
        WidgetToolkitObject.matches(object, WidgetAssignmentViewerType),
      name: 'Widget di visualizzazione'
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    object: ToolkitObject,
    targetObject: ToolkitObject | null
  ) {
    const assignment = AssignmentToolkitObject.get(object)!;
    const target = WidgetToolkitObject.get(targetObject);

    const data: WidgetAssignmentViewerProfile = {
      assignmentId: assignment.id as number,
      documentSubject:
        assignment.data.document?.subject ?? assignment.data.document?.filename
    };

    // Se stiamo aprendo in un widget target, usiamo quello.
    if (target) {
      const widget = ctx.deskContext.widgets.find(w => w.id === target.id)!;
      await ctx.deskContext.updateWidget(set(widget, 'profile.data', data));
      return;
    }

    await ctx.deskContext.cloneWidget(
      {
        type: 'floating',
        expanded: true,
        order: DeskWidgetsLogic.getFloatingNextOrder(
          ctx.deskContext.floatingWidgets
        )
      },
      WidgetAssignmentViewerType,
      ctx.sourceWidget!,
      {
        data,
        quickClose: true,
        initializeInFullScreen:
          ctx.sourceWidget?.profile?.showChildWidgetInFullScreen ?? false
      }
    );
  }
});

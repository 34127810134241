import * as React from 'react';
import { Card, CardProps } from 'antd';
import clx from 'classnames';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';

const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;

  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05), 0 5px 20px 0 rgba(0, 0, 0, 0.04);

  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  ${down('sm')} {
    border-radius: 0;
  }

  .ant-card-head-wrapper {
    /* align-items: start; */
    .ant-card-head-title {
      padding: 8px 0;
    }
  }
  .ant-card-head {
    padding: 0 8px;
    flex: 0 0 auto;
    overflow-x: hidden; // Per supportare le animazioni sulla width
  }
  .ant-card-body {
    height: 100%;
    padding: 0;
    flex: 1 1 auto;
    overflow: hidden;
    min-height: 0;
    min-width: 0;
    border-bottom-left-radius: ${props => props.theme.cardRadius};
    border-bottom-right-radius: ${props => props.theme.cardRadius};
    ${down('sm')} {
      border-radius: 0;
    }
  }
  &.ghost {
    opacity: 0.4;
    &:hover {
      opacity: 1;
    }
  }
  // Ombra mentre si sposta il widget
  // TODO: verificare ... non dovrebbe + attivarsi cob il nuovo dragger
  .react-draggable-dragging & {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px,
      rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
      rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
  }
`;

export interface BaseCardProps extends Omit<CardProps, 'bordered'> {
  ghost?: boolean;
}

/**
 * Card stilizzata del Widget
 */
export function BaseCard(props: BaseCardProps) {
  const { className, ghost, ...otherProps } = props;
  return (
    <StyledCard
      bordered={false}
      className={clx(className, { ghost })}
      {...otherProps}
    />
  );
}

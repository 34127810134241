import * as React from 'react';
import styled from 'styled-components';

export interface WidgetTabPanelProps {
  children: React.ReactNode;

  /** Nome del tab */
  title: string;
  /** Icona da mostrare con tooltips in caso ci sia poco spazio  */
  icon?: React.ReactNode;
  /** Tipologia di tab
   * - main: tab principale, visualizzato in una colonna dedicata in modalità fullscreen
   * - side-menu: tab che contiene un menu, visualizzato come tab in una colonna dedicata
   *   in modalità fullscreen e in un dropdown in modalità mobile
   * - one-tab: è presente un solo tab che diventa il tab principale,
   *   visualizzato a tutta larghezza anche in modalità fullscreen
   *
   * Se non specificato il tab sarà visualizzato come secondario
   * e apparirà nella colonna laterale in modalità fullscreen
   */
  type?: 'main' | 'side-menu' | 'actions-menu' | 'one-tab';
  /** Permette di scegliere la posizione del menù rispetto le tabs
   * quando visualizzato come dropdown (mobile) */
  menuSide?: 'left' | 'right';
  /** Aggiunge  il padding intorno al contenuto del tab */
  padded?: boolean;
  /** key di navigazione del tab, serve solo se non è impostato il type */
  tabKey?: string;

  /** Di default il contenuto e scrollabile
   * passare false per non scrollabile */
  scrollable?: boolean;
}
/**
 * Elemento TabPanel per il singolo Tab
 * utilizzato per passare le Props al componente <WidgetTabs />
 * Da utilizzare come children di <WidgetCardBodyTabs />
 *
 * Specificando il type="main" verrà posizionato in una colonna
 * dedicata in modalità fullsreen
 *
 * Specificando il type="side-menu" il children deve essere un componente <Menu />
 * che verrà visualizzato nel Tab in fullscreen e in un Dropdown in mobile
 */
export function WidgetTabPanel(props: WidgetTabPanelProps) {
  return <>{props.children}</>;
}

import { yup } from 'common/validation/initYup';

export const WidgetDamMonitoringSearchSchema = yup.object({
  startDate: yup.number().notRequired().label('Dal'),
  endDate: yup.number().notRequired().label('Al')
});

export type WidgetDamMonitoringSearchSchemaType = yup.InferType<
  typeof WidgetDamMonitoringSearchSchema
> & {
  selectedGraphView?: number;
};

import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
  EllipsisOutlined,
  FilePdfOutlined,
  FolderOutlined
} from '@ant-design/icons';
import clx from 'classnames';
import { List, Space } from 'antd';
import React, { useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import Draggable from 'react-draggable';
import { DeskActions, useDesk } from 'client/components/schema/desk/DeskModule';
import { useDispatch } from 'react-redux';
import { IWidgetListElement } from './IWidgetListElement';
import { ListElementText } from './ListElementText';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import {
  DraggedToolkitObject,
  toolkitObjectUniqId
} from 'client/components/toolkit/objects/ToolkitObject';
import { ToolkitObjectActionsMenu } from 'client/components/schema/widget/actions/interaction/ToolkitObjectActionsMenu';
import { useRegisterToolkitObject } from 'client/components/toolkit/objects/store/useToolkitObjectStore';
import { WidgetTypeIcon } from './WidgetTypeIcon';
import { useDroppableToolkitObject } from 'client/components/toolkit/objects/useDroppableToolkitObject';
import { useWidgetListMassiveContext } from '../massive/WidgetListMassiveContext';

const ListActionsWrapper = styled(Space)`
  margin-right: -6px;
  .ant-space-item > .ant-btn {
    background-color: transparent;
  }
`;

const ListItemMeta = styled(List.Item.Meta)`
  min-width: 1px;
  .ant-list-item-meta-avatar {
    margin-right: 8px;
    > .anticon {
      font-size: 18px;
    }
  }
  .ant-list-item-meta-title {
    margin-bottom: 0;
  }
  .ant-list-item-meta-description {
  }
`;

const AvatarColor = styled.span<{ color?: string }>`
  color: ${props => props.color || props.theme.textColor};
`;

const ListItem = List.Item as any;
const StyledItem = styled(ListItem)`
  transition: opacity 0.2s ease-in-out;
  transition: background-color 0.6s ease;

  &:hover {
    background-color: ${props => props.theme.borderColorSplit};
  }
  &.dragging-over {
    opacity: 0.5;
  }
  /* &.is-drop-over {
    background-color: ${props => props.theme.primaryColor};
  } */
  // Fix pulsante azioni accapo su mobile
  flex-wrap: nowrap;
  border-left: 3px solid;
  border-left-color: rgba(0, 0, 0, 0);

  &.is-selected {
    border-left-color: ${props => props.theme.primaryColor};
  }

  // TODO lasciarlo generale?
  &.is-massive {
    cursor: pointer;
  }
`;

export interface WidgetListElementProps {
  element: IWidgetListElement;
  className?: string;
}

const CheckIcon = styled(CheckSquareOutlined)`
  color: ${props => props.theme.primaryColor};
`;

const Width100Style = { width: '100%' };

/**
 * Elemento della lista all'interno di un Widget.
 * Il comportamento generico si integra automaticamente con:
 * - Drag&Drop dei ToolkitObject
 * - Azioni disponibili nel menu a tendina
 */
export function BaseWidgetListElement(props: WidgetListElementProps) {
  const { element, ...otherProps } = props;

  const object = props.element.object;
  const objectId = toolkitObjectUniqId(object)!;

  const theme = useTheme();

  const massive = useWidgetListMassiveContext();
  const isSelected = massive.selected.some(o => o.id === object?.id);

  const {
    attributes,
    listeners,
    setNodeRef: setDraggableRef,
    over
  } = useDraggable({
    id: objectId,
    data: {
      object,
      massiveSelected: massive.enabled ? massive.selected : undefined
    } as DraggedToolkitObject
  });

  const { isOver, setNodeRef: setDroppableRef } =
    useDroppableToolkitObject(object);

  const handleMassiveToggle = (e: React.SyntheticEvent<any>) => {
    massive.toggle(object!);
  };

  useRegisterToolkitObject(object);

  const actionsElements = useMemo(
    () => [
      <ListActionsWrapper key="list-actions" size={0}>
        {isSelected && <CheckIcon onClick={handleMassiveToggle} />}
        {element.onNavigate && (
          <ButtonIcon
            size="small"
            icon={<ArrowRightOutlined />}
            onClick={() => element.onNavigate!(element)}
          />
        )}
        <ToolkitObjectActionsMenu object={element.object} />
      </ListActionsWrapper>
    ],
    [isSelected, handleMassiveToggle, element.onNavigate, element.object]
  );

  return (
    <StyledItem
      className={clx(props.className, {
        'is-drop-over': isOver,
        'is-massive': massive.enabled,
        'is-selected': isSelected
      })}
      actions={actionsElements}
    >
      <div
        style={Width100Style}
        ref={setDraggableRef}
        {...listeners}
        {...attributes}
        {...otherProps}
      >
        <div ref={setDroppableRef} onClick={handleMassiveToggle}>
          <ListItemMeta
            avatar={
              <AvatarColor color={element.typeColor}>
                <WidgetTypeIcon type={element.type} />
              </AvatarColor>
            }
            title={
              <ListElementText
                text={element.name}
                extend={element.nameExtend}
              />
            }
            description={
              <div>
                <ListElementText
                  text={element.description}
                  extend={element.descriptionExtend}
                />
                <ListElementText
                  text={element.annotations}
                  extend={element.annotationsExtend}
                />
                <ListElementText
                  text={element.details}
                  extend={element.detailsExtend}
                />
              </div>
            }
          />
        </div>
      </div>
    </StyledItem>
  );
}

export const WidgetListElement = React.memo(BaseWidgetListElement);

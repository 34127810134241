/**
 * Generato automaticamente.
 * DeskUI REST API
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  DeskDam,
  GetDocumentaryDamParams,
  DeskDamObservation,
  GetDocumentaryDamObservationsParams,
  DeskKeyValue,
  GetDocumentaryDamObservationsParametersParams,
  GetDocumentaryDamIdParams,
  GetDocumentaryDamIdObservationsParams,
  GetDocumentaryDamIdObservationsLastParams,
  DeskDamPoint,
  GetDocumentaryDamIdReservoirCurveParams
} from '.././schemas';
import {
  apiInstance,
  ErrorType
} from '../../../core/network/mutators/apiClient';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Ritorna la lista delle dighe secondo i filtri richiesti
 */
export const getDocumentaryDam = (params?: GetDocumentaryDamParams) => {
  return apiInstance<DeskDam[]>({
    url: `/api/v1/documentary/dam`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDamQueryKey = (
  params?: GetDocumentaryDamParams
) => [`/api/v1/documentary/dam`, ...(params ? [params] : [])];

export type GetDocumentaryDamQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDam>
>;
export type GetDocumentaryDamQueryError = ErrorType<void>;

export const useGetDocumentaryDam = <
  TData = AsyncReturnType<typeof getDocumentaryDam>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDamParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDam>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryDamQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDam>
  > = () => getDocumentaryDam(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDam>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary getMultipleDamObservation
 */
export const getDocumentaryDamObservations = (
  params?: GetDocumentaryDamObservationsParams
) => {
  return apiInstance<DeskDamObservation[]>({
    url: `/api/v1/documentary/dam/observations`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDamObservationsQueryKey = (
  params?: GetDocumentaryDamObservationsParams
) => [`/api/v1/documentary/dam/observations`, ...(params ? [params] : [])];

export type GetDocumentaryDamObservationsQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDamObservations>
>;
export type GetDocumentaryDamObservationsQueryError = ErrorType<void>;

export const useGetDocumentaryDamObservations = <
  TData = AsyncReturnType<typeof getDocumentaryDamObservations>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDamObservationsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDamObservations>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryDamObservationsQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDamObservations>
  > = () => getDocumentaryDamObservations(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDamObservations>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary getObservationsParameters
 */
export const getDocumentaryDamObservationsParameters = (
  params?: GetDocumentaryDamObservationsParametersParams
) => {
  return apiInstance<DeskKeyValue[]>({
    url: `/api/v1/documentary/dam/observations/parameters`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDamObservationsParametersQueryKey = (
  params?: GetDocumentaryDamObservationsParametersParams
) => [
  `/api/v1/documentary/dam/observations/parameters`,
  ...(params ? [params] : [])
];

export type GetDocumentaryDamObservationsParametersQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDamObservationsParameters>
>;
export type GetDocumentaryDamObservationsParametersQueryError = ErrorType<void>;

export const useGetDocumentaryDamObservationsParameters = <
  TData = AsyncReturnType<typeof getDocumentaryDamObservationsParameters>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDamObservationsParametersParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDamObservationsParameters>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDamObservationsParametersQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDamObservationsParameters>
  > = () => getDocumentaryDamObservationsParameters(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDamObservationsParameters>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Recupera la diga identificata
 */
export const getDocumentaryDamId = (
  id: string,
  params?: GetDocumentaryDamIdParams
) => {
  return apiInstance<DeskDam>({
    url: `/api/v1/documentary/dam/${id}`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDamIdQueryKey = (
  id: string,
  params?: GetDocumentaryDamIdParams
) => [`/api/v1/documentary/dam/${id}`, ...(params ? [params] : [])];

export type GetDocumentaryDamIdQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDamId>
>;
export type GetDocumentaryDamIdQueryError = ErrorType<void>;

export const useGetDocumentaryDamId = <
  TData = AsyncReturnType<typeof getDocumentaryDamId>,
  TError = ErrorType<void>
>(
  id: string,
  params?: GetDocumentaryDamIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDamId>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryDamIdQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDamId>
  > = () => getDocumentaryDamId(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDamId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary getSingleDamObservations
 */
export const getDocumentaryDamIdObservations = (
  id: string,
  params?: GetDocumentaryDamIdObservationsParams
) => {
  return apiInstance<DeskDamObservation[]>({
    url: `/api/v1/documentary/dam/${id}/observations`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDamIdObservationsQueryKey = (
  id: string,
  params?: GetDocumentaryDamIdObservationsParams
) => [
  `/api/v1/documentary/dam/${id}/observations`,
  ...(params ? [params] : [])
];

export type GetDocumentaryDamIdObservationsQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDamIdObservations>
>;
export type GetDocumentaryDamIdObservationsQueryError = ErrorType<void>;

export const useGetDocumentaryDamIdObservations = <
  TData = AsyncReturnType<typeof getDocumentaryDamIdObservations>,
  TError = ErrorType<void>
>(
  id: string,
  params?: GetDocumentaryDamIdObservationsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDamIdObservations>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDamIdObservationsQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDamIdObservations>
  > = () => getDocumentaryDamIdObservations(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDamIdObservations>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary getLastDamObservation
 */
export const getDocumentaryDamIdObservationsLast = (
  id: string,
  params?: GetDocumentaryDamIdObservationsLastParams
) => {
  return apiInstance<DeskDamObservation[]>({
    url: `/api/v1/documentary/dam/${id}/observations/last`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDamIdObservationsLastQueryKey = (
  id: string,
  params?: GetDocumentaryDamIdObservationsLastParams
) => [
  `/api/v1/documentary/dam/${id}/observations/last`,
  ...(params ? [params] : [])
];

export type GetDocumentaryDamIdObservationsLastQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDamIdObservationsLast>
>;
export type GetDocumentaryDamIdObservationsLastQueryError = ErrorType<void>;

export const useGetDocumentaryDamIdObservationsLast = <
  TData = AsyncReturnType<typeof getDocumentaryDamIdObservationsLast>,
  TError = ErrorType<void>
>(
  id: string,
  params?: GetDocumentaryDamIdObservationsLastParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDamIdObservationsLast>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDamIdObservationsLastQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDamIdObservationsLast>
  > = () => getDocumentaryDamIdObservationsLast(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDamIdObservationsLast>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary getReservoirCurve
 */
export const getDocumentaryDamIdReservoirCurve = (
  id: string,
  params?: GetDocumentaryDamIdReservoirCurveParams
) => {
  return apiInstance<DeskDamPoint[]>({
    url: `/api/v1/documentary/dam/${id}/reservoir/curve`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDamIdReservoirCurveQueryKey = (
  id: string,
  params?: GetDocumentaryDamIdReservoirCurveParams
) => [
  `/api/v1/documentary/dam/${id}/reservoir/curve`,
  ...(params ? [params] : [])
];

export type GetDocumentaryDamIdReservoirCurveQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDamIdReservoirCurve>
>;
export type GetDocumentaryDamIdReservoirCurveQueryError = ErrorType<void>;

export const useGetDocumentaryDamIdReservoirCurve = <
  TData = AsyncReturnType<typeof getDocumentaryDamIdReservoirCurve>,
  TError = ErrorType<void>
>(
  id: string,
  params?: GetDocumentaryDamIdReservoirCurveParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDamIdReservoirCurve>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDamIdReservoirCurveQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDamIdReservoirCurve>
  > = () => getDocumentaryDamIdReservoirCurve(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDamIdReservoirCurve>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

import { Button, Collapse, Form } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { ConfigurationResponse, Widget } from 'client/api/backend/schemas';
import { getGetWidgetsQueryKey } from 'client/api/backend/widgets/widgets';
import { WidgetRepository } from 'client/components/widget-repository/WidgetRepository';
import { queryClient } from 'client/core/network/queryClient';
import { FormButtonsRightContainer } from 'client/ui/form/button/FormButtonsRightContainer';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { HelpText } from 'client/ui/help-text/HelpText';
import { yup } from 'common/validation/initYup';
import * as React from 'react';
import styled from 'styled-components';
import { useWidgetMutations } from '../../logic/useWidgetMutations';
import { WidgetProfile, WidgetProfileSchema } from '../../schema/WidgetProfile';

export interface IWidgetConfigurationProfileFormProps {
  widget: Widget;
}

const WidgetSchema = yup.object({
  profile: WidgetProfileSchema
});

export function WidgetConfigurationProfileForm(
  props: IWidgetConfigurationProfileFormProps
) {
  const { widget } = props;
  const widgetMutations = useWidgetMutations();

  const widgetType = WidgetRepository.fromWidget(widget);
  const FilterComponent = widgetType?.filterComponent;

  return (
    <FormikAugmented<Widget>
      initialValues={widget}
      enableReinitialize
      onSubmit={async (values, formik) => {
        const result = await widgetMutations.update(values);

        // TODO: Per efficenza potremmo ricaricare il singolo widget e poi
        // aggiornarlo a mano nella Cache locale di react-query
        await queryClient.invalidateQueries(getGetWidgetsQueryKey());
      }}
      validationSchema={WidgetSchema}
      validationContext={{}}
    >
      <FormikForm editable layout="vertical">
        <Collapse ghost defaultActiveKey={['appearence', 'filters']}>
          <CollapsePanel header="Apparenza" key="appearence">
            <FormFieldsContainer>
              <FormFieldItem
                name="profile.title"
                component={TextInput}
                label={null}
                size="large"
                placeholder="Titolo del Widget"
              />
              <>
                <FormFieldItem
                  name="profile.showChildWidgetInFullScreen"
                  component={CheckboxInput}
                  label={null}
                >
                  Apri widget di dettaglio a schermo intero
                </FormFieldItem>
                <HelpText
                  style={{
                    paddingTop: '0px',
                    fontSize: '12px',
                    paddingBottom: '12px'
                  }}
                >
                  Se questa opzione è attiva, quando si apre un widget di
                  dettaglio a partire da questo widget, il widget aperto verrà
                  mostrato sempre a intero
                </HelpText>
              </>
            </FormFieldsContainer>

            <FormButtonsRightContainer>
              <FormikSendButton as={Button} title="Salva" size="large">
                Salva
              </FormikSendButton>
            </FormButtonsRightContainer>
          </CollapsePanel>

          {FilterComponent && (
            <CollapsePanel header="Filtri di default" key="filters">
              <FilterComponent
                filters={{}}
                prefix="profile.filters"
                widgetId={widget.id!}
              />

              <FormButtonsRightContainer>
                <FormikSendButton as={Button} title="Salva" size="large">
                  Salva
                </FormikSendButton>
              </FormButtonsRightContainer>
            </CollapsePanel>
          )}
        </Collapse>
      </FormikForm>
    </FormikAugmented>
  );
}

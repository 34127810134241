import React from 'react';
import { Formik } from 'formik';
import { Card, Button, Typography, Divider, message } from 'antd';
import { FormikForm } from 'client/ui/form/FormikForm';
import { useDispatch } from 'react-redux';
import { AuthActions } from '../AuthModule';
import { useApiMutation } from 'client/core/network/hooks/useApiMutation';
import { AuthApi } from '../AuthApi';
import { TextInputItem } from 'client/ui/form/input/TextInputItem';
import {
  ICreateAccessTokenDto,
  createAccessTokenValidator
} from 'common/dto/AuthDto';
import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Asserts } from 'yup';
import { Link } from 'react-router-dom';
import { WidgetCard } from 'client/components/schema/widget/card/WidgetCard';
import styled from 'styled-components';
import { usePostAuthenticationLogin } from 'client/api/backend/authentication/authentication';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { VerticalSpace } from 'client/ui/space/VerticalSpace';
const { Title, Text } = Typography;

export const BodyWrapper = styled.div`
  padding: 0 12px;
`;
export const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 2em;
  margin-top: 1em;
`;
export const ButtonsWrapper = styled.div`
  margin-bottom: 12px;
  margin-top: 1em;
`;

const IconColor = styled.span`
  color: ${props => props.theme.textColorSecondary};
  opacity: 0.5;
  margin-right: 6px;
`;

interface LoginFormProps {}

/**
 * Form di login all'applicativo, tramite le API di creazione AccessToken JWT.
 */
export function LoginForm(props: LoginFormProps) {
  const login = usePostAuthenticationLogin({});
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{}}
      // validate={schemaToValidator(createAccessTokenValidator)}
      onSubmit={async (values, helpers) => {
        try {
          const result = await login.mutateAsync({ data: values });
          dispatch(AuthActions.loginSucceed('jwt', 'first-auth'));
        } catch (e) {
          message.error(getNetworkErrorMessage(e, `Si è verificato un errore durante il login, si prega di controllare le credenziali e riprovare.`)); // prettier-ignore
        }
      }}
    >
      <LoaderSpin spinning={login.isLoading} transparent fullWidth>
        <WidgetCard>
          <BodyWrapper>
            <TitleWrapper>
              <Title level={3}>Login</Title>
              <Text type="secondary">Accedi alla Scrivania</Text>
            </TitleWrapper>
            <FormikForm layout="vertical">
              <TextInputItem
                // label="Username"
                name="username"
                size="large"
                autoComplete="username"
                prefix={<IconColor children={<UserOutlined />} />}
                placeholder="User"
              />
              <TextInputItem
                // label="Password"
                name="password"
                type="password"
                size="large"
                autoComplete="current-password"
                prefix={<IconColor children={<LockOutlined />} />}
                placeholder="Password"
              />
              <ButtonsWrapper>
                <Button htmlType="submit" type="primary" size="large" block>
                  Login
                </Button>
                <VerticalSpace />
                {/* <Divider />
                <Link to="/">Lorem ipsum du</Link> */}
              </ButtonsWrapper>
            </FormikForm>
          </BodyWrapper>
        </WidgetCard>
      </LoaderSpin>
    </Formik>
  );
}

import { DeskDocument, Widget } from 'client/api/backend/schemas';
import { FormikForm } from 'client/ui/form/FormikForm';
import React from 'react';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextInput } from 'client/ui/form/input/TextInput';
import { WidgetSteps } from 'client/components/toolkit/components/speps/WidgetSteps';
import { RegistrySelectInput } from 'client/components/widget-repository/objects/registries/RegistrySelectInput';
import { UploadDraggerBase64Input } from 'client/ui/form/input/UploadDraggerBase64Input';
import { useFormikContext } from 'formik';
import { FormErrorsNotification } from 'client/ui/form/errors/FormErrorsNotification';
import { FullBodyForm } from 'client/ui/full-body-form/FullBodyForm';
import { RcFile } from 'antd/lib/upload';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { WidgetStepPanel } from 'client/components/toolkit/components/speps/WidgetStepPanel';
import { DocumentProtocolRegistryModeInput } from 'client/components/widget-repository/objects/documents/DocumentProtocolRegistryModeInput';
import { ToolkitObjectInput } from 'client/components/toolkit/objects/input/ToolkitObjectInput';
import { DocumentToolkitObject } from 'client/components/widget-repository/objects/documents/DocumentToolkitObject';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { canDocumentBeSentToQueue } from 'client/components/widget-repository/objects/documents/logic/canDocumentBeSentToQueue';

export interface IWidgetDocumentSendToProtocolQueueFormProps {
  widget: Widget;
}

export function WidgetDocumentSendToProtocolQueueForm(
  props: IWidgetDocumentSendToProtocolQueueFormProps
) {
  const { widget } = props;
  const formik = useFormikContext();

  return (
    <FullBodyForm>
      <FormErrorsNotification />
      <FormikForm layout="vertical" editable helpInTooltips>
        <WidgetSteps
          cancelButtonProps={{ onClick: () => formik.resetForm() }}
          finishButtonProps={{
            children: 'Invia',
            htmlType: 'submit',
            disabled: !formik.dirty
          }}
        >
          <WidgetStepPanel title="Protocollo" stepKey="protocol">
            <FormFieldsContainer removeTopTitleSpacing>
              <FormFieldItem
                size="middle"
                component={RegistrySelectInput}
                name="registryId"
                widgetId={widget.id!}
                label="Registrazione"
                placeholder="Registrazione"
              />
              <FormFieldItem
                size="middle"
                component={DocumentProtocolRegistryModeInput}
                registryIdFieldName="registryId"
                widgetId={widget.id!}
                name="mode"
                label="Modalità"
                placeholder="Modalità"
              />
              <FormFieldItem
                size="middle"
                component={TextInput}
                name="subject"
                label="Oggetto"
                placeholder="Oggetto"
              />
              <FormFieldItem
                size="middle"
                component={TextAreaInput}
                name="note"
                label="Note"
                placeholder="Note"
              />
            </FormFieldsContainer>
          </WidgetStepPanel>
          <WidgetStepPanel title="Allegati" stepKey="attachments">
            <FormFieldsContainer removeTopTitleSpacing columns={1} gutter={8}>
              <FormFieldItem
                size="large"
                component={ToolkitObjectInput}
                objectType={DocumentToolkitObject}
                widgetId={widget.id!}
                name="document"
                allows={canDocumentBeSentToQueue}
                label="Documento Principale"
                placeholder="Documento Principale"
              />
              <FormFieldItem
                size="large"
                component={ToolkitObjectInput}
                objectType={DocumentToolkitObject}
                mode="multiple"
                widgetId={widget.id!}
                name="attachments"
                allows={canDocumentBeSentToQueue}
                label="Allegati"
                placeholder="Allegati"
              />
            </FormFieldsContainer>
          </WidgetStepPanel>
        </WidgetSteps>
      </FormikForm>
    </FullBodyForm>
  );
}

import { RightSideMenu } from 'client/routes/menu/right/RightSideMenu';
import React from 'react';
import { PageLayout } from './PageLayout';

export interface CorePageLayoutProps {
  children: React.ReactNode;
  hideHeader?: boolean;
}

/**
 * Layout principale dell'applicativo con main menu
 */
export function CorePageLayout(props: CorePageLayoutProps) {
  return <PageLayout {...props} rightMenu={<RightSideMenu />} />;
}

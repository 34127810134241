import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

const MiniBarWrapper = styled.div`
  width: 100%;
  height: ${props => props.theme.miniBarHeight};
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
`;
const BaseMiniBar = styled.div`
  height: ${props => props.theme.miniBarHeight};
  flex: 1 1 auto;
  min-width: 1px;
  transition: width 0.3s ease-in-out;
`;
const MiniBar = styled(BaseMiniBar)<{ color?: string; width?: number }>`
  ${props =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width}%;
    `}
`;

interface MonitorMiniBarProps {
  colors: string[];
  values?: number[];
  total?: number;
}

export function MonitorMiniBar(props: MonitorMiniBarProps) {
  if (!props.values || !props.total) {
    return null;
  }

  const { values, total, colors } = props;

  return (
    <MiniBarWrapper>
      {values?.map((value, index) => (
        <MiniBar
          key={index}
          color={colors[index]}
          width={(value / total) * 100}
        />
      ))}
    </MiniBarWrapper>
  );
}

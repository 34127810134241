import React from 'react';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import {
  SelectInput,
  SelectInputProps,
  SelectOption
} from 'client/ui/form/input/SelectInput';
import { useGetDocumentaryRegistries } from 'client/api/backend/documentary-registries/documentary-registries';

interface Props extends SelectInputProps<any> {
  widgetId: number;
}

export function RegistrySelectInput(props: Props) {
  const { widgetId, ...otherProps } = props;

  const roles = useGetDocumentaryRegistries({
    widgetId: props.widgetId
  });

  const options = roles.data?.map(r => ({ label: r.name, value: r.id! })) ?? [];

  return (
    <SelectInput allowClear showSearch options={options} {...otherProps} />
  );
}

import * as React from 'react';
import { Card, CardProps } from 'antd';
import clx from 'classnames';
import { FloatCard } from './base/FloatCard';
import { FloatWidgetWrapper } from './base/FloatWidgetWrapper';
import { FloatWidgetSize } from './base/FloatWidgetSize';

export interface FloatWidgetCardProps extends Omit<CardProps, 'bordered'> {
  ghost?: boolean;
  widgetExpanded?: boolean;
  fullScreen?: boolean;
}

/**
 * Card stilizzata dei Floating Widget
 */
export function FloatWidgetCard(props: FloatWidgetCardProps) {
  const { widgetExpanded, ...otherProps } = props;
  return (
    <FloatWidgetSize
      widgetExpanded={widgetExpanded}
      fullScreen={props.fullScreen}
    >
      <FloatWidgetWrapper>
        <FloatCard {...otherProps} expanded={widgetExpanded} />
      </FloatWidgetWrapper>
    </FloatWidgetSize>
  );
}

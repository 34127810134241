import * as React from 'react';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextInput } from 'client/ui/form/input/TextInput';

export interface IWidgetDamSearchFilterFormProps {
  widgetId: number;
  /** Di base è `profile.filters`. */
  prefix: string;
  filters: any;
}

export function WidgetDamsSearchFilterForm(
  props: IWidgetDamSearchFilterFormProps
) {
  const { filters, prefix } = props;

  return (
    <>
      <FormFieldsContainer>
        <FormFieldItem
          allowClear
          size="large"
          component={TextInput}
          label={'Status'}
          name={`${prefix}.status`}
          placeholder="Status"
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormFieldItem
          allowClear
          size="large"
          component={TextInput}
          label={'Bacino'}
          name={`${prefix}.lagoon`}
          placeholder="Bacino"
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormFieldItem
          allowClear
          size="large"
          component={TextInput}
          label={'Concessionario'}
          name={`${prefix}.dealer`}
          placeholder="Concessionario"
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormFieldItem
          allowClear
          size="large"
          component={TextInput}
          label={'Gestore'}
          name={`${prefix}.operator`}
          placeholder="Gestore"
        />
      </FormFieldsContainer>
    </>
  );
}

import { EditOutlined } from '@ant-design/icons';
import { DeskWidgetsLogic } from 'client/components/schema/desk/logic/DeskWidgetsLogic';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { DossierToolkitObject } from 'client/components/widget-repository/objects/dossiers/DossierToolkitObject';
import React from 'react';
import { WidgetDossierCreateType } from '../WidgetDossierCreate.type';
import { WidgetDossierEditProfile } from '../WidgetDossierEditProfile';

export const WidgetActionEditDossier = createToolkitAction({
  name: 'Modifica Fascicolo',
  code: 'edit-dossier',
  icon: <EditOutlined />,
  arguments: [
    {
      name: 'Fascicolo da Modificare',
      type: [DossierToolkitObject]
    }
  ],
  async execute(ctx: IToolkitActionContext, object: ToolkitObject) {
    const dossier = DossierToolkitObject.get(object)!;

    const data: WidgetDossierEditProfile = {
      dossierNumber: dossier.data.number,
      dossierId: dossier.id as number
    };

    await ctx.deskContext.cloneWidget(
      {
        type: 'floating',
        expanded: true,
        order: DeskWidgetsLogic.getFloatingNextOrder(
          ctx.deskContext.floatingWidgets
        )
      },
      WidgetDossierCreateType,
      ctx.sourceWidget!,
      { quickClose: true, data }
    );
  }
});

import { DocumentSearchMode } from 'client/components/widget-repository/objects/documents/DocumentSearchMode';
import { yup } from 'common/validation/initYup';

export const WidgetDocumentsSearchSchema = yup.object({
  searchMode: yup.string().label('Modalità di ricerca documenti'),
  registryId: yup
    .number()
    .when(['searchMode'], (searchMode, schema) => {
      return searchMode !== DocumentSearchMode.Registry
        ? schema.strip()
        : schema;
    })
    .label('Registro')
});

import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import clx from 'classnames';

const MessageContainer = styled.div`
  position: absolute;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  z-index: 101;
`;

export interface ActionMessageContainerProps {
  children?: React.ReactNode;
}

/**
 * Container principale in cui renderizzare i messaggi modali delle Actions
 * Ne definisce per la posizione nella pagina
 */
export function ActionMessageContainer(props: ActionMessageContainerProps) {
  return <MessageContainer>{props.children}</MessageContainer>;
}

import React, { useCallback, useState } from 'react';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import { useGetUsers } from 'client/api/backend/users/users';
import { UserResponseDTO } from 'client/api/backend/schemas';
import { debounce } from 'lodash';
import { Spin } from 'antd';

interface IUserSelectInputProps extends SelectInputProps<any> {}

export function UserSelectInput(props: IUserSelectInputProps) {
  const [search, setSearch] = useState(undefined as string | undefined);

  const result = useGetUsers(
    { page: 0, size: 50, username: search },
    { query: { keepPreviousData: true } }
  );

  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearch(value);
    }, 200),
    [setSearch]
  );

  return (
    <SelectInput
      allowClear
      showSearch
      options={result.data?.content?.map(u => ({
        label: getUserLabel(u),
        value: u.id!
      }))}
      onSearch={handleSearch}
      filterOption={false}
      loading={result.isLoading}
      notFoundContent={
        result.isLoading ? <Spin size="small" /> : 'Nessun elemento trovato.'
      }
      {...props}
    />
  );
}

export function getUserLabel(user: UserResponseDTO) {
  return `${user.username} - ${user.name ?? ''} ${user.surname ?? ''}`;
}

import { Form } from 'antd';
import { useGetDocumentaryDossiersProceedings } from 'client/api/backend/documentary-dossiers/documentary-dossiers';
import { DeskProceeding } from 'client/api/backend/schemas';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { useField } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';
import { ProceedingPhasesInput } from './ProceedingPhasesInput';

interface IProceedingInputProps extends Omit<SelectInputProps<any>, 'name'> {
  widgetId: number;
}

export function ProceedingInput(props: IProceedingInputProps) {
  const { widgetId, ...otherProps } = props;
  const proceedingsResult = useGetDocumentaryDossiersProceedings({
    widgetId: props.widgetId
  });

  const [field, meta, helper] = useField<Maybe<DeskProceeding>>('proceeding');

  useEffect(() => {
    if (proceedingsResult.data == null) return;

    const selectedProceeding = proceedingsResult.data?.find(
      p => p.id === field.value?.id
    );
    helper.setValue(selectedProceeding ?? null);
  }, [field.value?.id, proceedingsResult.data]);

  return (
    <FormFieldsContainer>
      <FormFieldItem
        size="middle"
        component={SelectInput}
        label={'Procedimento Amministrativo'}
        name="proceeding.id"
        placeholder="Procedimento Amministrativo"
        loading={proceedingsResult.isLoading}
        options={proceedingsResult.data?.map(proceeding => {
          return { value: proceeding.id ?? '', label: proceeding.name ?? '' };
        })}
      />
      <FormFieldItem
        size="middle"
        component={TextInput}
        name={'proceeding.uo.name'}
        label="Unità Organizzativa Responsabile"
        readOnly
        hideIfEmpty
        placeholder="Unità Organizzativa Responsabile"
      />
      <FormFieldItem
        size="middle"
        component={TextInput}
        name={'proceeding.subject'}
        label="Materia/Argomento"
        readOnly
        hideIfEmpty
        placeholder="Materia/Argomento"
      />
      <FormFieldItem
        size="middle"
        component={TextInput}
        name={'proceeding.manager.name'}
        label="Responsabile Unico Procedimento"
        readOnly
        hideIfEmpty
        displayValue={`${field.value?.manager?.name} ${field.value?.manager?.surname}`}
        placeholder="Responsabile Unico Procedimento"
      />
      <FormFieldItem
        size="middle"
        component={TextInput}
        name={'proceeding.structure.name'}
        label="Struttura"
        readOnly
        hideIfEmpty
        placeholder="Struttura"
      />
      <ProceedingPhasesInput name="proceedingFases" />
    </FormFieldsContainer>
  );
}

import { schemaToValidator } from 'client/core/validation/schemaToValidator';
import { yup } from 'common/validation/initYup';
import { Formik, FormikConfig, FormikValues } from 'formik';
import * as React from 'react';
import { AnyObject } from 'yup/lib/types';
import { FormikAugmentedContext } from './FormikAugmentedContext';

export interface IFormikAdvancedProps<
  Values extends FormikValues = FormikValues
> extends Omit<FormikConfig<Values>, 'validationSchema' | 'validate'> {
  /** Schema di validazione */
  validationSchema: yup.ObjectSchema<any>;
  /** Contesto di validazione di Yup */
  validationContext: AnyObject;
  /**
   * Aggiunge valori al contesto di validazione a partire
   * dal valore del form, "spostandolo" nel context, es. `documentMode`
   */
  valueToContext?: (value: Values) => AnyObject;
}

/**
 * Versione di `Formik` aumentata per gestire lo schema di Yup allineato
 * con i DTO e l'introspezione (vedi `useFormikValidation`)
 */
export function FormikAugmented<
  Values extends FormikValues = FormikValues,
  ExtraProps = {}
>(props: IFormikAdvancedProps<Values> & ExtraProps) {
  const { validationSchema, validationContext, valueToContext, ...otherProps } =
    props;

  return (
    <FormikAugmentedContext.Provider
      value={{
        context: validationContext,
        schema: validationSchema,
        valueToContext
      }}
    >
      <Formik<Values>
        {...otherProps}
        validate={schemaToValidator(validationSchema, {
          context: validationContext,
          valueToContext
        })}
      />
    </FormikAugmentedContext.Provider>
  );
}

import { DeskDocument, DeskDossier } from 'client/api/backend/schemas';
import { IDocumentMetadata } from 'client/components/toolkit/components/metadata/IDocumentMetadata';
import { formatClassificationDescriptions } from 'client/components/widget-repository/objects/classifications/formatClassificationDescription';
import { formatCorrespondentNames } from 'client/components/widget-repository/objects/correspondents/formatCorrespondentNames';
import { formatDossierNames } from 'client/components/widget-repository/objects/dossiers/formatDossierNames';
import { formatSignersNames } from 'client/components/widget-repository/objects/signers/formatSignersNames';
import moment from 'moment';

export function convertDocumentToMetadatas(
  document: DeskDocument,
  dossiers?: DeskDossier[]
): IDocumentMetadata {
  return {
    documentType: document.mode,
    data: {
      protocolNumber: document.protocolNumber,
      protocolDate: document.dateProtocol
        ? moment(document.dateProtocol).format('ll')
        : '',
      subject: document.subject,
      shelfmark: document.shelfmark,
      correspondents: formatCorrespondentNames(document.correspondents),
      classifications: formatClassificationDescriptions(
        document.classifications
      ),
      signers: formatSignersNames(document.signers),
      dossiers: formatDossierNames(dossiers)
    }
  };
}

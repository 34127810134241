import { EditOutlined } from '@ant-design/icons';
import { DeskAssignment, DeskDocument } from 'client/api/backend/schemas';
import { DeskWidgetsLogic } from 'client/components/schema/desk/logic/DeskWidgetsLogic';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { AssignmentToolkitObject } from 'client/components/widget-repository/objects/assignments/AssignmentToolkitObject';
import { isAssignmentActionAllowed } from 'client/components/widget-repository/objects/assignments/isAssignmentActionAllowed';
import { DocumentToolkitObject } from 'client/components/widget-repository/objects/documents/DocumentToolkitObject';
import { isDocumentAccessible } from 'client/components/widget-repository/objects/documents/logic/isDocumentAccessible';
import React from 'react';
import { WidgetDocumentProtocolType } from '../WidgetDocumentProtocol.type';
import { WidgetDocumentProtocolProfile } from '../WidgetDocumentProtocolProfile';

export const WidgetActionEditProtocol = createToolkitAction({
  name: 'Modifica Protocollo',
  code: 'edit-protocol',
  icon: <EditOutlined />,
  arguments: [
    {
      name: 'Protocollo da Modificare',
      type: [AssignmentToolkitObject, DocumentToolkitObject],
      allows: (document: ToolkitObject<DeskDocument | DeskAssignment>) =>
        isActionAllowed(document) &&
        isAssignmentActionAllowed(document) &&
        isDocumentAccessible(document)
    }
  ],
  async execute(ctx: IToolkitActionContext, object: ToolkitObject) {
    const document = DocumentToolkitObject.get(object)!;

    const data: WidgetDocumentProtocolProfile = {
      protocolNumber: document.data.protocolNumber,
      documentId: document.id as number,
      mode: 'edit'
    };

    await ctx.deskContext.cloneWidget(
      {
        type: 'floating',
        expanded: true,
        order: DeskWidgetsLogic.getFloatingNextOrder(
          ctx.deskContext.floatingWidgets
        )
      },
      WidgetDocumentProtocolType,
      ctx.sourceWidget!,
      { quickClose: true, data }
    );
  }
});

/**
 * È possibile modificare il protocollo solo se:
 * - Il documento è accessibile (flag `isAccessible` da BE)
 * - Il documento è protocollato (`protocolNumber` valorizzato)
 */
function isActionAllowed(obj: ToolkitObject<DeskDocument | DeskAssignment>) {
  const document = DocumentToolkitObject.get(obj);
  if (!document) return false;

  return document.data.protocolNumber != null;
}

import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { AuditOutlined } from '@ant-design/icons';
import { WidgetActionProtocolDocument } from './actions/WidgetActionProtocolDocument';
import { WidgetDocumentProtocol } from './WidgetDocumentProtocol';
import { WidgetActionEditProtocol } from './actions/WidgetActionEditProtocol';
import { WidgetDocumentProtocolProfile } from './WidgetDocumentProtocolProfile';

export const WidgetDocumentProtocolType: IWidgetType = {
  code: 'document-protocol',
  name: 'Protocollazione documenti',
  icon: <AuditOutlined />,
  component: WidgetDocumentProtocol,
  description: `Protocollazione dei documenti nel documentale`,
  actions: [WidgetActionProtocolDocument, WidgetActionEditProtocol],
  getTitle: widget => {
    const data = widget.profile?.data as
      | WidgetDocumentProtocolProfile
      | undefined;
    return data?.mode === 'edit'
      ? data?.protocolNumber
        ? `Modifica Protocollo n.${data?.protocolNumber}`
        : 'Modifica Protocollo'
      : 'Nuovo Protocollo';
  }
};

WidgetRepository.register(WidgetDocumentProtocolType);

import { FormOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { postDocumentaryDocumentsSign } from 'client/api/backend/documentary-documents/documentary-documents';
import { DeskDocument } from 'client/api/backend/schemas';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { DocumentToolkitObject } from 'client/components/widget-repository/objects/documents/DocumentToolkitObject';
import { DocumentSignatureTypeSelectInput } from 'client/components/widget-repository/objects/documents/sign/DocumentSignatureTypeSelectInput';
import { DocumentSignDomainSelectInput } from 'client/components/widget-repository/objects/documents/sign/DocumentSignDomainSelectInput';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { PasswordInput } from 'client/ui/form/input/PasswordInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { yup } from 'common/validation/initYup';
import React from 'react';
import { InferType } from 'yup';

export const WidgetActionSignDocument = createToolkitAction({
  name: 'Firma documento',
  code: 'sign-document',
  icon: <FormOutlined />,
  arguments: [
    {
      type: [DocumentToolkitObject],
      name: 'Documento da Firmare',
      allows: (document: ToolkitObject<DeskDocument>) =>
        document.data.protocolNumber == null
    }
  ],

  async execute(
    ctx: IToolkitActionContext,
    documentObject: ToolkitObject<DeskDocument>
  ) {
    const document = DocumentToolkitObject.get(documentObject)!;

    ctx.deskContext.actionMessages.prompt({
      ctx,
      action: WidgetActionSignDocument,
      args: [document],
      schema: SignSchema,
      confirmText: 'Firma',
      initialValues: {},
      onConfirm,
      form: (
        <>
          <FormFieldsContainer>
            <FormFieldItem
              size="large"
              component={TextInput}
              label="Username"
              name="username"
            />
            <FormFieldItem
              size="large"
              component={PasswordInput}
              label="Password"
              name="password"
            />
            <FormFieldItem
              size="large"
              component={TextInput}
              label="OTP"
              name="pin"
            />
            <FormFieldItem
              size="large"
              component={DocumentSignDomainSelectInput}
              widgetId={document.sourceWidgetId}
              label="Dominio"
              name="domain"
              placeholder="Dominio"
            />
            <FormFieldItem
              size="large"
              component={DocumentSignatureTypeSelectInput}
              label="Tipo di firma"
              name="isPades"
              placeholder="Tipo di firma"
            />
          </FormFieldsContainer>
        </>
      )
    });
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: SignSchemaType,
  document: ToolkitObject<DeskDocument>
) {
  try {
    await postDocumentaryDocumentsSign(
      {
        username: values.username,
        password: values.password,
        pin: values.pin,
        domain: values.domain,
        isPades: values.isPades,
        widgetId: document.sourceWidgetId
      },
      { ids: [document?.id as number], widgetId: document.sourceWidgetId }
    );

    message.success('Documento firmato con successo.');
  } catch (e) {
    message.error(
      getNetworkErrorMessage(e, 'Errore durante la firma del documento.')
    );
    console.error(e);
    return false;
  }
}

const SignSchema = yup.object({
  username: yup.string().required().label('Username'),
  password: yup.string().required().label('Password'),
  pin: yup.string().required().label('OTP'),
  domain: yup.string().notRequired().label('Domain'),
  isPades: yup.boolean().required().label('Tipo di firma')
});
type SignSchemaType = InferType<typeof SignSchema>;

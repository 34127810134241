import { Widget } from 'client/api/backend/schemas';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { xor } from 'lodash';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { Subject } from 'rxjs';
import { IWidgetListElement } from '../element/IWidgetListElement';
import { IWidgetListMassiveOptions } from './IWidgetListMassiveOptions';

export type IWidgetListMassiveContext = ReturnType<
  typeof useWidgetListMassiveContextHandler
>;

export const WidgetListMassiveContext =
  React.createContext<IWidgetListMassiveContext>({} as any);

interface IWidgetListMassiveContextProviderProps {
  options?: IWidgetListMassiveOptions;
  list: IWidgetListElement[] | undefined;
  children: React.ReactNode;
}

/**
 * Gestisce lo stato di selezione massive del widget lista. Permette di avere
 * informazioni sui Widget selezionati.
 */
export function useWidgetListMassiveContextHandler(
  options?: IWidgetListMassiveOptions,
  list: IWidgetListElement[] = []
) {
  const [selected, setSelected] = useState<ToolkitObject[]>([]);

  const toggle = useCallback(
    (object: ToolkitObject) => {
      if (options?.enabled) {
        setSelected(s => xor(s, [object]));
      }
    },
    [options?.enabled]
  );

  const deselectAll = useCallback(() => {
    setSelected([]);
  }, []);

  // Quando la lista cambia, aggiorna la selezione mantenendo solo gli elementi
  // che sono ancora presenti nella lista. In questo modo se, ad esempio,
  // un elemento viene eliminato, viene automaticamente deselezionato.
  useEffect(() => {
    if (!options?.enabled) return;

    const listIds = new Set(list.map(element => element.object?.id));

    setSelected(s =>
      s.filter(selectedItem => {
        return listIds.has(selectedItem.id);
      })
    );
  }, [list]);

  return {
    selected,
    enabled: options?.enabled ?? false,
    toggle,
    deselectAll
  };
}

/**
 * Esponse le informazioni della selezione massiva a tutto il tree sottostante.
 */
export function WidgetListMassiveContextProvider(
  props: IWidgetListMassiveContextProviderProps
) {
  const contextState = useWidgetListMassiveContextHandler(
    props.options,
    props.list
  );

  return (
    <WidgetListMassiveContext.Provider value={contextState}>
      {props.children}
    </WidgetListMassiveContext.Provider>
  );
}

export function useWidgetListMassiveContext(): IWidgetListMassiveContext {
  return useContext(WidgetListMassiveContext);
}

import * as React from 'react';
import SyncOutlined from '@ant-design/icons/lib/icons/SyncOutlined';
import { ButtonIcon, ButtonIconProps } from 'client/ui/button/ButtonIcon';
import { useWidgetContext } from '../../context/WidgetContext';

export interface SyncButtonProps extends Omit<ButtonIconProps, 'icon'> {}

/**
 * Pulsante sincronizzare manualmente il Widget al backend
 */
export function SyncButton(props: SyncButtonProps) {
  const { refetch, isFetching, queryCount } = useWidgetContext();

  // Se non ci sono query, inutile mostrare il pulsante.
  if (queryCount === 0) return null;

  return (
    <ButtonIcon
      icon={<SyncOutlined spin={isFetching} disabled={isFetching} />}
      {...props}
      onClick={refetch}
    />
  );
}

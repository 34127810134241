import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetActionSignDocument } from './actions/WidgetActionSignDocument';
import { FormOutlined } from '@ant-design/icons';
import { WidgetDocumentSign } from './WidgetDocumentSign';

export const WidgetDocumentSignType: IWidgetType = {
  code: 'document-sign',
  name: 'Firma Documento',
  isHidden: true,
  icon: <FormOutlined />,
  component: WidgetDocumentSign,
  description: `Firma un documento`,
  actions: [WidgetActionSignDocument]
};

WidgetRepository.register(WidgetDocumentSignType);

import { ColumnType } from 'antd/lib/table';
import {
  getDocumentaryDamId,
  getGetDocumentaryDamIdQueryKey,
  useGetDocumentaryDamObservations
} from 'client/api/backend/documentary-dam/documentary-dam';
import { ToolkitObjectActionsMenu } from 'client/components/schema/widget/actions/interaction/ToolkitObjectActionsMenu';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { useWidgetNetworkQueries } from 'client/components/schema/widget/context/useWidgetNetworkEffect';
import { useWidgetContext } from 'client/components/schema/widget/context/WidgetContext';
import { WidgetTable } from 'client/components/toolkit/components/table/WidgetTable';
import { useRegisterToolkitObject } from 'client/components/toolkit/objects/store/useToolkitObjectStore';
import { ToolkitObjectBottomZone } from 'client/components/toolkit/objects/ToolkitObjectBottomZone';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { AlignType } from 'rc-table/lib/interface';
import React from 'react';
import { useQueries } from 'react-query';
import { getParamColumnLabel } from '../../objects/dams/DamParams';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import {
  convertMultipleObservationsToTableData,
  IMultipleDamObservationTableData
} from './convert/convertMultipleObservationsToTableData';
import { WidgetMultipleDamsMonitoringProfile } from './WidgetMultipleDamsMonitoringProfile';

export interface IWidgetDamMonitoringProps
  extends IWidgetComponentToolkitProps {}

function monitoringColumns(
  availableParameters: string[]
): ColumnType<IMultipleDamObservationTableData>[] {
  // Nel caso in cui non ci siano parametri disponibili, mostriamo un solo campo
  // con scritto "Parametri" con valore "N.D."
  const observationParameters =
    availableParameters.length > 0 ? availableParameters : ['Parametri'];

  return [
    {
      title: 'Nome',
      dataIndex: 'damName',
      key: 'damName',
      render: (name: string, record: IMultipleDamObservationTableData) => (
        <b>{name}</b>
      )
    },
    ...observationParameters.map((param, index) => ({
      title: getParamColumnLabel(param),
      dataIndex: `${param}${index}`,
      align: 'right' as AlignType,
      key: param,
      render: (value: string, record: IMultipleDamObservationTableData) => {
        return record.parameters[param] ?? 'N.D.';
      }
    })),
    {
      dataIndex: 'actions',
      key: 'actions',
      render: (value: string, record: IMultipleDamObservationTableData) => {
        return <ToolkitObjectActionsMenu object={record.object} />;
      }
    }
  ];
}

export function WidgetDamMonitoring(props: IWidgetDamMonitoringProps) {
  const { widget } = props;
  const widgetContext = useWidgetContext();

  const data = widget.profile?.data as
    | WidgetMultipleDamsMonitoringProfile
    | undefined;

  const monitoringResult = useGetDocumentaryDamObservations(
    {
      damIds: data?.damIds!,
      widgetId: widget.id!
    },
    {
      query: {
        enabled: data?.damIds != null && data?.damIds.length > 0,
        // 10 minuti in millisecondi
        refetchInterval: 10 * 60 * 1000
      }
    }
  );

  const queryBodies = data?.damIds?.map(d => {
    return {
      queryKey: getGetDocumentaryDamIdQueryKey(d, { widgetId: widget.id! }),
      queryFn: () => getDocumentaryDamId(d, { widgetId: widget.id! }),
      enabled: data?.damIds != null && data?.damIds.length > 0
    };
  });

  const damsResult = useQueries(queryBodies ?? []);

  const convertedObservations = convertMultipleObservationsToTableData(
    damsResult.map(d => d.data!),
    monitoringResult.data ?? [],
    widget
  );

  const availableParamsSet = new Set(
    convertedObservations.flatMap(o => Object.keys(o.parameters))
  );
  const availableParams = Array.from(availableParamsSet);

  useWidgetNetworkQueries([monitoringResult, ...damsResult]);

  // Creiamo l'oggetto per il toolkit
  const widgetObject = WidgetToolkitObject.from(widget);
  useRegisterToolkitObject(widgetObject);

  return (
    <WidgetCardBody bottom={<ToolkitObjectBottomZone object={widgetObject} />}>
      <WidgetTable
        dataSource={convertedObservations}
        columns={monitoringColumns(availableParams)}
        showHeader={true}
        pagination={false}
        loading={
          monitoringResult.isLoading || damsResult.some(d => d.isLoading)
        }
      />
    </WidgetCardBody>
  );
}

import { message } from 'antd';
import { DeskDam } from 'client/api/backend/schemas';
import { IDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { DeskWidgetsLogic } from 'client/components/schema/desk/logic/DeskWidgetsLogic';
import {
  createToolkitAction,
  ToolkitAction
} from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import {
  getToolkitObjectOfType,
  ToolkitObject
} from 'client/components/toolkit/objects/ToolkitObject';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { DamToolkitObject } from 'client/components/widget-repository/objects/dams/DamToolkitObject';
import { set } from 'client/core/data/set';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { FvIconDamSync } from 'client/ui/icon/FvIconsDam';
import React from 'react';
import { WidgetMultipleDamsMonitoring } from '../WidgetMultipleDamsMonitoring.type';
import { WidgetMultipleDamsMonitoringProfile } from '../WidgetMultipleDamsMonitoringProfile';

export const WidgetActionMonitorMultipleDams = createToolkitAction({
  name: 'Monitoraggio Multiplo Dighe',
  code: 'monitor-multiplo-dam',
  icon: <FvIconDamSync />,
  arguments: [
    {
      type: [DamToolkitObject],
      name: 'Dighe da Monitorare',
      isArray: true
    },
    {
      type: [WidgetToolkitObject],
      allows: (object: ToolkitObject) =>
        WidgetToolkitObject.matches(object, WidgetMultipleDamsMonitoring),
      name: 'Widget Dighe da Monitorare'
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    object: ToolkitObject,
    targetObject: ToolkitObject | null
  ) {
    const dams = DamToolkitObject.getMany(object)!;
    const target = WidgetToolkitObject.get(targetObject);

    const data: WidgetMultipleDamsMonitoringProfile = {
      damIds: dams.map(d => d.data.id!)
    };

    if (target) {
      addDamsToExistingWidget(ctx, dams, target);
      return;
    }

    await ctx.deskContext.cloneWidget(
      {
        type: 'floating',
        expanded: true,
        order: DeskWidgetsLogic.getFloatingNextOrder(
          ctx.deskContext.floatingWidgets
        )
      },
      WidgetMultipleDamsMonitoring,
      ctx.sourceWidget!,
      { data, quickClose: true }
    );
  }
});

async function addDamsToExistingWidget(
  ctx: IToolkitActionContext,
  dams: ToolkitObject<DeskDam>[],
  target: ToolkitObject
) {
  try {
    const widget = ctx.deskContext.widgets.find(w => w.id === target.id)!;
    const data = widget.profile?.data as WidgetMultipleDamsMonitoringProfile;
    const addedDamIds = dams.map(d => d.data.id!);

    const overlappingIds = addedDamIds.filter(value =>
      data?.damIds.includes(value)
    );

    if (overlappingIds.length > 0) {
      message.info(
        `Le seguenti dighe sono già presenti nel monitoraggio: ${overlappingIds.join(
          ', '
        )}`
      );
    }

    // Aggiungiamo solo le dighe che non sono già presenti nel monitoraggio
    const uniqueDamIds = Array.from(
      new Set([...(data?.damIds ?? []), ...addedDamIds])
    );

    await ctx.deskContext.updateWidget(
      set(widget!, 'profile.data.damIds', uniqueDamIds)
    );
  } catch (e) {
    message.error(
      getNetworkErrorMessage(
        e,
        'Impossibile aggiungere le dighe al monitoraggio'
      )
    );
    console.error(e);
    return;
  }
}

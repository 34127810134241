import { Tag } from 'antd';
import * as React from 'react';

export interface IWidgetFilterActiveTagsProps {
  search: object;
  filters?: object | undefined;
  /** Rende il tag "chiudibile" per eliminare i filtri */
  onReset?: () => void;
}

export function renderWidgetFilterActiveTags(
  options: IWidgetFilterActiveTagsProps
) {
  const { search, filters, onReset } = options;

  const searchCount = Object.values(search).filter(
    i => i != null && i != ''
  ).length;

  const filtersCount = Object.values(filters || {}).filter(
    i => i != null && i != ''
  ).length;

  return [
    searchCount > 0 && (
      <Tag
        key="search"
        closable={onReset != null}
        onClose={() => onReset?.()}
        color="blue"
      >
        {searchCount} Filtri applicati
      </Tag>
    ),
    filtersCount > 0 && (
      <Tag key="filters" closable={false} color="default">
        {filtersCount} Filtri di default applicati
      </Tag>
    )
  ].filter(i => !!i);
}

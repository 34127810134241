import React from 'react';
import { Input } from 'antd';
import { InputProps } from 'antd/lib/input';
import { FormikValues, useField } from 'formik';
import { useFormikFormContext } from '../FormikFormContext';

export interface TextInputProps<T extends FormikValues>
  extends Omit<InputProps, 'name'> {
  name: keyof T & string;
}

/**
 * Campo per input di tipo password.
 */
export function PasswordInput<T extends FormikValues>(
  props: TextInputProps<T>
) {
  const { name } = props;
  const [field, meta] = useField(name);
  const { disabled } = useFormikFormContext();

  return (
    <Input.Password
      {...field}
      {...props}
      disabled={props.disabled || disabled}
    />
  );
}

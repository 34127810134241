import * as React from 'react';
import { useAuth, AuthActions } from '../AuthModule';
import { useDispatch } from 'react-redux';
import { UserLogic } from 'common/schema/user/UserLogic';
import { LogoutOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { DeskActions } from 'client/components/schema/desk/DeskModule';
import { AuthMethod } from '../AuthMethods';
import { authSubject } from '../SsoContextProvider';

export interface ILogoutButtonProps {}

/**
 * Gestisce il logout dell'utente corrente
 */
export function LogoutButton(props: ILogoutButtonProps) {
  const dispatch = useDispatch();

  // Rimuoviamo il pulsante di logout se siamo in modalità SSO
  if (process.env.SSO_METHOD === AuthMethod.Sso) {
    return null;
  }

  // if (!auth.logged) return null;
  // if (!UserLogic.canLogout(user)) return null;

  const handleLogout = async () => {
    authSubject.next({ type: 'logout' });

    // Dobbiamo effettuare il reload della pagina impostando `isLogged` a false.
    // await logout.refetch({});
    dispatch(AuthActions.logout(false));
    dispatch(DeskActions.reset());
  };

  return (
    <Menu.Item {...props} key="logout" icon={<LogoutOutlined />}>
      <a onClick={handleLogout}>Logout</a>
    </Menu.Item>
  );
}

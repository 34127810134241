import { TagsOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { postDocumentaryDocumentsClassificationsMultiple } from 'client/api/backend/documentary-documents/documentary-documents';
import { DeskAssignment } from 'client/api/backend/schemas';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { AssignmentToolkitObject } from 'client/components/widget-repository/objects/assignments/AssignmentToolkitObject';
import { isAssignmentActionAllowed } from 'client/components/widget-repository/objects/assignments/isAssignmentActionAllowed';
import { isAssignmentStale } from 'client/components/widget-repository/objects/assignments/isAssignmentStale';
import { AssignmentType } from 'client/components/widget-repository/objects/assignments/mapIdType';
import { ClassificationNodeToolkitObject } from 'client/components/widget-repository/objects/classifications/ClassificationNodeToolkitObject';
import { ClassificationParamsType } from 'client/components/widget-repository/objects/classifications/ClassificationParamsType';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { yup } from 'common/validation/initYup';
import React from 'react';
import { InferType } from 'yup';

export const WidgetActionClassifyDocument = createToolkitAction({
  name: 'Classifica Documento',
  code: 'classify-document',
  icon: <TagsOutlined />,
  arguments: [
    {
      type: [AssignmentToolkitObject],
      name: 'Documento da Classificare',
      isArray: true,
      allows: (obj: ToolkitObject<DeskAssignment>) =>
        obj.data.idType?.toString() === AssignmentType.Competenza &&
        !isAssignmentStale(obj.data) &&
        isAssignmentActionAllowed(obj)
    },
    {
      type: [ClassificationNodeToolkitObject],
      name: 'Classificazione',
      isArray: true
    }
  ],

  async execute(
    ctx: IToolkitActionContext,
    assignmentsObject: ToolkitObject<DeskAssignment>[] | ToolkitObject | null,
    nodesObject: ToolkitObject[] | ToolkitObject | null
  ) {
    const assignment = AssignmentToolkitObject.getMany(assignmentsObject)!;
    const nodes = ClassificationNodeToolkitObject.getMany(nodesObject);

    ctx.deskContext.actionMessages.prompt({
      ctx,
      action: WidgetActionClassifyDocument,
      args: [assignment, nodes],
      argsProps: [
        undefined,
        { fetchObjectParams: { type: ClassificationParamsType.Document } }
      ],
      schema: ClassifySchema,
      confirmText: 'Classifica',
      onConfirm
    });
  }
});

const ClassifySchema = yup.object({});
type ClassifySchemaType = InferType<typeof ClassifySchema>;

async function onConfirm(
  ctx: IToolkitActionContext,
  values: ClassifySchemaType,
  assignmentObjects: ToolkitObject[] | ToolkitObject,
  nodesObject: ToolkitObject[] | ToolkitObject
) {
  const assignments = AssignmentToolkitObject.getMany(assignmentObjects)!;
  const nodes = ClassificationNodeToolkitObject.getMany(nodesObject); // Non sarebbe necessario, lo teniamo per i typings

  try {
    await postDocumentaryDocumentsClassificationsMultiple(
      {
        classificationIds: nodes.map(node => node.data.id!),
        areProtocols: false,
        objectIds: assignments.map(assignment => assignment.data.id!)
        // documentId: assignment.data.document?.id as number,
        // type: assignment.data.idType,
        // assignmentId: assignment.id as number,
        // widgetId: assignment.sourceWidgetId
      },
      {
        widgetId: assignments[0].sourceWidgetId
      }
    );
    message.success(
      assignments.length > 1
        ? 'Documenti classificati con successo'
        : 'Documento classificato con successo.'
    );
  } catch (e) {
    message.error(
      getNetworkErrorMessage(e, 'Errore durante la classifica del documento.')
    );
    console.error(e);
    return false;
  }
}

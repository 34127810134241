import React, { useCallback, useEffect, useState } from 'react';
import { Button, message, Typography } from 'antd';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { WidgetCard } from 'client/components/schema/widget/card/WidgetCard';
import { BodyWrapper, ButtonsWrapper, TitleWrapper } from '../login/LoginForm';
import {
  usePostAuthenticationAzureLogin,
  usePostAuthenticationIamLogin
} from 'client/api/backend/authentication/authentication';
import { useDispatch } from 'react-redux';
import { AuthActions } from '../AuthModule';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { useAuth } from 'react-oidc-context';
const { Title, Text } = Typography;

/**
 * Form di Login OpenID Connect
 */
export function OpenIDConnectLoginForm() {
  const {
    isAuthenticated,
    isLoading: isAuthLoading,
    signinRedirect,
    activeNavigator,
    user
  } = useAuth();
  const oidcLogin = usePostAuthenticationIamLogin({});
  const dispatch = useDispatch();

  /**
   * Gestisce il login con la redirect alla pagina dello IAM che implementa
   * OpenID Connect
   */
  const handleLogin = useCallback(async (loginType: string) => {
    if (loginType === 'redirect') {
      try {
        await signinRedirect();
      } catch (e) {
        console.error(`[Errore OIDC]`, e);
      }
    }
  }, []);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function handleOidcResponse() {
      if (isAuthenticated) {
        setLoading(true);

        try {
          console.log('[oidc] auth recognized', user);

          // Invio il token OIDC / OAuth2.0 al Backend per emettere il JWT con il token
          // di accesso alla scrivania
          const result = await oidcLogin.mutateAsync({
            data: {
              accessToken: user?.id_token
            }
          });
          dispatch(AuthActions.loginSucceed('jwt', 'first-auth-oidc'));
        } catch (e) {
          console.error(`[oidc] fetch token error`, e);
          message.error(getNetworkErrorMessage(e, `Si è verificato un errore durante il login, si prega di riprovare.`)); // prettier-ignore
        } finally {
          setLoading(false);
        }
      }
    }

    handleOidcResponse().catch(e => {
      console.error(`[Errore OIDC]`, e);
    });
  }, [isAuthenticated, user]);

  return (
    <LoaderSpin spinning={loading || isAuthLoading} transparent fullWidth>
      <WidgetCard>
        <BodyWrapper>
          <TitleWrapper>
            <Title level={3}>Login</Title>
            <Text type="secondary">Accedi alla Scrivania</Text>
          </TitleWrapper>
          <ButtonsWrapper>
            <Button
              type="primary"
              size="large"
              block
              onClick={() => handleLogin('redirect')}
            >
              Accedi con IAM
            </Button>
          </ButtonsWrapper>
        </BodyWrapper>
      </WidgetCard>
    </LoaderSpin>
  );
}

import { DeleteOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { set } from 'client/core/data/set';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { WidgetMultipleDamsMonitoringProfile } from '../WidgetMultipleDamsMonitoringProfile';
import { DamToolkitObject } from 'client/components/widget-repository/objects/dams/DamToolkitObject';

export const WidgetActionRemoveDamFromMonitoring = createToolkitAction({
  name: 'Rimuovi diga dal monitoraggio',
  code: 'remove-dam-from-monitoring',
  icon: <DeleteOutlined />,
  arguments: [
    {
      type: [DamToolkitObject],
      name: 'Diga monitorata'
    }
  ],
  async execute(ctx: IToolkitActionContext, monitoredDamObject: ToolkitObject) {
    const monitoredDam = DamToolkitObject.get(monitoredDamObject)!;

    onConfirm(ctx, {}, monitoredDam);
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: any,
  monitoredDam: ToolkitObject
) {
  try {
    const widget = ctx.deskContext.widgets.find(
      w => w.id === monitoredDam.sourceWidgetId
    );
    const profile = widget?.profile;
    const data = profile?.data as WidgetMultipleDamsMonitoringProfile | null;

    const removedDamId = monitoredDam.id;

    const updatedData = data?.damIds.filter(d => d !== removedDamId);

    await ctx.deskContext.updateWidget(
      set(widget!, 'profile.data.damIds', [...(updatedData ?? [])])
    );

    message.success('Diga rimossa con successo dal monitoraggio');
  } catch (e) {
    message.error(
      getNetworkErrorMessage(
        e,
        'Impossibile rimuovere la diga dal monitoraggio'
      )
    );
    console.error(e);
    return false;
  }
}

import { FileSearchOutlined } from '@ant-design/icons';
import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetActivitiesSearch } from './WidgetActivitiesSearch';

export const WidgetActivitySearchType: IWidgetType = {
  code: 'activity-search',
  name: 'Ricerca Atti',
  icon: <FileSearchOutlined />,
  component: WidgetActivitiesSearch,
  description: `Ricerca gli atti all'interno del documentale`
};

WidgetRepository.register(WidgetActivitySearchType);

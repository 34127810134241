import { DeskProceeding } from 'client/api/backend/schemas';

export interface ProceedingMetadata {
  label: string;
  value: string | undefined;
}

/**
 * Funzione che converte un DeskProceeding in un array di metadati
 */
export function convertProceeding(
  proceeding: DeskProceeding | undefined
): ProceedingMetadata[] | undefined {
  if (!proceeding) return undefined;

  const proceedingSubject = {
    label: 'Materia/Argomento',
    value: proceeding.subject
  };

  const proceedingUo = {
    label: 'Unità Organizzativa Responsabile',
    value: proceeding.uo?.name
  };

  const proceedingStructure = {
    label: 'Struttura',
    value: proceeding.structure?.name
  };

  const proceedingManager = {
    label: 'Responsabile Unico Procedimento',
    value: proceeding.manager?.name
  };

  const proceedingName = {
    label: 'Procedimento Amministrativo',
    value: proceeding.name
  };

  return [
    proceedingName,
    proceedingSubject,
    proceedingUo,
    proceedingStructure,
    proceedingManager
  ];
}

import { Empty } from 'antd';
import { ChartDataset } from 'chart.js';
import {
  DeskDam,
  DeskDamObservation,
  DeskKeyValue
} from 'client/api/backend/schemas';
import { MonitorTop } from 'client/components/toolkit/components/monitor/container/MonitorTop';
import { MonitorWrapper } from 'client/components/toolkit/components/monitor/container/MonitorWrapper';
import { MonitorLineChart } from 'client/components/toolkit/components/monitor/detail/MonitorLineChart';
import { values } from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import useDimensions from 'react-cool-dimensions';
import { convertDamMonitoringValue } from '../../objects/dams/convertDamMonitoringValue';
import { IGraphViewParameter } from '../../objects/dams/DamParams';
import { convertConstantDamParametersToObservations } from './convert/convertConstantDamParametersToObservations';
import { convertObservationsToDataset } from './convert/convertObservationsToDataset';
import { getScalesForDatasets } from './convert/getScalesForDatasets';

interface IWidgetDamMonitoringLineChartProps {
  selectedParameters: IGraphViewParameter[];
  rawData?: DeskDamObservation[];
  dam?: DeskDam;
}

export function WidgetDamMonitoringLineChart(
  props: IWidgetDamMonitoringLineChartProps
) {
  const { selectedParameters, dam, rawData } = props;

  if (!rawData || rawData.length === 0) {
    return (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Nessun dato'} />
    );
  }

  const { observe, unobserve, width, height, entry } = useDimensions({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      unobserve(); // To stop observing the current target element
      observe(); // To re-start observing the current target element
    }
  });

  const datasets = useMemo(() => {
    const observationData = selectedParameters?.map(param => {
      const data = rawData
        ?.filter(observation => observation.parameter === param.codeApi)
        .map(d => convertDamMonitoringValue(d.value));

      return convertObservationsToDataset(param, selectedParameters, data);
    });

    let constantDamData: ChartDataset<'line', string[]>[] = [];

    // Nel caso ci siano parametri costanti associati al parametro che visualizzaimo,
    // li aggiungiamo come dataset
    if (selectedParameters.some(p => p.hasConstantParameters)) {
      constantDamData = convertConstantDamParametersToObservations(
        rawData.length,
        dam
      ).map(d =>
        convertObservationsToDataset(d.param, selectedParameters, d.data)
      );
    }

    return [...observationData, ...constantDamData];
  }, [selectedParameters, rawData, dam]);

  const scales = useMemo(() => {
    return getScalesForDatasets(selectedParameters);
  }, [selectedParameters]);

  // Set di date disponibili
  const availableDatesSet = new Set(rawData?.map(data => data.date ?? 0) ?? []);
  const availableDates = Array.from(availableDatesSet);

  const dateLabels = availableDates.map(date =>
    moment(date).format('DD/MM/YYYY')
  );

  return (
    <MonitorWrapper>
      <MonitorTop ref={observe}>
        <MonitorLineChart
          labels={dateLabels}
          datasets={datasets}
          scales={scales}
          width={width}
          height={height}
        />
      </MonitorTop>
    </MonitorWrapper>
  );
}

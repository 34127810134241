import * as React from 'react';
import styled from 'styled-components';

export const FloatWidgetWrapper = styled.div`
  z-index: 9;
  pointer-events: none;
  position: fixed;
  bottom: 0;
  right: 0;

  /* pointer-events: none; */
  display: flex;
  align-items: flex-end;
`;
export interface FloatWidgetContainerProps {
  children?: React.ReactNode;
}

/**
 * Container per i floating widget
 *
 */
export function FloatWidgetContainer(props: FloatWidgetContainerProps) {
  return <FloatWidgetWrapper>{props.children}</FloatWidgetWrapper>;
}

export const ChartPalettes = {
  linearAlternate: [
    '#0080ff',
    '#92acff',
    '#478bff',
    '#a4b8ff',
    '#6596ff',
    '#b6c4ff',
    '#7da1ff',
    '#c6d0ff'
  ],
  blue: ['#6596ff', '#0080ff', '#0a4fb7', '#342f85'],
  divergent: ['#0080ff', '#00b9f6', '#47ae90', '#307510'],
  category: [
    '#0080ff',
    '#ef9b20',
    '#87bc45',
    '#b33dc6',
    '#27aeef',
    '#ea5545',
    '#47ae90'
  ]
};

import { Widget } from 'client/api/backend/schemas';
import { IWidgetType } from './schema/WidgetType';

/**
 * Elenco dei Widget presenti e registrati nel sistema scrivania.
 */
export class WidgetRepository {
  public static types: { [code: string]: IWidgetType } = {};

  static register(widgetType: IWidgetType) {
    if (this.types.hasOwnProperty(widgetType.code)) {
      throw new Error(
        `Impossibile registrare un Tipo di Widget con codice: "${widgetType.code}" poiché è già registrato`
      );
    }

    this.types[widgetType.code] = widgetType;
  }

  static find(code: string | null | undefined) {
    if (code == null) return null;

    if (!this.types.hasOwnProperty(code)) {
      console.error(
        `Impossibile ottenere il Tipo di Widget con codice: "${code}" poiché non è registrato`
      );
    }

    return this.types[code] as IWidgetType | null;
  }

  static fromWidget(widget: Widget | undefined) {
    if (!widget?.profile?.typeCode) return null;
    return this.find(widget.profile.typeCode);
  }

  static listAll() {
    return Object.values(this.types);
  }

  /**
   * Insieme di tutte le azioni disponibili
   */
  static getAllActions() {
    return this.listAll()
      .map(w => w.actions ?? [])
      .flat();
  }
}

/**
 * Carichiamo in automatico tutti i Widget presenti nel sistema alla cartella
 * `types`, seguendo la nomenclatura `<WidgetName>.type.tsx`.
 */
function importAll(r: any) {
  r.keys().forEach(r);
}

importAll((require as any).context('./types/', true, /\.type\.tsx$/));

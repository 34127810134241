import { yup } from 'common/validation/initYup';
import { appConfig } from '../appConfig';

export enum FeatureFlag {
  MassiveActions = 'massive_actions'
}

const FeatureFlagSchema = yup.array(yup.string().oneOfEnum(FeatureFlag));

export function loadFeatureFlags(raw: any) {
  try {
    const flags = FeatureFlagSchema.validateSync(raw);
    return flags;
  } catch (e) {
    console.error(`⛔️ Attenzione! I feature flags non sono validi`, e);
    return raw;
  }
}

const featureFlags = loadFeatureFlags(appConfig.rawFeatureFlags || []);

export function hasFeatureFlag(flag: FeatureFlag) {
  return featureFlags?.includes(flag);
}

import { GetDocumentaryDossiersSearchParams } from 'client/api/backend/schemas';
import * as React from 'react';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { DossierStatesSelectInput } from './DossierStatesSelectInput';
import { DossierTypeInput } from 'client/components/widget-repository/objects/dossiers/DossierTypeInput';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { DynamicDateRanges } from 'client/components/toolkit/components/filter/dynamic/DynamicDateRanges';
import { ToolkitObjectInput } from 'client/components/toolkit/objects/input/ToolkitObjectInput';
import { ClassificationNodeToolkitObject } from 'client/components/widget-repository/objects/classifications/ClassificationNodeToolkitObject';
import { DossierSubtypeInput } from 'client/components/widget-repository/objects/dossiers/DossierSubtypeInput';
import { ClassificationParamsType } from 'client/components/widget-repository/objects/classifications/ClassificationParamsType';

export interface IWidgetDossierSearchFilterFormProps {
  widgetId: number;
  /** Di base è `profile.filters`. */
  prefix: string;
  filters: any;
}

export function WidgetDossiersSearchFilterForm(
  props: IWidgetDossierSearchFilterFormProps
) {
  const { filters, prefix } = props;

  return (
    <>
      <FormFieldsContainer>
        <FormFieldItem
          size="large"
          component={TextInput}
          label={'Oggetto'}
          name={`${prefix}.subject`}
          placeholder="Oggetto"
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormFieldItem
          size="large"
          component={TextInput}
          label={'Descrizione'}
          name={`${prefix}.description`}
          placeholder="Descrizione"
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={2} gutter={8}>
        <FormFieldItem
          size="large"
          component={SelectInput}
          allowClear
          label={'Aperto Da..'}
          name={`${prefix}.dynamicDateOpenFrom`}
          placeholder="Aperto Da.."
          options={DynamicDateRanges}
        />
        <FormFieldItem
          size="large"
          component={SelectInput}
          allowClear
          label={'Aperto A..'}
          name={`${prefix}.dynamicDateOpenTo`}
          placeholder="Aperto A.."
          options={DynamicDateRanges}
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={2} gutter={8}>
        <FormFieldItem
          size="large"
          component={SelectInput}
          allowClear
          label={'Chiuso Da..'}
          name={`${prefix}.dynamicDateClosedFrom`}
          placeholder="Chiuso Da.."
          options={DynamicDateRanges}
        />
        <FormFieldItem
          size="large"
          component={SelectInput}
          allowClear
          label={'Chiuso A..'}
          name={`${prefix}.dynamicDateClosedTo`}
          placeholder="Chiuso A.."
          options={DynamicDateRanges}
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormFieldItem
          size="large"
          component={DossierTypeInput}
          widgetId={props.widgetId}
          label={'Tipo Fascicolo'}
          name={`${prefix}.typeId`}
          placeholder="Tipo Fascicolo"
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormFieldItem
          size="large"
          component={DossierSubtypeInput}
          widgetId={props.widgetId}
          label={'Sottotipo Fascicolo'}
          name={`${prefix}.subtypeId`}
          placeholder="Sottotipo Fascicolo"
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormFieldItem
          size="large"
          component={DossierStatesSelectInput}
          widgetId={props.widgetId}
          label={'Stato'}
          name={`${prefix}.stateId`}
          placeholder="Stato"
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormFieldItem
          size="large"
          component={TextInput}
          label={'Responsabile'}
          name={`${prefix}.owner`}
          placeholder="Responsabile"
          allowClear
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormFieldItem
          size="large"
          component={ToolkitObjectInput}
          objectType={ClassificationNodeToolkitObject}
          fetchObjectParams={{ type: ClassificationParamsType.Dossier }}
          widgetId={props.widgetId}
          name={`${prefix}.classification`}
          label={'Classificazione'}
          placeholder="Classificazione"
        />
      </FormFieldsContainer>
    </>
  );
}

import { IAutoActionMessage } from 'client/components/toolkit/actions/message/IAutoActionMessage';
import { message as antMessage } from 'antd';
import * as React from 'react';
import { ToolkitObjectInput } from 'client/components/toolkit/objects/input/ToolkitObjectInput';
import { DocumentToolkitObject } from 'client/components/widget-repository/objects/documents/DocumentToolkitObject';
import { OfficeToolkitObject } from 'client/components/widget-repository/objects/offices/OfficeToolkitObject';
import { UserToolkitObject } from 'client/components/widget-repository/objects/users/UserToolkitObject';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { ActionMessage } from './ActionMessage';
import { ActionArrowLine } from './message/ActionArrowLine';
import { ActionMessageButtons } from './message/ActionMessageButtons';
import { ObjectSchema } from 'yup';
import { yup } from 'common/validation/initYup';
import { TextInput } from 'client/ui/form/input/TextInput';
import { useMemo } from 'react';
import { mapValues } from 'lodash';
import { ToolkitActionArgument } from 'client/components/toolkit/actions/ToolkitAction';

export interface IAutoActionMessageProps {
  id: string;
  message: IAutoActionMessage<any, any>;
}

/**
 * Crea automaticamente il corpo di un `ActionMessage` tramite i parametri
 * forniti nell'interfaccia.
 * In futuro dovrebbe sostituire tutte le `ActionMessage`.
 */
export function AutoActionMessage(props: IAutoActionMessageProps) {
  const { message } = props;

  const args = Object.fromEntries(
    message.action.arguments.map((arg, i: number) => [
      `argument${i}`,
      arg.isArray && message.args[i] != null && !Array.isArray(message.args[i])
        ? [message.args[i]]
        : message.args[i]
    ])
  );

  const schema = useMemo(
    () =>
      message.schema
        .clone()
        .shape(
          Object.fromEntries(
            message.action.arguments.map((arg, i) => [
              `argument${i}`,
              arg.isArray
                ? yup.array(yup.object().required())
                : yup.object().required()
            ])
          )
        ),
    [message.schema, message.action.arguments]
  );

  return (
    <FormikAugmented<any>
      validationSchema={schema}
      validationContext={{}}
      initialValues={{ ...(message.initialValues as any), ...args }}
      onSubmit={async (rawValues: any) => {
        try {
          const values = await message.schema.validate(rawValues);
          const hasSuccess = await message.onConfirm(
            message.ctx,
            values,
            ...Object.entries(values)
              .filter(([key]) => key.startsWith('argument'))
              .sort((a, b) => a[0].localeCompare(b[0]))
              .map(e => e[1])
          );

          // Se c'è stato un errore, non chiudiamo la modal.
          if (hasSuccess === false) return;

          message.ctx.deskContext.actionMessages.close(props.id);
        } catch (e) {
          // Tecnicamente, non dovremmo mai arrivare qui e far gestire l'errore
          // internamente a `onConfirm`.
          antMessage.error(`Si è verificato un errore durante l'esecuzione dell'azione`); // prettier-ignore
          console.error(e);
        }
      }}
    >
      <ActionMessage
        title={message.title ?? message.action.name}
        actions={
          <ActionMessageButtons
            id={props.id}
            confirmText={message.confirmText}
            confirmProps={message.confirmProps}
          />
        }
      >
        <FormikForm layout="vertical" editable helpInTooltips>
          {!message.hideArgs &&
            message.action.arguments.map((arg, index) => {
              return (
                <FormFieldsContainer>
                  <FormFieldItem
                    key={index}
                    size="large"
                    component={ToolkitObjectInput}
                    objectType={arg.type}
                    allows={arg.allows}
                    widgetId={message.ctx.sourceWidget?.id}
                    showArrow={index > 0}
                    mode={arg.isArray ? 'multiple' : undefined}
                    label={null}
                    name={`argument${index}`}
                    placeholder={arg.name}
                    {...message.argsProps?.[index]}
                  />
                </FormFieldsContainer>
              );
            })}
          {message.form}
        </FormikForm>
      </ActionMessage>
    </FormikAugmented>
  );
}

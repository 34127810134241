import { DeskClassificationNode } from 'client/api/backend/schemas';

export function formatClassificationDescriptions(
  classifications?: DeskClassificationNode[]
) {
  if (!classifications) return undefined;

  return classifications.map(classification =>
    convertClassificationDescription(classification)
  );
}

export function convertClassificationDescription(
  classification?: DeskClassificationNode
): string {
  if (!classification) return '';

  return `${classification.code} - ${classification.description}`;
}

import {
  GetDocumentaryDocumentsSearchParams,
  Widget
} from 'client/api/backend/schemas';
import { applyDynamicDateRange } from 'client/components/toolkit/components/filter/dynamic/DynamicDateRanges';

/**
 * Intefaccia specifica dei filtri "di default" impostati da questo widget
 */
export interface IWidgetDocumentSearchFilterData
  extends Pick<
    GetDocumentaryDocumentsSearchParams,
    'correspondent' | 'subject' | 'mode' | 'searchMode' | 'registryId'
  > {
  dynamicDateFrom: string;
  dynamicDateTo: string;
}

export function applyWidgetDocumentSearchDefaultFilters(
  widget: Widget
): Partial<GetDocumentaryDocumentsSearchParams> {
  const filters = widget.profile
    ?.filters as IWidgetDocumentSearchFilterData | null;
  if (!filters) return {};

  const { dynamicDateFrom, dynamicDateTo, ...standardParams } = filters;
  return {
    ...standardParams,
    dateFrom: applyDynamicDateRange(dynamicDateFrom)?.valueOf(),
    dateTo: applyDynamicDateRange(dynamicDateTo)?.valueOf()
  };
}

import * as React from 'react';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { DeleteOutlined } from '@ant-design/icons';
import {
  getGetUsersQueryKey,
  useDeleteUsersId
} from 'client/api/backend/users/users';
import { message } from 'antd';
import { queryClient } from 'client/core/network/queryClient';
import { UserProfileResponseDTO } from 'client/api/backend/schemas';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

interface UserColumnDeleteButtonProps {
  user: UserProfileResponseDTO;
}

export function UserColumnDeleteButton(props: UserColumnDeleteButtonProps) {
  const deleteMutation = useDeleteUsersId({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetUsersQueryKey());
      }
    }
  });

  return (
    <ButtonDeleteConfirm
      placement="left"
      title={
        <span>
          Sei sicuro di voler eliminare l'Utente{' '}
          <b>
            {' '}
            {props.user.name} {props.user.surname}
          </b>
          ?
        </span>
      }
      cancelText="Annulla"
      onConfirm={async () => {
        try {
          await deleteMutation.mutateAsync({
            id: props.user.id!
          });
          message.success('Utente eliminato con successo. ');
        } catch (e) {
          message.error(
            getNetworkErrorMessage(
              e,
              "Errore durante l'eliminazione dell'utente. Riprovare."
            )
          );
        }
      }}
    >
      <ButtonIcon danger icon={<DeleteOutlined />} />
    </ButtonDeleteConfirm>
  );
}

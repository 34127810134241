import * as React from 'react';
import { AppstoreOutlined } from '@ant-design/icons';
import { DeskDam, Widget } from 'client/api/backend/schemas';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import { DamToolkitObject } from 'client/components/widget-repository/objects/dams/DamToolkitObject';

export function convertDamToWidgetListElement(
  dam: DeskDam,
  widget: Widget
): IWidgetListElement {
  const damName = !dam.name ? `Diga ${dam.id!}` : dam.name;

  return {
    name: damName,
    type: 'dam',
    nameExtend: {
      before: dam.id,
      bold: true
    },
    description: dam.statuses?.map(s => s.key).join(', '),
    descriptionExtend: {
      after: mapDamAddresses(dam.addresses)
    },
    object: DamToolkitObject.create({
      icon: <AppstoreOutlined />,
      id: dam.id!,
      name: damName,
      data: dam,
      sourceWidgetId: widget.id!
    })
  };
}

export function mapDamAddresses(addresses: DeskDam['addresses']) {
  return addresses
    ?.map(a =>
      [a.region, a.province, a.municipality]
        .filter(e => e != null && e.trim() !== '' && e !== '<NON SPECIFICATO>')
        .join(', ')
    )
    .join('| ');
}

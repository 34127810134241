import React, { useCallback, useEffect, useState } from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from './azureAuthConfig';
import { Button, message, Typography } from 'antd';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { InteractionStatus } from '@azure/msal-browser';
import { WidgetCard } from 'client/components/schema/widget/card/WidgetCard';
import { BodyWrapper, ButtonsWrapper, TitleWrapper } from '../login/LoginForm';
import { usePostAuthenticationAzureLogin } from 'client/api/backend/authentication/authentication';
import { useDispatch } from 'react-redux';
import { AuthActions } from '../AuthModule';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
const { Title, Text } = Typography;

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 */
export function AzureLoginForm() {
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const azureLogin = usePostAuthenticationAzureLogin({});
  const dispatch = useDispatch();

  /**
   * Gestisce il login con la redirect alla pagina di Azure, direttamente
   * tramite MSAL
   */
  const handleLogin = useCallback(async (loginType: string) => {
    if (loginType === 'redirect') {
      try {
        await instance.loginRedirect(loginRequest);
      } catch (e) {
        console.error(`[Errore Azure]`, e);
      }
    }
  }, []);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function handleAzureResponse() {
      if (isAuthenticated || accounts.length > 0) {
        setLoading(true);

        try {
          console.log('[azure] auth recognized', accounts);
          const data = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
          });

          // Invio il token di Azure al Backend per emettere il JWT con il token
          // di accesso alla scrivania
          const result = await azureLogin.mutateAsync({
            data: {
              accessToken: data.accessToken
            }
          });
          dispatch(AuthActions.loginSucceed('jwt', 'first-auth-azure'));
        } catch (e) {
          console.error(`[azure] fetch token error`, e);
          message.error(getNetworkErrorMessage(e, `Si è verificato un errore durante il login, si prega di riprovare.`)); // prettier-ignore
        } finally {
          setLoading(false);
        }
      }
    }

    handleAzureResponse().catch(e => {
      console.error(`[Errore Azure]`, e);
    });
  }, [isAuthenticated, accounts]);

  return (
    <LoaderSpin
      spinning={loading || inProgress !== InteractionStatus.None}
      transparent
      fullWidth
    >
      <WidgetCard>
        <BodyWrapper>
          <TitleWrapper>
            <Title level={3}>Login</Title>
            <Text type="secondary">Accedi alla Scrivania</Text>
          </TitleWrapper>
          <ButtonsWrapper>
            <Button
              type="primary"
              size="large"
              block
              onClick={() => handleLogin('redirect')}
            >
              Accedi con Azure
            </Button>
          </ButtonsWrapper>
        </BodyWrapper>
      </WidgetCard>
    </LoaderSpin>
  );
}

import { DeskExtendedAttributeType } from 'client/api/backend/schemas';
import { getCorrespondentSchema } from 'client/components/widget-repository/objects/correspondents/schema/CorrespondentSchema';
import { DossierType } from 'client/components/widget-repository/objects/dossiers/DossierTypes';
import { getExtendedAttributesSchema } from 'client/components/widget-repository/objects/extended-attributes/ExtendedAttributesSchema';
import { yup } from 'common/validation/initYup';

const DossierCorrespondentPersonSchema = yup.object({
  name: yup.string().required().label('Nome'),
  surname: yup.string().required().label('Cognome')
});

const DossierCorrespondentCorporateSchema = yup.object({
  name: yup.string().required().label('Ragione sociale')
});

const DossierCorrespondentAdministrationSchema = yup.object({
  ipacode: yup.string().required().label('Codice IPA'),
  name: yup.string().required().label('Nome Amministrazione'),
  aoocode: yup.string().required().label('Codice AOO'),
  aoodescription: yup.string().required().label('Descrizione AOO')
});

export const DossierCorrespondentSchema = getCorrespondentSchema(
  DossierCorrespondentPersonSchema,
  DossierCorrespondentCorporateSchema,
  DossierCorrespondentAdministrationSchema,
  { required: false }
);

export const DossierCreateSchema = (
  attributesTypes: DeskExtendedAttributeType[]
) =>
  yup.object({
    name: yup.string().required().label('Oggetto'),
    description: yup.string().notRequired().nullable().label('Descrizione'),
    grant: yup
      .object({ id: yup.number().required().label('Lista di Competenza') })
      .required()
      .label('Lista di Competenza'),
    classification: yup.object().required().label('Classificazione'),
    stateId: yup.number().required().label('Stato'),
    idType: yup.number().required().label('Tipo'),
    localExtendedAttributes: getExtendedAttributesSchema(attributesTypes),
    dateOpen: yup.date().required().label('Data Apertura'),
    subjects: yup
      .array()
      .of(DossierCorrespondentSchema)
      .label('Amministrazione Titolare/Partecipante'),
    petitioner: DossierCorrespondentSchema.notRequired()
      .nullable()
      .label('Richiedente'),
    proceeding: yup
      .object({
        id: yup.number().required().label('Procedimento Amministrativo')
      })
      .transform(t => (t == null ? {} : t))
      .when('idType', (idType: number, schema) => {
        return isProceedingRequired(idType)
          ? schema.required()
          : schema.strip();
      })
      .label('Procedimento Amministrativo')
  });

/**
 * Se il tipo del fascicolo è un procedimento amministrativo, allora il campo è obbligatorio
 */
function isProceedingRequired(idType: number) {
  return idType && idType.toString() === DossierType.ProcedimentoAmministrativo;
}

import { useGetDocumentaryDam } from 'client/api/backend/documentary-dam/documentary-dam';
import { DeskDam } from 'client/api/backend/schemas';
import { getHexColorById } from 'client/components/schema/colors/Colors';
import { useDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { DeskMarshaller } from 'client/components/schema/desk/logic/useDeskMutations';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { useWidgetNetworkQueries } from 'client/components/schema/widget/context/useWidgetNetworkEffect';
import { useWidgetContext } from 'client/components/schema/widget/context/WidgetContext';
import { WidgetMap } from 'client/components/toolkit/components/map/WidgetMap';
import { useRegisterToolkitObject } from 'client/components/toolkit/objects/store/useToolkitObjectStore';
import { ToolkitObjectBottomZone } from 'client/components/toolkit/objects/ToolkitObjectBottomZone';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import React, { useMemo } from 'react';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { WidgetDamGeolocationProfile } from './WidgetDamGeolocationProfile';

export interface IWidgetDamGeolocationProps
  extends IWidgetComponentToolkitProps {}

export function WidgetDamGeolocation(props: IWidgetDamGeolocationProps) {
  const { widget } = props;
  const widgetContext = useWidgetContext();

  const { desk } = useDeskContext();
  const current = desk && DeskMarshaller.unmarshall(desk);
  const deskColor = getHexColorById(current?.profile?.colorId);

  const data = widget.profile?.data as WidgetDamGeolocationProfile | undefined;

  const damsResult = useGetDocumentaryDam({
    pageNumber: 0,
    pageSize: 2000,
    widgetId: widget.id!
  });

  const damDetails = useMemo(() => {
    if (!damsResult.data) return [];

    return damsResult.data
      .flat()
      .filter(dam => areDamCoordinatesValid(dam))
      .map(dam => {
        return {
          label: `${dam.id} - ${dam.name}`,
          coordinates: [dam.longitude!, dam.latitude!]
        };
      });
  }, [damsResult.data]);

  useWidgetNetworkQueries([damsResult]);

  // Creiamo l'oggetto per il toolkit
  const widgetObject = WidgetToolkitObject.from(widget);
  useRegisterToolkitObject(widgetObject);

  return (
    <WidgetCardBody
      scrollable={false}
      bottom={<ToolkitObjectBottomZone object={widgetObject} />}
    >
      <WidgetMap
        pointsColor={deskColor}
        selectedCenter={data?.damLocation}
        details={damDetails}
      />
    </WidgetCardBody>
  );
}

function areDamCoordinatesValid(dam: DeskDam) {
  return (
    dam.latitude != null &&
    dam.latitude !== 0 &&
    dam.longitude != null &&
    dam.longitude !== 0
  );
}

import React, { useState } from 'react';
import clx from 'classnames';
import styled, { css } from 'styled-components';
import useDimensions from 'react-cool-dimensions';
import { DeskArea, DeskOffice, DeskUser } from 'client/api/backend/schemas';
import { Tree, Typography } from 'antd';
const { Text } = Typography;
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import {
  DraggedToolkitObject,
  ToolkitObject,
  toolkitObjectUniqId
} from 'client/components/toolkit/objects/ToolkitObject';
import { TextProps } from 'antd/lib/typography/Text';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { useRegisterToolkitObject } from 'client/components/toolkit/objects/store/useToolkitObjectStore';

export const BaseNode = styled(Text)`
  display: inline-block;
  &.is-drop-over {
    /* line-height: 26px;
    font-size: 1.1em; */
    font-weight: 600;
    color: ${props => props.theme.primaryColor};
    /* border: 1px solid ${props => props.theme.primaryColor};
    border-radius: 6px;
    padding: 0 10px; */
  }
  /* padding: 4px 0; */
`;
export const OfficeNode = styled(BaseNode)``;
export const UserNode = styled(BaseNode)``;
export const IconTree = styled(IconWrapper)`
  font-size: 16px;
  vertical-align: -0.2em;
`;

export interface TreeBaseNodeProps extends Omit<TextProps, 'type'> {
  children?: React.ReactNode;
  data?: DeskArea | DeskOffice | DeskUser;
  type: 'area' | 'office' | 'user';
  textType?: TextProps['type'];
  /**
   * ID del nodo padre
   */
  parentId?: number;
  /**
   * Oggetto per il Drag&Drop
   */
  object?: ToolkitObject<any>;
}

export function TreeBaseNode(props: TreeBaseNodeProps) {
  const { data, type, textType, object, children, parentId, ...rest } = props;
  const objectId = toolkitObjectUniqId(object, `-p${parentId ?? 'root'}`);

  // Permette di spostare l'elemento
  const {
    attributes,
    listeners,
    setNodeRef: setDraggableRef
  } = useDraggable({
    id: objectId!,
    disabled: object == null,
    data: {
      object
    } as DraggedToolkitObject
  });

  const { isOver, setNodeRef: setDroppableRef } = useDroppable({
    id: objectId!,
    disabled: object == null,
    data: {
      object
    }
  });

  // Non possiamo usare useRegisterToolkitObject perché essendo una lista
  // virtualizzata (vedi `StyledTree`), verrebbero aggiunti e rimossi
  // continuamente gli oggetti dallo store, causando problemi di performance.
  // Per risolvere questo problema, usiamo un oggetto di tipo
  // `ToolkitObjectStoreBag` in fase di caricamento, che viene poi registrato/
  // deregistrato dallo store quando il Widget stesso viene montato/smontato.
  // (Vedi `WidgetOrganizzationChart`)
  // useRegisterToolkitObject(object);

  return (
    <div {...listeners} {...attributes} ref={setDraggableRef}>
      <div ref={setDroppableRef}>
        <BaseNode {...rest} className={clx({ 'is-drop-over': isOver })}>
          {children}
        </BaseNode>
      </div>
    </div>
  );
}

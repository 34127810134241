import * as React from 'react';
import clx from 'classnames';
import styled from 'styled-components';
import { PlusOutlined } from '@ant-design/icons';

const IconPlusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .anticon {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    color: ${props => props.theme.textColor};
    opacity: 0.3;
  }
  &:hover {
    .anticon {
      color: ${props => props.theme.primaryColor};
      opacity: 0.8;
    }
  }
`;

const IconPlus = styled.div`
  width: 60px;
  height: 60px;
  max-width: 100%;
  max-height: 100%;
  .anticon,
  svg {
    width: 100%;
    height: 100%;
  }
`;

export interface WidgetAddPlusIconProps {}

export function WidgetAddPlusIcon(props: WidgetAddPlusIconProps) {
  return (
    <IconPlusWrapper>
      <IconPlus>
        <PlusOutlined />
      </IconPlus>
    </IconPlusWrapper>
  );
}

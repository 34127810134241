import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { yup } from 'common/validation/initYup';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextInput } from 'client/ui/form/input/TextInput';
import { WidgetBookmarksProfile } from '../WidgetBookmarksProfile';
import { set } from 'client/core/data/set';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { WidgetBookmarksType } from '../WidgetBookmarks.type';

export const WidgetActionAddBookmark = createToolkitAction({
  name: 'Aggiungi Preferito',
  code: 'add-bookmark',
  icon: <PlusOutlined />,
  isPreferential: true,
  arguments: [
    {
      name: 'Widget Preferiti',
      type: [WidgetToolkitObject],
      allows: (object: ToolkitObject) =>
        WidgetToolkitObject.matches(object, WidgetBookmarksType)
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    bookmarkWidgetObject: ToolkitObject | null
  ) {
    const bookmarkWidget = WidgetToolkitObject.get(bookmarkWidgetObject);

    ctx.deskContext.actionMessages.prompt({
      ctx,
      action: WidgetActionAddBookmark,
      args: [bookmarkWidget],
      confirmText: 'Aggiungi preferito',
      schema: AddBookmarkSchema,
      onConfirm,
      form: (
        <>
          <FormFieldsContainer>
            <FormFieldItem
              size="large"
              component={TextInput}
              label={null}
              name="name"
              placeholder="Nome"
            />
            <FormFieldItem
              size="large"
              component={TextInput}
              label={null}
              name="url"
              placeholder="Indirizzo"
            />
          </FormFieldsContainer>
        </>
      )
    });

    return;
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: any,
  bookmarkWidget: ToolkitObject
) {
  try {
    const widget = ctx.deskContext.widgets.find(
      w => w.id === bookmarkWidget.id
    );
    const profile = widget?.profile;
    const data = profile?.data as WidgetBookmarksProfile | null;

    const addedBookmark = { name: values.name, url: values.url };

    await ctx.deskContext.updateWidget(
      set(widget!, 'profile.data.bookmarks', [
        ...(data?.bookmarks ?? []),
        addedBookmark
      ])
    );

    message.success('Preferito aggiunto con successo');
  } catch (e) {
    message.error('Impossibile aggiungere il preferito');
    console.error(e);
    return false;
  }
}

const AddBookmarkSchema = yup.object({
  name: yup.string().required('Nome'),
  url: yup.string().required('Indirizzo')
});

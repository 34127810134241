import React from 'react';
import { ContainerOutlined } from '@ant-design/icons';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import {
  getToolkitObjectOfType,
  ToolkitObject
} from 'client/components/toolkit/objects/ToolkitObject';
import { message } from 'antd';
import { DocumentToolkitObject } from 'client/components/widget-repository/objects/documents/DocumentToolkitObject';
import {
  ExtendedDeskFolder,
  FolderToolkitObject
} from 'client/components/widget-repository/objects/folders/FolderToolkitObject';
import { PersonalDossierToolkitObject } from '../../../personal-dossier/objects/PersonalDossierToolkitObject';
import { DossierToolkitObject } from 'client/components/widget-repository/objects/dossiers/DossierToolkitObject';
import { yup } from 'common/validation/initYup';
import { InferType } from 'yup';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { AssignmentToolkitObject } from 'client/components/widget-repository/objects/assignments/AssignmentToolkitObject';
import { isInsertIntoDossierActionAllowed } from '../isInsertIntoDossierActionAllowed';
import { executeInsertDocumentDossier } from './insertDocumentDossier';
import { isAssignmentStale } from 'client/components/widget-repository/objects/assignments/isAssignmentStale';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { HelpText } from 'client/ui/help-text/HelpText';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { executeConcludeAssignment } from '../../../assignments/actions/conclude/executeConcludeAssignment';
import { isAssignmentActionAllowed } from 'client/components/widget-repository/objects/assignments/isAssignmentActionAllowed';
import { isDocumentAccessible } from 'client/components/widget-repository/objects/documents/logic/isDocumentAccessible';

export const WidgetActionInsertDocumentDossier = createToolkitAction({
  name: 'Fascicola Documento',
  code: 'insert-document-dossier',
  icon: <ContainerOutlined />,
  arguments: [
    {
      name: 'Documento',
      type: [DocumentToolkitObject, AssignmentToolkitObject],
      isArray: true,
      allows: (obj: ToolkitObject) =>
        isInsertIntoDossierActionAllowed(obj) &&
        isAssignmentActionAllowed(obj) &&
        isDocumentAccessible(obj)
    },
    {
      name: 'Cartella',
      type: [
        DossierToolkitObject,
        FolderToolkitObject,
        PersonalDossierToolkitObject
      ]
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    entityObjects: ToolkitObject | ToolkitObject[],
    folderObject: ToolkitObject | null
  ) {
    let folder = getToolkitObjectOfType(folderObject, [
      FolderToolkitObject.code,
      PersonalDossierToolkitObject.code,
      DossierToolkitObject.code
    ]);

    const assignments = AssignmentToolkitObject.getMany(entityObjects);
    const isAssignment = assignments.length > 0;

    // Se non viene fornita la cartella, dobbiamo chiederla tramite interfaccia
    // Capita con azioni contestuali
    // Nel caso stiamo fascicolando un'assegnazione dobbiamo chiedere se concluderla
    if (!folder || isAssignment) {
      ctx.deskContext.actionMessages.prompt({
        ctx,
        action: WidgetActionInsertDocumentDossier,
        args: [entityObjects, folder],
        schema: InsertDocumentDossierSchema,
        confirmText: 'Fascicola',
        onConfirm,
        form: (
          <>
            <FormFieldsContainer>
              {isAssignment && (
                <>
                  <FormFieldItem
                    component={CheckboxInput}
                    label={null}
                    name="conclude"
                  >
                    Concludi l'assegnazione
                  </FormFieldItem>
                  <HelpText style={{ paddingTop: '0px' }}>
                    Concludi l'assegnazione dopo aver fascicolato il documento
                  </HelpText>
                </>
              )}
            </FormFieldsContainer>
          </>
        )
      });
      return;
    }

    // Altrimenti in caso di drag & drop fascicoliamo direttamente
    await onConfirm(ctx, { conclude: false }, entityObjects, folder);
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: InferType<typeof InsertDocumentDossierSchema>,
  entityObjects: ToolkitObject | ToolkitObject[],
  folder: ToolkitObject<ExtendedDeskFolder>
) {
  const documents = DocumentToolkitObject.getMany(entityObjects)!;
  const assignments = AssignmentToolkitObject.getMany(entityObjects);

  try {
    await executeInsertDocumentDossier(assignments, documents, folder);

    message.success('Fascicolazione effettuata con successo');
  } catch (e) {
    message.error(
      getNetworkErrorMessage(
        e,
        `Impossibile fascicolare il documento, si è verificato un errore.`
      )
    );
    console.error(e);
    return false;
  }

  if (assignments != null && assignments.length > 0 && values.conclude) {
    try {
      // Conclusione dell'assegnazione
      await executeConcludeAssignment(assignments);
      message.success('Conclusione effettuata con successo');
    } catch (e: any) {
      message.error(
        getNetworkErrorMessage(e, 'Errore durante la conclusione.')
      );
      console.error(e);
      return false;
    }
  }
}

const InsertDocumentDossierSchema = yup.object({
  conclude: yup.boolean().notRequired().label('Concludi')
});

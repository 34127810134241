import 'reflect-metadata';

// Polyfill
import 'ts-polyfill/lib/es2015-core';
import 'ts-polyfill/lib/es2015-collection';
import 'ts-polyfill/lib/es2015-iterable';
import 'ts-polyfill/lib/es2015-promise';
import 'ts-polyfill/lib/es2015-reflect';
import 'ts-polyfill/lib/es2016-array-include';
import 'ts-polyfill/lib/es2017-object';
import 'core-js/es/symbol';

// Locale
import it_IT from 'antd/lib/locale-provider/it_IT';
import 'common/validation/initYup';
import moment from 'moment';
import 'moment/locale/it';
moment.locale('it');

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from 'client/core/store/configureReduxStore';
import { Routes } from './routes/Routes';
import { ConfigProvider } from 'antd';
import { NetworkProvider } from './core/network/context/NetworkContext';
import { networkState } from './core/network/context/configureNetworkState';
import { BreadcrumbProvider } from './core/router/breadcrumb/BreadcrumbContext';
import { appConfig } from './core/appConfig';
import { ThemeProvider } from 'styled-components';
import { theme } from './ui/theme/theme';

import 'src/client/ui/theme/fonts/Fonts.less';

// TODO: Remove, dev
import { faker } from '@faker-js/faker';
import { queryClient } from './core/network/queryClient';
import { QueryClientProvider } from 'react-query';
import { GridDraggingStyles } from './components/schema/desk/space/grid/dragging/GridDraggingStyles';
import { SideMenuDropdownGlobalStyles } from './components/toolkit/components/tabs/TabsSideMenuDropdown';
import { MainMenuStyles } from './routes/menu/MainMenuStyles';
import { RichTextInputGlobalStyles } from './ui/form/field/RichTextInputGlobalStyles';
import { PageGlobalStyles } from './ui/layout/PageGlobalStyles';
import { FontPreload } from './ui/theme/fonts/FontPreloader';
import { SsoContextProvider } from './components/auth/SsoContextProvider';
faker.locale = 'it';

export function App() {
  return (
    <BrowserRouter basename={appConfig.rootNavigationPath}>
      <SsoContextProvider>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            {/* Importazione stili globali */}
            <GridDraggingStyles />
            <SideMenuDropdownGlobalStyles />
            <MainMenuStyles />
            <RichTextInputGlobalStyles />
            <PageGlobalStyles />
            <FontPreload />

            <ConfigProvider locale={it_IT}>
              <QueryClientProvider client={queryClient}>
                <NetworkProvider value={networkState}>
                  <BreadcrumbProvider>
                    <Routes />
                  </BreadcrumbProvider>
                </NetworkProvider>
              </QueryClientProvider>
            </ConfigProvider>
          </ThemeProvider>
        </Provider>
      </SsoContextProvider>
    </BrowserRouter>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

import {
  GetDocumentaryActivityParams,
  Widget
} from 'client/api/backend/schemas';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import * as React from 'react';
import * as WidgetFilterSections from 'client/components/toolkit/components/filter/WidgetFilterSections';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { Button, Space } from 'antd';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { WidgetFilterDrawer } from 'client/components/toolkit/components/filter/WidgetFilterDrawer';
import { renderWidgetFilterActiveTags } from 'client/components/toolkit/components/filter/renderWidgetFilterActiveTags';
import { useState } from 'react';
import { WidgetActivitiesSearchSchema } from './WidgetActivitiesSearchSchema';

export interface IWidgetActivitiesSearchFormProps {
  widget: Widget;
  search: Partial<GetDocumentaryActivityParams>;
  setSearch: (search: Partial<GetDocumentaryActivityParams>) => void;
}

export function WidgetActivitiesSearchForm(
  props: IWidgetActivitiesSearchFormProps
) {
  const { widget, search, setSearch } = props;
  const [open, setOpen] = useState(false);

  return (
    <FormikAugmented
      validationSchema={WidgetActivitiesSearchSchema}
      validationContext={{}}
      initialValues={search}
      // validate={schemaToValidator()}
      onReset={() => {
        setSearch({});
        setOpen(false);
      }}
      onSubmit={(values: Partial<GetDocumentaryActivityParams>) => {
        setSearch(values);
        setOpen(false);
      }}
    >
      <WidgetFilterDrawer
        title="Ricerca"
        filters={renderWidgetFilterActiveTags({
          search,
          filters: widget.profile?.filters,
          onReset: () => setSearch({})
        })}
        open={[open, setOpen]}
      >
        <FormikForm layout="vertical" editable helpInTooltips>
          <WidgetFilterSections.BodyWrapper>
            <FormFieldsContainer>
              <FormFieldItem
                size="middle"
                component={TextInput}
                label={null}
                name="subject"
                placeholder="Oggetto"
              />
            </FormFieldsContainer>
            <FormFieldsContainer>
              <FormFieldItem
                size="middle"
                component={NumberInput}
                label={null}
                name="proposeNumber"
                placeholder="Numero Proposta"
              />
            </FormFieldsContainer>
            <FormFieldsContainer columns={2} gutter={8}>
              <FormFieldItem
                size="middle"
                component={NumberInput}
                label={null}
                name="officeId"
                placeholder="Id Ufficio"
              />
              <FormFieldItem
                size="middle"
                component={TextInput}
                label={null}
                name="officeDescription"
                placeholder="Nome Ufficio"
              />
            </FormFieldsContainer>
            <FormFieldsContainer columns={2} gutter={8}>
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                type="unix"
                label={null}
                name="startDate"
                placeholder="Dal"
              />
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                type="unix"
                label={null}
                name="endDate"
                placeholder="Al"
              />
            </FormFieldsContainer>
          </WidgetFilterSections.BodyWrapper>
          <WidgetFilterSections.ButtonsWrapper>
            <Space>
              <Button htmlType="reset">Pulisci</Button>
              <Button htmlType="submit" type="primary">
                Filtra
              </Button>
            </Space>
          </WidgetFilterSections.ButtonsWrapper>
        </FormikForm>
      </WidgetFilterDrawer>
    </FormikAugmented>
  );
}

import { DeskDossier } from 'client/api/backend/schemas';
import { IDossierMetadata } from 'client/components/toolkit/components/metadata/IDossierMetadata';
import { convertClassificationDescription } from 'client/components/widget-repository/objects/classifications/formatClassificationDescription';
import {
  convertCorrespondentName,
  formatCorrespondentNames
} from 'client/components/widget-repository/objects/correspondents/formatCorrespondentNames';
import { convertProceeding } from 'client/components/widget-repository/objects/proceedings/convertProceeding';
import moment from 'moment';

export function convertDossierToMetadata(
  dossier: DeskDossier
): IDossierMetadata {
  return {
    description: dossier.description ?? dossier.name,
    dateOpen: moment(dossier.dateOpen).format('ll'),
    dateClose: dossier.dateClose ? moment(dossier.dateClose).format('ll') : '',
    dateExpiry: dossier.dateExpiry
      ? moment(dossier.dateExpiry).format('ll')
      : '',
    classification: convertClassificationDescription(dossier.classification),
    grant: dossier.grant?.description,
    manager: dossier.manager,
    physicalPosition: dossier.physicalPosition,
    notes: dossier.notes,
    petitioner: convertCorrespondentName(dossier.petitioner),
    subjects: formatCorrespondentNames(dossier.subjects),
    proceeding: convertProceeding(dossier.proceeding)
  };
}

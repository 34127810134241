import { Widget } from 'client/api/backend/schemas';
import { omit } from 'lodash';

export const parseWidgetJSONConfiguration = (config: string | undefined) => {
  return JSON.parse(config ?? '{}');
};

export const WidgetMarshaller = {
  marshall(widget: Widget) {
    return {
      widgetTypeId: 1,
      ...omit(widget, ['profile']),
      configuration: JSON.stringify({
        ...parseWidgetJSONConfiguration(widget.configuration),
        fields: widget.fields,
        settings: widget.profile
      })
    };
  },
  unmarshall(widget: Widget): Widget {
    try {
      const configuration = parseWidgetJSONConfiguration(widget.configuration);

      // Supportiamo le vecchie configurazioni su `configuration` in caso non ci
      // sia il "settings" impostato (API v1.0.4)
      let profile = configuration.settings;
      let fields = configuration.fields;

      return {
        ...widget,
        profile,
        fields,
        isConfigured: configuration.isConfigured
      };
    } catch (e) {
      console.error(`Impossibile completare la decodifica del Widget [${widget.id}]`); // prettier-ignore
      console.error(`widget.configuration=`, widget.configuration);
      // throw new Error(
      //   `Impossibile completare la decodifica del Widget [${widget.id}]`
      // );
      return {
        ...widget,
        profile: {}
      };
    }
  }
};

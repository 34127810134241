import { Alert, Button, Space, Typography } from 'antd';
const { Text, Paragraph } = Typography;
import { UserResponseDTO } from 'client/api/backend/schemas';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikForm } from 'client/ui/form/FormikForm';
import React from 'react';
import { getUserLabel, UserSelectInput } from '../UserSelectInput';

interface DeskCopyFormProps {
  selectedUsers: UserResponseDTO[];
  onClose?: () => void;
}

export function DeskCopyForm(props: DeskCopyFormProps) {
  return (
    <FormikForm editable helpInTooltips layout="vertical">
      <Alert
        message="Effettuando la copia delle scrivanie verranno eliminate tutte le scrivanie attualmente disponibili degli utenti destinatari della copia."
        type="warning"
        showIcon
        closable
        style={{ marginBottom: '12px' }}
      />
      <FormFieldsContainer>
        <FormFieldItem
          component={UserSelectInput}
          name="userId"
          label="Utente da cui copiare le scrivanie"
          size="large"
          placeholder="Utente"
          showSearch
        />
      </FormFieldsContainer>
      <div>
        La copia delle scrivanie dell'utente selezionato verrà effettuata per i
        seguenti utenti:
      </div>
      <Paragraph strong ellipsis={{ rows: 3, expandable: true }}>
        {props.selectedUsers.map(user => getUserLabel(user)).join(', ')}
      </Paragraph>
      <Space
        style={{ marginTop: '12px', width: '100%', justifyContent: 'flex-end' }}
      >
        <Button size="large" onClick={props.onClose}>
          Annulla
        </Button>
        <FormikSendButton as={Button} title="Copia Scrivania" size="large">
          Avvia Copia
        </FormikSendButton>
      </Space>
    </FormikForm>
  );
}

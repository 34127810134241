import { useDroppable } from '@dnd-kit/core';
import { ToolkitObject, toolkitObjectUniqId } from './ToolkitObject';

export interface IDroppableToolkitData {
  object: ToolkitObject | null;
}

/**
 * Versione di `useDroppable` specifica per i ToolkitObject.
 * Si occupa automaticamente di generare l'`objectId`.
 *
 * @param data Dati aggiuntivi per l'evento di "Drop"
 */
export function useDroppableToolkitObject(
  object: ToolkitObject | null | undefined,
  data?: Omit<IDroppableToolkitData, 'object'>
) {
  const objectId = toolkitObjectUniqId(object)!;

  const droppable = useDroppable({
    id: objectId,
    disabled: object == null,
    data: {
      object,
      ...data
    }
  });

  return { ...droppable, objectId };
}

import {
  GetDocumentaryDossiersSearchParams,
  Widget
} from 'client/api/backend/schemas';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { yup } from 'common/validation/initYup';
import * as React from 'react';
import * as WidgetFilterSections from 'client/components/toolkit/components/filter/WidgetFilterSections';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { Button, Space } from 'antd';
import { DossierStatesSelectInput } from './DossierStatesSelectInput';
import { renderWidgetFilterActiveTags } from 'client/components/toolkit/components/filter/renderWidgetFilterActiveTags';
import { WidgetFilterDrawer } from 'client/components/toolkit/components/filter/WidgetFilterDrawer';
import { useState } from 'react';
import { DossierTypeInput } from 'client/components/widget-repository/objects/dossiers/DossierTypeInput';
import { ToolkitObjectInput } from 'client/components/toolkit/objects/input/ToolkitObjectInput';
import { ClassificationNodeToolkitObject } from 'client/components/widget-repository/objects/classifications/ClassificationNodeToolkitObject';
import { DossierSubtypeInput } from 'client/components/widget-repository/objects/dossiers/DossierSubtypeInput';
import { ClassificationParamsType } from 'client/components/widget-repository/objects/classifications/ClassificationParamsType';

export interface IWidgetDossiersSearchFormProps {
  widget: Widget;
  search: Partial<GetDocumentaryDossiersSearchParams>;
  setSearch: (search: Partial<GetDocumentaryDossiersSearchParams>) => void;
  rightButton: React.ReactNode;
}

export function WidgetDossiersSearchForm(
  props: IWidgetDossiersSearchFormProps
) {
  const { widget } = props;

  const { search, setSearch } = props;
  const [open, setOpen] = useState(false);

  return (
    <FormikAugmented
      validationSchema={yup.object()}
      validationContext={{}}
      initialValues={search}
      onReset={() => {
        setSearch({});
        setOpen(false);
      }}
      onSubmit={(values: Partial<GetDocumentaryDossiersSearchParams>) => {
        setSearch(values);
        setOpen(false);
      }}
    >
      <WidgetFilterDrawer
        title="Ricerca Fascicoli"
        filters={renderWidgetFilterActiveTags({
          search,
          filters: widget.profile?.filters,
          onReset: () => setSearch({})
        })}
        open={[open, setOpen]}
        rightButton={props.rightButton}
      >
        <FormikForm layout="vertical" editable helpInTooltips>
          <WidgetFilterSections.BodyWrapper>
            <FormFieldsContainer>
              <FormFieldItem
                size="middle"
                component={TextInput}
                label={null}
                name="subject"
                placeholder="Oggetto"
                allowClear
              />
            </FormFieldsContainer>
            <FormFieldsContainer>
              <FormFieldItem
                size="middle"
                component={TextInput}
                label={null}
                name="description"
                placeholder="Descrizione"
                allowClear
              />
            </FormFieldsContainer>
            <FormFieldsContainer columns={2} gutter={8}>
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                type="unix"
                label={null}
                name="dateOpenFrom"
                placeholder="Aperto Dal"
              />
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                type="unix"
                label={null}
                name="dateOpenTo"
                placeholder="Aperto Al"
              />
            </FormFieldsContainer>
            <FormFieldsContainer columns={2} gutter={8}>
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                type="unix"
                label={null}
                name="dateCloseFrom"
                placeholder="Chiuso Dal"
              />
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                type="unix"
                label={null}
                name="dateCloseTo"
                placeholder="Chiuso Al"
              />
            </FormFieldsContainer>
            <FormFieldsContainer>
              <FormFieldItem
                size="middle"
                component={DossierTypeInput}
                widgetId={props.widget.id!}
                label={null}
                name="typeId"
                placeholder="Tipo Fascicolo"
              />
              <FormFieldItem
                size="middle"
                component={DossierSubtypeInput}
                widgetId={props.widget.id!}
                label={null}
                name="subtypeId"
                placeholder="Sottotipo Fascicolo"
              />
              <FormFieldItem
                size="middle"
                component={DossierStatesSelectInput}
                widgetId={props.widget.id!}
                label={null}
                name="stateId"
                placeholder="Stato"
              />
              <FormFieldItem
                size="middle"
                component={TextInput}
                label={null}
                name="owner"
                placeholder="Responsabile"
                allowClear
              />
              <FormFieldItem
                size="large"
                component={ToolkitObjectInput}
                objectType={ClassificationNodeToolkitObject}
                fetchObjectParams={{ type: ClassificationParamsType.Dossier }}
                widgetId={props.widget.id!}
                name="classification"
                label={null}
                placeholder="Classificazione"
              />
            </FormFieldsContainer>
          </WidgetFilterSections.BodyWrapper>
          <WidgetFilterSections.ButtonsWrapper>
            <Space>
              <Button htmlType="reset">Pulisci</Button>
              <Button htmlType="submit" type="primary">
                Filtra
              </Button>
            </Space>
          </WidgetFilterSections.ButtonsWrapper>
        </FormikForm>
      </WidgetFilterDrawer>
    </FormikAugmented>
  );
}

import { DeskCorrespondent, Widget } from 'client/api/backend/schemas';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { TextInput } from 'client/ui/form/input/TextInput';
import * as React from 'react';
import { CorrespondentTransportInput } from './CorrespondentTransportInput';
import { CorrespondentKey } from './CorrespondentType';

export interface ICorrespondentInputProps {
  widget: Widget;
  item?: DeskCorrespondent;
  fieldNamePrefix: string;
  /** Permette di indicare un campo dal quale prendere la modalità di protocollo (dipendenza per il trasporto) */
  modeFieldName?: string;
  disabled?: boolean;
}

export function CorrespondentInput(props: ICorrespondentInputProps) {
  const { widget, item, fieldNamePrefix, disabled } = props;

  if (!item) return null;

  if (item.type === CorrespondentKey.Person) {
    return (
      <FormFieldsContainer removeTopTitleSpacing columns={2} gutter={8}>
        <FormFieldItem
          disabled={disabled}
          size="middle"
          component={TextInput}
          name={`${fieldNamePrefix}.person.name`}
          label="Nome"
          placeholder="Nome"
        />
        <FormFieldItem
          disabled={disabled}
          size="middle"
          component={TextInput}
          name={`${fieldNamePrefix}.person.surname`}
          label="Cognome"
          placeholder="Cognome"
        />
        <FormFieldItem
          disabled={disabled}
          size="middle"
          colSpan={2}
          component={TextInput}
          name={`${fieldNamePrefix}.person.fiscalCode`}
          label="Codice Fiscale"
          placeholder="Codice Fiscale"
        />
        <FormFieldItem
          disabled={disabled}
          size="middle"
          colSpan={2}
          component={TextInput}
          name={`${fieldNamePrefix}.person.address.address`}
          label="Indirizzo"
          placeholder="Indirizzo"
        />
        <FormFieldItem
          disabled={disabled}
          size="middle"
          colSpan={2}
          component={TextInput}
          name={`${fieldNamePrefix}.person.email`}
          label="Email"
          placeholder="Email"
        />
        <FormFieldItem
          disabled={disabled}
          size="middle"
          colSpan={2}
          component={CorrespondentTransportInput}
          widgetId={widget.id!}
          modeFieldName={props.modeFieldName}
          name={`${fieldNamePrefix}.transport.id`}
          label="Mezzo Spedizione"
          placeholder="Mezzo Spedizione"
        />
      </FormFieldsContainer>
    );
  }

  if (item.type === CorrespondentKey.Organization) {
    return (
      <FormFieldsContainer removeTopTitleSpacing columns={2} gutter={8}>
        <FormFieldItem
          disabled={disabled}
          size="middle"
          component={TextInput}
          name={`${fieldNamePrefix}.corporate.name`}
          label="Ragione sociale"
          placeholder="Ragione sociale"
        />
        <FormFieldItem
          disabled={disabled}
          size="middle"
          component={TextInput}
          name={`${fieldNamePrefix}.corporate.code`}
          label="Codice"
          placeholder="Codice"
        />
        <FormFieldItem
          disabled={disabled}
          size="middle"
          colSpan={2}
          component={TextInput}
          name={`${fieldNamePrefix}.corporate.fiscalCode`}
          label="Codice Fiscale"
          placeholder="Codice Fiscale"
        />
        <FormFieldItem
          disabled={disabled}
          size="middle"
          colSpan={2}
          component={TextInput}
          name={`${fieldNamePrefix}.corporate.address.address`}
          label="Indirizzo"
          placeholder="Indirizzo"
        />
        <FormFieldItem
          disabled={disabled}
          size="middle"
          colSpan={2}
          component={TextInput}
          name={`${fieldNamePrefix}.corporate.address.email`}
          label="Email"
          placeholder="Email"
        />
        <FormFieldItem
          disabled={disabled}
          size="middle"
          colSpan={2}
          component={CorrespondentTransportInput}
          widgetId={widget.id!}
          modeFieldName={props.modeFieldName}
          name={`${fieldNamePrefix}.transport.id`}
          label="Mezzo Spedizione"
          placeholder="Mezzo Spedizione"
        />
      </FormFieldsContainer>
    );
  }

  if (item.type === CorrespondentKey.PublicAdministration) {
    return (
      <FormFieldsContainer removeTopTitleSpacing gutter={8}>
        <FormFieldItem
          disabled={disabled}
          size="middle"
          component={TextInput}
          name={`${fieldNamePrefix}.administration.ipacode`}
          label="Codice IPA"
          placeholder="Codice IPA"
        />
        <FormFieldItem
          disabled={disabled}
          size="middle"
          component={TextInput}
          name={`${fieldNamePrefix}.administration.name`}
          label="Amministrazione"
          placeholder="Amministrazione"
        />
        <FormFieldItem
          disabled={disabled}
          size="middle"
          component={TextInput}
          name={`${fieldNamePrefix}.administration.aoocode`}
          label="Codice AOO"
          placeholder="Codice AOO"
        />
        <FormFieldItem
          disabled={disabled}
          size="middle"
          component={TextInput}
          name={`${fieldNamePrefix}.administration.aoodescription`}
          label="Denominazione AOO"
          placeholder="Denominazione AOO"
        />
        <FormFieldItem
          disabled={disabled}
          size="middle"
          component={TextInput}
          name={`${fieldNamePrefix}.administration.address.email`}
          label="Email"
          placeholder="Email"
        />
        <FormFieldItem
          disabled={disabled}
          size="middle"
          component={CorrespondentTransportInput}
          widgetId={widget.id!}
          modeFieldName={props.modeFieldName}
          name={`${fieldNamePrefix}.transport.id`}
          label="Mezzo Spedizione"
          placeholder="Mezzo Spedizione"
        />
      </FormFieldsContainer>
    );
  }

  if (item.type === CorrespondentKey.ForeignPublicAdministration) {
    return (
      <FormFieldsContainer removeTopTitleSpacing gutter={8}>
        <FormFieldItem
          disabled={disabled}
          size="middle"
          component={TextInput}
          name={`${fieldNamePrefix}.administration.name`}
          label="Amministrazione"
          placeholder="Amministrazione"
        />
        <FormFieldItem
          disabled={disabled}
          size="middle"
          component={TextInput}
          name={`${fieldNamePrefix}.administration.address.email`}
          label="Email"
          placeholder="Email"
        />
        <FormFieldItem
          disabled={disabled}
          size="middle"
          component={CorrespondentTransportInput}
          widgetId={widget.id!}
          modeFieldName={props.modeFieldName}
          name={`${fieldNamePrefix}.transport.id`}
          label="Mezzo Spedizione"
          placeholder="Mezzo Spedizione"
        />
      </FormFieldsContainer>
    );
  }

  return null;
}

import { DeskActivity } from 'client/api/backend/schemas';
import { IActivityMetadata } from 'client/components/toolkit/components/metadata/IActivityMetadata';
import moment from 'moment';

export function convertActivityToMetadata(
  activity: DeskActivity
): IActivityMetadata {
  return {
    data: {
      proposeNumber: activity.proposeNumber,
      proposeDate: activity.proposeDate
        ? moment(activity.proposeDate).format('ll')
        : '',
      subject: activity.subject,
      proposerOffice: `${activity.office?.code} - ${activity.office?.name}`
    }
  };
}

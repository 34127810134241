import * as React from 'react';
import { Skeleton, Tag } from 'antd';
import { GetDocumentaryDossiersSearchParams } from 'client/api/backend/schemas';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import { WidgetList } from 'client/components/toolkit/components/list/WidgetList';
import { PaddedSkeleton } from 'client/ui/skeleton/PaddedSkeleton';
import { useCallback, useMemo, useState } from 'react';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import {
  InfiniteDossiersSearchPageStart,
  useInfiniteDossiersSearch
} from './api/useInfiniteDossiersSearch';
import { convertDossierToListElement } from './convert/convertDossierToListElement';
import { WidgetDossiersSearchForm } from './filters/WidgetDossiersSearchForm';
import { useWidgetNetworkQueries } from 'client/components/schema/widget/context/useWidgetNetworkEffect';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { ToolkitObjectActionsMenu } from 'client/components/schema/widget/actions/interaction/ToolkitObjectActionsMenu';
import { applyWidgetDossierSearchDefaultFilters } from './filters/WidgetDossiersSearchFilter';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { omit } from 'lodash';

export interface IWidgetDossiersSearchProps
  extends IWidgetComponentToolkitProps {}

const DossiersPageSize = 20;

export function WidgetDossiersSearch(props: IWidgetDossiersSearchProps) {
  const { widget } = props;

  // Ricerca locale, per la durata della sessione.
  const [search, setSearch] = useState<
    Partial<GetDocumentaryDossiersSearchParams> & {
      classification?: ToolkitObject<any>;
    }
  >({});
  const apiSearch = useMemo(
    () => ({
      ...applyWidgetDossierSearchDefaultFilters(widget),
      ...applyDossierSearch(search),
      pageNumber: InfiniteDossiersSearchPageStart,
      pageSize: DossiersPageSize,
      widgetId: widget.id!
    }),
    [search, widget.profile?.filters]
  );
  const dossiersResult = useInfiniteDossiersSearch(apiSearch, {
    query: {
      getNextPageParam: (lastPage, pages) =>
        lastPage.length < DossiersPageSize
          ? undefined
          : pages.length + InfiniteDossiersSearchPageStart
    }
  });

  const dossiers = useMemo<IWidgetListElement[]>(() => {
    if (!dossiersResult.data) return [];

    return dossiersResult.data.pages
      .flat()
      .map(dossier => convertDossierToListElement(dossier, widget));
  }, [dossiersResult.data?.pages]);

  useWidgetNetworkQueries([dossiersResult]);

  const widgetObject = WidgetToolkitObject.from(widget);

  return (
    <WidgetCardBody
      top={
        <WidgetDossiersSearchForm
          widget={widget}
          search={search}
          setSearch={setSearch}
          rightButton={<ToolkitObjectActionsMenu object={widgetObject} />}
        />
      }
    >
      <PaddedSkeleton loading={dossiersResult.isLoading} active>
        <WidgetList
          list={dossiers}
          infinite={{
            query: dossiersResult
          }}
        />
      </PaddedSkeleton>
    </WidgetCardBody>
  );
}

function applyDossierSearch(
  search: Partial<GetDocumentaryDossiersSearchParams> & {
    classification?: ToolkitObject<any>;
  }
) {
  return {
    ...omit(search, ['classification']),
    ...(search.classification != null && {
      classificationId: search.classification?.id as number
    })
  };
}

import { PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Empty } from 'antd';
import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { down } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled from 'styled-components';
import { DeskActions } from '../DeskModule';

const EmptyPlaceholderDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export interface IDeskEmptyPlaceholderProps {}

export function DeskEmptyPlaceholder(props: IDeskEmptyPlaceholderProps) {
  const dispatch = useDispatch();
  const isMobile = useBreakpoint(down('sm'));

  const handleConfigure = useCallback(() => {
    dispatch(DeskActions.editable());
  }, [dispatch]);

  return (
    <EmptyPlaceholderDiv>
      <Empty
        description="Nessun widget presente."
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      >
        {!isMobile && (
          <Button
            icon={<SettingOutlined />}
            type="primary"
            onClick={handleConfigure}
          >
            Configura
          </Button>
        )}
      </Empty>
    </EmptyPlaceholderDiv>
  );
}

import { useGetDocumentaryOrganizationOfficesId } from 'client/api/backend/documentary-organization/documentary-organization';
import { DeskDossier } from 'client/api/backend/schemas';
import { Metadata } from 'client/components/toolkit/components/metadata/Metadata';
import { useGetDossierStateLabel } from 'client/components/widget-repository/objects/dossiers/getDossierStateLabel';
import { useGetDossierTypeLabel } from 'client/components/widget-repository/objects/dossiers/getDossierTypeLabel';
import { IndentedMetadataWrapper } from 'client/ui/indented-metadata/IndentedMetadataWrapper';
import React from 'react';
import { convertDossierToMetadata } from '../convert/convertDossierToMetadata';

interface IWidgetDossierMetadata {
  dossier?: DeskDossier;
  widgetId: number;
}

export function WidgetDossierMetadata(props: IWidgetDossierMetadata) {
  const { dossier, widgetId } = props;

  const typeLabel = useGetDossierTypeLabel(widgetId, dossier?.idType);
  const stateLabel = useGetDossierStateLabel(widgetId, dossier?.stateId);

  const ownerOffice = useGetDocumentaryOrganizationOfficesId(
    dossier?.idOwnerOffice!,
    { widgetId },
    { query: { enabled: dossier != null && dossier.idOwnerOffice != null } }
  );

  if (!dossier) {
    return null;
  }

  const metadata = convertDossierToMetadata(dossier);

  return (
    <div>
      <Metadata label="Descrizione" value={metadata.description} />
      <Metadata label="Tipo" value={typeLabel} />
      {metadata.proceeding && (
        <IndentedMetadataWrapper>
          {metadata.proceeding.map((s, index) => {
            return <Metadata label={s.label} value={s.value} />;
          })}
        </IndentedMetadataWrapper>
      )}
      <Metadata label="Data Apertura" value={metadata.dateOpen} />
      <Metadata
        label="Data Chiusura"
        value={metadata.dateClose}
        hideIfNoValue
      />
      <Metadata
        label="Data Scadenza"
        value={metadata.dateExpiry}
        hideIfNoValue
      />
      <Metadata label="Stato" value={stateLabel} />
      <Metadata label="Classificazione" value={metadata.classification} />
      <Metadata label="Lista di Competenza" value={metadata.grant} />
      <Metadata label="Ufficio Proprietario" value={ownerOffice.data?.name} />
      <Metadata label="Responsabile" value={metadata.manager} hideIfNoValue />
      <Metadata
        label="Posizione Fisica"
        value={metadata.physicalPosition}
        hideIfNoValue
      />
      <Metadata label="Richiedente" value={metadata.petitioner} hideIfNoValue />
      <Metadata label="Amministrazioni Titolari/Partecipanti" />
      <IndentedMetadataWrapper>
        {metadata.subjects?.map((s, index) => {
          return <Metadata label="" value={s} />;
        })}
      </IndentedMetadataWrapper>
    </div>
  );
}

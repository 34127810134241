import { FolderOutlined } from '@ant-design/icons';
import { DeskDossier, Widget } from 'client/api/backend/schemas';
import React from 'react';
import { convertDossierToListElement } from '../../dossiers-search/convert/convertDossierToListElement';
import {
  HighlightedDeskDossier,
  HighlightedDossierToolkitObject
} from '../objects/HighlightedDossierToolkitObject';

export function convertHighlightedDossierToListElement(
  dossier: DeskDossier,
  localDossier: HighlightedDeskDossier,
  widget: Widget
) {
  const element = convertDossierToListElement(dossier, widget);

  const notes = localDossier.highlighted?.notes?.trim() ?? '';

  element.annotations = notes;
  element.annotationsExtend = {
    before: notes ? 'Note:' : '',
    bold: true,
    divider: ''
  };

  element.object!.related = [
    HighlightedDossierToolkitObject.create({
      name: dossier.name ?? `Fascicolo #${dossier.id!}`,
      icon: <FolderOutlined />,
      id: dossier.id!,
      data: localDossier,
      sourceWidgetId: widget.id!
    })
  ];

  return element;
}

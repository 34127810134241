import Icon, {
  CustomIconComponentProps
} from '@ant-design/icons/lib/components/Icon';
import React from 'react';

const SvgDefaults = {
  focusable: false,
  viewBox: '6.4 6.4 89.6 89.6',
  fill: 'currentColor',
  width: '1em',
  height: '1em'
};
/**
 * Icone Personalizzate SVG   viewBox="0 0 102.4 102.4"
 *
 */
export const FvIconDam = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg {...SvgDefaults}>
        <path d="M39.7,84.02c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M90.89,84.02c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.53,1.24-3.37,1.93-5.33,2h0s-.3-.01-.3-.01c0-.01,0-.03,0-.04L53.28,11.75c-.04-.31-.3-.55-.62-.55h-6.02c-.35,0-.63,.28-.63,.63V90.57c0,.35,.28,.63,.63,.63h15.03c.35,0,.63-.28,.63-.63v-1.56h.3c2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,70.99c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,57.97c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,44.95c-1.97-.07-3.81-.75-5.35-2l-1.37-1.12c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.77,1.43-3.94,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.92,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.92,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,31.93c-1.97-.07-3.81-.75-5.35-2l-1.37-1.12c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.77,1.43-3.94,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.92,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.92,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
      </svg>
    )}
    {...props}
  />
);

export const FvIconDamSync = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg {...SvgDefaults}>
        <path d="M39.7,84.02c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,70.99c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,57.97c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,44.95c-1.97-.07-3.81-.75-5.35-2l-1.37-1.12c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.77,1.43-3.94,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.92,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.92,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,31.93c-1.97-.07-3.81-.75-5.35-2l-1.37-1.12c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.77,1.43-3.94,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.92,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.92,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M49.61,73.74c0-7.58,3.55-14.34,9.07-18.71l-5.4-43.28c-.04-.31-.31-.55-.62-.55h-6.02c-.35,0-.62,.28-.62,.63V90.57c0,.35,.28,.63,.62,.63h10.58c-4.68-4.36-7.61-10.57-7.61-17.46Z" />
        <path d="M91.97,73.71h-4.54c-.41,0-.74,.31-.75,.72-.17,3.25-1.53,6.3-3.85,8.62-2.47,2.47-5.85,3.86-9.35,3.86s-6.85-1.37-9.35-3.86c-.3-.3-.59-.62-.87-.95l2.18-1.69c.23-.18,.34-.47,.28-.75-.06-.28-.28-.51-.56-.57l-8.8-2.12c-.47-.11-.92,.25-.93,.73l-.06,9c0,.29,.16,.55,.42,.68,.26,.13,.57,.1,.8-.08l1.92-1.49c3.53,4.37,8.93,7.16,14.98,7.16,10.37,0,18.83-8.2,19.24-18.46,.02-.42-.33-.78-.75-.78Z" />
        <path d="M54.99,73.71h4.54c.41,0,.74-.31,.75-.72,.17-3.25,1.53-6.3,3.85-8.62,2.47-2.47,5.85-3.87,9.35-3.87s6.85,1.38,9.35,3.87c.3,.3,.59,.62,.87,.95l-2.18,1.69c-.23,.17-.34,.47-.28,.75,.06,.29,.28,.51,.56,.58l8.8,2.12c.47,.11,.92-.24,.93-.72l.06-9c0-.29-.16-.55-.42-.68-.26-.13-.57-.1-.8,.08l-1.92,1.49c-3.53-4.37-8.93-7.16-14.98-7.16-10.37,0-18.83,8.2-19.24,18.46-.02,.42,.33,.78,.75,.78Z" />
      </svg>
    )}
    {...props}
  />
);

export const FvIconDamSearch = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg {...SvgDefaults}>
        <path d="M39.7,84.02c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,70.99c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,57.97c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,44.95c-1.97-.07-3.81-.75-5.35-2l-1.37-1.12c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.77,1.43-3.94,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.92,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.92,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,31.93c-1.97-.07-3.81-.75-5.35-2l-1.37-1.12c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.77,1.43-3.94,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.92,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.92,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M49.69,70.29c0-7.1,3.44-13.42,8.73-17.37l-5.13-41.17c-.04-.31-.3-.55-.62-.55h-6.02c-.35,0-.63,.28-.63,.63V90.57c0,.35,.28,.63,.63,.63h15.03c.35,0,.63-.28,.63-.63v-.61c-7.43-3.43-12.6-10.95-12.6-19.67Z" />
        <path d="M93.34,89.07l-8.8-8.8c2.1-2.78,3.36-6.26,3.36-10.02,0-9.17-7.43-16.6-16.6-16.6s-16.6,7.43-16.6,16.6,7.43,16.61,16.6,16.61c3.38,0,6.51-1.01,9.13-2.74l8.93,8.93c.15,.15,.34,.22,.53,.22s.39-.07,.53-.22l2.92-2.92c.14-.14,.22-.33,.22-.53s-.08-.39-.22-.53Zm-22.05-8.26c-5.84,0-10.57-4.72-10.57-10.57s4.73-10.56,10.57-10.56,10.57,4.72,10.57,10.56-4.73,10.57-10.57,10.57Z" />
      </svg>
    )}
    {...props}
  />
);

export const FvIconDamPin = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg {...SvgDefaults}>
        <path d="M39.7,84.02c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,70.99c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,57.97c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,44.95c-1.97-.07-3.81-.75-5.35-2l-1.37-1.12c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.77,1.43-3.94,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.92,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.92,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,31.93c-1.97-.07-3.81-.75-5.35-2l-1.37-1.12c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.77,1.43-3.94,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.92,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.92,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M53.28,11.75c-.04-.31-.3-.55-.62-.55h-6.02c-.35,0-.63,.28-.63,.63V90.57c0,.35,.28,.63,.63,.63h15.03c.35,0,.63-.28,.63-.63v-6.54s0-.05,0-.08L53.28,11.75Z" />
        <path d="M79.45,53.99c-7.97,0-14.45,6.48-14.45,14.45,0,9.33,9.34,22.65,14.45,22.65s14.45-14.39,14.45-22.65-6.48-14.45-14.45-14.45Zm-.02,31.72c-2.48-1.5-9.18-10.4-9.18-17.28,0-5.07,4.13-9.2,9.2-9.2s9.2,4.13,9.2,9.2c0,6.75-7.04,15.81-9.22,17.28Z" />
        <circle cx="79.45" cy="68.01" r="5.28" />
      </svg>
    )}
    {...props}
  />
);

export const FvIconDamEye = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg {...SvgDefaults}>
        <path d="M39.7,84.02c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,70.99c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,57.97c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,44.95c-1.97-.07-3.81-.75-5.35-2l-1.37-1.12c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.77,1.43-3.94,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.92,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.92,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,31.93c-1.97-.07-3.81-.75-5.35-2l-1.37-1.12c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.77,1.43-3.94,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.92,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.92,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M49.78,81.44c-.96-2.06-1.12-4.35-.48-6.46v-.24l.48-1.03c2.73-5.76,6.15-9.55,9.73-12.01l-6.23-49.95c-.04-.31-.3-.55-.62-.55h-6.02c-.35,0-.63,.28-.63,.63V90.57c0,.35,.28,.63,.63,.63h10.06c-2.54-2.36-4.92-5.53-6.93-9.76Z" />
        <path d="M92.68,75.87h0s0-.02,0-.02c-4.16-8.8-10.6-13.33-19.18-13.33s-15.02,4.54-19.19,13.33h0c-.51,1.1-.51,2.35,0,3.44,4.17,8.79,10.61,13.32,19.19,13.32s15.02-4.54,19.19-13.33c.51-1.08,.51-2.34,0-3.42Zm-19.2,10.71c-5.86,0-10.13-2.83-13.31-9.01,3.17-6.18,7.45-9.01,13.31-9.01s10.13,2.83,13.31,9.01c-3.17,6.18-7.45,9.01-13.31,9.01Z" />
        <circle cx="73.53" cy="77.62" r="5.28" />
      </svg>
    )}
    {...props}
  />
);

export const FvIconDamData = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg {...SvgDefaults}>
        <path d="M39.7,84.02c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M90.89,84.02c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.53,1.24-3.37,1.93-5.33,2h0s-.3-.01-.3-.01c0-.01,0-.03,0-.04L53.28,11.75c-.04-.31-.3-.55-.62-.55h-6.02c-.35,0-.63,.28-.63,.63V90.57c0,.35,.28,.63,.63,.63h15.03c.35,0,.63-.28,.63-.63v-1.56h.3c2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,70.99c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,57.97c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,44.95c-1.97-.07-3.81-.75-5.35-2l-1.37-1.12c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.77,1.43-3.94,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.92,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.92,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,31.93c-1.97-.07-3.81-.75-5.35-2l-1.37-1.12c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.77,1.43-3.94,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.92,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.92,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M66.29,31.36h11.51c.42,0,.76,.34,.76,.76v4.53c0,.42-.34,.75-.75,.75h-11.51c-.42,0-.76-.34-.76-.76v-4.53c0-.42,.34-.75,.75-.75Z" />
        <path d="M66.29,18.53h24.15c.42,0,.76,.34,.76,.76v4.53c0,.42-.34,.75-.75,.75h-24.15c-.42,0-.76-.34-.76-.76v-4.53c0-.42,.34-.75,.75-.75Z" />
      </svg>
    )}
    {...props}
  />
);

export const FvIconDamCog = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg {...SvgDefaults}>
        <path d="M39.7,84.02c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,70.99c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,57.97c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,44.95c-1.97-.07-3.81-.75-5.35-2l-1.37-1.12c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.77,1.43-3.94,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.92,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.92,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,31.93c-1.97-.07-3.81-.75-5.35-2l-1.37-1.12c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.77,1.43-3.94,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.92,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.92,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M58.41,52.85l-5.13-41.1c-.04-.31-.3-.55-.62-.55h-6.02c-.35,0-.63,.28-.63,.63V90.57c0,.35,.28,.63,.63,.63h8.92c-4.6-4.6-7.46-10.95-7.46-17.95,0-8.35,4.06-15.77,10.3-20.4Z" />
        <path d="M89.74,75.5c.15-.85,.22-1.71,.22-2.58s-.08-1.73-.22-2.58l3.09-2.5c.48-.39,.65-1.01,.44-1.57l-.04-.12c-.85-2.25-2.12-4.34-3.76-6.16l-.08-.09c-.41-.45-1.06-.62-1.66-.42l-3.83,1.29c-1.41-1.1-2.99-1.96-4.7-2.57l-.74-3.79c-.11-.58-.6-1.04-1.22-1.15l-.13-.02c-2.46-.42-5.04-.42-7.5,0l-.13,.02c-.62,.11-1.1,.56-1.22,1.15l-.75,3.81c-1.69,.61-3.27,1.47-4.67,2.56l-3.86-1.3c-.59-.2-1.25-.03-1.66,.42l-.08,.09c-1.63,1.82-2.91,3.91-3.76,6.16l-.04,.12c-.21,.56-.04,1.18,.44,1.57l3.13,2.53c-.15,.84-.22,1.7-.22,2.55s.07,1.71,.22,2.55l-3.13,2.53c-.48,.39-.65,1.01-.44,1.57l.04,.12c.85,2.25,2.12,4.33,3.76,6.16l.08,.09c.41,.45,1.06,.62,1.66,.42l3.86-1.3c1.41,1.09,2.98,1.96,4.67,2.56l.75,3.81c.11,.58,.6,1.04,1.22,1.15l.13,.02c2.48,.42,5.02,.42,7.5,0l.13-.02c.62-.11,1.1-.56,1.22-1.15l.74-3.79c1.71-.61,3.3-1.47,4.7-2.57l3.83,1.29c.59,.2,1.25,.03,1.66-.42l.08-.09c1.64-1.83,2.91-3.91,3.76-6.16l.04-.12c.21-.55,.04-1.17-.44-1.56l-3.09-2.5Zm-16.38,6.08c-4.78,0-8.66-3.88-8.66-8.66s3.88-8.66,8.66-8.66,8.66,3.88,8.66,8.66-3.88,8.66-8.66,8.66Z" />
      </svg>
    )}
    {...props}
  />
);

export const FvIconDamAlert = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg {...SvgDefaults}>
        <path d="M39.7,84.02c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,70.99c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,57.97c-1.97-.07-3.81-.76-5.35-2l-1.37-1.11c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.77,1.43-3.93,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.11c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.93,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.93,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,44.95c-1.97-.07-3.81-.75-5.35-2l-1.37-1.12c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.77,1.43-3.94,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.92,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.92,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M39.7,31.93c-1.97-.07-3.81-.75-5.35-2l-1.37-1.12c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.77,1.43-3.94,2.13-6.23,1.99-1.97-.12-3.82-.96-5.35-2.21l-1.11-.9c-.12-.1-.29-.1-.4,0l-1.37,1.12c-1.54,1.25-3.38,1.94-5.35,2-.17,0-.31,.14-.31,.31v4.36c0,.18,.15,.33,.33,.33,2.38-.06,4.71-.73,6.75-1.92,.1-.06,.21-.06,.31,0,2.14,1.25,4.59,1.93,7.08,1.93s4.94-.68,7.08-1.93c.1-.06,.21-.06,.31,0,2.04,1.2,4.37,1.87,6.75,1.92,.18,0,.33-.15,.33-.33v-4.36c0-.17-.14-.31-.31-.31Z" />
        <path d="M58.41,52.85l-5.13-41.1c-.04-.31-.3-.55-.62-.55h-6.02c-.35,0-.63,.28-.63,.63V90.57c0,.35,.28,.63,.63,.63h8.92c-4.6-4.6-7.46-10.95-7.46-17.95,0-8.35,4.06-15.77,10.3-20.4Z" />
        <path d="M71.6,62.68h3.77c.42,0,.75,.34,.75,.75v10.57c0,.42-.34,.75-.75,.75h-3.77c-.42,0-.75-.34-.75-.75v-10.57c0-.42,.34-.75,.75-.75Z" />
        <path d="M73.48,77.78c-1.67,0-3.02,1.35-3.02,3.02s1.35,3.02,3.02,3.02,3.02-1.35,3.02-3.02-1.35-3.02-3.02-3.02Z" />
        <path d="M73.48,52.87c-11.25,0-20.38,9.12-20.38,20.38s9.12,20.38,20.38,20.38,20.38-9.12,20.38-20.38-9.12-20.38-20.38-20.38Zm10.14,30.52c-2.71,2.71-6.31,4.2-10.14,4.2s-7.43-1.49-10.14-4.2c-2.71-2.71-4.2-6.31-4.2-10.14s1.49-7.43,4.2-10.14c2.71-2.71,6.31-4.2,10.14-4.2s7.43,1.49,10.14,4.2c2.71,2.71,4.2,6.31,4.2,10.14s-1.49,7.43-4.2,10.14Z" />
      </svg>
    )}
    {...props}
  />
);

import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import {
  getToolkitObjectOfType,
  ToolkitObject
} from 'client/components/toolkit/objects/ToolkitObject';
import { yup } from 'common/validation/initYup';
import { InferType } from 'yup';
import { message } from 'antd';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { GroupOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { UserToolkitObject } from 'client/components/widget-repository/objects/users/UserToolkitObject';
import { OfficeToolkitObject } from 'client/components/widget-repository/objects/offices/OfficeToolkitObject';
import { DeskOffice, DeskUser } from 'client/api/backend/schemas';
import { WidgetAssignmentModelsProfile } from '../../WidgetAssignmentModelsProfile';
import {
  AssignmentModelToolkitObject,
  IAssignmentModel,
  IAssignmentModelItem
} from 'client/components/widget-repository/objects/assignment-models/AssignmentModelToolkitObject';
import { toolkitObjectToAssignmentModelItem } from 'client/components/widget-repository/objects/assignment-models/toolkitObjectToAssignmentModelItem';
import { AssignmentTypeSelectInput } from 'client/components/widget-repository/objects/assignments/AssignmentTypeSelectInput';
import { set } from 'client/core/data/set';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

export const WidgetActionAddAssignmentModel = createToolkitAction({
  name: 'Aggiungi a Modello di assegnazione',
  code: 'add-assignment-model',
  icon: <UsergroupAddOutlined />,
  arguments: [
    {
      name: 'Assegnatario',
      type: [UserToolkitObject, OfficeToolkitObject]
    },
    {
      name: 'Modello di Assegnazione',
      type: [AssignmentModelToolkitObject]
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    assigneeObject: ToolkitObject,
    modelObject: ToolkitObject | null
  ) {
    const assignee = getToolkitObjectOfType(assigneeObject, [
      UserToolkitObject.code,
      OfficeToolkitObject.code
    ])!;
    const model = AssignmentModelToolkitObject.get(modelObject);

    // Apriamo sempre il prompt perché ci serve la modalità di assegnazione
    ctx.deskContext.actionMessages.prompt({
      ctx,
      args: [assignee, model],
      action: WidgetActionAddAssignmentModel,
      confirmText: 'Aggiungi',
      schema: AddAssignmentModelSchema,
      onConfirm,
      form: (
        <>
          <FormFieldsContainer>
            <FormFieldItem
              size="large"
              component={AssignmentTypeSelectInput}
              widgetId={ctx.sourceWidget!.id!}
              label={null}
              name="typeId"
              placeholder="Modalità per l'assegnatario"
            />
          </FormFieldsContainer>
        </>
      )
    });
  }
});

/**
 * Crea e Aggiunge il modello di assegnazione al widget selezionato.
 */
async function onConfirm(
  ctx: IToolkitActionContext,
  values: AddAssignmentModelSchemaType,
  assignee: ToolkitObject<DeskUser | DeskOffice>,
  model: ToolkitObject<IAssignmentModel>
) {
  try {
    const widget = ctx.deskContext.widgets.find(
      w => w.id === model.sourceWidgetId
    );
    const profile = widget?.profile;
    const data = profile?.data as WidgetAssignmentModelsProfile;
    const index = data.models.findIndex(m => m.uuid === model.data.uuid);

    const item: IAssignmentModelItem = {
      assignee: toolkitObjectToAssignmentModelItem(assignee),
      typeId: values.typeId
    };

    await ctx.deskContext.updateWidget(
      set(widget!, `profile.data.models.${index}.assignees`, [
        ...data!.models[index].assignees,
        item
      ])
    );

    message.success('Assegnatario aggiunto al modello con successo');
  } catch (e) {
    message.error(
      getNetworkErrorMessage(e, "Impossibile aggiungere l'assegnatario")
    );
    console.error(e);
    return false;
  }
}

const AddAssignmentModelSchema = yup.object({
  typeId: yup.number().required().label("Modalità per l'assegnatario")
});

type AddAssignmentModelSchemaType = InferType<typeof AddAssignmentModelSchema>;

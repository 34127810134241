import React from 'react';
import styled from 'styled-components';
import { Button, Tooltip, TooltipProps } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import clx from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import { IconWrapper } from '../icon/IconWrapper';

const StyledButton = styled(Button)`
  border: none;
  width: 28px;
  height: 28px;

  &.has-children {
    width: auto;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    padding-top: 6.9px;
    padding-bottom: 4.9px;
    padding-left: 4px;
    padding-right: 4px;
    font-weight: 400;

    &.ant-btn-icon-only.ant-btn-sm > * {
      font-size: 12px;
    }
  }

  &.ant-btn-default {
    &:hover {
      background-color: #f2f4f5;
      &:active {
        background-color: ${props => props.theme.bodyBackground};
        transition: all 0.01s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
    &[ant-click-animating-without-extra-node='true']::after {
      display: none;
    }
  }
  .anticon > .anticon {
    line-height: 0;
    vertical-align: 0;
    font-size: 16px;
  }
  .uni {
    display: flex;
  }
`;

export interface ButtonIconProps extends Omit<ButtonProps, 'icon'> {
  icon: React.ReactNode;
  iconRight?: boolean;
  to?: LinkProps['to'];
  linkProps?: Omit<LinkProps, 'to'>;
  tooltip?: TooltipProps['title'];
  tooltipProps?: Omit<TooltipProps, 'title'>;
}

export function ButtonIcon(props: ButtonIconProps) {
  const {
    to,
    tooltip,
    icon,
    iconRight,
    linkProps,
    tooltipProps,
    children,
    className,
    ...buttonProps
  } = props;
  if (!icon) return null;

  const baseButton = (
    <StyledButton
      className={clx(className, 'ant-btn-icon-only', {
        'has-children': children != null
      })}
      {...buttonProps}
      icon={iconRight ? null : <IconWrapper icon={icon} />}
    >
      {children}
      {iconRight && <IconWrapper icon={icon} />}
    </StyledButton>
  );
  const button = tooltip ? (
    <Tooltip
      overlayClassName="button-icon-tooltip"
      title={tooltip}
      {...tooltipProps}
      children={baseButton}
    />
  ) : (
    baseButton
  );
  return to ? <Link to={to} {...linkProps} children={button} /> : button;
}

export const GridConstants = {
  Rows: 6,
  Cols: 6,
  /** Altezza e larghezza di default */
  WidgetDefaultWidth: 2,
  WidgetDefaultHeight: 3,
  /**
   * Altezza minima del widget
   */
  WidgetMinHeight: 2,
  /**
   * Larghezza minima del widget
   */
  WidgetMinWidth: 2
};

import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetDossierCreate } from './WidgetDossierCreate';
import { FolderAddOutlined } from '@ant-design/icons';
import { WidgetActionEditDossier } from './actions/WidgetActionEditDossier';
import { WidgetDossierEditProfile } from './WidgetDossierEditProfile';

export const WidgetDossierCreateType: IWidgetType = {
  code: 'dossier-create',
  name: 'Fascicolo',
  icon: <FolderAddOutlined />,
  component: WidgetDossierCreate,
  description: `Crea un nuovo fascicolo`,
  actions: [WidgetActionEditDossier],
  getTitle: widget => {
    const data = widget.profile?.data as WidgetDossierEditProfile | undefined;

    return data?.dossierId
      ? data?.dossierNumber
        ? `Modifica Fascicolo n.${data?.dossierNumber}`
        : 'Modifica Fascicolo'
      : 'Nuovo Fascicolo';
  }
};

WidgetRepository.register(WidgetDossierCreateType);

import {
  ArrowRightOutlined,
  BorderOuterOutlined,
  DragOutlined,
  ImportOutlined
} from '@ant-design/icons';
import { Over, useDndMonitor } from '@dnd-kit/core';
import { Badge, Card, Col, Row, Typography } from 'antd';
const { Text } = Typography;
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { IconWrapper } from 'client/ui/icon/IconWrapper';
import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import clx from 'classnames';
import { WidgetRepository } from 'client/components/widget-repository/WidgetRepository';
import { useWidgetRepository } from 'client/components/widget-repository/useWidgetRepository';

const iconHeight = 18;

const DragCard = styled(Card)`
  max-width: 300px;

  min-width: 200px;
  min-height: ${10 + 10 + iconHeight}px;

  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05), 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px,
    rgba(0, 0, 0, 0.14) 0px 24px 38px 3px, rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;

  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  cursor: -webkit-grabbing;
  cursor: grabbing;

  .ant-card-body {
    padding: 10px ${props => props.theme.cardPaddingBase};
    padding: 0;
  }

  &.dragging-over {
    background-color: rgba(255, 255, 255, 0.5);
  }
`;

const DragContainer = styled.div`
  min-height: ${iconHeight}px;
  display: flex;
  align-items: flex-start;
  padding: 10px ${props => props.theme.cardPaddingBase};
`;
const DragContainerOver = styled.div`
  padding: 10px ${props => props.theme.cardPaddingBase};
  padding-top: 5px;
  margin-top: -5px;
  display: flex;
  align-items: flex-start;
  border-top: 1px solid ${props => props.theme.bodyBackground};
  background-color: rgba(231, 236, 238, 0.7);
  border-bottom-left-radius: ${props => props.theme.cardRadius};
  border-bottom-right-radius: ${props => props.theme.cardRadius};
`;

const DragIconContainer = styled.div`
  width: ${iconHeight + 8}px;
`;
const DragTextContainer = styled.div`
  flex: 1 0 auto;
  min-width: 0;
  width: 0;
  display: flex;
  align-items: center;
`;

const DragCardText = styled(Text)`
  color: ${props => props.theme.primaryColor};
  margin-bottom: 0;
`;

const DragCardIcon = styled(IconWrapper)`
  font-size: ${iconHeight}px;
  width: ${iconHeight}px;
  height: ${iconHeight}px;
  margin-right: 4px;
`;

export interface ElementDragPreviewProps {
  object: ToolkitObject<any>;
  massiveSelected?: ToolkitObject<any>[];
}

export function ElementDragPreview(props: ElementDragPreviewProps) {
  const { object } = props;

  // Evidenziamo l'elemento se è "sopra" un nodo che può essere "droppato"
  const [over, setOver] = useState(null as Over | null);
  const widgetRepository = useWidgetRepository();

  useDndMonitor({
    onDragOver(event) {
      setOver(event.over);
    }
  });

  const overObject = over?.data.current?.object as ToolkitObject;
  const action = widgetRepository.findPrimaryAction(object, overObject);

  const hasValidOver = overObject && action;

  const currentObject = props.massiveSelected?.[0] ?? object;

  return (
    <Badge count={props.massiveSelected?.length ?? 0}>
      <DragCard
        bordered={false}
        className={clx({
          'dragging-over': over != null,
          'dragging-invalid': over != null && !hasValidOver
        })}
      >
        <DragContainer>
          <DragIconContainer>
            <DragCardIcon icon={currentObject.icon ?? <DragOutlined />} />
          </DragIconContainer>
          <DragTextContainer>
            <DragCardText strong>{currentObject.name} </DragCardText>
          </DragTextContainer>
        </DragContainer>
        {hasValidOver && (
          <DragContainerOver>
            <DragIconContainer>
              <DragCardIcon icon={<ArrowRightOutlined />} />
            </DragIconContainer>
            <DragTextContainer>
              <DragCardIcon
                icon={overObject.icon ?? <ImportOutlined rotate={180} />}
              />
              <DragCardText strong>{overObject.name}</DragCardText>
            </DragTextContainer>
          </DragContainerOver>
        )}
      </DragCard>
    </Badge>
  );
}

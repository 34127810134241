import {
  getDocumentaryStatisticsAssignmentsDetails,
  getGetDocumentaryStatisticsAssignmentsDetailsQueryKey
} from 'client/api/backend/documentary-statistics/documentary-statistics';
import { GetDocumentaryStatisticsAssignmentsDetailsParams } from 'client/api/backend/schemas';
import { ErrorType } from 'client/core/network/mutators/apiClient';
import {
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult
} from 'react-query';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

export const InfiniteStatisticsAssignmentsDetailsPageStart = 0;

export const useInfiniteStatisticsAssignmentsDetails = <
  TData = AsyncReturnType<typeof getDocumentaryStatisticsAssignmentsDetails>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryStatisticsAssignmentsDetailsParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      AsyncReturnType<typeof getDocumentaryStatisticsAssignmentsDetails>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryStatisticsAssignmentsDetailsQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryStatisticsAssignmentsDetails>
  > = ctx =>
    getDocumentaryStatisticsAssignmentsDetails({
      ...params!,
      pageNumber: ctx.pageParam ?? InfiniteStatisticsAssignmentsDetailsPageStart
    });

  const query = useInfiniteQuery<
    AsyncReturnType<typeof getDocumentaryStatisticsAssignmentsDetails>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

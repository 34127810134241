import * as React from 'react';
import Sider from 'antd/lib/layout/Sider';
import { Button, PageHeader, Space, Tag } from 'antd';
import styled from 'styled-components';
import { AdminLayout } from '../../../ui/layout/AdminLayout';
import { AdminMenu } from '../menu/AdminMenu';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { RouteComponentProps } from 'react-router';
import { ColumnProps } from 'antd/lib/table';
import { EndpointResponseDTO } from 'client/api/backend/schemas';
import { FixturesUsers, getFakeRole } from 'client/fixtures/FixturesUsers';
import { TableDataScroll } from 'client/ui/table/TableDataScroll';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { Link } from 'react-router-dom';
import { FixturesEndpoints } from 'client/fixtures/FixturesEndpoints';
import { LinkButton } from 'client/ui/link/LinkButton';
import {
  useDeleteEndpointsId,
  useGetEndpoints
} from 'client/api/backend/endpoints/endpoints';
import { EndpointColumnDeleteButton } from './EndpointColumnDeleteButton';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';

// interface EndpointResponseDTO {
//   documentaryID?: number;
//   id?: number;
//   name?: string;
//   url?: string;
// }
const columns: ColumnProps<EndpointResponseDTO>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '10%'
  },
  {
    title: 'ID Documentale',
    dataIndex: 'documentaryID',
    key: 'documentaryID',
    width: '15%'
  },
  {
    title: 'Client',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Url',
    dataIndex: 'url',
    key: 'url',
    render: (_, record) => (
      <a href={record.url} target="_blank">
        {record.url}
      </a>
    )
  },
  {
    dataIndex: 'actions',
    key: 'actions',
    width: '86px',
    render: (_, record) => (
      <Space>
        <EndpointColumnDeleteButton id={record.id!} name={record.name} />
        <Link to={`/admin/endpoints/${record.id}`}>
          <ButtonIcon icon={<EditOutlined />} />
        </Link>
      </Space>
    )
  }
];

export interface EndpointListPageProps extends RouteComponentProps {}

/**
 * Pagina degli Utenti
 */
export function EndpointListPage(props: EndpointListPageProps) {
  useBreadcrumbItem({
    path: props.location.pathname,
    title: 'Endpoints'
  });

  const { data, error, isLoading } = useGetEndpoints();

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco degli endpoint. Riprovare."
        error={error}
      />
    );
  }

  return (
    <AdminLayout menu={<AdminMenu />}>
      <PageHeader
        title="Endpoints"
        extra={[
          <LinkButton
            ghost
            type="primary"
            to="/admin/endpoints/create"
            icon={<PlusOutlined />}
          >
            Nuovo
          </LinkButton>
        ]}
      />
      <TableDataScroll<EndpointResponseDTO>
        loading={isLoading}
        dataSource={data ?? []}
        columns={columns}
      />
    </AdminLayout>
  );
}

import { appConfig } from 'client/core/appConfig';
import * as React from 'react';
import { AuthProvider } from 'react-oidc-context';
import { AuthMethod } from '../AuthMethods';
import { authSubject, AuthSubject } from '../SsoContextProvider';
import { oidcAuthConfig } from './oidcAuthConfig';
import { OpenIDConnectEventHandler } from './OpenIDConnectEventHandler';

export interface IOpenIDConnectProviderProps {
  children?: React.ReactNode;
  authSubject: AuthSubject;
}

export function OpenIDConnectProvider(props: IOpenIDConnectProviderProps) {
  if (appConfig.ssoMethod !== AuthMethod.OpenIDConnect) {
    return <>{props.children}</>;
  }

  return (
    <AuthProvider {...oidcAuthConfig}>
      <OpenIDConnectEventHandler authSubject={authSubject} />
      {props.children}
    </AuthProvider>
  );
}

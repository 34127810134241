import {
  ToolkitAction,
  ToolkitActionArgument
} from 'client/components/toolkit/actions/ToolkitAction';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { uniq, unzip } from 'lodash';
import { matchToolkitActionArgument } from './matchToolkitActionArgument';

/**
 * Restituisce l'insieme di ToolkitObject correlati all'oggetto
 * passato in ingresso.
 * In caso di array ritorna un insieme di array di oggetti e i suoi correlati,
 * in modo da poter verificare se l'azione è supportata per ogni oggetto
 *
 * Da usare solo per operazioni low-level sui toolkit object.
 */
// TODO spiegare meglio l'esempio riportato sotto nel codice e la divisione
// dei correlati fra gli array di ritorno
export function flattenToolkitRelatedObjects(
  object: ActionObjectInput
): ActionObjectInput[] {
  // Per un caso senza array
  if (!Array.isArray(object)) return [object, ...(object.related ?? [])];

  // Nel caso di array, per ogni oggetto, prendiamo l'oggetto stesso e
  // tutti i suoi oggetti correlati solo se il TIPO di correlato è presente
  // come correlato per tutti gli oggetti dell'array.
  //
  // Es 1: [assignment1 + document1, document2]
  //  -> ritorna solo document, ovvero [[document1, document2]]
  //
  // Es 2: [assignment1 + document1, assignment2 + document2]
  //  -> ritorna sia document che assignment,
  //     ovvero [[document1, document2], [assignment1, assignment2]]
  //
  // Questo ci permette di gestire casi complessi: per esempio, se l'azione
  // riguarda una assegnazione, `allows` potrebbe ritornare `true` per una di
  // tipo Competenza e `false` per una di tipo Conoscenza.
  const withRelated = object.map(o => [o, ...(o.related ?? [])]);
  const allowedTypes = uniq(withRelated.flatMap(o => o.map(o => o.type)));
  const onlyAllRelated = withRelated.map(o =>
    o.filter(o => allowedTypes.includes(o.type))
  );

  // Trasforma [['a1', 'd1'], ['a2', 'd2'], ['a3', 'd3']] in
  //  [["a1", "a2", "a3"], ["d1", "d2", "d3"]]
  return unzip(onlyAllRelated);
}

/**
 * Permette in ingresso a un'azione un singolo oggetto o un array di oggetti.
 */
export type ActionObjectInput = ToolkitObject<any> | ToolkitObject<any>[];

export function isToolkitActionSupportedBy(
  action: ToolkitAction<any>,
  object: Maybe<ActionObjectInput>
) {
  return isToolkitActionArgumentSupportedBy(action.arguments[0], object);
}

function isToolkitActionArgumentSupportedBy(
  argument: ToolkitActionArgument,
  object: Maybe<ActionObjectInput>
) {
  if (!object) return false;

  return flattenToolkitRelatedObjects(object).some(os =>
    matchToolkitActionArgument(argument, os)
  );
}

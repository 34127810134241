import { IDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { DeskWidgetsLogic } from 'client/components/schema/desk/logic/DeskWidgetsLogic';
import {
  createToolkitAction,
  ToolkitAction
} from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import {
  getToolkitObjectOfType,
  ToolkitObject
} from 'client/components/toolkit/objects/ToolkitObject';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { DamToolkitObject } from 'client/components/widget-repository/objects/dams/DamToolkitObject';
import { set } from 'client/core/data/set';
import { FvIconDamSync } from 'client/ui/icon/FvIconsDam';
import React from 'react';
import { WidgetDamMonitoringType } from '../WidgetDamMonitoring.type';
import { WidgetDamMonitoringProfile } from '../WidgetDamMonitoringProfile';

export const WidgetActionMonitorDam = createToolkitAction({
  name: 'Monitoraggio Diga',
  code: 'monitor-dam',
  icon: <FvIconDamSync />,
  arguments: [
    {
      type: [DamToolkitObject],
      name: 'Diga da Monitorare'
    },
    {
      type: [WidgetToolkitObject],
      allows: (object: ToolkitObject) =>
        WidgetToolkitObject.matches(object, WidgetDamMonitoringType),
      name: 'Widget Diga da Monitorare'
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    object: ToolkitObject,
    targetObject: ToolkitObject | null
  ) {
    const dam = DamToolkitObject.get(object)!;
    const target = WidgetToolkitObject.get(targetObject);

    const data: WidgetDamMonitoringProfile = {
      damId: dam.id as string,
      damDescription: `${dam.data.id} - ${dam.data.name}`
    };

    if (target) {
      const widget = ctx.deskContext.widgets.find(w => w.id === target.id)!;
      await ctx.deskContext.updateWidget(set(widget, 'profile.data', data));
      return;
    }

    await ctx.deskContext.cloneWidget(
      {
        type: 'floating',
        expanded: true,
        order: DeskWidgetsLogic.getFloatingNextOrder(
          ctx.deskContext.floatingWidgets
        )
      },
      WidgetDamMonitoringType,
      ctx.sourceWidget!,
      { data, quickClose: true }
    );
  }
});

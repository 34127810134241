import * as React from 'react';
import { Button, message, PageHeader } from 'antd';
import { AdminLayout } from '../../../ui/layout/AdminLayout';
import { AdminMenu } from '../menu/AdminMenu';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { RouteComponentProps } from 'react-router';
import {
  UserCreateDTO,
  UserProfileResponseDTO,
  UserUpdateDTO
} from 'client/api/backend/schemas';
import { FormikForm } from 'client/ui/form/FormikForm';
import { Formik } from 'formik';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextInput } from 'client/ui/form/input/TextInput';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { FormButtonsRow } from 'client/ui/form/button/FormButtonsRow';
import {
  useDeleteUsersId,
  useGetUsersId,
  usePostUsers,
  usePutUsers,
  getGetUsersIdQueryKey
} from 'client/api/backend/users/users';
import { queryClient } from 'client/core/network/queryClient';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { UserDto } from 'common/dto/UserDto';
import { RoleSelectInput } from '../profile/RoleSelectInput';
import { AdminFormContainer } from 'client/ui/form-container/AdminFormContainer';
import { ContentWrapper } from 'client/ui/content-wrapper/ContentWrapper';
import { PasswordInput } from 'client/ui/form/input/PasswordInput';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { UserSchema } from './UserSchema';
import { StateSelectInput } from './StateSelectInput';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { useMemo } from 'react';

// type UserProfileResponseDTO {
//   authorities?: Authority[];
//   cf?: string;
//   email?: string;
//   id?: number;
//   name?: string;
//   roleId?: number;
//   surname?: string;
//   username?: string;
// }

interface Params {
  id: string;
}

export interface UserPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina degli Utenti
 */
export function UserPage(props: UserPageProps) {
  useBreadcrumbItem({ path: '/admin/users', title: `Utenti` });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: 'Dettaglio Utente'
  });

  const id =
    props.match.params.id && props.match.params.id !== 'create'
      ? parseInt(props.match.params.id, 10)
      : null;

  const isEdit = id != null;

  const { data, error, isLoading } = useGetUsersId(id!, {
    query: { enabled: isEdit }
  });

  const user = useMemo(() => {
    if (!isEdit) {
      return {};
    }
    return data;
  }, [data, isEdit]);

  const createMutation = usePostUsers({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetUsersIdQueryKey(id!));
      }
    }
  });

  const updateMutation = usePutUsers({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetUsersIdQueryKey(id!));
      }
    }
  });

  const deleteMutation = useDeleteUsersId();

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile accedere all'utente"
        error={error}
      />
    );
  }

  if (!user) return <LoadingPage />;

  return (
    <AdminLayout menu={<AdminMenu />}>
      <PageHeader
        onBack={() => {
          props.history.push(`/admin/users`);
        }}
        title={
          !isEdit
            ? 'Nuovo Utente'
            : `Dettaglio Utente: ${user.name} ${user.surname}`
        }
        extra={[
          id && (
            <ButtonDeleteConfirm
              placement="left"
              title={
                <span>
                  Sei sicuro di voler eliminare l'Utente{' '}
                  <b>
                    {user.name} {user.surname}
                  </b>
                  ?
                </span>
              }
              cancelText="Annulla"
              onConfirm={async () => {
                try {
                  await deleteMutation.mutateAsync({
                    id: id
                  });
                  message.success('Utente eliminato con successo. ');
                  props.history.push(`/admin/users`);
                } catch (e) {
                  message.error(
                    getNetworkErrorMessage(
                      e,
                      "Errore durante l'eliminazione dell'utente. Riprovare."
                    )
                  );
                }
              }}
              buttonType="default"
            >
              <Button danger>Elimina Utente</Button>
            </ButtonDeleteConfirm>
          )
        ]}
      />
      <ContentWrapper>
        <AdminFormContainer>
          <FormikAugmented<UserCreateDTO | UserUpdateDTO>
            validationSchema={UserSchema}
            validationContext={{ isEdit }}
            initialValues={user}
            enableReinitialize
            onSubmit={async (values, formik) => {
              try {
                const password =
                  values.password && values.password.length > 0
                    ? values.password
                    : undefined;

                if (!isEdit) {
                  const result = (await createMutation.mutateAsync({
                    data: { ...values, password }
                  })) as UserDto;

                  message.success('Utente creato con successo. ');
                  props.history.push(`/admin/users/${result.id}`);
                } else {
                  await updateMutation.mutateAsync({
                    data: { id, ...values, password }
                  });
                  message.success('Utente aggiornato con successo. ');
                }
              } catch (e) {
                message.error(
                  getNetworkErrorMessage(
                    e,
                    `Si è verificato un errore durante la ${
                      isEdit ? 'modifica' : 'creazione'
                    } dell'utente.`
                  )
                );
                console.error(e);
              }
            }}
          >
            <FormikForm editable layout="vertical">
              <FormFieldsContainer>
                <FormFieldItem
                  label="Login"
                  placeholder="Login"
                  name="username"
                  component={TextInput}
                  size="large"
                />
                <FormFieldItem
                  label="Password"
                  placeholder="Password"
                  name="password"
                  component={PasswordInput}
                  size="large"
                />
                <FormFieldItem
                  label="Ruolo"
                  placeholder="Ruolo"
                  name="roleId"
                  component={RoleSelectInput}
                  size="large"
                />
                <FormFieldItem
                  label="Nome"
                  placeholder="Nome"
                  name="name"
                  component={TextInput}
                  size="large"
                />
                <FormFieldItem
                  label="Cognome"
                  placeholder="Cognome"
                  name="surname"
                  component={TextInput}
                  size="large"
                />
                <FormFieldItem
                  label="Email"
                  placeholder="Email"
                  name="email"
                  component={TextInput}
                  size="large"
                />
                <FormFieldItem
                  label="Codice Fiscale"
                  placeholder="Codice Fiscale"
                  name="cf"
                  component={TextInput}
                  size="large"
                />
                <FormFieldItem
                  label="Stato"
                  placeholder="Stato"
                  name="enabled"
                  component={StateSelectInput}
                  size="large"
                />
              </FormFieldsContainer>

              <FormButtonsRow>
                <Button
                  size="large"
                  onClick={() => {
                    props.history.push(`/admin/users`);
                  }}
                >
                  Annulla
                </Button>
                <FormikSendButton as={Button} title="Salva" size="large">
                  Salva
                </FormikSendButton>
              </FormButtonsRow>
            </FormikForm>
          </FormikAugmented>
        </AdminFormContainer>
      </ContentWrapper>
    </AdminLayout>
  );
}

import {
  getGetDocumentaryAssignmentsMonitoringDetailsQueryKey,
  getGetDocumentaryAssignmentsMonitoringQueryKey,
  getGetDocumentaryAssignmentsQueryKey
} from 'client/api/backend/documentary-assignments/documentary-assignments';
import {
  getGetDocumentaryStatisticsAssignmentsDetailsQueryKey,
  getGetDocumentaryStatisticsAssignmentsQueryKey
} from 'client/api/backend/documentary-statistics/documentary-statistics';
import { queryClient } from 'client/core/network/queryClient';

export function invalidateAssignmentQueries() {
  queryClient.invalidateQueries({
    predicate: query => {
      return Boolean(
        query.queryKey &&
          Array.isArray(query.queryKey) &&
          isQueryKeyOneOfAssignments
      );
    }
  });
}

function isQueryKeyOneOfAssignments(queryKey: unknown) {
  const assignmentsQueryKey = getGetDocumentaryAssignmentsQueryKey()[0];
  const statisticsQueryKey =
    getGetDocumentaryStatisticsAssignmentsQueryKey()[0];
  const statisticsDetailsQueryKey =
    getGetDocumentaryStatisticsAssignmentsDetailsQueryKey()[0];
  const monitorAssignmentsQueryKey =
    getGetDocumentaryAssignmentsMonitoringQueryKey()[0];
  const monitorAssignmentsDetailsQueryKey =
    getGetDocumentaryAssignmentsMonitoringDetailsQueryKey()[0];

  return (
    queryKey === assignmentsQueryKey ||
    queryKey === statisticsQueryKey ||
    queryKey === statisticsDetailsQueryKey ||
    queryKey === monitorAssignmentsQueryKey ||
    queryKey === monitorAssignmentsDetailsQueryKey
  );
}

import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { CheckOutlined } from '@ant-design/icons';
import { AssignmentToolkitObject } from 'client/components/widget-repository/objects/assignments/AssignmentToolkitObject';
import { executeConcludeAssignment } from './executeConcludeAssignment';
import { message } from 'antd';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { isAssignmentStale } from 'client/components/widget-repository/objects/assignments/isAssignmentStale';
import { isAssignmentActionAllowed } from 'client/components/widget-repository/objects/assignments/isAssignmentActionAllowed';
import { DeskAssignment } from 'client/api/backend/schemas';

export const WidgetActionConcludeAssignment = createToolkitAction({
  name: 'Concludi',
  code: 'conclude-assignment',
  icon: <CheckOutlined />,
  arguments: [
    {
      name: 'Assegnazione da Concludere',
      type: [AssignmentToolkitObject],
      isArray: true,
      allows: (obj: ToolkitObject) =>
        !isAssignmentStale(obj.data) && isAssignmentActionAllowed(obj)
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    assignmentObjects: ToolkitObject<DeskAssignment>[] | ToolkitObject
  ) {
    try {
      await executeConcludeAssignment(assignmentObjects);

      message.success('Conclusione effettuata con successo');
    } catch (e: any) {
      message.error(
        getNetworkErrorMessage(e, 'Errore durante la conclusione.')
      );
      console.error(e);
      return false;
    }
  }
});

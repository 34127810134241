import { DeskDossier } from 'client/api/backend/schemas';

export function formatDossierNames(dossiers?: DeskDossier[]) {
  if (!dossiers) return undefined;

  return dossiers.map(dossier => convertDossierName(dossier));
}

export function convertDossierName(dossier?: DeskDossier): string {
  if (!dossier) return '';

  if (!dossier.number) return dossier.name ?? '';

  return `${dossier.number} - ${dossier.name ?? ''}`;
}

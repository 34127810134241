import { Button, Collapse, Tabs } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import {
  useGetEndpoints,
  useGetEndpointsIdTemplate
} from 'client/api/backend/endpoints/endpoints';
import {
  ConfigurationField,
  ConfigurationResponse,
  Widget
} from 'client/api/backend/schemas';
import {
  getGetWidgetsQueryKey,
  usePostWidgetsIdConfiguration
} from 'client/api/backend/widgets/widgets';
import { queryClient } from 'client/core/network/queryClient';
import { FormButtonsRightContainer } from 'client/ui/form/button/FormButtonsRightContainer';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { useFormikFieldChange } from 'client/ui/form/hooks/useFormikFieldChange';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { yup } from 'common/validation/initYup';
import { Formik, useFormikContext } from 'formik';
import { isEqual, pick, sortBy } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormWidgetConfigurationFieldItem } from './fields/FormWidgetConfigurationFieldItem';
import { WidgetConfigurationProfileForm } from './profile/WidgetConfigurationProfileForm';

const { TabPane } = Tabs;

export interface IWidgetConfigurationFormProps {
  widget: Widget;
}

interface FormProps {
  widget: Widget;
  reloadCount: number;
  incrementReloadCount: () => void;
}

function Form(props: FormProps) {
  const { widget } = props;
  const formik = useFormikContext<any>();
  const endpoints = useGetEndpoints();
  const endpointTemplate = useGetEndpointsIdTemplate(formik.values.endpointId, {
    query: { enabled: formik.values.endpointId != null }
  });

  const template = endpointTemplate.data;

  const dependencies = template?.fields?.map((f, i) => `fields.${i}.value`);

  useEffect(() => {
    // Auto-selezione endpoint di default
    if (
      !formik.values.endpointId &&
      endpoints?.data &&
      endpoints.data.length >= 1
    ) {
      const defaultEndpoint = endpoints.data.find(e => e.preselected);
      const endpointId = defaultEndpoint?.id ?? endpoints.data[0].id;

      formik.setFieldValue('endpointId', endpointId);
      setTimeout(() => {
        formik.submitForm();
      }, 0);
    }
  }, [formik.values.endpointId, endpoints?.data]);

  useEffect(() => {
    // Ordinamento dei campi in base all'ordine del template
    const sortedField = sortBy(
      formik.values.fields as ConfigurationField[],
      field =>
        template?.fields?.findIndex(
          templateField => templateField.type === field.type
        )
    );

    if (!isEqual(sortedField, formik.values.fields)) {
      formik.setFieldValue('fields', sortedField);
    }
  }, [template, formik.values.fields]);

  return (
    <FormikForm editable helpInTooltips layout="vertical">
      <FormFieldsContainer>
        <FormFieldItem
          component={SelectInput}
          loading={endpoints.isLoading}
          options={endpoints.data?.map(endpoint => ({
            label: endpoint.name,
            value: endpoint.id!
          }))}
          name="endpointId"
          onChange={() => {
            formik.setFieldValue(`fields`, []); // Reset dei campi successivi
            formik.submitForm();
          }}
          label="Endpoint"
          size="large"
          placeholder="Endpoint"
        />
        {formik.values.endpointId &&
          template?.fields?.map((field, i) => (
            <FormWidgetConfigurationFieldItem
              template={template}
              key={field.type}
              dependencies={dependencies}
              field={field}
              index={i}
              widget={widget}
              reloadCount={props.reloadCount}
              incrementReloadCount={props.incrementReloadCount}
            />
          ))}
      </FormFieldsContainer>

      <FormButtonsRightContainer>
        <FormikSendButton as={Button} title="Salva" size="large">
          Salva
        </FormikSendButton>
      </FormButtonsRightContainer>
    </FormikForm>
  );
}

/**
 * Contenitore dei form generali di configurazione dei Widget
 */
export function WidgetConfigurationForm(props: IWidgetConfigurationFormProps) {
  const { widget } = props;

  /**
   * Gestisce con un aggiornamento lato client lo stato del Widget modificato
   * all'interno dell'elenco dei Widget, evitando di aggiornare tutti i widget
   * con una chiamata API
   */
  const configurationApi = usePostWidgetsIdConfiguration({
    mutation: {
      onSuccess: (data, variables) => {
        if (typeof data !== 'object') return;

        // Se nella risposta ci viene fornito il template, vuol dire che è la
        // chiamata "preparatoria", quindi non è possibile aggiornare i dati
        // in locale (ma solo lo stato del form).
        // Se non ci sono fields nel template, vuol dire che è la chiamata
        // "definitiva", quindi possiamo aggiornare i dati in locale
        if (
          data.template &&
          data.template.fields?.length &&
          data.template.fields.length > 0
        )
          return;

        console.log('onSuccess', data, variables);

        queryClient.setQueryData(
          getGetWidgetsQueryKey(),
          (widgets: Widget[] | undefined) =>
            (widgets ?? []).map(w =>
              w.id === variables.id
                ? {
                    // Aggiorniamo il Widget con i dati passati all'API. Dato
                    // che non restituisce esattamente la struttura Partial<Widget>,
                    // provvediamo a "ricostruirla" manualmente
                    ...w,
                    configuration: data.configuration,
                    endpointId: variables.data.endpointId,
                    documentaryCode: data.documentaryCode
                  }
                : w
            )
        );
      }
    }
  });
  const [reloadCount, setReloadCount] = useState(0);

  const hasEndpoint = widget.isConfigured;

  return (
    <div>
      <FormikAugmented
        initialValues={widget}
        enableReinitialize
        onSubmit={async (values, formik) => {
          const result = (await configurationApi.mutateAsync({
            id: widget.id!,
            data: {
              ...pick(values, ['fields', 'endpointId']),
              settings: JSON.stringify(widget.profile)
            }
          })) as ConfigurationResponse;

          // TODO: Al momento la ricerca si fonda sulla struttura che abbiamo
          // di default (generata da orval) in cui i `params` della query
          // sono sempre il secondo elemento dell'array di "queryKey".
          await queryClient.invalidateQueries({
            predicate: query => {
              return Boolean(
                query.queryKey &&
                  Array.isArray(query.queryKey) &&
                  typeof query.queryKey[1] === 'object' &&
                  query.queryKey[1].widgetId === widget.id!
              );
            }
          });

          // Effettuiamo il trigger per ricaricare le API dei singoli
          // fields.
          setReloadCount(count => count + 1);
        }}
        validationSchema={yup.object()}
        validationContext={{}}
      >
        <Collapse ghost defaultActiveKey={hasEndpoint ? [] : ['endpoint']}>
          <CollapsePanel header="Connessione" key="endpoint">
            <Form
              widget={widget}
              reloadCount={reloadCount}
              incrementReloadCount={() => setReloadCount(c => c + 1)}
            />
          </CollapsePanel>
        </Collapse>
      </FormikAugmented>
      {/** Altre opzioni di profilo */}
      <WidgetConfigurationProfileForm widget={widget} />
    </div>
  );
}

import { set as _set } from 'lodash/fp';
import { Function, Object, String } from 'ts-toolbelt';

/**
 * Effettua un set annidato preservando i tipi
 */
export function set<Obj extends object, Path extends string>(
  object: Obj,
  path: Function.AutoPath<Obj, Path>,
  value: Object.Path<Obj, String.Split<Path, '.'>>
): Obj {
  return _set(path, value, object);
}

import {
  GetDocumentaryDocumentsSearchParams,
  GetDocumentaryDossiersSearchParams
} from 'client/api/backend/schemas';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { yup } from 'common/validation/initYup';
import * as React from 'react';
import * as WidgetFilterSections from 'client/components/toolkit/components/filter/WidgetFilterSections';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { Button, Space } from 'antd';
import { DocumentProtocolModeInput } from 'client/components/widget-repository/objects/documents/DocumentProtocolModeInput';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { useState } from 'react';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { DynamicDateRanges } from 'client/components/toolkit/components/filter/dynamic/DynamicDateRanges';
import { DocumentSearchModeInput } from 'client/components/widget-repository/objects/documents/DocumentSearchModeInput';
import { RegistrySelectInput } from 'client/components/widget-repository/objects/registries/RegistrySelectInput';
import { getIn, useFormikContext } from 'formik';
import { DocumentSearchMode } from 'client/components/widget-repository/objects/documents/DocumentSearchMode';

export interface IWidgetDocumentSearchFilterFormProps {
  widgetId: number;
  /** Di base è `profile.filters`. */
  prefix: string;
  filters: any;
}

export function WidgetDocumentsSearchFilterForm(
  props: IWidgetDocumentSearchFilterFormProps
) {
  const { filters, prefix } = props;

  const formik = useFormikContext();
  const searchModeValue = getIn(formik.values, `${prefix}.searchMode`) as
    | string
    | undefined;

  return (
    <>
      <FormFieldsContainer>
        <FormFieldItem
          size="large"
          component={DocumentSearchModeInput}
          label={'Modalità di ricerca documenti'}
          name={`${prefix}.searchMode`}
          placeholder="Fascicolati"
        />
      </FormFieldsContainer>
      {searchModeValue === DocumentSearchMode.Registry && (
        <FormFieldsContainer>
          <FormFieldItem
            size="large"
            component={RegistrySelectInput}
            widgetId={props.widgetId}
            label={'Registro'}
            name={`${prefix}.registryId`}
            placeholder="Registro"
          />
        </FormFieldsContainer>
      )}
      <FormFieldsContainer>
        <FormFieldItem
          size="large"
          component={TextInput}
          label={'Oggetto'}
          name={`${prefix}.subject`}
          placeholder="Oggetto"
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormFieldItem
          size="large"
          component={TextInput}
          label={'Corrispondenti'}
          name={`${prefix}.correspondent`}
          placeholder="Corrispondenti"
        />
      </FormFieldsContainer>
      <FormFieldsContainer gutter={8}>
        <FormFieldItem
          size="large"
          component={DocumentProtocolModeInput}
          allowClear
          widgetId={props.widgetId}
          label={'Modalità'}
          name={`${prefix}.mode`}
          placeholder="Modalità"
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={2} gutter={8}>
        <FormFieldItem
          size="large"
          component={SelectInput}
          allowClear
          label={'A partire da'}
          name={`${prefix}.dynamicDateFrom`}
          placeholder="Da.."
          options={DynamicDateRanges}
        />
        <FormFieldItem
          size="large"
          component={SelectInput}
          allowClear
          label={'Fino a'}
          name={`${prefix}.dynamicDateTo`}
          placeholder="A.."
          options={DynamicDateRanges}
        />
      </FormFieldsContainer>
    </>
  );
}

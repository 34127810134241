import React from 'react';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import { useGetDocumentaryAssignmentsFlows } from 'client/api/backend/documentary-assignments/documentary-assignments';
import { getIn, useFormikContext } from 'formik';
import { logger } from 'client/core/logger/logger';
import { Alert } from 'antd';
import { HelpText } from 'client/ui/help-text/HelpText';

interface Props extends SelectInputProps<any> {
  widgetId: number;
  typeFieldName?: string;
  typeId?: string;
}

export function AssignmentStateSelectInput(props: Props) {
  const {
    widgetId,
    typeFieldName,
    typeId: externalTypeId,
    ...otherProps
  } = props;

  if (!typeFieldName && !externalTypeId) {
    logger.warn('AssignmentStateSelectInput: typeFieldName or typeId should be provided'); // prettier-ignore
  }

  const formik = useFormikContext();

  /**
   * Se viene passato un typeId esterno, lo usiamo altrimenti
   * cerchiamo di prendere il valore dal formik
   */
  const typeId = externalTypeId
    ? externalTypeId
    : typeFieldName
    ? (getIn(formik.values, typeFieldName) as string | undefined)
    : undefined;

  const types = useGetDocumentaryAssignmentsFlows({
    widgetId: props.widgetId
  });

  const states = types.data?.find(t => t.value === typeId)?.children ?? [];

  const options = states.map(t => ({ label: t.label, value: t.value! })) ?? [];

  return (
    <>
      <SelectInput
        allowClear
        showSearch
        options={options}
        {...otherProps}
        disabled={!typeId}
      />
      {!typeId ? (
        <HelpText>
          Per poter selezionare lo stato è necessario prima selezionare il tipo.
        </HelpText>
      ) : null}
    </>
  );
}

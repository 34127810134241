import { Reducer } from 'redux';
import { createAction, ActionTypes } from 'client/core/store/actions';
import { useSelector, shallowEqual } from 'react-redux';
import { RootState } from 'client/core/store/RootReducer';
import { Panel } from 'client/api/backend/schemas';
import { DraggedToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';

const initialState = {
  isEditable: false,
  isDragging: false,
  isFullScreen: false
};

export const DeskActions = {
  select: createAction('@desk/select', (deskId: number) => ({
    selectedId: deskId
  })),
  selectIfNone: createAction('@desk/selectIfNone', (deskId: number) => ({
    selectedId: deskId
  })),
  editable: createAction('@desk/editable', (isEditable?: boolean) => ({
    isEditable
  })),
  draggingItem: createAction(
    '@desk/draggingItem',
    (isDragging: boolean, item?: DraggedToolkitObject) => ({
      isDragging,
      item
    })
  ),
  fullScreen: createAction('@desk/fullScreen', (isFullScreen?: boolean) => ({
    isFullScreen
  })),
  reset: createAction('@desk/reset')
};

export interface DeskState {
  isEditable?: boolean;
  isDragging?: boolean;
  draggingItem?: DraggedToolkitObject;
  isFullScreen?: boolean;
  selectedId?: number;
}

export const DeskReducer: Reducer<
  DeskState,
  ActionTypes<typeof DeskActions>
> = (state = initialState, action) => {
  switch (action.type) {
    case '@desk/select': {
      return { ...state, selectedId: action.payload.selectedId };
    }

    case '@desk/selectIfNone': {
      if (state.selectedId) return state;
      return { ...state, selectedId: action.payload.selectedId };
    }

    case '@desk/editable': {
      const { isEditable } = action.payload;
      return {
        ...state,
        isEditable: isEditable == null ? !state.isEditable : isEditable
      };
    }

    case '@desk/draggingItem': {
      const { isDragging, item } = action.payload;
      return {
        ...state,
        isDragging: isDragging,
        draggingItem: item
      };
    }

    case '@desk/fullScreen': {
      const { isFullScreen } = action.payload;
      return {
        ...state,
        isFullScreen: isFullScreen == null ? !state.isFullScreen : isFullScreen
      };
    }

    case '@desk/reset': {
      return { ...initialState };
    }

    default:
      return state;
  }
};

/**
 * Hook per ...
 */
export const useDesk = () =>
  useSelector((state: RootState) => state.desk, shallowEqual);

import * as React from 'react';
import styled from 'styled-components';

export const CardBodyWrapper = styled.div`
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 0;
  border-bottom-left-radius: ${props => props.theme.cardRadius};
  border-bottom-right-radius: ${props => props.theme.cardRadius};
`;

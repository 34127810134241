import {
  GetDocumentaryDocumentsSearchParams,
  GetDocumentaryDossiersSearchParams,
  Widget
} from 'client/api/backend/schemas';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { yup } from 'common/validation/initYup';
import * as React from 'react';
import * as WidgetFilterSections from 'client/components/toolkit/components/filter/WidgetFilterSections';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { Button, Space } from 'antd';
import { DocumentProtocolModeInput } from 'client/components/widget-repository/objects/documents/DocumentProtocolModeInput';
import { NumberInput } from 'client/ui/form/input/NumberInput';
import { WidgetFilterDrawer } from 'client/components/toolkit/components/filter/WidgetFilterDrawer';
import { renderWidgetFilterActiveTags } from 'client/components/toolkit/components/filter/renderWidgetFilterActiveTags';
import { useState } from 'react';
import { DocumentSearchModeInput } from 'client/components/widget-repository/objects/documents/DocumentSearchModeInput';
import { RegistrySelectInput } from 'client/components/widget-repository/objects/registries/RegistrySelectInput';
import { WidgetDocumentsSearchSchema } from './WidgetDocumentsSearchSchema';

export interface IWidgetDocumentSearchFormProps {
  widget: Widget;
  search: Partial<GetDocumentaryDocumentsSearchParams>;
  setSearch: (search: Partial<GetDocumentaryDocumentsSearchParams>) => void;
}

export function WidgetDocumentsSearchForm(
  props: IWidgetDocumentSearchFormProps
) {
  const { widget, search, setSearch } = props;
  const [open, setOpen] = useState(false);

  return (
    <FormikAugmented
      validationSchema={WidgetDocumentsSearchSchema}
      validationContext={{}}
      initialValues={search}
      // validate={schemaToValidator()}
      onReset={() => {
        setSearch({});
        setOpen(false);
      }}
      onSubmit={(values: Partial<GetDocumentaryDocumentsSearchParams>) => {
        setSearch(values);
        setOpen(false);
      }}
    >
      <WidgetFilterDrawer
        title="Ricerca"
        filters={renderWidgetFilterActiveTags({
          search,
          filters: widget.profile?.filters,
          onReset: () => setSearch({})
        })}
        open={[open, setOpen]}
      >
        <FormikForm layout="vertical" editable helpInTooltips>
          <WidgetFilterSections.BodyWrapper>
            <FormFieldsContainer>
              <FormFieldItem
                size="large"
                component={DocumentSearchModeInput}
                label={'Modalità di ricerca documenti'}
                name={`searchMode`}
                placeholder="Fascicolati"
              />
            </FormFieldsContainer>
            <FormFieldsContainer>
              <FormFieldItem
                size="middle"
                component={RegistrySelectInput}
                widgetId={widget.id!}
                label={null}
                name="registryId"
                placeholder="Registro"
              />
            </FormFieldsContainer>
            <FormFieldsContainer>
              <FormFieldItem
                size="middle"
                component={TextInput}
                label={null}
                name="subject"
                placeholder="Oggetto"
              />
            </FormFieldsContainer>
            <FormFieldsContainer>
              <FormFieldItem
                size="middle"
                component={TextInput}
                label={null}
                name="correspondent"
                placeholder="Corrispondenti"
              />
            </FormFieldsContainer>
            <FormFieldsContainer gutter={8}>
              <FormFieldItem
                size="middle"
                component={DocumentProtocolModeInput}
                widgetId={props.widget.id!}
                label={null}
                name="mode"
                placeholder="Modalità"
              />
            </FormFieldsContainer>
            <FormFieldsContainer columns={2} gutter={8}>
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                type="unix"
                label={null}
                name="dateFrom"
                placeholder="Dal"
              />
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                type="unix"
                label={null}
                name="dateTo"
                placeholder="Al"
              />
            </FormFieldsContainer>
            <FormFieldsContainer columns={2} gutter={8}>
              <FormFieldItem
                size="middle"
                component={NumberInput}
                label={null}
                name="protocolNumberFrom"
                placeholder="N. Protocollo Dal"
              />
              <FormFieldItem
                size="middle"
                component={NumberInput}
                label={null}
                name="protocolNumberTo"
                placeholder="Al"
              />
            </FormFieldsContainer>
          </WidgetFilterSections.BodyWrapper>
          <WidgetFilterSections.ButtonsWrapper>
            <Space>
              <Button htmlType="reset">Pulisci</Button>
              <Button htmlType="submit" type="primary">
                Filtra
              </Button>
            </Space>
          </WidgetFilterSections.ButtonsWrapper>
        </FormikForm>
      </WidgetFilterDrawer>
    </FormikAugmented>
  );
}

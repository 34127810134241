import { useGetDocumentaryAssignmentsPriorities } from 'client/api/backend/documentary-assignments/documentary-assignments';
import { RadioInput, RadioInputProps } from 'client/ui/form/input/RadioInput';
import React from 'react';

interface Props extends RadioInputProps<any> {
  widgetId: number;
}

export function AssignmentPriorityRadioInput(props: Props) {
  const { ...otherProps } = props;

  const priorityResult = useGetDocumentaryAssignmentsPriorities({
    widgetId: props.widgetId
  });

  return (
    <RadioInput
      options={priorityResult.data?.map(priority => {
        return { value: priority.value ?? '', label: priority.label ?? '' };
      })}
      optionType="button"
      buttonStyle="solid"
      style={{ width: '100%' }}
      {...otherProps}
    />
  );
}

const PriorityOptions = [
  { label: 'Alta', value: 1 },
  { label: 'Media', value: 3 },
  { label: 'Bassa', value: 4 }
];

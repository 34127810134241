import React, { useState } from 'react';
import styled, { css } from 'styled-components';

const BaseMenu = styled.div`
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  padding: 8px;
  padding-bottom: 5px;
  border-bottom: solid 3px transparent;
  border-left: solid 1px ${props => props.theme.borderColorBase};
  &:first-child {
    border-left: none;
    border-bottom-left-radius: ${props => props.theme.cardRadius};
  }
  &:last-child {
    border-bottom-right-radius: ${props => props.theme.cardRadius};
  }
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: ${props => props.theme.borderColorSplit};
    &:focus,
    &:active {
      outline: none;
      background-color: none;
    }
  }
`;
const OpenedMenu = styled(BaseMenu)<{ opened?: boolean }>`
  ${props =>
    props.opened &&
    css`
      &:first-child,
      &:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    `}
`;
const MenuLineCol = styled(OpenedMenu)<{ selected?: boolean }>`
  ${props =>
    props.selected &&
    css`
      border-bottom: solid 3px ${props => props.theme.primaryColor};
      transition: all 0.15s;
    `}
`;

interface MenuColProps extends React.HTMLAttributes<HTMLDivElement> {
  opened?: boolean;
  selected?: boolean;
}

export function MenuCol(props: MenuColProps) {
  return <MenuLineCol {...props} />;
}

import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  ChartDataset,
  ScaleOptionsByType,
  CartesianScaleTypeRegistry
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { _DeepPartialObject } from 'chart.js/types/utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ChartWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ChartLimit = styled.div`
  padding: 12px;
  flex: 0 1 auto;
  min-height: 0;
  min-width: 0;
  width: 100%;
  overflow: hidden;
  canvas {
    margin: 0 auto;
  }
`;
const ChartFalse = styled.div`
  width: 100%;
  height: 250px;
`;

interface MonitorLineChartProps {
  labels?: string[];
  datasets?: ChartDataset<'line', string[]>[];
  scales?: any;
  width: number;
  height: number;
}

export function MonitorLineChart(props: MonitorLineChartProps) {
  const [reload, setReload] = React.useState(true);

  const { labels, datasets, scales, width, height } = props;

  useEffect(() => {
    setReload(true);
    const isTooSmall = height < 50 || width < 50;
    setTimeout(() => {
      setReload(isTooSmall);
    }, 50);
  }, [height, width]);


  return (
    <ChartWrapper>
      <ChartLimit>
        {reload ? (
          <ChartFalse />
        ) : (
          <Line
            height={height}
            width={width - 30}
            options={{
              responsive: false,
              interaction: {
                mode: 'index',
                intersect: false
              },
              plugins: {
                tooltip: {
                  padding: 8,
                  backgroundColor: 'rgba(0, 0, 0, 0.75)',
                  cornerRadius: 4,
                  displayColors: false,
                  caretSize: 8,
                  bodyFont: {
                    size: 14
                  }
                },
                legend: {
                  labels: {
                    usePointStyle: true,
                    boxWidth: 6
                  }
                }
              },
              scales: scales
            }}
            data={{
              labels: labels ?? [],
              datasets: datasets ?? []
            }}
          />
        )}
      </ChartLimit>
    </ChartWrapper>
  );
}

import { DeleteOutlined } from '@ant-design/icons';
import { message } from 'antd';
import {
  deleteDocumentaryDossiersId,
  getGetDocumentaryDossiersSearchQueryKey
} from 'client/api/backend/documentary-dossiers/documentary-dossiers';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import {
  getToolkitObjectOfType,
  ToolkitObject
} from 'client/components/toolkit/objects/ToolkitObject';
import { queryClient } from 'client/core/network/queryClient';
import { yup } from 'common/validation/initYup';
import React from 'react';
import { InferType } from 'yup';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { DossierToolkitObject } from 'client/components/widget-repository/objects/dossiers/DossierToolkitObject';
import { DeskDossier } from 'client/api/backend/schemas';
import { WidgetDossierViewerType } from '../../dossier-viewer/WidgetDossierViewer.type';
import { WidgetDossierViewerProfile } from '../../dossier-viewer/WidgetDossierViewerProfile';

export const WidgetActionDeleteDossier = createToolkitAction({
  name: 'Elimina Fascicolo',
  code: 'delete-dossier',
  icon: <DeleteOutlined />,
  arguments: [
    {
      name: 'Fascicolo',
      type: [DossierToolkitObject]
    }
  ],
  async execute(ctx: IToolkitActionContext, dossierObject: ToolkitObject) {
    const dossier: ToolkitObject<DeskDossier> = getToolkitObjectOfType(
      dossierObject,
      [DossierToolkitObject.code]
    )!;

    ctx.deskContext.actionMessages.prompt({
      ctx,
      args: [dossier],
      action: WidgetActionDeleteDossier,
      confirmText: 'Elimina',
      confirmProps: { danger: true },
      schema: DeleteDossierSchema,
      onConfirm
    });
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: DeleteDossierSchemaType,
  dossier: ToolkitObject
) {
  try {
    await deleteDossier(values, dossier);

    message.success('Fascicolo eliminato con successo');
  } catch (error) {
    message.error(
      getNetworkErrorMessage(error, 'Impossibile eliminare il fascicolo')
    );
    console.error(error);
    return false;
  }

  const dossierViewerWidgets = ctx.deskContext.widgets.filter(
    w => w.profile?.typeCode === WidgetDossierViewerType.code
  );

  dossierViewerWidgets.forEach(w => {
    if (
      (w.profile?.data as WidgetDossierViewerProfile).dossierId === dossier.id
    ) {
      ctx.deskContext.remove(w);
    }
  });
}

async function deleteDossier(
  values: DeleteDossierSchemaType,
  dossier: ToolkitObject<DeskDossier>
) {
  await deleteDocumentaryDossiersId(dossier.id as number, {
    widgetId: dossier.sourceWidgetId
  });

  // Invalido la cache
  const queryKey = getGetDocumentaryDossiersSearchQueryKey()[0];

  queryClient.invalidateQueries({
    predicate: query => {
      return Boolean(
        query.queryKey &&
          Array.isArray(query.queryKey) &&
          query.queryKey[0] === queryKey
      );
    }
  });
}

const DeleteDossierSchema = yup.object({});

type DeleteDossierSchemaType = InferType<typeof DeleteDossierSchema>;

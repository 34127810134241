import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { MonitorMiniBar } from './MonitorMiniBar';
import { ChartPalette } from '../palette/ChartPalette';

ChartJS.register(ArcElement, Tooltip, Legend);

const ChartWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ChartLimit = styled.div`
  padding: 12px;
  flex: 0 1 auto;
  min-height: 0;
  min-width: 0;
  width: 100%;
  overflow: hidden;
  canvas {
    margin: 0 auto;
  }
`;
const ChartFalse = styled.div`
  width: 100%;
  height: 250px;
`;

const linearColor = [
  '#0080ff',
  '#478bff',
  '#6596ff',
  '#7da1ff',
  '#92acff',
  '#a4b8ff',
  '#b6c4ff',
  '#c6d0ff'
];

const defaultColor = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)'
];

interface MonitorDoughnutChartProps {
  labels?: string[];
  values?: number[];
  total: number;
  max: number;
  /**
   * Se è aperto, viene mostrato solamente come fascia superiore ("MiniBar")
   */
  openedList?: boolean;
  /**
   * Palette di colori da utilizzare
   */
  palette: ChartPalette;
}

export function MonitorDoughnutChart(props: MonitorDoughnutChartProps) {
  const [calcHeight, setCalcHeight] = React.useState<number>(250);
  const [reload, setReload] = React.useState(true);

  const { labels, values, max, total, openedList, palette } = props;

  useEffect(() => {
    setReload(true);
    const maxHeight = max - 24;
    const isTooSmall = maxHeight < 50;
    setCalcHeight(maxHeight);
    setTimeout(() => {
      setReload(isTooSmall);
    }, 50);
  }, [openedList, max, calcHeight]);

  return (
    <ChartWrapper>
      {openedList ? (
        <MonitorMiniBar values={values} total={total} colors={palette} />
      ) : (
        <ChartLimit>
          {reload ? (
            <ChartFalse />
          ) : (
            <Doughnut
              height={calcHeight}
              width={calcHeight}
              options={{
                responsive: false,
                rotation: 180,

                plugins: {
                  legend: {
                    position: 'bottom',
                    display: false
                    // onClick: (e, legendItem) => {}
                  },
                  tooltip: {
                    padding: 8,
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    cornerRadius: 4,
                    displayColors: false,
                    caretSize: 8,
                    bodyFont: {
                      size: 14
                    }
                  }
                }
              }}
              data={{
                labels: labels ?? [],
                datasets: [
                  {
                    data: values ?? [],
                    backgroundColor: palette,
                    borderWidth: 2,
                    borderColor: '#fff',
                    hoverBorderColor: '#fff'
                  }
                ]
              }}
            />
          )}
        </ChartLimit>
      )}
    </ChartWrapper>
  );
}

import styled from 'styled-components';
import { Tag, TagProps } from 'antd';

export const HistoryMiniTag = styled(Tag)`
  line-height: 1.3em;
  padding: 0 4px;
`;

export interface IHistoryTag {
  label?: string;
  color?: TagProps['color'];
  isHidden?: boolean;
}

import * as React from 'react';
import { WidgetCard } from './card/WidgetCard';
import { WidgetTitle } from './header/title/WidgetTitle';
import { WidgetExtra } from './header/buttons/WidgetExtra';
import { FullScreenButton } from 'client/components/schema/widget/header/buttons/FullScreenButton';
import { DeleteOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { SyncButton } from 'client/components/schema/widget/header/buttons/SyncButton';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { DeskActions, useDesk } from '../desk/DeskModule';
import { useDispatch } from 'react-redux';
import { Widget } from 'client/api/backend/schemas';
import { WidgetTypeContent } from './dynamic/WidgetTypeContent';
import { useWidgetMutations } from './logic/useWidgetMutations';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { useDeskContext } from '../desk/context/DeskContext';
import { WidgetConfigurationButton } from './configure/WidgetConfigurationButton';
import { WidgetContextProvider } from './context/WidgetContext';
import { MoveToFloatingBarButton } from './header/buttons/MoveToFloatingBarButton';

export interface WidgetComponentProps {
  widget: Widget;
}

/**
 * Struttura base del Widget
 */
export function WidgetComponent(props: WidgetComponentProps) {
  const [notificationDrawerVisible, setNotificationDrawerVisible] =
    useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const deskState = useDesk();
  const widgetMutations = useWidgetMutations();
  const dispatch = useDispatch();
  const deskContext = useDeskContext();

  const { widget } = props;

  const toggleNotificationDrawer = () => {
    setNotificationDrawerVisible(!notificationDrawerVisible);
  };

  const handleCloseNotificationDrawer = () => {
    setNotificationDrawerVisible(false);
  };

  const toggleFullScreen = () => {
    const fullscreen = !fullScreen;
    setFullScreen(fullscreen);
    dispatch(DeskActions.fullScreen(fullscreen));
  };

  return (
    <WidgetContextProvider widget={widget}>
      <WidgetCard
        title={<WidgetTitle widget={widget} />}
        fullScreen={fullScreen}
        hideAllDrawer={deskState.isFullScreen && !fullScreen}
        extra={
          <WidgetExtra>
            {deskState.isEditable ? (
              <>
                <ButtonDeleteConfirm
                  title={
                    <span>
                      Sei sicuro di voler eliminare
                      <br />
                      il Widget da questa scrivania?
                    </span>
                  }
                  placement="left"
                  cancelText="Annulla"
                  onConfirm={async () => {
                    await deskContext.saveWidgetsLayout();
                    await widgetMutations.remove(widget);
                  }}
                  buttonType="default"
                >
                  <ButtonIcon
                    danger
                    icon={<DeleteOutlined />}
                    loading={widgetMutations.removeState.isLoading}
                  />
                </ButtonDeleteConfirm>
                <MoveToFloatingBarButton widget={widget} />

                <WidgetConfigurationButton widget={widget} />
              </>
            ) : (
              <>
                {/* TODO Supportare {widget.notification && (
                <Badge dot offset={[-6, 6]}>
                  <ButtonIcon
                    danger
                    icon={<BellOutlined />}
                    onClick={toggleNotificationDrawer}
                  />
                </Badge>
              )} */}
                <FullScreenButton
                  onClick={toggleFullScreen}
                  fullScreen={fullScreen}
                />
                <SyncButton />
              </>
            )}
          </WidgetExtra>
        }
      >
        <WidgetTypeContent widget={widget} />
        {/* {widget.notification && (
          <WidgetDrawer
            key={widget.widgetId}
            title="Notifiche"
            onClose={handleCloseNotificationDrawer}
            visible={notificationDrawerVisible}
          >
            <NotificationList insideWidget list={FixturesNotificaions} />
          </WidgetDrawer>
        )} */}
      </WidgetCard>
    </WidgetContextProvider>
  );
}

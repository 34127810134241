import { GetDocumentaryDamParams, Widget } from 'client/api/backend/schemas';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import * as React from 'react';
import * as WidgetFilterSections from 'client/components/toolkit/components/filter/WidgetFilterSections';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextInput } from 'client/ui/form/input/TextInput';
import { Button, Space } from 'antd';
import { WidgetFilterDrawer } from 'client/components/toolkit/components/filter/WidgetFilterDrawer';
import { renderWidgetFilterActiveTags } from 'client/components/toolkit/components/filter/renderWidgetFilterActiveTags';
import { useState } from 'react';
import { WidgetDamsSearchSchema } from './WidgetDamsSearchSchema';

export interface IWidgetDamSearchFormProps {
  widget: Widget;
  search: Partial<GetDocumentaryDamParams>;
  setSearch: (search: Partial<GetDocumentaryDamParams>) => void;
}

export function WidgetDamsSearchForm(props: IWidgetDamSearchFormProps) {
  const { widget, search, setSearch } = props;
  const [open, setOpen] = useState(false);

  return (
    <FormikAugmented
      validationSchema={WidgetDamsSearchSchema}
      validationContext={{}}
      initialValues={search}
      onReset={() => {
        setSearch({});
        setOpen(false);
      }}
      onSubmit={(values: any) => {
        setSearch(values);
        setOpen(false);
      }}
    >
      <WidgetFilterDrawer
        title="Ricerca"
        filters={renderWidgetFilterActiveTags({
          search,
          filters: widget.profile?.filters,
          onReset: () => setSearch({})
        })}
        open={[open, setOpen]}
      >
        <FormikForm layout="vertical" editable helpInTooltips>
          <WidgetFilterSections.BodyWrapper>
            <FormFieldsContainer columns={2} gutter={8}>
              <FormFieldItem
                allowClear
                size="middle"
                component={TextInput}
                label={null}
                name="name"
                placeholder="Nome"
              />
              <FormFieldItem
                allowClear
                size="middle"
                component={TextInput}
                label={null}
                name="status"
                placeholder="Status"
              />
            </FormFieldsContainer>
            <FormFieldsContainer columns={2} gutter={8}>
              <FormFieldItem
                allowClear
                size="middle"
                component={TextInput}
                label={null}
                name="river"
                placeholder="Fiume sbarrato"
              />
              <FormFieldItem
                allowClear
                size="middle"
                component={TextInput}
                label={null}
                name="tributary"
                placeholder="Nome fiume a valle"
              />
            </FormFieldsContainer>
            <FormFieldsContainer columns={2} gutter={8}>
              <FormFieldItem
                allowClear
                size="middle"
                component={TextInput}
                label={null}
                name="lagoon"
                placeholder="Bacino"
              />
              <FormFieldItem
                allowClear
                size="middle"
                component={TextInput}
                label={null}
                name="reservoirDam"
                placeholder="Diga invaso"
              />
            </FormFieldsContainer>
            <FormFieldsContainer columns={2} gutter={8}>
              <FormFieldItem
                allowClear
                size="middle"
                component={TextInput}
                label={null}
                name="dealer"
                placeholder="Concessionario"
              />
              <FormFieldItem
                allowClear
                size="middle"
                component={TextInput}
                label={null}
                name="operator"
                placeholder="Gestore"
              />
            </FormFieldsContainer>
            <FormFieldsContainer>
              <FormFieldItem
                allowClear
                size="middle"
                component={TextInput}
                label={null}
                name="municipality"
                placeholder="Comune opera presa"
              />
            </FormFieldsContainer>
          </WidgetFilterSections.BodyWrapper>
          <WidgetFilterSections.ButtonsWrapper>
            <Space>
              <Button htmlType="reset">Pulisci</Button>
              <Button htmlType="submit" type="primary">
                Filtra
              </Button>
            </Space>
          </WidgetFilterSections.ButtonsWrapper>
        </FormikForm>
      </WidgetFilterDrawer>
    </FormikAugmented>
  );
}

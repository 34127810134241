import * as React from 'react';
import { useRelationEditor } from 'client/components/form/relations/useRelationEditor';
import { Divider, Dropdown, Menu, Space, Tooltip } from 'antd';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import {
  BankOutlined,
  DeleteOutlined,
  PlusOutlined,
  ShopOutlined,
  UserAddOutlined
} from '@ant-design/icons';
import { DeskCorrespondent } from 'client/api/backend/schemas';
import { VerticalSpace } from 'client/ui/space/VerticalSpace';
import { useWidgetContext } from 'client/components/schema/widget/context/WidgetContext';
import { CorrespondentInput } from './CorrespondentInput';
import {
  CorrespondentKey,
  getCorrespondentDefaults
} from './CorrespondentType';
import { assertNever } from 'common/utils/assertNever';
import { useMemo } from 'react';
import { StyledCard } from 'client/ui/styled-card/StyledCard';
import { useDesk } from 'client/components/schema/desk/DeskModule';

function getCorrespondentTypeIcon(key?: string) {
  switch (key) {
    case CorrespondentKey.Person:
      return <UserAddOutlined />;
    case CorrespondentKey.Organization:
      return <ShopOutlined />;
    case CorrespondentKey.PublicAdministration:
    case CorrespondentKey.ForeignPublicAdministration:
      return <BankOutlined />;
    default:
      return null;
  }
}

export interface ICorrespondentsInputProps {
  displayName: string;
  name: string;
  /** Limita i tipi di elementi selezionabili (CorrespondetKey) */
  allowedTypes?: string[];
  /** Numero massimo di elementi inseribili */
  maxItems?: number;
  disabled?: boolean;
}

export function CorrespondentsInput(props: ICorrespondentsInputProps) {
  const { displayName, name, allowedTypes, maxItems, disabled } = props;
  const editor = useRelationEditor<DeskCorrespondent>(name);
  const { widget } = useWidgetContext();
  const deskState = useDesk();

  const allowsMultiple = maxItems == null || maxItems > 1;

  const menuItems = useMemo(() => {
    return [
      {
        key: CorrespondentKey.Person,
        icon: <UserAddOutlined />,
        label: 'Aggiungi Persona Fisica'
      },
      {
        key: CorrespondentKey.Organization,
        icon: <ShopOutlined />,
        label: 'Aggiungi Persona Giuridica'
      },
      {
        key: CorrespondentKey.PublicAdministration,
        icon: <BankOutlined />,
        label: 'Aggiungi Pubblica Amministrazione'
      },
      {
        key: CorrespondentKey.ForeignPublicAdministration,
        icon: <BankOutlined />,
        label: 'Aggiungi Pubblica Amministrazione Estera'
      }
    ].filter(item => allowedTypes?.includes(item.key) || allowedTypes == null);
  }, [allowedTypes]);

  const AddMenu = (
    <Menu
      onClick={e => {
        editor.add(getCorrespondentDefaults(e.key));
      }}
      items={menuItems}
    />
  );
  const showAddCorrespondent = !maxItems || editor.items.length < maxItems;

  return (
    <div>
      {showAddCorrespondent && !disabled && (
        <Space>
          <Dropdown overlay={AddMenu}>
            <div>
              <ButtonIcon size="small" icon={<PlusOutlined />}>
                <span style={{ paddingRight: '4px' }}>
                  Aggiungi {displayName}
                </span>
              </ButtonIcon>
            </div>
          </Dropdown>
        </Space>
      )}

      <Space
        size={12}
        align={deskState.isFullScreen ? 'start' : undefined}
        direction={deskState.isFullScreen ? 'horizontal' : 'vertical'}
        wrap={deskState.isFullScreen}
        style={{ width: '100%' }}
      >
        {editor.items.map((item, index) => {
          return (
            <StyledCard
              key={index}
              size="small"
              extra={
                <ButtonIcon
                  disabled={disabled}
                  icon={<DeleteOutlined />}
                  tooltip={`Rimuovi ${displayName}`}
                  tooltipProps={{ placement: 'topRight' }}
                  danger
                  onClick={() => editor.remove(item)}
                />
              }
              title={
                <Space>
                  {getCorrespondentTypeIcon(item.type)}
                  <span>
                    {displayName}
                    {allowsMultiple ? ` ${index + 1}` : ''}
                  </span>
                </Space>
              }
            >
              <CorrespondentInput
                item={item}
                widget={widget}
                fieldNamePrefix={`${name}.${index}`}
                modeFieldName="mode"
                disabled={disabled}
              />
            </StyledCard>
          );
        })}
      </Space>
    </div>
  );
}

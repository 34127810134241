import { FolderOpenOutlined } from '@ant-design/icons';
import { IDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { DeskWidgetsLogic } from 'client/components/schema/desk/logic/DeskWidgetsLogic';
import {
  createToolkitAction,
  ToolkitAction
} from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import {
  getToolkitObjectOfType,
  ToolkitObject
} from 'client/components/toolkit/objects/ToolkitObject';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { DossierToolkitObject } from 'client/components/widget-repository/objects/dossiers/DossierToolkitObject';
import { set } from 'client/core/data/set';
import React from 'react';
import { WidgetDossierViewerType } from '../../WidgetDossierViewer.type';
import { WidgetDossierViewerProfile } from '../../WidgetDossierViewerProfile';

export const WidgetActionOpenDossier = createToolkitAction({
  name: 'Apri Fascicolo',
  code: 'open-dossier',
  showInBar: true,
  icon: <FolderOpenOutlined />,
  arguments: [
    {
      type: [DossierToolkitObject],
      name: 'Fascicolo da Aprire'
    },
    {
      type: [WidgetToolkitObject],
      allows: (object: ToolkitObject) =>
        WidgetToolkitObject.matches(object, WidgetDossierViewerType),
      name: 'Widget Visualizza Fascicolo'
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    object: ToolkitObject,
    targetObject: ToolkitObject | null
  ) {
    const dossier = DossierToolkitObject.get(object)!;
    const target = WidgetToolkitObject.get(targetObject);

    // const dossier = dossierObject.data as DeskDossier;
    const data: WidgetDossierViewerProfile = {
      dossierId: dossier.id as number,
      dossierName: dossier.name,
      loadFromDossierName: null
    };

    if (target) {
      const widget = ctx.deskContext.widgets.find(w => w.id === target.id)!;
      await ctx.deskContext.updateWidget(set(widget, 'profile.data', data));
      return;
    }

    // TODO Se non esiste il sourceWidget gestire con un semplice create
    await ctx.deskContext.cloneWidget(
      {
        type: 'floating',
        expanded: true,
        order: DeskWidgetsLogic.getFloatingNextOrder(
          ctx.deskContext.floatingWidgets
        )
      },
      WidgetDossierViewerType,
      ctx.sourceWidget!,
      {
        data,
        quickClose: true,
        initializeInFullScreen:
          ctx.sourceWidget?.profile?.showChildWidgetInFullScreen ?? false
      }
    );
  }
});

import { MailOutlined } from '@ant-design/icons';
import { FvIconMailView } from 'client/ui/icon/FvIcons';
import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetActionOpenMail } from './actions/WidgetActionOpenMail';
import { WidgetMailViewer } from './WidgetMailViewer';
import { WidgetMailViewerProfile } from './WidgetMailViewerProfile';

export const WidgetMailViewerType: IWidgetType = {
  code: 'mail-viewer',
  name: 'Visualizzatore Email',
  icon: <FvIconMailView />,
  component: WidgetMailViewer,
  description: `Apre una email per la sua visualizzazione`,
  actions: [WidgetActionOpenMail],
  getTitle: widget => {
    const data = widget.profile?.data as WidgetMailViewerProfile | undefined;
    return data?.mailSubject;
  }
};

WidgetRepository.register(WidgetMailViewerType);

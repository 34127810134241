import { yup } from 'common/validation/initYup';
import { ObjectSchema } from 'yup';

interface ICorrespondentSchemaOptions {
  required?: boolean;
}

export function getCorrespondentSchema(
  personSchema: ObjectSchema<any>,
  corporateSchema: ObjectSchema<any>,
  administrationSchema: ObjectSchema<any>,
  options: ICorrespondentSchemaOptions = { required: true }
) {
  const typeSchema = yup.string().label('Tipo Corrispondente');

  return yup.object({
    type: options.required
      ? typeSchema.required()
      : typeSchema.notRequired().nullable(),
    transport: yup
      .object({
        id: yup.number().nullable().label('Mezzo di Spedizione')
      })
      .nullable()
      .label('Mezzo di Spedizione'),
    person: personSchema.when('type', {
      is: 'PF',
      otherwise: (schema: ObjectSchema<any>) => schema.strip()
    }),
    corporate: corporateSchema.when('type', {
      is: 'PG',
      otherwise: (schema: ObjectSchema<any>) => schema.strip()
    }),
    administration: administrationSchema.when('type', {
      is: ['PAI', 'PAE'],
      otherwise: (schema: ObjectSchema<any>) => schema.strip()
    })
  });
}

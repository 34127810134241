import { Button, ButtonProps } from 'antd';
import { useFormikContext } from 'formik';
import * as React from 'react';

export interface IFormikSendButtonProps extends ButtonProps {
  as?: React.ComponentType<ButtonProps>;
}

export function FormikSendButton(props: IFormikSendButtonProps) {
  const formik = useFormikContext();
  const ButtonComponent = props.as ?? Button;
  return (
    <ButtonComponent
      type="primary"
      // disabled={formik.isSubmitting}
      loading={formik.isSubmitting}
      {...props}
      onClick={formik.submitForm}
    />
  );
}

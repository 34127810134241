import { VerticalAlignBottomOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { Widget } from 'client/api/backend/schemas';
import { useDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { DeskWidgetsLogic } from 'client/components/schema/desk/logic/DeskWidgetsLogic';
import { set } from 'client/core/data/set';
import { logger } from 'client/core/logger/logger';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import * as React from 'react';
import { useCallback } from 'react';

export interface IMoveToFloatingBarButtonProps {
  widget: Widget;
}

export function MoveToFloatingBarButton(props: IMoveToFloatingBarButtonProps) {
  const { widget } = props;
  const deskContext = useDeskContext();

  const handleMoving = useCallback(async () => {
    try {
      await deskContext.updateWidget(
        set(widget, 'profile.position', {
          ...widget.profile?.position,
          type: 'floating',
          expanded: false,
          order: DeskWidgetsLogic.getFloatingNextOrder(deskContext.widgets)
        })
      );
    } catch (err) {
      message.error("Non è stato possibile spostare il widget, si prega di riprovare."); // prettier-ignore
      logger.error(`[MoveToFloatingBar] error:`, err);
    }
  }, [deskContext.updateWidget, deskContext.widgets, widget]);

  return (
    <ButtonIcon icon={<VerticalAlignBottomOutlined />} onClick={handleMoving} />
  );
}

import { HighlightOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { DeskWidgetsLogic } from 'client/components/schema/desk/logic/DeskWidgetsLogic';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { AssignmentToolkitObject } from 'client/components/widget-repository/objects/assignments/AssignmentToolkitObject';
import { set } from 'client/core/data/set';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { yup } from 'common/validation/initYup';
import React from 'react';
import { WidgetHighlightedAssignmentsType } from '../WidgetHighlightedAssignments.type';
import { WidgetHighlightedAssignmentsProfile } from '../WidgetHighlightedAssignmentsProfile';

export const WidgetActionHighlightAssignment = createToolkitAction({
  name: 'Evidenzia Assegnazione',
  code: 'highlight-assignment',
  icon: <HighlightOutlined />,
  arguments: [
    {
      type: [AssignmentToolkitObject],
      name: 'Assegnazione'
    },
    {
      name: 'Widget Assegnazioni in Evidenzia',
      type: [WidgetToolkitObject],
      allows: (object: ToolkitObject) =>
        WidgetToolkitObject.matches(object, WidgetHighlightedAssignmentsType)
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    assignmentObject: ToolkitObject,
    highlightedObject: ToolkitObject | null
  ) {
    const assignment = AssignmentToolkitObject.get(assignmentObject)!;
    const highlighted = WidgetToolkitObject.get(highlightedObject);

    // A. Se stiamo trascinando su un Widget già esistente..
    if (highlighted) {
      await onConfirm(ctx, {}, assignment, highlighted);
      return;
    }

    // B. Se esiste almeno un Widget di tipo Evidenzia Assegnazione..
    const hasHiglightWidgets = ctx.deskContext.widgets.some(
      w => w.profile?.typeCode === WidgetHighlightedAssignmentsType.code
    );
    if (hasHiglightWidgets) {
      ctx.deskContext.actionMessages.prompt({
        ctx,
        args: [assignment, highlighted],
        action: WidgetActionHighlightAssignment,
        schema: HighlightAssignmentSchema,
        confirmText: 'Metti in Evidenza',
        onConfirm
      });
      return;
    }

    // C. Se non esiste almeno un Widget di tipo Evidenzia Assegnazione, lo creiamo
    const data: WidgetHighlightedAssignmentsProfile = {
      assignments: [assignment.data]
    };

    await ctx.deskContext.cloneWidget(
      {
        type: 'floating',
        expanded: true,
        order: DeskWidgetsLogic.getFloatingNextOrder(
          ctx.deskContext.floatingWidgets
        )
      },
      WidgetHighlightedAssignmentsType,
      ctx.sourceWidget!,
      { data, quickClose: false }
    );
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: any,
  assignment: ToolkitObject,
  highlighted: ToolkitObject
) {
  try {
    const widget = ctx.deskContext.widgets.find(w => w.id === highlighted.id);
    const profile = widget?.profile;
    const data = profile?.data as WidgetHighlightedAssignmentsProfile | null;

    const addedAssignment = assignment.data;

    if (data?.assignments?.some(a => a.id === addedAssignment.id)) {
      message.warning('Assegnazione già presente in evidenza');
      return false;
    }

    await ctx.deskContext.updateWidget(
      set(widget!, 'profile.data.assignments', [
        ...(data?.assignments ?? []),
        addedAssignment
      ])
    );

    message.success('Assegnazione salvata in evidenza');
  } catch (e) {
    message.error(
      getNetworkErrorMessage(e, "Impossibile evidenziare l'assegnazione")
    );
    console.error(e);
    return false;
  }
}

const HighlightAssignmentSchema = yup.object({});

import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { DeskAttachment } from 'client/api/backend/schemas';
import { getIn, useFormikContext } from 'formik';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import {
  UploadDraggerInput,
  UploadDraggerInputProps
} from './UploadDraggerInput';

const { Dragger } = Upload;

interface UploadOptionsSingle {
  type: 'single';
  /** Nome del campo formik del nome stesso del file */
  filenameFieldName: string;
}

interface UploadOptionsMultiple {
  type: 'multiple';
  /**
   * Trasforma il file appena caricato per essere inserito nella lista dei file
   */
  transformFile: (file: RcFile, inlineMimeType: string, content: string) => any;
}

export interface IUploadDraggerBase64Input
  extends Omit<UploadDraggerInputProps<any>, 'multiple'> {
  /** Nome del campo formik del file in base64 / dell'array dei file */
  name: string;
  options: UploadOptionsSingle | UploadOptionsMultiple;
}

function mapValueToFileList(
  values: any,
  props: IUploadDraggerBase64Input
): UploadFile[] {
  const type = props.options.type;

  const valueName =
    type === 'single' ? props.options.filenameFieldName : props.name;

  const value = getIn(values, valueName);
  if (value == null) return [];

  switch (type) {
    case 'single': {
      return [
        { uid: 'single', name: getIn(values, props.options.filenameFieldName) }
      ];
    }
    case 'multiple': {
      return value.map((attachment: DeskAttachment, i: number) => ({
        uid: i.toString(),
        name: attachment.name,
        status: 'done'
      }));
    }
  }
}

interface IUploadingRefState {
  count: number;
  files: any[];
}

export function UploadDraggerBase64Input(props: IUploadDraggerBase64Input) {
  const { name, options, ...otherProps } = props;
  const formik = useFormikContext<any>();

  const fileList = mapValueToFileList(formik.values, props);

  /**
   * Per gestire gli upload contemporanei, dovremmo usare `formik.setFieldValue`
   * in maniera asincrona, purtroppo formik non espone in modo _sincrono_
   * i suoi formik.values: l'effetto è che solo l'ultimo `setFieldValue` funziona,
   * "sovrascrivendo" gli altri.
   * Per evitarlo, in caso di operazioni concorrenti, teniamo in una ref i
   * file (uno state avrebbe più o meno gli stessi problemi di sincronicità),
   * e li aggiungiamo in "batch" quando sono finiti quelli contemporanei.
   */
  const uploadingRef = React.useRef<IUploadingRefState>({
    count: 0,
    files: []
  });

  return (
    <UploadDraggerInput
      name={name}
      fileList={fileList}
      onChange={info => {
        // console.log('info is', info);
        // // Eliminiamo il file precedente se non ammettiamo upload multiplio
        // if (options.type === 'single') {
        //   setFileList([...info.fileList.slice(-1)]);
        // } else {
        //   setFileList(info.fileList);
        // }
      }}
      onRemove={file => {
        if (options.type === 'single') {
          formik.setFieldValue(name, null);
          formik.setFieldValue(options.filenameFieldName, null);
        } else {
          const idx = fileList.indexOf(file);
          formik.setFieldValue(
            name,
            (getIn(formik.values, name) ?? []).filter(
              (attachment: DeskAttachment, i: number) => i !== idx
            )
          );
        }
      }}
      multiple={options.type === 'multiple'}
      maxCount={options.type === 'single' ? 1 : undefined}
      {...otherProps}
      beforeUpload={async file => {
        const reader = new FileReader();

        uploadingRef.current.count++;

        await new Promise<void>((resolve, reject) => {
          reader.onloadend = e => {
            const [inlineMimeType, content] = String(e.target?.result).split(','); // prettier-ignore
            switch (options.type) {
              case 'single': {
                formik.setFieldValue(name, content);
                formik.setFieldValue(options.filenameFieldName, file.name);
                break;
              }

              case 'multiple': {
                const transformedFile = options.transformFile(
                  file,
                  inlineMimeType,
                  content
                );
                uploadingRef.current.files.push(transformedFile);
                uploadingRef.current.count--;
                break;
              }
            }
            resolve();
          };

          reader.onerror = e => {
            reject('Impossibile caricare il file');
            uploadingRef.current.count--;
          };

          reader.readAsDataURL(file);
        });

        if (uploadingRef.current.count === 0) {
          formik.setFieldValue(name, [
            ...(getIn(formik.values, name) ?? []),
            ...uploadingRef.current.files
          ]);
          uploadingRef.current.files = [];
        }

        // Prevent upload
        return false;
      }}
    />
  );
}

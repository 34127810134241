import { resolvePublicPath } from 'client/core/appConfig';
import React from 'react';
import styled from 'styled-components';

/**
 * Placeholder per il logo principale dell'applicativo
 *
 * inseriamo un'immagine trasparente con le dimensioni del logo
 * (il logo sara inserito come background).
 * Le dimensioni e il file del logo sono definite in configurazione.
 */
const LogoPlaceholder = require('./image/logo-space.png');

const LogoImg = styled.img`
  width: ${props => props.theme.logoWidth};
  height: ${props => props.theme.logoHeight};
  border: none;
  margin: 0;
  padding: 0;
  vertical-align: top;
`;

const LogoWrapper = styled.div`
  width: ${props => props.theme.logoWidth};
  height: ${props => props.theme.logoHeight};
  background-image: url(${props => props.theme.logoUrl});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
`;

export interface HeaderLogoProps {
  alt?: string;
  ariaLabel?: string;
}

/**
 * Logo principale dell'applicativo.
 * Le dimensioni e il file del logo sono definite in configurazione.
 */
export function HeaderLogo(props: HeaderLogoProps) {
  return (
    <LogoWrapper>
      <LogoImg
        src={resolvePublicPath(LogoPlaceholder)}
        alt={props.alt ?? 'Logo'}
        aria-label={props.ariaLabel ?? 'Logo'}
      />
    </LogoWrapper>
  );
}

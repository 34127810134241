import {
  getDocumentaryDocumentsTypesId,
  useGetDocumentaryDocumentsTypesId
} from 'client/api/backend/documentary-documents/documentary-documents';
import { getDocumentaryDossiersSubtypesId } from 'client/api/backend/documentary-dossiers/documentary-dossiers';
import {
  DeskDocument,
  DeskDossier,
  DeskExtendedAttributeType,
  DeskExtendedType
} from 'client/api/backend/schemas';
import { WidgetStepPanel } from 'client/components/toolkit/components/speps/WidgetStepPanel';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { SelectInputProps } from 'client/ui/form/input/SelectInput';
import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { AttributeTypesFormFieldItem } from '../dossiers/AttributeTypesFormFieldItem';

interface IDossierSubtypeMetadataFormInputProps
  extends Omit<SelectInputProps<any>, 'name'> {
  widgetId: number;
  documentExtendedType?: DeskExtendedType;
  setDocumentExtendedType: (value?: DeskExtendedType) => void;
}

export function DocumentSubtypeMetadataFormInput(
  props: IDossierSubtypeMetadataFormInputProps
) {
  const {
    widgetId,
    documentExtendedType,
    setDocumentExtendedType,
    ...otherProps
  } = props;

  const formik = useFormikContext<DeskDocument>();

  const result = useGetDocumentaryDocumentsTypesId(
    formik.values.idType!,
    { widgetId },
    { query: { enabled: formik.values.idType != null } }
  );

  const extendedType = result.data;
  const attributes = extendedType?.attributes ?? [];

  useEffect(() => {
    if (!isEqual(extendedType, documentExtendedType)) {
      setDocumentExtendedType(extendedType);
    }
  }, [extendedType]);

  if (attributes.length === 0) {
    return null;
  }

  return (
    <FormFieldsContainer>
      {attributes.map((attribute, index) => {
        return (
          <AttributeTypesFormFieldItem
            key={index}
            metadata={attribute}
            index={index}
          />
        );
      })}
    </FormFieldsContainer>
  );
}

import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { SendOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { DeskActivity } from 'client/api/backend/schemas';
import { ActivityToolkitObject } from 'client/components/widget-repository/objects/activities/ActivityToolkitObject';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { yup } from 'common/validation/initYup';
import { InferType } from 'yup';
import { ActivityCompletionsSelectInput } from 'client/components/widget-repository/objects/activities/ActivityCompletionsSelectInput';
import { ActivityCompletion } from 'client/components/widget-repository/objects/activities/ActivityCompletions';
import { executeCompleteActivity } from './complete/executeCompleteActivity';
import { without } from 'lodash';
import { ActivityActionCategory } from 'client/components/widget-repository/objects/activities/ActivityActionCategories';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';

export const WidgetActionGiveOpinionActivity = createToolkitAction({
  name: 'Esprimi un parere',
  code: 'give-opinion-activity',
  icon: <SendOutlined />,
  arguments: [
    {
      name: 'Attività su cui Espimere un parere',
      type: [ActivityToolkitObject],
      allows: (activityObject: ToolkitObject<DeskActivity>) =>
        activityObject.data.actionCategory === ActivityActionCategory.Parere
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    activityObject: ToolkitObject<DeskActivity>
  ) {
    const activity = ActivityToolkitObject.get(activityObject)!;

    ctx.deskContext.actionMessages.prompt({
      ctx,
      action: WidgetActionGiveOpinionActivity,
      args: [activityObject],
      confirmText: 'Esprimi Parere',
      schema: GiveOpinionActivitySchema,
      onConfirm,
      form: (
        <>
          <FormFieldsContainer>
            <FormFieldItem
              size="large"
              component={ActivityCompletionsSelectInput}
              availableCompletions={without(
                activityObject.data.responses,
                ActivityCompletion.RichiestaModifiche
              )}
              widgetId={activity.sourceWidgetId}
              code={activity.data.code!}
              label={null}
              name="type"
              placeholder="Parere"
            />
            <FormFieldItem
              size="large"
              component={TextAreaInput}
              label={null}
              name="note"
              placeholder="Note"
            />
          </FormFieldsContainer>
        </>
      )
    });
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: GiveOpinionActivitySchemaType,
  activityObject: ToolkitObject<DeskActivity>
) {
  try {
    await executeCompleteActivity(activityObject, {
      type: values.type,
      note: values.note
    });

    message.success('Parere espresso con successo');
  } catch (e: any) {
    message.error(
      getNetworkErrorMessage(e, "Errore durante l'espressione del parere.")
    );
    console.error(e);
    return false;
  }
}

const GiveOpinionActivitySchema = yup.object({
  type: yup.string().required(),
  note: yup.string().when('type', (type: string, schema) => {
    return type === ActivityCompletion.Negativo
      ? schema.required()
      : schema.strip();
  })
});

type GiveOpinionActivitySchemaType = InferType<
  typeof GiveOpinionActivitySchema
>;

import React from 'react';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import {
  SelectInput,
  SelectInputProps,
  SelectOption
} from 'client/ui/form/input/SelectInput';
import { useGetDocumentaryDocumentsProtocolModes } from 'client/api/backend/documentary-documents/documentary-documents';
import { useGetDocumentaryRegistries } from 'client/api/backend/documentary-registries/documentary-registries';
import { useFormikContext } from 'formik';
import { DeskRegistry } from 'client/api/backend/schemas';
import { DocumentMode } from './DocumentMode';

// Modalità così come ritornate da Folium:
// {label: "E", value: "ENTRATA"}
// {label: "U", value: "USCITA"}
// {label: "I", value: "INTERNA"}
// {label: "R", value: "REGISTRAZIONE"}

interface Props extends SelectInputProps<any> {
  widgetId: number;
}

export function DocumentProtocolModeInput(props: Props) {
  const { widgetId, ...otherProps } = props;

  const modesResult = useGetDocumentaryDocumentsProtocolModes({
    widgetId: props.widgetId
  });

  const results = Array.isArray(modesResult.data) ? modesResult.data : [];

  return (
    <SelectInput
      allowClear
      showSearch
      loading={modesResult.isLoading}
      options={results.map(mode => ({
        // Attenzione, sono invertiti lato API
        label: mode.value,
        value: mode.label!
      }))}
      {...otherProps}
    />
  );
}

import * as React from 'react';
import { Tabs } from 'antd';
const { TabPane } = Tabs;
import styled, { css } from 'styled-components';

/**
 * Tutti gli stili del componente WidgetTabs vengono definiti qui
 */

/**  Contenitore Generale Tabs (WidgetTabs) */
export const WidgetTabsStyle = styled(Tabs)`
  // Container delle tabs
  min-height: 0;
  height: 100%;
  max-height: 100%;
  min-width: 0;
  width: 100%;

  border-radius: 0;
  border-bottom-left-radius: ${props => props.theme.cardRadius};
  border-bottom-right-radius: ${props => props.theme.cardRadius};

  overflow: hidden;

  // Navigazione Tabs
  > .ant-tabs-nav {
    margin: 0;
    height: 45px;
    .ant-tabs-tab {
      padding: 12px;
      + .ant-tabs-tab {
        margin-left: 6px;
      }
      .anticon {
        font-size: 16px;
        margin-right: 8px;
        margin-left: 8px;
      }
    }
  }

  // Content della tab
  .ant-tabs-content {
    height: 100%;
    max-height: 100%;
  }
`;

/**  Singolo Tab (WidgetTabPanel) */
export const WidgetTabPanelStyle = styled(TabPane)`
  width: 100%;
  max-height: 100%;
`;

/**  Container (intorno alle Tabs) da cui ottenere le dimensioni per il responsive */
export const WidgetTabsObserverSize = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  border-radius: 0;
  border-bottom-left-radius: ${props => props.theme.cardRadius};
  border-bottom-right-radius: ${props => props.theme.cardRadius};
  display: flex;
`;

/**  Colonna principale (quando lo spazio è sufficiente a mostrare 2 colonne) */
export const WidgetTabsMainContent = styled.div`
  flex: 1 1 auto;
  width: auto;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  border-radius: 0;
  border-bottom-right-radius: ${props => props.theme.cardRadius};
  border-left: 1px solid ${props => props.theme.borderColorBase};
  // fix altrimenti il filo superiore non viene visualizzato correttamente
  padding-top: 1px;
`;

/**  Colonna che contiene le tabs (sembre presente) */
export const WidgetTabsResponsiveContent = styled.div<{ fullWidth: boolean }>`
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  border-radius: 0;
  border-bottom-left-radius: ${props => props.theme.cardRadius};

  ${props =>
    props.fullWidth
      ? css`
          width: 100%;
          max-width: 100%;
          border-bottom-right-radius: ${props => props.theme.cardRadius};
        `
      : css`
          flex: 0 0 35%;
          width: 35%;
          max-width: 600px;
          min-width: 260px;
        `}
`;

import * as React from 'react';
import { Card, CardProps, Typography } from 'antd';
import styled from 'styled-components';
import { LoginLogo } from './LoginLogo';
import { WidgetWrapper } from 'client/components/schema/widget/card/base/WidgetWrapper';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';

const { Title, Text, Paragraph } = Typography;

const WelcomeCard = styled(Card)`
  height: 100%;
  background-color: ${props =>
    props.theme.loginLogoBackground ?? props.theme.primaryColor};
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  .ant-card-body {
    padding: 24px;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    .logo-wrapper {
      flex-grow: 0;
      margin-bottom: 0;
    }
  }
`;

export interface LoginMobileLogoProps extends Omit<CardProps, 'bordered'> {}

/**
 * Messaggio di Welcome del login
 */
export function LoginMobileLogo(props: LoginMobileLogoProps) {
  return (
    <WidgetWrapper>
      <WelcomeCard bordered={false}>
        <LoginLogo className="logo-wrapper" />
      </WelcomeCard>
    </WidgetWrapper>
  );
}

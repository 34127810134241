import * as React from 'react';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { AuthMethod } from '../AuthMethods';
import { AuthSubject } from '../SsoContextProvider';

export interface IOpenIDConnectEventHandlerProps {
  authSubject: AuthSubject;
}

export function OpenIDConnectEventHandler(
  props: IOpenIDConnectEventHandlerProps
) {
  const { authSubject } = props;
  const auth = useAuth();

  useEffect(() => {
    if (process.env.SSO_METHOD !== AuthMethod.OpenIDConnect) {
      return;
    }

    const subscription = authSubject.subscribe(async event => {
      if (event.type === 'logout') {
        try {
          await auth.removeUser();
          await auth.signoutRedirect();
        } catch (err) {
          console.error(`[OIDC] Impossibile completare il logout`, err);
        }
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [authSubject]);
  return null;
}

import React, { useCallback, useState } from 'react';
import { RadioInput } from 'client/ui/form/input/RadioInput';
import { RadioInputProps } from 'client/ui/form/input/RadioInput';
import { useGetDocumentaryAssignmentsFlows } from 'client/api/backend/documentary-assignments/documentary-assignments';

interface Props extends RadioInputProps<any> {
  widgetId: number;
  allowCancel?: boolean;
}

export function AssignmentTypeRadioInput(props: Props) {
  const { widgetId, allowCancel, ...otherProps } = props;

  const types = useGetDocumentaryAssignmentsFlows({
    widgetId: props.widgetId
  });

  const baseOptions =
    types.data?.map(t => ({ label: t.label, value: t.value! })) ?? [];

  const options = allowCancel
    ? [...baseOptions, { label: '\u2715', value: null as any }]
    : baseOptions;

  return (
    <RadioInput
      optionType="button"
      buttonStyle="solid"
      options={options}
      {...otherProps}
    />
  );
}

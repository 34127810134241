import { getGetDocumentaryActivityQueryKey } from 'client/api/backend/documentary-activity/documentary-activity';
import { queryClient } from 'client/core/network/queryClient';

export function invalidateActivityQueries() {
  queryClient.invalidateQueries({
    predicate: query => {
      return Boolean(
        query.queryKey &&
          Array.isArray(query.queryKey) &&
          query.queryKey[0] === getGetDocumentaryActivityQueryKey()[0]
      );
    }
  });
}

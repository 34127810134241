import * as React from 'react';
import styled from 'styled-components';
import { Timeline, TimelineProps } from 'antd';

const StyledTimeline = styled(Timeline)`
  &.children-wrapper {
    padding-left: 8px;
  }
`;

export interface HistoryTimelineProps extends TimelineProps {}
/**
 * Contenitore per la timeline di una Cronologia
 */
export function HistoryTimeline(props: HistoryTimelineProps) {
  return <StyledTimeline {...props} />;
}

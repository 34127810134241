import { WidgetPosition } from 'client/components/schema/widget/schema/WidgetPosition';
import { useReducer } from 'react';

type AddWidgetAction =
  // Imposta la posizione
  | { type: 'set-position'; position: WidgetPosition }
  // Chiude il pannello
  | { type: 'close' };

export interface AddWidgetState {
  position?: WidgetPosition;
  visible?: boolean;
}

function reducer(
  state: AddWidgetState,
  action: AddWidgetAction
): AddWidgetState {
  switch (action.type) {
    case 'set-position':
      return { ...state, visible: true, position: action.position };

    case 'close':
      return { ...state, visible: false };
  }
}

/**
 * Gestisce lo stato della dialog per l'aggiunta di un widget.
 */
export function useAddWidgetReducer() {
  const [state, dispatch] = useReducer(reducer, {});
  return {
    state,
    dispatch
  };
}

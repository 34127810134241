import { Widget } from 'client/api/backend/schemas';
import { WidgetPosition } from '../schema/WidgetPosition';

const DefaultWidgetPosition: WidgetPosition = {
  type: 'floating'
};

export class WidgetPositionHandler {
  static position(widget: Widget) {
    return widget.profile?.position ?? DefaultWidgetPosition;
  }

  static floatingPosition(widget: Widget) {
    const position = WidgetPositionHandler.position(widget);
    if (position.type !== 'floating') throw new Error('Widget non floating');
    return position;
  }

  static gridPosition(widget: Widget) {
    const position = WidgetPositionHandler.position(widget);
    if (position.type !== 'grid') throw new Error('Widget non grid');
    return position;
  }
}

import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetActionMonitorDam } from './actions/WidgetActionMonitorDam';
import { WidgetDamMonitoring } from './WidgetDamMonitoring';
import { FvIconDamSync } from 'client/ui/icon/FvIconsDam';
import { WidgetDamMonitoringSearchFilterForm } from './filters/WidgetDamMonitoringSearchFilterForm';
import { damMonitoringDefaultFilters } from './WidgetDamMonitoringDefaultFilters';
import { WidgetDamMonitoringProfile } from './WidgetDamMonitoringProfile';

export const WidgetDamMonitoringType: IWidgetType = {
  code: 'dam-mon-single',
  name: 'Monitoraggio Diga',
  getTitle: widget => {
    const data = widget.profile?.data as WidgetDamMonitoringProfile | undefined;
    return data?.damDescription;
  },
  icon: <FvIconDamSync />,
  component: WidgetDamMonitoring,
  filterComponent: WidgetDamMonitoringSearchFilterForm,
  defaultFilters: damMonitoringDefaultFilters,
  description: `Permette il monitoraggio dei dati della Diga`,
  actions: [WidgetActionMonitorDam]
};

WidgetRepository.register(WidgetDamMonitoringType);

import { IHistoryTag } from 'client/components/toolkit/components/history/HistoryTag';
import { AssignmentType } from './mapIdType';

// Riga 2 dell’Assegnazione: Data, ora e stato assegnazione,
// evidenziando possibilmente gli Stati di lavorazione delle assegnazioni con colori diversi,
// es: Rosso per le assegnazioni Da eseguire,
// Giallo per quelle Prese in carico ecc…
// (In tal caso andrebbe abbandonato il colore per differenziare
//   le Assegnazioni per Competenza, Conoscenza e Smistamento)

export function convertIdStateToStateTagComponents(
  idState?: string
): IHistoryTag {
  switch (idState) {
    case AssignmentState.DaEseguireCompetenza:
    case AssignmentState.DaEseguireConoscenza:
    case AssignmentState.DaEseguireSmistamento:
      return { label: 'Da Eseguire', color: 'red' };
    case AssignmentState.InCarico:
      return { label: 'In Carico', color: 'orange' };
    case AssignmentState.Restituita:
      return { label: 'Restituita', color: 'gold' };
    case AssignmentState.Conclusa:
      return { label: 'Conclusa', color: 'green' };
    case AssignmentState.InFascicolo:
      return { label: 'In Fascicolo', color: 'blue' };
    case AssignmentState.Cancellata:
      return { label: 'Cancellata', color: 'purple' };
    default:
      return { label: '', color: '', isHidden: true };
  }
}
function convertTagColorToHex(color?: string): string {
  switch (color) {
    case 'red':
      return '#cf1322';
    case 'orange':
      return '#d46b08';
    case 'gold':
      return '#d48806';
    case 'green':
      return '#389e0d';
    case 'blue':
      return '#0958d9';
    case 'purple':
      return '#531dab';
    default:
      return '#606060';
  }
}

export function mapIdState(idState?: string) {
  return convertIdStateToStateTagComponents(idState).label;
}
export function mapIdStateColor(idState?: string) {
  return convertTagColorToHex(
    convertIdStateToStateTagComponents(idState).color
  );
}

export enum AssignmentState {
  DaEseguireCompetenza = '0',
  DaEseguireConoscenza = '1',
  DaEseguireSmistamento = '2',
  InCarico = '3',
  Restituita = '4',
  Conclusa = '5',
  InFascicolo = '6',
  Cancellata = '7'
}

/**
 * Gli id dello stato Da Eseguire dipende dal tipo di assegnazione,
 * di conseguenza è necessario convertire l'id dello stato.
 */
export function getAssignmentStateId(idType?: string, idState?: string) {
  const assignmentStateDaEseguire = getIdStateFromIdType(idType);

  switch (idState) {
    case AssignmentState.DaEseguireCompetenza:
    case AssignmentState.DaEseguireConoscenza:
    case AssignmentState.DaEseguireSmistamento:
      return assignmentStateDaEseguire;
    default:
      return idState;
  }
}

/**
 * Restituisce l'id dello stato da eseguire in base al tipo di assegnazione.
 */
function getIdStateFromIdType(idType?: string) {
  switch (idType) {
    case AssignmentType.Competenza:
      return AssignmentState.DaEseguireCompetenza;
    case AssignmentType.Conoscenza:
      return AssignmentState.DaEseguireConoscenza;
    case AssignmentType.Smistamento:
      return AssignmentState.DaEseguireSmistamento;
    default:
      return AssignmentState.DaEseguireCompetenza;
  }
}

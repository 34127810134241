import {
  getDocumentaryDam,
  getGetDocumentaryDamQueryKey
} from 'client/api/backend/documentary-dam/documentary-dam';
import { GetDocumentaryDamParams } from 'client/api/backend/schemas';
import { ErrorType } from 'client/core/network/mutators/apiClient';
import {
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult
} from 'react-query';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

export const InfiniteDamsSearchPageStart = 0;

export const useInfiniteDamsSearch = <
  TData = AsyncReturnType<typeof getDocumentaryDam>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDamParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      AsyncReturnType<typeof getDocumentaryDam>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryDamQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDam>
  > = ctx =>
    getDocumentaryDam({
      ...params!,
      pageNumber: ctx.pageParam ?? InfiniteDamsSearchPageStart
    });

  const query = useInfiniteQuery<
    AsyncReturnType<typeof getDocumentaryDam>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

import { Tag } from 'antd';
import { DeskDocument } from 'client/api/backend/schemas';
import { DocumentMode } from 'client/components/widget-repository/objects/documents/DocumentMode';
import * as React from 'react';

export interface IDocumentModeMetadataProps {
  documentType?: string;
}

export function DocumentModeMetadata(props: IDocumentModeMetadataProps) {
  switch (props.documentType) {
    case DocumentMode.Incoming:
      return <Tag color="blue">Protocollo in Entrata</Tag>;
    case DocumentMode.Outgoing:
      return <Tag color="orange">Protocollo in Uscita</Tag>;
    case DocumentMode.Internal:
      return <Tag color="gray">Protocollo Interno</Tag>;
    default:
      return null;
  }
}

import React from 'react';
import styled from 'styled-components';

const VSpace = styled.div<{ size?: number }>`
  width: 100%;
  height: 1px;
  margin-bottom: ${props => (props.size ? props.size - 1 : 23)}px;
`;

export interface VerticalSpaceProps {
  size?: number;
}

/**
 * Spazio distanziatore verticale.
 */
export function VerticalSpace(props: VerticalSpaceProps) {
  return <VSpace size={props.size}></VSpace>;
}

import { Drawer } from 'antd';
import React from 'react';

import styled from 'styled-components';

export const BaseDrawerStyle = styled(Drawer)`
  .ant-drawer-header {
    padding: 10px;
  }
  .ant-drawer-body {
    padding: 0;
  }
  .ant-drawer-title {
    line-height: 1.3;
  }
`;

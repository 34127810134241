/**
 * Generato automaticamente.
 * DeskUI REST API
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  DeskMail,
  GetDocumentaryMailsParams,
  DeskMailElement,
  GetDocumentaryMailsAttachmentParams,
  DeskMailbox,
  GetDocumentaryMailsBoxesParams,
  MailNoteRequestDTO,
  MailProtocolResponseDTO,
  MailProtocolRequestDTO,
  OptionItem,
  GetDocumentaryMailsTypesParams,
  GetDocumentaryMailsIdParams,
  DeleteDocumentaryMailsIdParams
} from '.././schemas';
import {
  apiInstance,
  ErrorType
} from '../../../core/network/mutators/apiClient';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Ritorna la lista delle mail
 */
export const getDocumentaryMails = (params?: GetDocumentaryMailsParams) => {
  return apiInstance<DeskMail[]>({
    url: `/api/v1/documentary/mails`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryMailsQueryKey = (
  params?: GetDocumentaryMailsParams
) => [`/api/v1/documentary/mails`, ...(params ? [params] : [])];

export type GetDocumentaryMailsQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryMails>
>;
export type GetDocumentaryMailsQueryError = ErrorType<void>;

export const useGetDocumentaryMails = <
  TData = AsyncReturnType<typeof getDocumentaryMails>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryMailsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryMails>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryMailsQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryMails>
  > = () => getDocumentaryMails(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryMails>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna l'allegato della mail
 */
export const getDocumentaryMailsAttachment = (
  params?: GetDocumentaryMailsAttachmentParams
) => {
  return apiInstance<DeskMailElement>({
    url: `/api/v1/documentary/mails/attachment`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryMailsAttachmentQueryKey = (
  params?: GetDocumentaryMailsAttachmentParams
) => [`/api/v1/documentary/mails/attachment`, ...(params ? [params] : [])];

export type GetDocumentaryMailsAttachmentQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryMailsAttachment>
>;
export type GetDocumentaryMailsAttachmentQueryError = ErrorType<void>;

export const useGetDocumentaryMailsAttachment = <
  TData = AsyncReturnType<typeof getDocumentaryMailsAttachment>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryMailsAttachmentParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryMailsAttachment>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryMailsAttachmentQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryMailsAttachment>
  > = () => getDocumentaryMailsAttachment(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryMailsAttachment>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna la lista delle caselle mail
 */
export const getDocumentaryMailsBoxes = (
  params?: GetDocumentaryMailsBoxesParams
) => {
  return apiInstance<DeskMailbox[]>({
    url: `/api/v1/documentary/mails/boxes`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryMailsBoxesQueryKey = (
  params?: GetDocumentaryMailsBoxesParams
) => [`/api/v1/documentary/mails/boxes`, ...(params ? [params] : [])];

export type GetDocumentaryMailsBoxesQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryMailsBoxes>
>;
export type GetDocumentaryMailsBoxesQueryError = ErrorType<void>;

export const useGetDocumentaryMailsBoxes = <
  TData = AsyncReturnType<typeof getDocumentaryMailsBoxes>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryMailsBoxesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryMailsBoxes>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryMailsBoxesQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryMailsBoxes>
  > = () => getDocumentaryMailsBoxes(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryMailsBoxes>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Aggiunge nota alla mail
 */
export const postDocumentaryMailsNote = (
  mailNoteRequestDTO: MailNoteRequestDTO
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/mails/note`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: mailNoteRequestDTO
  });
};

export type PostDocumentaryMailsNoteMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryMailsNote>
>;
export type PostDocumentaryMailsNoteMutationBody = MailNoteRequestDTO;
export type PostDocumentaryMailsNoteMutationError = ErrorType<unknown>;

export const usePostDocumentaryMailsNote = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryMailsNote>,
    TError,
    { data: MailNoteRequestDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryMailsNote>,
    { data: MailNoteRequestDTO }
  > = props => {
    const { data } = props || {};

    return postDocumentaryMailsNote(data);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryMailsNote>,
    TError,
    { data: MailNoteRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Protocolla la mail identificata
 */
export const postDocumentaryMailsProtocol = (
  mailProtocolRequestDTO: MailProtocolRequestDTO
) => {
  return apiInstance<MailProtocolResponseDTO | void>({
    url: `/api/v1/documentary/mails/protocol`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: mailProtocolRequestDTO
  });
};

export type PostDocumentaryMailsProtocolMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryMailsProtocol>
>;
export type PostDocumentaryMailsProtocolMutationBody = MailProtocolRequestDTO;
export type PostDocumentaryMailsProtocolMutationError = ErrorType<unknown>;

export const usePostDocumentaryMailsProtocol = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryMailsProtocol>,
    TError,
    { data: MailProtocolRequestDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryMailsProtocol>,
    { data: MailProtocolRequestDTO }
  > = props => {
    const { data } = props || {};

    return postDocumentaryMailsProtocol(data);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryMailsProtocol>,
    TError,
    { data: MailProtocolRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Ritorna la lista dei tipi documento nelle mail
 */
export const getDocumentaryMailsTypes = (
  params?: GetDocumentaryMailsTypesParams
) => {
  return apiInstance<OptionItem[]>({
    url: `/api/v1/documentary/mails/types`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryMailsTypesQueryKey = (
  params?: GetDocumentaryMailsTypesParams
) => [`/api/v1/documentary/mails/types`, ...(params ? [params] : [])];

export type GetDocumentaryMailsTypesQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryMailsTypes>
>;
export type GetDocumentaryMailsTypesQueryError = ErrorType<void>;

export const useGetDocumentaryMailsTypes = <
  TData = AsyncReturnType<typeof getDocumentaryMailsTypes>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryMailsTypesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryMailsTypes>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryMailsTypesQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryMailsTypes>
  > = () => getDocumentaryMailsTypes(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryMailsTypes>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna la mail identificata
 */
export const getDocumentaryMailsId = (
  id: number,
  params?: GetDocumentaryMailsIdParams
) => {
  return apiInstance<DeskMail>({
    url: `/api/v1/documentary/mails/${id}`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryMailsIdQueryKey = (
  id: number,
  params?: GetDocumentaryMailsIdParams
) => [`/api/v1/documentary/mails/${id}`, ...(params ? [params] : [])];

export type GetDocumentaryMailsIdQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryMailsId>
>;
export type GetDocumentaryMailsIdQueryError = ErrorType<void>;

export const useGetDocumentaryMailsId = <
  TData = AsyncReturnType<typeof getDocumentaryMailsId>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetDocumentaryMailsIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryMailsId>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryMailsIdQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryMailsId>
  > = () => getDocumentaryMailsId(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryMailsId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Cancella la mail identificata
 */
export const deleteDocumentaryMailsId = (
  id: number,
  params?: DeleteDocumentaryMailsIdParams
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/mails/${id}`,
    method: 'delete',
    params
  });
};

export type DeleteDocumentaryMailsIdMutationResult = NonNullable<
  AsyncReturnType<typeof deleteDocumentaryMailsId>
>;

export type DeleteDocumentaryMailsIdMutationError = ErrorType<unknown>;

export const useDeleteDocumentaryMailsId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteDocumentaryMailsId>,
    TError,
    { id: number; params?: DeleteDocumentaryMailsIdParams },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deleteDocumentaryMailsId>,
    { id: number; params?: DeleteDocumentaryMailsIdParams }
  > = props => {
    const { id, params } = props || {};

    return deleteDocumentaryMailsId(id, params);
  };

  return useMutation<
    AsyncReturnType<typeof deleteDocumentaryMailsId>,
    TError,
    { id: number; params?: DeleteDocumentaryMailsIdParams },
    TContext
  >(mutationFn, mutationOptions);
};

import { HighlightOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { DeskDocument } from 'client/api/backend/schemas';
import { DeskWidgetsLogic } from 'client/components/schema/desk/logic/DeskWidgetsLogic';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { DocumentToolkitObject } from 'client/components/widget-repository/objects/documents/DocumentToolkitObject';
import { set } from 'client/core/data/set';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { yup } from 'common/validation/initYup';
import React from 'react';
import { WidgetHighlightedDocumentsType } from '../WidgetHighlightedDocuments.type';
import { WidgetHighlightedDocumentsProfile } from '../WidgetHighlightedDocumentsProfile';

export const WidgetActionHighlightDocument = createToolkitAction({
  name: 'Evidenzia documento',
  code: 'highlight-document',
  icon: <HighlightOutlined />,
  arguments: [
    {
      type: [DocumentToolkitObject],
      name: 'Documento',
      allows: (object: ToolkitObject<DeskDocument>) =>
        object.data.protocolNumber != null
    },
    {
      name: 'Widget Protocolli in Evidenzia',
      type: [WidgetToolkitObject],
      allows: (object: ToolkitObject) =>
        WidgetToolkitObject.matches(object, WidgetHighlightedDocumentsType)
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    documentObject: ToolkitObject,
    highlightedObject: ToolkitObject | null
  ) {
    const document = DocumentToolkitObject.get(documentObject)!;
    const highlighted = WidgetToolkitObject.get(highlightedObject);

    // A. Se stiamo trascinando su un Widget già esistente..
    if (highlighted) {
      await onConfirm(ctx, {}, document, highlighted);
      return;
    }

    // B. Se esiste almeno un Widget di tipo Evidenzia Documento..
    const hasHiglightWidgets = ctx.deskContext.widgets.some(
      w => w.profile?.typeCode === WidgetHighlightedDocumentsType.code
    );
    if (hasHiglightWidgets) {
      ctx.deskContext.actionMessages.prompt({
        ctx,
        args: [document, highlighted],
        action: WidgetActionHighlightDocument,
        schema: HighlightDocumentSchema,
        confirmText: 'Metti in Evidenza',
        onConfirm
      });
      return;
    }

    // C. Se non esiste almeno un Widget di tipo Evidenzia Documento, lo creiamo
    const data: WidgetHighlightedDocumentsProfile = {
      documents: [document.data]
    };

    await ctx.deskContext.cloneWidget(
      {
        type: 'floating',
        expanded: true,
        order: DeskWidgetsLogic.getFloatingNextOrder(
          ctx.deskContext.floatingWidgets
        )
      },
      WidgetHighlightedDocumentsType,
      ctx.sourceWidget!,
      { data, quickClose: false }
    );
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: any,
  document: ToolkitObject,
  highlighted: ToolkitObject
) {
  try {
    const widget = ctx.deskContext.widgets.find(w => w.id === highlighted.id);
    const profile = widget?.profile;
    const data = profile?.data as WidgetHighlightedDocumentsProfile | null;

    const addedDocument = document.data;

    if (data?.documents.some(a => a.id === addedDocument.id)) {
      message.warning('Documento già presente in evidenza');
      return false;
    }

    await ctx.deskContext.updateWidget(
      set(widget!, 'profile.data.documents', [
        ...(data?.documents ?? []),
        addedDocument
      ])
    );

    message.success('Documento salvato in evidenza');
  } catch (e) {
    message.error(
      getNetworkErrorMessage(e, 'Impossibile evidenziare il documento')
    );
    console.error(e);
    return false;
  }
}

const HighlightDocumentSchema = yup.object({});

import { MsalProvider } from '@azure/msal-react';
import { appConfig } from 'client/core/appConfig';
import * as React from 'react';
import { useEffect } from 'react';
import { AuthMethod } from '../AuthMethods';
import { AuthSubject } from '../SsoContextProvider';
import { msalInstance } from './azureAuthConfig';

export interface IAzureProviderProps {
  children?: React.ReactNode;
  authSubject: AuthSubject;
}

/**
 * Rendering condizionale del provider di autenticazione Azure, solamente
 * se l'applicazione è configurata per l'autenticazione Azure
 */
export function AzureProvider(props: IAzureProviderProps) {
  const { authSubject } = props;

  useEffect(() => {
    if (appConfig.ssoMethod !== AuthMethod.Azure) {
      return;
    }

    // Gestisce il logout dell'utente corrente da MSAL (Azure)
    const subscription = authSubject.subscribe(event => {
      if (event.type === 'logout') {
        msalInstance.logoutRedirect({
          onRedirectNavigate: url => {
            // Return false if you would like to stop navigation after local logout
            return false;
          }
        });
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [authSubject]);

  if (appConfig.ssoMethod !== AuthMethod.Azure) {
    return <>{props.children}</>;
  }

  return <MsalProvider instance={msalInstance}>{props.children}</MsalProvider>;
}

import React from 'react';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import {
  SelectInput,
  SelectInputProps,
  SelectOption
} from 'client/ui/form/input/SelectInput';
import { DocumentSearchMode } from './DocumentSearchMode';

const DocumentSearchModeOptions = [
  { label: 'Fascicolati', value: DocumentSearchMode.Dossier },
  { label: 'Registrati', value: DocumentSearchMode.Registry },
  { label: 'Assegnati', value: DocumentSearchMode.Assignment }
];

interface Props extends SelectInputProps<any> {}

export function DocumentSearchModeInput(props: Props) {
  return (
    <SelectInput showSearch options={DocumentSearchModeOptions} {...props} />
  );
}

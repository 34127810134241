import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, ButtonProps, message, Space, Steps } from 'antd';
const { Step } = Steps;
import { WidgetStepPanelProps } from './WidgetStepPanel';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { useFormikContext } from 'formik';

const ButtonsFlexRow = styled.div`
  padding: 6px 12px;
  display: flex;
  justify-content: space-between;
`;

const StyledSpeps = styled(Steps)`
  .ant-steps-item {
    .ant-steps-item-tail {
      margin-left: 10px;
    }
    .ant-steps-item-icon {
      margin-left: 7px;
    }
    .ant-steps-item-content {
      width: 20px;
    }
  }

  // Fix per passaggio automatico a versione verticale di antd
  // in visualizzazione MOBILE (xs)
  &&&.ant-steps-vertical {
    flex-direction: row;

    > .ant-steps-item {
      display: inline-block;
      flex: 1;
      overflow: visible;
      &:last-child {
        flex: none;
        .ant-steps-item-container > .ant-steps-item-tail,
        .ant-steps-item-container > .ant-steps-item-content,
        .ant-steps-item-container
          > .ant-steps-item-content
          > .ant-steps-item-title::after {
          display: none;
        }
      }
      > .ant-steps-item-container {
        > .ant-steps-item-tail {
          top: 2px;
          width: 100%;
          margin: 0;
          left: 9px;
          padding: 0 0 6px;
          &::after {
            width: calc(100% - 20px);
            height: 3px;
            margin-left: 12px;
          }
        }
        .ant-steps-item-icon {
          float: none;
          margin: 0 0 0 7px;
        }

        .ant-steps-item-content {
          min-height: 0;
          margin-top: 8px;
        }
      }
    }

    > .ant-steps-item:not(:last-child)
      > .ant-steps-item-container
      > .ant-steps-item-tail {
      display: block;
    }
  }
`;

const StepsWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: -4px;
`;
const StepBody = styled.div`
  padding: ${props => props.theme.cardPaddingBase};
`;
const StepTitle = styled.div`
  padding-bottom: 4px;
  font-weight: 500;
  text-align: center;
  color: ${props => props.theme.primaryColor};
`;

export interface IWidgetStepsProps {
  children:
    | (React.ReactElement<WidgetStepPanelProps> | false)[]
    | React.ReactElement<WidgetStepPanelProps>;
  cancelButton?: React.ReactNode;
  finishButton?: React.ReactNode;
  finishButtonProps?: ButtonProps;
  cancelButtonProps?: ButtonProps;
}

export function WidgetSteps(props: IWidgetStepsProps) {
  const [current, setCurrent] = useState(0);
  const { finishButtonProps, cancelButtonProps } = props;
  const formik = useFormikContext();

  // Trasformiamo il children in un array
  const unfilteredChildren = Array.isArray(props.children)
    ? props.children
    : [props.children];

  const children = unfilteredChildren.filter(
    (c): c is React.ReactElement<WidgetStepPanelProps> => c !== false
  );

  const next = () => setCurrent(current + 1);
  const prev = () => setCurrent(current - 1);

  const onChange = (value: number) => {
    setCurrent(value);
  };

  return (
    <WidgetCardBody
      top={
        <StepsWrapper>
          <StyledSpeps
            progressDot
            current={current}
            onChange={onChange}
            size="small"
          >
            {children.map(item => {
              return <Step key={item.props.stepKey} />;
            })}
          </StyledSpeps>
          <StepTitle>{children[current].props.title}</StepTitle>
        </StepsWrapper>
      }
      bottom={
        <ButtonsFlexRow>
          <Space>
            <Button children="Pulisci" {...cancelButtonProps} />
            <Button
              disabled={current < 1}
              icon={<LeftOutlined />}
              onClick={() => prev()}
            />
            <Button
              disabled={current === children.length - 1}
              icon={<RightOutlined />}
              onClick={() => next()}
            />
          </Space>
          <Space>
            <Button
              children="Invia"
              type="primary"
              htmlType="submit"
              onClick={() =>
                Object.keys(formik.errors).length == 0 ? setCurrent(0) : null
              }
              {...finishButtonProps}
            />
          </Space>
        </ButtonsFlexRow>
      }
    >
      <StepBody>
        {children.map((step, i) => {
          return (
            <div
              key={i}
              style={{ display: i === current ? 'inherit' : 'none' }}
            >
              {step.props.children}
            </div>
          );
        })}
      </StepBody>
    </WidgetCardBody>
  );
}

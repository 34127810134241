/**
 * Generato automaticamente.
 * DeskUI REST API
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  DeskRegistry,
  GetDocumentaryRegistriesParams,
  OptionItem,
  GetDocumentaryRegistriesTypesParams,
  GetDocumentaryRegistriesIdModesParams
} from '.././schemas';
import {
  apiInstance,
  ErrorType
} from '../../../core/network/mutators/apiClient';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Ritorna la lista dei registri
 */
export const getDocumentaryRegistries = (
  params?: GetDocumentaryRegistriesParams
) => {
  return apiInstance<DeskRegistry[]>({
    url: `/api/v1/documentary/registries`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryRegistriesQueryKey = (
  params?: GetDocumentaryRegistriesParams
) => [`/api/v1/documentary/registries`, ...(params ? [params] : [])];

export type GetDocumentaryRegistriesQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryRegistries>
>;
export type GetDocumentaryRegistriesQueryError = ErrorType<void>;

export const useGetDocumentaryRegistries = <
  TData = AsyncReturnType<typeof getDocumentaryRegistries>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryRegistriesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryRegistries>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryRegistriesQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryRegistries>
  > = () => getDocumentaryRegistries(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryRegistries>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Recupera i tipi di registro
 */
export const getDocumentaryRegistriesTypes = (
  params?: GetDocumentaryRegistriesTypesParams
) => {
  return apiInstance<OptionItem[]>({
    url: `/api/v1/documentary/registries/types`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryRegistriesTypesQueryKey = (
  params?: GetDocumentaryRegistriesTypesParams
) => [`/api/v1/documentary/registries/types`, ...(params ? [params] : [])];

export type GetDocumentaryRegistriesTypesQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryRegistriesTypes>
>;
export type GetDocumentaryRegistriesTypesQueryError = ErrorType<void>;

export const useGetDocumentaryRegistriesTypes = <
  TData = AsyncReturnType<typeof getDocumentaryRegistriesTypes>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryRegistriesTypesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryRegistriesTypes>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryRegistriesTypesQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryRegistriesTypes>
  > = () => getDocumentaryRegistriesTypes(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryRegistriesTypes>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Recupera le modalità di registrazione
 */
export const getDocumentaryRegistriesIdModes = (
  id: number,
  params?: GetDocumentaryRegistriesIdModesParams
) => {
  return apiInstance<OptionItem[]>({
    url: `/api/v1/documentary/registries/${id}/modes`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryRegistriesIdModesQueryKey = (
  id: number,
  params?: GetDocumentaryRegistriesIdModesParams
) => [
  `/api/v1/documentary/registries/${id}/modes`,
  ...(params ? [params] : [])
];

export type GetDocumentaryRegistriesIdModesQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryRegistriesIdModes>
>;
export type GetDocumentaryRegistriesIdModesQueryError = ErrorType<void>;

export const useGetDocumentaryRegistriesIdModes = <
  TData = AsyncReturnType<typeof getDocumentaryRegistriesIdModes>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetDocumentaryRegistriesIdModesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryRegistriesIdModes>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryRegistriesIdModesQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryRegistriesIdModes>
  > = () => getDocumentaryRegistriesIdModes(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryRegistriesIdModes>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

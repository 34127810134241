import React from 'react';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import { useField } from 'formik';

interface IDocumentSignatureTypeSelectInputProps
  extends SelectInputProps<any> {}

export function DocumentSignatureTypeSelectInput(
  props: IDocumentSignatureTypeSelectInputProps
) {
  const { name } = props;
  const [field, meta, helpers] = useField<string | null>(name);

  function signatureTypeOptions() {
    return [
      { label: 'PAdES', value: 'true' },
      { label: 'CAdES', value: 'false' }
    ];
  }

  return (
    <SelectInput
      allowClear
      options={signatureTypeOptions()}
      value={field.value?.toString()}
      {...props}
    />
  );
}

import { UploadOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { postDocumentaryDocumentsUpload } from 'client/api/backend/documentary-documents/documentary-documents';
import { getGetDocumentaryDossiersIdFilesQueryKey } from 'client/api/backend/documentary-dossiers/documentary-dossiers';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import {
  getToolkitObjectOfType,
  ToolkitObject
} from 'client/components/toolkit/objects/ToolkitObject';
import { DossierToolkitObject } from 'client/components/widget-repository/objects/dossiers/DossierToolkitObject';
import {
  ExtendedDeskFolder,
  FolderToolkitObject
} from 'client/components/widget-repository/objects/folders/FolderToolkitObject';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { queryClient } from 'client/core/network/queryClient';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { UploadDraggerBase64Input } from 'client/ui/form/input/UploadDraggerBase64Input';
import { yup } from 'common/validation/initYup';
import React from 'react';
import { InferType } from 'yup';
import { PersonalDossierToolkitObject } from '../../../personal-dossier/objects/PersonalDossierToolkitObject';

export const WidgetActionUploadDocumentToDossier = createToolkitAction({
  name: 'Carica Documento',
  code: 'upload-document-to-dossier',
  icon: <UploadOutlined />,
  arguments: [
    {
      name: 'Cartella',
      type: [
        PersonalDossierToolkitObject,
        DossierToolkitObject,
        FolderToolkitObject
      ]
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    folderObject: ToolkitObject | null
  ) {
    let folder = getToolkitObjectOfType(folderObject, [
      PersonalDossierToolkitObject.code,
      DossierToolkitObject.code,
      FolderToolkitObject.code
    ]);

    ctx.deskContext.actionMessages.prompt({
      ctx,
      args: [folder],
      action: WidgetActionUploadDocumentToDossier,
      title: 'Carica documento nel fascicolo',
      confirmText: 'Carica',
      schema: UploadDocumentToDossierSchema,
      onConfirm,
      form: (
        <>
          <FormFieldsContainer>
            <FormFieldItem
              size="middle"
              component={TextInput}
              label={null}
              name="subject"
              placeholder="Oggetto"
            />
          </FormFieldsContainer>
          <FormFieldsContainer removeTopTitleSpacing columns={1} gutter={8}>
            <FormFieldItem
              component={UploadDraggerBase64Input}
              name="content"
              options={{
                type: 'single',
                filenameFieldName: 'filename'
              }}
              label={null}
              descriptionText="Fai click o trascina un file per caricare il documento"
            />
          </FormFieldsContainer>
          <FormFieldsContainer>
            <FormFieldItem
              size="large"
              component={TextAreaInput}
              label={null}
              name="notes"
              placeholder="Note"
            />
          </FormFieldsContainer>
        </>
      )
    });
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: UploadDocumentToDossierSchemaType,
  folder: ToolkitObject<ExtendedDeskFolder>
) {
  try {
    await uploadDocument(values, folder);

    message.success('Documento caricato con successo');
  } catch (e) {
    message.error(
      getNetworkErrorMessage(
        e,
        `Impossibile caricare il documento, si è verificato un errore.`
      )
    );
    console.error(e);
    return false;
  }
}

async function uploadDocument(
  values: UploadDocumentToDossierSchemaType,
  folder: ToolkitObject<ExtendedDeskFolder>
) {
  await postDocumentaryDocumentsUpload({
    folderId: folder.id as number,
    subject: values.subject,
    content: values.content,
    filename: values.filename,
    notes: values.notes,
    widgetId: folder.sourceWidgetId
  });

  const deskFilesQueryKey = getGetDocumentaryDossiersIdFilesQueryKey(
    folder.id as number,
    {
      folderId: folder.id as number,
      widgetId: folder.sourceWidgetId
    }
  );

  queryClient.invalidateQueries({
    predicate: query => {
      return Boolean(
        query.queryKey &&
          Array.isArray(query.queryKey) &&
          query.queryKey[0] === deskFilesQueryKey[0] &&
          query.queryKey[1]?.folderId === folder.id
      );
    }
  });

  // Invalido la cache
  const dossierFilesQueryKey = getGetDocumentaryDossiersIdFilesQueryKey(
    folder.id as number
  )[0];

  queryClient.invalidateQueries({
    predicate: query => {
      return Boolean(
        query.queryKey &&
          Array.isArray(query.queryKey) &&
          query.queryKey[0] === dossierFilesQueryKey
      );
    }
  });
}

const UploadDocumentToDossierSchema = yup.object({
  subject: yup.string().required().label('Soggetto'),
  content: yup.string().required().label('Contenuto'),
  filename: yup.string().required().label('Nome file'),
  notes: yup.string().label('Note')
});
type UploadDocumentToDossierSchemaType = InferType<
  typeof UploadDocumentToDossierSchema
>;

import { GroupOutlined, TeamOutlined } from '@ant-design/icons';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { WidgetList } from 'client/components/toolkit/components/list/WidgetList';
import { useRegisterToolkitObject } from 'client/components/toolkit/objects/store/useToolkitObjectStore';
import { ToolkitObjectBottomZone } from 'client/components/toolkit/objects/ToolkitObjectBottomZone';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { convertAssignmentModelItemToListElement } from '../../objects/assignment-models/convertAssignmentModelItemToListElement';
import { convertAssignmentModelToListElement } from '../../objects/assignment-models/convertAssignmentModelToListElement';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { WidgetAssignmentModelsProfile } from './WidgetAssignmentModelsProfile';

export interface IWidgetAssignmentModelsProps
  extends IWidgetComponentToolkitProps {}

export function WidgetAssignmentModels(props: IWidgetAssignmentModelsProps) {
  const { widget } = props;
  const data = widget.profile!.data as WidgetAssignmentModelsProfile | null;

  const [selected, setSelected] = useState(null as string | null);
  const selectedModel = data?.models?.find(model => model.uuid === selected);

  const models = useMemo(() => {
    return (
      data?.models?.map(model =>
        convertAssignmentModelToListElement(model, widget, model =>
          setSelected(model.object!.id as string)
        )
      ) ?? []
    );
  }, [data?.models, setSelected]);

  // Elementi selezionati
  const items = selected
    ? selectedModel?.assignees.map((item, index) =>
        convertAssignmentModelItemToListElement(
          selectedModel!,
          item,
          index,
          widget
        )
      )
    : null;

  // Permettiamo il drag&drop
  const widgetObject = WidgetToolkitObject.from(widget);
  useRegisterToolkitObject(widgetObject);

  return (
    <WidgetCardBody bottom={<ToolkitObjectBottomZone object={widgetObject} />}>
      <WidgetList
        list={items ? items : models}
        backElement={models.find(model => model.object!.id === selected)}
        onBack={() => setSelected(null)}
        emptyMessage="Nessun modello di assegnazione creato"
      />
    </WidgetCardBody>
  );
}

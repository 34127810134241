import { appConfig } from 'client/core/appConfig';
import { User } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';

export const oidcAuthConfig: AuthProviderProps = {
  client_id: appConfig.oidcClientId,
  redirect_uri: appConfig.oidcRedirectUri,
  authority: appConfig.oidcAuthority,
  response_type: 'code',
  scope: 'openid profile email',
  onSigninCallback: (_user: User | void): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
  }
};

export function mapIdPriority(idPriority?: string) {
  switch (idPriority) {
    case '2':
      return 'Alta';
    case '3':
      return 'Media';
    case '4':
      return 'Bassa';
    default:
      return '';
  }
}

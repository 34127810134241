import { useGetDocumentaryDocumentsSendMeans } from 'client/api/backend/documentary-documents/documentary-documents';

/**
 * Restituisce la lista degli id dei metodi di spedizione di tipo mail
 */
export function useCorrespondentMailTransportIds(widgetId: number) {
  const transportsResult = useGetDocumentaryDocumentsSendMeans({
    mode: '',
    widgetId: widgetId
  });

  return (
    // I typeId 1 e 2 sono mail, pec o file di grandi dimensioni
    transportsResult.data
      ?.filter(t => {
        const typeId = t.typeId;
        return typeId === 1 || typeId === 2;
      })
      .map(t => t.id!) ?? []
  );
}

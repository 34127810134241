import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { useRelationEditor } from 'client/components/form/relations/useRelationEditor';
import { useDesk } from 'client/components/schema/desk/DeskModule';
import { useWidgetContext } from 'client/components/schema/widget/context/WidgetContext';
import { ToolkitObjectInput } from 'client/components/toolkit/objects/input/ToolkitObjectInput';
import { OfficeToolkitObject } from 'client/components/widget-repository/objects/offices/OfficeToolkitObject';
import {
  ISenderReceiverOffice,
  SenderReceiverOfficeMode,
  SenderReceiverOfficeType
} from 'client/components/widget-repository/objects/offices/SenderReceiverOffice';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { StyledCard } from 'client/ui/styled-card/StyledCard';
import React, { useMemo } from 'react';

export interface ISenderReceiverOfficeInputProps {
  name: string;
  type: SenderReceiverOfficeType;
  disabled?: boolean;
}

export function SenderReceiverOfficeInput(
  props: ISenderReceiverOfficeInputProps
) {
  const { name, type, disabled } = props;
  const title =
    type === SenderReceiverOfficeType.Receiver ? 'Destinatario' : 'Mittente';
  const editor = useRelationEditor<ISenderReceiverOffice>(name);
  const { widget } = useWidgetContext();
  const deskState = useDesk();

  const offices = useMemo(() => {
    return editor.items;
  }, [editor.items]);

  return (
    <div style={{ marginBottom: '12px', width: '100% ' }}>
      <Space>
        <div>
          <ButtonIcon
            size="small"
            icon={<PlusOutlined />}
            onClick={() =>
              editor.add({
                justAdded: true
              })
            }
          >
            <span style={{ paddingRight: '4px' }}>
              {`Aggiungi Ufficio ${title}`}
            </span>
          </ButtonIcon>
        </div>
      </Space>

      <Space
        size={12}
        align={deskState.isFullScreen ? 'start' : undefined}
        direction={deskState.isFullScreen ? 'horizontal' : 'vertical'}
        wrap={deskState.isFullScreen}
        style={{ width: '100%' }}
      >
        {offices.map((item, index) => {
          const isDisabled = disabled && !item.justAdded;

          return (
            <StyledCard
              key={index}
              size="small"
              extra={
                <ButtonIcon
                  disabled={isDisabled}
                  icon={<DeleteOutlined />}
                  tooltip={`Rimuovi Ufficio ${title}`}
                  tooltipProps={{ placement: 'topRight' }}
                  danger
                  onClick={() => editor.remove(item)}
                />
              }
              title={`Ufficio ${title}`}
            >
              <FormFieldItem
                size="large"
                disabled={isDisabled}
                component={ToolkitObjectInput}
                objectType={OfficeToolkitObject}
                widgetId={widget.id!}
                name={`${name}.${index}.office`}
                label={`Uffici ${
                  type === SenderReceiverOfficeType.Receiver
                    ? 'Destinatari'
                    : 'Mittenti'
                }`}
                placeholder={`${title}`}
              />
              <FormFieldItem
                size="large"
                disabled={isDisabled}
                component={SelectInput}
                label={'Modalità'}
                name={`${name}.${index}.mode`}
                options={[
                  {
                    label: 'Competenza',
                    value: SenderReceiverOfficeMode.Competenza
                  },
                  {
                    label: 'Conoscenza',
                    value: SenderReceiverOfficeMode.Conoscenza
                  }
                ]}
                placeholder="Modalità"
              />
            </StyledCard>
          );
        })}
      </Space>
    </div>
  );
}

import { createGlobalStyle } from 'styled-components';

// Importato in Index.tsx
export const PageGlobalStyles = createGlobalStyle`
  
  html,
  body {
    zoom: 100%;
    height: 100vh;
    width: 100%;
    min-height: 100vh;
    height: -webkit-fill-available;
    min-height: -webkit-fill-available;
    overflow: hidden;
    position: fixed;
  }
`;

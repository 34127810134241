import React from 'react';
import styled from 'styled-components';
import { Button } from 'antd';

export const DeskButtonStyle = styled(Button)`
  padding-right: 12px;
  padding-left: 8px;
  vertical-align: top;
  &.ant-btn {
    > .anticon {
      + span {
        margin-left: 5px;
      }
    }
    &.selected {
      background-color: #fff;
      &:active,
      &:hover,
      &:focus {
        background-color: #fff;
      }
      &:active {
        border-color: ${props => props.theme.bodyBackground};
      }
    }
    &:active,
    &:focus {
      border-color: ${props => props.theme.bodyBackground};
      color: ${props => props.theme.textColor};
    }
    &:hover {
      border-color: ${props => props.theme.primaryColor};
    }

    // Menu Dropdown posizionati nel body (Desks Menu)
    .desk-menu-dropdown &,
    .ant-menu-submenu.ant-menu-submenu-popup & {
      background-color: transparent;
    }
    &.selected,
    &:active,
    &:hover,
    &:focus {
      .desk-menu-dropdown &,
      .ant-menu-submenu.ant-menu-submenu-popup & {
        background-color: transparent;
      }
    }
  }
  &.edit {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-right: 6px;
  }
`;

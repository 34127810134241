import { DeskUser, DeskOffice } from 'client/api/backend/schemas';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import {
  IAssignmentModel,
  AssignmentModelToolkitObject
} from '../../objects/assignment-models/AssignmentModelToolkitObject';

export type AssigneeToolKitObject =
  | ToolkitObject<DeskUser>
  | ToolkitObject<DeskOffice>
  | ToolkitObject<IAssignmentModel>;

export function getAssigneeChildren(
  object: AssigneeToolKitObject,
  typeId?: number
) {
  if (AssignmentModelToolkitObject.is(object)) {
    return object.data.assignees.map(item => ({
      entityId: item.assignee.data.ente,
      type: item.typeId,
      id: item.assignee.type !== 'office' ? item.assignee.data.id : undefined,
      officeId:
        item.assignee.type === 'office'
          ? item.assignee.data.id?.toString()
          : undefined
    }));
  }

  return [
    {
      entityId: object.data.ente,
      type: typeId,
      id: object.type !== 'office' ? object.data.id : undefined,
      officeId:
        object.type === 'office' ? object.data.id?.toString() : undefined
    }
  ];
}

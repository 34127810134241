import * as React from 'react';
import { useDesk } from 'client/components/schema/desk/DeskModule';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { RightSideMenu } from '../menu/right/RightSideMenu';
import { DeskEditModeButton } from './menu/DeskEditModeButton';
import { DeskSpace } from 'client/components/schema/desk/space/DeskSpace';
import { DeskContextProvider } from 'client/components/schema/desk/context/DeskContext';
import { RightSideMenuPortal } from 'client/routes/menu/right/RightSideMenuPortal';
import { DesksMenu } from './menu/desks/DesksMenu';

export interface DeskPageProps {}

export function DeskPage(props: DeskPageProps) {
  const deskState = useDesk();

  return (
    <PageLayout mainMenu={<DesksMenu />} rightMenu={<RightSideMenu />}>
      <DeskContextProvider deskId={deskState.selectedId!}>
        {/** Sezione del Menu che fa riferimento alla scrivania attuale */}
        <RightSideMenuPortal>
          <DeskEditModeButton />
        </RightSideMenuPortal>
        {/** L'effettivo spazio in cui sono contenuti i Widget, contiene sia la griglia che la Action Bar */}
        <DeskSpace />
      </DeskContextProvider>
    </PageLayout>
  );
}

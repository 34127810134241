import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { yup } from 'common/validation/initYup';
import * as React from 'react';
import * as WidgetFilterSections from 'client/components/toolkit/components/filter/WidgetFilterSections';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { Button, Space } from 'antd';
import { WidgetFilterDrawer } from 'client/components/toolkit/components/filter/WidgetFilterDrawer';
import { renderWidgetFilterActiveTags } from 'client/components/toolkit/components/filter/renderWidgetFilterActiveTags';
import { useState } from 'react';
import { GetDocumentaryAssignmentsParams } from 'client/api/backend/schemas';
import { AssignmentTypeRadioInput } from 'client/components/widget-repository/objects/assignments/AssignmentTypeRadioInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { DynamicDateRanges } from 'client/components/toolkit/components/filter/dynamic/DynamicDateRanges';
import { AssignmentStateSelectInput } from 'client/components/widget-repository/objects/assignments/AssignmentStateSelectInput';

export interface IWidgetAssignmentsFilterFormProps {
  widgetId: number;
  /** Di base è `profile.filters`. */
  prefix: string;
  filters: any;
}

export function WidgetAssignmentsFilterForm(
  props: IWidgetAssignmentsFilterFormProps
) {
  const { prefix } = props;

  return (
    <>
      <FormFieldsContainer>
        <FormFieldItem
          component={AssignmentTypeRadioInput}
          label={'Tipo Assegnazione'}
          name={`${prefix}.assignmentTypeId`}
          colSpan={2}
          widgetId={props.widgetId}
          size="large"
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormFieldItem
          size="large"
          component={AssignmentStateSelectInput}
          label={'Stato'}
          name={`${prefix}.assignmentStateId`}
          typeFieldName={`${prefix}.assignmentTypeId`}
          widgetId={props.widgetId}
          placeholder="Stato"
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormFieldItem
          size="large"
          component={TextInput}
          label={'Oggetto'}
          name={`${prefix}.subject`}
          placeholder="Oggetto"
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={2} gutter={8}>
        <FormFieldItem
          size="large"
          component={SelectInput}
          options={DynamicDateRanges}
          label={'A partire da'}
          name={`${prefix}.dynamicStartDate`}
          placeholder="Da.."
        />
        <FormFieldItem
          size="large"
          component={SelectInput}
          options={DynamicDateRanges}
          label={'Fino a'}
          name={`${prefix}.dynamicEndDate`}
          placeholder="A.."
        />
      </FormFieldsContainer>
    </>
  );
}

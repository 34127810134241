import { lessThemeConverted } from './lessThemeConverted';

function getNumberFromCss(css: string) {
  return Number(css.replace(/\D/g, ''));
}

const size = {
  // Dimensioni del menu delle scrivanie (header)
  header: getNumberFromCss(lessThemeConverted.layoutHeaderHeight),
  // Dimensioni dell'Action Bar (footer)
  footer: 48
};

const minus = {
  // Padding verticali (header)
  top: 8,
  // Padding verticali (footer)
  bottom: 0
};
/**
 * Thema di default dell'applicativo
 *
 * Contiene le variabili utilizzate nell'applicativo
 * che possono essere sovrascritte per personalizzazione
 */
export const mainTheme = {
  logoWidth: '111px',
  logoHeight: '36px',
  logoUrl: require('./image/folium-desk.png'),

  loginLogoWidth: '160px',
  loginLogoHeight: '52px',
  loginLogoUrl: require('./image/folium-desk-white.png'),

  // Widget
  widgetBaseGap: 12 + 'px',
  widgetGap: 12 + 12 + 'px',
  widgetGridFixedTop: size.header - minus.top + 'px',
  widgetGridFixedBottom: size.footer - minus.bottom + 'px',
  widgetGridMinusHeigh:
    size.header - minus.top + (size.footer - minus.bottom) + 'px',
  widgetGridFixedSide: 0,
  widgetGridPadding: 12 + 'px',

  miniBarHeight: '24px',

  shadowBase:
    '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)'
};

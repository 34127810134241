import { ApartmentOutlined } from '@ant-design/icons';
import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetOrganizzationChart } from './WidgetOrganizzationChart';

export const WidgetOrganizzationChartType: IWidgetType = {
  code: 'organization-chart',
  name: 'Organigramma',
  icon: <ApartmentOutlined />,
  component: WidgetOrganizzationChart,
  description: `Mostra l'organigramma degli Uffici e degli Utenti.`
};

WidgetRepository.register(WidgetOrganizzationChartType);

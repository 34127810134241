import { Spin } from 'antd';
import { useGetDocumentaryDossiersAcl } from 'client/api/backend/documentary-dossiers/documentary-dossiers';
import { DeskGrant, Widget } from 'client/api/backend/schemas';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import { useField } from 'formik';
import { debounce } from 'lodash';
import * as React from 'react';
import { useCallback, useState } from 'react';

export interface IGrantInputProps extends SelectInputProps<any> {
  widget: Widget;
}

export function GrantInput(props: IGrantInputProps) {
  const { widget, ...otherProps } = props;

  const [search, setSearch] = useState(undefined as string | undefined);
  const [field, , helpers] = useField<number>(props.name);

  const grantsResult = useGetDocumentaryDossiersAcl(
    {
      widgetId: widget.id!,
      description: search || ''
    },
    { query: { keepPreviousData: true } }
  );

  const onChange = (value: number) => {
    helpers.setValue(value);
  };

  // Ricerca
  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearch(value);
    }, 800),
    [setSearch]
  );

  const options =
    grantsResult.data?.map(grant => ({
      label: grant.description,
      value: grant.id!
    })) ?? [];

  return (
    <SelectInput
      {...otherProps}
      showSearch
      value={field.value}
      onChange={onChange as any}
      loading={grantsResult.isLoading}
      options={options}
      onSearch={handleSearch}
      notFoundContent={
        grantsResult.isLoading ? (
          <Spin size="small" />
        ) : (
          'Nessun elemento trovato.'
        )
      }
    />
  );
}

import * as React from 'react';
import styled from 'styled-components';
import { Button, Card, Typography } from 'antd';
const { Text, Title } = Typography;
import { IWidgetType } from 'client/components/widget-repository/schema/WidgetType';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { PlusOutlined } from '@ant-design/icons';

const paddingCard = '12px';

const StyledAddWidgetCard = styled(Card)`
  width: 100%;
  display: flex;
  flex-direction: column;

  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05), 0 5px 20px 0 rgba(0, 0, 0, 0.04);

  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  .ant-card-body {
    height: 100%;
    padding: ${paddingCard};
    flex: 1 1 auto;
    overflow: hidden;
    min-height: 0;
    min-width: 0;
    border-bottom-left-radius: ${props => props.theme.cardRadius};
    border-bottom-right-radius: ${props => props.theme.cardRadius};
  }
`;

const Container = styled.div`
  display: flex;
  border-radius: ${props => props.theme.cardRadius};
`;
const IconWrapper = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  flex: 0 0 auto;
  min-width: 0;
  width: 24px;
  height: 24px;
  font-size: 24px;
  color: ${props => props.theme.textColorSecondary};
  border-radius: ${props => props.theme.cardRadius};
  margin-right: 12px;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 0;
  h3 {
    margin-bottom: 4px;
    margin-top: 3px;

    font-size: 14px;
    line-height: 1.3;
  }
  p {
    margin-bottom: 0px;
    color: ${props => props.theme.textColorSecondary};
    font-size: 12px;
    line-height: 1.3;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  align-self: stretch;
  flex: 0 0 auto;
  min-width: 0;
  margin-right: 0;
  margin-left: 8px;
  margin-top: -${paddingCard};
  margin-bottom: -${paddingCard};
  margin-right: -${paddingCard};
  border-left: 1px solid ${props => props.theme.borderColorBase};
  &&& {
    .add-button {
      height: 100%;
      width: 40px;
      border-radius: ${props => props.theme.cardRadius};
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      .anticon {
        font-size: 24px;
      }
    }
  }
`;

export interface AddWidgetCardProps {
  widgetType: IWidgetType;
  onClick: (widgetType: IWidgetType) => void;
}

/**
 * Elemento di visualizzazione ed inserimento
 * nella Scrivania del Widget
 */
export function AddWidgetCard(props: AddWidgetCardProps) {
  const { widgetType, onClick } = props;

  return (
    <StyledAddWidgetCard>
      <Container>
        <IconWrapper>{widgetType.icon}</IconWrapper>
        <ContentWrapper>
          <h3>{widgetType.name}</h3>
          <p>{widgetType.description}</p>
        </ContentWrapper>
        <ButtonWrapper>
          <ButtonIcon
            className="add-button"
            icon={<PlusOutlined />}
            onClick={() => onClick(widgetType)}
          />
        </ButtonWrapper>
      </Container>
    </StyledAddWidgetCard>
  );
}

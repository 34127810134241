import { IDeskContext } from 'client/components/schema/desk/context/DeskContext';
import * as React from 'react';
import { useMemo } from 'react';
import { ActionMessageContainer } from './message/ActionMessageContainer';
import { v4 as uuid } from 'uuid';

export interface IActionMessagesHubProps {
  context: IDeskContext;
}

/**
 * Gestisce i Messaggi derivanti dalle Azioni, ovvero quei punti in cui
 * è necessaria l'interazione con l'utente per proseguire
 */
export function ActionMessagesHub(props: IActionMessagesHubProps) {
  const { state } = props.context.actionMessages;
  const messages = useMemo(
    () => state.messages.map(m => <div key={m.id}>{m.content(m.id)}</div>),
    [state.messages]
  );

  return <ActionMessageContainer>{messages}</ActionMessageContainer>;
}

import { DeskAssignment, Widget } from 'client/api/backend/schemas';
import { AssignmentState } from 'client/components/widget-repository/objects/assignments/mapIdState';
import { convertAssignmentToWidgetListElement } from '../../monitor-assignments/convert/convertAssignmentToWidgetListElement';
import {
  HighlightedAssignmentToolkitObject,
  HighlightedDeskAssignment
} from '../objects/HighlightedAssignmentToolkitObject';

export function convertHighlightedAssignmentToWidgetListElement(
  assignment: DeskAssignment,
  localAssignment: HighlightedDeskAssignment,
  widget: Widget
) {
  const element = convertAssignmentToWidgetListElement(assignment, widget);

  const assignmentName =
    assignment.document?.subject ?? assignment.document?.filename ?? 'N.A.';

  const notes = localAssignment.highlighted?.notes?.trim() ?? '';

  element.details = notes;
  element.detailsExtend = {
    before: notes ? 'Note:' : '',
    bold: true,
    divider: ''
  };

  element.object!.related!.push(
    HighlightedAssignmentToolkitObject.create({
      id: assignment.id!,
      name: assignmentName,
      data: localAssignment,
      sourceWidgetId: widget.id!
    })
  );

  return element;
}

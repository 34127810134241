import { Tag } from 'antd';
import React from 'react';

interface StateTagProps {
  enabled?: boolean;
}

export function StateTag(props: StateTagProps) {
  if (props.enabled === true) {
    return <Tag color="green">ATTIVO</Tag>;
  }
  return <Tag color="red">NON ATTIVO</Tag>;
}

/**
 * Il value è una stringa altrimenti il filtro non funziona correttamente
 */
export function stateOptions() {
  return [
    { label: 'Attivo', value: 'true' },
    { label: 'Non Attivo', value: 'false' }
  ];
}

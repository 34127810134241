import {
  useGetDocumentaryStatisticsAssignments,
  useGetDocumentaryStatisticsAssignmentsDetails
} from 'client/api/backend/documentary-statistics/documentary-statistics';
import { GetDocumentaryStatisticsAssignmentsParams } from 'client/api/backend/schemas';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { useWidgetNetworkQueries } from 'client/components/schema/widget/context/useWidgetNetworkEffect';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import { MonitorChart } from 'client/components/toolkit/components/monitor/MonitorChart';
import { ChartPalettes } from 'client/components/toolkit/components/monitor/palette/ChartPalettes';
import { PaddedSkeleton } from 'client/ui/skeleton/PaddedSkeleton';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { convertAssignmentToWidgetListElement } from '../monitor-assignments/convert/convertAssignmentToWidgetListElement';
import {
  InfiniteStatisticsAssignmentsDetailsPageStart,
  useInfiniteStatisticsAssignmentsDetails
} from './api/useInfiniteStatisticsAssignmentsDetails';
import { applyWidgetMonitorOfficeAssignmentsDefaultFilters } from './filters/WidgetMonitorOfficeAssignmentsFilter';
import { WidgetMonitorOfficeAssignmentsSearchForm } from './filters/WidgetMonitorOfficeAssignmentsSearchForm';

const AssignmentStateLabels: Record<string, string> = {
  '0': 'Da eseguire',
  '1': 'Da eseguire',
  '2': 'Da eseguire',
  '3': 'In carico',
  '4': 'Restituite',
  '5': 'Concluse',
  '6': 'In Fascicolo',
  '7': 'Cancellate'
};

const StatisticsAssignmentsDetailsPageSize = 20;
export interface IWidgetMonitorOfficeAssignmentsProps
  extends IWidgetComponentToolkitProps {}

export function WidgetMonitorOfficeAssignments(
  props: IWidgetMonitorOfficeAssignmentsProps
) {
  const { widget } = props;

  const [search, setSearch] = useState<
    Partial<GetDocumentaryStatisticsAssignmentsParams>
  >({});
  const apiSearch = useMemo(
    () => ({
      ...applyWidgetMonitorOfficeAssignmentsDefaultFilters(widget),
      ...search,
      widgetId: widget.id!
    }),
    [search, widget.profile?.filters]
  );

  const monitoringResult = useGetDocumentaryStatisticsAssignments({
    ...(apiSearch as any),
    widgetId: props.widget.id!
  });

  // Palette di visualizzazione
  const palette = ChartPalettes.category;

  // Estrazione dei dati del monitoraggio per la visualizzazione nel grafico.
  const data = monitoringResult.data;
  const items = data?.map(item => ({
    key: item.key!,
    value: Number(item.value ?? 0),
    label: AssignmentStateLabels[item.key!] ?? item.key
  }));

  // Selezione della scheda di interesse per il dettaglio
  const [selected, setSelected] = useState(undefined as string | undefined);

  const detailApiSearch = useMemo(
    () => ({
      ...(apiSearch as any),
      pageNumber: InfiniteStatisticsAssignmentsDetailsPageStart,
      pageSize: StatisticsAssignmentsDetailsPageSize,
      widgetId: widget.id!,
      key: selected!
    }),
    [apiSearch, widget.profile?.filters, selected]
  );

  const detailResult = useInfiniteStatisticsAssignmentsDetails(
    detailApiSearch,
    {
      query: {
        enabled: selected != null,
        getNextPageParam: (lastPage, pages) =>
          lastPage.length < StatisticsAssignmentsDetailsPageSize
            ? undefined
            : pages.length + InfiniteStatisticsAssignmentsDetailsPageStart
      }
    }
  );

  const selectedList = useMemo(() => {
    const index = items?.findIndex(i => i.key === selected);
    return detailResult.data?.pages.flat()?.map(assignment => {
      return convertAssignmentToWidgetListElement(
        assignment,
        widget,
        AssignmentStateLabels[selected!] ?? selected,
        palette[index ?? -1]
      );
    });
  }, [detailResult.data, selected, widget]);

  useWidgetNetworkQueries([monitoringResult, detailResult]);

  return (
    <WidgetCardBody
      top={
        <WidgetMonitorOfficeAssignmentsSearchForm
          widget={widget}
          search={search}
          setSearch={setSearch}
        />
      }
    >
      <PaddedSkeleton loading={monitoringResult.isLoading} active>
        {items && (
          <MonitorChart
            items={items}
            selected={selected}
            selectedListLoading={detailResult.isLoading}
            setSelected={setSelected}
            selectedList={selectedList}
            palette={palette}
            recordLabels={AssignmentStateLabels}
            infinite={{ query: detailResult }}
          />
        )}
      </PaddedSkeleton>
    </WidgetCardBody>
  );
}

import { Spin } from 'antd';
import { useGetDocumentaryDossiersReferencesTypes } from 'client/api/backend/documentary-dossiers/documentary-dossiers';
import { DeskReference, Widget } from 'client/api/backend/schemas';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import { useField } from 'formik';
import { debounce } from 'lodash';
import * as React from 'react';
import { useCallback, useState } from 'react';

export interface IReferenceInputProps extends SelectInputProps<any> {
  widget: Widget;
}

export function ReferenceInput(props: IReferenceInputProps) {
  const { widget, ...otherProps } = props;

  const [search, setSearch] = useState(undefined as string | undefined);
  const [field, , helpers] = useField<DeskReference>(props.name);

  const referencesResult = useGetDocumentaryDossiersReferencesTypes(
    {
      widgetId: widget.id!,
      description: search || ''
    },
    { query: { keepPreviousData: true } }
  );

  const onChange = (value: number) => {
    helpers.setValue({ id: value });
  };

  // Ricerca
  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearch(value);
    }, 800),
    [setSearch]
  );

  const options =
    referencesResult.data?.map(reference => ({
      label: reference.label,
      value: reference.value!
    })) ?? [];

  return (
    <SelectInput
      {...otherProps}
      showSearch
      value={field.value}
      onChange={onChange as any}
      loading={referencesResult.isLoading}
      options={options}
      onSearch={handleSearch}
      notFoundContent={
        referencesResult.isLoading ? (
          <Spin size="small" />
        ) : (
          'Nessun elemento trovato.'
        )
      }
    />
  );
}

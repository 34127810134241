import * as React from 'react';

export function convertDossierStateIdToText(
  id?: string | number
): string | undefined {
  const textId = id?.toString();
  // /desk/api/v1/documentary/dossiers/states
  switch (textId) {
    case '1':
      return 'Aperta';
    case '2':
      return 'Chiusa';
    case '3':
      return 'Sospesa';
    case '4':
      return 'In lavorazione';
    case '5':
      return 'Archivio deposito';
    case '6':
      return 'Riesame RT';
    case '7':
      return 'Ricorso TAR';
    case '8':
      return 'Ricorso CDS';
    default:
      return undefined;
  }
}

import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { AuditOutlined } from '@ant-design/icons';
import { WidgetDocumentSendToProtocolQueue } from './WidgetDocumentSendToProtocolQueue';
import { WidgetActionSendToProtocolQueue } from './actions/WidgetActionSendToProtocolQueue';

export const WidgetDocumentSendToProtocolQueueType: IWidgetType = {
  code: 'document-send-to-protocol-queue',
  name: 'Invio documenti alla coda di Protocollazione',
  icon: <AuditOutlined />,
  component: WidgetDocumentSendToProtocolQueue,
  description: `Invio alla coda di Protocollazione dei documenti nel documentale`,
  actions: [WidgetActionSendToProtocolQueue]
};

WidgetRepository.register(WidgetDocumentSendToProtocolQueueType);

import {
  ArrowDownOutlined,
  DownOutlined,
  EllipsisOutlined,
  MoreOutlined
} from '@ant-design/icons';
import { Tag } from 'antd';
import { ToolkitObjectActionsMenu } from 'client/components/schema/widget/actions/interaction/ToolkitObjectActionsMenu';
import { useToolkitObjectStore } from 'client/components/toolkit/objects/store/useToolkitObjectStore';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import * as React from 'react';
import { useWidgetListMassiveContext } from './WidgetListMassiveContext';

export interface IWidgetListMassiveActionsProps {}

export function WidgetListMassiveActions(
  props: IWidgetListMassiveActionsProps
) {
  const massive = useWidgetListMassiveContext();
  const objectStore = useToolkitObjectStore();

  if (!massive.enabled || massive.selected.length === 0) return null;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '8px 16px'
      }}
    >
      <div>
        <Tag color="blue" closable onClose={() => massive.deselectAll()}>
          {massive.selected.length} selezionati
        </Tag>
      </div>
      <ToolkitObjectActionsMenu object={massive.selected}>
        <ButtonIcon icon={<MoreOutlined />} iconRight size="small">
          Azioni massive
        </ButtonIcon>
      </ToolkitObjectActionsMenu>
    </div>
  );
}

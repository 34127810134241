import { AppstoreOutlined } from '@ant-design/icons';
import { useDroppable } from '@dnd-kit/core';
import { Button, Card } from 'antd';
import { ToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import * as React from 'react';
import styled from 'styled-components';
import { useDeskContext } from '../../desk/context/DeskContext';
import { useDesk } from '../../desk/DeskModule';
import { isToolkitActionSupportedBy } from './interaction/isToolkitActionSupportedBy';

const StyledActionCard = styled(Card)<{ $isOver?: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;

  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05), 0 5px 20px 0 rgba(0, 0, 0, 0.04);

  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  .ant-card-body {
    height: 100%;
    padding: 0;
    flex: 1 1 auto;
    overflow: hidden;
    min-height: 0;
    min-width: 0;
    border-bottom-left-radius: ${props => props.theme.cardRadius};
    border-bottom-right-radius: ${props => props.theme.cardRadius};
  }
`;

const ActionButton = styled(Button)`
  border-radius: ${props => props.theme.cardRadius};
`;

export interface IActionCardProps {
  action: ToolkitAction<any>;
}

export function ActionCard(props: IActionCardProps) {
  const { action } = props;
  const deskState = useDesk();
  const deskContext = useDeskContext();
  const { isOver, setNodeRef } = useDroppable({
    id: action.code,
    disabled: !isToolkitActionSupportedBy(
      action,
      deskState.draggingItem?.object
    ),

    data: {
      action
    }
  });

  // Permette l'avvio dell'azione direttamente dalla barra
  const handleClick = async () => {
    if (!action.allowClickToStart) return;
    await action.execute({ deskContext });
  };

  return (
    <div ref={setNodeRef}>
      <StyledActionCard bordered={false} $isOver={isOver}>
        <ActionButton
          type={isOver ? 'primary' : 'link'}
          icon={action.icon ?? <AppstoreOutlined />}
          disabled={deskState.isEditable}
          onClick={handleClick}
        >
          {action.name}
        </ActionButton>
      </StyledActionCard>
    </div>
  );
}

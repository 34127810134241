import * as React from 'react';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { AssignmentTypeRadioInput } from 'client/components/widget-repository/objects/assignments/AssignmentTypeRadioInput';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { DynamicDateRanges } from 'client/components/toolkit/components/filter/dynamic/DynamicDateRanges';
import { ToolkitObjectInput } from 'client/components/toolkit/objects/input/ToolkitObjectInput';
import { OfficeToolkitObject } from 'client/components/widget-repository/objects/offices/OfficeToolkitObject';
import { AssignmentTypeSelectInput } from 'client/components/widget-repository/objects/assignments/AssignmentTypeSelectInput';
import { DocumentProtocolModeInput } from 'client/components/widget-repository/objects/documents/DocumentProtocolModeInput';
import { AssignmentMonitoringTypeSelectInput } from 'client/components/widget-repository/objects/assignment-monitoring/AssignmentMonitoringTypeSelectInput';
import { UserOfficeInput } from 'client/components/widget-repository/objects/offices/UserOfficeInput';

export interface IWidgetMonitorOfficeAssignmentsFilterFormProps {
  widgetId: number;
  /** Di base è `profile.filters`. */
  prefix: string;
  filters: any;
}

export function WidgetMonitorOfficeAssignmentsFilterForm(
  props: IWidgetMonitorOfficeAssignmentsFilterFormProps
) {
  const { prefix } = props;

  return (
    <>
      <FormFieldsContainer>
        <FormFieldItem
          component={AssignmentTypeRadioInput}
          label={'Tipo Assegnazione'}
          name={`${prefix}.type`}
          colSpan={2}
          widgetId={props.widgetId}
          size="large"
        />
      </FormFieldsContainer>
      <FormFieldsContainer gutter={8}>
        <FormFieldItem
          size="large"
          component={AssignmentMonitoringTypeSelectInput}
          label={'Tipologia Assegnazioni'}
          name={`${prefix}.sent`}
          placeholder="Tipologia Assegnazioni"
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormFieldItem
          size="large"
          component={UserOfficeInput}
          widgetId={props.widgetId}
          hierarchy={true}
          name={`${prefix}.officeId`}
          label={'Ufficio Assegnazioni'}
          placeholder="Filtra le assegnazioni per ufficio"
        />
      </FormFieldsContainer>
      <FormFieldsContainer gutter={8}>
        <FormFieldItem
          size="large"
          component={DocumentProtocolModeInput}
          widgetId={props.widgetId}
          label={'Modalità'}
          name={`${prefix}.mode`}
          placeholder="Modalità"
        />
      </FormFieldsContainer>
      <FormFieldsContainer columns={2} gutter={8}>
        <FormFieldItem
          size="large"
          component={SelectInput}
          options={DynamicDateRanges}
          label={'A partire da'}
          name={`${prefix}.dynamicStartDate`}
          placeholder="Da.."
        />
        <FormFieldItem
          size="large"
          component={SelectInput}
          options={DynamicDateRanges}
          label={'Fino a'}
          name={`${prefix}.dynamicEndDate`}
          placeholder="A.."
        />
      </FormFieldsContainer>
    </>
  );
}

import { Widget } from 'client/api/backend/schemas';
import { applyDynamicDateRange } from 'client/components/toolkit/components/filter/dynamic/DynamicDateRanges';
import { damMonitoringDefaultFilters } from '../WidgetDamMonitoringDefaultFilters';
import { WidgetDamMonitoringSearchSchemaType } from './WidgetDamMonitoringSearchSchema';

/**
 * Intefaccia specifica dei filtri "di default" impostati da questo widget
 */
export interface IWidgetDamMonitoringSearchFilterData {
  startDate: number;
  endDate: number;
  selectedGraphView: number;
}

export function applyWidgetDamMonitoringSearchDefaultFilters(
  widget: Widget
): Partial<WidgetDamMonitoringSearchSchemaType> {
  const filters = widget.profile
    ?.filters as IWidgetDamMonitoringSearchFilterData;
  if (!filters) return damMonitoringDefaultFilters;

  const { ...standardParams } = filters;
  return {
    ...standardParams
  };
}

import {
  DeskAssignmentModelItem,
  DeskOffice,
  DeskUser
} from 'client/api/backend/schemas';
import { registerToolkitObjectType } from 'client/components/toolkit/objects/registerToolkitObjectType';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';

export interface IAssignmentModelItem {
  // Tipo di assegnazione (competenza, smistamento, ...)
  typeId: number;
  // Oggetto assegnatario
  assignee:
    | { type: 'user'; data: DeskUser }
    | { type: 'office'; data: DeskOffice };
}

export interface IAssignmentModel {
  // ID univoco generato dal client
  uuid: string;
  // Nome identificativo
  label: string;
  // Elenco assegnatari
  assignees: IAssignmentModelItem[];
}

export const AssignmentModelToolkitObject =
  registerToolkitObjectType<IAssignmentModel>('assignment-model');

import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { yup } from 'common/validation/initYup';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { InferType } from 'yup';
import { message } from 'antd';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { FormOutlined } from '@ant-design/icons';

import { set } from 'client/core/data/set';
import {
  HighlightedDocumentToolkitObject,
  HighlightedDeskDocument
} from '../objects/HighlightedDocumentToolkitObject';
import { WidgetHighlightedDocumentsProfile } from '../WidgetHighlightedDocumentsProfile';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

export const WidgetActionAnnotateHighlightedDocument = createToolkitAction({
  name: 'Modifica note documento',
  code: 'annotate-highlighted-document',
  icon: <FormOutlined />,
  showInBar: false,
  arguments: [
    {
      name: 'Documento da annotare',
      type: [HighlightedDocumentToolkitObject]
    }
  ],
  async execute(ctx: IToolkitActionContext, documentObject: ToolkitObject) {
    let document: ToolkitObject<HighlightedDeskDocument> =
      HighlightedDocumentToolkitObject.get(documentObject)!;

    const notes = document.data.highlighted?.notes;

    ctx.deskContext.actionMessages.prompt({
      ctx,
      action: WidgetActionAnnotateHighlightedDocument,
      args: [document],
      confirmText: notes?.trim() ? 'Aggiorna note' : 'Aggiungi note',
      schema: AnnotateSchema,
      onConfirm,
      initialValues: { notes: notes },
      form: (
        <>
          <FormFieldsContainer>
            <FormFieldItem
              size="large"
              component={TextAreaInput}
              label={null}
              name="notes"
              placeholder="Note"
            />
          </FormFieldsContainer>
        </>
      )
    });
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: InferType<typeof AnnotateSchema>,
  document: ToolkitObject
) {
  try {
    const widget = ctx.deskContext.widgets.find(
      w => w.id === document.sourceWidgetId
    );
    const profile = widget?.profile;
    const data = profile?.data as WidgetHighlightedDocumentsProfile;
    const index = data.documents.findIndex(a => a.id === document.data.id);

    await ctx.deskContext.updateWidget(
      set(
        widget!,
        `profile.data.documents.${index}.highlighted.notes`,
        values.notes || null
      )
    );

    message.success('Note aggiornate con successo');
  } catch (e) {
    message.error(
      getNetworkErrorMessage(e, "Errore durante l'aggiornamento delle note")
    );
    console.error(e);
    return false;
  }
}

const AnnotateSchema = yup.object({
  notes: yup.string().notRequired().label('Note')
});

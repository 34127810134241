import * as React from 'react';
import { FileOutlined } from '@ant-design/icons';
import { DeskActivity, DeskDocument, Widget } from 'client/api/backend/schemas';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import moment from 'moment';
import { DocumentToolkitObject } from 'client/components/widget-repository/objects/documents/DocumentToolkitObject';
import { ActivityToolkitObject } from 'client/components/widget-repository/objects/activities/ActivityToolkitObject';

export function convertActivityToWidgetListElement(
  activity: DeskActivity,
  widget: Widget
): IWidgetListElement {
  const activityName = activity.subject ?? `Atto #${activity.id}`;

  return {
    name: activityName,
    nameExtend: {
      before: activity.proposeNumber,
      bold: true
    },
    description: activity.type,
    descriptionExtend: {
      after: activity.office?.name
    },
    object: ActivityToolkitObject.create({
      icon: <FileOutlined />,
      id: activity.id!,
      name: activityName,
      data: activity,
      sourceWidgetId: widget.id!
    })
  };
}

import * as React from 'react';
import styled, { css } from 'styled-components';
import useDimensions from 'react-cool-dimensions';
import { WidgetTabPanelProps } from './WidgetTabPanel';
import { WidgetTabPanelScroller } from './WidgetTabPanelScroller';
import {
  WidgetTabPanelStyle,
  WidgetTabsMainContent,
  WidgetTabsObserverSize,
  WidgetTabsResponsiveContent,
  WidgetTabsStyle
} from './WidgetTabsStyle';
import { TabsSideMenuDropdown } from './TabsSideMenuDropdown';
import { IconTooltips } from 'client/ui/tooltips/IconTooltips';
import { Space, Tabs } from 'antd';
import { useMemo, useState } from 'react';

const MenuActionWrapper = styled.span`
  margin-right: 8px;
`;

export interface WidgetTabsProps {
  children:
    | React.ReactElement<WidgetTabPanelProps>[]
    | React.ReactElement<WidgetTabPanelProps>;
  actionsMenu?: React.ReactNode;
  defaultKey?: string;
}

const tabKey = (panel?: WidgetTabPanelProps) => panel?.type ?? panel?.tabKey!;

/**
 * Componente Tabs del widget
 *
 * Permette di suddividere in Tabs il contenuto del widget
 */
export function WidgetTabs(props: WidgetTabsProps) {
  const defaultKey = props.defaultKey ?? 'main';

  const { observe, currentBreakpoint } = useDimensions({
    // The "currentBreakpoint" will be the object key based on the target's width
    // for instance, 0px - 399px (currentBreakpoint = XS), 400px - 559px (currentBreakpoint = SM) and so on
    breakpoints: { xs: 0, sm: 400, lg: 560, xl: 4000 },
    // Will only update the state on breakpoint changed, default is false
    updateOnBreakpointChange: true,
    onResize: ({ currentBreakpoint }) => {
      const mobile = currentBreakpoint === 'sm' || currentBreakpoint === 'xs';
      setActiveKey(
        mobile
          ? defaultKey
          : // Se la tab selezionata è il main, selezioniamo la prima tab secondaria disponibile
          activeKey === 'main'
          ? firstSecondaryTabKey
          : // Altrimenti possiamo preservarla
            activeKey
      );
    }
  });
  const isMobile = currentBreakpoint === 'sm' || currentBreakpoint === 'xs';
  const isExtraSmall = currentBreakpoint === 'xs';

  // Trasformiamo il children in un array
  const children = useMemo(
    () => (Array.isArray(props.children) ? props.children : [props.children]),
    [props.children]
  );

  // Può essere nullo se non ci sono tabs secondarie
  const firstSecondaryTabKey = tabKey(
    children.find(c => c.props.type !== 'main')?.props
  );

  const [activeKey, setActiveKey] = useState(
    isMobile ? defaultKey : firstSecondaryTabKey
  );

  // Otteniamo l'eventuale panel "main" e le props
  const main = children.find(child => child.props.type === 'main');
  const mainProps = children.find(child => child.props.type === 'main')?.props;

  // Otteniamo l'eventuale panel "side-menu" e le props
  const menu = children.find(child => child.props.type === 'side-menu');
  const menuProps = children.find(
    child => child.props.type === 'side-menu'
  )?.props;

  const shouldShowMainContent = children.length > 1 && !isMobile;

  // Creiamo evenutalmente il menu da mostrare in caso di mobile
  const SideMenu = useMemo(
    () =>
      menu && (
        <TabsSideMenuDropdown
          overlay={menu}
          side={menuProps?.menuSide}
          title={
            <IconTooltips
              title={menuProps?.title}
              icon={menuProps?.icon}
              showTitle={!isExtraSmall}
            />
          }
        />
      ),
    [children]
  );

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  return (
    <WidgetTabsObserverSize ref={observe}>
      <WidgetTabsResponsiveContent fullWidth={!shouldShowMainContent}>
        <WidgetTabsStyle
          onChange={onChange}
          activeKey={activeKey}
          size="small"
          tabBarExtraContent={{
            right: (
              <Space>
                {menu && isMobile && SideMenu}
                {props.actionsMenu && (
                  <MenuActionWrapper>{props.actionsMenu}</MenuActionWrapper>
                )}
              </Space>
            )
          }}
        >
          {children.map((panel, index) => {
            const panelProps = panel.props;
            const type = panelProps.type;
            if (type === 'main' && shouldShowMainContent) {
              return null;
            }
            if (type === 'side-menu' && isMobile) {
              return null;
            }
            if (type === 'actions-menu') {
              return null;
            }

            return (
              <WidgetTabPanelStyle
                tab={
                  <IconTooltips
                    title={panelProps.title}
                    icon={panelProps.icon}
                    showTitle={!isExtraSmall}
                  />
                }
                key={type ? type : panelProps.tabKey}
              >
                <WidgetTabPanelScroller
                  padded={panelProps.padded}
                  isMobile={isMobile}
                  isMainContent={false}
                  scrollable={panelProps.scrollable}
                >
                  {panel}
                </WidgetTabPanelScroller>
              </WidgetTabPanelStyle>
            );
          })}
        </WidgetTabsStyle>
      </WidgetTabsResponsiveContent>
      {shouldShowMainContent && main && (
        <WidgetTabsMainContent>
          <WidgetTabPanelScroller
            padded={mainProps?.padded}
            isMobile={isMobile}
            isMainContent
            scrollable={mainProps?.scrollable}
          >
            {main}
          </WidgetTabPanelScroller>
        </WidgetTabsMainContent>
      )}
    </WidgetTabsObserverSize>
  );
}

import { DeleteOutlined } from '@ant-design/icons';
import { message } from 'antd';
import {
  deleteDocumentaryDossiersDossierIdChildrenChildrenId,
  getGetDocumentaryDossiersIdChildrenQueryKey
} from 'client/api/backend/documentary-dossiers/documentary-dossiers';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import {
  getToolkitObjectOfType,
  ToolkitObject
} from 'client/components/toolkit/objects/ToolkitObject';
import {
  ExtendedDeskFolder,
  FolderToolkitObject
} from 'client/components/widget-repository/objects/folders/FolderToolkitObject';
import { queryClient } from 'client/core/network/queryClient';
import { yup } from 'common/validation/initYup';
import React from 'react';
import { InferType } from 'yup';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

export const WidgetActionDeleteSubfolder = createToolkitAction({
  name: 'Elimina Sottofascicolo',
  code: 'delete-subfolder',
  icon: <DeleteOutlined />,
  arguments: [
    {
      name: 'Cartella',
      type: [FolderToolkitObject]
    }
  ],
  async execute(ctx: IToolkitActionContext, folderObject: ToolkitObject) {
    const folder: ToolkitObject<ExtendedDeskFolder> = getToolkitObjectOfType(
      folderObject,
      [FolderToolkitObject.code]
    )!;

    ctx.deskContext.actionMessages.prompt({
      ctx,
      args: [folder],
      action: WidgetActionDeleteSubfolder,
      confirmText: 'Elimina',
      confirmProps: { danger: true },
      schema: DeleteSubfolderSchema,
      onConfirm
    });
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: DeleteSubfolderSchemaType,
  folder: ToolkitObject
) {
  try {
    await deleteSubfolder(values, folder);

    message.success('Sottocartella eliminata con successo');
  } catch (error) {
    message.error(
      getNetworkErrorMessage(error, 'Impossibile eliminare la sottocartella')
    );
    console.error(error);
    return false;
  }
}

async function deleteSubfolder(
  values: DeleteSubfolderSchemaType,
  folder: ToolkitObject<ExtendedDeskFolder>
) {
  await deleteDocumentaryDossiersDossierIdChildrenChildrenId(
    folder.data.parentId,
    folder.id as number,
    {
      widgetId: folder.sourceWidgetId
    }
  );

  // Invalido la cache
  const queryKey = getGetDocumentaryDossiersIdChildrenQueryKey(
    folder.data.parentId
  )[0];

  queryClient.invalidateQueries({
    predicate: query => {
      return Boolean(
        query.queryKey &&
          Array.isArray(query.queryKey) &&
          query.queryKey[0] === queryKey
      );
    }
  });
}

const DeleteSubfolderSchema = yup.object({});

type DeleteSubfolderSchemaType = InferType<typeof DeleteSubfolderSchema>;

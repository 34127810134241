import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { yup } from 'common/validation/initYup';
import { InferType } from 'yup';
import { message } from 'antd';
import { UsergroupDeleteOutlined } from '@ant-design/icons';
import { WidgetAssignmentModelsProfile } from '../../WidgetAssignmentModelsProfile';
import {
  AssignmentModelToolkitObject,
  IAssignmentModel
} from 'client/components/widget-repository/objects/assignment-models/AssignmentModelToolkitObject';
import { set } from 'client/core/data/set';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

export const WidgetActionDeleteAssignmentModel = createToolkitAction({
  name: 'Elimina Modello di assegnazione',
  code: 'delete-assignment-model',
  icon: <UsergroupDeleteOutlined />,
  arguments: [
    {
      name: 'Modello di Assegnazione',
      type: [AssignmentModelToolkitObject]
    }
  ],
  async execute(ctx: IToolkitActionContext, modelObject: ToolkitObject) {
    const model = AssignmentModelToolkitObject.get(modelObject)!;

    // Apriamo sempre il prompt per conferma.
    ctx.deskContext.actionMessages.prompt({
      ctx,
      args: [model],
      action: WidgetActionDeleteAssignmentModel,
      title: "Conferma l'eliminazione del modello",
      confirmText: 'Elimina Modello',
      confirmProps: { danger: true },
      schema: DeleteAssignmentModelSchema,
      onConfirm
    });
  }
});

/**
 * Rimuove il modello di assegnazione dal widget selezionato.
 */
async function onConfirm(
  ctx: IToolkitActionContext,
  values: DeleteAssignmentModelSchemaType,
  model: ToolkitObject<IAssignmentModel>
) {
  try {
    const widget = ctx.deskContext.widgets.find(
      w => w.id === model.sourceWidgetId
    );
    const profile = widget?.profile;
    const data = profile?.data as WidgetAssignmentModelsProfile | null;

    await ctx.deskContext.updateWidget(
      set(
        widget!,
        'profile.data.models',
        data?.models.filter(m => m.uuid !== model.data.uuid)
      )
    );

    message.success('Modello eliminato con successo');
  } catch (e) {
    message.error(
      getNetworkErrorMessage(e, 'Impossibile eliminare il modello')
    );
    console.error(e);
    return false;
  }
}

const DeleteAssignmentModelSchema = yup.object({});

type DeleteAssignmentModelSchemaType = InferType<
  typeof DeleteAssignmentModelSchema
>;

import { BookOutlined } from '@ant-design/icons';
import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetActionAddBookmark } from './actions/WidgetActionAddBookmark';
import { WidgetActionDeleteBookmark } from './actions/WidgetActionDeleteBookmark';
import { WidgetActionOpenBookmark } from './actions/WidgetActionOpenBookmark';
import { WidgetBookmarks } from './WidgetBookmarks';

export const WidgetBookmarksType: IWidgetType = {
  code: 'bookmarks',
  name: 'Preferiti',
  icon: <BookOutlined />,
  component: WidgetBookmarks,
  description: `Permette di mostrare i preferiti`,
  actions: [WidgetActionAddBookmark],
  internalActions: [WidgetActionDeleteBookmark, WidgetActionOpenBookmark]
};

WidgetRepository.register(WidgetBookmarksType);

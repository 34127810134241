import {
  ApartmentOutlined,
  FolderOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Divider, Empty, Typography } from 'antd';
import { useGetDocumentaryAssignmentsId } from 'client/api/backend/documentary-assignments/documentary-assignments';
import { useGetDocumentaryDocumentsIdDossiers } from 'client/api/backend/documentary-documents/documentary-documents';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { Metadata } from 'client/components/toolkit/components/metadata/Metadata';
import { useRegisterToolkitObject } from 'client/components/toolkit/objects/store/useToolkitObjectStore';
import { ToolkitObjectBottomZone } from 'client/components/toolkit/objects/ToolkitObjectBottomZone';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { FirstLineType } from 'client/ui/first-line/FirstLineType';
import { IndentedMetadataWrapper } from 'client/ui/indented-metadata/IndentedMetadataWrapper';
import { PaddedSkeleton } from 'client/ui/skeleton/PaddedSkeleton';
import React from 'react';
import styled from 'styled-components';
import { AssignmentToolkitObject } from '../../objects/assignments/AssignmentToolkitObject';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { DocumentModeMetadata } from '../document-viewer/metadata/DocumentModeMetadata';
import { convertAssignmentToMetadatas } from './convert/convertAssignmentToMetadata';
import { WidgetAssignmentViewerProfile } from './WidgetAssignmentViewerProfile';

const { Title, Text, Paragraph } = Typography;

export interface IWidgetAssignmentViewerProps
  extends IWidgetComponentToolkitProps {}

export function WidgetAssignmentViewer(props: IWidgetAssignmentViewerProps) {
  const { widget } = props;
  const data = widget.profile?.data as
    | WidgetAssignmentViewerProfile
    | undefined;

  const assignmentResult = useGetDocumentaryAssignmentsId(data?.assignmentId!, {
    widgetId: widget.id!
  });

  const dossiersResult = useGetDocumentaryDocumentsIdDossiers(
    assignmentResult?.data?.document?.id!,
    {
      widgetId: widget.id!
    },
    {
      query: {
        enabled: !!assignmentResult?.data?.document?.id
      }
    }
  );

  // Creiamo l'object da passare alla toolkit area
  const widgetObject = WidgetToolkitObject.from(widget);
  useRegisterToolkitObject(widgetObject);

  const metadata = assignmentResult?.data
    ? convertAssignmentToMetadatas(assignmentResult?.data, dossiersResult.data)
    : null;

  return (
    <WidgetCardBody
      padded
      bottom={<ToolkitObjectBottomZone object={widgetObject} />}
    >
      <PaddedSkeleton loading={assignmentResult.isLoading} active>
        {!data && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        {metadata && (
          <>
            <FirstLineType>
              <DocumentModeMetadata documentType={metadata.documentType} />
            </FirstLineType>
            <Metadata label={'Segnatura'} value={metadata.shelfmark} />
            <Metadata label={'Data Protocollo'} value={metadata.protocolDate} />
            <Divider />
            <Metadata label={'Stato'} value={metadata.state} />
            <Metadata label={'Assegnanti'} />
            <IndentedMetadataWrapper>
              <Metadata label={<UserOutlined />} value={metadata.assignor} />
              {metadata.assignorOffice && (
                <Metadata
                  label={<ApartmentOutlined />}
                  value={metadata.assignorOffice}
                />
              )}
            </IndentedMetadataWrapper>
            <Metadata label={'Assegnatari'} />
            <IndentedMetadataWrapper>
              <Metadata label={<UserOutlined />} value={metadata.assignee} />
              {metadata.assigneeOffice && (
                <Metadata
                  label={<ApartmentOutlined />}
                  value={metadata.assigneeOffice}
                />
              )}
            </IndentedMetadataWrapper>
            <Metadata label={'Data Assegnazione'} value={metadata.date} />
            <Metadata label={'Data Scadenza'} value={metadata.expiresAt} />
            <Metadata label={'Priorità'} value={metadata.priority} />
            <Metadata label={'Note'} value={metadata.notes} />
            {metadata.dossiers && metadata.dossiers?.length > 0 && (
              <>
                <Metadata label="Fascicoli" />
                <IndentedMetadataWrapper>
                  {metadata.dossiers?.map((dossier, index) => {
                    return (
                      <>
                        <Metadata
                          key={index}
                          label={<FolderOutlined />}
                          value={dossier.name}
                        />
                        <Metadata
                          key={index}
                          label={'Data Apertura'}
                          value={dossier.dateOpen}
                        />
                      </>
                    );
                  })}
                </IndentedMetadataWrapper>
              </>
            )}
          </>
        )}
      </PaddedSkeleton>
    </WidgetCardBody>
  );
}

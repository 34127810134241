import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetActionAddSubfolder } from './actions/add-subfolder/WidgetActionAddSubfolder';
import { WidgetActionInsertDocumentDossier } from './actions/insert-document-dossier/WidgetActionInsertDocumentDossier';
import { WidgetActionOpenDossier } from './actions/open-dossier/WidgetActionOpenDossier';
import { WidgetDossierViewer } from './WidgetDossierViewer';
import { WidgetDossierViewerProfile } from './WidgetDossierViewerProfile';
import { WidgetActionDeleteSubfolder } from './actions/delete-subfolder/WidgetActionDeleteSubfolder';
import { FvIconFolderView } from 'client/ui/icon/FvIcons';
import { WidgetActionUploadDocumentToDossier } from './actions/upload-document/WidgetActionUploadDocumentToDossier';
import { WidgetActionRemoveFromDossier } from '../personal-dossier/actions/WidgetActionRemoveFromDossier';
import { WidgetActionOpenDamDossier } from './actions/open-dossier/WidgetActionOpenDamDossier';

export const WidgetDossierViewerType: IWidgetType = {
  code: 'dossier-viewer',
  name: 'Visualizzatore Fascicolo',
  isHidden: true,
  icon: <FvIconFolderView />,
  component: WidgetDossierViewer,
  description: `Apre un fascicolo per la sua visualizzazione`,
  actions: [
    WidgetActionOpenDossier,
    WidgetActionOpenDamDossier,
    WidgetActionAddSubfolder,
    WidgetActionInsertDocumentDossier,
    WidgetActionDeleteSubfolder,
    WidgetActionUploadDocumentToDossier
  ],
  internalActions: [WidgetActionRemoveFromDossier],
  getTitle: widget => {
    const data = widget.profile?.data as WidgetDossierViewerProfile | undefined;
    return data?.dossierName;
  }
};

WidgetRepository.register(WidgetDossierViewerType);

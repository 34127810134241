import { Col, Descriptions, Drawer, Row, Typography } from 'antd';
const { Title, Text } = Typography;
import { IWidgetType } from 'client/components/widget-repository/schema/WidgetType';
import { VerticalSpace } from 'client/ui/space/VerticalSpace';
import * as React from 'react';
import styled from 'styled-components';

const FormRowTitle = styled(Row)`
  padding-left: 12px;
  .ant-typography {
    margin-bottom: 0;
  }
`;
const InfoWrapper = styled.div`
  width: 100%;
  padding: 12px 12px 24px 12px;
`;
export interface WidgetTopInfoProps {
  widgetType?: IWidgetType | null;
  children?: React.ReactNode;
}

export function WidgetTopInfo(props: WidgetTopInfoProps) {
  const { widgetType, children } = props;

  return (
    <>
      <FormRowTitle align="middle">
        <Col flex="auto">
          <Title level={4}>Configurazione Widget</Title>
        </Col>
        <Col flex="40px">{children}</Col>
      </FormRowTitle>
      <InfoWrapper>
        <Descriptions bordered size="small" column={1}>
          <Descriptions.Item label="Widget">
            {widgetType?.icon} <Text strong>{widgetType?.name}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Descrizione">
            {widgetType?.description}
          </Descriptions.Item>
        </Descriptions>
      </InfoWrapper>
    </>
  );
}

import { joinPath } from './network/joinPath';

const window$ = window as any;

export const appConfig = {
  publicPath: window$.PUBLIC_PATH || process.env.PUBLIC_PATH,
  remotePath: window$.REMOTE_PATH || process.env.REMOTE_PATH,
  rootNavigationPath:
    window$.ROOT_NAVIGATION_PATH || process.env.ROOT_NAVIGATION_PATH,
  rawFeatureFlags: window$.FEATURE_FLAGS || process.env.FEATURE_FLAGS,
  ssoMethod: window$.SSO_METHOD || process.env.SSO_METHOD,
  // Azure
  azureClientId: window$.AZURE_CLIENT_ID || process.env.AZURE_CLIENT_ID,
  azureTenantId: window$.AZURE_TENANT_ID || process.env.AZURE_TENANT_ID,
  azureRedirectUri:
    window$.AZURE_REDIRECT_URI || process.env.AZURE_REDIRECT_URI,
  azureAuthStateInCookie: window$.AZURE_AUTH_STATE_IN_COOKIE || process.env.AZURE_AUTH_STATE_IN_COOKIE || false, // prettier-ignore
  // OpenID Connect
  oidcClientId: window$.OIDC_CLIENT_ID || process.env.OIDC_CLIENT_ID,
  oidcRedirectUri: window$.OIDC_REDIRECT_URI || process.env.OIDC_REDIRECT_URI,
  oidcAuthority: window$.OIDC_AUTHORITY || process.env.OIDC_AUTHORITY
};

/**
 * Permette di utilizzare URL importati da webpack anche in caso di
 * sottocartelle.
 */
export function resolvePublicPath(path: string) {
  return joinPath([appConfig.publicPath, path]);
}

/**
 * Permette di ottenere il link alle API risolvendo sottocartelle e URL
 * delle API.
 */
export function resolveApiPath(path: string) {
  return joinPath([appConfig.remotePath, path]);
}

import { Button } from 'antd';
import { useGetDocumentaryUsersImportConfigurationId } from 'client/api/backend/documentary-users/documentary-users';
import { useGetEndpoints } from 'client/api/backend/endpoints/endpoints';
import { ConfigurationTemplate } from 'client/api/backend/schemas';
import { FormButtonsRightContainer } from 'client/ui/form/button/FormButtonsRightContainer';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikForm } from 'client/ui/form/FormikForm';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { useFormikContext } from 'formik';
import React from 'react';
import { UserSelectInput } from '../UserSelectInput';
import { ImportUsersFormFieldItem } from './ImportUsersFormFieldItem';

interface ImportUsersFormProps {
  reloadCount: number;
  setReloadCount: (reloadCount: number) => void;
}

export function ImportUsersForm(props: ImportUsersFormProps) {
  const formik = useFormikContext<any>();
  const endpoints = useGetEndpoints();
  const endpointConfiguration = useGetDocumentaryUsersImportConfigurationId(
    formik.values.endpointId,
    {
      query: { enabled: formik.values.endpointId != null }
    }
  );

  const configuration = endpointConfiguration.data;
  const template = JSON.parse(
    configuration?.template ?? '{}'
  ) as ConfigurationTemplate;

  return (
    <FormikForm editable helpInTooltips layout="vertical">
      <FormFieldsContainer>
        <FormFieldItem
          component={SelectInput}
          loading={endpoints.isLoading}
          options={endpoints.data?.map(endpoint => ({
            label: endpoint.name,
            value: endpoint.id!
          }))}
          name="endpointId"
          label="Endpoint"
          size="large"
          placeholder="Endpoint"
        />
        {formik.values.endpointId &&
          template?.fields?.map((field, i) => (
            <ImportUsersFormFieldItem
              template={template}
              key={field.type}
              field={field}
              index={i}
              reloadCount={props.reloadCount}
              setReloadCount={props.setReloadCount}
            />
          ))}
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormFieldItem
          component={UserSelectInput}
          name="copyDeskUserId"
          label="Utente da cui copiare le scrivanie"
          size="large"
          placeholder="Utente"
        />
      </FormFieldsContainer>
      <FormButtonsRightContainer>
        <FormikSendButton as={Button} title="Cerca Utenti" size="large">
          Cerca Utenti
        </FormikSendButton>
      </FormButtonsRightContainer>
    </FormikForm>
  );
}

import * as React from 'react';
import { useMemo, useState } from 'react';
import { GetDocumentaryDamParams } from 'client/api/backend/schemas';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import { WidgetList } from 'client/components/toolkit/components/list/WidgetList';
import { PaddedSkeleton } from 'client/ui/skeleton/PaddedSkeleton';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { useWidgetNetworkQueries } from 'client/components/schema/widget/context/useWidgetNetworkEffect';
import { useGetDocumentaryDam } from 'client/api/backend/documentary-dam/documentary-dam';
import { convertDamToWidgetListElement } from '../dam-viewer/convert/convertDamToWidgetListElement';
import { WidgetDamsSearchForm } from './filters/WidgetDamsSearchForm';
import {
  InfiniteDamsSearchPageStart,
  useInfiniteDamsSearch
} from './api/useInfiniteDamsSearch';
import { applyWidgetDamSearchDefaultFilters } from './filters/WidgetDamsSearchFilter';
import {
  hasFeatureFlag,
  FeatureFlag
} from 'client/core/feature-flags/featureFlags';

const DamsPageSize = 20;

export interface IWidgetDamsSearchProps extends IWidgetComponentToolkitProps {}

export function WidgetDamsSearch(props: IWidgetDamsSearchProps) {
  const { widget } = props;

  // Ricerca locale, per la durata della sessione.
  const [search, setSearch] = useState<Partial<GetDocumentaryDamParams>>({});
  const apiSearch = useMemo(
    () => ({
      ...applyWidgetDamSearchDefaultFilters(widget),
      ...search,
      pageNumber: InfiniteDamsSearchPageStart,
      pageSize: DamsPageSize,
      widgetId: widget.id!
    }),
    [search, widget.profile?.filters]
  );

  const damsResult = useInfiniteDamsSearch(apiSearch as any, {
    query: {
      getNextPageParam: (lastPage, pages) =>
        lastPage.length < DamsPageSize
          ? undefined
          : pages.length + InfiniteDamsSearchPageStart
    }
  });

  const dams = useMemo<IWidgetListElement[]>(() => {
    if (!damsResult.data) return [];

    return damsResult.data.pages
      .flat()
      .map(dam => convertDamToWidgetListElement(dam, widget));
  }, [damsResult.data]);

  useWidgetNetworkQueries([damsResult]);

  return (
    <WidgetCardBody
      top={
        <WidgetDamsSearchForm
          widget={widget}
          search={search}
          setSearch={setSearch}
        />
      }
    >
      <PaddedSkeleton loading={damsResult.isLoading} active>
        <WidgetList
          list={dams}
          infinite={{ query: damsResult }}
          massive={{
            enabled: hasFeatureFlag(FeatureFlag.MassiveActions)
          }}
        />
      </PaddedSkeleton>
    </WidgetCardBody>
  );
}

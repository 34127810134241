import { EditOutlined } from '@ant-design/icons';
import { Space, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { useGetRoles } from 'client/api/backend/roles/roles';
import { UserResponseDTO } from 'client/api/backend/schemas';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { RoleTag } from 'client/ui/role/RoleTag';
import { stateOptions, StateTag } from 'client/ui/state/StateTag';
import {
  createColumnFilter,
  FilterInputType
} from 'client/ui/table/TableColumnFilter';
import React from 'react';
import { Link } from 'react-router-dom';
import { UserColumnDeleteButton } from './UserColumnDeleteButton';

export function useUserColumns(filtersVisible: boolean) {
  const roles = useGetRoles().data ?? [];

  const userColumns: ColumnProps<UserResponseDTO>[] = [
    {
      title: 'Login',
      dataIndex: 'username',
      key: 'username',
      ...(filtersVisible ? createColumnFilter(FilterInputType.Text) : {})
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => <span>{record.name ?? ''}</span>,
      ...(filtersVisible ? createColumnFilter(FilterInputType.Text) : {}),
      width: '15%'
    },
    {
      title: 'Cognome',
      dataIndex: 'surname',
      key: 'surname',
      render: (_, record) => <span>{record.surname ?? ''}</span>,
      ...(filtersVisible ? createColumnFilter(FilterInputType.Text) : {}),
      width: '15%'
    },
    {
      title: 'Ruolo',
      dataIndex: 'roleId',
      key: 'roleId',
      render: (_, record) => (
        <RoleTag roleName={roles.find(r => r.id === record.roleId)?.name} />
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => <StateTag enabled={record.enabled} />,
      ...(filtersVisible
        ? createColumnFilter(FilterInputType.Enum, {
            options: stateOptions()
          })
        : {})
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      width: '86px',
      render: (_, record) => (
        <Space>
          <UserColumnDeleteButton user={record} />
          <Link to={`/admin/users/${record.id}`}>
            <ButtonIcon icon={<EditOutlined />} />
          </Link>
        </Space>
      )
    }
  ];

  return userColumns;
}

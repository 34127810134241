import {
  useGetDocumentaryAssignmentsMonitoring,
  useGetDocumentaryAssignmentsMonitoringDetails
} from 'client/api/backend/documentary-assignments/documentary-assignments';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { useWidgetNetworkQueries } from 'client/components/schema/widget/context/useWidgetNetworkEffect';
import { StateLabels } from 'client/components/toolkit/components/monitor/menu/StateLabels';
import { MonitorChart } from 'client/components/toolkit/components/monitor/MonitorChart';
import { ChartPalettes } from 'client/components/toolkit/components/monitor/palette/ChartPalettes';
import { PaddedSkeleton } from 'client/ui/skeleton/PaddedSkeleton';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { WidgetMonitorSentAssignmentsType } from '../monitor-sent-assignments/WidgetMonitorSentAssignments.type';
import { convertAssignmentToWidgetListElement } from './convert/convertAssignmentToWidgetListElement';

export interface IWidgetMonitorAssignmentsProps
  extends IWidgetComponentToolkitProps {}

export function WidgetMonitorAssignments(
  props: IWidgetMonitorAssignmentsProps
) {
  const { widget } = props;

  const isSent =
    widget.profile?.typeCode === WidgetMonitorSentAssignmentsType.code;

  const monitoringResult = useGetDocumentaryAssignmentsMonitoring({
    isSent: isSent,
    widgetId: props.widget.id!
  });

  // Palette di visualizzazione
  const palette = ChartPalettes.category;

  // Estrazione dei dati del monitoraggio per la visualizzazione nel grafico.
  const data = monitoringResult.data;
  const items = data
    ?.filter(item => item.value != null && Number(item.value) !== 0)
    .map(item => ({
      key: item.key!,
      value: Number(item.value ?? 0),
      label: StateLabels[item.key!] ?? item.key
    }));

  // Selezione della scheda di interesse per il dettaglio
  const [selected, setSelected] = useState(undefined as string | undefined);
  const detailResult = useGetDocumentaryAssignmentsMonitoringDetails(
    {
      isSent,
      widgetId: props.widget.id!,
      key: selected!
    },
    { query: { enabled: selected != null } }
  );

  useEffect(() => {
    monitoringResult.refetch();
  }, [selected]);

  const selectedList = useMemo(() => {
    const index = items?.findIndex(i => i.key === selected);
    return detailResult.data?.map(assignment => {
      return convertAssignmentToWidgetListElement(
        assignment,
        widget,
        StateLabels[selected!] ?? selected,
        palette[index ?? -1]
      );
    });
  }, [detailResult.data, selected, widget]);

  useWidgetNetworkQueries([monitoringResult, detailResult]);

  return (
    <WidgetCardBody>
      <PaddedSkeleton loading={monitoringResult.isLoading} active>
        {items && (
          <MonitorChart
            items={items}
            selected={selected}
            selectedListLoading={detailResult.isLoading}
            setSelected={setSelected}
            selectedList={selectedList}
            palette={palette}
            recordLabels={StateLabels}
          />
        )}
      </PaddedSkeleton>
    </WidgetCardBody>
  );
}

import { Card } from 'antd';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  flex: 1 1 auto;
  min-width: 100%;
  max-width: 100%;
  .fullscreen &,
  .expanded & {
    /* min-width: 340px; */
    max-width: 340px;
    width: auto;
    min-width: 340px;
  }
  > .ant-card-head {
    /* padding: 0 8px;
    flex: 0 0 auto;
    overflow-x: hidden; // Per supportare le animazioni sulla width */
    background-color: #f0f2f5;
    /* min-height: 38px; */
    > .ant-card-head-wrapper {
      > .ant-card-head-title {
        padding: 0;
        padding-left: 8px;
      }
      > .ant-card-extra {
        padding: 4px 0;
      }
    }
  }
  > .ant-card-body {
    padding: 12px;
  }
`;

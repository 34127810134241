import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, DropdownProps, Space } from 'antd';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

const SideMenuDropdown = styled(Dropdown)`
  margin: auto 8px;
  .ant-menu-vertical {
    color: #e11111;
  }
`;

// importato in Index.tsx
export const SideMenuDropdownGlobalStyles = createGlobalStyle`
.tabs-side-menu-dropdown {
  ul.ant-menu {
    max-width: 300px;
    border-radius: 4px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 
    0 6px 16px 0 rgb(0 0 0 / 8%), 
    0 9px 28px 8px rgb(0 0 0 / 5%);
    li.ant-menu-item 
    { margin-top: 4px;
      margin-bottom: 4px;
    }
  }
}`;

export interface TabsSideMenuDropdownProps extends DropdownProps {
  title: React.ReactNode;
  side?: 'left' | 'right';
}
/**
 * Dropdown Menu stilizzato per la WidgetTabs
 */
export function TabsSideMenuDropdown(props: TabsSideMenuDropdownProps) {
  const { title, side, ...rest } = props;
  return (
    <SideMenuDropdown
      overlayClassName="tabs-side-menu-dropdown"
      placement={side === 'left' ? 'bottomLeft' : 'bottomRight'}
      arrow
      {...rest}
    >
      <a onClick={e => e.preventDefault()}>
        <Space>
          {title}
          <DownOutlined />
        </Space>
      </a>
    </SideMenuDropdown>
  );
}

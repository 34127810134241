import * as React from 'react';
import clx from 'classnames';
import styled from 'styled-components';
import { getHexColorById } from 'client/components/schema/colors/Colors';
import AppstoreOutlined from '@ant-design/icons/lib/icons/AppstoreOutlined';
import { WidgetIcon } from './WidgetIcon';
import { WidgetTitleWrapper } from './WidgetTitleWrapper';
import { Widget } from 'client/api/backend/schemas';
import { WidgetDisplayLogic } from '../../logic/WidgetDisplayLogic';
import { useGetDocumentaryOrganizationOfficesId } from 'client/api/backend/documentary-organization/documentary-organization';

const WidgetTitleContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-flex;
  flex-direction: column;
  flex: 1;
`;

const WidgetName = styled.div`
  font-size: 14px;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  .float & {
    color: ${props => props.theme.componentBackground};
  }
`;

const WidgetOffice = styled.div`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: -2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  .float & {
    color: ${props => props.theme.componentBackground};
    display: none;
  }
`;

export interface WidgetTitleProps {
  widget: Widget;
  float?: boolean;
}

/**
 * Nome del Widget stilizzato
 *
 * Mostra l'icona e il nome assegnato al widget (nel colore impostato)
 * e il nome dell'ufficio in base alle proprietà fornite
 */
export function WidgetTitle(props: WidgetTitleProps) {
  const { widget, float } = props;
  const titleProps = WidgetDisplayLogic.getTitleProps(widget);

  const officeId = widget.fields?.find(f => f.type === 'doc.ufficio')?.value;
  const institution = widget.fields?.find(f => f.type === 'doc.ente')?.value;

  const office = useGetDocumentaryOrganizationOfficesId(
    parseInt(officeId!, 10),
    { widgetId: widget.id! },
    {
      query: {
        enabled: officeId != null
      }
    }
  );

  const officeAndInstitution = office.data
    ? `${office.data.name ?? ''}${institution ? ` | ${institution}` : ''}`
    : null;

  return (
    <WidgetTitleWrapper className={clx({ float })}>
      <WidgetIcon icon={titleProps.icon} float={float} />
      <WidgetTitleContainer>
        <WidgetName title={titleProps.title}>{titleProps.title}</WidgetName>
        {officeAndInstitution && (
          <WidgetOffice title={officeAndInstitution}>
            {officeAndInstitution}
          </WidgetOffice>
        )}
      </WidgetTitleContainer>
    </WidgetTitleWrapper>
  );
}

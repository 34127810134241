import { DeskAssignment } from 'client/api/backend/schemas';
import { IHistoryItem } from 'client/components/toolkit/components/history/IHistoryItem';
import {
  formatAssignmentEntityDescription,
  formatAssignmentEntityName
} from 'client/components/widget-repository/objects/assignments/formatAssignmentEntityDescription';
import { convertIdStateToStateTagComponents } from 'client/components/widget-repository/objects/assignments/mapIdState';
import { convertIdTypeToReasonTagComponents } from 'client/components/widget-repository/objects/assignments/mapIdType';

export function convertDocumentToHistoryItems(
  list: DeskAssignment[]
): IHistoryItem[] {
  const tree: IHistoryItem[] = [];
  const map: { [code: string]: IHistoryItem } = {};

  list.forEach(item => {
    map[item.id!] = {
      id: item.id,
      historyChildren: [],
      parentId: item.idParent,
      receiver: formatAssignmentEntityDescription(item.assegnee),
      submitter: formatAssignmentEntityDescription(item.assignor),
      submitterOffice: formatAssignmentEntityName(
        item.assignor?.officeDescription
      ),
      reasonTag: convertIdTypeToReasonTagComponents(item.idType?.toString()),
      stateTag: convertIdStateToStateTagComponents(item.idState?.toString()),
      mode: 'Assegnante',
      date: item.startDate ?? ''
    };
  });

  list.forEach(item => {
    if (item.idParent) {
      map[item.idParent].historyChildren!.push(map[item.id!]);
    } else {
      tree.push(map[item.id!]);
    }
  });

  return tree;
}

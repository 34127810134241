import { UserOutlined } from '@ant-design/icons';
import { FvIconUserAssignmentView } from 'client/ui/icon/FvIcons';
import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetActionOpenAssignment } from './actions/WidgetActionViewAssignment';
import { WidgetAssignmentViewer } from './WidgetAssignmentViewer';
import { WidgetAssignmentViewerProfile } from './WidgetAssignmentViewerProfile';

export const WidgetAssignmentViewerType: IWidgetType = {
  code: 'assignment-viewer',
  name: 'Visualizzatore Assegnazione',
  icon: <FvIconUserAssignmentView />,
  component: WidgetAssignmentViewer,
  description: `Apre un'assegnazione per la sua visualizzazione`,
  actions: [WidgetActionOpenAssignment],
  getTitle: widget => {
    const data = widget.profile?.data as
      | WidgetAssignmentViewerProfile
      | undefined;
    return data?.documentSubject;
  }
};

WidgetRepository.register(WidgetAssignmentViewerType);

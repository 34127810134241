import * as React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
const { Title, Text, Paragraph } = Typography;

const MetadataParagraph = styled(Paragraph)`
  &&& {
    margin-bottom: 8px;
  }
`;

export interface MetadataProps {
  label?: React.ReactNode;
  value?: React.ReactNode;
  hideIfNoValue?: boolean;
}
/**
 * Singola linea di metadato con label e valore
 */
export function Metadata(props: MetadataProps) {
  const { label, value } = props;

  if (props.hideIfNoValue && !value) return null;

  return (
    <MetadataParagraph>
      {label && <Text type="secondary">{label}: </Text>}
      <Text>{value}</Text>
    </MetadataParagraph>
  );
}

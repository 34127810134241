import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { yup } from 'common/validation/initYup';
import { InferType } from 'yup';
import { message } from 'antd';
import { UsergroupAddOutlined } from '@ant-design/icons';
import { WidgetAssignmentModelsProfile } from '../../WidgetAssignmentModelsProfile';
import { set } from 'client/core/data/set';
import {
  AssignmentModelItemToolkitObject,
  IExtendedAssignmentModelItem
} from 'client/components/widget-repository/objects/assignment-models/AssignmentModelItemToolkitObject';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

export const WidgetActionRemoveAssignmentModelItem = createToolkitAction({
  name: 'Rimuovi assegnatario dal Modello',
  code: 'remove-assignment-model-item',
  icon: <UsergroupAddOutlined />,
  arguments: [
    {
      name: 'Assegnatario',
      type: [AssignmentModelItemToolkitObject]
    }
  ],
  async execute(ctx: IToolkitActionContext, assigneeObject: ToolkitObject) {
    const assignee = AssignmentModelItemToolkitObject.get(assigneeObject)!;

    await onConfirm(ctx, {}, assignee);
  }
});

/**
 * Crea e Aggiunge il modello di assegnazione al widget selezionato.
 */
async function onConfirm(
  ctx: IToolkitActionContext,
  values: RemoveAssignmentModelItemSchemaType,
  item: ToolkitObject<IExtendedAssignmentModelItem>
) {
  try {
    const widget = ctx.deskContext.widgets.find(
      w => w.id === item.sourceWidgetId
    );
    const profile = widget?.profile;
    const data = profile?.data as WidgetAssignmentModelsProfile;
    const index = data.models.findIndex(m => m.uuid === item.data.modelUuid);

    await ctx.deskContext.updateWidget(
      set(
        widget!,
        `profile.data.models.${index}.assignees`,
        data!.models[index].assignees.filter((_, i) => i !== item.data.index)
      )
    );

    message.success('Assegnatario rimosso dal modello con successo');
  } catch (e) {
    message.error(
      getNetworkErrorMessage(e, "Impossibile rimuovere l'assegnatario")
    );
    console.error(e);
    return false;
  }
}

const RemoveAssignmentModelItemSchema = yup.object({});

type RemoveAssignmentModelItemSchemaType = InferType<
  typeof RemoveAssignmentModelItemSchema
>;

import { message, Skeleton } from 'antd';
import {
  getGetDocumentaryDocumentsSearchQueryKey,
  useGetDocumentaryDocumentsId,
  usePostDocumentaryDocumentsProtocol,
  usePutDocumentaryDocumentsId
} from 'client/api/backend/documentary-documents/documentary-documents';
import { DeskDocument, DeskExtendedType } from 'client/api/backend/schemas';
import { useDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { DeskActions } from 'client/components/schema/desk/DeskModule';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { queryClient } from 'client/core/network/queryClient';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { LoaderSpin } from 'client/ui/loader/LoaderSpin';
import { omit } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ClassificationNodeToolkitObject } from '../../objects/classifications/ClassificationNodeToolkitObject';
import { useCorrespondentMailTransportIds } from '../../objects/correspondents/input/useCorrespondentTransportMailIds';
import {
  extendedAttributesFromPairs,
  extendedAttributesToPairs
} from '../../objects/extended-attributes/transformExtendedAttributes';
import { OfficeToolkitObject } from '../../objects/offices/OfficeToolkitObject';
import {
  ISenderReceiverOffice,
  SenderReceiverOfficeMode
} from '../../objects/offices/SenderReceiverOffice';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { DocumentProtocolSchema } from './form/DocumentProtocolSchema';
import { WidgetDocumentProtocolForm } from './form/WidgetDocumentProtocolForm';
import { WidgetDocumentProtocolProfile } from './WidgetDocumentProtocolProfile';

export interface IWidgetDocumentProtocolProps
  extends IWidgetComponentToolkitProps {}

export function WidgetDocumentProtocol(props: IWidgetDocumentProtocolProps) {
  const { widget } = props;
  const ctx = useDeskContext();
  const dispatch = useDispatch();

  const [documentExtendedType, setDocumentExtendedType] = useState<
    DeskExtendedType | undefined
  >(undefined);

  const protocol = usePostDocumentaryDocumentsProtocol({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(
          getGetDocumentaryDocumentsSearchQueryKey()
        );
      }
    }
  });
  const editProtocol = usePutDocumentaryDocumentsId({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(
          getGetDocumentaryDocumentsSearchQueryKey()
        );
      }
    }
  });

  // Gestiamo il profilo iniziale dinamicamente
  const profile = widget.profile?.data as WidgetDocumentProtocolProfile | null;

  const isEdit = profile?.mode === 'edit';

  const mailTransportIds = useCorrespondentMailTransportIds(widget.id!);

  const profileDocument = useGetDocumentaryDocumentsId(
    profile?.documentId!,
    { widgetId: widget.id! },
    {
      query: {
        enabled: profile?.documentId != null,
        staleTime: Infinity
      }
    }
  );

  const valueToContext = (value: DeskDocument) => {
    return {
      documentMode: value.mode
    };
  };

  const existingValues = useMemo(() => {
    if (!isEdit) {
      return {
        subject: profile?.documentSubject,
        content: profileDocument.data?.content,
        filename: profileDocument.data?.filename
      };
    }

    const attributeValues = profileDocument.data?.extendedAttributes ?? [];

    return {
      ...omit(profileDocument.data, ['content']),
      localExtendedAttributes: extendedAttributesFromPairs(attributeValues),
      classifications: profileDocument.data?.classifications?.map(c =>
        ClassificationNodeToolkitObject.create({
          id: c.id!,
          name: `${c.path ?? c.code} - ${c.description ?? 'N.A.'}`,
          data: c,
          sourceWidgetId: widget.id!
        })
      ),
      info: {
        ...profileDocument.data?.info,
        senders: profileDocument.data?.info?.senders?.map(s => {
          return {
            office: OfficeToolkitObject.create({
              id: s.id!,
              name: s.name!,
              data: s,
              sourceWidgetId: widget.id!
            }),
            mode: s.competence
              ? SenderReceiverOfficeMode.Competenza
              : SenderReceiverOfficeMode.Conoscenza
          };
        }),
        receivers: profileDocument.data?.info?.receivers?.map(r => {
          return {
            office: OfficeToolkitObject.create({
              id: r.id!,
              name: r.name!,
              data: r,
              sourceWidgetId: widget.id!
            }),
            mode: r.competence
              ? SenderReceiverOfficeMode.Competenza
              : SenderReceiverOfficeMode.Conoscenza
          };
        })
      },
      mailboxId: profileDocument.data?.idMailbox ?? undefined
    };
  }, [isEdit, profileDocument.data]);

  if (profile?.documentId && profileDocument.isLoading)
    return <Skeleton active />;

  return (
    <FormikAugmented<DeskDocument>
      validationSchema={DocumentProtocolSchema(documentExtendedType)}
      validationContext={{ isEdit, mailTransportIds }}
      valueToContext={valueToContext}
      initialValues={
        {
          reserved: false,
          idConfidentialityLevel: 5, // Non classificato
          ...existingValues
        } as any
      }
      onSubmit={async (values, helpers) => {
        try {
          const classifications = values.classifications as
            | ToolkitObject[]
            | null;
          const senders = values.info?.senders as
            | ISenderReceiverOffice[]
            | null;
          const receivers = values.info?.receivers as
            | ISenderReceiverOffice[]
            | null;
          const mailboxId = (values as any).mailboxId as number | null;
          const extendedAttributes = extendedAttributesToPairs(
            (values as any).localExtendedAttributes
          );

          const documentId = profileDocument.data?.id;

          const data = {
            data: {
              mailboxId: mailboxId ?? undefined,
              document: {
                ...omit(values, ['mailboxId', 'localExtendedAttributes']),
                // L'id viene inviato solo se stiamo protocollando un documento grigio
                id: documentId,
                extendedAttributes,
                classifications: classifications?.map(c => ({
                  id: c.id as number
                })),
                info: {
                  ...values.info,
                  senders: senders?.map(s => ({
                    id: s.office?.id as number,
                    competence: s.mode === SenderReceiverOfficeMode.Competenza
                  })),
                  receivers: receivers?.map(r => ({
                    id: r.office?.id as number,
                    competence: r.mode === SenderReceiverOfficeMode.Competenza
                  }))
                }
              },
              widgetId: widget.id
            }
          };

          if (isEdit) {
            await editProtocol.mutateAsync({
              id: documentId!,
              ...data,
              params: { widgetId: widget.id! }
            });
          } else {
            await protocol.mutateAsync({ ...data });
          }

          message.success(
            isEdit
              ? 'Il protocollo è stato modificato con successo'
              : 'Il documento è stato protocollato con successo.'
          );

          // Rimuoviamo il widget se era collegato a un documento specifico,
          // non ha senso protocollarlo due volte.
          // TODO: La rimozione dalla cartella personale?
          if (profile?.documentId) {
            // Disabilitiamo la modalità fullscreen
            dispatch(DeskActions.fullScreen(false));
            await ctx.remove(widget);
          }

          helpers.resetForm();
        } catch (error) {
          message.error(
            getNetworkErrorMessage(
              error,
              isEdit
                ? 'Si è verificato un errore durante la modifica del protocollo'
                : 'Si è verificato un errore durante il protocollo del documento.'
            )
          );
          console.error(error);
        }
      }}
    >
      <WidgetCardBody>
        {/* <WidgetTabPanel
          title="Anteprima"
          icon={<FileOutlined />}
          type="main"
          tabKey="main"
          padded
          scrollable={false}
        >
          File documento...
        </WidgetTabPanel>
        <WidgetTabPanel
          title="Dati Protocollazione"
          tabKey="data"
          icon={<FormOutlined />}
          scrollable={false}
        > */}
        <WidgetDocumentProtocolForm
          widget={widget}
          isEdit={isEdit}
          protocolNumber={profileDocument.data?.protocolNumber}
          documentExtendedType={documentExtendedType}
          setDocumentExtendedType={setDocumentExtendedType}
        />
        {/* </WidgetTabPanel>
        <WidgetTabPanel
          title="Allegati"
          icon={<PaperClipOutlined />}
          type="side-menu"
          tabKey="side-menu"
        >
          <TabsSideMenu
            items={menu}
            defaultSelectedKeys={[menuSelectedKey]}
            onClick={e => setMenuSelectedKey(e.key)}
          />
        </WidgetTabPanel> */}
      </WidgetCardBody>
    </FormikAugmented>
  );
}

import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { yup } from 'common/validation/initYup';
import { getDocumentaryAssignmentsMassiveAcceptance } from 'client/api/backend/documentary-assignments/documentary-assignments';
import { InferType } from 'yup';
import { message } from 'antd';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { VerticalAlignBottomOutlined } from '@ant-design/icons';
import { AssignmentToolkitObject } from 'client/components/widget-repository/objects/assignments/AssignmentToolkitObject';
import { DeskAssignment } from 'client/api/backend/schemas';
import { AssignmentType } from 'client/components/widget-repository/objects/assignments/mapIdType';
import { AssignmentState } from 'client/components/widget-repository/objects/assignments/mapIdState';
import { invalidateAssignmentQueries } from '../invalidateAssignmentQueries';

export const WidgetActionTakeChargeOfAssignment = createToolkitAction({
  name: 'Prendi in Carico',
  code: 'take-charge-assignment',
  icon: <VerticalAlignBottomOutlined />,
  arguments: [
    {
      name: 'Assegnazione da Prendere in Carico',
      type: [AssignmentToolkitObject],
      isArray: true,
      allows: (obj: ToolkitObject<DeskAssignment>) =>
        obj.data.idType?.toString() === AssignmentType.Competenza &&
        obj.data.idState?.toString() === AssignmentState.DaEseguireCompetenza
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    assignmentsObject: ToolkitObject<DeskAssignment>[] | ToolkitObject | null
  ) {
    const assignments = AssignmentToolkitObject.getMany(assignmentsObject)!;

    try {
      await getDocumentaryAssignmentsMassiveAcceptance({
        ids: assignments.map(assignment => assignment.data.id!),
        widgetId: assignments[0].sourceWidgetId
      });

      // Invalido la cache
      invalidateAssignmentQueries();

      message.success(
        assignments.length > 1
          ? 'Assegnazioni presa in carico con successo.'
          : 'Assegnazione presa in carico con successo.'
      );
    } catch (e: any) {
      message.error(
        getNetworkErrorMessage(e, 'Errore durante la presa in carico.')
      );
      console.error(e);
      return false;
    }
  }
});

const TakeChargeSchema = yup.object({});

import { createGlobalStyle } from 'styled-components';

// importato in Index.tsx
export const GridDraggingStyles = createGlobalStyle`
.react-grid-layout {
  position: relative;
  transition: height 200ms ease;
  width: 100%;
}
.react-grid-item {
  transition: all 200ms ease;
  transition-property: left, top;
}
.react-grid-item img {
  pointer-events: none;
  user-select: none;  
}
.react-grid-item.cssTransforms {
  transition-property: transform;
}
.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height;
}

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform;
}

.react-grid-item.dropping {
  visibility: hidden;
}

.react-grid-item.react-grid-placeholder {
  background: #fff;
  border-radius: 24px;
  opacity: 0.45;
  transition-duration: 100ms;
  /* z-index: 2; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.react-grid-item > .react-resizable-handle {
  position: absolute;
  width: 28px;
  height: 28px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid  #e7ecee;
  opacity: 1;
  transition: all 300ms ease;
  &:hover {
    border-color: rgba(0, 128, 255, 1);
    &:after {
      border-color: rgba(0, 128, 255, 1);
    }
  }
}

.react-grid-item > .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 9px;
  bottom: 9px;
  width: 9px;
  height: 9px;
  border-right: 2px solid rgba(84, 100, 109, 0.9); 
  border-bottom: 2px solid rgba(84, 100, 109, 0.9);
}

.react-resizable-hide > .react-resizable-handle {
  display: none;
}

.react-grid-item > .react-resizable-handle.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-se {
  bottom: 20px;
  right: 20px;
  cursor: se-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w,
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  top: 50%;
  margin-top: -14px;
  cursor: ew-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-e {
  right: 20px;
  transform: rotate(315deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n,
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  left: 50%;
  margin-left: -14px;
  cursor: ns-resize;
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-grid-item > .react-resizable-handle.react-resizable-handle-s {
  bottom: 20px;
  transform: rotate(45deg);
}
`;

import { DeskFolder } from 'client/api/backend/schemas';
import { registerToolkitObjectType } from 'client/components/toolkit/objects/registerToolkitObjectType';

export interface ExtendedDeskFolder extends DeskFolder {
  parentId: number;
  source: 'personal-dossier' | 'dossier';
}

export const FolderToolkitObject =
  registerToolkitObjectType<ExtendedDeskFolder>('folder');

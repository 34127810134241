import {
  DeskDocument,
  DeskExtendedType,
  Widget
} from 'client/api/backend/schemas';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { yup } from 'common/validation/initYup';
import React, { useEffect, useState } from 'react';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { Button, Divider, Space } from 'antd';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { WidgetSteps } from 'client/components/toolkit/components/speps/WidgetSteps';
import { WidgetStepPanel } from 'client/components/toolkit/components/speps/WidgetStepPanel';
import styled from 'styled-components';
import { UploadDraggerInput } from 'client/ui/form/input/UploadDraggerInput';
import { UploadInputWrapper } from 'client/ui/form/input/UploadInputWrapper';
import { RegistrySelectInput } from 'client/components/widget-repository/objects/registries/RegistrySelectInput';
import { CorrespondentsInput } from 'client/components/widget-repository/objects/correspondents/input/CorrespondentsInput';
import { UploadDraggerBase64Input } from 'client/ui/form/input/UploadDraggerBase64Input';
import { useFormikContext } from 'formik';
import { FormErrorsNotification } from 'client/ui/form/errors/FormErrorsNotification';
import { DocumentConfidentialityInput } from 'client/components/widget-repository/objects/documents/DocumentConfidentialityInput';
import { ToolkitObjectInput } from 'client/components/toolkit/objects/input/ToolkitObjectInput';
import { ClassificationNodeToolkitObject } from 'client/components/widget-repository/objects/classifications/ClassificationNodeToolkitObject';
import { OfficeToolkitObject } from 'client/components/widget-repository/objects/offices/OfficeToolkitObject';
import { DocumentProtocolOfficesForm } from './DocumentProtocolOfficesForm';
import { DocumentTypeInput } from 'client/components/widget-repository/objects/documents/DocumentTypeInput';
import { FullBodyForm } from 'client/ui/full-body-form/FullBodyForm';
import { RcFile } from 'antd/lib/upload';
import { DocumentProtocolRegistryModeInput } from 'client/components/widget-repository/objects/documents/DocumentProtocolRegistryModeInput';
import { DocumentProtocolMailBoxesInput } from 'client/components/widget-repository/objects/documents/DocumentProtocolMailBoxesInput';
import { ClassificationParamsType } from 'client/components/widget-repository/objects/classifications/ClassificationParamsType';
import { DocumentSubtypeMetadataFormInput } from 'client/components/widget-repository/objects/documents/DocumentSubtypeMetadataFormInput';
import { DocumentMode } from 'client/components/widget-repository/objects/documents/DocumentMode';

export interface IWidgetDocumentProtocolFormProps {
  widget: Widget;
  isEdit: boolean;
  protocolNumber?: number;
  documentExtendedType?: DeskExtendedType;
  setDocumentExtendedType: (value?: DeskExtendedType) => void;
}

export function WidgetDocumentProtocolForm(
  props: IWidgetDocumentProtocolFormProps
) {
  const {
    isEdit,
    widget,
    protocolNumber,
    documentExtendedType,
    setDocumentExtendedType
  } = props;
  const formik = useFormikContext<DeskDocument>();

  const areCorrespondentsAvailable =
    formik.values.mode != null &&
    formik.values.mode !== DocumentMode.Internal &&
    formik.values.mode !== DocumentMode.Registration;

  // Se il numero di protocollo è già presente nel titolo del widget lo escludiamo dal body
  // per pulizia
  const protocolNumberInBody =
    protocolNumber && !widget.profile?.data?.protocolNumber
      ? `n.${protocolNumber}`
      : '';

  return (
    <FullBodyForm>
      <FormErrorsNotification />
      <FormikForm layout="vertical" editable helpInTooltips>
        <WidgetSteps
          cancelButtonProps={{ onClick: () => formik.resetForm() }}
          finishButtonProps={{
            children: isEdit ? 'Modifica Protocollo' : 'Protocolla',
            htmlType: 'submit',
            disabled: !formik.dirty
          }}
        >
          <WidgetStepPanel
            title={['Protocollo', protocolNumberInBody].join(' ')}
            stepKey="protocol"
          >
            <FormFieldsContainer removeTopTitleSpacing columns={2} gutter={8}>
              <FormFieldItem
                size="middle"
                component={RegistrySelectInput}
                name="idRegistry"
                disabled={isEdit}
                widgetId={widget.id!}
                label="Registrazione"
                placeholder="Registrazione"
              />
              <FormFieldItem
                size="middle"
                component={DocumentProtocolRegistryModeInput}
                disabled={isEdit}
                widgetId={widget.id!}
                registryIdFieldName="idRegistry"
                name="mode"
                label="Modalità"
                placeholder="Modalità"
              />
              <FormFieldItem
                colSpan={2}
                size="middle"
                component={TextInput}
                disabled={isEdit}
                name="subject"
                label="Oggetto"
                placeholder="Oggetto"
              />
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                name="dateDocument"
                type="unix"
                label="Data documento"
                placeholder="Data documento"
              />
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                name="expireDate"
                type="unix"
                label="Data scadenza"
                placeholder="Data scadenza"
              />
              <FormFieldItem
                colSpan={2}
                size="middle"
                component={TextInput}
                name="signer"
                label="Firmatario"
                placeholder="Firmatario"
              />
              <FormFieldItem
                component={CheckboxInput}
                name="reserved"
                label="Riservato"
              />
            </FormFieldsContainer>
            <DocumentProtocolOfficesForm disabled={isEdit} widget={widget} />
            <FormFieldItem
              size="middle"
              component={DocumentTypeInput}
              widgetId={widget.id!}
              name="idType"
              label="Tipo Documento"
              placeholder="Tipo Documento"
            />
            <DocumentSubtypeMetadataFormInput
              widgetId={widget.id!}
              documentExtendedType={documentExtendedType}
              setDocumentExtendedType={setDocumentExtendedType}
            />
          </WidgetStepPanel>
          {areCorrespondentsAvailable && (
            <WidgetStepPanel title="Corrispondenti" stepKey="correspondent">
              <FormFieldItem
                size="middle"
                component={DocumentProtocolMailBoxesInput}
                disabled={isEdit}
                registryIdFieldName="idRegistry"
                correspondentsFieldName="correspondents"
                widgetId={widget.id!}
                name="mailboxId"
                label="Casella e-mail d'invio"
                placeholder="Casella e-mail d'invio"
              />
              <CorrespondentsInput
                disabled={isEdit}
                displayName="Corrispondente"
                name="correspondents"
              />
            </WidgetStepPanel>
          )}

          <WidgetStepPanel title="Classificazione" stepKey="classifications">
            <FormFieldsContainer removeTopTitleSpacing columns={1} gutter={8}>
              <FormFieldItem
                size="large"
                component={ToolkitObjectInput}
                objectType={ClassificationNodeToolkitObject}
                fetchObjectParams={{ type: ClassificationParamsType.Document }}
                widgetId={widget.id!}
                mode="multiple"
                name="classifications"
                label="Classificazione"
                placeholder="Classificazione"
              />
            </FormFieldsContainer>
          </WidgetStepPanel>

          <WidgetStepPanel title="Allegati" stepKey="attachments">
            <FormFieldsContainer removeTopTitleSpacing columns={1} gutter={8}>
              <FormFieldItem
                component={UploadDraggerBase64Input}
                name="content"
                options={{
                  type: 'single',
                  filenameFieldName: 'filename'
                }}
                disabled={isEdit && (formik.values as any).filename}
                label="Documento Principale"
                descriptionText="Fai click o trascina un file per caricare il documento principale"
              />
              <Divider />
              <FormFieldItem
                component={UploadDraggerBase64Input}
                name="attachments"
                options={{
                  type: 'multiple',
                  transformFile: (
                    file: RcFile,
                    mimeType: string,
                    content: string
                  ) => ({
                    content,
                    name: file.name
                  })
                }}
                label="Allegati"
                descriptionText="Fai click o trascina un file per fare caricare gli allegati"
                showUploadList={{ showRemoveIcon: !isEdit }}
              />
            </FormFieldsContainer>
          </WidgetStepPanel>

          {/* <WidgetStepPanel title="Riferimenti" stepKey="reference">
              <FormFieldsContainer removeTopTitleSpacing columns={1} gutter={8}>
                <FormFieldItem
                  size="middle"
                  component={SelectInput}
                  filterOption
                  name="references.id"
                  label={null}
                  placeholder="Tipo Riferimento"
                  options={[
                    {
                      value: '2.1',
                      label: 'Personale Dipendente'
                    },
                    {
                      value: '2.2',
                      label: 'Altro'
                    }
                  ]}
                />
                <FormFieldItem
                  size="middle"
                  component={TextInput}
                  name="references.description"
                  label={null}
                  placeholder="Riferimento"
                />
              </FormFieldsContainer>
            </WidgetStepPanel> */}
        </WidgetSteps>
      </FormikForm>
    </FullBodyForm>
  );
}

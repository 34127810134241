import * as React from 'react';
import clx from 'classnames';
import styled from 'styled-components';
import { useDesk } from 'client/components/schema/desk/DeskModule';

const TabPanelScroller = styled.div`
  flex: 1 1 auto;
  overflow-y: hidden;
  min-height: 0;
  height: 100%;
  max-height: 100%;
  min-width: 0;

  border-radius: 0;
  border-bottom-right-radius: ${props => props.theme.cardRadius};

  &.has-scroll {
    overflow-y: auto;
  }

  &.editable {
    overflow-y: hidden;
  }

  &.is-main-content {
    border-bottom-left-radius: ${props => props.theme.cardRadius};
    border-bottom-right-radius: 0;
  }
  &.is-mobile {
    border-bottom-left-radius: ${props => props.theme.cardRadius};
  }
  &.padded {
    padding: ${props => props.theme.cardPaddingBase};
  }
`;

export interface WidgetTabPanelScrollerProps {
  children: React.ReactNode;
  padded?: boolean;
  isMobile?: boolean;
  isMainContent?: boolean;
  /** Di default il contenuto e scrollabile
   * passare false per non scrollabile
   */
  scrollable?: boolean;
}

/**
 * Body del singolo Tab (WidgetTabPanel) che permette lo scrolling del contenuto
 */
export function WidgetTabPanelScroller(props: WidgetTabPanelScrollerProps) {
  const deskState = useDesk();

  const { children, padded, isMobile, isMainContent, scrollable } = props;
  const hasScroll = scrollable !== false;

  return (
    <TabPanelScroller
      className={clx({
        padded,
        'is-mobile': isMobile,
        'is-main-content': isMainContent,
        'has-scroll': hasScroll,
        editable: deskState.isEditable
      })}
    >
      {children}
    </TabPanelScroller>
  );
}

import { AppstoreOutlined } from '@ant-design/icons';
import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetDamsSearchFilterForm } from './filters/WidgetDamsSearchFilterForm';
import { WidgetDamsSearch } from './WidgetDamsSearch';
import { FvIconDamSearch } from 'client/ui/icon/FvIconsDam';

export const WidgetDamsSearchType: IWidgetType = {
  code: 'dam-search',
  name: 'Ricerca Dighe',
  icon: <FvIconDamSearch />,
  component: WidgetDamsSearch,
  filterComponent: WidgetDamsSearchFilterForm,
  description: `Ricerca le dighe presenti nella banca dati`
};

WidgetRepository.register(WidgetDamsSearchType);

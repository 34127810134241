import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { DocumentToolkitObject } from '../documents/DocumentToolkitObject';
import { AssignmentToolkitObject } from './AssignmentToolkitObject';
import { AssignmentState } from './mapIdState';

/**
 * Controlla se l'azione è permessa, ovvero se l'assegnazione non è conclusa
 */
export function isAssignmentActionAllowed(obj: ToolkitObject): boolean {
  const document = DocumentToolkitObject.get(obj);

  if (document != null && !document.isRelated) {
    return true;
  }

  const assignment = AssignmentToolkitObject.get(obj);

  if (
    assignment != null &&
    assignment.data.idState?.toString() !== AssignmentState.Conclusa
  ) {
    return true;
  }

  return false;
}

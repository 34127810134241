/**
 * Generato automaticamente.
 * DeskUI REST API
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  UserListResponseDTO,
  UserListRequestDTO,
  UserImportResponseDTO,
  UserImportRequestDTO,
  DocumentaryTemplate,
  OptionItem,
  ConfigurationField,
  UserResponseDTO,
  GetDocumentaryUsersManagersParams,
  DeskOffice,
  GetDocumentaryUsersOfficesParams,
  GetDocumentaryUsersRolesParams
} from '.././schemas';
import {
  apiInstance,
  ErrorType
} from '../../../core/network/mutators/apiClient';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Ritorna la lista degli utenti
 */
export const postDocumentaryUsers = (
  userListRequestDTO: UserListRequestDTO
) => {
  return apiInstance<UserListResponseDTO | void>({
    url: `/api/v1/documentary/users`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: userListRequestDTO
  });
};

export type PostDocumentaryUsersMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryUsers>
>;
export type PostDocumentaryUsersMutationBody = UserListRequestDTO;
export type PostDocumentaryUsersMutationError = ErrorType<unknown>;

export const usePostDocumentaryUsers = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryUsers>,
    TError,
    { data: UserListRequestDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryUsers>,
    { data: UserListRequestDTO }
  > = props => {
    const { data } = props || {};

    return postDocumentaryUsers(data);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryUsers>,
    TError,
    { data: UserListRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Importa utenti dal documentale
 */
export const postDocumentaryUsersImport = (
  userImportRequestDTO: UserImportRequestDTO
) => {
  return apiInstance<UserImportResponseDTO | void>({
    url: `/api/v1/documentary/users/import`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: userImportRequestDTO
  });
};

export type PostDocumentaryUsersImportMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryUsersImport>
>;
export type PostDocumentaryUsersImportMutationBody = UserImportRequestDTO;
export type PostDocumentaryUsersImportMutationError = ErrorType<unknown>;

export const usePostDocumentaryUsersImport = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryUsersImport>,
    TError,
    { data: UserImportRequestDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryUsersImport>,
    { data: UserImportRequestDTO }
  > = props => {
    const { data } = props || {};

    return postDocumentaryUsersImport(data);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryUsersImport>,
    TError,
    { data: UserImportRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Ritorna il template per la configurazione dell'importazione
 */
export const getDocumentaryUsersImportConfigurationId = (id: number) => {
  return apiInstance<DocumentaryTemplate>({
    url: `/api/v1/documentary/users/import/configuration/${id}`,
    method: 'get'
  });
};

export const getGetDocumentaryUsersImportConfigurationIdQueryKey = (
  id: number
) => [`/api/v1/documentary/users/import/configuration/${id}`];

export type GetDocumentaryUsersImportConfigurationIdQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryUsersImportConfigurationId>
>;
export type GetDocumentaryUsersImportConfigurationIdQueryError =
  ErrorType<void>;

export const useGetDocumentaryUsersImportConfigurationId = <
  TData = AsyncReturnType<typeof getDocumentaryUsersImportConfigurationId>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryUsersImportConfigurationId>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryUsersImportConfigurationIdQueryKey(id);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryUsersImportConfigurationId>
  > = () => getDocumentaryUsersImportConfigurationId(id);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryUsersImportConfigurationId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna i valori per il campo di configurazione
 */
export const postDocumentaryUsersImportConfigurationIdFieldsField = (
  id: number,
  field: string,
  configurationField: ConfigurationField[]
) => {
  return apiInstance<OptionItem[] | void>({
    url: `/api/v1/documentary/users/import/configuration/${id}/fields/${field}`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: configurationField
  });
};

export type PostDocumentaryUsersImportConfigurationIdFieldsFieldMutationResult =
  NonNullable<
    AsyncReturnType<typeof postDocumentaryUsersImportConfigurationIdFieldsField>
  >;
export type PostDocumentaryUsersImportConfigurationIdFieldsFieldMutationBody =
  ConfigurationField[];
export type PostDocumentaryUsersImportConfigurationIdFieldsFieldMutationError =
  ErrorType<unknown>;

export const usePostDocumentaryUsersImportConfigurationIdFieldsField = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<
      typeof postDocumentaryUsersImportConfigurationIdFieldsField
    >,
    TError,
    { id: number; field: string; data: ConfigurationField[] },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<
      typeof postDocumentaryUsersImportConfigurationIdFieldsField
    >,
    { id: number; field: string; data: ConfigurationField[] }
  > = props => {
    const { id, field, data } = props || {};

    return postDocumentaryUsersImportConfigurationIdFieldsField(
      id,
      field,
      data
    );
  };

  return useMutation<
    AsyncReturnType<
      typeof postDocumentaryUsersImportConfigurationIdFieldsField
    >,
    TError,
    { id: number; field: string; data: ConfigurationField[] },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Ritorna la lista degli responsabili per AOO
 */
export const getDocumentaryUsersManagers = (
  params?: GetDocumentaryUsersManagersParams
) => {
  return apiInstance<UserResponseDTO[]>({
    url: `/api/v1/documentary/users/managers`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryUsersManagersQueryKey = (
  params?: GetDocumentaryUsersManagersParams
) => [`/api/v1/documentary/users/managers`, ...(params ? [params] : [])];

export type GetDocumentaryUsersManagersQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryUsersManagers>
>;
export type GetDocumentaryUsersManagersQueryError = ErrorType<void>;

export const useGetDocumentaryUsersManagers = <
  TData = AsyncReturnType<typeof getDocumentaryUsersManagers>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryUsersManagersParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryUsersManagers>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryUsersManagersQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryUsersManagers>
  > = () => getDocumentaryUsersManagers(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryUsersManagers>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna la lista degli uffici associati all'utente corrente
 */
export const getDocumentaryUsersOffices = (
  params?: GetDocumentaryUsersOfficesParams
) => {
  return apiInstance<DeskOffice[]>({
    url: `/api/v1/documentary/users/offices`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryUsersOfficesQueryKey = (
  params?: GetDocumentaryUsersOfficesParams
) => [`/api/v1/documentary/users/offices`, ...(params ? [params] : [])];

export type GetDocumentaryUsersOfficesQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryUsersOffices>
>;
export type GetDocumentaryUsersOfficesQueryError = ErrorType<void>;

export const useGetDocumentaryUsersOffices = <
  TData = AsyncReturnType<typeof getDocumentaryUsersOffices>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryUsersOfficesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryUsersOffices>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryUsersOfficesQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryUsersOffices>
  > = () => getDocumentaryUsersOffices(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryUsersOffices>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna la lista dei ruoli per AOO
 */
export const getDocumentaryUsersRoles = (
  params?: GetDocumentaryUsersRolesParams
) => {
  return apiInstance<OptionItem[]>({
    url: `/api/v1/documentary/users/roles`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryUsersRolesQueryKey = (
  params?: GetDocumentaryUsersRolesParams
) => [`/api/v1/documentary/users/roles`, ...(params ? [params] : [])];

export type GetDocumentaryUsersRolesQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryUsersRoles>
>;
export type GetDocumentaryUsersRolesQueryError = ErrorType<void>;

export const useGetDocumentaryUsersRoles = <
  TData = AsyncReturnType<typeof getDocumentaryUsersRoles>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryUsersRolesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryUsersRoles>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryUsersRolesQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryUsersRoles>
  > = () => getDocumentaryUsersRoles(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryUsersRoles>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * Generato automaticamente.
 * DeskUI REST API
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  PostAuthenticationAzureLogin200,
  AzureLoginRequest,
  PostAuthenticationIamLogin200,
  IAMLoginRequest,
  JwtAuthenticationRequest,
  GetAuthenticationRefreshToken200,
  PostAuthenticationSiteminderLogin200,
  PostAuthenticationSsoLogin200
} from '.././schemas';
import {
  apiInstance,
  ErrorType
} from '../../../core/network/mutators/apiClient';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Pre-autenticazione con Azure
 */
export const postAuthenticationAzureLogin = (
  azureLoginRequest: AzureLoginRequest
) => {
  return apiInstance<PostAuthenticationAzureLogin200 | void>({
    url: `/api/v1/authentication/azure/login`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: azureLoginRequest
  });
};

export type PostAuthenticationAzureLoginMutationResult = NonNullable<
  AsyncReturnType<typeof postAuthenticationAzureLogin>
>;
export type PostAuthenticationAzureLoginMutationBody = AzureLoginRequest;
export type PostAuthenticationAzureLoginMutationError = ErrorType<unknown>;

export const usePostAuthenticationAzureLogin = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postAuthenticationAzureLogin>,
    TError,
    { data: AzureLoginRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postAuthenticationAzureLogin>,
    { data: AzureLoginRequest }
  > = props => {
    const { data } = props || {};

    return postAuthenticationAzureLogin(data);
  };

  return useMutation<
    AsyncReturnType<typeof postAuthenticationAzureLogin>,
    TError,
    { data: AzureLoginRequest },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Pre-autenticazione con IAM
 */
export const postAuthenticationIamLogin = (
  iAMLoginRequest: IAMLoginRequest
) => {
  return apiInstance<PostAuthenticationIamLogin200 | void>({
    url: `/api/v1/authentication/iam/login`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: iAMLoginRequest
  });
};

export type PostAuthenticationIamLoginMutationResult = NonNullable<
  AsyncReturnType<typeof postAuthenticationIamLogin>
>;
export type PostAuthenticationIamLoginMutationBody = IAMLoginRequest;
export type PostAuthenticationIamLoginMutationError = ErrorType<unknown>;

export const usePostAuthenticationIamLogin = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postAuthenticationIamLogin>,
    TError,
    { data: IAMLoginRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postAuthenticationIamLogin>,
    { data: IAMLoginRequest }
  > = props => {
    const { data } = props || {};

    return postAuthenticationIamLogin(data);
  };

  return useMutation<
    AsyncReturnType<typeof postAuthenticationIamLogin>,
    TError,
    { data: IAMLoginRequest },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Login con credenziali
 */
export const postAuthenticationLogin = (
  jwtAuthenticationRequest: JwtAuthenticationRequest
) => {
  return apiInstance<void>({
    url: `/api/v1/authentication/login`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: jwtAuthenticationRequest
  });
};

export type PostAuthenticationLoginMutationResult = NonNullable<
  AsyncReturnType<typeof postAuthenticationLogin>
>;
export type PostAuthenticationLoginMutationBody = JwtAuthenticationRequest;
export type PostAuthenticationLoginMutationError = ErrorType<unknown>;

export const usePostAuthenticationLogin = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postAuthenticationLogin>,
    TError,
    { data: JwtAuthenticationRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postAuthenticationLogin>,
    { data: JwtAuthenticationRequest }
  > = props => {
    const { data } = props || {};

    return postAuthenticationLogin(data);
  };

  return useMutation<
    AsyncReturnType<typeof postAuthenticationLogin>,
    TError,
    { data: JwtAuthenticationRequest },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Refresh token JWT
 */
export const getAuthenticationRefreshToken = () => {
  return apiInstance<GetAuthenticationRefreshToken200>({
    url: `/api/v1/authentication/refresh-token`,
    method: 'get'
  });
};

export const getGetAuthenticationRefreshTokenQueryKey = () => [
  `/api/v1/authentication/refresh-token`
];

export type GetAuthenticationRefreshTokenQueryResult = NonNullable<
  AsyncReturnType<typeof getAuthenticationRefreshToken>
>;
export type GetAuthenticationRefreshTokenQueryError = ErrorType<void>;

export const useGetAuthenticationRefreshToken = <
  TData = AsyncReturnType<typeof getAuthenticationRefreshToken>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<
    AsyncReturnType<typeof getAuthenticationRefreshToken>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetAuthenticationRefreshTokenQueryKey();

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getAuthenticationRefreshToken>
  > = () => getAuthenticationRefreshToken();

  const query = useQuery<
    AsyncReturnType<typeof getAuthenticationRefreshToken>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Pre-autenticazione con SiteMinder
 */
export const postAuthenticationSiteminderLogin = () => {
  return apiInstance<PostAuthenticationSiteminderLogin200 | void>({
    url: `/api/v1/authentication/siteminder/login`,
    method: 'post'
  });
};

export type PostAuthenticationSiteminderLoginMutationResult = NonNullable<
  AsyncReturnType<typeof postAuthenticationSiteminderLogin>
>;

export type PostAuthenticationSiteminderLoginMutationError = ErrorType<unknown>;

export const usePostAuthenticationSiteminderLogin = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postAuthenticationSiteminderLogin>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postAuthenticationSiteminderLogin>,
    TVariables
  > = () => {
    return postAuthenticationSiteminderLogin();
  };

  return useMutation<
    AsyncReturnType<typeof postAuthenticationSiteminderLogin>,
    TError,
    TVariables,
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Pre-autenticazione con Single Sign On
 */
export const postAuthenticationSsoLogin = () => {
  return apiInstance<PostAuthenticationSsoLogin200 | void>({
    url: `/api/v1/authentication/sso/login`,
    method: 'post'
  });
};

export type PostAuthenticationSsoLoginMutationResult = NonNullable<
  AsyncReturnType<typeof postAuthenticationSsoLogin>
>;

export type PostAuthenticationSsoLoginMutationError = ErrorType<unknown>;

export const usePostAuthenticationSsoLogin = <
  TError = ErrorType<unknown>,
  TVariables = void,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postAuthenticationSsoLogin>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postAuthenticationSsoLogin>,
    TVariables
  > = () => {
    return postAuthenticationSsoLogin();
  };

  return useMutation<
    AsyncReturnType<typeof postAuthenticationSsoLogin>,
    TError,
    TVariables,
    TContext
  >(mutationFn, mutationOptions);
};

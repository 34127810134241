import { IDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { DeskWidgetsLogic } from 'client/components/schema/desk/logic/DeskWidgetsLogic';
import {
  createToolkitAction,
  ToolkitAction
} from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import {
  getToolkitObjectOfType,
  ToolkitObject
} from 'client/components/toolkit/objects/ToolkitObject';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { DamToolkitObject } from 'client/components/widget-repository/objects/dams/DamToolkitObject';
import { set } from 'client/core/data/set';
import { FvIconDamPin } from 'client/ui/icon/FvIconsDam';
import React from 'react';
import { WidgetDamGeolocationType } from '../WidgetDamGeolocation.type';
import { WidgetDamGeolocationProfile } from '../WidgetDamGeolocationProfile';

export const WidgetActionGeolocateDam = createToolkitAction({
  name: 'Geolocalizzazione Diga',
  code: 'geolocate-dam',
  icon: <FvIconDamPin />,
  arguments: [
    {
      type: [DamToolkitObject],
      name: 'Diga da Geolocalizzare'
    },
    {
      type: [WidgetToolkitObject],
      allows: (object: ToolkitObject) =>
        WidgetToolkitObject.matches(object, WidgetDamGeolocationType),
      name: 'Widget Diga da Geolocalizzare'
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    object: ToolkitObject,
    targetObject: ToolkitObject | null
  ) {
    const dam = DamToolkitObject.get(object)!;
    const target = WidgetToolkitObject.get(targetObject);

    const data: WidgetDamGeolocationProfile = {
      damLocation: {
        latitude: dam.data.latitude!,
        longitude: dam.data.longitude!
      }
    };

    if (target) {
      const widget = ctx.deskContext.widgets.find(w => w.id === target.id)!;
      await ctx.deskContext.updateWidget(set(widget, 'profile.data', data));
      return;
    }

    await ctx.deskContext.cloneWidget(
      {
        type: 'floating',
        expanded: true,
        order: DeskWidgetsLogic.getFloatingNextOrder(
          ctx.deskContext.floatingWidgets
        )
      },
      WidgetDamGeolocationType,
      ctx.sourceWidget!,
      { data, quickClose: true }
    );
  }
});

import {
  DeskCorrespondent,
  DeskExtendedType
} from 'client/api/backend/schemas';
import { getCorrespondentSchema } from 'client/components/widget-repository/objects/correspondents/schema/CorrespondentSchema';
import { DocumentMode } from 'client/components/widget-repository/objects/documents/DocumentMode';
import { mapDocumentModeToOfficeVisibility } from 'client/components/widget-repository/objects/documents/logic/mapDocumentModeToOfficeVisibility';
import { getExtendedAttributesSchema } from 'client/components/widget-repository/objects/extended-attributes/ExtendedAttributesSchema';
import { SenderReceiverOfficeMode } from 'client/components/widget-repository/objects/offices/SenderReceiverOffice';
import { yup } from 'common/validation/initYup';
import { NumberSchema, StringSchema, BaseSchema } from 'yup';

/**
 * Il campo mail è obbligatorio nel caso sia un protocollo in uscita e se il mezzo di
 * spedizione è di tipo mail.
 *
 * @param parent Indica in quale campo dell'array è presente il campo parent in cui è presente il `transport`
 */
export function getDocumentCorrespondentEmailSchema(parent: number) {
  return {
    email: yup
      .string()
      .email()
      .nullable()
      .label('Email')
      .when(
        ['$isEdit', '$mailTransportIds'],
        function (this: yup.BaseSchema<any>, isEdit, mailTransportIds) {
          return this.test({
            params: { mailTransportIds },
            message: 'Il campo ${path} è un campo richiesto.',
            test(value, context: any) {
              const transportId = context.from[parent].value.transport?.id;
              const isModeOutgoing =
                context.from[parent + 1].value.mode === DocumentMode.Outgoing;

              const isRequired =
                (this.resolve(mailTransportIds) || []).includes(transportId) &&
                isModeOutgoing;

              if (isRequired && !isEdit) {
                return value != null && value !== '';
              }

              return true;
            }
          });
        }
      )
  };
}

/**
 * Resituisce lo schema stringa required o notRequired a seconda del valore di `$isEdit`
 */
function getCorrespondentStringSchema() {
  return yup
    .string()
    .when(['$isEdit'], (isEdit: boolean, schema: BaseSchema) => {
      return isEdit ? schema.nullable().notRequired() : schema.required();
    });
}

const DocumentCorrespondentPersonSchema = yup.object({
  name: getCorrespondentStringSchema().label('Nome'),
  surname: getCorrespondentStringSchema().label('Cognome'),
  fiscalCode: yup.string().label('Codice Fiscale').nullable(),
  ...getDocumentCorrespondentEmailSchema(1)
});

const DocumentCorrespondentCorporateSchema = yup.object({
  name: getCorrespondentStringSchema().label('Ragione sociale'),
  address: yup.object({
    ...getDocumentCorrespondentEmailSchema(2)
  })
});

const DocumentCorrespondentAdministrationSchema = yup.object({
  ipacode: getCorrespondentStringSchema().label('Codice IPA'),
  name: getCorrespondentStringSchema().label('Nome Amministrazione'),
  aoocode: getCorrespondentStringSchema().label('Codice AOO'),
  aoodescription: getCorrespondentStringSchema().label('Descrizione AOO'),
  address: yup.object({
    email: yup.string().email().required().label('Email')
  })
});

export const DocumentCorrespondentSchema = getCorrespondentSchema(
  DocumentCorrespondentPersonSchema,
  DocumentCorrespondentCorporateSchema,
  DocumentCorrespondentAdministrationSchema
);

/**
 * Modifica lo schema in base ai valori required del tipo esteso del documento
 */
function getModifiedSchema(schema: BaseSchema, required?: boolean) {
  return required ? schema.required() : schema.nullable().notRequired();
}

function getOfficeSchema(
  officeTypeLabel: string,
  officeType: 'sender' | 'receiver',
  competentsMandatory?: boolean
) {
  return yup
    .array(
      yup.object({
        office: yup.object().label(`Ufficio ${officeTypeLabel}`),
        mode: yup.string().label('Modalità')
      })
    )
    .test({
      message: `Deve essere presente almeno un Ufficio ${officeTypeLabel} Competente`,
      test(value, context: any) {
        if (!competentsMandatory) {
          return true;
        }

        const mode = context?.from?.[1]?.value?.mode;
        const visibilities = mapDocumentModeToOfficeVisibility(mode);

        if (
          (visibilities.senders && officeType === 'sender') ||
          (visibilities.receivers && officeType === 'receiver')
        ) {
          return (
            value?.some(o => o.mode === SenderReceiverOfficeMode.Competenza) ??
            false
          );
        }

        return true;
      }
    });
}

export const DocumentProtocolSchema = (extendedType?: DeskExtendedType) => {
  return yup.object({
    subject: yup.string().required().label('Oggetto'),
    content: yup
      .string()
      .nullable()
      .notRequired()
      .label('Documento Principale'),
    mode: yup.string().required().label('Modalità di Protocollo'),
    idRegistry: yup.number().required().label('Registro'),
    localExtendedAttributes: getExtendedAttributesSchema(
      extendedType?.attributes ?? []
    ),
    mailboxId: yup
      .number()
      .label('Casella')
      .when(['$isEdit', '$mailTransportIds', 'correspondents', 'mode'], ((
        isEdit: boolean,
        mailTransportIds: number[],
        correspondents: DeskCorrespondent[],
        mode: string,
        schema: BaseSchema
      ) => {
        const meanId = correspondents
          ?.map(c => c.transport?.id)
          .find(t => {
            if (t == null) return false;
            return (mailTransportIds || []).includes(t);
          });

        if (meanId != null && mode === DocumentMode.Outgoing) {
          if (isEdit) {
            return schema.nullable().notRequired();
          }

          return schema.required();
        }

        return schema.strip();
      }) as any),
    correspondents: yup
      .array()
      .of(DocumentCorrespondentSchema)
      .label('Corrispondenti'),
    dateDocument: getModifiedSchema(
      yup.number(),
      extendedType?.dateDocumentMandatory
    ).label('Data documento'),
    expireDate: getModifiedSchema(
      yup.number(),
      extendedType?.expireDateMandatory
    ).label('Data scadenza'),
    signer: getModifiedSchema(
      yup.string(),
      extendedType?.signerMandatory
    ).label('Firmatario'),
    info: yup.object({
      dateSend: yup
        .date()
        .label('Data spedizione')
        .when(['$documentMode'], (documentMode, schema) => {
          if (documentMode !== DocumentMode.Outgoing) {
            return schema.nullable().notRequired().strip();
          }

          if (extendedType?.dateReceivedSentMandatory) {
            return schema.required();
          }

          return schema.nullable().notRequired();
        }),
      dateReceived: yup
        .date()
        .label('Data ricezione')
        .when(['$documentMode'], (documentMode, schema) => {
          if (documentMode !== DocumentMode.Incoming) {
            return schema.nullable().notRequired().strip();
          }

          if (extendedType?.dateReceivedSentMandatory) {
            return schema.required();
          }

          return schema.nullable().notRequired();
        }),
      receivers: getOfficeSchema(
        'Destinatario',
        'receiver',
        extendedType?.competentsMandatory
      ),
      senders: getOfficeSchema(
        'Mittente',
        'sender',
        extendedType?.competentsMandatory
      )
    })
  });
};

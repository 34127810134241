import styled from 'styled-components';
import clx from 'classnames';
import React from 'react';

export const FullScreenBackdropStyle = styled.div`
  position: fixed;
  z-index: 2;
  width: 100%;
  height: 1px;
  top: -100px;
  left: 0;
  width: 100%;
  opacity: 0;
  background-color: ${props => props.theme.bodyBackground};
  transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1) 0.15s;

  &.fullscreen {
    top: 0;
    /* height: calc(100vh - 48px); */
    height: 100vh;
    max-height: 100%;
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
  }
`;

export interface FullScreenBackdropProps {
  className?: string;
}

/**
 * Card stilizzata del Widget
 */
export function FullScreenBackdrop(props: FullScreenBackdropProps) {
  return <FullScreenBackdropStyle className={clx(props.className)} />;
}

import * as React from 'react';
import { Metadata } from 'client/components/toolkit/components/metadata/Metadata';
import { DeskActivity } from 'client/api/backend/schemas';
import { convertActivityToMetadata } from '../convert/convertActivityToMetadata';

export interface ActivityMetadataProps {
  widgetId: number;
  activity?: DeskActivity;
}

/**
 * Contenuto del Tab Metadati contenente i metadati dell'atto
 */
export function ActivityMetadata(props: ActivityMetadataProps) {
  const { activity } = props;

  if (!activity) {
    return null;
  }

  const metadata = convertActivityToMetadata(activity);

  return (
    <div>
      <Metadata label="Numero Proposta" value={metadata.data?.proposeNumber} />
      <Metadata label="Data Proposta" value={metadata.data?.proposeDate} />
      <Metadata label="Oggetto" value={metadata.data?.subject} />
      <Metadata
        label="Ufficio Proponente"
        value={metadata.data?.proposerOffice}
      />
    </div>
  );
}

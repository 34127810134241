import { useGetDocumentaryDossiersTypes } from 'client/api/backend/documentary-dossiers/documentary-dossiers';

export function useGetDossierTypeLabel(widgetId: number, typeId?: number) {
  const typesResult = useGetDocumentaryDossiersTypes(
    {
      widgetId
    },
    {
      query: {
        enabled: typeId != null
      }
    }
  );

  return (
    typesResult.data?.find(type => type.value === typeId?.toString())?.label ||
    ''
  );
}

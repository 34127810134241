import { ChartDataset } from 'chart.js';
import {
  ConstantDamParamsType,
  ApiParamsType,
  IGraphViewParameter
} from 'client/components/widget-repository/objects/dams/DamParams';
import { getParamYAxisId } from './getScalesForDatasets';

export function convertObservationsToDataset(
  param: IGraphViewParameter,
  selectedParams: IGraphViewParameter[],
  data: string[]
): ChartDataset<'line', string[]> {
  const yAxisID = getParamYAxisId(param, selectedParams);
  switch (param.codeApi) {
    case ApiParamsType.LivelloInvaso:
      return {
        data,
        label: param.label,
        fill: false,
        backgroundColor: 'rgb(53, 162, 235)',
        borderColor: 'rgb(53, 162, 235)',
        yAxisID
      };
    case ApiParamsType.VolumeInvaso:
      return {
        data,
        label: param.label,
        fill: false,
        backgroundColor: 'rgb(255, 206, 86)',
        borderColor: 'rgb(255, 206, 86)',
        yAxisID
      };
    case ApiParamsType.PortataScaricata:
      return {
        data,
        label: param.label,
        fill: false,
        backgroundColor: 'rgb(46, 204, 113)',
        borderColor: 'rgb(46, 204, 113)',
        yAxisID
      };
    case ApiParamsType.PortataAdduzione:
      return {
        data,
        label: param.label,
        fill: false,
        backgroundColor: 'rgb(39, 174, 96)',
        borderColor: 'rgb(39, 174, 96)',
        yAxisID
      };
    case ApiParamsType.PortataDerivata:
      return {
        data,
        label: param.label,
        fill: false,
        backgroundColor: 'rgb(22, 160, 133)',
        borderColor: 'rgb(22, 160, 133)',
        yAxisID
      };

    case ConstantDamParamsType.QuotaMax:
      return {
        data,
        label: param.label,
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgb(255, 99, 132)',
        yAxisID
      };
    case ConstantDamParamsType.QuotaMaxRegulation:
      return {
        data,
        label: param.label,
        fill: false,
        backgroundColor: 'rgb(255, 159, 64)',
        borderColor: 'rgb(255, 159, 64)',
        yAxisID
      };
    default:
      return {
        data,
        label: param.label,
        fill: false,
        backgroundColor: 'rgb(53, 162, 235)',
        borderColor: 'rgb(53, 162, 235)',
        yAxisID
      };
  }
}

import {
  UnorderedListOutlined,
  EnvironmentOutlined,
  AreaChartOutlined
} from '@ant-design/icons';
import { useGetDocumentaryDamId } from 'client/api/backend/documentary-dam/documentary-dam';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { WidgetCardBodyTabs } from 'client/components/schema/widget/card/body/WidgetCardBodyTabs';
import { useWidgetNetworkQueries } from 'client/components/schema/widget/context/useWidgetNetworkEffect';
import { useWidgetContext } from 'client/components/schema/widget/context/WidgetContext';
import { Metadata } from 'client/components/toolkit/components/metadata/Metadata';
import { MetadataLabel } from 'client/components/toolkit/components/metadata/MetadataLabel';
import { WidgetTable } from 'client/components/toolkit/components/table/WidgetTable';
import { WidgetTabPanel } from 'client/components/toolkit/components/tabs/WidgetTabPanel';
import { useRegisterToolkitObject } from 'client/components/toolkit/objects/store/useToolkitObjectStore';
import { ToolkitObjectBottomZone } from 'client/components/toolkit/objects/ToolkitObjectBottomZone';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { FvIconFileView } from 'client/ui/icon/FvIcons';
import { PaddedSkeleton } from 'client/ui/skeleton/PaddedSkeleton';
import React from 'react';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { convertDamGeoregistryToTable } from './convert/convertDamGeoregistryToTable';
import { WidgetDamGeoregistryViewerProfile } from './WidgetDamGeoregistryViewerProfile';

export interface IWidgetDamGeoregistryViewerProps
  extends IWidgetComponentToolkitProps {}

const georegistryColumns = [
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: (record: any) => {
      return <MetadataLabel label={record} />;
    }
  },
  {
    title: 'Metadato',
    dataIndex: 'value',
    key: 'value'
  }
];

export function WidgetDamGeoregistryViewer(
  props: IWidgetDamGeoregistryViewerProps
) {
  const { widget } = props;
  const widgetContext = useWidgetContext();

  const data = widget.profile?.data as
    | WidgetDamGeoregistryViewerProfile
    | undefined;

  const damResult = useGetDocumentaryDamId(
    data?.damId!,
    { widgetId: widget.id! },
    {
      query: {
        enabled: data?.damId != null
      }
    }
  );

  useWidgetNetworkQueries([damResult]);

  // Creiamo l'oggetto per il toolkit
  const widgetObject = WidgetToolkitObject.from(widget);
  useRegisterToolkitObject(widgetObject);

  const tableData = convertDamGeoregistryToTable(damResult.data);

  return (
    <WidgetCardBody
      scrollable={false}
      bottom={<ToolkitObjectBottomZone object={widgetObject} />}
    >
      <WidgetCardBodyTabs>
        <WidgetTabPanel
          title="Anagrafica"
          tabKey="registry"
          icon={<UnorderedListOutlined />}
          type="main"
        >
          <WidgetTable
            dataSource={tableData?.registry}
            columns={georegistryColumns}
            showHeader={false}
            pagination={false}
            loading={damResult.isLoading}
          />
        </WidgetTabPanel>
        <WidgetTabPanel
          title="Localizzazione"
          tabKey="location"
          icon={<EnvironmentOutlined />}
        >
          <WidgetTable
            dataSource={tableData?.location}
            columns={georegistryColumns}
            showHeader={false}
            pagination={false}
            loading={damResult.isLoading}
          />
        </WidgetTabPanel>
        <WidgetTabPanel
          title="Idrografia"
          tabKey="hydrography"
          icon={<AreaChartOutlined />}
        >
          <WidgetTable
            dataSource={tableData?.hydrography}
            columns={georegistryColumns}
            showHeader={false}
            pagination={false}
            loading={damResult.isLoading}
          />
        </WidgetTabPanel>
        {/* <WidgetTabPanel
          title="Documenti Associati"
          tabKey="documents"
          icon={<FvIconFileView />}
          padded
        >
          <DocumentMetadatas
            widgetId={widget.id!}
            document={documentResult.data}
          />
        </WidgetTabPanel> */}
      </WidgetCardBodyTabs>
    </WidgetCardBody>
  );
}

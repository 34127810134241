import { Widget } from 'client/api/backend/schemas';
import React, { useContext } from 'react';
import { useWidgetContextHandler } from './useWidgetContextHandler';

export type IWidgetContext = ReturnType<typeof useWidgetContextHandler>;

export const WidgetContext = React.createContext<IWidgetContext>({} as any);

interface IWidgetContextProviderProps {
  widget: Widget;
  children: React.ReactNode;
}

/**
 * Esponse le informazioni del Widget a tutto il tree sottostante.
 */
export function WidgetContextProvider(props: IWidgetContextProviderProps) {
  const contextState = useWidgetContextHandler(props.widget);
  return (
    <WidgetContext.Provider value={contextState}>
      {props.children}
    </WidgetContext.Provider>
  );
}

export function useWidgetContext(): IWidgetContext {
  return useContext(WidgetContext);
}

import { AssignmentModelToolkitObject } from 'client/components/widget-repository/objects/assignment-models/AssignmentModelToolkitObject';
import { getCorrespondentSchema } from 'client/components/widget-repository/objects/correspondents/schema/CorrespondentSchema';
import { yup } from 'common/validation/initYup';
import { MixedSchema } from 'yup/lib/mixed';

const MailCorrespondentPersonSchema = yup.object({
  name: yup.string().required().label('Nome'),
  surname: yup.string().required().label('Cognome'),
  fiscalCode: yup.string().label('Codice Fiscale').nullable(),
  email: yup.string().email().required().label('Email')
});

const MailCorrespondentCorporateSchema = yup.object({
  name: yup.string().required().label('Ragione sociale'),
  address: yup.object({ email: yup.string().email().required().label('Email') })
});

const MailCorrespondentAdministrationSchema = yup.object({
  ipacode: yup.string().required().label('Codice IPA'),
  name: yup.string().required().label('Nome Amministrazione'),
  aoocode: yup.string().required().label('Codice AOO'),
  aoodescription: yup.string().required().label('Descrizione AOO'),
  address: yup.object({
    email: yup.string().email().required().label('Email')
  })
});

export const MailCorrespondentSchema = getCorrespondentSchema(
  MailCorrespondentPersonSchema,
  MailCorrespondentCorporateSchema,
  MailCorrespondentAdministrationSchema
);

export const MailProtocolAssignmentSchema = yup.object({
  subject: yup.string().required().label('Oggetto'),
  sender: MailCorrespondentSchema.label('Mittente'),
  elementPath: yup.string().required().label('Documento Principale'),
  assignment: yup
    .object({
      // Gli assegnatari possono essere un insieme di modelli o un insieme di utenti e uffici
      assignees: yup.array(yup.object()).test({
        message:
          'Il campo deve essere composto da modelli di assegnazione o da un insieme di utenti e uffici',
        test(value) {
          if (Array.isArray(value)) {
            return (
              !isModelPresent(value) ||
              value.every(
                (item: any) =>
                  item != null &&
                  item.type === AssignmentModelToolkitObject.code
              )
            );
          }
          return true;
        }
      }),
      // Se è un modello di assegnazione allora rimuove la possibilità di scegliere
      // il tipo di assegnazione
      // altrimenti se è presente un assegnatario il tipo di assegnazione è obbligatorio
      typeId: yup
        .mixed()
        .when(['assignees'], (assignees, schema: MixedSchema) => {
          return isModelPresent(assignees)
            ? schema.strip()
            : hasAssignees(assignees)
            ? schema.required()
            : schema;
        })
        .label("Modalità per l'assegnatario")
    })
    .label('Assegnazione')
});

function isModelPresent(assignees: any[]) {
  const model = assignees?.find(
    (item: any) =>
      item != null && item.type === AssignmentModelToolkitObject.code
  );

  return model != null;
}

/**
 * Se è presente un assegnatario e non è un modello allora la modalità di assegnazione è obbligatoria
 */
function hasAssignees(assignees: any[]) {
  return assignees && assignees.length > 0;
}

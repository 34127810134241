import { message, Skeleton } from 'antd';
import {
  usePostDocumentaryDocumentsQueueMultiple,
  usePostDocumentaryDocumentsQueueSingle
} from 'client/api/backend/documentary-documents/documentary-documents';
import {
  DeskDocument,
  DocumentQueueRequestDTO
} from 'client/api/backend/schemas';
import { useDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { queryClient } from 'client/core/network/queryClient';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { omit } from 'lodash';
import * as React from 'react';
import { DocumentToolkitObject } from '../../objects/documents/DocumentToolkitObject';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { DocumentSendToProtocolQueueSchema } from './form/DocumentSendToProtocolQueueSchema';
import { WidgetDocumentSendToProtocolQueueForm } from './form/WidgetDocumentSendToProtocolQueueForm';
import { WidgetDocumentSendToProtocolQueueProfile } from './WidgetDocumentSendToProtocolQueueProfile';

interface IDocumentSendToProtocolQueueFormValues
  extends DocumentQueueRequestDTO {
  document: ToolkitObject<DeskDocument> | null;
  attachments?: ToolkitObject<DeskDocument>[] | null;
}
export interface IWidgetDocumentSendToProtocolQueueProps
  extends IWidgetComponentToolkitProps {}

export function WidgetDocumentSendToProtocolQueue(
  props: IWidgetDocumentSendToProtocolQueueProps
) {
  const { widget } = props;
  const ctx = useDeskContext();

  const sendToQueue = usePostDocumentaryDocumentsQueueSingle();

  const profile = widget.profile
    ?.data as WidgetDocumentSendToProtocolQueueProfile | null;

  return (
    <FormikAugmented<IDocumentSendToProtocolQueueFormValues>
      validationSchema={DocumentSendToProtocolQueueSchema}
      validationContext={{}}
      initialValues={{
        subject: profile?.document?.subject,
        document: profile?.document
          ? DocumentToolkitObject.create({
              id: profile.document.id!,
              name: profile.document.subject!,
              data: profile.document,
              sourceWidgetId: widget.id!
            })
          : null
      }}
      onSubmit={async (values, helpers) => {
        try {
          const documentiId = values.document?.data.id;
          const attachmentsIds = values.attachments
            ? values.attachments?.map(a => a.data.id!)
            : [];

          await sendToQueue.mutateAsync({
            data: {
              ...omit(values, ['document', 'attachments']),
              documentiId,
              attachmentsIds,
              widgetId: widget.id,
              widgetIdFrom: widget.id
            }
          });

          // Invalidiamo la query collegata al widget sorgente
          await queryClient.invalidateQueries({
            predicate: query => {
              return Boolean(
                query.queryKey &&
                  Array.isArray(query.queryKey) &&
                  typeof query.queryKey[1] === 'object' &&
                  query.queryKey[1].widgetId === profile?.sourceWidgetId
              );
            }
          });

          message.success(
            'Il protocollo è stato inviato con successo alla coda di protocollazione'
          );

          // Rimuoviamo il widget se era collegato a un documento specifico
          if (profile?.document) {
            await ctx.remove(widget);
          }

          helpers.resetForm();
        } catch (error) {
          message.error(
            getNetworkErrorMessage(
              error,
              "Si è verificato un errore durante l'invio del documento alla coda di protocollazione"
            )
          );
          console.error(error);
        }
      }}
    >
      <WidgetCardBody>
        <WidgetDocumentSendToProtocolQueueForm widget={widget} />
      </WidgetCardBody>
    </FormikAugmented>
  );
}

import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import clx from 'classnames';
import { Button, Space, Typography } from 'antd';
import { ActionMessageAnimation } from './message/ActionMessageAnimation';
const { Text, Title } = Typography;

const ActionMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const MessageTitle = styled.div`
  padding: 0 16px;
  max-width: 100%;
  /* background-color: ${props => props.theme.primaryColor};
  color: ${props => props.theme.componentBackground}; */
  min-height: 50px;
  border-bottom: 1px solid ${props => props.theme.borderColorBase};
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
const ActionMessageBody = styled.div`
  padding: 16px;
`;

const FixedBottom = styled.div`
  flex: 0 0 auto;
  padding: 4px ${props => props.theme.cardPaddingBase};
  border-top: solid 1px ${props => props.theme.borderColorBase};
  /* background-color: #e8eff2; */
  // prettier-ignore
  border-radius: 0 0 ${props => props.theme.cardRadius} ${props =>
    props.theme.cardRadius};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px ${props => props.theme.cardPaddingBase};
  border-top: 1px solid ${props => props.theme.borderColorBase};
`;

export interface ActionMessageProps {
  title: React.ReactNode;
  actions?: React.ReactNode;
  children: React.ReactNode;
}

/**
 * Componente Modale Stilizzato
 * per la visualizzazione di messaggi e l'interazione delle Actions
 */
export function ActionMessage(props: ActionMessageProps) {
  const { children, title, actions, ...rest } = props;
  return (
    <ActionMessageAnimation {...rest}>
      <ActionMessageWrapper>
        <MessageTitle>{title}</MessageTitle>
        <ActionMessageBody>{children}</ActionMessageBody>
        {actions && <ButtonWrapper>{actions}</ButtonWrapper>}
      </ActionMessageWrapper>
    </ActionMessageAnimation>
  );
}

import React from 'react';
import styled, { css } from 'styled-components';

export const MonitorWrapper = styled.div`
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

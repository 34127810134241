import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Dropdown, Menu, message, Space } from 'antd';
import { DeskActions, useDesk } from 'client/components/schema/desk/DeskModule';
import { useDispatch } from 'react-redux';
import { ButtonGhost } from 'client/ui/button/ButtonGhost';
import { SettingOutlined, SyncOutlined } from '@ant-design/icons';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';
import { useDeskContext } from 'client/components/schema/desk/context/DeskContext';

export interface DeskEditModeButtonProps {
  buttons?: React.ReactNode;
}

export function DeskEditModeButton(props: DeskEditModeButtonProps) {
  const deskState = useDesk();
  const dispatch = useDispatch();
  const deskContext = useDeskContext();
  const isMobile = useBreakpoint(down('sm'));

  if (isMobile) {
    return null;
  }

  return deskState.isEditable ? (
    <Space>
      {/* <Dropdown
        overlay={
          <Menu>
            <Menu.Item onClick={() => deskContext.removeAll()}>
              Rimuovi Tutti
            </Menu.Item>
          </Menu>
        }
      >
        <ButtonGhost icon={<BorderlessTableOutlined />} />
      </Dropdown> */}
      <Button
        shape="round"
        icon={<SettingOutlined />}
        children="Finito"
        onClick={async () => {
          deskContext.saveWidgetsLayout();
          dispatch(DeskActions.editable());
        }}
      />
    </Space>
  ) : (
    <ButtonGhost
      icon={<SettingOutlined />}
      onClick={() => dispatch(DeskActions.editable())}
    />
  );
}

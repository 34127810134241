import { useGetDocumentaryDamObservationsParameters } from 'client/api/backend/documentary-dam/documentary-dam';
import { useGetDocumentaryDossiersTypes } from 'client/api/backend/documentary-dossiers/documentary-dossiers';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import * as React from 'react';
import { GraphViewSelectOptions } from './DamParams';

interface IDamObservationsGraphViewParameterInputProps
  extends SelectInputProps<any> {}

export function DamObservationsGraphViewParameterInput(
  props: IDamObservationsGraphViewParameterInputProps
) {
  const { ...otherProps } = props;

  return (
    <SelectInput
      allowClear
      showSearch
      options={GraphViewSelectOptions}
      {...otherProps}
    />
  );
}

import { FvIconFileHightLight } from 'client/ui/icon/FvIcons';
import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetActionAnnotateHighlightedDocument } from './actions/WidgetActionAnnotateHighlightedDocument';
import { WidgetActionDeleteHighlightedDocument } from './actions/WidgetActionDeleteHighlighedDocument';
import { WidgetActionHighlightDocument } from './actions/WidgetActionHighlightDocument';
import { WidgetHighlightedDocuments } from './WidgetHighlightedDocuments';

export const WidgetHighlightedDocumentsType: IWidgetType = {
  code: 'highlighted-documents',
  name: 'Protocolli in Evidenza',
  icon: <FvIconFileHightLight />,
  component: WidgetHighlightedDocuments,
  description: `Permette di mostrare i protocolli in evidenza`,
  actions: [WidgetActionHighlightDocument],
  internalActions: [
    WidgetActionDeleteHighlightedDocument,
    WidgetActionAnnotateHighlightedDocument
  ]
};

WidgetRepository.register(WidgetHighlightedDocumentsType);

import * as React from 'react';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextInput } from 'client/ui/form/input/TextInput';
import { DynamicDateRanges } from 'client/components/toolkit/components/filter/dynamic/DynamicDateRanges';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { DamObservationsGraphViewParameterInput } from 'client/components/widget-repository/objects/dams/DamObservationsGraphViewParameterInput';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';

export interface IWidgetDamMonitoringSearchFilterFormProps {
  widgetId: number;
  /** Di base è `profile.filters`. */
  prefix: string;
  filters: any;
}

export function WidgetDamMonitoringSearchFilterForm(
  props: IWidgetDamMonitoringSearchFilterFormProps
) {
  const { filters, prefix } = props;

  return (
    <>
      <FormFieldsContainer columns={2} gutter={8}>
        <FormFieldItem
          size="large"
          component={DatePickerInput}
          type="unix"
          label="Dal"
          name={`${prefix}.startDate`}
          placeholder="Dal"
          allowClear={false}
        />
        <FormFieldItem
          size="large"
          component={DatePickerInput}
          type="unix"
          label="Al"
          name={`${prefix}.endDate`}
          placeholder="Al"
          allowClear={false}
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <FormFieldItem
          size="middle"
          component={DamObservationsGraphViewParameterInput}
          label="Opzioni di visualizzazione grafica"
          placeholder="Seleziona variabili per il grafico"
          name={`${prefix}.selectedGraphView`}
          allowClear={false}
        />
      </FormFieldsContainer>
    </>
  );
}

import * as React from 'react';
import { Typography } from 'antd';
const { Title, Text, Paragraph } = Typography;

interface IMetadataLabelProps {
  label?: React.ReactNode;
}

export function MetadataLabel(props: IMetadataLabelProps) {
  return <Text type="secondary">{props.label}: </Text>;
}

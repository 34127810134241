import { Tag } from 'antd';
import { DeskDocument } from 'client/api/backend/schemas';
import { ElementTextExtend } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import { DocumentMode } from 'client/components/widget-repository/objects/documents/DocumentMode';
import * as React from 'react';

export function convertDocumentModeToElementTextExtend(
  mode?: string
): ElementTextExtend | undefined {
  switch (mode) {
    case DocumentMode.Incoming:
      return { color: '#096dd9', after: 'Entrata', bold: true };
    case DocumentMode.Outgoing:
      return { color: '#d46b08', after: 'Uscita', bold: true };
    case DocumentMode.Internal:
      return { color: '#54646d', after: 'Interno', bold: true };
    default:
      return undefined;
  }
}

import { DeskDam } from 'client/api/backend/schemas';
import {
  ConstantDamParamsType,
  ConstantGraphViewParameters
} from 'client/components/widget-repository/objects/dams/DamParams';

/**
 * Converte i parametri costanti della diga in osservazioni da mostrare nel grafico.
 */
export function convertConstantDamParametersToObservations(
  dataLength: number,
  dam?: DeskDam
) {
  const quotaMaxData = dam?.quotaMax
    ? Array(dataLength).fill(dam.quotaMax)
    : [];
  const quotaMaxRegulationData = dam?.quotaMaxRegulation
    ? Array(dataLength).fill(dam.quotaMaxRegulation)
    : [];

  return [
    {
      param: ConstantGraphViewParameters[ConstantDamParamsType.QuotaMax],
      data: quotaMaxData
    },
    {
      param:
        ConstantGraphViewParameters[ConstantDamParamsType.QuotaMaxRegulation],
      data: quotaMaxRegulationData
    }
  ];
}

import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { GetDocumentaryMailsParams } from 'client/api/backend/schemas/getDocumentaryMailsParams';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { WidgetList } from 'client/components/toolkit/components/list/WidgetList';
import { convertMailToListElement } from './convert/convertMailToListElements';
import { PaddedSkeleton } from 'client/ui/skeleton/PaddedSkeleton';
import {
  InfiniteMailsPageStart,
  useInfiniteMails
} from './api/useInfiniteMails';
import { WidgetMailsSearchForm } from './filters/WidgetMailsSearchForm';
import { useWidgetNetworkQueries } from 'client/components/schema/widget/context/useWidgetNetworkEffect';
import { applyWidgetMailSearchDefaultFilters } from './filters/WidgetMailsSearchFilter';

interface IWidgetMailsProps extends IWidgetComponentToolkitProps {}

const MailsPageSize = 20;

export function WidgetMails(props: IWidgetMailsProps) {
  const { widget } = props;

  // Ricerca locale, per la durata della sessione.
  const [search, setSearch] = useState<Partial<GetDocumentaryMailsParams>>({});

  const apiSearch = useMemo(
    () => ({
      ...applyWidgetMailSearchDefaultFilters(widget),
      ...search,
      pageNumber: InfiniteMailsPageStart,
      pageSize: MailsPageSize,
      widgetId: widget.id!
    }),
    [search, widget.profile?.filters]
  );

  const mailsResult = useInfiniteMails(apiSearch, {
    query: {
      getNextPageParam: (lastPage, pages) =>
        lastPage.length < MailsPageSize
          ? undefined
          : pages.length + InfiniteMailsPageStart
    }
  });

  useWidgetNetworkQueries([mailsResult]);

  const mails = useMemo<IWidgetListElement[]>(() => {
    if (!mailsResult.data) return [];

    return mailsResult.data.pages
      .flat()
      .map(mail => convertMailToListElement(mail, widget));
  }, [mailsResult.data?.pages]);

  return (
    <WidgetCardBody
      top={
        <WidgetMailsSearchForm
          widget={widget}
          search={search}
          setSearch={setSearch}
        />
      }
    >
      <PaddedSkeleton loading={mailsResult.isLoading} active>
        <WidgetList
          list={mails}
          infinite={{
            query: mailsResult
          }}
        />
      </PaddedSkeleton>
    </WidgetCardBody>
  );
}

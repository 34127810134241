import { useGetPanels } from 'client/api/backend/panels/panels';
import { Panel } from 'client/api/backend/schemas';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { logger } from 'client/core/logger/logger';
import { omit, sortBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { DeskActions, useDesk } from '../DeskModule';
import { DeskMarshaller } from './useDeskMutations';

/**
 * Per il momento ordiniamo le scrivanie per ID. Dobbiamo studiare un metodo
 * per riordinarle?
 */
function sortDesks(desks: Panel[]) {
  return sortBy(desks, 'id');
}

/**
 * Ottiene l'elenco dei Panel (scrivanie, Desk) ed effettua il parsing ovvero
 * l'unmarshaling dal JSON salvato come stringa.
 */
export function useDesksQuery() {
  const dispatch = useDispatch();
  const deskState = useDesk();
  const rawPanels = useGetPanels({
    query: {
      onSuccess: desks => {
        // Se esiste una scrivania, è nessuna è selezionata, la apriamo.
        if (desks.length > 0) {
          logger.log(`[useDesksQuery] Found ${desks.length} desks, selecting first one.`); // prettier-ignore
          const sortedDesks = sortDesks(desks);

          // Gestiamo anche il caso in cui la scrivania selezionata è appena
          // stata eliminata
          if (
            deskState.selectedId == null ||
            !desks.some(d => d.id == deskState.selectedId)
          ) {
            dispatch(DeskActions.select(sortedDesks[0].id!));
          }
        }
      },
      staleTime: Infinity
    }
  });

  const currentUser = useCurrentUser();

  useEffect(() => {
    rawPanels.refetch();
  }, [currentUser?.id]);

  const panels = useMemo(() => {
    const panels = rawPanels.data?.map(DeskMarshaller.unmarshall) ?? [];
    return sortDesks(panels);
  }, [rawPanels.data]);

  return {
    panels,
    // Blocchiamo l'accesso ai dati grezzi
    ...omit(rawPanels, ['data'])
  };
}

import { omit } from 'lodash';
import React from 'react';
import { ToolkitObjectType } from './registerToolkitObjectType';

export type ToolkitId = string | number;

export interface DraggedToolkitObject<T = any> {
  object: ToolkitObject<T>;
  massiveSelected?: ToolkitObject<T>[];
}

export interface ToolkitObject<T = any> {
  id: ToolkitId;
  type: string;
  data: T;
  name: string;
  icon?: string | React.ReactNode;
  description?: string;
  sourceWidgetId: number;

  /**
   * Identifica l'oggetto come un oggetto contenuto in un altro oggetto primario.
   * Ad esempio un documento contenuto in un'assegnazione.
   * Se non è definito, l'oggetto è considerato un oggetto primario.
   * Se è definito, l'oggetto è considerato un oggetto secondario.
   */
  isRelated?: boolean;

  /**
   * Un oggetto trascinato può contenere informazioni aggiuntive anche in
   * merito ad altri elementi.
   */
  related?: ToolkitObject[];
}

/**
 * ID Unico per il drag&drop.
 * È possibile passare un sufisso in caso ci siano più oggetti con lo stesso ID
 * nello stesso widget, ad esempio per i widget di tipo "tree".
 */
export function toolkitObjectUniqId(
  object?: ToolkitObject<any> | null,
  suffix?: string
) {
  if (!object) return null;
  return `${object.type}-${object.id}-w${object.sourceWidgetId}${suffix ?? ''}`;
}

/**
 * Ottiene l'oggetto di un tipo specifico all'interno della struttura di un
 * oggetto trascinato.
 */
export function getToolkitObjectOfType(
  object: ToolkitObject<any> | null,
  type: string | string[] | ToolkitObjectType | ToolkitObjectType[]
): ToolkitObject<any> | null {
  if (!object) return null;

  if (Array.isArray(type)) {
    // console.log('getToolkitObjectOfType: type is an array', object, object.related, type); // prettier-ignore
    for (const subtype of type) {
      const subobject = getToolkitObjectOfType(object, subtype);
      if (subobject) return subobject;
    }
    return null;
  }

  // Supporto per i ToolkitObjectType
  const targetType = typeof type === 'string' ? type : type.code;

  if (object.type === targetType) return object;
  return object.related?.find(o => o.type === targetType) ?? null;
}

/**
 * Se abbiamo bisogno di serializzare un oggetto ToolkitObject, possiamo
 * utilizzare questa funzione per rimuovere le proprietà che non sono
 * serializzabili (ad esempio i ReactNode).
 * La serializzazione viene utilizzata per passare gli oggetti trascinati
 * tra un widget e l'altro (all'interno della Profile)
 */
export function serializableToolkitObject(obj: ToolkitObject | null) {
  if (!obj) return null;
  return omit(obj, ['icon']);
}

import React from 'react';
import styled from 'styled-components';
import { up, down, between, only, createTheme } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { WidgetCard } from 'client/components/schema/widget/card/WidgetCard';
import { LoginMobileLogo } from './LoginMobileLogo';

const PageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  min-height: 10px;
  padding: 12px;
`;

const BaseFlex = styled.div`
  display: flex;
  min-height: 10px;
  min-width: 0;
`;

const TopRow = styled(BaseFlex)`
  width: 100%;
  flex: 1 1 auto;
`;
const CenterRow = styled(BaseFlex)`
  width: 100%;
  flex: 0 0 auto;
`;
const BottomRow = styled(BaseFlex)`
  width: 100%;
  flex: 2 1 auto;
`;
const SmallCol = styled(BaseFlex)`
  flex: 1 1 17%;
`;
const MediumCol = styled(BaseFlex)`
  flex: 0 1 33%;
  ${down('sm')} {
    flex-basis: 100%;
  }
`;
const LargeCol = styled(BaseFlex)`
  flex: 1 1 66%;
`;

interface LoginPageGridProps {
  children: React.ReactNode;
  message?: React.ReactNode;
}

/**
 * Griglia grafica di sfondo per la pagina di Login
 */
export const LoginPageGrid = (props: LoginPageGridProps) => {
  const isMobile = useBreakpoint(down('sm'));
  return (
    <PageWrapper>
      <TopRow>
        {!isMobile && (
          <MediumCol>
            <WidgetCard ghost />
          </MediumCol>
        )}
        <LargeCol>
          {isMobile ? <LoginMobileLogo /> : <WidgetCard ghost />}
        </LargeCol>
      </TopRow>
      <CenterRow>
        {!isMobile && (
          <SmallCol>
            <WidgetCard ghost />
          </SmallCol>
        )}
        {!isMobile && (
          <MediumCol>{props.message ?? <WidgetCard ghost />}</MediumCol>
        )}
        <MediumCol>{props.children}</MediumCol>
        {!isMobile && (
          <SmallCol>
            <WidgetCard ghost />
          </SmallCol>
        )}
      </CenterRow>
      <BottomRow>
        <LargeCol>
          <WidgetCard ghost />
        </LargeCol>
        {!isMobile && (
          <MediumCol>
            <WidgetCard ghost />
          </MediumCol>
        )}
      </BottomRow>
    </PageWrapper>
  );
};

import React from 'react';
import clx from 'classnames';
import styled from 'styled-components';

export interface IconWrapperProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  icon?: React.ReactNode;
}
const IconWrapperStyle = styled.span`
  svg {
    fill: currentColor;
    width: 1em;
    height: 1em;
  }
`;
/**
 * Wrapper per le icone custom o react-icons per associare gli stili base di Ant
 */
export function IconWrapper(props: IconWrapperProps) {
  const { icon, children, className, ...otherProps } = props;

  return (
    <IconWrapperStyle
      role="img"
      className={clx('anticon', className)}
      {...otherProps}
    >
      {icon ?? children}
    </IconWrapperStyle>
  );
}

import { AuditOutlined } from '@ant-design/icons';
import { DeskMail, DeskUser } from 'client/api/backend/schemas';
import { DeskWidgetsLogic } from 'client/components/schema/desk/logic/DeskWidgetsLogic';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import {
  getToolkitObjectOfType,
  serializableToolkitObject,
  ToolkitObject
} from 'client/components/toolkit/objects/ToolkitObject';
import {
  AssignmentModelToolkitObject,
  IAssignmentModel
} from 'client/components/widget-repository/objects/assignment-models/AssignmentModelToolkitObject';
import { MailToolkitObject } from 'client/components/widget-repository/objects/mails/MailToolkitObject';
import { OfficeToolkitObject } from 'client/components/widget-repository/objects/offices/OfficeToolkitObject';
import { UserToolkitObject } from 'client/components/widget-repository/objects/users/UserToolkitObject';
import { yup } from 'common/validation/initYup';
import React from 'react';
import { InferType } from 'yup';
import { WidgetMailProtocolAssignmentType } from '../WidgetMailProtocolAssignment.type';
import { WidgetMailProtocolAssignmentProfile } from '../WidgetMailProtocolAssignmentProfile';

export const WidgetActionProtocolAndAssignMail = createToolkitAction({
  name: 'Protocolla e assegna Email',
  code: 'protocolo-assign-mail',
  icon: <AuditOutlined />,
  showInBar: false,
  arguments: [
    {
      name: 'Mail da protocollare e assegnare',
      type: [MailToolkitObject]
    },
    {
      name: 'Assegnatario',
      type: [
        UserToolkitObject,
        OfficeToolkitObject,
        AssignmentModelToolkitObject
      ]
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    mailObject: ToolkitObject,
    assigneeObject: ToolkitObject | null
  ) {
    let mail = MailToolkitObject.get(mailObject)!;
    let assignee = getToolkitObjectOfType(assigneeObject, [
      UserToolkitObject.code,
      OfficeToolkitObject.code,
      AssignmentModelToolkitObject.code
    ]);

    await onConfirm(ctx, {}, mail, assignee);
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: InferType<typeof ProtocolAndAssignSchema>,
  mail: ToolkitObject<DeskMail>,
  assignee: ToolkitObject | null
) {
  if (!mail) {
    await ctx.deskContext.createWidget(
      {
        type: 'floating',
        expanded: true,
        order: DeskWidgetsLogic.getFloatingNextOrder(
          ctx.deskContext.floatingWidgets
        )
      },
      WidgetMailProtocolAssignmentType,
      { quickClose: true }
    );
    return;
  }

  const data: WidgetMailProtocolAssignmentProfile = {
    mail: mail.data,
    assignee: serializableToolkitObject(assignee)
  };

  await ctx.deskContext.cloneWidget(
    { type: 'floating', expanded: true },
    WidgetMailProtocolAssignmentType,
    ctx.sourceWidget!,
    { data, quickClose: false }
  );
}

const ProtocolAndAssignSchema = yup.object({});

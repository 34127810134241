import { Widget } from 'client/api/backend/schemas';
import { useCallback, useRef, useState } from 'react';
import { Subject } from 'rxjs';
import { WidgetNetworkEvent } from './useWidgetNetworkEffect';

/**
 * Gestisce lo stato generale del Widget. Ad esempio, tutte le query vengono
 * gestite qui tramite il `networkSubject` che permette di notificare quando
 * si preme su "Ricarica" per aggiornare le singole query.
 */
export function useWidgetContextHandler(widget: Widget) {
  const networkSubject = useRef(new Subject<WidgetNetworkEvent>());

  const [isFetching, setIsFetching] = useState(false);
  const [queryCount, setQueryCount] = useState(0);
  const [queryErrors, setQueryErrors] = useState<Error[] | null>(null);

  const refetch = useCallback(() => {
    networkSubject.current.next({ type: 'refetch' });
  }, []);

  return {
    widget,
    refetch,
    networkSubject,
    isFetching,
    setIsFetching,
    queryCount,
    setQueryCount,
    queryErrors,
    setQueryErrors
  };
}

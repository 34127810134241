import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import {
  getToolkitObjectOfType,
  ToolkitObject
} from 'client/components/toolkit/objects/ToolkitObject';
import { yup } from 'common/validation/initYup';
import { InferType } from 'yup';
import { message } from 'antd';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextInput } from 'client/ui/form/input/TextInput';
import { GroupOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { UserToolkitObject } from 'client/components/widget-repository/objects/users/UserToolkitObject';
import { OfficeToolkitObject } from 'client/components/widget-repository/objects/offices/OfficeToolkitObject';
import { DeskOffice, DeskUser } from 'client/api/backend/schemas';
import { WidgetAssignmentModelsProfile } from '../../WidgetAssignmentModelsProfile';
import { IAssignmentModel } from 'client/components/widget-repository/objects/assignment-models/AssignmentModelToolkitObject';
import { v4 } from 'uuid';
import { toolkitObjectToAssignmentModelItem } from 'client/components/widget-repository/objects/assignment-models/toolkitObjectToAssignmentModelItem';
import { AssignmentTypeSelectInput } from 'client/components/widget-repository/objects/assignments/AssignmentTypeSelectInput';
import { set } from 'client/core/data/set';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { WidgetAssignmentModelsType } from '../../WidgetAssignmentModels.type';

export const WidgetActionCreateAssignmentModel = createToolkitAction({
  name: 'Crea Modello di assegnazione',
  code: 'create-assignment-model',
  icon: <UsergroupAddOutlined />,
  arguments: [
    {
      name: 'Assegnatario',
      type: [UserToolkitObject, OfficeToolkitObject]
    },
    {
      name: 'Widget Modelli di Assegnazione',
      type: [WidgetToolkitObject],
      allows: (object: ToolkitObject) =>
        WidgetToolkitObject.matches(object, WidgetAssignmentModelsType)
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    assigneeObject: ToolkitObject,
    widgetObject: ToolkitObject | null
  ) {
    const assignee = getToolkitObjectOfType(assigneeObject, [
      UserToolkitObject.code,
      OfficeToolkitObject.code
    ])!;
    const widget = WidgetToolkitObject.get(widgetObject);

    // Apriamo sempre il prompt perché ci serve il nome
    ctx.deskContext.actionMessages.prompt({
      ctx,
      args: [assignee, widget],
      action: WidgetActionCreateAssignmentModel,
      confirmText: 'Crea Modello',
      schema: CreateAssignmentModelSchema,
      onConfirm,
      form: (
        <>
          <FormFieldsContainer>
            <FormFieldItem
              size="large"
              component={TextInput}
              label={null}
              name="groupName"
              placeholder="Nome del modello"
            />
            <FormFieldItem
              size="large"
              component={AssignmentTypeSelectInput}
              widgetId={ctx.sourceWidget!.id!}
              label={null}
              name="typeId"
              placeholder="Modalità per l'assegnatario"
            />
          </FormFieldsContainer>
        </>
      )
    });
  }
});

/**
 * Crea e Aggiunge il modello di assegnazione al widget selezionato.
 */
async function onConfirm(
  ctx: IToolkitActionContext,
  values: CreateAssignmentModelSchemaType,
  assignee: ToolkitObject<DeskUser | DeskOffice>,
  widgetObject: ToolkitObject
) {
  try {
    const widget = ctx.deskContext.widgets.find(w => w.id === widgetObject.id);
    const profile = widget?.profile;
    const data = profile?.data as WidgetAssignmentModelsProfile | null;

    const model: IAssignmentModel = {
      uuid: v4(),
      label: values.groupName,
      assignees: [
        {
          assignee: toolkitObjectToAssignmentModelItem(assignee),
          typeId: values.typeId
        }
      ]
    };

    await ctx.deskContext.updateWidget(
      set(widget!, 'profile.data.models', [...(data?.models ?? []), model])
    );

    message.success('Modello creato con successo');
  } catch (e) {
    message.error(getNetworkErrorMessage(e, 'Impossibile creare il modello'));
    console.error(e);
    return false;
  }
}

const CreateAssignmentModelSchema = yup.object({
  groupName: yup.string().required().label('Nome del modello'),
  typeId: yup.number().required().label("Modalità per l'assegnatario")
});

type CreateAssignmentModelSchemaType = InferType<
  typeof CreateAssignmentModelSchema
>;

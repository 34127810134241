import { lessThemeConverted } from './main/lessThemeConverted';
import { mainTheme } from './main/mainTheme';
import { up, down, between, only, createTheme } from 'styled-breakpoints';

const breakpointsTheme = createTheme({
  xs: '0px',
  sm: '576px',
  md: '768px',
  // Modificato "lg" per seguire il breakpoint di React Grid in Desk Space
  // (modificato anche su Ant less)
  lg: '824px', // 800 + 24 di padding //'992px',
  xl: '1200px',
  xxl: '1400px'
});

/**
 * Thema personalizzabile dell'applicativo
 *
 * Permette di sovrascivere le variabili utilizzate nell'applicativo
 * per la personalizzazione
 */
const customTheme = {
  logoWidth: '111px',
  // altezza massima logo 40px
  logoHeight: '36px',
  // TODO: Gestire inserimento url immagine e file in configurazione
  logoUrl: process.env.THEME_LOGO_HEADER
    ? require(process.env.THEME_LOGO_HEADER)
    : require('./image/folium-desk.png'),

  loginLogoBackground: process.env.THEME_LOGIN_BACKGROUND, // '#fff', // '#fd731e',
  loginLogoWidth: '250px',
  loginLogoHeight: '81px',
  loginLogoUrl: process.env.THEME_LOGO_LOGIN
    ? require(process.env.THEME_LOGO_LOGIN)
    : require('./image/folium-desk-white.png')
};

export const theme = {
  ...lessThemeConverted,
  ...mainTheme,
  ...breakpointsTheme,
  ...customTheme
};

import * as React from 'react';
import { PageHeader, Space, Tag } from 'antd';
import { AdminLayout } from '../../../ui/layout/AdminLayout';
import { AdminMenu } from '../menu/AdminMenu';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { RouteComponentProps } from 'react-router';
import { ColumnProps } from 'antd/lib/table';
import {
  UserProfileResponseDTO,
  UserResponseDTO
} from 'client/api/backend/schemas';
import { TableDataScroll } from 'client/ui/table/TableDataScroll';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { Link } from 'react-router-dom';
import { ButtonImportUsersDrawer } from './import/ButtonImportUsersDrawer';
import { LinkButton } from 'client/ui/link/LinkButton';
import { useGetUsers } from 'client/api/backend/users/users';
import { UserColumnDeleteButton } from './UserColumnDeleteButton';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { useState } from 'react';
import { useGetRoles } from 'client/api/backend/roles/roles';
import { RoleTag } from 'client/ui/role/RoleTag';
import { useUserColumns } from './UserColumns';
import { ButtonDeskCopyDrawer } from './desk-copy/ButtonDeskCopyDrawer';
import { TableRowSelection } from 'antd/lib/table/interface';

export interface UserListPageProps extends RouteComponentProps {}

/**
 * Pagina degli Utenti
 */
export function UserListPage(props: UserListPageProps) {
  useBreadcrumbItem({
    path: props.location.pathname,
    title: 'Utenti'
  });

  const [query, setQuery] = useState({
    page: 0,
    size: 20,
    name: undefined as string | undefined,
    surname: undefined as string | undefined,
    username: undefined as string | undefined,
    enabled: undefined as boolean | undefined
  });

  const { data, error, isLoading } = useGetUsers(query);

  const [userSelectionVisible, setUserSelectionVisible] = useState(false);
  const [selectedUsersForDeskCopy, setSelectedUsersForDeskCopy] = useState<
    UserResponseDTO[]
  >(() => []);

  const rowSelection: TableRowSelection<UserResponseDTO> = {
    selectedRowKeys: selectedUsersForDeskCopy.map(d => d.id!),
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedUsersForDeskCopy(selectedRows);
    }
  };

  const userColumns = useUserColumns(true);

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile caricare l'elenco degli utenti. Riprovare."
        error={error}
      />
    );
  }

  const columns: ColumnProps<UserResponseDTO>[] = userColumns;

  return (
    <AdminLayout menu={<AdminMenu />}>
      <PageHeader
        title="Utenti"
        extra={[
          <LinkButton
            ghost
            type="primary"
            to="/admin/users/create"
            icon={<PlusOutlined />}
          >
            Nuovo
          </LinkButton>,
          <ButtonImportUsersDrawer />,
          <ButtonDeskCopyDrawer
            userSelectionVisible={userSelectionVisible}
            setUserSelectionVisible={setUserSelectionVisible}
            selectedUsers={selectedUsersForDeskCopy}
            setSelectedUsers={setSelectedUsersForDeskCopy}
          />
        ]}
      />
      <TableDataScroll<UserResponseDTO>
        rowSelection={userSelectionVisible ? rowSelection : undefined}
        rowKey="id"
        loading={isLoading}
        dataSource={data?.content}
        columns={columns}
        onChange={(pagination, filters, sorter) => {
          setQuery({
            page:
              (pagination.current ? pagination.current - 1 : undefined) ??
              query.page,
            size: pagination.pageSize ?? query.size,
            name:
              filters.name && filters.name.length > 0
                ? filters.name?.[0].toString()
                : undefined,
            surname:
              filters.surname && filters.surname.length > 0
                ? filters.surname?.[0].toString()
                : undefined,
            username:
              filters.username && filters.username.length > 0
                ? filters.username?.[0].toString()
                : undefined,
            enabled:
              filters.status && filters.status.length > 0
                ? (filters.status?.[0] as boolean)
                : undefined
          });
        }}
        pagination={{
          pageSizeOptions: ['5', '10', '20', '30', '40'],
          total: data?.totalElements,
          pageSize: data?.pageable?.pageSize,
          current: data?.pageable?.pageNumber
            ? data?.pageable?.pageNumber + 1
            : undefined
        }}
      />
    </AdminLayout>
  );
}

import * as React from 'react';
import clx from 'classnames';
import { Col, Row, RowProps, Typography } from 'antd';
import styled from 'styled-components';
const { Title, Text, Paragraph } = Typography;

const ColTitle = styled(Col)<{ padding: boolean }>`
  ${props => props.padding && `padding-top: 14px;`}
`;

const SectionTitle = styled(Paragraph)`
  margin-top: 0.5em;
`;
export interface FormFieldsContainerProps extends Omit<RowProps, 'children'> {
  /**
   * Array di elementi da posizionare nelle colonne
   * */
  children: React.ReactNode;
  /**
   * Numero di colonne
   * */
  columns?: 1 | 2 | 3 | 4 | 6 | 8 | 12;

  /**
   * Permette l'inserimento di un titolo
   * */
  title?: string;
  /**
   * Sopra un titolo viene inserito uno spazio bianco di default
   * removeTopTitleSpacing permette di eliminarlo
   * */
  removeTopTitleSpacing?: boolean;
}
/**
 * Elememento contenitore di Riga per FormFieldLabel
 * */
export function FormFieldsContainer(props: FormFieldsContainerProps) {
  const { children, columns, title, removeTopTitleSpacing, ...rowsProps } =
    props;
  const span = 24 / (columns ?? 1);

  return (
    <Row gutter={24} {...rowsProps}>
      {props.title && (
        <ColTitle span={24} padding={!props.removeTopTitleSpacing}>
          <SectionTitle type="secondary" children={props.title} />
        </ColTitle>
      )}
      {React.Children.map(children, (child, index) => {
        if (!React.isValidElement(child)) return null;
        return (
          <Col key={index} span={span * (child.props.colSpan ?? 1)}>
            {child}
          </Col>
        );
      })}
    </Row>
  );
}

import { FolderOutlined } from '@ant-design/icons';
import { FvIconFolderHightLight } from 'client/ui/icon/FvIcons';
import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetActionAnnotateHighlightedDossier } from './actions/annotate-dossier/WidgetActionAnnotateHighlightedDossier';
import { WidgetActionDeleteHighlightedDossier } from './actions/delete-dossier/WidgetActionDeleteHighlighedDossier';
import { WidgetActionHighlightDossier } from './actions/highlight-dossier/WidgetActionHighlightDossier';
import { WidgetHighlightedDossiers } from './WidgetHighlightedDossiers';

export const WidgetHighlightedDossiersType: IWidgetType = {
  code: 'highlighted-dossiers',
  name: 'Fascicoli in evidenza',
  icon: <FvIconFolderHightLight />,
  component: WidgetHighlightedDossiers,
  description: `Permette di mostrare i fascicoli in evidenza`,
  actions: [WidgetActionHighlightDossier],
  internalActions: [
    WidgetActionDeleteHighlightedDossier,
    WidgetActionAnnotateHighlightedDossier
  ]
};

WidgetRepository.register(WidgetHighlightedDossiersType);

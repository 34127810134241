import { GetDocumentaryDamParams, Widget } from 'client/api/backend/schemas';
import { applyDynamicDateRange } from 'client/components/toolkit/components/filter/dynamic/DynamicDateRanges';

/**
 * Intefaccia specifica dei filtri "di default" impostati da questo widget
 */
export interface IWidgetDamSearchFilterData
  extends Pick<
    GetDocumentaryDamParams,
    'status' | 'lagoon' | 'dealer' | 'operator'
  > {}

export function applyWidgetDamSearchDefaultFilters(
  widget: Widget
): Partial<GetDocumentaryDamParams> {
  const filters = widget.profile?.filters as IWidgetDamSearchFilterData | null;
  if (!filters) return {};

  return filters;
}

import { Tooltip } from 'antd';
import React from 'react';
import styled from 'styled-components';

export interface IconTooltipsProps {
  title?: React.ReactNode;
  icon?: React.ReactNode;

  showTitle?: boolean;
}

/**
 * Ritorna un icona con il testo in un tooltip
 *
 * Se non è presente l'icona ritorna il testo
 */
export function IconTooltips(props: IconTooltipsProps) {
  const { title, icon, showTitle } = props;
  if (!icon || showTitle) {
    return <span>{title}</span>;
  }
  return <Tooltip title={title}>{icon}</Tooltip>;
}

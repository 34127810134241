import { Widget } from 'client/api/backend/schemas';
import { useCallback } from 'react';
import { WidgetPositionHandler } from './WidgetPositionHandler';

export function useWidgetPositionHandler() {
  const position = useCallback(WidgetPositionHandler.position, []);

  return {
    position
  };
}

import React, { useState } from 'react';
import {
  BarsOutlined,
  FileOutlined,
  HistoryOutlined,
  PaperClipOutlined
} from '@ant-design/icons';
import { useDroppable } from '@dnd-kit/core';
import {
  useGetDocumentaryDocumentsAttachmentsId,
  useGetDocumentaryDocumentsId,
  useGetDocumentaryDocumentsIdAssignments
} from 'client/api/backend/documentary-documents/documentary-documents';
import { useDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { useDesk } from 'client/components/schema/desk/DeskModule';
import { WidgetCardBodyTabs } from 'client/components/schema/widget/card/body/WidgetCardBodyTabs';
import { ToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { WidgetDocumentViewerProfile } from './WidgetDocumentViewerProfile';
import { WidgetTabPanel } from 'client/components/toolkit/components/tabs/WidgetTabPanel';
import { TabsSideMenu } from '../../../toolkit/components/tabs/TabsSideMenu';
import { FixturesDoc } from './FixturesDoc';
import { convertDocumentToAttachmentMenuItems } from './convert/convertDocumentToAttachmentMenuItems';
import { DocumentMetadatas } from './metadata/DocumentMetadatas';
import { DocumentHistory } from './history/DocumentHistory';
import { PaddedSkeleton } from 'client/ui/skeleton/PaddedSkeleton';
import { WidgetDocumentPreview } from './WidgetDocumentPreview';
import { useWidgetContext } from 'client/components/schema/widget/context/WidgetContext';
import { useWidgetNetworkQueries } from 'client/components/schema/widget/context/useWidgetNetworkEffect';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { ToolkitObjectBottomZone } from 'client/components/toolkit/objects/ToolkitObjectBottomZone';
import { useRegisterToolkitObject } from 'client/components/toolkit/objects/store/useToolkitObjectStore';
import { DocumentToolkitObject } from '../../objects/documents/DocumentToolkitObject';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import {
  getGetDocumentaryStreamsAttachmentsIdQueryKey,
  getGetDocumentaryStreamsDocumentsIdQueryKey,
  useGetDocumentaryStreamsDocumentsId
} from 'client/api/backend/documentary-streams/documentary-streams';

const PRINCIPAL_DOCUMENT = 'principal';

const isPrincipalDocument = (selectedAttachment: string) => {
  return selectedAttachment === PRINCIPAL_DOCUMENT;
};

export interface IWidgetDocumentViewerProps
  extends IWidgetComponentToolkitProps {}

export function WidgetDocumentViewer(props: IWidgetDocumentViewerProps) {
  const { widget } = props;
  const widgetContext = useWidgetContext();
  const data = widget.profile?.data as WidgetDocumentViewerProfile | undefined;

  const documentResult = useGetDocumentaryDocumentsId(
    data?.documentId!,
    { widgetId: widget.id! },
    {
      query: {
        enabled: data?.documentId != null
      }
    }
  );

  const documentAssignmentsResult = useGetDocumentaryDocumentsIdAssignments(
    data?.documentId!,
    {
      widgetId: widget.id!
    }
  );
  const documentAssignments = documentAssignmentsResult.data;

  const [selectedAttachment, setSelectedAttachment] =
    useState<string>(PRINCIPAL_DOCUMENT);

  const menuAttachments = convertDocumentToAttachmentMenuItems(
    documentResult.data
  );

  const attachmentResult = useGetDocumentaryDocumentsAttachmentsId(
    parseInt(selectedAttachment, 10),
    { widgetId: widget.id! },
    {
      query: {
        enabled: !isPrincipalDocument(selectedAttachment)
      }
    }
  );

  useWidgetNetworkQueries([
    attachmentResult,
    documentResult,
    documentAssignmentsResult
  ]);

  // TODO: Rimuovere `${documentResult.data?.subject}.pdf` quando `filename` verrà ritornato da folium
  const fileName = isPrincipalDocument(selectedAttachment)
    ? documentResult.data?.filename ?? `${documentResult.data?.subject}.pdf`
    : attachmentResult.data?.name;

  // Creiamo l'oggetto per il toolkit
  const widgetObject = WidgetToolkitObject.from(widget);
  useRegisterToolkitObject(widgetObject);

  return (
    <WidgetCardBody
      scrollable={false}
      bottom={<ToolkitObjectBottomZone object={widgetObject} />}
    >
      <WidgetCardBodyTabs>
        <WidgetTabPanel
          title="Documento"
          icon={<FileOutlined />}
          type="main"
          tabKey="main"
          scrollable={false}
        >
          <PaddedSkeleton
            loading={documentResult.isLoading || attachmentResult.isLoading}
            active
          >
            <WidgetDocumentPreview
              fileName={fileName}
              source={{
                type: 'url',
                url: getApiUrl(data?.documentId, selectedAttachment),
                widgetId: widget.id!
              }}
            />
          </PaddedSkeleton>
        </WidgetTabPanel>
        <WidgetTabPanel
          title="Metadati"
          tabKey="metadata"
          icon={<BarsOutlined />}
          padded
        >
          <DocumentMetadatas
            widgetId={widget.id!}
            document={documentResult.data}
          />
        </WidgetTabPanel>
        <WidgetTabPanel
          title="Storico"
          tabKey="history"
          icon={<HistoryOutlined />}
          padded
        >
          <DocumentHistory documentAssignments={documentAssignments} />
        </WidgetTabPanel>
        <WidgetTabPanel
          title="Allegati"
          icon={<PaperClipOutlined />}
          type="side-menu"
          tabKey="side-menu"
        >
          <TabsSideMenu
            items={menuAttachments}
            defaultSelectedKeys={[selectedAttachment]}
            onClick={e => setSelectedAttachment(e.key)}
          />
        </WidgetTabPanel>
      </WidgetCardBodyTabs>
    </WidgetCardBody>
  );
}

function getApiUrl(documentId: number | undefined, selectedAttachment: string) {
  if (!documentId) return undefined;

  return isPrincipalDocument(selectedAttachment)
    ? (getGetDocumentaryStreamsDocumentsIdQueryKey(documentId)[0] as string)
    : (getGetDocumentaryStreamsAttachmentsIdQueryKey(
        parseInt(selectedAttachment, 10)
      )[0] as string);
}

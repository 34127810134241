import React from 'react';
import { Switch, Route, Redirect } from 'react-router';
import { AuthCurrentLoader } from 'client/components/auth/current-loader/AuthCurrentLoader';
import {
  useAuth,
  useIsCurrentUserAdministrator
} from 'client/components/auth/AuthModule';
import { NotFoundPage } from 'client/components/errors/not-found/NotFoundPage';
import { useModalHistoryCleanup } from 'client/ui/modal/useModalHistoryCleanup';
import { LoginPage } from 'client/routes/login/LoginPage';
import { DeskPage } from './desk/DeskPage';
import { UserListPage } from './admin/user/UserListPage';
import { UserPage } from './admin/user/UserPage';
import { EndpointListPage } from './admin/endpoint/EndpointListPage';
import { EndpointPage } from './admin/endpoint/EndpointPage';
import { ProfileListPage } from './admin/profile/ProfileListPage';
import { ProfilePage } from './admin/profile/ProfilePage';

export const Routes = () => {
  const { logged } = useAuth();
  const isAdministrator = useIsCurrentUserAdministrator();

  // const permissions = usePermissions();

  // Chiudiamo tutte le modal alla navigazione
  useModalHistoryCleanup();

  return (
    <AuthCurrentLoader>
      {!logged ? (
        <Switch>
          <Route path="/login" component={LoginPage} />
          <Redirect to={{ pathname: '/login' }} />
        </Switch>
      ) : (
        <Switch>
          <Redirect exact from="/login" to="/" />
          <Route path="/desk" component={DeskPage} />
          {/* Admin Pages */}
          {isAdministrator && [
            <Route path="/admin/users/:id" component={UserPage} />,
            <Route path="/admin/users" component={UserListPage} />,

            <Route path="/admin/endpoints/:id" component={EndpointPage} />,
            <Route path="/admin/endpoints" component={EndpointListPage} />,

            <Route path="/admin/profiles/:id" component={ProfilePage} />,
            <Route path="/admin/profiles" component={ProfileListPage} />
          ]}

          <Redirect exact from="/" to="/desk" />

          {/** Pagina non trovata */}
          <Route component={NotFoundPage} />
        </Switch>
      )}
    </AuthCurrentLoader>
  );
};

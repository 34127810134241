import * as React from 'react';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { DeleteOutlined } from '@ant-design/icons';
import {
  getGetEndpointsQueryKey,
  useDeleteEndpointsId
} from 'client/api/backend/endpoints/endpoints';
import { message } from 'antd';
import { queryClient } from 'client/core/network/queryClient';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

interface EndpointColumnDeleteButtonProps {
  id: number;
  name?: string;
}

export function EndpointColumnDeleteButton(
  props: EndpointColumnDeleteButtonProps
) {
  const deleteMutation = useDeleteEndpointsId({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetEndpointsQueryKey());
      }
    }
  });

  return (
    <ButtonDeleteConfirm
      placement="left"
      title={
        <span>
          Sei sicuro di voler eliminare l'Endpoint <b>{props.name ?? ''}</b>?
        </span>
      }
      cancelText="Annulla"
      onConfirm={async () => {
        try {
          await deleteMutation.mutateAsync({
            id: props.id
          });
          message.success('Endpoint eliminato con successo. ');
        } catch (e) {
          message.error(
            getNetworkErrorMessage(
              e,
              "Errore durante l'eliminazione dell'endpoint. Riprovare."
            )
          );
        }
      }}
    >
      <ButtonIcon danger icon={<DeleteOutlined />} />
    </ButtonDeleteConfirm>
  );
}

/**
 * Generato automaticamente.
 * DeskUI REST API
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  DeskKeyValue,
  GetDocumentaryStatisticsAssignmentsParams,
  DeskAssignment,
  GetDocumentaryStatisticsAssignmentsDetailsParams
} from '.././schemas';
import {
  apiInstance,
  ErrorType
} from '../../../core/network/mutators/apiClient';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Ritorna le statistiche per le assegnazioni
 */
export const getDocumentaryStatisticsAssignments = (
  params?: GetDocumentaryStatisticsAssignmentsParams
) => {
  return apiInstance<DeskKeyValue[]>({
    url: `/api/v1/documentary/statistics/assignments`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryStatisticsAssignmentsQueryKey = (
  params?: GetDocumentaryStatisticsAssignmentsParams
) => [
  `/api/v1/documentary/statistics/assignments`,
  ...(params ? [params] : [])
];

export type GetDocumentaryStatisticsAssignmentsQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryStatisticsAssignments>
>;
export type GetDocumentaryStatisticsAssignmentsQueryError = ErrorType<void>;

export const useGetDocumentaryStatisticsAssignments = <
  TData = AsyncReturnType<typeof getDocumentaryStatisticsAssignments>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryStatisticsAssignmentsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryStatisticsAssignments>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryStatisticsAssignmentsQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryStatisticsAssignments>
  > = () => getDocumentaryStatisticsAssignments(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryStatisticsAssignments>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna i dettagli delle statistiche per le assegnazioni
 */
export const getDocumentaryStatisticsAssignmentsDetails = (
  params?: GetDocumentaryStatisticsAssignmentsDetailsParams
) => {
  return apiInstance<DeskAssignment[]>({
    url: `/api/v1/documentary/statistics/assignments/details`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryStatisticsAssignmentsDetailsQueryKey = (
  params?: GetDocumentaryStatisticsAssignmentsDetailsParams
) => [
  `/api/v1/documentary/statistics/assignments/details`,
  ...(params ? [params] : [])
];

export type GetDocumentaryStatisticsAssignmentsDetailsQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryStatisticsAssignmentsDetails>
>;
export type GetDocumentaryStatisticsAssignmentsDetailsQueryError =
  ErrorType<void>;

export const useGetDocumentaryStatisticsAssignmentsDetails = <
  TData = AsyncReturnType<typeof getDocumentaryStatisticsAssignmentsDetails>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryStatisticsAssignmentsDetailsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryStatisticsAssignmentsDetails>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryStatisticsAssignmentsDetailsQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryStatisticsAssignmentsDetails>
  > = () => getDocumentaryStatisticsAssignmentsDetails(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryStatisticsAssignmentsDetails>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

import {
  GroupOutlined,
  TeamOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';
import { FvIconUserAssignment } from 'client/ui/icon/FvIcons';
import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetActionAddAssignmentModel } from './actions/add-assignment-model/WidgetActionAddAssignmentModel';
import { WidgetActionAssignDocumentToModel } from './actions/assign-document-to-model/WidgetActionAssignDocumentToModel';
import { WidgetActionCreateAssignmentModel } from './actions/create-assignment-model/WidgetActionCreateAssignmentModel';
import { WidgetActionDeleteAssignmentModel } from './actions/delete-assignment-model/WidgetActionDeleteAssignmentModel';
import { WidgetActionRemoveAssignmentModelItem } from './actions/remove-assignment-model-item/WidgetActionRemoveAssignmentModelItem';
import { WidgetActionRenameAssignmentModel } from './actions/rename-assignment-model/WidgetActionRenameAssignmentModel';
import { WidgetAssignmentModels } from './WidgetAssignmentModels';

export const WidgetAssignmentModelsType: IWidgetType = {
  code: 'assignment-models',
  name: 'Modelli Assegnazione',
  icon: <TeamOutlined />, // TODO creare icona
  component: WidgetAssignmentModels,
  description: `Elenco dei modelli di assegnazione`,
  actions: [
    WidgetActionCreateAssignmentModel,
    WidgetActionAddAssignmentModel,
    WidgetActionAssignDocumentToModel
  ],
  internalActions: [
    WidgetActionDeleteAssignmentModel,
    WidgetActionRenameAssignmentModel,
    WidgetActionRemoveAssignmentModelItem
  ]
};

WidgetRepository.register(WidgetAssignmentModelsType);

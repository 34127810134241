import { Select } from 'antd';
import { DeskCorrespondent } from 'client/api/backend/schemas';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { useField } from 'formik';
import React from 'react';
import { CorrespondentKey } from './CorrespondentType';

export interface ICorrespondentTypeSelectInputProps {
  name: string;
  placeholder?: string;
}

export function CorrespondentTypeSelectInput(
  props: ICorrespondentTypeSelectInputProps
) {
  const options = [
    {
      value: CorrespondentKey.Person,
      label: 'Persona Fisica'
    },
    {
      value: CorrespondentKey.Organization,
      label: 'Persona Giuridica'
    },
    {
      value: CorrespondentKey.PublicAdministration,
      label: 'Pubblica Amministrazione'
    },
    {
      value: CorrespondentKey.ForeignPublicAdministration,
      label: 'Pubblica Amministrazione Estera'
    }
  ];

  return (
    <SelectInput
      options={options}
      placeholder={props.placeholder ?? 'Tipo corrispondente'}
      {...props}
    />
  );
}

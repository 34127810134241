import { yup } from 'common/validation/initYup';
import { WidgetPosition } from './WidgetPosition';

export interface WidgetProfile {
  /** Permette la chiusura rapida (eliminazione) senza conferma */
  quickClose?: boolean;

  /**
   * Layout del Widget all'interno della scrivania
   */
  position?: WidgetPosition;
  /** Colore */
  colorId?: number;
  /** Titolo personalizzato del Widget */
  title?: string;

  /** Filtri di default del widget. */
  filters?: any;

  /**
   * Tipo di Widget (specializzazione)
   */
  typeCode?: string;
  /**
   * Dati specifici relativi al singolo Widget specializzato
   */
  data?: any;

  /**
   * Specifica se il widget figlio deve essere aperto di default a schermo intero.
   * Se si apre un widget di dettaglio a partire da questo widget, il widget aperto verrà mostrato a schermo intero
   */
  showChildWidgetInFullScreen?: boolean;

  /**
   * Specifica se il widget viene inizializzato a schermo intero
   */
  initializeInFullScreen?: boolean;
}

export const WidgetProfileSchema = yup.object({
  quickClose: yup.boolean().notRequired(),
  title: yup.string().nullable().notRequired().label('Titolo')
});

import { Button, Drawer, message, notification, Space } from 'antd';
import {
  NotificationInstance,
  NotificationPlacement
} from 'antd/lib/notification';
import { TableRowSelection } from 'antd/lib/table/interface';
import Paragraph from 'antd/lib/typography/Paragraph';
import {
  postDocumentaryUsersImport,
  usePostDocumentaryUsers,
  usePostDocumentaryUsersImport
} from 'client/api/backend/documentary-users/documentary-users';
import {
  UserImportResponseDTO,
  UserListRequestDTO,
  UserResponseDTO
} from 'client/api/backend/schemas';
import { UserListResponseDTO } from 'client/api/backend/schemas/userListResponseDTO';
import { postUsersIdPanelsCopy } from 'client/api/backend/users/users';
import { getGetUsersQueryKey } from 'client/api/backend/users/users';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { queryClient } from 'client/core/network/queryClient';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikForm } from 'client/ui/form/FormikForm';
import { MessageParagraph } from 'client/ui/message/MessageParagraph';
import { TableData } from 'client/ui/table/TableData';
import { yup } from 'common/validation/initYup';
import { Formik } from 'formik';
import { isEmpty, values } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { RoleSelectInput } from '../../profile/RoleSelectInput';
import { useUserColumns } from '../UserColumns';

interface ImportUsersDrawerProps {
  userListVisible: boolean;
  setUserListVisible: (userListVisible: boolean) => void;
  userQueryParams: UserListRequestDTO;
  setUserQueryParams: (userQueryParams: UserListRequestDTO) => void;
  userImportFormVisible: boolean;
  setUserImportFormVisible: (userImportFormVisible: boolean) => void;
  copyDeskUserId?: number | null;
}

export function ImportUsersDrawer(props: ImportUsersDrawerProps) {
  const {
    userListVisible,
    setUserListVisible,
    userQueryParams,
    setUserQueryParams,
    userImportFormVisible,
    setUserImportFormVisible,
    copyDeskUserId
  } = props;

  const [userList, setUserList] = useState<UserListResponseDTO>({});
  const users = usePostDocumentaryUsers();
  const importUsers = usePostDocumentaryUsersImport({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetUsersQueryKey());
      }
    }
  });

  // Stato della selezione
  const [selectedUsers, setSelectedUsers] = useState<UserResponseDTO[]>(
    () => []
  );
  const rowSelection: TableRowSelection<UserResponseDTO> = {
    selectedRowKeys: selectedUsers.map(d => d.id!),
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedUsers(selectedRows);
    }
  };

  useEffect(() => {
    async function loadUsers() {
      if (isEmpty(userQueryParams)) return;

      try {
        const result = (await users.mutateAsync({
          data: userQueryParams
        })) as UserListResponseDTO;

        if (result) {
          setUserList(result);
        }
      } catch (e) {
        message.error(
          getNetworkErrorMessage(
            e,
            'Non è stato possibile recuperare la lista degli utenti'
          )
        );
      }
    }
    loadUsers();
  }, [userQueryParams]);

  const userColumns = useUserColumns(false);

  const [notificationApi, contextHolder] = notification.useNotification();

  return (
    <>
      {contextHolder}
      <Drawer
        placement="bottom"
        height="70%"
        title="Importa Utenti"
        visible={userListVisible}
        onClose={() => setUserListVisible(false)}
        footer={
          <Formik<any>
            initialValues={{}}
            validationSchema={yup.object({
              roleId: yup.number().required().label('Ruolo')
            })}
            onSubmit={async values => {
              try {
                const importedUsers = await importUsers.mutateAsync({
                  data: {
                    endpointId: userQueryParams.endpointId!,
                    fields: userQueryParams.fields,
                    roleId: values.roleId,
                    usersIds: selectedUsers.map(u => u.id!)
                  }
                });

                try {
                  if (
                    copyDeskUserId != null &&
                    importedUsers?.usersIds?.length
                  ) {
                    await postUsersIdPanelsCopy(copyDeskUserId, {
                      userIds: importedUsers?.usersIds
                    });
                  }
                } catch (e) {
                  message.error(
                    getNetworkErrorMessage(
                      e,
                      'Si è verificato un errore durante la copia delle scrivanie'
                    )
                  );
                }

                showNotificationMessage(importedUsers, notificationApi);
              } catch (e) {
                message.error(
                  getNetworkErrorMessage(
                    e,
                    "Si è verificato un errore durante l'importazione degli utenti"
                  )
                );
              }

              setUserListVisible(false);
              setUserImportFormVisible(false);
              setSelectedUsers([]);
            }}
          >
            <Space style={{ float: 'right' }}>
              <FormikForm
                editable
                helpInTooltips
                tooltipPlacement="left"
                layout="inline"
              >
                <FormFieldsContainer style={{ width: '250px' }}>
                  <FormFieldItem
                    component={RoleSelectInput}
                    label={null}
                    name="roleId"
                    size="middle"
                    placeholder="Scegli Ruolo"
                  />
                </FormFieldsContainer>
                <FormFieldsContainer>
                  <FormikSendButton type="primary">
                    Importa Utenti Selezionati
                  </FormikSendButton>
                </FormFieldsContainer>
              </FormikForm>
            </Space>
          </Formik>
        }
      >
        <TableData<UserResponseDTO>
          rowSelection={rowSelection}
          rowKey="id"
          dataSource={userList.users}
          columns={userColumns.filter(
            c => c.dataIndex !== 'actions' && c.dataIndex !== 'roleId'
          )}
          onChange={(pagination, filters, sorter) => {
            setUserQueryParams({
              ...userQueryParams,
              pageNumber:
                (pagination.current ? pagination.current - 1 : undefined) ??
                userQueryParams.pageNumber,
              pageSize: pagination.pageSize ?? userQueryParams.pageSize
            });
          }}
          pagination={{
            pageSizeOptions: ['5', '10', '20', '30', '40'],
            pageSize: userQueryParams.pageSize,
            total: userList.totalCount,
            current: userQueryParams.pageNumber
              ? userQueryParams.pageNumber + 1
              : undefined
          }}
        />
      </Drawer>
    </>
  );
}

function showNotificationMessage(
  importedUsers: UserImportResponseDTO | void,
  notificationApi: NotificationInstance
) {
  if (!importedUsers) {
    return 'Importazione effettuata con successo';
  }

  const description = (
    <>
      <MessageParagraph>
        Utenti trovati: {importedUsers.usersFounded}
      </MessageParagraph>
      <MessageParagraph>
        Utenti importati: {importedUsers.usersImported}
      </MessageParagraph>
    </>
  );

  if (importedUsers.usersFounded !== importedUsers.usersImported) {
    return notificationApi.warning({
      message: 'Importazione effettuata con errori',
      description,
      placement: 'top'
    });
  }

  return notificationApi.success({
    message: 'Importazione effettuata con successo',
    description,
    placement: 'top'
  });
}

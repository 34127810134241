import * as React from 'react';
import clx from 'classnames';
import styled from 'styled-components';
import { useDesk } from 'client/components/schema/desk/DeskModule';
import { Result, ResultProps } from 'antd';
import { ClockCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import { CardBodyWrapper } from './CardBodyWrapper';

const ResultError = styled(Result)`
  height: 100%;
  max-height: 400px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ant-result-icon {
    margin-bottom: 12px;
    .anticon {
      font-size: 64px;
    }
  }
`;

export interface WidgetFullBodyResultProps
  extends React.HTMLAttributes<HTMLDivElement> {
  result?: Pick<
    ResultProps,
    'title' | 'subTitle' | 'extra' | 'status' | 'icon'
  >;
}

/**
 * ...
 *
 */
export function WidgetFullBodyResult(props: WidgetFullBodyResultProps) {
  const { result, ...otherProps } = props;

  return (
    <CardBodyWrapper {...otherProps}>
      <ResultError
        icon={
          result?.icon ? (
            result.icon
          ) : result?.status == 'error' ? (
            <WarningTwoTone twoToneColor={'#f83e5a'} />
          ) : (
            <ClockCircleTwoTone twoToneColor={'#ff9700'} />
          )
        }
        title={
          result?.title
            ? result.title
            : result?.status == 'error'
            ? 'Errore!'
            : undefined
        }
        subTitle={
          result?.subTitle ??
          'Errore nel caricamento dei dati: Codice di errore 8001'
        }
        extra={result?.extra}
      />
    </CardBodyWrapper>
  );
}

import { registerToolkitObjectType } from 'client/components/toolkit/objects/registerToolkitObjectType';

export interface HighlightedDeskAssignment {
  id?: number;
  highlighted?: {
    notes?: string;
  };
}

export const HighlightedAssignmentToolkitObject = registerToolkitObjectType<{}>(
  'highlighted-assignment'
);

import React, { useState } from 'react';
import styled from 'styled-components';
import Button, { ButtonProps } from 'antd/lib/button';
import clx from 'classnames';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useDesk } from 'client/components/schema/desk/DeskModule';
import { DeskEditButtonStyle } from '../detail/DeskEditButtonStyle';
import { message, Popconfirm, Space } from 'antd';
import { DeskEditForm } from './DeskEditForm';
import { BaseDrawerStyle } from 'client/ui/drawer/BaseDrawerStyle';
import { FormikForm } from 'client/ui/form/FormikForm';
import { Panel } from 'client/api/backend/schemas';
import { useDeskMutations } from 'client/components/schema/desk/logic/useDeskMutations';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { yup } from 'common/validation/initYup';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';

const WideButton = styled(Button)`
  padding-left: 25px;
  padding-right: 25px;
  min-width: 100px;
`;

interface DeskEditButtonDrawerProps
  extends Omit<ButtonProps, 'shape' | 'icon' | 'color'> {
  desk: Panel;
}

// TODO Spostare
const DeskUpdateSchema = yup.object({
  name: yup.string().required().label('Nome'),
  profile: yup.object({
    colorId: yup.number().default(1), // TODO
    description: yup.string().default('').label('Descrizione')
  })
});

// TODO Rinominare, contiene anche il form e il drawer
export function DeskEditButtonDrawer(props: DeskEditButtonDrawerProps) {
  const [visible, setVisible] = useState(false);

  const deskState = useDesk();
  const deskMutations = useDeskMutations();

  const handleOpen = () => {
    setVisible(true);
  };
  const handleClose = () => {
    setVisible(false);
  };

  const { desk } = props;

  if (!deskState.isEditable) {
    return null;
  }
  if (desk.id !== deskState.selectedId) {
    return null;
  }

  const isLoading =
    deskMutations.updateState.isLoading || deskMutations.removeState.isLoading;

  return (
    <>
      <DeskEditButtonStyle
        className={clx({
          selected: deskState.selectedId === desk.id
        })}
        shape="round"
        type="ghost"
        icon={<EditOutlined />}
        onClick={handleOpen}
      />
      <FormikAugmented
        validationSchema={DeskUpdateSchema}
        validationContext={{}}
        initialValues={props.desk ?? {}}
        onSubmit={async (data: Panel) => {
          try {
            console.log('saving..', data);
            await deskMutations.update(data);
            setVisible(false);
          } catch (e) {
            message.error(`Si è verificato un errore durante l'aggiornamento, si prega di riprovare.`); // prettier-ignore
          }
        }}
      >
        <FormikForm
          layout="vertical"
          editable
          disabled={isLoading}
          helpInTooltips
        >
          <BaseDrawerStyle
            key="desk-edit-drawer"
            title="Impostazioni Scrivania"
            placement="top"
            onClose={handleClose}
            visible={visible}
            height={290}
            extra={
              <Space>
                <ButtonDeleteConfirm
                  title={
                    <span>
                      Sei sicuro di voler eliminare la
                      <br />
                      scrivania <b>{desk.name ?? ''}</b>?
                    </span>
                  }
                  cancelText="Annulla"
                  // okText="Elimina Scrivania"
                  onConfirm={async () => {
                    await deskMutations.remove(desk);
                  }}
                  buttonType="default"
                >
                  <WideButton
                    danger={true}
                    loading={deskMutations.removeState.isLoading}
                    icon={<DeleteOutlined />}
                    children="Elimina"
                  />
                </ButtonDeleteConfirm>

                {/* <Popconfirm
                  title={
                    <span>
                      Sei sicuro di voler eliminare la scrivania{' '}
                      <b>{desk.name ?? ''}</b>?
                    </span>
                  }
                  cancelText="Annulla"
                  okText="Elimina Scrivania"
                  onConfirm={async () => {
                    await deskMutations.remove(desk);
                  }}
                >
                  <WideButton
                    danger={true}
                    loading={deskMutations.removeState.isLoading}
                    icon={<DeleteOutlined />}
                    children="Elimina"
                  />
                </Popconfirm> */}
                <WideButton
                  // size="large"
                  onClick={handleClose}
                  children="Annulla"
                />
                <FormikSendButton
                  as={WideButton}
                  loading={isLoading}
                  type="primary"
                  children="Salva"
                  // size="large"
                />
              </Space>
            }
          >
            <DeskEditForm desk={desk} />
          </BaseDrawerStyle>
        </FormikForm>
      </FormikAugmented>
    </>
  );
}

import React from 'react';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import { useGetDocumentaryRegistriesIdModes } from 'client/api/backend/documentary-registries/documentary-registries';
import { getIn, useFormikContext } from 'formik';

// Modalità così come ritornate da Folium:
// {label: "E", value: "ENTRATA"}
// {label: "U", value: "USCITA"}
// {label: "I", value: "INTERNA"}
// {label: "R", value: "REGISTRAZIONE"}

interface Props extends SelectInputProps<any> {
  registryIdFieldName: string;
  widgetId: number;
}

export function DocumentProtocolRegistryModeInput(props: Props) {
  const { widgetId, registryIdFieldName, ...otherProps } = props;

  const formik = useFormikContext();
  const registryId = getIn(formik.values, registryIdFieldName) as
    | number
    | undefined;

  const modesResult = useGetDocumentaryRegistriesIdModes(
    registryId!,
    {
      widgetId: props.widgetId
    },
    { query: { enabled: registryId != null } }
  );

  return (
    <SelectInput
      allowClear
      showSearch
      loading={modesResult.isLoading}
      options={modesResult.data?.map(mode => ({
        // Attenzione, sono invertiti lato API
        label: mode.value,
        value: mode.label!
      }))}
      {...otherProps}
    />
  );
}

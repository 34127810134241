import * as React from 'react';
import clx from 'classnames';
import styled from 'styled-components';
import { Typography, Radio } from 'antd';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextInput } from 'client/ui/form/input/TextInput';
import { RadioInput } from 'client/ui/form/input/RadioInput';
import { getColors } from 'client/components/schema/colors/Colors';
import { ColorOption } from './ColorOption';
import { Panel } from 'client/api/backend/schemas';

const { Text } = Typography;

const BodyWrapper = styled.div`
  padding: 0 20px;
  margin: 0 auto;
  margin-top: 20px;
  max-width: 900px;
`;

const RadioColorsWrapper = styled.div`
  .ant-radio-group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
`;

const RadioButton = styled(Radio.Button)`
  flex-grow: 1;
`;
export interface DeskEditFormProps {
  desk?: Panel;
}

/**
 * //TODO
 */
export function DeskEditForm(props: DeskEditFormProps) {
  const colors = getColors();
  return (
    <BodyWrapper>
      <FormFieldsContainer>
        <FormFieldItem
          size="large"
          component={TextInput}
          name="name"
          label={null}
          // label="Nome Scrivania"
          placeholder="Nome Scrivania"
        />
        <FormFieldItem
          size="large"
          component={TextInput}
          name="profile.description"
          label={null}
          // label="Descrizione"
          placeholder="Descrizione"
        />
      </FormFieldsContainer>
      <FormFieldsContainer>
        <RadioColorsWrapper>
          <FormFieldItem
            size="large"
            component={RadioInput}
            children={colors.map(color => (
              <ColorOption color={color} />
            ))}
            optionType="button"
            name="profile.colorId"
            label={null}
            // label="Descrizone"
          />
        </RadioColorsWrapper>
      </FormFieldsContainer>
    </BodyWrapper>
  );
}

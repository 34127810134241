import { FvIconUserAssignment } from 'client/ui/icon/FvIcons';
import React from 'react';
import { AssignmentType } from '../../objects/assignments/mapIdType';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetActionClassifyDocument } from '../document-viewer/actions/WidgetActionClassifyDocument';
import { WidgetActionAssignDocument } from './actions/assign-document/WidgetActionAssignDocument';
import { WidgetActionConcludeAssignment } from './actions/conclude/WidgetActionConcludeAssignment';
import { WidgetActionDeleteAssignment } from './actions/delete/WidgetActionDeleteAssignment';
import { WidgetActionReturnAssignment } from './actions/return/WidgetActionReturnAssignment';
import { WidgetActionTakeChargeOfAssignment } from './actions/take-charge/WidgetActionTakeChargeOfAssignment';
import { WidgetAssignmentsFilterForm } from './filters/WidgetAssignmentsFilterForm';
import { WidgetAssignments } from './WidgetAssignments';

export const WidgetAssignmentsType: IWidgetType = {
  code: 'assignments',
  name: 'Assegnazioni',
  icon: <FvIconUserAssignment />,
  component: WidgetAssignments,
  filterComponent: WidgetAssignmentsFilterForm,
  defaultFilters: {
    assignmentTypeId: AssignmentType.Competenza
  },
  description: `Elenco e ricerca delle assegnazioni`,
  actions: [
    WidgetActionAssignDocument,
    WidgetActionConcludeAssignment,
    WidgetActionReturnAssignment,
    WidgetActionDeleteAssignment,
    WidgetActionTakeChargeOfAssignment,
    WidgetActionClassifyDocument
  ]
};

WidgetRepository.register(WidgetAssignmentsType);

import { IActionMessage } from 'client/components/toolkit/actions/message/IActionMessage';
import { IAutoActionMessage } from 'client/components/toolkit/actions/message/IAutoActionMessage';
import { FormikValues } from 'formik';
import React, { useState } from 'react';
import { useCallback, useReducer } from 'react';
import { v4 as uuid } from 'uuid';
import { InferType, ObjectSchema } from 'yup';
import { AutoActionMessage } from './AutoActionMessage';

type ActionMessagesAction =
  // Mostra un messaggio
  | { type: 'show'; message: IActionMessage }
  // Chiude un messaggio
  | { type: 'close'; messageId: string };

export interface ActionMessagesState {
  messages: IActionMessage[];
}

function reducer(
  state: ActionMessagesState,
  action: ActionMessagesAction
): ActionMessagesState {
  switch (action.type) {
    case 'show':
      return {
        ...state,
        messages: [...state.messages, { ...action.message }]
      };

    case 'close':
      return {
        ...state,
        messages: state.messages.filter(m => m.id !== action.messageId)
      };
  }
}

/**
 * Gestisce lo stato dei messaggi delle azioni
 */
export function useActionMessagesReducer() {
  const [state, dispatch] = useReducer(reducer, { messages: [] });

  /**
   * Shortcut per aprire un messaggio. Non esponiamo direttamente `dispatch`
   * per incapsulare il comportamento del reducer.
   */
  const show = useCallback(
    (message: Omit<IActionMessage, 'id'>) => {
      dispatch({ type: 'show', message: { ...message, id: uuid() } });
    },
    [dispatch]
  );

  const close = useCallback(
    (id: string) => {
      dispatch({ type: 'close', messageId: id });
    },
    [dispatch]
  );

  /**
   * Prompt per gestire le azioni automatiche.
   */
  const prompt = useCallback(
    function <
      TArgs extends any[],
      TBaseValues extends FormikValues,
      TValues = InferType<ObjectSchema<TBaseValues>>
    >(message: IAutoActionMessage<TArgs, TBaseValues>) {
      const id = uuid();
      dispatch({
        type: 'show',
        message: {
          id,
          content: id => <AutoActionMessage id={id} message={message} />
        }
      });
    },
    [dispatch]
  );

  return {
    state,
    show,
    close,
    /**
     * Apre un prompt all'utente richiedendo di compilare il form e gli
     * eventuali ToolkitObject mancanti.
     */
    prompt
  };
}

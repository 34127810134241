import styled from 'styled-components';

export const BodyWrapper = styled.div`
  padding: ${props => props.theme.cardPaddingBase};
  /* margin-top: 20px; */
`;

export const ButtonsWrapper = styled.div`
  text-align: right;
  padding: ${props => props.theme.cardPaddingBase};
  /* border-top: solid 1px ${props => props.theme.borderColorBase}; */
`;

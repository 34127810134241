import { CheckboxOptionType } from 'antd/lib/checkbox/Group';

export interface IColor {
  id: number;
  name: string;
  color: string;
}
/**
 * Colori assegnabili ai Widget
 * Sono stabiliti di default per garantire
 * un buon aspetto grafico e la leggibilità //0098ed
 */
const defaultColor = '#0080ff';
const colors: IColor[] = [
  { id: 1, name: 'Red', color: '#B20E24' },
  { id: 2, name: 'Orange', color: '#CA7024' },
  { id: 3, name: 'Yellow', color: '#c6b806' },
  { id: 4, name: 'Gold', color: '#a5a05e' },
  { id: 5, name: 'Olive', color: '#8aab7b' },
  { id: 6, name: 'Green', color: '#008954' },
  { id: 7, name: 'Default', color: defaultColor },
  { id: 8, name: 'Blue', color: '#0066cc' },
  { id: 9, name: 'Violet', color: '#402d9b' },
  { id: 10, name: 'Purple', color: '#642f8e' },
  { id: 11, name: 'Pink', color: '#B33275' },
  { id: 12, name: 'Brown', color: '#754854' },
  { id: 13, name: 'Grey', color: '#4f6584' },
  { id: 14, name: 'Black', color: '#1B1C1D' }
];

/**
 * Ottiene l'elenco dei Colori
 */
export function getColors(): IColor[] {
  return colors;
}

/**
 * Ottiene un oggetto Colore {id, nome e colore}
 * @param id id del colore da ottenere
 */
export function getColorById(id: number): IColor | undefined {
  return colors.find(x => x.id === id);
}

/**
 * Ottiene il Colore in formato Hex da passare ai Css ('#1B1C1D')
 * @param id id del colore da ottenere
 */
export function getHexColorById(id?: number): string {
  return colors.find(x => x.id === id)?.color ?? defaultColor;
}

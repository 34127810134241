import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { yup } from 'common/validation/initYup';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { InferType } from 'yup';
import { message } from 'antd';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { FormOutlined } from '@ant-design/icons';

import { set } from 'client/core/data/set';
import {
  HighlightedAssignmentToolkitObject,
  HighlightedDeskAssignment
} from '../objects/HighlightedAssignmentToolkitObject';
import { WidgetHighlightedAssignmentsProfile } from '../WidgetHighlightedAssignmentsProfile';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

export const WidgetActionAnnotateHighlightedAssignment = createToolkitAction({
  name: 'Modifica note Assegnazione',
  code: 'annotate-highlighted-assignment',
  icon: <FormOutlined />,
  showInBar: false,
  arguments: [
    {
      name: 'Assegnazione da annotare',
      type: [HighlightedAssignmentToolkitObject]
    }
  ],
  async execute(ctx: IToolkitActionContext, assignmentObject: ToolkitObject) {
    let assignment: ToolkitObject<HighlightedDeskAssignment> =
      HighlightedAssignmentToolkitObject.get(assignmentObject)!;

    const notes = assignment.data.highlighted?.notes;

    ctx.deskContext.actionMessages.prompt({
      ctx,
      action: WidgetActionAnnotateHighlightedAssignment,
      args: [assignment],
      confirmText: notes?.trim() ? 'Aggiorna note' : 'Aggiungi note',
      schema: AnnotateSchema,
      onConfirm,
      initialValues: { notes: notes },
      form: (
        <>
          <FormFieldsContainer>
            <FormFieldItem
              size="large"
              component={TextAreaInput}
              label={null}
              name="notes"
              placeholder="Note"
            />
          </FormFieldsContainer>
        </>
      )
    });
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: InferType<typeof AnnotateSchema>,
  assignment: ToolkitObject
) {
  try {
    const widget = ctx.deskContext.widgets.find(
      w => w.id === assignment.sourceWidgetId
    );
    const profile = widget?.profile;
    const data = profile?.data as WidgetHighlightedAssignmentsProfile;
    const index = data.assignments?.findIndex(a => a.id === assignment.data.id);

    await ctx.deskContext.updateWidget(
      set(
        widget!,
        `profile.data.assignments.${index}.highlighted.notes`,
        values.notes || null
      )
    );

    message.success('Note aggiornate con successo');
  } catch (e) {
    message.error(
      getNetworkErrorMessage(e, "Errore durante l'aggiornamento delle note")
    );
    console.error(e);
    return false;
  }
}

const AnnotateSchema = yup.object({
  notes: yup.string().notRequired().label('Note')
});

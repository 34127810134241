import * as React from 'react';
import { useMemo, useState } from 'react';
import { GetDocumentaryDocumentsSearchParams } from 'client/api/backend/schemas';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import { WidgetList } from 'client/components/toolkit/components/list/WidgetList';
import { PaddedSkeleton } from 'client/ui/skeleton/PaddedSkeleton';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { convertDocumentToWidgetListElement } from '../document-viewer/convert/convertDocumentToWidgetListElement';
import {
  InfiniteDocumentsSearchPageStart,
  useInfiniteDocumentsSearch
} from '../documents-search/api/useInfiniteDocumentsSearch';
import { WidgetFullTextDocumentsSearchForm } from './filters/WidgetFullTextDocumentsSearchForm';
import { useWidgetNetworkQueries } from 'client/components/schema/widget/context/useWidgetNetworkEffect';

const DocumentsPageSize = 20;

export interface IWidgetFullTextDocumentsSearchProps
  extends IWidgetComponentToolkitProps {}

export function WidgetFullTextDocumentsSearch(
  props: IWidgetFullTextDocumentsSearchProps
) {
  const { widget } = props;

  // Ricerca locale, per la durata della sessione.
  const [search, setSearch] = useState<
    Partial<GetDocumentaryDocumentsSearchParams>
  >({});
  const apiSearch = useMemo(
    () => ({
      ...search,
      fullText: true,
      pageNumber: InfiniteDocumentsSearchPageStart,
      pageSize: DocumentsPageSize,
      widgetId: widget.id!
    }),
    [search]
  );

  const documentsResult = useInfiniteDocumentsSearch(apiSearch, {
    query: {
      getNextPageParam: (lastPage, pages) =>
        lastPage.length < DocumentsPageSize
          ? undefined
          : pages.length + InfiniteDocumentsSearchPageStart
    }
  });

  const documents = useMemo<IWidgetListElement[]>(() => {
    if (!documentsResult.data) return [];

    return documentsResult.data.pages
      .flat()
      .map(document => convertDocumentToWidgetListElement(document, widget));
  }, [documentsResult.data]);

  useWidgetNetworkQueries([documentsResult]);

  return (
    <WidgetCardBody
      top={
        <WidgetFullTextDocumentsSearchForm
          widgetId={widget.id!}
          search={search}
          setSearch={setSearch}
        />
      }
    >
      <PaddedSkeleton loading={documentsResult.isLoading} active>
        <WidgetList list={documents} infinite={{ query: documentsResult }} />
      </PaddedSkeleton>
    </WidgetCardBody>
  );
}

import { createGlobalStyle, css } from 'styled-components';

// importato in Index.tsx
export const MainMenuStyles = createGlobalStyle`
// Fix Trascinamento su safari (fondo Blue)
div,
canvas, 
body {
  &::selection {
  color: unset;
  background: unset;
}}
-webkit-tap-highlight-color: transparent;


// Menu Dropdown posizionati nel body (Desks Menu)
.ant-menu-submenu-hidden {
  /* display: block; */
}
.desk-menu-dropdown,
.ant-menu-submenu.ant-menu-submenu-popup {
  > .ant-menu {
    margin-top: -10px;
    margin-left: -6px;
  }
  .ant-dropdown-menu-vertical > .ant-dropdown-menu-item,
  .ant-menu-vertical > .ant-menu-item  {
    line-height: 32px;
    height: 32px;
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
  .ant-dropdown-menu-title-content,
  .ant-menu-title-content {
    > button {
        height: auto;
        padding:0;
        border-radius: 0;
        border: none;
        min-width: 16px;
        &.ant-btn-icon-only {
          width: 16px;
          > .anticon {
            font-size: 16px;
            
            &.anticon-edit {
              margin-left: 10px;
              width: 16px;
            }
          }
        }
      }
    }
    &.ant-dropdown-hidden {
      /* display: block */
    }
  }
`;

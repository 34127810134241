import { yup } from 'common/validation/initYup';
import { AnyObject } from 'yup/lib/types';
import React, { useContext } from 'react';

export interface IFormikAugmentedContext {
  /** Schema di validazione */
  schema: yup.ObjectSchema<any>;
  /** Opzioni di contesto (`context`) */
  context: AnyObject;
  valueToContext?: (value: any) => AnyObject;
}

export const FormikAugmentedContext =
  React.createContext<IFormikAugmentedContext>(undefined as any);

export const useFormikAugmentedContext = () =>
  useContext(FormikAugmentedContext);

import React from 'react';
import styled, { css } from 'styled-components';

export const MonitorTopButton = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: ${props => props.theme.miniBarHeight};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  opacity: 0;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    opacity: 0.25;
  }
`;

import { useGetDocumentaryAssignments } from 'client/api/backend/documentary-assignments/documentary-assignments';
import { useGetDocumentaryDocumentsSearch } from 'client/api/backend/documentary-documents/documentary-documents';
import {
  GetDocumentaryAssignmentsParams,
  GetDocumentaryDocumentsSearchParams,
  GetDocumentaryDossiersSearchParams
} from 'client/api/backend/schemas';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { useWidgetNetworkQueries } from 'client/components/schema/widget/context/useWidgetNetworkEffect';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import { WidgetList } from 'client/components/toolkit/components/list/WidgetList';
import {
  FeatureFlag,
  hasFeatureFlag
} from 'client/core/feature-flags/featureFlags';
import { PaddedSkeleton } from 'client/ui/skeleton/PaddedSkeleton';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { AssignmentType } from '../../objects/assignments/mapIdType';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { convertAssignmentToWidgetListElement } from '../monitor-assignments/convert/convertAssignmentToWidgetListElement';
import {
  InfiniteAssignmentsSearchPageStart,
  useInfiniteAssignmentsSearch
} from './api/useInfiniteAssignmentSearch';
import { applyWidgetAssignmentsDefaultFilters } from './filters/WidgetAssignmentsFilter';
import { WidgetAssignmentsSearchForm } from './filters/WidgetAssignmentsSearchForm';

export interface IWidgetAssignmentsProps extends IWidgetComponentToolkitProps {}

const AssignmentsPageSize = 20;

export function WidgetAssignments(props: IWidgetAssignmentsProps) {
  const { widget } = props;

  // Ricerca locale, per la durata della sessione.
  const [search, setSearch] = useState<
    Partial<GetDocumentaryAssignmentsParams>
  >({});
  const apiSearch = useMemo(
    () => ({
      ...applyWidgetAssignmentsDefaultFilters(widget),
      ...search,
      pageNumber: InfiniteAssignmentsSearchPageStart,
      pageSize: AssignmentsPageSize,
      widgetId: widget.id!
    }),
    [search, widget.profile?.filters]
  );

  const assignmentsResult = useInfiniteAssignmentsSearch(apiSearch, {
    query: {
      getNextPageParam: (lastPage, pages) =>
        lastPage.length < AssignmentsPageSize
          ? undefined
          : pages.length + InfiniteAssignmentsSearchPageStart
    }
  });

  const assignments = useMemo<IWidgetListElement[]>(() => {
    if (!assignmentsResult.data) return [];

    return assignmentsResult.data.pages
      .flat()
      .map(assignment =>
        convertAssignmentToWidgetListElement(assignment, widget)
      );
  }, [assignmentsResult.data?.pages]);

  useWidgetNetworkQueries([assignmentsResult]);

  return (
    <WidgetCardBody
      top={
        <WidgetAssignmentsSearchForm
          widget={widget}
          search={search}
          setSearch={setSearch}
        />
      }
    >
      <PaddedSkeleton active loading={assignmentsResult.isLoading}>
        <WidgetList
          list={assignments}
          massive={{
            enabled: hasFeatureFlag(FeatureFlag.MassiveActions)
          }}
          infinite={{
            query: assignmentsResult
          }}
        />
      </PaddedSkeleton>
    </WidgetCardBody>
  );
}

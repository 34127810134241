import { HighlightOutlined } from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import { useGetDocumentaryDossiersSearch } from 'client/api/backend/documentary-dossiers/documentary-dossiers';
import { DeskDossier } from 'client/api/backend/schemas';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { useWidgetNetworkQueries } from 'client/components/schema/widget/context/useWidgetNetworkEffect';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import { WidgetList } from 'client/components/toolkit/components/list/WidgetList';
import { useRegisterToolkitObject } from 'client/components/toolkit/objects/store/useToolkitObjectStore';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { ToolkitObjectBottomZone } from 'client/components/toolkit/objects/ToolkitObjectBottomZone';
import { ToolkitObjectZone } from 'client/components/toolkit/objects/ToolkitObjectZone';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { FilterWrapper } from 'client/ui/filter/FilterWrapper';
import { FvIconFolderHightLight } from 'client/ui/icon/FvIcons';
import { PaddedSkeleton } from 'client/ui/skeleton/PaddedSkeleton';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { IHighlightedNotesSearch } from '../../objects/highlighted/IHighlightedNotesSearch';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { convertHighlightedDossierToListElement } from './convert/convertHighlightedDossierToListElement';
import { WidgetHighlightedDossiersProfile } from './WidgetHighlightedDossiersProfile';
export interface IWidgetHighlightedDossiersProps
  extends IWidgetComponentToolkitProps {}

const DossiersPageSize = 50;
const DossiersPageStart = 0;

/**
 * Fascicoli in evidenza
 */
export function WidgetHighlightedDossiers(
  props: IWidgetHighlightedDossiersProps
) {
  const { widget } = props;
  const [search, setSearch] = useState<IHighlightedNotesSearch>({});
  const data = widget.profile?.data as WidgetHighlightedDossiersProfile | null;

  const dossiersResult = useGetDocumentaryDossiersSearch(
    {
      ids: data?.dossiers?.map(d => d.id!),
      pageNumber: DossiersPageStart,
      pageSize: DossiersPageSize,
      widgetId: widget.id!
    },
    {
      query: {
        enabled: !!data?.dossiers?.length,
        keepPreviousData: true
      }
    }
  );

  const dossiers = useMemo<IWidgetListElement[]>(() => {
    if (!dossiersResult.data) return [];

    return dossiersResult.data
      ?.map(dossier => {
        const localDossier = data?.dossiers?.find(d => d.id === dossier.id);
        if (!localDossier) return null;

        return convertHighlightedDossierToListElement(
          dossier,
          localDossier,
          widget
        );
      })
      .filter((a): a is IWidgetListElement => a != null);
  }, [dossiersResult.data, widget]);

  const filteredDossiers = useMemo<IWidgetListElement[]>(() => {
    if (!search.text) return dossiers;
    const searchText = search.text!.toLowerCase();

    return dossiers.filter(d =>
      (d.annotations as string)?.toLowerCase().includes(searchText)
    );
  }, [search.text, dossiers]);

  const highlightedObject = WidgetToolkitObject.from(widget);
  useRegisterToolkitObject(highlightedObject);

  useWidgetNetworkQueries([dossiersResult]);

  return (
    <WidgetCardBody
      top={
        <FilterWrapper>
          <Search
            allowClear
            placeholder="Cerca tra le note..."
            enterButton
            onSearch={text => setSearch(s => ({ ...s, text }))}
          />
        </FilterWrapper>
      }
      bottom={<ToolkitObjectBottomZone object={highlightedObject} />}
    >
      <PaddedSkeleton active loading={dossiersResult.isLoading}>
        <WidgetList
          list={filteredDossiers}
          emptyMessage="Nessun fascicolo in evidenza"
        />
      </PaddedSkeleton>
    </WidgetCardBody>
  );
}

import Search from 'antd/lib/input/Search';
import { useGetDocumentaryDocumentsSearchByIds } from 'client/api/backend/documentary-documents/documentary-documents';
import { WidgetCardBody } from 'client/components/schema/widget/card/body/WidgetCardBody';
import { useWidgetNetworkQueries } from 'client/components/schema/widget/context/useWidgetNetworkEffect';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import { WidgetList } from 'client/components/toolkit/components/list/WidgetList';
import { useRegisterToolkitObject } from 'client/components/toolkit/objects/store/useToolkitObjectStore';
import { ToolkitObjectBottomZone } from 'client/components/toolkit/objects/ToolkitObjectBottomZone';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { FilterWrapper } from 'client/ui/filter/FilterWrapper';
import { PaddedSkeleton } from 'client/ui/skeleton/PaddedSkeleton';
import React, { useMemo, useState } from 'react';
import { DocumentSearchMode } from '../../objects/documents/DocumentSearchMode';
import { IHighlightedNotesSearch } from '../../objects/highlighted/IHighlightedNotesSearch';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';
import { convertHighlightedDocumentToWidgetListElement } from './convert/convertHighlightedDocumentToListElement';
import { WidgetHighlightedDocumentsProfile } from './WidgetHighlightedDocumentsProfile';

export interface IWidgetHighlightedDocumentsProps
  extends IWidgetComponentToolkitProps {}

// Al momento la paginazione non funziona lato BE se la ricerca è filtrata per id
const DocumentsPageSize = 50;
const DocumentsPageStart = 0;

/**
 * Documenti in evidenza
 */
export function WidgetHighlightedDocuments(
  props: IWidgetHighlightedDocumentsProps
) {
  const { widget } = props;
  const [search, setSearch] = useState<IHighlightedNotesSearch>({});
  const data = widget.profile?.data as WidgetHighlightedDocumentsProfile | null;

  const documentsResult = useGetDocumentaryDocumentsSearchByIds(
    {
      ids: data?.documents?.map(d => d.id!) ?? [],
      widgetId: widget.id!
    },
    {
      query: {
        enabled: !!data?.documents?.length,
        keepPreviousData: true
      }
    }
  );

  const documents = useMemo<IWidgetListElement[]>(() => {
    if (!documentsResult.data) return [];

    return documentsResult.data
      ?.map(document => {
        const localDocument = data?.documents.find(d => d.id === document.id);
        if (!localDocument) return null;

        return convertHighlightedDocumentToWidgetListElement(
          document,
          localDocument!,
          widget
        );
      })
      .filter((a): a is IWidgetListElement => a != null);
  }, [documentsResult.data, widget]);

  const filteredDocuments = useMemo<IWidgetListElement[]>(() => {
    if (!search.text) return documents;
    const searchText = search.text!.toLowerCase();

    return documents.filter(d =>
      (d.annotations as string)?.toLowerCase().includes(searchText)
    );
  }, [search.text, documents]);

  const highlightedObject = WidgetToolkitObject.from(widget);
  useRegisterToolkitObject(highlightedObject);

  useWidgetNetworkQueries([documentsResult]);

  return (
    <WidgetCardBody
      top={
        <FilterWrapper>
          <Search
            allowClear
            placeholder="Cerca tra le note..."
            enterButton
            onSearch={text => setSearch(s => ({ ...s, text }))}
          />
        </FilterWrapper>
      }
      bottom={<ToolkitObjectBottomZone object={highlightedObject} />}
    >
      <PaddedSkeleton active loading={documentsResult.isLoading}>
        <WidgetList
          list={filteredDocuments}
          emptyMessage="Nessun documento in evidenza"
        />
      </PaddedSkeleton>
    </WidgetCardBody>
  );
}

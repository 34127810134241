export enum AuthMethod {
  Siteminder = 'siteminder',
  Azure = 'azure',
  OpenIDConnect = 'openidconnect',
  Iam = 'iam',
  Sso = 'sso'
}

/**
 * Per Azure l'SSO è gestito da un'interfaccia separata, lato frontend,
 * tramite @msal/browser.
 */
export const AutoLoginAuthMethods = [
  AuthMethod.Siteminder,
  AuthMethod.Iam,
  AuthMethod.Sso
];

import * as React from 'react';
import { PageLayout } from 'client/ui/layout/PageLayout';
import { RightSideMenu } from '../../routes/menu/right/RightSideMenu';
import Sider from 'antd/lib/layout/Sider';
import { Layout, Typography } from 'antd';
const { Title } = Typography;
import { Content } from 'antd/lib/layout/layout';
import styled from 'styled-components';

const WrapperLayout = styled(Layout)`
  margin: 6px 24px 24px 24px;
  border-radius: ${props => props.theme.cardRadius};
  background-color: ${props => props.theme.componentBackground};
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05), 0 5px 20px 0 rgba(0, 0, 0, 0.04);
`;

const WrapperContent = styled(Content)`
  /* padding: 24px; */
  position: relative;
  display: flex;
  flex-direction: column;
`;

const PageTitle = styled(Title)`
  &&& {
    font-weight: 300;
    margin-bottom: 0;
  }
`;

export interface AdminLayoutProps {
  children: React.ReactNode;
  menu: React.ReactNode;
}

export function AdminLayout(props: AdminLayoutProps) {
  return (
    <PageLayout
      mainMenu={<PageTitle level={3}>Amministrazione</PageTitle>}
      rightMenu={<RightSideMenu />}
    >
      <WrapperLayout>
        <Sider width={150}>{props.menu}</Sider>
        <WrapperContent>{props.children}</WrapperContent>
      </WrapperLayout>
    </PageLayout>
  );
}

import { registerToolkitObjectType } from 'client/components/toolkit/objects/registerToolkitObjectType';
import { IAssignmentModelItem } from './AssignmentModelToolkitObject';

export interface IExtendedAssignmentModelItem extends IAssignmentModelItem {
  modelUuid: string;
  index: number;
}

export const AssignmentModelItemToolkitObject =
  registerToolkitObjectType<IExtendedAssignmentModelItem>(
    'assignment-model-item'
  );

import { Widget } from 'client/api/backend/schemas';
import { useGetWidgets } from 'client/api/backend/widgets/widgets';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { omit } from 'lodash';
import { useMemo } from 'react';
import { WidgetMarshaller } from './WidgetMarshaller';

function filterWidgetsByDesk(
  widgets: Widget[] | undefined,
  deskId?: number,
  userId?: number
) {
  if (!deskId || !userId) return [];
  return (widgets ?? []).filter(w => w.panelId === deskId);
}

/**
 * Ottiene l'elenco dei Widget ed effettua il parsing.
 */
export function useWidgetsQuery(deskId?: number) {
  const allWidgets = useGetWidgets();
  const currentUser = useCurrentUser();
  const widgets = useMemo(
    () =>
      filterWidgetsByDesk(allWidgets.data, deskId, currentUser?.id).map(
        WidgetMarshaller.unmarshall
      ),
    [deskId, allWidgets.data]
  );

  return {
    widgets,
    // Blocchiamo l'accesso ai dati grezzi
    ...omit(allWidgets, ['data'])
  };
}

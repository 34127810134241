import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import clx from 'classnames';
import { DeskButton } from './detail/DeskButton';
import { IoEllipse } from 'react-icons/io5';
import { Button, Dropdown, Menu, Space } from 'antd';
import { DeskActions, useDesk } from 'client/components/schema/desk/DeskModule';
import { DownOutlined, PlusOutlined } from '@ant-design/icons';
import { DeskEditButtonDrawer } from './edit/DeskEditButtonDrawer';
import { useCurrentUser } from 'client/components/auth/AuthModule';
import { useDispatch } from 'react-redux';
import { useDesksQuery } from 'client/components/schema/desk/logic/useDesksQuery';
import { useDeskMutations } from 'client/components/schema/desk/logic/useDeskMutations';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';
import { DeskButtonStyle } from './detail/DeskButtonStyle';
import { IconDeskMenu } from './detail/IconDeskMenu';
import { getHexColorById } from 'client/components/schema/colors/Colors';

const DeskMenuWrapper = styled.div`
  display: block;
  width: 100%;
  line-height: 1.3;
`;
const AddDeskButton = styled(Button)`
  line-height: 1.4;
`;

const HiddenTextPlusIcon = styled.span`
  display: none;
  .ant-menu-submenu.ant-menu-submenu-popup > .ant-menu & {
    display: inline;
    font-size: 14px;
    margin-left: 4px;
  }
`;

const MenuStyled = styled(Menu)`
  border-bottom: none;
  background-color: transparent;

  &.ant-menu-horizontal:not(.ant-menu-dark) {
    > .ant-menu-item:hover::after,
    > .ant-menu-item-selected::after,
    > .ant-menu-item-active::after,
    > .ant-menu-item-open::after,
    > .ant-menu-item-selected::after,
    > .ant-menu-submenu:hover::after,
    > .ant-menu-submenu-active::after,
    > .ant-menu-submenu-open::after,
    > .ant-menu-submenu-selected::after {
      display: none;
    }
    > .ant-menu-item,
    > .ant-menu-submenu {
      padding: 0 4px;
    }

    // Stile per icona ellipsis (...) per altri menu non visualizzati
    .ant-menu-overflow-item-rest {
      .ant-menu-submenu-title {
        .anticon.anticon-ellipsis {
          font-size: 16px;
          padding: 6px;
          border-radius: 16px;
          border: 1px solid transparent;
          transition: all 0.2s;
          :hover {
            border: 1px solid ${({ theme }) => theme.primaryColor};
            opacity: 0.8;
          }
        }
      }
    }
  }
`;
const MenuItemStyled = styled(Menu.Item)`
  background-color: transparent;
  &.selected {
    .desk-menu-dropdown,
    .ant-menu-submenu.ant-menu-submenu-popup & {
      background-color: #e6f7ff; // #f0f8ff;
    }
  }
`;

export interface DesksMenuProps {}

export function DesksMenu(props: DesksMenuProps) {
  const deskState = useDesk();
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();

  const desks = useDesksQuery();
  const deskMutations = useDeskMutations();
  const isMobile = useBreakpoint(down('sm'));

  /**
   * Aggiungiamo una nuova scrivania con un nome di default e la selezioniamo.
   */
  // TODO Aggiungere un colore randomico di default?
  const handleAddDesk = useCallback(async () => {
    const result = await deskMutations.create({
      name: 'Nuova Scrivania ',
      userId: currentUser?.id,
      profile: {}
    });

    if (result instanceof Object) {
      dispatch(DeskActions.select(result.id!));
    }
  }, [deskMutations.create]);

  /**
   * Aggiungiamo una scrivania se non ce ne sono.
   */
  useEffect(() => {
    if (desks.status === 'success' && desks.panels?.length === 0) {
      handleAddDesk();

      // Entriamo in modalità edit per mostrare il "+" (solo se non siamo
      // in modalità mobile)
      if (!isMobile) {
        dispatch(DeskActions.editable(true));
      }
    }
  }, [desks.panels]);

  const deskListMenu = desks.panels?.map(desk => (
    <MenuItemStyled
      key={desk.id}
      className={clx({
        selected: desk.id === deskState.selectedId
      })}
    >
      <DeskButton desk={desk} isMobile={isMobile} />
      <DeskEditButtonDrawer desk={desk} />
    </MenuItemStyled>
  ));

  const currentDesk = desks.panels?.find(
    desk => desk.id === deskState.selectedId
  );

  return (
    <DeskMenuWrapper>
      {isMobile ? (
        <Dropdown
          overlayClassName="desk-menu-dropdown"
          overlay={<Menu selectable={false}>{deskListMenu}</Menu>}
        >
          <DeskButtonStyle
            shape="round"
            type="ghost"
            icon={
              <IconDeskMenu
                icon={<IoEllipse />}
                color={getHexColorById(currentDesk?.profile?.colorId)}
              />
            }
          >
            <Space style={{ paddingLeft: '8px' }}>
              {currentDesk?.name || 'Seleziona una scrivania'}
              <DownOutlined />
            </Space>
          </DeskButtonStyle>
        </Dropdown>
      ) : (
        <MenuStyled mode="horizontal" selectable={false}>
          {deskListMenu}
          {deskState.isEditable && (
            <MenuItemStyled>
              <AddDeskButton
                key="edit"
                shape="circle"
                icon={
                  <span>
                    <PlusOutlined />
                    <HiddenTextPlusIcon>Aggiungi Scrivania</HiddenTextPlusIcon>
                  </span>
                }
                loading={deskMutations.createState.isLoading}
                onClick={handleAddDesk}
              />
            </MenuItemStyled>
          )}
        </MenuStyled>
      )}
    </DeskMenuWrapper>
  );
}

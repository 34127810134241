import { useDndMonitor, useDroppable } from '@dnd-kit/core';
import { Input } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { useDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { ActionArrowLine } from 'client/components/schema/widget/actions/message-hub/message/ActionArrowLine';
import { ActionDropIcon } from 'client/components/schema/widget/actions/message-hub/message/ActionDropIcon';
import { SelectInputProps } from 'client/ui/form/input/SelectInput';
import { useField } from 'formik';
import * as React from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import clx from 'classnames';
import { v4 as uuid } from 'uuid';
import { ToolkitObjectType } from '../registerToolkitObjectType';
import {
  DraggedToolkitObject,
  getToolkitObjectOfType,
  ToolkitObject
} from '../ToolkitObject';
import { useToolkitObjectInputSearch } from './useToolkitObjectInputSearch';
import { ToolkitActionArgument } from '../../actions/ToolkitAction';
import { ToolkitObjectStore } from '../store/useToolkitObjectStore';
import {
  createToolkitObjectSelectOption as createOption,
  toolkitObjectOptionValue
} from './createToolkitObjectSelectOption';
import { $GroupWrapper } from './ToolkitObjectInput.styles';
import { ToolkitObjectSelectInput } from './ToolkitObjectSelectInput';

export interface IToolkitObjectInputProps extends SelectInputProps<any> {
  objectType: ToolkitObjectType | ToolkitObjectType[];
  allows?: ToolkitActionArgument['allows'];
  widgetId?: number;
  showArrow?: boolean;
  fetchObjectParams?: Record<string, any>;
}

/**
 * Componente per gestire l'input come select o come drag&drop
 */
export function ToolkitObjectInput(props: IToolkitObjectInputProps) {
  const { objectType, ...otherProps } = props;

  const inputId = useRef(uuid());

  // Supportiamo il drop su questo elemento. Per comodità usiamo `useDndMonitor`
  // in modo da avere l'handler in questo stesso componente; inoltre, per
  // evitare conflitti con `handleDrop` in `DeskSpace`, impostiamo `isInput` a true.
  // TODO: Definire un'interfaccia standard per i data di `useDroppable`
  const {
    isOver,
    active,
    setNodeRef: setDroppableRef
  } = useDroppable({
    id: inputId.current,
    data: {
      isInput: true
    }
  });

  const objectTypes = useMemo(
    () => (Array.isArray(objectType) ? objectType : [objectType]),
    [objectType]
  );

  const isDragOverValid = isValidObjectForInput(
    active?.data?.current?.object,
    objectTypes
  );

  const { objectStore } = useDeskContext();

  return (
    <ToolkitBaseObjectInputMemoized
      {...otherProps}
      isOver={isOver}
      isDragOverValid={isDragOverValid}
      objectStoreState={objectStore.state}
      setDroppableRef={setDroppableRef}
      objectType={objectType}
      inputId={inputId}
      objectTypes={objectTypes}
    />
  );
}

const ToolkitBaseObjectInputMemoized = React.memo(ToolkitBaseObjectInput);

interface IToolkitObjectContextInputProps extends IToolkitObjectInputProps {
  isOver: boolean;
  isDragOverValid: boolean;
  setDroppableRef: (ref: HTMLElement | null) => void;
  objectStoreState: ToolkitObjectStore;
  inputId: React.MutableRefObject<string>;
  objectTypes: ToolkitObjectType[];
}

/**
 * Versione pre-memorizzata di `ToolkitBaseObjectInput`, per evitare re-render
 * inutili quando cambia lo stato di `objectStore` o `isOver`.
 */
export function ToolkitBaseObjectInput(props: IToolkitObjectContextInputProps) {
  const {
    objectType,
    allows,
    widgetId,
    showArrow,
    fetchObjectParams,
    isOver,
    isDragOverValid,
    setDroppableRef,
    inputId,
    objectTypes,
    objectStoreState,
    ...otherProps
  } = props;

  const isMultiple = props.mode === 'multiple';
  const [field, meta, helpers] = useField(props.name);

  // Ricerca, sia in locale che tramite `fetchObjects`
  const [search, setSearch] = useState(undefined as string | undefined);

  useDndMonitor({
    onDragEnd(event) {
      if (event.over?.id !== inputId.current) return;
      const data = event.active.data.current as DraggedToolkitObject;
      const over = getToolkitObjectOfType(data.object, objectTypes);
      if (over == null) {
        console.log("impossibile usare l'oggetto", data);
        return;
      }
      helpers.setValue(isMultiple ? [...(field.value ?? []), over] : over);
    }
  });

  // L'impostazione gestisce sia `value` singoli che multipli. Per i
  // ToolkitObject, salviamo nel Formik l'intero oggetto, non solo l'ID,
  // per semplificarne la gestione nelle callback (ad esempio `onConfirm`) delle
  // action.
  const handleChange = useCallback((value: SelectValue, option: any) => {
    // console.log('fieldvalue', field.value, value, option);
    helpers.setValue(
      isMultiple
        ? (option ?? []).map((o: any) => o.object)
        : option.object ?? null
    );
  }, []);

  // Opzioni in locale
  const allOptions = useMemo(
    () =>
      objectTypes.flatMap(
        type =>
          objectStoreState[type.code]
            ?.map(o => o.object)
            .filter(o => allows?.(o) ?? true)
            .map(createOption) ?? []
      ),
    [objectStoreState, objectTypes, allows]
  );

  let options = useMemo(
    () =>
      allOptions.filter(o =>
        o.title.toLowerCase().includes((search ?? '').toLowerCase())
      ),
    [search]
  );

  // Opzioni lato server
  const serverSearch = useToolkitObjectInputSearch(objectTypes, {
    widgetId: widgetId!,
    search,
    params: fetchObjectParams
  });
  const { searchResults, searchableTypes } = serverSearch;

  const searchOptions = useMemo(
    () => searchResults.flatMap(({ objects }) => objects.map(createOption)),
    [searchResults]
  );

  // Gestione dei valori multipli
  const value = isMultiple
    ? (field.value ?? []).map((o: ToolkitObject) => toolkitObjectOptionValue(o))
    : toolkitObjectOptionValue(field.value);

  const fieldValue = field.value as
    | ToolkitObject
    | ToolkitObject[]
    | null
    | undefined;

  // Per le opzioni singole e multiple, aggiungiamo l'opzione se non c'è fra le
  // opzioni ritornate dal Server o dagli altri Widget
  if (fieldValue) {
    const existingValues = Array.isArray(fieldValue)
      ? fieldValue
      : [fieldValue];

    // TODO Utilizzare un `Set` per velocizzare il controllo
    for (const existingValue of existingValues) {
      const existingValueId = toolkitObjectOptionValue(existingValue);
      if (
        !options.some(o => o.value === existingValueId) &&
        !searchOptions.some(o => o.value === existingValueId)
      ) {
        options.push(createOption(existingValue));
      }
    }
  }

  const IconWidth = 40;
  const selectStyle = useMemo(() => {
    const fixedWidth = showArrow ? IconWidth * 2 - 2 : IconWidth - 1;
    return { width: `calc(100% - ${fixedWidth}px)` };
  }, [showArrow]);

  return (
    <div ref={setDroppableRef}>
      <$GroupWrapper
        className={clx({
          'toolkit-object-input-active': isOver,
          'toolkit-object-input-invalid': isOver && !isDragOverValid
        })}
      >
        <Input.Group compact style={{ display: 'flex' }}>
          {props.showArrow && (
            <ActionArrowLine style={{ width: IconWidth }} key={'arrow'} />
          )}
          <ToolkitObjectSelectInput
            allowClear
            showSearch
            onSearch={setSearch}
            filterOption={false}
            loading={serverSearch.loading}
            // optionFilterProp="title"
            onChange={handleChange}
            value={value}
            {...otherProps}
            style={selectStyle}
            // x Memoized
            options={options}
            searchableTypes={searchableTypes}
            searchOptions={searchOptions}
          />
          <ActionDropIcon style={{ width: IconWidth }} />
        </Input.Group>
      </$GroupWrapper>
    </div>
  );
}

function isValidObjectForInput(
  draggedObject: ToolkitObject | null,
  objectTypes: ToolkitObjectType[]
) {
  const over = getToolkitObjectOfType(draggedObject, objectTypes);
  return over != null;
}

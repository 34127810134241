import { DeskWidgetsLogic } from 'client/components/schema/desk/logic/DeskWidgetsLogic';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { WidgetToolkitObject } from 'client/components/toolkit/objects/WidgetToolkitObject';
import { ActivityToolkitObject } from 'client/components/widget-repository/objects/activities/ActivityToolkitObject';
import { set } from 'client/core/data/set';
import { FvIconFileView } from 'client/ui/icon/FvIcons';
import React from 'react';
import { WidgetActivityViewerType } from '../WidgetActivityViewer.type';
import { WidgetActivityViewerProfile } from '../WidgetActivityViewerProfile';

export const WidgetActionOpenActivity = createToolkitAction({
  name: 'Apri Atto',
  code: 'open-activity',
  icon: <FvIconFileView />,
  showInBar: true,
  arguments: [
    {
      type: [ActivityToolkitObject],
      name: 'Atto da Aprire'
    },
    {
      type: [WidgetToolkitObject],
      allows: (object: ToolkitObject) =>
        WidgetToolkitObject.matches(object, WidgetActivityViewerType),
      name: 'Widget Visualizza Atto'
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    object: ToolkitObject,
    targetObject: ToolkitObject | null
  ) {
    const activity = ActivityToolkitObject.get(object)!;
    const target = WidgetToolkitObject.get(targetObject);

    const data: WidgetActivityViewerProfile = {
      activityId: activity.id as number,
      activityCode: activity.data.code!,
      documentId: activity.data.documentId!,
      activitySubject: activity.data.subject
    };

    if (target) {
      const widget = ctx.deskContext.widgets.find(w => w.id === target.id)!;
      await ctx.deskContext.updateWidget(set(widget, 'profile.data', data));
      return;
    }

    await ctx.deskContext.cloneWidget(
      {
        type: 'floating',
        expanded: true,
        order: DeskWidgetsLogic.getFloatingNextOrder(
          ctx.deskContext.floatingWidgets
        )
      },
      WidgetActivityViewerType,
      ctx.sourceWidget!,
      {
        data,
        quickClose: true,
        initializeInFullScreen:
          ctx.sourceWidget?.profile?.showChildWidgetInFullScreen ?? false
      }
    );
  }
});

import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import clx from 'classnames';
import { ArrowRightOutlined, DragOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
  border: solid 1px ${props => props.theme.inputBorderColor};
  border-radius: 0 4px 4px 0;
  /* background-color: ${props => props.theme.borderColorSplit}; */
  font-size: 20px;
  color: ${props => props.theme.inputPlaceholderColor};
`;

export interface ActionDropIconProps {
  hide?: boolean;
  style?: React.CSSProperties;
}

/**
 * Icona con messagggio tooltip per drag and drop
 */
export function ActionDropIcon(props: ActionDropIconProps) {
  if (props.hide) return null;

  return (
    <div {...props}>
      <Tooltip
        title="Trascina l'elemento nell'area per aggiungerlo"
        placement="topRight"
      >
        <IconBox>
          <DragOutlined />
        </IconBox>
      </Tooltip>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import {
  SelectInput,
  SelectInputProps,
  SelectOption
} from 'client/ui/form/input/SelectInput';
import { useGetDocumentaryDocumentsSendMeans } from 'client/api/backend/documentary-documents/documentary-documents';
import { useFormikFieldChange } from 'client/ui/form/hooks/useFormikFieldChange';

interface Props extends SelectInputProps<any> {
  widgetId: number;
  /** Modalità di default. Se non specifica è "" (tutte) */
  modeValue?: string;
  /** Rende dipendente la modalità da un altro campo */
  modeFieldName?: string;
}

export function CorrespondentTransportInput(props: Props) {
  const { widgetId, modeValue, ...otherProps } = props;
  const [transportMode, setTransportMode] = useState(modeValue ?? '');
  const transportsResult = useGetDocumentaryDocumentsSendMeans({
    mode: transportMode,
    widgetId: props.widgetId
  });

  // TODO Al momento ignoriamo la modalità di trasporto perché differisce da UI folium
  // useFormikFieldChange(
  //   (formik, [mode]) => {
  //     setTransportMode(mode);
  //   },
  //   [props.modeFieldName]
  // );

  return (
    <SelectInput
      allowClear
      showSearch
      optionFilterProp="label"
      loading={transportsResult.isLoading}
      options={transportsResult.data?.map(mode => ({
        label: mode.name!,
        value: Number(mode.id!)
      }))}
      {...otherProps}
    />
  );
}

/**
 * Generato automaticamente.
 * DeskUI REST API
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  StreamingResponseBody,
  GetDocumentaryStreamsAttachmentsIdParams,
  GetDocumentaryStreamsDocumentsIdParams
} from '.././schemas';
import {
  apiInstance,
  ErrorType
} from '../../../core/network/mutators/apiClient';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Allegato del documento in streaming
 */
export const getDocumentaryStreamsAttachmentsId = (
  id: number,
  params?: GetDocumentaryStreamsAttachmentsIdParams
) => {
  return apiInstance<StreamingResponseBody>({
    url: `/api/v1/documentary/streams/attachments/${id}`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryStreamsAttachmentsIdQueryKey = (
  id: number,
  params?: GetDocumentaryStreamsAttachmentsIdParams
) => [
  `/api/v1/documentary/streams/attachments/${id}`,
  ...(params ? [params] : [])
];

export type GetDocumentaryStreamsAttachmentsIdQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryStreamsAttachmentsId>
>;
export type GetDocumentaryStreamsAttachmentsIdQueryError = ErrorType<void>;

export const useGetDocumentaryStreamsAttachmentsId = <
  TData = AsyncReturnType<typeof getDocumentaryStreamsAttachmentsId>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetDocumentaryStreamsAttachmentsIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryStreamsAttachmentsId>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryStreamsAttachmentsIdQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryStreamsAttachmentsId>
  > = () => getDocumentaryStreamsAttachmentsId(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryStreamsAttachmentsId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna il documento identificato in streaming
 */
export const getDocumentaryStreamsDocumentsId = (
  id: number,
  params?: GetDocumentaryStreamsDocumentsIdParams
) => {
  return apiInstance<StreamingResponseBody>({
    url: `/api/v1/documentary/streams/documents/${id}`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryStreamsDocumentsIdQueryKey = (
  id: number,
  params?: GetDocumentaryStreamsDocumentsIdParams
) => [
  `/api/v1/documentary/streams/documents/${id}`,
  ...(params ? [params] : [])
];

export type GetDocumentaryStreamsDocumentsIdQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryStreamsDocumentsId>
>;
export type GetDocumentaryStreamsDocumentsIdQueryError = ErrorType<void>;

export const useGetDocumentaryStreamsDocumentsId = <
  TData = AsyncReturnType<typeof getDocumentaryStreamsDocumentsId>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetDocumentaryStreamsDocumentsIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryStreamsDocumentsId>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryStreamsDocumentsIdQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryStreamsDocumentsId>
  > = () => getDocumentaryStreamsDocumentsId(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryStreamsDocumentsId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

import React from 'react';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import { useGetDocumentaryAssignmentsFlows } from 'client/api/backend/documentary-assignments/documentary-assignments';

interface Props extends SelectInputProps<any> {
  widgetId: number;
  /** Limita i tipi di elementi selezionabili */
  allowedTypes?: string[] | null;
}

export function AssignmentTypeSelectInput(props: Props) {
  const { widgetId, allowedTypes, ...otherProps } = props;

  const types = useGetDocumentaryAssignmentsFlows({
    widgetId: props.widgetId
  });

  const options =
    types.data
      ?.map(t => ({ label: t.label, value: t.value! }))
      .filter(t => allowedTypes?.includes(t.value) || allowedTypes == null) ??
    [];

  return (
    <SelectInput allowClear showSearch options={options} {...otherProps} />
  );
}

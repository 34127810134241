import React from 'react';
import styled from 'styled-components';

// Importato in Index.tsx
const FontPreloadDiv = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 0;
  opacity: 0;
`;

const Roboto300 = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-style: normal;
`;
const Roboto300Italic = styled(Roboto300)`
  font-style: italic;
`;
const Roboto400 = styled(Roboto300)`
  font-weight: 400;
`;
const Roboto400Italic = styled(Roboto400)`
  font-style: italic;
`;
const Roboto500 = styled(Roboto300)`
  font-weight: 500;
`;
const Roboto500Italic = styled(Roboto500)`
  font-style: italic;
`;
const Roboto700 = styled(Roboto300)`
  font-weight: 700;
`;
const Roboto700Italic = styled(Roboto700)`
  font-style: italic;
`;

export interface FontPreloadProps {}

/**
 * Permette il pre caricamento dei font per evitare il flash di caratteri
 */
export function FontPreload(props: FontPreloadProps) {
  return (
    <FontPreloadDiv>
      <Roboto300 />
      <Roboto300Italic />
      <Roboto400 />
      <Roboto400Italic />
      <Roboto500 />
      <Roboto500Italic />
      <Roboto700 />
      <Roboto700Italic />
    </FontPreloadDiv>
  );
}

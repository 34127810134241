import React from 'react';

/**
 * Ottiene il messaggio di un errore avvenuto a livello di network.
 */
export function getNetworkErrorMessage(err: any, defaultMessage?: string) {
  if (err.response && err.response.data && err.response.data) {
    if (err.response.data.apierror) {
      return (
        <span>
          Si è verificato un errore interno del server Folium.{' '}
          {defaultMessage ?? ''}
          <br />
          {getFoliumNetworkMessage(err)}
        </span>
      );
    }

    if (err.response.data.error) {
      return (
        <span>
          Si è verificato un errore interno del server. {defaultMessage ?? ''}
          <br />
          {err.response.data.error}
        </span>
      );
    }
  }

  if (err.message) return err.message;

  return defaultMessage || err;
}

function getFoliumNetworkMessage(err: any) {
  const foliumMessage = err.response.data.apierror.message;

  if (typeof foliumMessage !== 'string' || foliumMessage.trim().length === 0) {
    return '';
  }

  if (foliumMessage.includes('FoliumDocumentary response:')) {
    return foliumMessage
      .replace('FoliumDocumentary response: {esito=', '')
      .replace('}', '');
  }

  return foliumMessage;
}

import clx from 'classnames';
import * as React from 'react';
import { Space, SpaceProps } from 'antd';
import styled from 'styled-components';

const StyledSpace = styled(Space)`
  margin-right: -4px;
  padding-left: 8px;
`;

export interface WidgetExtraProps extends Omit<SpaceProps, 'size'> {}

/**
 * Contenitore stilizzato per i pulsanti azione (icone) del Widget
 */
export function WidgetExtra(props: WidgetExtraProps) {
  return (
    <StyledSpace
      {...props}
      className={clx(props.className, 'no-drag')}
      size={0}
    />
  );
}

import React, { useCallback } from 'react';
import { Checkbox } from 'antd';
import { FormikValues, useField, useFormikContext } from 'formik';
import { CheckboxProps, CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useFormikFormContext } from '../FormikFormContext';

export interface CheckboxInputProps<T extends FormikValues>
  extends Omit<CheckboxProps, 'name'> {
  name: keyof T & string;
}

/**
 * Campo per input di tipo checkbox.
 */
export function CheckboxInput<T extends FormikValues>(
  props: CheckboxInputProps<T>
) {
  const { name } = props;
  const [field, meta] = useField<boolean>(name);
  const formik = useFormikContext<T>();
  const { disabled } = useFormikFormContext();
  const handleChange = useCallback(
    (e: CheckboxChangeEvent) => formik.setFieldValue(name, e.target.checked),
    [name]
  );

  return (
    <Checkbox
      {...props}
      {...field}
      disabled={props.disabled || disabled}
      checked={field.value}
      onChange={handleChange}
    />
  );
}

/**
 * Gestisce il rendering per i `FormField` in modalità non di modifica
 */
CheckboxInput.renderFieldValue = (
  value: boolean,
  props: CheckboxInputProps<any>
) => {
  return (
    <Checkbox className="checkbox-not-editable" {...props} checked={value} />
  );
};

import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { OfficeToolkitObject } from '../offices/OfficeToolkitObject';
import { UserToolkitObject } from '../users/UserToolkitObject';
import { IAssignmentModelItem } from './AssignmentModelToolkitObject';

export function toolkitObjectToAssignmentModelItem(
  object: ToolkitObject
): IAssignmentModelItem['assignee'] {
  const user = UserToolkitObject.get(object);
  if (user) {
    return {
      type: 'user',
      data: user.data
    };
  }

  const office = OfficeToolkitObject.get(object);
  if (office) {
    return {
      type: 'office',
      data: office.data
    };
  }

  throw new Error('Non riconosciuto. (W-AME-001)');
}

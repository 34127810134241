import { useGetDocumentaryDocumentsTypes } from 'client/api/backend/documentary-documents/documentary-documents';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import * as React from 'react';

interface IDocumentTypeInputProps extends SelectInputProps<any> {
  widgetId: number;
}

export function DocumentTypeInput(props: IDocumentTypeInputProps) {
  const { widgetId, ...otherProps } = props;
  const typesResult = useGetDocumentaryDocumentsTypes({
    widgetId: props.widgetId
  });

  return (
    <SelectInput
      allowClear
      showSearch
      loading={typesResult.isLoading}
      options={[
        { label: 'Documento generico', value: undefined as any },
        ...(typesResult.data?.map(mode => ({
          label: mode.label!,
          value: Number(mode.value!)
        })) ?? [])
      ]}
      {...otherProps}
    />
  );
}

import React, { useCallback, useMemo, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Drawer, List } from 'antd';
import { IWidgetType } from 'client/components/widget-repository/schema/WidgetType';
import { useWidgetRepository } from 'client/components/widget-repository/useWidgetRepository';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { DrawerTitle } from 'client/ui/drawer/DrawerTitle';
import { useDeskContext } from '../../context/DeskContext';
import { AddWidgetCard } from './element/AddWidgetCard';
import { FilterWrapper } from 'client/ui/filter/FilterWrapper';
import Search from 'antd/lib/input/Search';
import styled from 'styled-components';
import { IWidgetDrawerSearch } from './IWidgetDrawerSearch';

interface IAddWidgetDrawerProps {}

const DrawerFilterWrapper = styled(FilterWrapper)`
  height: auto;
`;

/**
 * Drawer per la scelta dei widget da aggiungere al desk
 */
export function AddWidgetDrawer(props: IAddWidgetDrawerProps) {
  const deskContext = useDeskContext();
  const { state, dispatch } = deskContext.addWidget;
  const [search, setSearch] = useState<IWidgetDrawerSearch>({});

  const repository = useWidgetRepository();

  const handleClose = useCallback(() => {
    setSearch({});
    dispatch({ type: 'close' });
  }, [dispatch]);

  const handleAdd = useCallback(
    async (type: IWidgetType) => {
      await deskContext.saveWidgetsLayout();
      await deskContext.createWidget(state.position!, type);
      dispatch({ type: 'close' });
    },
    [deskContext.createWidget, state, dispatch]
  );

  const widgetTypes = repository.selectableWidgetTypes;

  const filteredWidgetTypes = useMemo<IWidgetType[]>(() => {
    if (!search.text) return widgetTypes;
    const searchText = search.text!.toLowerCase();

    return widgetTypes.filter(w =>
      (w.name as string)?.toLowerCase().includes(searchText)
    );
  }, [search.text, widgetTypes]);

  return (
    <Drawer
      title={<DrawerTitle onClose={handleClose}>Aggiungi Widget</DrawerTitle>}
      closable={false}
      visible={state.visible}
      onClose={handleClose}
    >
      <DrawerFilterWrapper>
        <Search
          allowClear
          placeholder="Cerca..."
          enterButton
          onSearch={(text: string) => setSearch(s => ({ ...s, text }))}
        />
      </DrawerFilterWrapper>
      <List
        dataSource={filteredWidgetTypes}
        split={false}
        renderItem={widgetType => (
          <List.Item key={widgetType.code}>
            <AddWidgetCard
              widgetType={widgetType}
              onClick={() => handleAdd(widgetType)}
            />
          </List.Item>
        )}
      />
    </Drawer>
  );
}

import React from 'react';
import { Card, Button, Typography, Divider, message } from 'antd';
import { WidgetCard } from 'client/components/schema/widget/card/WidgetCard';
import styled from 'styled-components';
const { Title, Text } = Typography;

const BodyWrapper = styled.div`
  padding: 0 12px;
`;
const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 2em;
  margin-top: 1em;
`;

interface UnauthorizedPageProps {}

/**
 * Pagina di errore per utente non autenticato.
 */
export function UnauthorizedPage(props: UnauthorizedPageProps) {
  return (
    <WidgetCard>
      <BodyWrapper>
        <TitleWrapper>
          <Title level={3}>Attenzione</Title>
          <Text type="secondary">L'utente non è autenticato</Text>
        </TitleWrapper>
      </BodyWrapper>
    </WidgetCard>
  );
}

import { useGetPanelsId, usePutPanels } from 'client/api/backend/panels/panels';
import { IWidgetType } from 'client/components/widget-repository/schema/WidgetType';
import { queryClient } from 'client/core/network/queryClient';
import { cloneDeep, isEqual, omit, pick, sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Layout } from 'react-grid-layout';
import { useWidgetMutations } from '../../widget/logic/useWidgetMutations';
import { useWidgetPositionHandler } from '../../widget/logic/useWidgetPositionHandler';
import { useWidgetsQuery } from '../../widget/logic/useWidgetsQuery';
import { WidgetDisplayLogic } from '../../widget/logic/WidgetDisplayLogic';
import { useAddWidgetReducer } from '../space/add-widget/useAddWidgetReducer';
import { useDesk } from '../DeskModule';
import { createConstantLayout } from '../logic/createConstantLayout';
import { DeskWidgetsLogic } from '../logic/DeskWidgetsLogic';
import { useDeskMutations } from '../logic/useDeskMutations';
import { WidgetPosition } from '../../widget/schema/WidgetPosition';
import { WidgetProfile } from '../../widget/schema/WidgetProfile';
import { Widget } from 'client/api/backend/schemas';
import { useConfigureWidgetReducer } from '../../widget/configure/useConfigureWidgetReducer';
import { WidgetPositionHandler } from '../../widget/logic/WidgetPositionHandler';
import { createSelector } from 'reselect';
import { logger } from 'client/core/logger/logger';
import { notification } from 'antd';
import { useActionMessagesReducer } from '../../widget/actions/message-hub/useActionMessagesReducer';
import { useToolkitObjectStore } from 'client/components/toolkit/objects/store/useToolkitObjectStore';

/**
 * Il selettore dei Widget Grid ci permette di evitare re-rendering del
 * GridLayout se ci sono modifiche solo a Widget _floating_.
 *
 * Questa caratteristica è strettamente connessa al fatto che modifichiamo
 * la cache di react-query localmente, e non con un update dal server.
 * In quel caso (reload) vengono comunque rirenderizzati tutti i Widget.
 */
const gridWidgetsSelector = createSelector(
  (widgets: Widget[]) => DeskWidgetsLogic.getGridWidgets(widgets),
  (widgets: Widget[]) => widgets,
  {
    memoizeOptions: {
      equalityCheck: (a: Widget[], b: Widget[]) =>
        a.length === b.length && a.every((w, i) => b[i] === w)
    }
  }
);

const floatingWidgetsSelector = createSelector(
  (widgets: Widget[]) => DeskWidgetsLogic.getFloatingWidgets(widgets),
  (widgets: Widget[]) => widgets,
  {
    memoizeOptions: {
      equalityCheck: (a: Widget[], b: Widget[]) =>
        a.length === b.length && a.every((w, i) => b[i] === w)
    }
  }
);

/**
 * Hook fondamentale che gestisce le azioni sulla scrivania e sui suoi widget
 * a livello di `statefulness`.
 * Le operazioni sono disponibili nel Tree dei componenti grazie al `DeskContext`,
 * un context di react che espone le operazioni e i dati a tutti i componenti.
 */
export function useDeskHandler(deskId: number) {
  // Otteniamo la scrivania corrente...
  const current = useGetPanelsId(deskId!, {
    query: { enabled: deskId != null }
  });
  const desk = current.data;

  // ..e tutti i widget. Al momento non abbiamo un'API dedicata, quindi
  // li ritorniamo tutti.
  const { widgets: originalWidgets, isLoading } = useWidgetsQuery(deskId);
  const [widgets, setWidgets] = useState(() => cloneDeep(originalWidgets));
  useEffect(() => {
    setWidgets(cloneDeep(originalWidgets));
  }, [originalWidgets]);

  const widgetsPositionHandler = useWidgetPositionHandler();

  // Ottieniamo i widget floating e grid e li memoizziamo per valore.
  const gridWidgets = gridWidgetsSelector(widgets);
  const floatingWidgets = floatingWidgetsSelector(widgets);

  const widgetMutations = useWidgetMutations();

  /**
   * Salva il layout attuale
   */
  const saveWidgetsLayout = useCallback(async () => {
    const updated = widgets.filter(w => {
      const ow = originalWidgets.find(ow => ow.id === w.id);
      return !isEqual(ow?.profile?.position, w.profile?.position);
    });
    await Promise.all(updated.map(w => widgetMutations.update(w)));
    // await queryClient.invalidateQueries(getGetWidgetsQueryKey());
  }, [widgets, originalWidgets, widgetMutations.update]);

  /**
   * Creazione Widget
   */
  const createWidget = useCallback(
    async (
      position: WidgetPosition,
      type: IWidgetType,
      profile: Partial<WidgetProfile> = {}
    ) => {
      const widget = await widgetMutations.create({
        panelId: deskId,
        profile: {
          ...profile,
          filters: type.defaultFilters,
          position: omit(position, ['i', 'placeholder']) as any,
          typeCode: type.code
        }
      });
    },
    [widgetMutations.create, deskId]
  );

  const cloneWidget = useCallback(
    async (
      position: WidgetPosition,
      type: IWidgetType,
      cloned: Widget,
      profile: Partial<WidgetProfile> = {}
    ) => {
      const widget = (await widgetMutations.clone(cloned.id!, cloned.panelId!, {
        ...profile,
        filters: type.defaultFilters,
        position: omit(position, ['i', 'placeholder']) as any,
        typeCode: type.code
      })) as Widget;
      return widget;
    },
    [widgetMutations.create, deskId]
  );

  // Pulizia scrivania
  const removeAll = useCallback(async () => {
    await Promise.all(widgets.map(w => widgetMutations.remove(w)));
  }, [widgetMutations.remove, widgets]);

  /**
   * Rimozione widget
   */
  const remove = useCallback(
    async (widget: Widget) => {
      await widgetMutations.remove(widget);
    },
    [widgetMutations.remove, deskId]
  );

  // Gestione stato di aggiunta widget.
  const addWidget = useAddWidgetReducer();
  const configureWidget = useConfigureWidgetReducer();

  // Messaggi delle azioni
  const actionMessages = useActionMessagesReducer();

  // Store degli oggetti mostrati
  const objectStore = useToolkitObjectStore();

  return {
    desk,
    widgets,
    gridWidgets,
    floatingWidgets,
    setWidgets,
    saveWidgetsLayout,
    createWidget,
    cloneWidget,
    addWidget,
    configureWidget,
    removeAll,
    remove,
    actionMessages,
    objectStore,
    updateWidget: widgetMutations.update,
    isLoading
  };
}

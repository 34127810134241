import { DeskAssignment } from 'client/api/backend/schemas';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { DocumentToolkitObject } from '../documents/DocumentToolkitObject';
import { AssignmentToolkitObject } from './AssignmentToolkitObject';
import { AssignmentState } from './mapIdState';

/**
 * Controlla se l'assegnazione è scaduta, ovvero se ha cambiato stato
 */
export function isAssignmentStale(assignment: DeskAssignment): boolean {
  if (
    assignment != null &&
    assignment.endDate != null &&
    assignment.idState?.toString() !== AssignmentState.Conclusa
  )
    return true;

  return false;
}

import * as React from 'react';
import { Button, message, PageHeader } from 'antd';
import { AdminLayout } from '../../../ui/layout/AdminLayout';
import { AdminMenu } from '../menu/AdminMenu';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { RouteComponentProps } from 'react-router';
import { FormikForm } from 'client/ui/form/FormikForm';
import { Formik } from 'formik';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextInput } from 'client/ui/form/input/TextInput';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { FormButtonsRow } from 'client/ui/form/button/FormButtonsRow';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { queryClient } from 'client/core/network/queryClient';
import { useGetDocumentaries } from 'client/api/backend/documentaries/documentaries';
import { Role, RoleCreateDTO, WidgetType } from 'client/api/backend/schemas';
import {
  useDeleteRolesId,
  useGetRolesId,
  usePostRoles,
  usePutRoles,
  getGetRolesIdQueryKey,
  postRolesIdWidgets
} from 'client/api/backend/roles/roles';
import { AdminFormContainer } from 'client/ui/form-container/AdminFormContainer';
import { ContentWrapper } from 'client/ui/content-wrapper/ContentWrapper';
import { WidgetTypeSelectInput } from './WidgetTypeSelectInput';
import { isEqual } from 'lodash';
import { isRoleAdministrator } from 'client/components/auth/isRoleAdministrator';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

interface IExtendedRoleCreateDTO extends RoleCreateDTO {
  widgetTypes?: WidgetType[];
}
interface Params {
  id: string;
}

export interface ProfilePageProps extends RouteComponentProps<Params> {}

/**
 * Pagina dei Profili
 */
export function ProfilePage(props: ProfilePageProps) {
  useBreadcrumbItem({ path: '/admin/users', title: `Profili` });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: 'Dettaglio Profilo'
  });

  const id =
    props.match.params.id && props.match.params.id !== 'create'
      ? parseInt(props.match.params.id, 10)
      : null;

  const isEdit = id != null;

  const { data, error, isLoading } = useGetRolesId(id!, {
    query: { enabled: isEdit }
  });

  const profile: Maybe<IExtendedRoleCreateDTO> = !isEdit ? {} : data;

  const createMutation = usePostRoles();

  const updateMutation = usePutRoles();

  const deleteMutation = useDeleteRolesId();

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile accedere al profilo"
        error={error}
      />
    );
  }

  if (!profile) return <LoadingPage />;

  return (
    <AdminLayout menu={<AdminMenu />}>
      <PageHeader
        onBack={() => {
          props.history.push(`/admin/profiles`);
        }}
        title={!isEdit ? 'Nuovo Profilo' : `Dettaglio Profilo: ${profile.name}`}
        extra={[
          id && !isRoleAdministrator(id) && (
            <ButtonDeleteConfirm
              placement="left"
              title={
                <span>
                  Sei sicuro di voler eliminare il Profilo{' '}
                  <b>{profile.name ?? ''}</b>?
                </span>
              }
              cancelText="Annulla"
              onConfirm={async () => {
                try {
                  await deleteMutation.mutateAsync({
                    id: id
                  });
                  message.success('Profilo eliminato con successo. ');
                  props.history.push(`/admin/profiles`);
                } catch (e) {
                  message.error(
                    getNetworkErrorMessage(
                      e,
                      "Errore durante l'eliminazione del profilo. Riprovare."
                    )
                  );
                }
              }}
              buttonType="default"
            >
              <Button danger>Elimina Profilo</Button>
            </ButtonDeleteConfirm>
          )
        ]}
      />
      <ContentWrapper>
        <AdminFormContainer>
          <Formik<IExtendedRoleCreateDTO>
            initialValues={profile}
            onSubmit={async (values, formik) => {
              const { widgetTypes, ...roleValues } = values;
              try {
                let result: Role;

                if (!isEdit) {
                  result = (await createMutation.mutateAsync({
                    data: roleValues
                  })) as Role;

                  message.success('Profilo creato con successo.');
                  props.history.push(`/admin/profiles/${result.id}`);
                } else {
                  result = (await updateMutation.mutateAsync({
                    data: { id, ...roleValues }
                  })) as Role;
                  message.success('Profilo aggiornato con successo.');
                }

                try {
                  if (
                    widgetTypes &&
                    !isEqual(widgetTypes, profile.widgetTypes)
                  ) {
                    await postRolesIdWidgets(result.id!, {
                      widgetTypeIds: widgetTypes.map(wt => wt.id!)
                    });
                  }
                } catch (e) {
                  message.error(
                    getNetworkErrorMessage(
                      e,
                      "Si è verificato un errore durante l'aggiunta dei widget disponibili"
                    )
                  );
                  console.error(e);
                }

                queryClient.invalidateQueries(getGetRolesIdQueryKey(id!));
              } catch (e) {
                message.error(
                  getNetworkErrorMessage(
                    e,
                    `Si è verificato un errore durante la ${
                      isEdit ? 'modifica' : 'creazione'
                    } del profilo.`
                  )
                );
                console.error(e);
              }
            }}
          >
            <FormikForm editable layout="vertical">
              <FormFieldsContainer>
                <FormFieldItem
                  label="Nome"
                  placeholder="Nome"
                  name="name"
                  component={TextInput}
                  size="large"
                />
              </FormFieldsContainer>
              <FormFieldsContainer>
                <FormFieldItem
                  label="Widget Disponibili"
                  placeholder="Widget Disponibili"
                  name="widgetTypes"
                  component={WidgetTypeSelectInput}
                  size="large"
                />
              </FormFieldsContainer>
              <FormButtonsRow>
                <Button
                  size="large"
                  onClick={() => {
                    props.history.push(`/admin/profiles`);
                  }}
                >
                  Annulla
                </Button>
                <FormikSendButton as={Button} title="Salva" size="large">
                  Salva
                </FormikSendButton>
              </FormButtonsRow>
            </FormikForm>
          </Formik>
        </AdminFormContainer>
      </ContentWrapper>
    </AdminLayout>
  );
}

import React from 'react';
import { LoginForm } from '../../components/auth/login/LoginForm';
import { RouteComponentProps, Redirect } from 'react-router';
import { useAuth } from '../../components/auth/AuthModule';
import { logger } from 'client/core/logger/logger';
import { PageLayout } from 'client/ui/layout/PageLayout';
import styled from 'styled-components';
import { LoginPageGrid } from './detail/LoginPageGrid';
import { LoginWelcomeMessage } from './detail/LoginWelcomeMessage';
import { UnauthorizedPage } from 'client/components/auth/unauthorized/UnauthorizedPage';
import { AzureLoginForm } from 'client/components/auth/azure/AzureLoginForm';
import { appConfig } from 'client/core/appConfig';
import { AuthMethod } from 'client/components/auth/AuthMethods';
import qs from 'qs';
import { OpenIDConnectLoginForm } from 'client/components/auth/openidconnect/OpenIDConnectLoginForm';

interface Props extends RouteComponentProps {}

/**
 * Disponibile soltanto se l'utente non ha ancora effettuato il login.
 * In caso di successo, lo reindirizza alla pagina corretta.
 */
export const LoginPage = (props: Props) => {
  const { logged, isLogoutForced } = useAuth();

  // Calcolo l'origine
  const { from } = (props.location.state as any) || { from: { pathname: '/' } };

  // Se viene inserito nella query string il parametro `forceJwt`, allora
  // viene forzato il login con il metodo JWT "classico (non SSO)"
  const params = props.location.search
    ? qs.parse(props.location.search, { ignoreQueryPrefix: true })
    : {};

  logger.log(`Redirect utente dopo login: ->`, from);
  if (logged) return <Redirect to={from} />;

  if (isLogoutForced) {
    return (
      <PageLayout hideHeader>
        <LoginPageGrid message={<LoginWelcomeMessage />}>
          <UnauthorizedPage />
        </LoginPageGrid>
      </PageLayout>
    );
  }

  return (
    <PageLayout hideHeader>
      <LoginPageGrid message={<LoginWelcomeMessage />}>
        {appConfig.ssoMethod === AuthMethod.Azure && !params.forceJwt ? (
          <AzureLoginForm />
        ) : appConfig.ssoMethod === AuthMethod.OpenIDConnect &&
          !params.forceJwt ? (
          <OpenIDConnectLoginForm />
        ) : (
          <LoginForm />
        )}
      </LoginPageGrid>
    </PageLayout>
  );
};

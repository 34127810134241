import * as React from 'react';
import styled from 'styled-components';
import { Menu, MenuProps } from 'antd';

const StyledTabsSideMenu = styled(Menu)`
  width: 100%;
  &.ant-menu {
    border-right: none;
    li.ant-menu-item {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`;

export interface TabsSideMenuProps extends MenuProps {}
/**
 * Menu stilizzato per la WidgetTabs
 */
export function TabsSideMenu(props: TabsSideMenuProps) {
  return <StyledTabsSideMenu {...props} />;
}

import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import clx from 'classnames';

const fadeIn = keyframes`
  0%  {
    top: -380px;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }`;

const fadeEffect = css`
  animation-duration: 0.24s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
`;

const MessageAimationWrapper = styled.div`
  position: relative;
  width: 384px;
  max-width: calc(100vw - 48px);
  margin-bottom: 16px;
  /* padding: 16px; */
  overflow: hidden;
  line-height: 1.3;
  word-wrap: break-word;
  background: ${props => props.theme.componentBackground};
  box-shadow: ${props => props.theme.shadowBase};
  border-radius: ${props => props.theme.cardRadius};

  &.fade {
    ${fadeEffect};
    opacity: 0;
    animation-play-state: paused;
    &.active-enter {
      animation-name: ${fadeIn};
      animation-play-state: running;
    }
    &.active-exit {
      animation-duration: 0.2s;
      animation-name: ${fadeOut};
      animation-play-state: running;
    }
  }
`;

export interface ActionMessageAnimationProps {
  closeMessage?: boolean;
  children: React.ReactNode;
}
/**
 * Elemento modale con le Animazioni dei messaggi delle Actions
 */
export function ActionMessageAnimation(props: ActionMessageAnimationProps) {
  return (
    <MessageAimationWrapper
      className={clx('fade', {
        'active-enter': !props.closeMessage,
        'active-exit': props.closeMessage
      })}
    >
      {props.children}
    </MessageAimationWrapper>
  );
}

import { FvIconFileView } from 'client/ui/icon/FvIcons';
import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetActionCompleteActivity } from './actions/complete/WidgetActionCompleteActivity';
import { WidgetActionGiveOpinionActivity } from './actions/WidgetActionGiveOpinionActivity';
import { WidgetActionOpenActivity } from './actions/WidgetActionOpenActivity';
import { WidgetActionRequestChangesActivity } from './actions/WidgetActionRequestChangesActivity';
import { WidgetActionSignActivity } from './actions/WidgetActionSignActivity';
import { WidgetActivityViewer } from './WidgetActivityViewer';
import { WidgetActivityViewerProfile } from './WidgetActivityViewerProfile';

export const WidgetActivityViewerType: IWidgetType = {
  code: 'activity-viewer',
  name: 'Visualizzatore Atto',
  icon: <FvIconFileView />,
  component: WidgetActivityViewer,
  getTitle: widget => {
    const data = widget.profile?.data as
      | WidgetActivityViewerProfile
      | undefined;
    return data?.activitySubject;
  },
  description: `Apre un atto per la sua visualizzazione`,
  actions: [
    WidgetActionOpenActivity,
    WidgetActionSignActivity,
    WidgetActionCompleteActivity,
    WidgetActionGiveOpinionActivity,
    WidgetActionRequestChangesActivity
  ]
};

WidgetRepository.register(WidgetActivityViewerType);

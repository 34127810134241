import { SelectInputProps } from 'client/ui/form/input/SelectInput';
import React, { ReactComponentElement } from 'react';
import { getToolkitObjectOfType, ToolkitObject } from './ToolkitObject';

interface IToolkitObjectTypeOptions<T>
  extends Pick<ToolkitObjectType<T>, 'fetchObjects'> {}

/**
 * Parametri per la ricerca degli oggetti.
 */
export interface IToolkitObjectFetchOptions {
  widgetId: number;
  search?: string;
  params?: Record<string, any>;
}

export interface ToolkitObjectType<T = any> {
  code: string;
  /**
   * Crea un oggetto ToolkitObject di questo tipo.
   */
  create: (object: Omit<ToolkitObject<T>, 'type'>) => ToolkitObject<T>;
  /**
   * Ritorna all'interno dell'object l'elemento con il tipo esatto specificato,
   * anche se si trova nei `related`.
   */
  get: (object: ToolkitObject<any> | null) => ToolkitObject<T> | null;
  /**
   * Ritorna un array di object del tipo specificato.
   */
  getMany: (
    objects: ToolkitObject | ToolkitObject[] | null
  ) => ToolkitObject<T>[];
  /**
   * Ricerca degli oggetti
   */
  fetchObjects?: (
    options: IToolkitObjectFetchOptions
  ) => Promise<ToolkitObject<T>[]>;

  /**
   * Verfica se l'oggetto è del tipo specificato
   */
  is: (object: ToolkitObject<any> | null) => object is ToolkitObject<T>;
}

// TODO Aggiungere proprietà statiche del ToolkitObject
export function registerToolkitObjectType<T>(
  code: string,
  options?: IToolkitObjectTypeOptions<T>
): ToolkitObjectType<T> {
  return {
    code,
    create: (object: Omit<ToolkitObject<T>, 'type'>) => ({
      ...object,
      type: code
    }),
    get: (object: ToolkitObject<any> | null) => {
      return getToolkitObjectOfType(object, code);
    },
    getMany: (objects: ToolkitObject | ToolkitObject[] | null) => {
      let objs =
        objects == null ? [] : Array.isArray(objects) ? objects : [objects];

      return objs
        .map(obj => getToolkitObjectOfType(obj, code))
        .filter((o): o is ToolkitObject<T> => o != null);
    },
    fetchObjects: options?.fetchObjects,
    is: (object: ToolkitObject<any> | null): object is ToolkitObject<T> => {
      return object?.type === code;
    }
  };
}

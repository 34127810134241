import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetPersonalDossier } from './WidgetPersonalDossier';
import { WidgetPersonalDossierProfile } from './WidgetPersonalDossierProfile';
import { FvIconFolderPersonal } from 'client/ui/icon/FvIcons';
import { WidgetActionRemoveFromDossier } from './actions/WidgetActionRemoveFromDossier';

export const WidgetPersonalDossierType: IWidgetType = {
  code: 'personal-dossier',
  name: 'Cartella Personale',
  icon: <FvIconFolderPersonal />,
  component: WidgetPersonalDossier,
  description: `Cartella personale dell'utente`,
  internalActions: [WidgetActionRemoveFromDossier],
  getTitle: widget => {
    const data = widget.profile?.data as
      | WidgetPersonalDossierProfile
      | undefined;
    return data?.dossierName;
  }
};

WidgetRepository.register(WidgetPersonalDossierType);

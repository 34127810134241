import * as React from 'react';
import clx from 'classnames';
import styled from 'styled-components';
import { useDesk } from 'client/components/schema/desk/DeskModule';
import { WidgetLock } from './WidgetLock';
import { Alert, AlertProps, Result, ResultProps } from 'antd';
import { ClockCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import { CardBodyWrapper } from './CardBodyWrapper';
import { WidgetFullBodyResult } from './WidgetFullBodyResult';
import { useWidgetContext } from '../../context/WidgetContext';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

const FixedTop = styled.div`
  flex: 0 0 auto;
  min-height: 45px;
  width: 100%;
  padding: 4px ${props => props.theme.cardPaddingBase};
  border-bottom: solid 1px ${props => props.theme.borderColorBase};
`;
const CardBodyScroller = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: 0;
  height: 100%;
  max-height: 100%;
  min-width: 0;

  border-radius: 0;
  border-bottom-left-radius: ${props => props.theme.cardRadius};
  border-bottom-right-radius: ${props => props.theme.cardRadius};

  &.has-scroll {
    overflow-y: auto;
  }

  &.editable {
    overflow-y: hidden;
  }

  &.has-bottom {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.padded {
    padding: ${props => props.theme.cardPaddingBase};
  }
`;
const FixedBottom = styled.div`
  flex: 0 0 auto;
  padding: 4px ${props => props.theme.cardPaddingBase};
  padding: 0;
  border-top: solid 1px ${props => props.theme.borderColorBase};
  /* background-color: #e8eff2; */
  // prettier-ignore
  border-radius: 0 0 ${props => props.theme.cardRadius} ${props =>
    props.theme.cardRadius};
`;

const AlertBanner = styled(Alert)`
  margin-top: 1px;
`;

export interface WidgetCardBodyProps
  extends React.HTMLAttributes<HTMLDivElement> {
  top?: React.ReactNode;
  bottom?: React.ReactNode;
  padded?: boolean;
  /** Alert in testa al contenuto del widget */
  alert?: Pick<AlertProps, 'message' | 'type' | 'closable'>;
  /** Se valorizzato, in caso di errori, sostituisce il contenuto del widget
   * con il componente Result di Ant Design */
  result?: Pick<ResultProps, 'title' | 'subTitle' | 'extra' | 'status'>;
  /** Di default il contenuto e scrollabile
   * passare false per non scrollabile
   */
  scrollable?: boolean;
}

/**
 * Permette lo scroll del contenuto del widget
 * con la possibilità di elementi fissi in testa o al piede dell'area
 *
 */
export function WidgetCardBody(props: WidgetCardBodyProps) {
  const deskState = useDesk();

  const {
    children,
    top,
    bottom,
    padded,
    alert,
    result,
    scrollable,
    ...otherProps
  } = props;
  const hasBottom = bottom !== undefined;
  const hasScroll = scrollable !== false;

  const { queryErrors } = useWidgetContext();

  if (result) {
    return <WidgetFullBodyResult result={result} />;
  }

  return (
    <CardBodyWrapper {...otherProps}>
      {queryErrors && (
        <AlertBanner
          message={
            <>
              {queryErrors.map((e, i) => (
                <span key={i}>{getNetworkErrorMessage(e)}</span>
              ))}
            </>
          }
          banner
          type="error"
        />
      )}
      {alert && (
        <AlertBanner
          message={alert?.message ?? 'Codice messaggio: 8001'}
          banner
          closable
          type="warning"
          {...alert}
        />
      )}
      {top && <FixedTop>{top}</FixedTop>}
      <CardBodyScroller
        className={clx({
          padded,
          'has-bottom': hasBottom,
          'has-scroll': hasScroll,
          editable: deskState.isEditable
        })}
      >
        {props.children}
      </CardBodyScroller>
      {bottom && <FixedBottom>{bottom}</FixedBottom>}
      {deskState.isEditable && <WidgetLock />}
    </CardBodyWrapper>
  );
}

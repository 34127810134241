import React from 'react';
import styled from 'styled-components';
import { Space } from 'antd';

const ButtonsRightContainer = styled.div`
  text-align: right;
  .ant-btn {
    min-width: 110px;
  }
`;

interface FormButtonsRowProps {
  children?: React.ReactNode;
}

export function FormButtonsRow(props: FormButtonsRowProps) {
  return (
    <ButtonsRightContainer>
      <Space>{props.children}</Space>
    </ButtonsRightContainer>
  );
}

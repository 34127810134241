import { Button, Empty, List, Skeleton, Tag } from 'antd';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { IWidgetListElement } from './element/IWidgetListElement';
import { WidgetListElement } from './element/WidgetListElement';
import { ListNavigationBackButton } from './navigation/ListNavigationBackButton';
import { ListNavigationBody } from './navigation/ListNavigationBody';
import { UseInfiniteQueryResult } from 'react-query';
import { IWidgetListMassiveOptions } from './massive/IWidgetListMassiveOptions';
import { WidgetListMassiveContextProvider } from './massive/WidgetListMassiveContext';
import { WidgetListMassiveActions } from './massive/WidgetListMassiveActions';

const StyledList = styled(List)`
  &.ant-list {
    .ant-list-item-action {
      margin-left: 8px;
      min-width: 1px;
    }
    .ant-list-pagination {
      margin-top: 0;
      background-color: #e8eff2; // ${props => props.theme.borderColorBase};
      padding: 4px ${props => props.theme.cardPaddingBase};
    }
  }
`;

const PaddedSkeleton = styled(Skeleton)`
  padding: 0px 16px;
`;

const PaddedLoadMore = styled.div`
  padding: 6px;
  text-align: center;
`;

export interface BaseListProps {
  name: string;
  description: string;
}

/**
 * Interfacce per indicare il supporto all'Infinite Loading della lista.
 */
export interface InfiniteLoadingProps {
  query: UseInfiniteQueryResult<any, any>;
  pageStart?: number;
}

export interface WidgetListProps {
  list: IWidgetListElement[];
  /**
   * Elemento padre della lista che verrà mostrato nel pulsante back
   */
  backElement?: IWidgetListElement;
  /**
   * Funzione chiamata al click sul pulsante back
   */
  onBack?: () => void;
  /**
   * Gestisce il caricamento infinito della lista.
   */
  infinite?: InfiniteLoadingProps;
  /**
   * Messaggio se vuoto
   */
  emptyMessage?: string;
  /**
   * Abilita la selezione massiva
   */
  massive?: IWidgetListMassiveOptions;
}

export function WidgetList(props: WidgetListProps) {
  const { list, backElement, onBack, massive } = props;

  const loadMore = props.infinite?.query.hasNextPage ? (
    <PaddedLoadMore>
      <Button
        block
        onClick={() => props.infinite!.query.fetchNextPage()}
        loading={props.infinite!.query.isFetchingNextPage}
      >
        Carica altri
      </Button>
    </PaddedLoadMore>
  ) : null;

  return (
    <WidgetListMassiveContextProvider options={massive} list={list}>
      <ListNavigationBody
        top={
          <>
            {backElement && (
              <ListNavigationBackButton
                name={backElement.name}
                onClick={onBack}
              />
            )}
            {massive?.enabled && <WidgetListMassiveActions />}
          </>
        }
      >
        <StyledList
          size="small"
          itemLayout="horizontal"
          dataSource={list}
          loadMore={loadMore}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={props.emptyMessage ?? 'Nessun dato'}
              />
            )
          }}
          renderItem={(item, idx) => {
            const element = item as IWidgetListElement;
            return <WidgetListElement key={idx} element={element} />;
          }}
        />
      </ListNavigationBody>
    </WidgetListMassiveContextProvider>
  );
}

import React from 'react';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import {
  SelectInput,
  SelectInputProps,
  SelectOption
} from 'client/ui/form/input/SelectInput';
import { stateOptions, StateTag } from 'client/ui/state/StateTag';
import { useField } from 'formik';

interface IStateSelectInputProps extends SelectInputProps<any> {}

export function StateSelectInput(props: IStateSelectInputProps) {
  const { name } = props;
  const [field, meta, helpers] = useField<string | null>(name);

  return (
    <SelectInput
      allowClear
      options={stateOptions()}
      value={field.value?.toString()}
      renderOption={(option: SelectOption) => (
        <StateTag enabled={option.value === 'true'} />
      )}
      {...props}
    />
  );
}

import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { yup } from 'common/validation/initYup';
import * as React from 'react';
import * as WidgetFilterSections from 'client/components/toolkit/components/filter/WidgetFilterSections';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { Button, Space } from 'antd';
import { WidgetFilterDrawer } from 'client/components/toolkit/components/filter/WidgetFilterDrawer';
import { renderWidgetFilterActiveTags } from 'client/components/toolkit/components/filter/renderWidgetFilterActiveTags';
import { useState } from 'react';
import {
  GetDocumentaryAssignmentsParams,
  Widget
} from 'client/api/backend/schemas';
import { TextInput } from 'client/ui/form/input/TextInput';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { AssignmentStateSelectInput } from 'client/components/widget-repository/objects/assignments/AssignmentStateSelectInput';
import { DocumentProtocolModeInput } from 'client/components/widget-repository/objects/documents/DocumentProtocolModeInput';
import { useFormikContext } from 'formik';
import { getAssignmentStateId } from 'client/components/widget-repository/objects/assignments/mapIdState';

export interface IWidgetAssignmentsFormProps {
  widget: Widget;
  search: Partial<GetDocumentaryAssignmentsParams>;
  setSearch: (search: Partial<GetDocumentaryAssignmentsParams>) => void;
}

export function WidgetAssignmentsSearchForm(
  props: IWidgetAssignmentsFormProps
) {
  const { widget, search, setSearch } = props;
  const [open, setOpen] = useState(false);

  return (
    <FormikAugmented
      validationSchema={yup.object({})}
      validationContext={{}}
      initialValues={search}
      onReset={() => {
        setSearch({});
        setOpen(false);
      }}
      onSubmit={(values: Partial<GetDocumentaryAssignmentsParams>) => {
        // Convertiamo lo stato, nel caso in cui non è presente
        // torna undefined
        const stateId = getAssignmentStateId(
          widget.profile?.filters.assignmentTypeId?.toString(),
          values.assignmentStateId?.toString()
        );

        setSearch({ ...values, assignmentStateId: stateId as any });
        setOpen(false);
      }}
    >
      <WidgetFilterDrawer
        title="Ricerca Assegnazioni"
        filters={renderWidgetFilterActiveTags({
          search,
          filters: widget?.profile?.filters,
          onReset: () => setSearch({})
        })}
        open={[open, setOpen]}
      >
        <FormikForm layout="vertical" editable helpInTooltips>
          <WidgetFilterSections.BodyWrapper>
            <FormFieldsContainer>
              <FormFieldItem
                size="middle"
                component={AssignmentStateSelectInput}
                label={null}
                name="assignmentStateId"
                typeId={widget.profile?.filters?.assignmentTypeId}
                widgetId={props.widget.id!}
                placeholder="Stato"
              />
            </FormFieldsContainer>
            <FormFieldsContainer>
              <FormFieldItem
                size="middle"
                component={DocumentProtocolModeInput}
                label={null}
                name="documentModeId"
                widgetId={props.widget.id!}
                placeholder="Modalità"
              />
            </FormFieldsContainer>
            <FormFieldsContainer>
              <FormFieldItem
                size="middle"
                component={TextInput}
                label={null}
                name="subject"
                placeholder="Oggetto"
              />
            </FormFieldsContainer>
            <FormFieldsContainer columns={2} gutter={8}>
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                type="unix"
                label={null}
                name="startDate"
                placeholder="Dal"
              />
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                type="unix"
                label={null}
                name="endDate"
                placeholder="Al"
              />
            </FormFieldsContainer>
          </WidgetFilterSections.BodyWrapper>
          <WidgetFilterSections.ButtonsWrapper>
            <Space>
              <Button htmlType="reset">Pulisci</Button>
              <Button htmlType="submit" type="primary">
                Filtra
              </Button>
            </Space>
          </WidgetFilterSections.ButtonsWrapper>
        </FormikForm>
      </WidgetFilterDrawer>
    </FormikAugmented>
  );
}

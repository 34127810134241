import PlayCircleOutlined from '@ant-design/icons/lib/icons/PlayCircleOutlined';
import { HistoryItem } from 'client/components/toolkit/components/history/HistoryItem';
import { HistoryTimeline } from 'client/components/toolkit/components/history/HistoryTimeline';
import { IHistoryItem } from 'client/components/toolkit/components/history/IHistoryItem';
import React from 'react';
import styled from 'styled-components';
import clx from 'classnames';

export interface IDocumentHistoryTimelineProps {
  assignmentHistory: IHistoryItem[];
  isChild?: boolean;
}

export function DocumentHistoryTimeline(props: IDocumentHistoryTimelineProps) {
  if (!props.assignmentHistory) return null;
  return (
    <HistoryTimeline className={clx({ 'children-wrapper': props.isChild })}>
      {props.assignmentHistory.map((item, index) => (
        <>
          <HistoryItem
            key={index}
            {...item}
            color={props.isChild ? 'gray' : 'blue'}
            className={clx({
              'is-child-item': props.isChild,
              'is-last-item': index === props.assignmentHistory.length - 1,
              'has-children':
                item.historyChildren && item.historyChildren.length > 0
            })}
          />
          {item.historyChildren && item.historyChildren.length > 0 && (
            <DocumentHistoryTimeline
              assignmentHistory={item.historyChildren}
              isChild
            />
          )}
        </>
      ))}
    </HistoryTimeline>
  );
}

import { yup } from 'common/validation/initYup';
import { BaseSchema, StringSchema } from 'yup';

export const UserSchema = yup.object({
  username: yup.string().required().label('Login'),
  password: yup
    .string()
    .when(['$isEdit'], (isEdit: boolean, schema: BaseSchema) => {
      return isEdit ? schema.notRequired() : schema.required();
    })
    .label('Password'),
  name: yup.string().notRequired().label('Nome'),
  surname: yup.string().notRequired().label('Cognome'),
  cf: yup.string().required().label('Codice Fiscale')
});

import { Button, Empty } from 'antd';
import { useAuth } from 'client/components/auth/AuthModule';
import { resolveApiPath } from 'client/core/appConfig';
import qs from 'qs';
import React from 'react';

interface IUrl {
  type: 'url';
  url?: string;
  widgetId: number;
}

interface IContent {
  type: 'content';
  content?: string;
}
interface IWidgetDocumentPreviewProps {
  source: IUrl | IContent;
  fileName?: string;
}

/**
 * Permette di ottenere una preview di un documento sia che venga passato il contenuto in base64 o l'url
 */
export function WidgetDocumentPreview(props: IWidgetDocumentPreviewProps) {
  const { source, fileName } = props;

  if (source.type === 'content' && !source.content) return null;

  if (source.type === 'url' && !source.url) return null;

  const accessToken = useAuth().accessToken;

  if (!fileName?.toLowerCase().endsWith('pdf')) {
    const hRef =
      source.type === 'content'
        ? `data:application/octet-stream;base64,${source.content}`
        : source.url;

    return (
      <Empty
        description={`Scarica il file ${fileName ? `"${fileName}"` : ''} `}
      >
        <a href={hRef} target="_blank" download={fileName}>
          <Button type="primary">Scarica</Button>
        </a>
      </Empty>
    );
  }

  const src =
    source.type === 'content'
      ? `data:application/pdf;base64,${source.content}`
      : resolveApiPath(
          `${source.url}?${qs.stringify({
            widgetId: source.widgetId,
            accessToken
          })}`
        );

  return (
    <iframe
      src={`${src}#toolbar=0&navpanes=0&statusbar=0&view=FitH`}
      frameBorder={0}
      allowFullScreen
      width="100%"
      height="100%"
    />
  );
}

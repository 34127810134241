import { useGetDocumentaryDocumentsProtocolMailboxes } from 'client/api/backend/documentary-documents/documentary-documents';
import { DeskCorrespondent } from 'client/api/backend/schemas';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { useCorrespondentMailTransportIds } from '../correspondents/input/useCorrespondentTransportMailIds';

interface IDocumentProtocolMailBoxesInputProps extends SelectInputProps<any> {
  widgetId: number;
  correspondentsFieldName: string;
  registryIdFieldName: string;
}

export function DocumentProtocolMailBoxesInput(
  props: IDocumentProtocolMailBoxesInputProps
) {
  const {
    widgetId,
    correspondentsFieldName,
    registryIdFieldName,
    ...otherProps
  } = props;

  const formik = useFormikContext();

  const registryId = getIn(formik.values, registryIdFieldName) as
    | number
    | undefined;
  const correspondents = getIn(formik.values, correspondentsFieldName) as
    | DeskCorrespondent[]
    | undefined;

  const mailTransportIds = useCorrespondentMailTransportIds(widgetId);

  // Controlla se uno dei transport.id dei corrispondenti è presente in mailTransportIds
  const meanId = correspondents
    ?.map(c => c.transport?.id)
    .find(t => {
      if (t == null) return false;
      return mailTransportIds.includes(t);
    });

  const boxesResult = useGetDocumentaryDocumentsProtocolMailboxes(
    {
      meanId: meanId!,
      registryId: registryId!,
      widgetId
    },
    { query: { enabled: registryId != null && meanId != null } }
  );

  return (
    <SelectInput
      allowClear
      showSearch
      loading={boxesResult.isLoading}
      options={boxesResult.data?.map(box => {
        return { value: box.id!, label: box.address ?? `Casella ID ${box.id}` };
      })}
      {...otherProps}
    />
  );
}

import * as React from 'react';
import { FileOutlined } from '@ant-design/icons';
import { DeskAssignment, Widget } from 'client/api/backend/schemas';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import moment from 'moment';
import { DocumentToolkitObject } from 'client/components/widget-repository/objects/documents/DocumentToolkitObject';
import { AssignmentToolkitObject } from 'client/components/widget-repository/objects/assignments/AssignmentToolkitObject';
import { convertIdTypeToReasonTagComponents } from 'client/components/widget-repository/objects/assignments/mapIdType';
import {
  mapIdState,
  mapIdStateColor
} from 'client/components/widget-repository/objects/assignments/mapIdState';
import { Tag } from 'antd';
import { isAssignmentStale } from 'client/components/widget-repository/objects/assignments/isAssignmentStale';

export function convertAssignmentToWidgetListElement(
  assignment: DeskAssignment,
  widget: Widget,
  filterLabel: string | undefined = undefined,
  filterColor: string | undefined = undefined
): IWidgetListElement {
  const assignmentName =
    assignment.document?.subject ?? assignment.document?.filename ?? 'N.A.';

  const document = assignment.document
    ? DocumentToolkitObject.create({
        id: assignment.document.id!,
        name: assignment.document.subject ?? 'N.A.',
        sourceWidgetId: widget.id!,
        data: assignment.document,
        icon: <FileOutlined />,
        isRelated: true
      })
    : null;

  const mode = convertIdTypeToReasonTagComponents(
    assignment.idType?.toString()
  );

  const isStale = isAssignmentStale(assignment);

  const assignmentObject = AssignmentToolkitObject.create({
    id: assignment.id!,
    name: assignmentName,
    icon: <FileOutlined />,
    data: assignment,
    sourceWidgetId: widget.id!,
    related: document && !isStale ? [document] : []
  });

  return {
    name: assignmentName,
    filter: filterLabel,
    description: assignment.startDate
      ? moment(assignment.startDate).format('lll')
      : undefined,
    descriptionExtend: filterLabel
      ? {
          after: filterLabel,
          color: filterColor,
          bold: true
        }
      : {
          before: !isStale
            ? mapIdState(assignment.idState?.toString())
            : undefined,
          beforeColor: !isStale
            ? mapIdStateColor(assignment.idState?.toString())
            : undefined,
          after: mode.label,
          // afterColor: mode.color,
          bold: true
        },
    type: 'file',
    typeColor: filterColor,
    annotations: assignment.note,
    annotationsExtend: {
      before: assignment.document?.shelfmark ?? undefined
    },
    object: assignmentObject
  };
}

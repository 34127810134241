import { DeleteOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { set } from 'client/core/data/set';
import { AssignmentToolkitObject } from 'client/components/widget-repository/objects/assignments/AssignmentToolkitObject';
import { WidgetHighlightedAssignmentsProfile } from '../WidgetHighlightedAssignmentsProfile';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { DeskAssignment } from 'client/api/backend/schemas';
import {
  HighlightedAssignmentToolkitObject,
  HighlightedDeskAssignment
} from '../objects/HighlightedAssignmentToolkitObject';

export const WidgetActionDeleteHighlightedAssignment = createToolkitAction({
  name: 'Rimuovi Assegnazione',
  code: 'delete-highlighted-assignment',
  icon: <DeleteOutlined />,
  arguments: [
    {
      type: [HighlightedAssignmentToolkitObject],
      name: 'Assegnazione'
    }
  ],
  async execute(ctx: IToolkitActionContext, assignmentObject: ToolkitObject) {
    let assignment: ToolkitObject<HighlightedDeskAssignment> =
      HighlightedAssignmentToolkitObject.get(assignmentObject)!;

    onConfirm(ctx, {}, assignment);
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: any,
  assignment: ToolkitObject
) {
  try {
    const widget = ctx.deskContext.widgets.find(
      w => w.id === assignment.sourceWidgetId
    );
    const profile = widget?.profile;
    const data = profile?.data as WidgetHighlightedAssignmentsProfile | null;

    const deletedAssignment = assignment.data;

    const updatedData = data?.assignments?.filter(
      d => d.id !== deletedAssignment.id
    );

    await ctx.deskContext.updateWidget(
      set(widget!, 'profile.data.assignments', [...(updatedData ?? [])])
    );

    message.success('Assegnazione rimossa con successo');
  } catch (e) {
    message.error(
      getNetworkErrorMessage(e, "Impossibile rimuovere l'assegnazione")
    );
    console.error(e);
    return false;
  }
}

import React, { useCallback } from 'react';
import clx from 'classnames';
import { CheckboxOptionType, Radio } from 'antd';
import { FormikValues, useField, useFormikContext } from 'formik';
import { RadioChangeEvent, RadioGroupProps } from 'antd/lib/radio';
import { useFormikFormContext } from '../FormikFormContext';

export interface RadioInputProps<T extends FormikValues>
  extends Omit<RadioGroupProps, 'name'> {
  name: keyof T & string;

  /**
   * Permette lo sviluppo in verticale delle opzioni
   * -> un opzione per ogni riga
   */
  vertical?: boolean;
}

/**
 * Campo per input di tipo Radio.
 */
export function RadioInput<T extends FormikValues>(props: RadioInputProps<T>) {
  const { name, vertical } = props;
  const [field, meta, helpers] = useField<string>(name);
  const { disabled } = useFormikFormContext();
  const handleChange = useCallback(
    (e: RadioChangeEvent) => helpers.setValue(e.target.value),
    []
  );

  return (
    <Radio.Group
      {...props}
      {...field}
      disabled={props.disabled || disabled}
      value={field.value}
      onChange={handleChange}
      className={clx({ 'radio-input-vertical': vertical })}
    />
  );
}

RadioInput.renderFieldValue = (
  value: string | number,
  props: RadioInputProps<any>
) => {
  if (!props.options) return value;
  for (let i = 0; i < props.options.length; ++i) {
    const option = props.options[i];
    if (typeof option === 'string' && value === option) {
      return value;
    }
    if ((option as CheckboxOptionType).value === value) {
      return (option as CheckboxOptionType).label;
    }
  }
  return value;
};

export const StateLabels: Record<string, string> = {
  NON_IN_CARICO_WEEK: 'Da eseguire ultima settimana',
  NON_IN_CARICO_UPWEEK: 'Da eseguire da più di una settimana',
  IN_CARICO_WEEK: 'In carico ultima settimana',
  IN_CARICO_UPWEEK: 'In carico da più di una settimana',
  CONCLUSE_WEEK: 'Concluse ultima settimana',
  CONCLUSE_UPWEEK: 'Concluse da più di una settimana',
  NON_IN_CARICO_TODAY: 'Da eseguire oggi',
  IN_CARICO_TODAY: 'In carico oggi',
  CONCLUSE_TODAY: 'Concluse oggi'
};
export const StateLabelsCompact: Record<string, string> = {
  NON_IN_CARICO_WEEK: 'Da eseguire',
  NON_IN_CARICO_UPWEEK: 'Da eseguire (+7gg)',
  IN_CARICO_WEEK: 'In carico',
  IN_CARICO_UPWEEK: 'In carico (+7gg)',
  CONCLUSE_WEEK: 'Concluse',
  CONCLUSE_UPWEEK: 'Concluse (+7gg)',
  NON_IN_CARICO_TODAY: 'Da eseguire oggi',
  IN_CARICO_TODAY: 'In carico oggi',
  CONCLUSE_TODAY: 'Concluse oggi'
};

import { Divider, Empty } from 'antd';
import { DeskMail } from 'client/api/backend/schemas';
import { Metadata } from 'client/components/toolkit/components/metadata/Metadata';
import moment from 'moment';
import React from 'react';
import { sanitizeHtml } from 'src/utils/sanitizeHtml';

interface IWidgetMailBodyProps {
  mail?: DeskMail;
}

export function WidgetMailBody(props: IWidgetMailBodyProps) {
  const { mail } = props;

  const sanitizedHtml = sanitizeHtml(mail?.body);

  return (
    <div>
      <Metadata label="Mittente" value={mail?.emailSender} />
      <Metadata label="Destinatario" value={mail?.emailReceiver} />
      <Metadata label="Data" value={moment(mail?.date).format('DD/MM/YYYY')} />
      <Metadata label="Oggetto" value={mail?.subject} />
      <Divider />
      {!sanitizedHtml.trim() ? (
        <Empty />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(mail?.body) }} />
      )}
    </div>
  );
}

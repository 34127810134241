import {
  DeskDam,
  DeskDamObservation,
  Widget
} from 'client/api/backend/schemas';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { DamToolkitObject } from 'client/components/widget-repository/objects/dams/DamToolkitObject';
import { fromPairs, groupBy } from 'lodash';

export interface IMultipleDamObservationTableData {
  damId: string;
  damName: string;
  parameters: { [index: string]: string };
  object: ToolkitObject;
}

export function convertMultipleObservationsToTableData(
  dams: DeskDam[],
  observations: DeskDamObservation[],
  widget: Widget
): IMultipleDamObservationTableData[] {
  const damsById = groupBy(observations, 'id');

  // Per ogni diga estraiamo le osservazioni, anche se non ci sono osservazioni
  // restituiamo comunque l'id e il nome della diga per mostrarla in tabella
  const damsObservations = dams.map(dam => {
    const damId = dam?.id!;
    const damName = dam?.name!;

    const damObservations = damsById[damId];

    const parameters = fromPairs(
      damObservations?.map(obs => [obs.parameter, obs.value])
    );
    const object = DamToolkitObject.create({
      id: damId,
      name: damName,
      data: dam,
      sourceWidgetId: widget.id!
    });
    return { damId, damName, parameters, object };
  });

  return damsObservations;
}

import { useGetDocumentaryDossiersStates } from 'client/api/backend/documentary-dossiers/documentary-dossiers';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import * as React from 'react';

interface IDossierStateInputProps extends SelectInputProps<any> {
  widgetId: number;
}

export function DossierStateInput(props: IDossierStateInputProps) {
  const { widgetId, ...otherProps } = props;
  const statesResult = useGetDocumentaryDossiersStates({
    widgetId: props.widgetId
  });

  return (
    <SelectInput
      allowClear
      showSearch
      loading={statesResult.isLoading}
      options={[
        ...(statesResult.data?.map(mode => ({
          label: mode.label!,
          value: Number(mode.value!)
        })) ?? [])
      ]}
      {...otherProps}
    />
  );
}

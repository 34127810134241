import { Tooltip } from 'antd';
import React from 'react';
import styled, { css } from 'styled-components';
import { IterationStatement } from 'ts-morph';
import { IMonitorChartItem } from '../MonitorChart';

const MenuLabelWrapper = styled.span<{ showTooltip?: boolean }>`
  ${props =>
    props.showTooltip &&
    css`
      font-size: 12px;
    `}
`;

interface MenuLabelProps {
  item: IMonitorChartItem;
  width: number;
  recordLabels: Record<string, string>;
}

export function MenuLabel(props: MenuLabelProps) {
  const { item, width, recordLabels } = props;
  const showTooltip = width < 90;
  return (
    <MenuLabelWrapper showTooltip={showTooltip}>
      {showTooltip ? (
        <Tooltip title={item.label}>{recordLabels[item.key]}</Tooltip>
      ) : (
        <span>{item.label}</span>
      )}
    </MenuLabelWrapper>
  );
}

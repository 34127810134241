import * as React from 'react';
import { Pagination, Table, TablePaginationConfig, TableProps } from 'antd';
import { tableLoaderSpin } from '../loader/tableLoaderSpin';
import styled from 'styled-components';

const TableInfo = styled.span`
  color: ${props => props.theme.textColorSecondary};
  opacity: 0.75;
  margin-right: 6px;
`;

const TableWrapper = styled.div`
  background-color: ${props => props.theme.componentBackground};
  border-radius: ${props => props.theme.borderRadiusBase};

  .ant-table-thead {
    > tr > th {
      text-transform: uppercase;
      letter-spacing: 0.05em;
      font-size: ${props => props.theme.fontSizeSm};
      line-height: 1.2em;
    }
  }
  .anticon-filter {
    color: ${props => props.theme.tableHeaderIconColor};
    .active.ant-table-filter-trigger & {
      color: ${props => props.theme.tableHeaderIconColorHover};
    }
  }
  .ant-table-pagination {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export interface TableDataProps<T> extends Omit<TableProps<T>, 'loading'> {
  loading?: boolean;
}
/**
 * Tabella con stili e paginazione personalizzati
 */
export function TableData<T extends {}>(props: TableDataProps<T>) {
  const { loading, ...otherProps } = props;

  // Stile di base della paginazione
  const pagination: TablePaginationConfig = {
    position: ['bottomRight'],
    size: 'small',
    showSizeChanger: true,
    showTotal: (total, range) => (
      <TableInfo>
        {range[0]}-{range[1]} di {total}
      </TableInfo>
    )
  };

  return (
    <TableWrapper>
      <Table<T>
        loading={tableLoaderSpin(loading)}
        {...otherProps}
        pagination={{ ...pagination, ...props.pagination }}
      />
    </TableWrapper>
  );
}

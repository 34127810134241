export interface IGridParams {
  gridWidth: number;
  gridHeight: number;
  // Uguale a colsNumber
  rowsNumber: number;
}

export interface IGridPosition {
  x: number;
  y: number;
  w: number;
  h: number;
}

export function calculateGridPosition(
  params: IGridParams,
  slot: IGridPosition
) {
  const { gridWidth, gridHeight, rowsNumber } = params;
  const { x, y, w, h } = slot;

  return {
    x: Math.round((slot.x / rowsNumber) * gridWidth),
    y: Math.round((slot.y / rowsNumber) * gridHeight),
    width: Math.round((slot.w / rowsNumber) * gridWidth),
    height: Math.round((slot.h / rowsNumber) * gridHeight)
  };
}

import * as React from 'react';
import clx from 'classnames';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons/lib/icons';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { DrawerProps } from 'antd';

const DrawerTitleStyle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px; // TODO font-size standard?
  font-weight: bold;
  .close-button {
    margin-right: 12px;
    .anticon {
      font-size: 14px;
    }
  }
`;

export interface DrawerTitleProps {
  children?: React.ReactNode;
  /**
   * Pulsante di chiusura con ButtonIcon
   */
  onClose?: () => void;
}

/**
 * Barra del titolo del Drawer stilizzata 
 * Include il pulsante di chiusura con ButtonIcon
 * 
 * Se non viene passata la funzione onClose(), 
 * il pulsante non viene mostrato
 * 
 * Per una corretta visualizzazione impostare 
 * closable={false} nel Drawer per evitare la doppia icona
 * <Drawer
      title={<DrawerTitle onClose={handleClose}>Aggiungi Widget</DrawerTitle>}
      closable={false}
 */
export function DrawerTitle(props: DrawerTitleProps) {
  const { children, onClose } = props;
  return (
    <DrawerTitleStyle>
      {!!onClose && (
        <ButtonIcon
          icon={<CloseOutlined />}
          onClick={onClose}
          className="close-button"
        />
      )}
      {children}
    </DrawerTitleStyle>
  );
}

import React from 'react';
import clx from 'classnames';
import { Layout } from 'antd';
const { Content } = Layout;
import { useThemeConfig } from 'client/components/schema/config/ClientConfigModule';
import { PageLayoutHeader } from './header/PageLayoutHeader';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';

const FullHeightLayout = styled(Layout)`
  /* height: 100%; */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const FlexContent = styled(Content)`
  display: flex;
  flex-direction: column;
  ${down('lg')} {
    overflow-y: auto;
  }
`;
export interface PageLayoutProps {
  children: React.ReactNode;
  hideHeader?: boolean;
  mainMenu?: React.ReactNode;
  rightMenu?: React.ReactNode;
}

/**
 * Layout principale dell'applicativo.
 */
export function PageLayout(props: PageLayoutProps) {
  const { children, ...rest } = props;
  const { options } = useThemeConfig();

  return (
    <FullHeightLayout>
      <PageLayoutHeader {...rest} />
      <FlexContent>{props.children}</FlexContent>
    </FullHeightLayout>
  );
}

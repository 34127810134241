import { Select } from 'antd';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import React from 'react';
import { ToolkitObjectType } from '../registerToolkitObjectType';
import { ToolkitObject } from '../ToolkitObject';

interface IComputedOption {
  label: JSX.Element;
  title: string;
  value: string;
  object: ToolkitObject<any>;
}

interface IToolkitObjectSelectInputProps extends SelectInputProps<any> {
  name: string;
  options: IComputedOption[];
  searchableTypes: ToolkitObjectType[];
  searchOptions: IComputedOption[];
}

/**
 * Versione memoizzata di ToolkitObjectSelectInput, ovvero un SelectInput che
 * mostra le opzioni di ToolkitObject disponibili per un dato tipo.
 */
export const ToolkitObjectSelectInput = React.memo(
  (props: IToolkitObjectSelectInputProps) => {
    const { options, searchableTypes, searchOptions, ...otherProps } = props;
    return (
      <SelectInput {...otherProps}>
        {options.length > 0 && (
          <Select.OptGroup key="widgets" label="Dai Widget">
            {options.map(option => (
              <Select.Option
                key={option.value}
                {...option}
                children={option.label}
              />
            ))}
          </Select.OptGroup>
        )}
        {searchableTypes.length > 0 && (
          <Select.OptGroup key="search" label="Dalla Ricerca">
            {searchOptions.map(option => (
              <Select.Option
                key={option.value + '-search'}
                {...option}
                children={option.label}
              />
            ))}
          </Select.OptGroup>
        )}
      </SelectInput>
    );
  }
);

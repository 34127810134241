import * as React from 'react';
import styled from 'styled-components';
import { Col, ColProps, Row } from 'antd';
import { Widget } from 'client/api/backend/schemas';
import { WidgetComponent } from '../../WidgetComponent';
import { DndContext } from '@dnd-kit/core';

const WidgetPreviewBackgroundCol = styled(Col)`
  background-color: ${props => props.theme.bodyBackground};
  height: 100%;
  padding: 24px 0 100px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const WidgetPreviewContainer = styled.div`
  height: 100%;
  width: 75%;
  max-height: 500px;
  max-width: 400px;
`;
const WidgetPreviewLock = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
`;

export interface WidgetPreviewProps extends ColProps {
  widget?: Widget | undefined;
  type?: 'primary' | 'default';
}

/**
 * Anteprima del widget.
 *
 * Creiamo un falso <DndContext> per evitare possibili interazioni e,
 * soprattutto, per evitare doppie registrazioni (`useDroppable`) con gli stessi
 * ID fra il widget reale e quello in anteprima.
 */
export function WidgetPreview(props: WidgetPreviewProps) {
  const { widget, type, ...rest } = props;

  return (
    <DndContext>
      <WidgetPreviewBackgroundCol span={12} {...rest}>
        <WidgetPreviewContainer>
          {widget && <WidgetComponent widget={widget} />}
        </WidgetPreviewContainer>
        <WidgetPreviewLock />
      </WidgetPreviewBackgroundCol>
    </DndContext>
  );
}

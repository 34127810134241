import { yup } from 'common/validation/initYup';
import { yupToFormErrors } from 'formik';
import { ObjectShape } from 'yup/lib/object';
import { ValidateOptions } from 'yup/lib/types';

interface ISchemaToValidatorOptions<T> extends ValidateOptions {
  /**
   * Aggiunge valori al contesto di validazione a partire
   * dal valore del form.
   */
  valueToContext?: (value: any) => Record<string, any>;
}

/**
 * Crea un validatore di uno schema per i form che non abbia solamente
 * `abortEarly`.
 */
export function schemaToValidator<T extends ObjectShape>(
  schema: yup.ObjectSchema<T>,
  options?: ISchemaToValidatorOptions<T>
) {
  const { valueToContext, ...validateOptions } = options ?? {};
  return async (values: any) => {
    try {
      const res = await schema.validate(values, {
        ...validateOptions,
        context: {
          ...validateOptions?.context,
          ...valueToContext?.(values)
        },
        abortEarly: false
      });
      return;
    } catch (err: any) {
      if ((window as any)?.DESK_LogValidation) {
        console.error('Validation error', err.errors, err.inner, err, values);
      }
      return yupToFormErrors(err);
    }
  };
}

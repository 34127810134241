import {
  GroupOutlined,
  UsergroupAddOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Widget } from 'client/api/backend/schemas';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import React from 'react';
import { mapIdType } from '../assignments/mapIdType';
import { AssignmentModelItemToolkitObject } from './AssignmentModelItemToolkitObject';
import {
  AssignmentModelToolkitObject,
  IAssignmentModel,
  IAssignmentModelItem
} from './AssignmentModelToolkitObject';

export function convertAssignmentModelItemToListElement(
  model: IAssignmentModel,
  item: IAssignmentModelItem,
  index: number,
  widget: Widget
): IWidgetListElement {
  return {
    name: item.assignee.data.name ?? 'N.A.',
    description: mapIdType(item.typeId.toString()),
    type: 'group', // TODO
    object: AssignmentModelItemToolkitObject.create({
      name: model.label,
      icon: <UserOutlined />,
      id: index,
      data: { ...item, index, modelUuid: model.uuid },
      sourceWidgetId: widget.id!
    })
  };
}

import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import useDimensions from 'react-cool-dimensions';
import { MonitorScroller } from './container/MonitorScroller';
import { MonitorTop } from './container/MonitorTop';
import { MonitorWrapper } from './container/MonitorWrapper';
import { MonitorDoughnutChart } from './detail/MonitorDoughnutChart';
import { MonitorTopButton } from './detail/MonitorTopButton';
import { ColorStatistic } from './menu/ColorStatistic';
import { MenuCol } from './menu/MenuCol';
import { MenuRow } from './menu/MenuRow';
import { getMonitoringSerieFromList } from './detail/getMonitoringSerieFromList';
import { sum } from 'lodash';
import { IWidgetListElement } from '../list/element/IWidgetListElement';
import { ChartPalette } from './palette/ChartPalette';
import { PaddedSkeleton } from 'client/ui/skeleton/PaddedSkeleton';
import { MenuLabel } from './menu/MenuLabel';
import { InfiniteLoadingProps, WidgetList } from '../list/WidgetList';

export interface IMonitorChartItem {
  label: string;
  key: string;
  value: number;
}

interface MonitorChartProps {
  items: IMonitorChartItem[];
  selected?: string;
  selectedList?: IWidgetListElement[];
  selectedListLoading?: boolean;
  setSelected: (selected: string | undefined) => void;
  palette: ChartPalette;
  recordLabels: Record<string, string>;
  infinite?: InfiniteLoadingProps;
}

export function MonitorChart(props: MonitorChartProps) {
  const {
    items,
    selectedList,
    selectedListLoading,
    selected,
    setSelected,
    palette,
    recordLabels
  } = props;

  const { observe, unobserve, width, height, entry } = useDimensions({
    onResize: ({ observe, unobserve, width, height, entry }) => {
      // Triggered whenever the size of the target is changed...
      setMaxSize(height > width ? Math.trunc(width) : Math.trunc(height));
      unobserve(); // To stop observing the current target element
      observe(); // To re-start observing the current target element
    }
  });

  const [maxSize, setMaxSize] = useState(
    height > width ? Math.trunc(width) : Math.trunc(height)
  );

  const labels =
    maxSize < 400
      ? items.map(item => recordLabels[item.key])
      : items.map(item => item.label);
  const values = items.map(item => item.value);
  const total = sum(values);

  // Altezza minima per mostrare il grafico a torta,
  // al di sotto di questa altezza, il grafico viene mostrato come mini-barra
  const minHeight = 75;

  return (
    <MonitorWrapper>
      <MonitorTop ref={observe} fixed={selected != null}>
        <MonitorDoughnutChart
          labels={labels}
          values={values}
          total={total}
          openedList={selected != null || height < minHeight}
          max={maxSize}
          palette={palette}
        />
        {selected != null && (
          <MonitorTopButton onClick={() => setSelected(undefined)} />
        )}
      </MonitorTop>
      <MenuRow>
        {items?.map((item, i) => (
          <MenuCol
            key={i}
            onClick={() => setSelected(item.key)}
            opened={selected != null}
            selected={selected === item.key}
          >
            <ColorStatistic
              title={
                <MenuLabel
                  item={item}
                  width={Math.trunc(width / labels.length)}
                  recordLabels={recordLabels}
                />
              }
              value={item.value}
              suffix={`/ ${total}`}
              color={palette[i]}
            />
          </MenuCol>
        ))}
      </MenuRow>
      {selected && (
        <MonitorScroller>
          <PaddedSkeleton active loading={selectedListLoading}>
            <WidgetList list={selectedList ?? []} infinite={props.infinite} />
          </PaddedSkeleton>
        </MonitorScroller>
      )}
    </MonitorWrapper>
  );
}

import {
  getDocumentaryActivity,
  getGetDocumentaryActivityQueryKey
} from 'client/api/backend/documentary-activity/documentary-activity';
import { GetDocumentaryActivityParams } from 'client/api/backend/schemas';
import { ErrorType } from 'client/core/network/mutators/apiClient';
import {
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseQueryOptions,
  UseQueryResult
} from 'react-query';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

export const InfiniteActivitiesSearchPageStart = 0;

export const useInfiniteActivitiesSearch = <
  TData = AsyncReturnType<typeof getDocumentaryActivity>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryActivityParams,
  options?: {
    query?: UseInfiniteQueryOptions<
      AsyncReturnType<typeof getDocumentaryActivity>,
      TError,
      TData
    >;
  }
): UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryActivityQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryActivity>
  > = ctx =>
    getDocumentaryActivity({
      ...params!,
      pageNumber: ctx.pageParam ?? InfiniteActivitiesSearchPageStart
    });

  const query = useInfiniteQuery<
    AsyncReturnType<typeof getDocumentaryActivity>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

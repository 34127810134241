import { GetDocumentaryMailsParams, Widget } from 'client/api/backend/schemas';
import { applyDynamicDateRange } from 'client/components/toolkit/components/filter/dynamic/DynamicDateRanges';

/**
 * Intefaccia specifica dei filtri "di default" impostati da questo widget
 */
export interface IWidgetMailSearchFilterData
  extends Pick<GetDocumentaryMailsParams, 'keyword' | 'mailboxId'> {
  dynamicBeginDate: string;
  dynamicEndDate: string;
}

export function applyWidgetMailSearchDefaultFilters(
  widget: Widget
): Partial<GetDocumentaryMailsParams> {
  const filters = widget.profile?.filters as IWidgetMailSearchFilterData | null;
  if (!filters) return {};

  const { dynamicBeginDate, dynamicEndDate, ...standardParams } = filters;
  return {
    ...standardParams,
    beginDate: applyDynamicDateRange(dynamicBeginDate)?.valueOf(),
    endDate: applyDynamicDateRange(dynamicEndDate)?.valueOf()
  };
}

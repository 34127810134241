import * as React from 'react';
import { Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { SpinProps } from 'antd/lib/spin/index';
import styled, { css } from 'styled-components';

const LoaderSpinWrapper = styled.div<LoaderSpinProps>`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding: 20px;
  padding-bottom: 15px;
  margin-top: 1px;

  ${props =>
    props.hasScroll &&
    css`
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0;

      .ant-spin-nested-loading {
        width: 100%;
        height: 100%;
        .ant-spin-container {
          width: 100%;
          height: 100%;
        }
      }
    `};

  ${props =>
    props.full &&
    css`
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    `};

  ${props =>
    props.transparent &&
    css`
      background: transparent;
    `}
  ${props =>
    props.fullWidth &&
    css`
      padding: 0;
      .ant-spin-nested-loading {
        width: 100%;
      }
    `}
`;

export interface LoaderSpinProps extends SpinProps {
  full?: boolean;
  fullWidth?: boolean;
  transparent?: boolean;
  hasScroll?: boolean;
  children?: React.ReactNode;
}

/**
 * Spinning Loader.
 */
export function LoaderSpin(props: LoaderSpinProps) {
  const { full, fullWidth, transparent, hasScroll, ...otherProps } = props;
  const divProps = { full, transparent, hasScroll, fullWidth };

  return (
    <LoaderSpinWrapper {...divProps}>
      <Spin
        size="large"
        {...otherProps}
        indicator={<Loading3QuartersOutlined spin />}
      />
    </LoaderSpinWrapper>
  );
}

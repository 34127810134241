import { DeskExtendedAttributeValue } from 'client/api/backend/schemas';
import { fromPairs, isEmpty, toPairs } from 'lodash';

export function extendedAttributesFromPairs(
  attributeValues: DeskExtendedAttributeValue[]
) {
  return fromPairs(attributeValues.map(v => [v.name, v.value]));
}

export function extendedAttributesToPairs(attributes: Record<string, string>) {
  return toPairs(attributes).map(([name, value]) => ({
    name,
    // Folium non accetta valori nulli
    value: (value as any) ?? ''
  }));
}

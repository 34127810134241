import { DeleteOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { DossierToolkitObject } from 'client/components/widget-repository/objects/dossiers/DossierToolkitObject';
import React from 'react';
import { WidgetHighlightedDossiersProfile } from '../../WidgetHighlightedDossiersProfile';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { set } from 'client/core/data/set';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

export const WidgetActionDeleteHighlightedDossier = createToolkitAction({
  name: 'Rimuovi Fascicolo',
  code: 'delete-highlighted-dossier',
  icon: <DeleteOutlined />,
  arguments: [
    {
      type: [DossierToolkitObject],
      name: 'Fascicolo'
    }
  ],
  async execute(ctx: IToolkitActionContext, dossierObject: ToolkitObject) {
    const dossier = DossierToolkitObject.get(dossierObject)!;

    onConfirm(ctx, {}, dossier);
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: any,
  dossier: ToolkitObject
) {
  try {
    const widget = ctx.deskContext.widgets.find(
      w => w.id === dossier.sourceWidgetId
    );
    const profile = widget?.profile;
    const data = profile?.data as WidgetHighlightedDossiersProfile | null;

    const deletedDossier = dossier.data;

    const updatedData = data?.dossiers?.filter(d => d.id !== deletedDossier.id);

    await ctx.deskContext.updateWidget(
      set(widget!, 'profile.data.dossiers', [...(updatedData ?? [])])
    );

    message.success('Fascicolo rimosso con successo');
  } catch (e) {
    message.error(
      getNetworkErrorMessage(e, 'Impossibile rimuovere il fascicolo')
    );
    console.error(e);
    return false;
  }
}

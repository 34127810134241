/**
 * Generato automaticamente.
 * DeskUI REST API
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type { Panel, PanelCreateDTO, PanelCopyDTO } from '.././schemas';
import {
  apiInstance,
  ErrorType
} from '../../../core/network/mutators/apiClient';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Ritorna la lista dei pannelli presenti
 */
export const getPanels = () => {
  return apiInstance<Panel[]>({ url: `/api/v1/panels`, method: 'get' });
};

export const getGetPanelsQueryKey = () => [`/api/v1/panels`];

export type GetPanelsQueryResult = NonNullable<
  AsyncReturnType<typeof getPanels>
>;
export type GetPanelsQueryError = ErrorType<void>;

export const useGetPanels = <
  TData = AsyncReturnType<typeof getPanels>,
  TError = ErrorType<void>
>(options?: {
  query?: UseQueryOptions<AsyncReturnType<typeof getPanels>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetPanelsQueryKey();

  const queryFn: QueryFunction<AsyncReturnType<typeof getPanels>> = () =>
    getPanels();

  const query = useQuery<AsyncReturnType<typeof getPanels>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions
  );

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Inserisce un pannello
 */
export const postPanels = (panelCreateDTO: PanelCreateDTO) => {
  return apiInstance<Panel | void>({
    url: `/api/v1/panels`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: panelCreateDTO
  });
};

export type PostPanelsMutationResult = NonNullable<
  AsyncReturnType<typeof postPanels>
>;
export type PostPanelsMutationBody = PanelCreateDTO;
export type PostPanelsMutationError = ErrorType<unknown>;

export const usePostPanels = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postPanels>,
    TError,
    { data: PanelCreateDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postPanels>,
    { data: PanelCreateDTO }
  > = props => {
    const { data } = props || {};

    return postPanels(data);
  };

  return useMutation<
    AsyncReturnType<typeof postPanels>,
    TError,
    { data: PanelCreateDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Aggiorna un pannello
 */
export const putPanels = (panel: Panel) => {
  return apiInstance<Panel | void>({
    url: `/api/v1/panels`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: panel
  });
};

export type PutPanelsMutationResult = NonNullable<
  AsyncReturnType<typeof putPanels>
>;
export type PutPanelsMutationBody = Panel;
export type PutPanelsMutationError = ErrorType<unknown>;

export const usePutPanels = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof putPanels>,
    TError,
    { data: Panel },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof putPanels>,
    { data: Panel }
  > = props => {
    const { data } = props || {};

    return putPanels(data);
  };

  return useMutation<
    AsyncReturnType<typeof putPanels>,
    TError,
    { data: Panel },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Ritorna i dati del pannello identificato
 */
export const getPanelsId = (id: number) => {
  return apiInstance<Panel>({ url: `/api/v1/panels/${id}`, method: 'get' });
};

export const getGetPanelsIdQueryKey = (id: number) => [`/api/v1/panels/${id}`];

export type GetPanelsIdQueryResult = NonNullable<
  AsyncReturnType<typeof getPanelsId>
>;
export type GetPanelsIdQueryError = ErrorType<void>;

export const useGetPanelsId = <
  TData = AsyncReturnType<typeof getPanelsId>,
  TError = ErrorType<void>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<typeof getPanelsId>, TError, TData>;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetPanelsIdQueryKey(id);

  const queryFn: QueryFunction<AsyncReturnType<typeof getPanelsId>> = () =>
    getPanelsId(id);

  const query = useQuery<AsyncReturnType<typeof getPanelsId>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions }
  );

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Cancella il pannello identificato
 */
export const deletePanelsId = (id: number) => {
  return apiInstance<void>({ url: `/api/v1/panels/${id}`, method: 'delete' });
};

export type DeletePanelsIdMutationResult = NonNullable<
  AsyncReturnType<typeof deletePanelsId>
>;

export type DeletePanelsIdMutationError = ErrorType<unknown>;

export const useDeletePanelsId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deletePanelsId>,
    TError,
    { id: number },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deletePanelsId>,
    { id: number }
  > = props => {
    const { id } = props || {};

    return deletePanelsId(id);
  };

  return useMutation<
    AsyncReturnType<typeof deletePanelsId>,
    TError,
    { id: number },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Inserisce un pannello
 */
export const postPanelsIdCopy = (id: number, panelCopyDTO: PanelCopyDTO) => {
  return apiInstance<void>({
    url: `/api/v1/panels/${id}/copy`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: panelCopyDTO
  });
};

export type PostPanelsIdCopyMutationResult = NonNullable<
  AsyncReturnType<typeof postPanelsIdCopy>
>;
export type PostPanelsIdCopyMutationBody = PanelCopyDTO;
export type PostPanelsIdCopyMutationError = ErrorType<unknown>;

export const usePostPanelsIdCopy = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postPanelsIdCopy>,
    TError,
    { id: number; data: PanelCopyDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postPanelsIdCopy>,
    { id: number; data: PanelCopyDTO }
  > = props => {
    const { id, data } = props || {};

    return postPanelsIdCopy(id, data);
  };

  return useMutation<
    AsyncReturnType<typeof postPanelsIdCopy>,
    TError,
    { id: number; data: PanelCopyDTO },
    TContext
  >(mutationFn, mutationOptions);
};

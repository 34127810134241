import * as React from 'react';
import { IWidgetComponentToolkitProps } from '../../schema/IWidgetComponentToolkitProps';

export interface IWidgetDocumentSignProps
  extends IWidgetComponentToolkitProps {}

/**
 * Il widget è di proposito vuoto. È stato creato solo per fare in modo che l'azione di firma
 * sia disponibile solo quando è disponibile il widget perché non è possibile al momento
 * rendere non disponibili solo le azioni
 */
export function WidgetDocumentSign(props: IWidgetDocumentSignProps) {
  const { widget } = props;

  return <>Firma Documento</>;
}

import { DeleteOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { set } from 'client/core/data/set';
import { BookmarkToolkitObject } from 'client/components/widget-repository/objects/bookmarks/BookmarksToolkitObject';
import { WidgetBookmarksProfile } from '../WidgetBookmarksProfile';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

export const WidgetActionDeleteBookmark = createToolkitAction({
  name: 'Rimuovi Preferito',
  code: 'delete-bookmark',
  icon: <DeleteOutlined />,
  arguments: [
    {
      type: [BookmarkToolkitObject],
      name: 'Preferito'
    }
  ],
  async execute(ctx: IToolkitActionContext, bookmarkObject: ToolkitObject) {
    const bookmark = BookmarkToolkitObject.get(bookmarkObject)!;

    onConfirm(ctx, {}, bookmark);
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: any,
  bookmark: ToolkitObject
) {
  try {
    const widget = ctx.deskContext.widgets.find(
      w => w.id === bookmark.sourceWidgetId
    );
    const profile = widget?.profile;
    const data = profile?.data as WidgetBookmarksProfile | null;

    const deletedBookmark = bookmark.data;

    const updatedData = data?.bookmarks.filter(
      d => d.name !== deletedBookmark.name && d.url !== deletedBookmark.url
    );

    await ctx.deskContext.updateWidget(
      set(widget!, 'profile.data.bookmarks', [...(updatedData ?? [])])
    );

    message.success('Preferito rimosso con successo');
  } catch (e) {
    message.error(
      getNetworkErrorMessage(e, 'Impossibile rimuovere il preferito')
    );
    console.error(e);
    return false;
  }
}

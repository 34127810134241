import * as React from 'react';
import clx from 'classnames';
import styled from 'styled-components';

const UploadWrapper = styled.div`
  .ant-upload.ant-upload-drag {
    p.ant-upload-drag-icon {
      margin-bottom: 5px;
    }
  }
  > span {
    display: block;
    > .ant-upload {
      display: block;
    }
  }
`;

export interface UploadInputWrapperProps {
  className?: string;
  children: React.ReactNode;
}

/**
 * Container per assegnare gli stili ai componenti Drawer o Upload di Ant
 */
export function UploadInputWrapper(props: UploadInputWrapperProps) {
  return <UploadWrapper>{props.children}</UploadWrapper>;
}

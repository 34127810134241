import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { yup } from 'common/validation/initYup';
import { getDocumentaryAssignmentsIdReturn } from 'client/api/backend/documentary-assignments/documentary-assignments';
import { InferType } from 'yup';
import { message } from 'antd';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { RollbackOutlined } from '@ant-design/icons';
import { AssignmentToolkitObject } from 'client/components/widget-repository/objects/assignments/AssignmentToolkitObject';
import { DeskAssignment } from 'client/api/backend/schemas';
import { AssignmentType } from 'client/components/widget-repository/objects/assignments/mapIdType';
import { AssignmentState } from 'client/components/widget-repository/objects/assignments/mapIdState';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { isAssignmentStale } from 'client/components/widget-repository/objects/assignments/isAssignmentStale';
import { isAssignmentActionAllowed } from 'client/components/widget-repository/objects/assignments/isAssignmentActionAllowed';
import { invalidateAssignmentQueries } from '../invalidateAssignmentQueries';

export const WidgetActionReturnAssignment = createToolkitAction({
  name: 'Restituisci',
  code: 'return-assignment',
  icon: <RollbackOutlined />,
  arguments: [
    {
      name: 'Assegnazione da Restituire',
      type: [AssignmentToolkitObject],
      allows: (obj: ToolkitObject<DeskAssignment>) =>
        ((obj.data.idType?.toString() === AssignmentType.Competenza &&
          obj.data.idState?.toString() ===
            AssignmentState.DaEseguireCompetenza) ||
          obj.data.idType?.toString() === AssignmentType.Smistamento) &&
        !isAssignmentStale(obj.data) &&
        isAssignmentActionAllowed(obj)
    }
  ],
  async execute(ctx: IToolkitActionContext, assignmentObject: ToolkitObject) {
    let assignment = AssignmentToolkitObject.get(assignmentObject)!;

    ctx.deskContext.actionMessages.prompt({
      ctx,
      action: WidgetActionReturnAssignment,
      args: [assignment],
      confirmText: 'Restituisci',
      schema: ReturnSchema,
      onConfirm,
      form: (
        <>
          <FormFieldsContainer>
            <FormFieldItem
              size="large"
              component={TextAreaInput}
              label={null}
              name="reason"
              placeholder="Ragione della restituzione"
            />
          </FormFieldsContainer>
        </>
      )
    });
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: InferType<typeof ReturnSchema>,
  assignment: ToolkitObject<DeskAssignment>
) {
  try {
    await getDocumentaryAssignmentsIdReturn(assignment.data.id!, {
      widgetId: assignment.sourceWidgetId,
      reason: values.reason
    });

    // Invalido la cache
    invalidateAssignmentQueries();

    message.success('Assegnazione restituita');
  } catch (e: any) {
    message.error(getNetworkErrorMessage(e, 'Errore durante la restituzione.'));
    console.error(e);
    return false;
  }
}

const ReturnSchema = yup.object({
  reason: yup.string().required().label('Ragione della restituzione')
});

import { Widget } from 'client/api/backend/schemas';
import { WidgetDisplayLogic } from 'client/components/schema/widget/logic/WidgetDisplayLogic';
import { IWidgetType } from 'client/components/widget-repository/schema/WidgetType';
import {
  registerToolkitObjectType,
  ToolkitObjectType
} from './registerToolkitObjectType';
import { ToolkitObject } from './ToolkitObject';

export interface WidgetToolkitData {
  id: number;
  typeCode: string;
}

export interface WidgetToolkitObjectType
  extends ToolkitObjectType<WidgetToolkitData> {
  /**
   * Crea un oggetto di tipo Widget a partire dal Widget stesso.
   */
  from(widget: Widget): ToolkitObject<Widget>;
  /**
   * Funzione centralizzata per controllare se un qualsiasi object
   * WidgetToolkitObject corrisponde al tipo di Widget desiderato.
   *
   * Può essere usato in modo standard nelle azioni per controllare se il
   * Widget su cui abbiamo effettuato il drop è del tipo desiderato.
   */
  matches(
    object: ToolkitObject<WidgetToolkitData>,
    widgetType: IWidgetType
  ): boolean;
}

/**
 * Introduciamo un concetto generale di ToolkitObject, ovvero il ToolkitObject
 * che rappresenta un **widget**.
 * Per evitarne una creazione ripetitiva, predisponiamo un metodo `from`
 * che crea direttamente il WidgetToolkitObject a partire da un Widget.
 *
 * Viene utilizzato direttamente dalle azioni per riconoscere i Widget,
 * e tramite `allows` possono filtrare solamente i Widget di interesse.
 *
 * @see WidgetToolkitObject.matches
 */
export const WidgetToolkitObject: WidgetToolkitObjectType =
  registerToolkitObjectType<WidgetToolkitData>('widget') as any;

WidgetToolkitObject.from = (widget: Widget) => {
  const titleProps = WidgetDisplayLogic.getTitleProps(widget);

  return WidgetToolkitObject.create({
    id: widget.id!,
    name: titleProps.title,
    icon: titleProps.icon,
    sourceWidgetId: widget.id!,
    data: {
      id: widget.id!,
      typeCode: widget.profile?.typeCode ?? 'unknown'
    }
  });
};

WidgetToolkitObject.matches = (
  object: ToolkitObject<WidgetToolkitData>,
  widgetType: IWidgetType
) => {
  return object.data.typeCode === widgetType.code;
};

/**
 * Generato automaticamente.
 * DeskUI REST API
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  DeskAttachment,
  GetDocumentaryDocumentsAttachmentsIdParams,
  DeskClassificationNode,
  GetDocumentaryDocumentsClassificationsParams,
  ClassificationRequestDTO,
  PostDocumentaryDocumentsClassificationsParams,
  MultipleClassificationRequest,
  PostDocumentaryDocumentsClassificationsMultipleParams,
  DeskDocument,
  DocumentUpdateNotesRequestDTO,
  PostDocumentaryDocumentsNotesParams,
  ProtocolDocumentRequestDTO,
  DeskMailbox,
  GetDocumentaryDocumentsProtocolMailboxesParams,
  OptionItem,
  GetDocumentaryDocumentsProtocolModesParams,
  DocumentQueueRequestDTO,
  PostDocumentaryDocumentsQueueMultipleParams,
  GetDocumentaryDocumentsSearchParams,
  GetDocumentaryDocumentsSearchByIdsParams,
  DeskMean,
  GetDocumentaryDocumentsSendMeansParams,
  SignRequestBody,
  PostDocumentaryDocumentsSignParams,
  GetDocumentaryDocumentsSignDomainsParams,
  GetDocumentaryDocumentsTypesParams,
  DeskExtendedType,
  GetDocumentaryDocumentsTypesIdParams,
  DocumentUploadRequestDTO,
  DocumentUploadSignedRequestDTO,
  GetDocumentaryDocumentsIdParams,
  UpdateDocumentRequestDTO,
  PutDocumentaryDocumentsIdParams,
  DeleteDocumentaryDocumentsIdParams,
  DeskAssignment,
  GetDocumentaryDocumentsIdAssignmentsParams,
  DeskDossier,
  GetDocumentaryDocumentsIdDossiersParams,
  GetDocumentaryDocumentsIdRestoreParams,
  PostDocumentaryDocumentsIdSignParams,
  DocumentAddTagRequestDTO,
  DeleteDocumentaryDocumentsIdTagTagIdParams
} from '.././schemas';
import {
  apiInstance,
  ErrorType
} from '../../../core/network/mutators/apiClient';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Allegato del documento
 */
export const getDocumentaryDocumentsAttachmentsId = (
  id: number,
  params?: GetDocumentaryDocumentsAttachmentsIdParams
) => {
  return apiInstance<DeskAttachment>({
    url: `/api/v1/documentary/documents/attachments/${id}`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDocumentsAttachmentsIdQueryKey = (
  id: number,
  params?: GetDocumentaryDocumentsAttachmentsIdParams
) => [
  `/api/v1/documentary/documents/attachments/${id}`,
  ...(params ? [params] : [])
];

export type GetDocumentaryDocumentsAttachmentsIdQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDocumentsAttachmentsId>
>;
export type GetDocumentaryDocumentsAttachmentsIdQueryError = ErrorType<void>;

export const useGetDocumentaryDocumentsAttachmentsId = <
  TData = AsyncReturnType<typeof getDocumentaryDocumentsAttachmentsId>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetDocumentaryDocumentsAttachmentsIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDocumentsAttachmentsId>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDocumentsAttachmentsIdQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDocumentsAttachmentsId>
  > = () => getDocumentaryDocumentsAttachmentsId(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDocumentsAttachmentsId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Lista classifiche assegnabili ai documenti
 */
export const getDocumentaryDocumentsClassifications = (
  params?: GetDocumentaryDocumentsClassificationsParams
) => {
  return apiInstance<DeskClassificationNode[]>({
    url: `/api/v1/documentary/documents/classifications`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDocumentsClassificationsQueryKey = (
  params?: GetDocumentaryDocumentsClassificationsParams
) => [
  `/api/v1/documentary/documents/classifications`,
  ...(params ? [params] : [])
];

export type GetDocumentaryDocumentsClassificationsQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDocumentsClassifications>
>;
export type GetDocumentaryDocumentsClassificationsQueryError = ErrorType<void>;

export const useGetDocumentaryDocumentsClassifications = <
  TData = AsyncReturnType<typeof getDocumentaryDocumentsClassifications>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDocumentsClassificationsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDocumentsClassifications>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDocumentsClassificationsQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDocumentsClassifications>
  > = () => getDocumentaryDocumentsClassifications(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDocumentsClassifications>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Classificazione di un documento
 */
export const postDocumentaryDocumentsClassifications = (
  classificationRequestDTO: ClassificationRequestDTO,
  params?: PostDocumentaryDocumentsClassificationsParams
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/documents/classifications`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: classificationRequestDTO,
    params
  });
};

export type PostDocumentaryDocumentsClassificationsMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryDocumentsClassifications>
>;
export type PostDocumentaryDocumentsClassificationsMutationBody =
  ClassificationRequestDTO;
export type PostDocumentaryDocumentsClassificationsMutationError =
  ErrorType<unknown>;

export const usePostDocumentaryDocumentsClassifications = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryDocumentsClassifications>,
    TError,
    {
      data: ClassificationRequestDTO;
      params?: PostDocumentaryDocumentsClassificationsParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryDocumentsClassifications>,
    {
      data: ClassificationRequestDTO;
      params?: PostDocumentaryDocumentsClassificationsParams;
    }
  > = props => {
    const { data, params } = props || {};

    return postDocumentaryDocumentsClassifications(data, params);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryDocumentsClassifications>,
    TError,
    {
      data: ClassificationRequestDTO;
      params?: PostDocumentaryDocumentsClassificationsParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Classificazione multipla di documenti
 */
export const postDocumentaryDocumentsClassificationsMultiple = (
  multipleClassificationRequest: MultipleClassificationRequest,
  params?: PostDocumentaryDocumentsClassificationsMultipleParams
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/documents/classifications/multiple`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: multipleClassificationRequest,
    params
  });
};

export type PostDocumentaryDocumentsClassificationsMultipleMutationResult =
  NonNullable<
    AsyncReturnType<typeof postDocumentaryDocumentsClassificationsMultiple>
  >;
export type PostDocumentaryDocumentsClassificationsMultipleMutationBody =
  MultipleClassificationRequest;
export type PostDocumentaryDocumentsClassificationsMultipleMutationError =
  ErrorType<unknown>;

export const usePostDocumentaryDocumentsClassificationsMultiple = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryDocumentsClassificationsMultiple>,
    TError,
    {
      data: MultipleClassificationRequest;
      params?: PostDocumentaryDocumentsClassificationsMultipleParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryDocumentsClassificationsMultiple>,
    {
      data: MultipleClassificationRequest;
      params?: PostDocumentaryDocumentsClassificationsMultipleParams;
    }
  > = props => {
    const { data, params } = props || {};

    return postDocumentaryDocumentsClassificationsMultiple(data, params);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryDocumentsClassificationsMultiple>,
    TError,
    {
      data: MultipleClassificationRequest;
      params?: PostDocumentaryDocumentsClassificationsMultipleParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Modifica note documento
 */
export const postDocumentaryDocumentsNotes = (
  documentUpdateNotesRequestDTO: DocumentUpdateNotesRequestDTO,
  params?: PostDocumentaryDocumentsNotesParams
) => {
  return apiInstance<DeskDocument | void>({
    url: `/api/v1/documentary/documents/notes`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: documentUpdateNotesRequestDTO,
    params
  });
};

export type PostDocumentaryDocumentsNotesMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryDocumentsNotes>
>;
export type PostDocumentaryDocumentsNotesMutationBody =
  DocumentUpdateNotesRequestDTO;
export type PostDocumentaryDocumentsNotesMutationError = ErrorType<unknown>;

export const usePostDocumentaryDocumentsNotes = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryDocumentsNotes>,
    TError,
    {
      data: DocumentUpdateNotesRequestDTO;
      params?: PostDocumentaryDocumentsNotesParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryDocumentsNotes>,
    {
      data: DocumentUpdateNotesRequestDTO;
      params?: PostDocumentaryDocumentsNotesParams;
    }
  > = props => {
    const { data, params } = props || {};

    return postDocumentaryDocumentsNotes(data, params);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryDocumentsNotes>,
    TError,
    {
      data: DocumentUpdateNotesRequestDTO;
      params?: PostDocumentaryDocumentsNotesParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Protocolla il documento
 */
export const postDocumentaryDocumentsProtocol = (
  protocolDocumentRequestDTO: ProtocolDocumentRequestDTO
) => {
  return apiInstance<DeskDocument | void>({
    url: `/api/v1/documentary/documents/protocol`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: protocolDocumentRequestDTO
  });
};

export type PostDocumentaryDocumentsProtocolMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryDocumentsProtocol>
>;
export type PostDocumentaryDocumentsProtocolMutationBody =
  ProtocolDocumentRequestDTO;
export type PostDocumentaryDocumentsProtocolMutationError = ErrorType<unknown>;

export const usePostDocumentaryDocumentsProtocol = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryDocumentsProtocol>,
    TError,
    { data: ProtocolDocumentRequestDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryDocumentsProtocol>,
    { data: ProtocolDocumentRequestDTO }
  > = props => {
    const { data } = props || {};

    return postDocumentaryDocumentsProtocol(data);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryDocumentsProtocol>,
    TError,
    { data: ProtocolDocumentRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Ritorna la lista delle caselle mail da usare per la protocollazione in uscita
 */
export const getDocumentaryDocumentsProtocolMailboxes = (
  params?: GetDocumentaryDocumentsProtocolMailboxesParams
) => {
  return apiInstance<DeskMailbox[]>({
    url: `/api/v1/documentary/documents/protocol/mailboxes`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDocumentsProtocolMailboxesQueryKey = (
  params?: GetDocumentaryDocumentsProtocolMailboxesParams
) => [
  `/api/v1/documentary/documents/protocol/mailboxes`,
  ...(params ? [params] : [])
];

export type GetDocumentaryDocumentsProtocolMailboxesQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDocumentsProtocolMailboxes>
>;
export type GetDocumentaryDocumentsProtocolMailboxesQueryError =
  ErrorType<void>;

export const useGetDocumentaryDocumentsProtocolMailboxes = <
  TData = AsyncReturnType<typeof getDocumentaryDocumentsProtocolMailboxes>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDocumentsProtocolMailboxesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDocumentsProtocolMailboxes>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDocumentsProtocolMailboxesQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDocumentsProtocolMailboxes>
  > = () => getDocumentaryDocumentsProtocolMailboxes(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDocumentsProtocolMailboxes>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna la lista delle modalità di protocollazione
 */
export const getDocumentaryDocumentsProtocolModes = (
  params?: GetDocumentaryDocumentsProtocolModesParams
) => {
  return apiInstance<OptionItem[]>({
    url: `/api/v1/documentary/documents/protocol/modes`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDocumentsProtocolModesQueryKey = (
  params?: GetDocumentaryDocumentsProtocolModesParams
) => [
  `/api/v1/documentary/documents/protocol/modes`,
  ...(params ? [params] : [])
];

export type GetDocumentaryDocumentsProtocolModesQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDocumentsProtocolModes>
>;
export type GetDocumentaryDocumentsProtocolModesQueryError = ErrorType<void>;

export const useGetDocumentaryDocumentsProtocolModes = <
  TData = AsyncReturnType<typeof getDocumentaryDocumentsProtocolModes>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDocumentsProtocolModesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDocumentsProtocolModes>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDocumentsProtocolModesQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDocumentsProtocolModes>
  > = () => getDocumentaryDocumentsProtocolModes(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDocumentsProtocolModes>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Invia al protocollo N documenti
 */
export const postDocumentaryDocumentsQueueMultiple = (
  documentQueueRequestDTO: DocumentQueueRequestDTO[],
  params?: PostDocumentaryDocumentsQueueMultipleParams
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/documents/queue/multiple`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: documentQueueRequestDTO,
    params
  });
};

export type PostDocumentaryDocumentsQueueMultipleMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryDocumentsQueueMultiple>
>;
export type PostDocumentaryDocumentsQueueMultipleMutationBody =
  DocumentQueueRequestDTO[];
export type PostDocumentaryDocumentsQueueMultipleMutationError =
  ErrorType<unknown>;

export const usePostDocumentaryDocumentsQueueMultiple = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryDocumentsQueueMultiple>,
    TError,
    {
      data: DocumentQueueRequestDTO[];
      params?: PostDocumentaryDocumentsQueueMultipleParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryDocumentsQueueMultiple>,
    {
      data: DocumentQueueRequestDTO[];
      params?: PostDocumentaryDocumentsQueueMultipleParams;
    }
  > = props => {
    const { data, params } = props || {};

    return postDocumentaryDocumentsQueueMultiple(data, params);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryDocumentsQueueMultiple>,
    TError,
    {
      data: DocumentQueueRequestDTO[];
      params?: PostDocumentaryDocumentsQueueMultipleParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Invia un documento alla coda di protocollazione
 */
export const postDocumentaryDocumentsQueueSingle = (
  documentQueueRequestDTO: DocumentQueueRequestDTO
) => {
  return apiInstance<DeskDocument | void>({
    url: `/api/v1/documentary/documents/queue/single`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: documentQueueRequestDTO
  });
};

export type PostDocumentaryDocumentsQueueSingleMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryDocumentsQueueSingle>
>;
export type PostDocumentaryDocumentsQueueSingleMutationBody =
  DocumentQueueRequestDTO;
export type PostDocumentaryDocumentsQueueSingleMutationError =
  ErrorType<unknown>;

export const usePostDocumentaryDocumentsQueueSingle = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryDocumentsQueueSingle>,
    TError,
    { data: DocumentQueueRequestDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryDocumentsQueueSingle>,
    { data: DocumentQueueRequestDTO }
  > = props => {
    const { data } = props || {};

    return postDocumentaryDocumentsQueueSingle(data);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryDocumentsQueueSingle>,
    TError,
    { data: DocumentQueueRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * I valori accettati per il searchMode sono: assignment,dossier,registry
 * @summary Ricerca protocolli
 */
export const getDocumentaryDocumentsSearch = (
  params?: GetDocumentaryDocumentsSearchParams
) => {
  return apiInstance<DeskDocument[]>({
    url: `/api/v1/documentary/documents/search`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDocumentsSearchQueryKey = (
  params?: GetDocumentaryDocumentsSearchParams
) => [`/api/v1/documentary/documents/search`, ...(params ? [params] : [])];

export type GetDocumentaryDocumentsSearchQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDocumentsSearch>
>;
export type GetDocumentaryDocumentsSearchQueryError = ErrorType<void>;

export const useGetDocumentaryDocumentsSearch = <
  TData = AsyncReturnType<typeof getDocumentaryDocumentsSearch>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDocumentsSearchParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDocumentsSearch>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryDocumentsSearchQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDocumentsSearch>
  > = () => getDocumentaryDocumentsSearch(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDocumentsSearch>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * I valori accettati per il searchMode sono: assignment,dossier,registry
 * @summary Ricerca protocolli per identificativo
 */
export const getDocumentaryDocumentsSearchByIds = (
  params?: GetDocumentaryDocumentsSearchByIdsParams
) => {
  return apiInstance<DeskDocument[]>({
    url: `/api/v1/documentary/documents/searchByIds`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDocumentsSearchByIdsQueryKey = (
  params?: GetDocumentaryDocumentsSearchByIdsParams
) => [`/api/v1/documentary/documents/searchByIds`, ...(params ? [params] : [])];

export type GetDocumentaryDocumentsSearchByIdsQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDocumentsSearchByIds>
>;
export type GetDocumentaryDocumentsSearchByIdsQueryError = ErrorType<void>;

export const useGetDocumentaryDocumentsSearchByIds = <
  TData = AsyncReturnType<typeof getDocumentaryDocumentsSearchByIds>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDocumentsSearchByIdsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDocumentsSearchByIds>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDocumentsSearchByIdsQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDocumentsSearchByIds>
  > = () => getDocumentaryDocumentsSearchByIds(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDocumentsSearchByIds>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna la lista delle mezzi di spedizione
 */
export const getDocumentaryDocumentsSendMeans = (
  params?: GetDocumentaryDocumentsSendMeansParams
) => {
  return apiInstance<DeskMean[]>({
    url: `/api/v1/documentary/documents/send/means`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDocumentsSendMeansQueryKey = (
  params?: GetDocumentaryDocumentsSendMeansParams
) => [`/api/v1/documentary/documents/send/means`, ...(params ? [params] : [])];

export type GetDocumentaryDocumentsSendMeansQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDocumentsSendMeans>
>;
export type GetDocumentaryDocumentsSendMeansQueryError = ErrorType<void>;

export const useGetDocumentaryDocumentsSendMeans = <
  TData = AsyncReturnType<typeof getDocumentaryDocumentsSendMeans>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDocumentsSendMeansParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDocumentsSendMeans>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDocumentsSendMeansQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDocumentsSendMeans>
  > = () => getDocumentaryDocumentsSendMeans(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDocumentsSendMeans>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Firma N documenti
 */
export const postDocumentaryDocumentsSign = (
  signRequestBody: SignRequestBody,
  params?: PostDocumentaryDocumentsSignParams
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/documents/sign`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: signRequestBody,
    params
  });
};

export type PostDocumentaryDocumentsSignMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryDocumentsSign>
>;
export type PostDocumentaryDocumentsSignMutationBody = SignRequestBody;
export type PostDocumentaryDocumentsSignMutationError = ErrorType<unknown>;

export const usePostDocumentaryDocumentsSign = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryDocumentsSign>,
    TError,
    { data: SignRequestBody; params?: PostDocumentaryDocumentsSignParams },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryDocumentsSign>,
    { data: SignRequestBody; params?: PostDocumentaryDocumentsSignParams }
  > = props => {
    const { data, params } = props || {};

    return postDocumentaryDocumentsSign(data, params);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryDocumentsSign>,
    TError,
    { data: SignRequestBody; params?: PostDocumentaryDocumentsSignParams },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Ritorna la lista dei domain per la firma
 */
export const getDocumentaryDocumentsSignDomains = (
  params?: GetDocumentaryDocumentsSignDomainsParams
) => {
  return apiInstance<string[]>({
    url: `/api/v1/documentary/documents/sign/domains`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDocumentsSignDomainsQueryKey = (
  params?: GetDocumentaryDocumentsSignDomainsParams
) => [
  `/api/v1/documentary/documents/sign/domains`,
  ...(params ? [params] : [])
];

export type GetDocumentaryDocumentsSignDomainsQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDocumentsSignDomains>
>;
export type GetDocumentaryDocumentsSignDomainsQueryError = ErrorType<void>;

export const useGetDocumentaryDocumentsSignDomains = <
  TData = AsyncReturnType<typeof getDocumentaryDocumentsSignDomains>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDocumentsSignDomainsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDocumentsSignDomains>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDocumentsSignDomainsQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDocumentsSignDomains>
  > = () => getDocumentaryDocumentsSignDomains(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDocumentsSignDomains>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna la lista delle tipologie di documento
 */
export const getDocumentaryDocumentsTypes = (
  params?: GetDocumentaryDocumentsTypesParams
) => {
  return apiInstance<OptionItem[]>({
    url: `/api/v1/documentary/documents/types`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDocumentsTypesQueryKey = (
  params?: GetDocumentaryDocumentsTypesParams
) => [`/api/v1/documentary/documents/types`, ...(params ? [params] : [])];

export type GetDocumentaryDocumentsTypesQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDocumentsTypes>
>;
export type GetDocumentaryDocumentsTypesQueryError = ErrorType<void>;

export const useGetDocumentaryDocumentsTypes = <
  TData = AsyncReturnType<typeof getDocumentaryDocumentsTypes>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryDocumentsTypesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDocumentsTypes>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryDocumentsTypesQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDocumentsTypes>
  > = () => getDocumentaryDocumentsTypes(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDocumentsTypes>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna la lista dei campi profilati per tipologia documento
 */
export const getDocumentaryDocumentsTypesId = (
  id: number,
  params?: GetDocumentaryDocumentsTypesIdParams
) => {
  return apiInstance<DeskExtendedType>({
    url: `/api/v1/documentary/documents/types/${id}`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDocumentsTypesIdQueryKey = (
  id: number,
  params?: GetDocumentaryDocumentsTypesIdParams
) => [`/api/v1/documentary/documents/types/${id}`, ...(params ? [params] : [])];

export type GetDocumentaryDocumentsTypesIdQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDocumentsTypesId>
>;
export type GetDocumentaryDocumentsTypesIdQueryError = ErrorType<void>;

export const useGetDocumentaryDocumentsTypesId = <
  TData = AsyncReturnType<typeof getDocumentaryDocumentsTypesId>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetDocumentaryDocumentsTypesIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDocumentsTypesId>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDocumentsTypesIdQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDocumentsTypesId>
  > = () => getDocumentaryDocumentsTypesId(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDocumentsTypesId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Esegue upload di un documento
 */
export const postDocumentaryDocumentsUpload = (
  documentUploadRequestDTO: DocumentUploadRequestDTO
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/documents/upload`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: documentUploadRequestDTO
  });
};

export type PostDocumentaryDocumentsUploadMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryDocumentsUpload>
>;
export type PostDocumentaryDocumentsUploadMutationBody =
  DocumentUploadRequestDTO;
export type PostDocumentaryDocumentsUploadMutationError = ErrorType<unknown>;

export const usePostDocumentaryDocumentsUpload = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryDocumentsUpload>,
    TError,
    { data: DocumentUploadRequestDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryDocumentsUpload>,
    { data: DocumentUploadRequestDTO }
  > = props => {
    const { data } = props || {};

    return postDocumentaryDocumentsUpload(data);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryDocumentsUpload>,
    TError,
    { data: DocumentUploadRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Esegue upload di un documento firmato
 */
export const postDocumentaryDocumentsUploadSigned = (
  documentUploadSignedRequestDTO: DocumentUploadSignedRequestDTO
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/documents/upload/signed`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: documentUploadSignedRequestDTO
  });
};

export type PostDocumentaryDocumentsUploadSignedMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryDocumentsUploadSigned>
>;
export type PostDocumentaryDocumentsUploadSignedMutationBody =
  DocumentUploadSignedRequestDTO;
export type PostDocumentaryDocumentsUploadSignedMutationError =
  ErrorType<unknown>;

export const usePostDocumentaryDocumentsUploadSigned = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryDocumentsUploadSigned>,
    TError,
    { data: DocumentUploadSignedRequestDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryDocumentsUploadSigned>,
    { data: DocumentUploadSignedRequestDTO }
  > = props => {
    const { data } = props || {};

    return postDocumentaryDocumentsUploadSigned(data);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryDocumentsUploadSigned>,
    TError,
    { data: DocumentUploadSignedRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Ritorna il documento identificato
 */
export const getDocumentaryDocumentsId = (
  id: number,
  params?: GetDocumentaryDocumentsIdParams
) => {
  return apiInstance<DeskDocument>({
    url: `/api/v1/documentary/documents/${id}`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDocumentsIdQueryKey = (
  id: number,
  params?: GetDocumentaryDocumentsIdParams
) => [`/api/v1/documentary/documents/${id}`, ...(params ? [params] : [])];

export type GetDocumentaryDocumentsIdQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDocumentsId>
>;
export type GetDocumentaryDocumentsIdQueryError = ErrorType<void>;

export const useGetDocumentaryDocumentsId = <
  TData = AsyncReturnType<typeof getDocumentaryDocumentsId>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetDocumentaryDocumentsIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDocumentsId>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryDocumentsIdQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDocumentsId>
  > = () => getDocumentaryDocumentsId(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDocumentsId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Aggiorna il documento identificato
 */
export const putDocumentaryDocumentsId = (
  id: number,
  updateDocumentRequestDTO: UpdateDocumentRequestDTO,
  params?: PutDocumentaryDocumentsIdParams
) => {
  return apiInstance<DeskDocument | void>({
    url: `/api/v1/documentary/documents/${id}`,
    method: 'put',
    headers: { 'Content-Type': 'application/json' },
    data: updateDocumentRequestDTO,
    params
  });
};

export type PutDocumentaryDocumentsIdMutationResult = NonNullable<
  AsyncReturnType<typeof putDocumentaryDocumentsId>
>;
export type PutDocumentaryDocumentsIdMutationBody = UpdateDocumentRequestDTO;
export type PutDocumentaryDocumentsIdMutationError = ErrorType<unknown>;

export const usePutDocumentaryDocumentsId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof putDocumentaryDocumentsId>,
    TError,
    {
      id: number;
      data: UpdateDocumentRequestDTO;
      params?: PutDocumentaryDocumentsIdParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof putDocumentaryDocumentsId>,
    {
      id: number;
      data: UpdateDocumentRequestDTO;
      params?: PutDocumentaryDocumentsIdParams;
    }
  > = props => {
    const { id, data, params } = props || {};

    return putDocumentaryDocumentsId(id, data, params);
  };

  return useMutation<
    AsyncReturnType<typeof putDocumentaryDocumentsId>,
    TError,
    {
      id: number;
      data: UpdateDocumentRequestDTO;
      params?: PutDocumentaryDocumentsIdParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Cancella un documento
 */
export const deleteDocumentaryDocumentsId = (
  id: number,
  params?: DeleteDocumentaryDocumentsIdParams
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/documents/${id}`,
    method: 'delete',
    params
  });
};

export type DeleteDocumentaryDocumentsIdMutationResult = NonNullable<
  AsyncReturnType<typeof deleteDocumentaryDocumentsId>
>;

export type DeleteDocumentaryDocumentsIdMutationError = ErrorType<unknown>;

export const useDeleteDocumentaryDocumentsId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteDocumentaryDocumentsId>,
    TError,
    { id: number; params?: DeleteDocumentaryDocumentsIdParams },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deleteDocumentaryDocumentsId>,
    { id: number; params?: DeleteDocumentaryDocumentsIdParams }
  > = props => {
    const { id, params } = props || {};

    return deleteDocumentaryDocumentsId(id, params);
  };

  return useMutation<
    AsyncReturnType<typeof deleteDocumentaryDocumentsId>,
    TError,
    { id: number; params?: DeleteDocumentaryDocumentsIdParams },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Storico assegnazioni del documento
 */
export const getDocumentaryDocumentsIdAssignments = (
  id: number,
  params?: GetDocumentaryDocumentsIdAssignmentsParams
) => {
  return apiInstance<DeskAssignment[]>({
    url: `/api/v1/documentary/documents/${id}/assignments`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDocumentsIdAssignmentsQueryKey = (
  id: number,
  params?: GetDocumentaryDocumentsIdAssignmentsParams
) => [
  `/api/v1/documentary/documents/${id}/assignments`,
  ...(params ? [params] : [])
];

export type GetDocumentaryDocumentsIdAssignmentsQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDocumentsIdAssignments>
>;
export type GetDocumentaryDocumentsIdAssignmentsQueryError = ErrorType<void>;

export const useGetDocumentaryDocumentsIdAssignments = <
  TData = AsyncReturnType<typeof getDocumentaryDocumentsIdAssignments>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetDocumentaryDocumentsIdAssignmentsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDocumentsIdAssignments>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDocumentsIdAssignmentsQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDocumentsIdAssignments>
  > = () => getDocumentaryDocumentsIdAssignments(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDocumentsIdAssignments>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna la lista dei fascicoli nei quali è presente un documento
 */
export const getDocumentaryDocumentsIdDossiers = (
  id: number,
  params?: GetDocumentaryDocumentsIdDossiersParams
) => {
  return apiInstance<DeskDossier[]>({
    url: `/api/v1/documentary/documents/${id}/dossiers`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDocumentsIdDossiersQueryKey = (
  id: number,
  params?: GetDocumentaryDocumentsIdDossiersParams
) => [
  `/api/v1/documentary/documents/${id}/dossiers`,
  ...(params ? [params] : [])
];

export type GetDocumentaryDocumentsIdDossiersQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDocumentsIdDossiers>
>;
export type GetDocumentaryDocumentsIdDossiersQueryError = ErrorType<void>;

export const useGetDocumentaryDocumentsIdDossiers = <
  TData = AsyncReturnType<typeof getDocumentaryDocumentsIdDossiers>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetDocumentaryDocumentsIdDossiersParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDocumentsIdDossiers>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDocumentsIdDossiersQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDocumentsIdDossiers>
  > = () => getDocumentaryDocumentsIdDossiers(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDocumentsIdDossiers>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Recupera un documento documento precedentemente rifiutato
 */
export const getDocumentaryDocumentsIdRestore = (
  id: number,
  params?: GetDocumentaryDocumentsIdRestoreParams
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/documents/${id}/restore`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryDocumentsIdRestoreQueryKey = (
  id: number,
  params?: GetDocumentaryDocumentsIdRestoreParams
) => [
  `/api/v1/documentary/documents/${id}/restore`,
  ...(params ? [params] : [])
];

export type GetDocumentaryDocumentsIdRestoreQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryDocumentsIdRestore>
>;
export type GetDocumentaryDocumentsIdRestoreQueryError = ErrorType<unknown>;

export const useGetDocumentaryDocumentsIdRestore = <
  TData = AsyncReturnType<typeof getDocumentaryDocumentsIdRestore>,
  TError = ErrorType<unknown>
>(
  id: number,
  params?: GetDocumentaryDocumentsIdRestoreParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryDocumentsIdRestore>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryDocumentsIdRestoreQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryDocumentsIdRestore>
  > = () => getDocumentaryDocumentsIdRestore(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryDocumentsIdRestore>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Firma un documento
 */
export const postDocumentaryDocumentsIdSign = (
  id: number,
  signRequestBody: SignRequestBody,
  params?: PostDocumentaryDocumentsIdSignParams
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/documents/${id}/sign`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: signRequestBody,
    params
  });
};

export type PostDocumentaryDocumentsIdSignMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryDocumentsIdSign>
>;
export type PostDocumentaryDocumentsIdSignMutationBody = SignRequestBody;
export type PostDocumentaryDocumentsIdSignMutationError = ErrorType<unknown>;

export const usePostDocumentaryDocumentsIdSign = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryDocumentsIdSign>,
    TError,
    {
      id: number;
      data: SignRequestBody;
      params?: PostDocumentaryDocumentsIdSignParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryDocumentsIdSign>,
    {
      id: number;
      data: SignRequestBody;
      params?: PostDocumentaryDocumentsIdSignParams;
    }
  > = props => {
    const { id, data, params } = props || {};

    return postDocumentaryDocumentsIdSign(id, data, params);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryDocumentsIdSign>,
    TError,
    {
      id: number;
      data: SignRequestBody;
      params?: PostDocumentaryDocumentsIdSignParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Inserisce un tag sul documento
 */
export const postDocumentaryDocumentsIdTag = (
  id: number,
  documentAddTagRequestDTO: DocumentAddTagRequestDTO
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/documents/${id}/tag`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: documentAddTagRequestDTO
  });
};

export type PostDocumentaryDocumentsIdTagMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryDocumentsIdTag>
>;
export type PostDocumentaryDocumentsIdTagMutationBody =
  DocumentAddTagRequestDTO;
export type PostDocumentaryDocumentsIdTagMutationError = ErrorType<unknown>;

export const usePostDocumentaryDocumentsIdTag = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryDocumentsIdTag>,
    TError,
    { id: number; data: DocumentAddTagRequestDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryDocumentsIdTag>,
    { id: number; data: DocumentAddTagRequestDTO }
  > = props => {
    const { id, data } = props || {};

    return postDocumentaryDocumentsIdTag(id, data);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryDocumentsIdTag>,
    TError,
    { id: number; data: DocumentAddTagRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Rimuove un tag sul documento
 */
export const deleteDocumentaryDocumentsIdTagTagId = (
  id: number,
  tagId: number,
  params?: DeleteDocumentaryDocumentsIdTagTagIdParams
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/documents/${id}/tag/${tagId}`,
    method: 'delete',
    params
  });
};

export type DeleteDocumentaryDocumentsIdTagTagIdMutationResult = NonNullable<
  AsyncReturnType<typeof deleteDocumentaryDocumentsIdTagTagId>
>;

export type DeleteDocumentaryDocumentsIdTagTagIdMutationError =
  ErrorType<unknown>;

export const useDeleteDocumentaryDocumentsIdTagTagId = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteDocumentaryDocumentsIdTagTagId>,
    TError,
    {
      id: number;
      tagId: number;
      params?: DeleteDocumentaryDocumentsIdTagTagIdParams;
    },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deleteDocumentaryDocumentsIdTagTagId>,
    {
      id: number;
      tagId: number;
      params?: DeleteDocumentaryDocumentsIdTagTagIdParams;
    }
  > = props => {
    const { id, tagId, params } = props || {};

    return deleteDocumentaryDocumentsIdTagTagId(id, tagId, params);
  };

  return useMutation<
    AsyncReturnType<typeof deleteDocumentaryDocumentsIdTagTagId>,
    TError,
    {
      id: number;
      tagId: number;
      params?: DeleteDocumentaryDocumentsIdTagTagIdParams;
    },
    TContext
  >(mutationFn, mutationOptions);
};

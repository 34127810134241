import { DeleteOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { deleteDocumentaryDocumentsId } from 'client/api/backend/documentary-documents/documentary-documents';
import { getGetDocumentaryDossiersIdFilesQueryKey } from 'client/api/backend/documentary-dossiers/documentary-dossiers';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import {
  DocumentToolkitObject,
  ExtendedDeskDocument
} from 'client/components/widget-repository/objects/documents/DocumentToolkitObject';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { queryClient } from 'client/core/network/queryClient';
import { yup } from 'common/validation/initYup';
import React from 'react';
import { InferType } from 'yup';

export const WidgetActionRemoveFromDossier = createToolkitAction({
  name: 'Rimuovi Documento',
  code: 'remove-document-from-dossier',
  icon: <DeleteOutlined />,
  arguments: [
    {
      type: [DocumentToolkitObject],
      name: 'Documento',
      allows: (document: ToolkitObject<ExtendedDeskDocument>) =>
        document.data.folderId != null
    }
  ],
  async execute(ctx: IToolkitActionContext, documentObject: ToolkitObject) {
    const document = DocumentToolkitObject.get(documentObject)!;

    ctx.deskContext.actionMessages.prompt({
      ctx,
      args: [document],
      action: WidgetActionRemoveFromDossier,
      title: 'Rimuovi documento dal fascicolo',
      confirmText: 'Rimuovi',
      confirmProps: { danger: true },
      schema: RemoveFromDossierSchema,
      onConfirm
    });
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: RemoveFromDossierSchemaType,
  document: ToolkitObject<ExtendedDeskDocument>
) {
  try {
    await removeDocument(values, document);
    message.success('Documento rimosso con successo.');
  } catch (e) {
    message.error(
      getNetworkErrorMessage(e, 'Errore durante la rimozione del documento.')
    );
    console.error(e);
    return false;
  }
}

async function removeDocument(
  values: RemoveFromDossierSchemaType,
  document: ToolkitObject<ExtendedDeskDocument>
) {
  await deleteDocumentaryDocumentsId(document.id as number, {
    folderId: document.data.folderId as number,
    widgetId: document.sourceWidgetId
  });

  const deskFilesQueryKey = getGetDocumentaryDossiersIdFilesQueryKey(
    document.data.folderId as number,
    {
      folderId: document.data.folderId as number,
      widgetId: document.sourceWidgetId
    }
  );

  queryClient.invalidateQueries({
    predicate: query => {
      return Boolean(
        query.queryKey &&
          Array.isArray(query.queryKey) &&
          query.queryKey[0] === deskFilesQueryKey[0] &&
          query.queryKey[1]?.folderId === document.data.folderId
      );
    }
  });

  // Invalido la cache
  const dossierFilesQueryKey = getGetDocumentaryDossiersIdFilesQueryKey(
    document.data.folderId as number
  )[0];

  queryClient.invalidateQueries({
    predicate: query => {
      return Boolean(
        query.queryKey &&
          Array.isArray(query.queryKey) &&
          query.queryKey[0] === dossierFilesQueryKey
      );
    }
  });
}

const RemoveFromDossierSchema = yup.object({});
type RemoveFromDossierSchemaType = InferType<typeof RemoveFromDossierSchema>;

import { yup } from 'common/validation/initYup';

export const AssignSchema = yup.object({
  modeId: yup.number().required().label('Tipo Assegnazione'),
  priorityId: yup.number().notRequired().label('Priorità'),
  notes: yup.string().notRequired().label('Note'),
  expiresAt: yup.number().notRequired().label('Data Scadenza'),
  conclude: yup.boolean().notRequired().label('Concludi'),
  fromAssignmentIds: yup
    .array()
    .of(yup.number().nullable().notRequired())
    .required()
    .label('Id Assegnazioni Precedenti'),
  isReassignment: yup.boolean().required().label('Riassegnazione')
});

import { BookOutlined } from '@ant-design/icons';
import { Widget } from 'client/api/backend/schemas';
import {
  BookmarkToolkitObject,
  DeskBookmark
} from 'client/components/widget-repository/objects/bookmarks/BookmarksToolkitObject';
import React from 'react';

export function convertBookmarkToWidgetListElement(
  bookmark: DeskBookmark,
  widget: Widget
) {
  const path = bookmark.url.startsWith('http')
    ? bookmark.url
    : `https://${bookmark.url}`;

  return {
    name: bookmark.name,
    description: (
      <a href={path} target="_blank">
        {bookmark.url}
      </a>
    ),
    type: 'bookmark',
    object: BookmarkToolkitObject.create({
      sourceWidgetId: widget.id!,
      name: 'Preferiti',
      icon: <BookOutlined />,
      id: widget.id!,
      data: bookmark
    })
  };
}

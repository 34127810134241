import { useGetDocumentaryDossiersStates } from 'client/api/backend/documentary-dossiers/documentary-dossiers';

export function useGetDossierStateLabel(widgetId: number, stateId?: number) {
  const statesResult = useGetDocumentaryDossiersStates(
    {
      widgetId
    },
    {
      query: {
        enabled: stateId != null
      }
    }
  );

  return (
    statesResult.data?.find(state => state.value === stateId?.toString())
      ?.label || ''
  );
}

import React, { useState } from 'react';
import { Button, message, Modal } from 'antd';
import { getUsersIdPanelsReset } from 'client/api/backend/users/users';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

interface DeskResetModalProps {
  userId: number;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function DeskResetModal(props: DeskResetModalProps) {
  const { userId, open, setOpen } = props;
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    setConfirmLoading(true);

    try {
      await getUsersIdPanelsReset(userId);
      message.success('Reset della scrivania effettuato con successo.');

      setOpen(false);
      setConfirmLoading(false);

      window.location.reload();
    } catch (e) {
      message.error(
        getNetworkErrorMessage(
          e,
          'Si è verificato un errore durante il reset della scrivania. Riprovare.'
        )
      );
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Modal
      title="Reset Scrivania"
      visible={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      okText="Conferma"
      maskClosable={false}
    >
      Sei sicuro di voler resettare la scrivania alla sua configurazione
      originale? L'operazione non è reversibile.
    </Modal>
  );
}

import { IDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { ToolkitObjectType } from '../objects/registerToolkitObjectType';
import { ToolkitObject } from '../objects/ToolkitObject';
import { IToolkitActionContext } from './ToolkitActionContext';

export interface ToolkitActionArgument {
  type: ToolkitObjectType[];
  allows?: (argument: ToolkitObject) => boolean;
  code?: string;
  name: string;
  /**
   * Supporta un array di oggetti.
   */
  isArray?: boolean;
}

export interface ToolkitAction<TArgs extends any[]> {
  code: string;
  name: string;
  icon?: string | React.ReactNode;
  /**
   * Indica se l'azione deve essere mostrata al di fuori del menù dropdown
   */
  isPreferential?: boolean;
  arguments: Array<ToolkitActionArgument>;
  /**
   * Permette di vedere l'azione dalla toolbar inferiore.
   */
  showInBar?: boolean;
  /**
   * Permette di avviare l'azione semplicemente premendo nell'ActionBar
   */
  allowClickToStart?: boolean;
  /**
   * Esecuzione effettiva dell'azione.
   */
  execute: (ctx: IToolkitActionContext, ...args: TArgs) => Promise<any>;
}

export function createToolkitAction<TArgs extends any[]>(
  action: ToolkitAction<TArgs>
) {
  return action;
}

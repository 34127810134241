import { useGetDocumentaryUsersOffices } from 'client/api/backend/documentary-users/documentary-users';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import * as React from 'react';

interface IUserOfficeInputProps extends SelectInputProps<any> {
  hierarchy?: boolean;
  widgetId: number;
}

export function UserOfficeInput(props: IUserOfficeInputProps) {
  const { widgetId, hierarchy, ...otherProps } = props;
  const officesResult = useGetDocumentaryUsersOffices({
    hierarchy,
    widgetId
  });

  return (
    <SelectInput
      allowClear
      showSearch
      loading={officesResult.isLoading}
      options={[
        ...(officesResult.data?.map(office => ({
          label: office.name!,
          value: office.id!
        })) ?? [])
      ]}
      {...otherProps}
    />
  );
}

import { FolderOutlined } from '@ant-design/icons';
import { DeskDocument, DeskFolder, Widget } from 'client/api/backend/schemas';
import { IWidgetListElement } from 'client/components/toolkit/components/list/element/IWidgetListElement';
import { ToolkitObjectType } from 'client/components/toolkit/objects/registerToolkitObjectType';
import {
  ExtendedDeskFolder,
  FolderToolkitObject
} from 'client/components/widget-repository/objects/folders/FolderToolkitObject';
import React from 'react';
import { convertDocumentToWidgetListElement } from '../../document-viewer/convert/convertDocumentToWidgetListElement';

export function convertDossierToWidgetList(
  parentId: number,
  subfolders: DeskFolder[] | undefined,
  source: ExtendedDeskFolder['source'],
  documents: DeskDocument[] | undefined,
  widget: Widget,
  setFolder: (folder: IWidgetListElement) => void
): IWidgetListElement[] {
  const subfoldersItems =
    subfolders?.map(folder =>
      convertFolderToWidgetListElement(
        parentId,
        folder,
        source,
        widget,
        setFolder
      )
    ) ?? [];

  const items =
    documents?.map(document =>
      convertDocumentToWidgetListElement(document, widget, parentId)
    ) ?? [];

  return [...subfoldersItems, ...items];
}

export function convertFolderToWidgetListElement(
  parentId: number,
  folder: DeskFolder,
  source: ExtendedDeskFolder['source'],
  widget: Widget,
  setFolder: (folder: IWidgetListElement) => void
): IWidgetListElement {
  const folderName = folder.name ?? `Cartella #${folder.id!}`;

  return {
    name: folderName,
    type: 'folder',
    onNavigate: setFolder,
    object: FolderToolkitObject.create({
      icon: <FolderOutlined />,
      id: folder.id!,
      name: folderName,
      data: { parentId, source, ...folder },
      sourceWidgetId: widget.id!
    })
  };
}

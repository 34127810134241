import React, { useState } from 'react';
import styled from 'styled-components';
import { Menu, Dropdown, Button } from 'antd';
import {
  AppstoreAddOutlined,
  ContainerOutlined,
  ControlOutlined,
  CrownOutlined,
  DownOutlined,
  SettingOutlined,
  SyncOutlined,
  UserOutlined
} from '@ant-design/icons';
import {
  useCurrentUser,
  useIsCurrentUserAdministrator
} from 'client/components/auth/AuthModule';
import { LogoutButton } from 'client/components/auth/logout/LogoutButton';
import { ButtonGhost } from 'client/ui/button/ButtonGhost';
import { useDispatch } from 'react-redux';
import { DeskActions, useDesk } from 'client/components/schema/desk/DeskModule';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { down } from 'styled-breakpoints';
import { UserLogic } from 'common/schema/user/UserLogic';
import { Link } from 'react-router-dom';
import { DeskResetModal } from 'client/routes/desk/reset/DeskResetModal';

interface UserButtonProps {}

export function UserButton(props: UserButtonProps) {
  const currentUser = useCurrentUser();
  const isSmall = useBreakpoint(down('lg'));

  const deskState = useDesk();

  const dispatch = useDispatch();
  const handleClick = () => {};

  const isAdministrator = useIsCurrentUserAdministrator();
  const [openResetModal, setOpenResetModal] = useState(false);

  const menu = (
    <Menu>
      {isSmall && (
        <>
          <Menu.Item key="1" icon={<UserOutlined />} disabled>
            {UserLogic.getDisplayName(currentUser)}
          </Menu.Item>
          <Menu.Divider />
        </>
      )}
      {/* <Menu.Item
        icon={<SettingOutlined />}
        onClick={() => dispatch(DeskActions.editable())}
      >
        Configura
      </Menu.Item> */}
      <Menu.Item icon={<ControlOutlined />} disabled>
        Preferenze
      </Menu.Item>
      <Menu.Item icon={<AppstoreAddOutlined />}>
        <Link to="/desk">Scrivania</Link>
      </Menu.Item>
      {isAdministrator && (
        <Menu.Item icon={<CrownOutlined />}>
          <Link to="/admin/users">Amministrazione</Link>
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item
        icon={<SyncOutlined />}
        onClick={() => setOpenResetModal(true)}
      >
        Reset Scrivania
      </Menu.Item>
      <Menu.Divider />
      <LogoutButton />
    </Menu>
  );

  return (
    <>
      <Dropdown
        overlay={menu}
        placement="bottomRight"
        trigger={['click']}
        disabled={deskState.isEditable}
      >
        {isSmall ? (
          <ButtonGhost icon={<UserOutlined />} />
        ) : (
          <ButtonGhost shape="round" icon={<UserOutlined />}>
            {UserLogic.getDisplayName(currentUser)} <DownOutlined />
          </ButtonGhost>
        )}
      </Dropdown>
      <DeskResetModal
        userId={currentUser?.id!}
        open={openResetModal}
        setOpen={setOpenResetModal}
      />
    </>
  );
}

import { FormOutlined } from '@ant-design/icons';
import { message } from 'antd';
import {
  getDocumentaryActivityId,
  postDocumentaryActivityIdSign
} from 'client/api/backend/documentary-activity/documentary-activity';
import { DeskActivity, DeskDocument } from 'client/api/backend/schemas';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { ActivityToolkitObject } from 'client/components/widget-repository/objects/activities/ActivityToolkitObject';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { PasswordInput } from 'client/ui/form/input/PasswordInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { yup } from 'common/validation/initYup';
import React from 'react';
import { InferType } from 'yup';
import { ActivityActionCategory } from 'client/components/widget-repository/objects/activities/ActivityActionCategories';
import { invalidateActivityQueries } from './invalidateActivityQueries';
import { HelpText } from 'client/ui/help-text/HelpText';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { executeCompleteActivity } from './complete/executeCompleteActivity';
import { ActivityCompletionsSelectInput } from 'client/components/widget-repository/objects/activities/ActivityCompletionsSelectInput';
import { without } from 'lodash';
import { ActivityCompletion } from 'client/components/widget-repository/objects/activities/ActivityCompletions';

export const WidgetActionSignActivity = createToolkitAction({
  name: 'Firma Atto',
  code: 'sign-record',
  icon: <FormOutlined />,
  arguments: [
    {
      type: [ActivityToolkitObject],
      name: 'Atto da Firmare',
      allows: (obj: ToolkitObject<DeskActivity>) =>
        obj.data.actionCategory === ActivityActionCategory.Firma &&
        !obj.data.signed
    }
  ],

  async execute(
    ctx: IToolkitActionContext,
    activityObject: ToolkitObject<DeskActivity>
  ) {
    const activity = ActivityToolkitObject.get(activityObject)!;

    const completions = without(
      activityObject.data.responses,
      ActivityCompletion.RichiestaModifiche
    );

    const hide = message.loading('Preparazione firma...', 0);

    // Chiamiamo l'API perché l'azione di firma necessita che
    // il documento sia stato generato
    try {
      await getDocumentaryActivityId(activity.data.id!, {
        code: activity.data.code!,
        documentId: activity.data.documentId!,
        widgetId: activity.sourceWidgetId!
      });
    } catch (e) {
      message.error(
        getNetworkErrorMessage(
          e,
          'Si è verificato un errore durante la preparazione della firma.'
        )
      );
      console.error(e);
    } finally {
      hide();
    }

    ctx.deskContext.actionMessages.prompt({
      ctx,
      action: WidgetActionSignActivity,
      args: [activity],
      schema: SignSchema,
      confirmText: 'Firma',
      initialValues: {
        type: completions.length === 1 ? completions[0] : undefined
      },
      onConfirm,
      form: (
        <>
          <FormFieldsContainer>
            <FormFieldItem
              size="large"
              component={TextInput}
              label="Username"
              name="username"
            />
            <FormFieldItem
              size="large"
              component={PasswordInput}
              label="Password"
              name="password"
            />
            <FormFieldItem
              size="large"
              component={TextInput}
              label="OTP"
              name="pin"
            />
            <>
              <FormFieldItem
                component={CheckboxInput}
                label={null}
                name="conclude"
              >
                Completa l'attività
              </FormFieldItem>
              <HelpText style={{ paddingTop: '0px' }}>
                Completa l'attività dopo aver firmato l'atto
              </HelpText>
            </>
            {completions.length > 1 && (
              <FormFieldItem
                size="large"
                component={ActivityCompletionsSelectInput}
                availableCompletions={completions}
                widgetId={activity.sourceWidgetId}
                code={activity.data.code!}
                label={null}
                name="type"
                placeholder="Completamento"
              />
            )}
          </FormFieldsContainer>
        </>
      )
    });
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: SignSchemaType,
  activity: ToolkitObject<DeskActivity>
) {
  try {
    await postDocumentaryActivityIdSign(
      activity.data.id!,
      {
        username: values.username,
        password: values.password,
        pin: values.pin,
        widgetId: activity.sourceWidgetId
      },
      {
        code: activity.data.code!,
        documentId: activity.data.documentId!.toString(),
        widgetId: activity.sourceWidgetId
      }
    );

    invalidateActivityQueries();

    message.success('Atto firmato con successo.');
  } catch (e) {
    message.error(
      getNetworkErrorMessage(e, "Errore durante la firma dell'atto.")
    );
    console.error(e);
    return false;
  }

  if (values.conclude && values.type) {
    try {
      // Conclusione dell'attività
      await executeCompleteActivity(activity, { type: values.type });
      message.success('Conclusione effettuata con successo');
    } catch (e: any) {
      message.error(
        getNetworkErrorMessage(e, 'Errore durante la conclusione.')
      );
      console.error(e);
      return false;
    }
  }
}

const SignSchema = yup.object({
  username: yup.string().required().label('Username'),
  password: yup.string().required().label('Password'),
  pin: yup.string().required().label('OTP'),
  conclude: yup.boolean().notRequired().label('Concludi'),
  type: yup
    .string()
    .label('Completamento')
    .when('conclude', (conclude, schema) => {
      if (!conclude) return schema.notRequired();
      return schema.required();
    })
});
type SignSchemaType = InferType<typeof SignSchema>;

import {
  GetDocumentaryDocumentsSearchParams,
  GetDocumentaryDossiersSearchParams
} from 'client/api/backend/schemas';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { FormikForm } from 'client/ui/form/FormikForm';
import { yup } from 'common/validation/initYup';
import * as React from 'react';
import * as WidgetFilterSections from 'client/components/toolkit/components/filter/WidgetFilterSections';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { DatePickerInput } from 'client/ui/form/input/DatePickerInput';
import { TextInput } from 'client/ui/form/input/TextInput';
import { Button, Space } from 'antd';
import { WidgetFilterDrawer } from 'client/components/toolkit/components/filter/WidgetFilterDrawer';
import { renderWidgetFilterActiveTags } from 'client/components/toolkit/components/filter/renderWidgetFilterActiveTags';
import { useState } from 'react';

export interface IWidgetFullTextDocumentsSearchFormProps {
  widgetId: number;
  search: Partial<GetDocumentaryDocumentsSearchParams>;
  setSearch: (search: Partial<GetDocumentaryDocumentsSearchParams>) => void;
}

export function WidgetFullTextDocumentsSearchForm(
  props: IWidgetFullTextDocumentsSearchFormProps
) {
  const { search, setSearch } = props;
  const [open, setOpen] = useState(false);

  return (
    <FormikAugmented
      validationSchema={yup.object()}
      validationContext={{}}
      initialValues={search}
      // validate={schemaToValidator()}
      onReset={() => {
        setSearch({});
        setOpen(false);
      }}
      onSubmit={(values: Partial<GetDocumentaryDocumentsSearchParams>) => {
        setSearch(values);
        setOpen(false);
      }}
    >
      <WidgetFilterDrawer
        title="Ricerca Documenti Fascicolati Full-Text"
        filters={renderWidgetFilterActiveTags({
          search,
          onReset: () => setSearch({})
        })}
        open={[open, setOpen]}
      >
        <FormikForm layout="vertical" editable helpInTooltips>
          <WidgetFilterSections.BodyWrapper>
            <FormFieldsContainer>
              <FormFieldItem
                size="middle"
                component={TextInput}
                label={null}
                name="subject"
                placeholder="Testo"
              />
            </FormFieldsContainer>
            <FormFieldsContainer columns={2} gutter={8}>
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                type="unix"
                label={null}
                name="dateFrom"
                placeholder="Dal"
              />
              <FormFieldItem
                size="middle"
                component={DatePickerInput}
                type="unix"
                label={null}
                name="dateTo"
                placeholder="Al"
              />
            </FormFieldsContainer>
          </WidgetFilterSections.BodyWrapper>
          <WidgetFilterSections.ButtonsWrapper>
            <Space>
              <Button htmlType="reset">Pulisci</Button>
              <Button htmlType="submit" type="primary">
                Filtra
              </Button>
            </Space>
          </WidgetFilterSections.ButtonsWrapper>
        </FormikForm>
      </WidgetFilterDrawer>
    </FormikAugmented>
  );
}

import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Drawer, message } from 'antd';
import {
  postDocumentaryUsers,
  usePostDocumentaryUsers
} from 'client/api/backend/documentary-users/documentary-users';
import {
  ConfigurationField,
  UserListRequestDTO
} from 'client/api/backend/schemas';
import { AdminFormContainer } from 'client/ui/form-container/AdminFormContainer';
import { FormikAugmented } from 'client/ui/form/FormikAugmented';
import { yup } from 'common/validation/initYup';
import React, { useEffect, useState } from 'react';
import { ImportUsersForm } from './ImportUsersForm';
import { ImportUsersDrawer } from './ImportUsersDrawer';

interface ImportUsersDTO {
  endpointId?: number;
  fields?: ConfigurationField[];
  copyDeskUserId?: number | null;
}

interface ButtonImportUsersDrawerProps {}

export function ButtonImportUsersDrawer(props: ButtonImportUsersDrawerProps) {
  const [visible, setVisible] = useState(false);
  const [userListVisible, setUserListVisible] = useState(false);
  const [userQueryParams, setUserQueryParams] = useState<UserListRequestDTO>(
    {}
  );
  const [copyDeskUserId, setCopyDeskUserId] = useState<number | null>(null);

  const [reloadCount, setReloadCount] = useState(0);

  return (
    <>
      <Button
        ghost
        type="primary"
        icon={<CloudDownloadOutlined />}
        onClick={() => setVisible(true)}
      >
        Importa Utenti
      </Button>
      <Drawer
        placement="bottom"
        height="70%"
        title="Importa Utenti"
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <AdminFormContainer>
          <FormikAugmented<ImportUsersDTO>
            initialValues={{}}
            enableReinitialize
            onSubmit={async (values, formik) => {
              setUserQueryParams({
                endpointId: values.endpointId!,
                fields: values.fields,
                pageNumber: 0,
                pageSize: 20
              });

              if (values.copyDeskUserId) {
                setCopyDeskUserId(values.copyDeskUserId);
              }

              setReloadCount(count => count + 1);

              setUserListVisible(true);
            }}
            validationSchema={yup.object()}
            validationContext={{}}
          >
            <ImportUsersForm
              reloadCount={reloadCount}
              setReloadCount={setReloadCount}
            />
          </FormikAugmented>
        </AdminFormContainer>
      </Drawer>
      <ImportUsersDrawer
        userListVisible={userListVisible}
        setUserListVisible={setUserListVisible}
        userQueryParams={userQueryParams}
        setUserQueryParams={setUserQueryParams}
        userImportFormVisible={visible}
        setUserImportFormVisible={setVisible}
        copyDeskUserId={copyDeskUserId}
      />
    </>
  );
}

import React from 'react';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';

interface Props extends SelectInputProps<any> {
  code: string;
  widgetId: number;
  availableCompletions: string[];
}

export function ActivityCompletionsSelectInput(props: Props) {
  const { code, widgetId, availableCompletions, ...otherProps } = props;

  const options = availableCompletions?.map(c => ({
    label: c,
    value: c
  }));

  return (
    <SelectInput allowClear showSearch options={options} {...otherProps} />
  );
}

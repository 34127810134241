import * as React from 'react';
import FullscreenOutlined from '@ant-design/icons/lib/icons/FullscreenOutlined';
import FullscreenExitOutlined from '@ant-design/icons/lib/icons/FullscreenExitOutlined';
import { ButtonIcon, ButtonIconProps } from 'client/ui/button/ButtonIcon';

export interface FullScreenButtonProps extends Omit<ButtonIconProps, 'icon'> {
  fullScreen?: boolean;
}
/**
 * Pulsante per portare il Widget a fullscreen e viceversa
 */
export function FullScreenButton(props: FullScreenButtonProps) {
  const { fullScreen, ...othersProps } = props;

  return (
    <ButtonIcon
      icon={
        props.fullScreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />
      }
      {...othersProps}
    />
  );
}

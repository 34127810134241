import { useDeskContext } from 'client/components/schema/desk/context/DeskContext';
import { AssignmentModelToolkitObject } from 'client/components/widget-repository/objects/assignment-models/AssignmentModelToolkitObject';
import { groupBy, pick, uniqBy, without } from 'lodash';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';
import { ToolkitObject } from '../ToolkitObject';

/**
 * Manteniamo un sistema di riferimenti per contare quante volte un oggetto
 * è stato aggiunto all'objectStore. Questo ci permette di rimuovere l'oggetto
 * solo quando il numero di riferimenti è 0.
 *
 * Il caso pratico in cui ciò è necessario è quando un oggetto viene aggiunto
 * in un Widget in Evidenza (es. "Fascicolo n.10"). Nel momento in cui viene
 * eliminato il Widget, l'oggetto viene rimosso dall'objectStore, anche
 * se è ancora presente in un altro Widget (es. "Fascicolo n.10" in "Ricerca
 * Fascicoli").
 *
 * Con il sistema dei riferimenti, l'oggetto viene rimosso solo quando non
 * è più presente in nessun Widget.
 *
 * @see https://it.wikipedia.org/wiki/Automatic_Reference_Counting
 */
export type ToolkitObjectStoreReference = {
  object: ToolkitObject;
  count: number;
};

export type ToolkitObjectStore = {
  [key: string]: Array<ToolkitObjectStoreReference>;
};

export type ToolkitObjectStoreAction =
  | { type: 'add'; object: ToolkitObject }
  | { type: 'add-many'; objects: ToolkitObject[] }
  | { type: 'remove'; object: ToolkitObject }
  | { type: 'remove-many'; objects: ToolkitObject[] };

const getObjectId = (object: ToolkitObject) => object.id;

function reducer(state: ToolkitObjectStore, action: ToolkitObjectStoreAction) {
  switch (action.type) {
    case 'add': {
      if (action.object.id == null) {
        console.warn(`Salto il ToolkitObject id null`, action.object);
        return state;
      }

      // TODO Sostituire con immer
      const typedStore = state[action.object.type]
        ? [...state[action.object.type]]
        : [];
      const existing = typedStore.findIndex(
        s => s.object.id === action.object.id
      );
      if (existing >= 0) {
        typedStore[existing].count++;
      } else {
        typedStore.push({ object: action.object, count: 1 });
      }

      return {
        ...state,
        [action.object.type]: typedStore
      };
    }

    case 'add-many': {
      const grouped = groupBy(action.objects, o => o.type);
      let nextState = { ...state };

      for (const objectType in grouped) {
        const typedStore = state[objectType] ? [...state[objectType]] : [];

        // Creiamo una mappa per accedere più velocemente agli oggetti in O(1)
        const existing = new Map<any, ToolkitObjectStoreReference>();
        typedStore.forEach(o => {
          existing.set(o.object.id, o);
        });

        // Per ogni oggetto di un certo tipo, controlliamo se esiste già
        // nell'objectStore. Se esiste, incrementiamo il contatore dei
        // riferimenti, altrimenti lo aggiungiamo.
        for (const object of grouped[objectType]) {
          if (object.id == null) {
            console.warn(`Salto il ToolkitObject id null`, object);
            continue;
          }

          const existingRef = existing.get(object.id);
          if (existingRef) {
            existingRef.count++;
          } else {
            typedStore.push({ object, count: 1 });
          }
        }

        nextState[objectType] = typedStore;
      }

      return nextState;
    }

    case 'remove': {
      const typedStore = state[action.object.type]
        ? [...state[action.object.type]]
        : [];

      const existing = typedStore.findIndex(
        o => o.object.id === action.object.id
      );

      // Se l'oggetto esiste, decrementiamo il contatore dei riferimenti.
      if (existing >= 0) {
        // TODO Verificare se è necessario clonare l'oggetto
        typedStore[existing].count--;

        // Se il contatore è 0, rimuoviamo l'oggetto dall'objectStore.
        if (typedStore[existing].count <= 0) {
          typedStore.splice(existing, 1);
        }
      }

      return {
        ...state,
        [action.object.type]: typedStore
      };
    }

    case 'remove-many': {
      const grouped = groupBy(action.objects, o => o.type);
      let nextState = { ...state };

      for (const objectType in grouped) {
        const typedStore = state[objectType] ? [...state[objectType]] : [];

        // Creiamo una mappa per accedere più velocemente agli oggetti in O(1).

        // Rispetto all'algoritmo di `add-many`, qui dobbiamo tenere traccia
        // anche dell'indice dell'oggetto nell'array, per poterlo rimuovere
        // in O(1) quando il contatore dei riferimenti è 0.
        const existing = new Map<any, [ToolkitObjectStoreReference, number]>();
        typedStore.forEach((o, i) => {
          existing.set(o.object.id, [o, i]);
        });

        // Per ogni oggetto di un certo tipo, controlliamo se esiste già
        // nell'objectStore. Se esiste, riduciamo il contatore dei riferimenti,
        // se il contatore è 0, rimuoviamo l'oggetto dall'objectStore.
        for (const object of grouped[objectType]) {
          if (object.id == null) {
            console.warn(`Salto il ToolkitObject id null`, object);
            continue;
          }

          const [existingRef, existingIdx] = existing.get(object.id) ?? [];
          // Già eliminato
          if (!existingRef) continue;

          existingRef.count--;

          // Se il contatore è 0, rimuoviamo l'oggetto dall'objectStore.
          if (existingRef.count <= 0) {
            typedStore.splice(existingIdx!, 1);
          }
        }

        nextState[objectType] = typedStore;
      }

      return nextState;
    }

    default:
      return state;
  }
}

export function useToolkitObjectStore() {
  const [state, dispatch] = useReducer(reducer, {});

  const register = useCallback(
    (objects: ToolkitObject[]) => {
      dispatch({ type: 'add-many', objects });
    },
    [dispatch]
  );

  const unregister = useCallback(
    (objects: ToolkitObject[]) => {
      dispatch({ type: 'remove-many', objects });
    },
    [dispatch]
  );

  return {
    state,
    dispatch,
    register,
    unregister
  };
}

/**
 * Viene utilizzato nella WidgetListElement e anche nella treeBaseNode
 */
export function useRegisterToolkitObject(object: ToolkitObject | undefined) {
  const {
    objectStore: { dispatch }
  } = useDeskContext();

  useEffect(() => {
    // console.log('[--register--] object', object);
    if (!object) return;

    dispatch({ type: 'add', object });
    return () => {
      // console.log('useRegisterToolkitObject.remove', object);
      dispatch({ type: 'remove', object });
    };
  }, [JSON.stringify(getSerializableObjectProperties(object))]);
}

function getSerializableObjectProperties(object: ToolkitObject | undefined) {
  if (!object) return undefined;
  return pick(object, ['data', 'id', 'type', 'name']);
}

import * as React from 'react';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { ButtonIcon } from 'client/ui/button/ButtonIcon';
import { DeleteOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { queryClient } from 'client/core/network/queryClient';
import {
  useDeleteRolesId,
  getGetRolesQueryKey
} from 'client/api/backend/roles/roles';
import { isRoleAdministrator } from 'client/components/auth/isRoleAdministrator';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

interface ProfileColumnDeleteButtonProps {
  id: number;
  name?: string;
}

export function ProfileColumnDeleteButton(
  props: ProfileColumnDeleteButtonProps
) {
  if (isRoleAdministrator(props.id)) return null;

  const deleteMutation = useDeleteRolesId({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetRolesQueryKey());
      }
    }
  });

  return (
    <ButtonDeleteConfirm
      placement="left"
      title={
        <span>
          Sei sicuro di voler eliminare il Profilo <b>{props.name ?? ''}</b>?
        </span>
      }
      cancelText="Annulla"
      onConfirm={async () => {
        try {
          await deleteMutation.mutateAsync({
            id: props.id
          });
          message.success('Profilo eliminato con successo. ');
        } catch (e) {
          message.error(
            getNetworkErrorMessage(
              e,
              "Errore durante l'eliminazione del profilo. Riprovare."
            )
          );
        }
      }}
    >
      <ButtonIcon danger icon={<DeleteOutlined />} />
    </ButtonDeleteConfirm>
  );
}

import * as React from 'react';
import { Button, message, PageHeader } from 'antd';
import styled from 'styled-components';
import { AdminLayout } from '../../../ui/layout/AdminLayout';
import { AdminMenu } from '../menu/AdminMenu';
import { useBreadcrumbItem } from 'client/core/router/breadcrumb/BreadcrumbContext';
import { RouteComponentProps } from 'react-router';
import { Endpoint, EndpointCreateDTO } from 'client/api/backend/schemas';
import { FormikForm } from 'client/ui/form/FormikForm';
import { Formik } from 'formik';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormikSendButton } from 'client/ui/form/button/FormikSendButton';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { TextInput } from 'client/ui/form/input/TextInput';
import { SelectInput } from 'client/ui/form/input/SelectInput';
import { ButtonDeleteConfirm } from 'client/ui/button/ButtonDeleteConfirm';
import { FormButtonsRow } from 'client/ui/form/button/FormButtonsRow';
import {
  getGetEndpointsIdQueryKey,
  useDeleteEndpointsId,
  useGetEndpointsId,
  usePostEndpoints,
  usePutEndpoints
} from 'client/api/backend/endpoints/endpoints';
import { LoadingPage } from 'client/ui/loader/LoadingPage';
import { NetworkAlertPage } from 'client/components/errors/network-alert/NetworkAlertPage';
import { queryClient } from 'client/core/network/queryClient';
import { useGetDocumentaries } from 'client/api/backend/documentaries/documentaries';
import { AdminFormContainer } from 'client/ui/form-container/AdminFormContainer';
import { CheckboxInput } from 'client/ui/form/input/CheckboxInput';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';

const ContentWrapper = styled.div`
  flex: 1 1 auto;
  min-height: 0;
  overflow-y: auto;
  padding: 0 24px;
  display: block;
  border-top: 1px solid ${props => props.theme.borderColorBase};
`;

// export interface Endpoint {
//   client?: string;
//   documentaryID?: number;
//   id: number;
//   name?: string;
//   secret?: string;
//   url?: string;
// }

interface Params {
  id: string;
}

export interface EndpointPageProps extends RouteComponentProps<Params> {}

/**
 * Pagina degli Endpoint
 */
export function EndpointPage(props: EndpointPageProps) {
  useBreadcrumbItem({ path: '/admin/users', title: `Endpoints` });
  useBreadcrumbItem({
    path: props.location.pathname,
    title: 'Dettaglio Endpoint'
  });

  // Carica la lista dei documentali per utilizzarli nel form come opzioni
  const documentaries = useGetDocumentaries().data ?? [];

  const id =
    props.match.params.id && props.match.params.id !== 'create'
      ? parseInt(props.match.params.id, 10)
      : null;

  const isEdit = id != null;

  const { data, error, isLoading } = useGetEndpointsId(id!, {
    query: { enabled: isEdit }
  });

  const endpoint: Maybe<EndpointCreateDTO> = !isEdit ? {} : data;

  const createMutation = usePostEndpoints({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetEndpointsIdQueryKey(id!));
      }
    }
  });

  const updateMutation = usePutEndpoints({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(getGetEndpointsIdQueryKey(id!));
      }
    }
  });

  const deleteMutation = useDeleteEndpointsId();

  if (error) {
    return (
      <NetworkAlertPage
        message="Impossibile accedere all'endpoint"
        error={error}
      />
    );
  }

  if (!endpoint) return <LoadingPage />;

  return (
    <AdminLayout menu={<AdminMenu />}>
      <PageHeader
        onBack={() => {
          props.history.push(`/admin/endpoints`);
        }}
        title={
          !isEdit ? 'Nuovo Endpoint' : `Dettaglio Endpoint: ${endpoint.name}`
        }
        extra={[
          id && (
            <ButtonDeleteConfirm
              placement="left"
              title={
                <span>
                  Sei sicuro di voler eliminare l'Endpoint{' '}
                  <b>{endpoint.name ?? ''}</b>?
                </span>
              }
              cancelText="Annulla"
              onConfirm={async () => {
                try {
                  await deleteMutation.mutateAsync({
                    id: id
                  });
                  message.success('Endpoint eliminato con successo. ');
                  props.history.push(`/admin/endpoints`);
                } catch (e) {
                  message.error(
                    getNetworkErrorMessage(
                      e,
                      "Errore durante l'eliminazione dell'endpoint. Riprovare."
                    )
                  );
                }
              }}
              buttonType="default"
            >
              <Button danger>Elimina Endpoint</Button>
            </ButtonDeleteConfirm>
          )
        ]}
      />
      <ContentWrapper>
        <AdminFormContainer>
          <Formik<EndpointCreateDTO>
            initialValues={endpoint}
            onSubmit={async (values, formik) => {
              try {
                if (!isEdit) {
                  const result = (await createMutation.mutateAsync({
                    data: values
                  })) as Endpoint;

                  message.success('Endpoint creato con successo. ');
                  props.history.push(`/admin/endpoints/${result.id}`);
                } else {
                  await updateMutation.mutateAsync({ data: { id, ...values } });
                  message.success('Endpoint aggiornato con successo. ');
                }
              } catch (e) {
                message.error(
                  getNetworkErrorMessage(
                    e,
                    `Si è verificato un errore durante la ${
                      isEdit ? 'modifica' : 'creazione'
                    } dell'endpoint.`
                  )
                );
                console.error(e);
              }
            }}
          >
            <FormikForm editable layout="vertical">
              <FormFieldsContainer>
                <FormFieldItem
                  label="Nome"
                  placeholder="Nome"
                  name="name"
                  component={TextInput}
                  size="large"
                />
                <FormFieldItem
                  label="Documentale"
                  placeholder="Documentale"
                  name="documentaryID"
                  component={SelectInput}
                  size="large"
                  options={documentaries.map(d => ({
                    value: d.id!,
                    label: d.name
                  }))}
                />
                <FormFieldItem
                  label="Client"
                  placeholder="Client"
                  name="client"
                  component={TextInput}
                  size="large"
                />
                <FormFieldItem
                  label="Secret"
                  placeholder="Secret"
                  name="secret"
                  component={TextInput}
                  size="large"
                />
                <FormFieldItem
                  label="URL"
                  placeholder="URL"
                  name="url"
                  component={TextInput}
                  size="large"
                />
                <FormFieldItem
                  label="Default"
                  name="preselected"
                  component={CheckboxInput}
                >
                  Rendi questo endpoint il default
                </FormFieldItem>
              </FormFieldsContainer>

              <FormButtonsRow>
                <Button
                  size="large"
                  onClick={() => {
                    props.history.push(`/admin/endpoints`);
                  }}
                >
                  Annulla
                </Button>
                <FormikSendButton as={Button} title="Salva" size="large">
                  Salva
                </FormikSendButton>
              </FormButtonsRow>
            </FormikForm>
          </Formik>
        </AdminFormContainer>
      </ContentWrapper>
    </AdminLayout>
  );
}

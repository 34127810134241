import * as React from 'react';
import { Card, CardProps } from 'antd';
import clx from 'classnames';
import styled from 'styled-components';

const StyledFloatCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;

  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05), 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  > .ant-card-head {
    padding: 0 8px;
    flex: 0 0 auto;
    overflow-x: hidden; // Per supportare le animazioni sulla width
    background-color: ${props => props.theme.primaryColor};
    min-height: 38px;
    .ant-card-head-title {
      padding: 0;
      padding-left: 8px;
    }
    .ant-card-extra {
      padding: 4px 0;
    }
  }
  > .ant-card-body {
    height: 100%;
    padding: 0;
    flex: 1 1 auto;
    overflow: hidden;
    min-height: 0;
    min-width: 0;
    border-bottom-left-radius: ${props => props.theme.cardRadius};
    border-bottom-right-radius: ${props => props.theme.cardRadius};
    padding-bottom: 10px;
  }
  &.fullscreen {
    .ant-card-body {
      padding-bottom: 0;
    }
  }
  &.expanded {
    box-shadow: rgb(0, 0, 0, 0.2) 0px 3px 3px -2px,
      rgb(0, 0, 0, 0.14) 0px 3px 4px 0px, rgb(0, 0, 0, 0.3) 0px 0px 8px 0px;
  }
`;

export interface FloatCardProps extends Omit<CardProps, 'bordered'> {
  fullScreen?: boolean;
  expanded?: boolean;
}

/**
 * Card stilizzata del Widget
 */
export function FloatCard(props: FloatCardProps) {
  const { fullScreen, expanded, ...otherProps } = props;
  return (
    <StyledFloatCard
      bordered={false}
      className={clx({ fullscreen: fullScreen, expanded })}
      {...otherProps}
    />
  );
}

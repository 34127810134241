import { down } from 'styled-breakpoints';
import styled from 'styled-components';

export const WidgetWrapper = styled.div`
  padding: ${props => props.theme.widgetGridPadding};
  ${down('sm')} {
    padding-right: 0;
    padding-left: 0;
  }

  width: 100%;
  height: 100%;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);

  &.hide-all-drawer {
    position: relative;
    z-index: 1;
  }

  &.fullscreen {
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 52px;
    max-height: 100%;
    height: calc(100vh - 48px);
    padding-bottom: 12px;
    transition: all 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  }
`;

import { Widget } from 'client/api/backend/schemas';
import React, { useContext } from 'react';
import { useDeskHandler } from './useDeskHandler';

export type IDeskContext = ReturnType<typeof useDeskHandler>;

export const DeskContext = React.createContext<IDeskContext>({} as any);

interface IDeskContextProviderProps {
  children: React.ReactNode;
  deskId: number;
}

/**
 * Esponse le funzionalità del `deskHandler` di una singola scrivania tramite
 * un context a tutto il tree sottostante. È possibile usare l'hook
 * `useDeskContext`
 */
export function DeskContextProvider(props: IDeskContextProviderProps) {
  const contextState = useDeskHandler(props.deskId);
  return (
    <DeskContext.Provider value={contextState}>
      {props.children}
    </DeskContext.Provider>
  );
}

export function useDeskContext(): IDeskContext {
  return useContext(DeskContext);
}

import { registerToolkitObjectType } from 'client/components/toolkit/objects/registerToolkitObjectType';

export interface HighlightedDeskDocument {
  id?: number;
  highlighted?: {
    notes?: string;
  };
}

export const HighlightedDocumentToolkitObject = registerToolkitObjectType<{}>(
  'highlighted-document'
);

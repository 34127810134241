import { useGetWidgetsTypes } from 'client/api/backend/widgets/widgets';
import { useCallback, useEffect, useMemo } from 'react';
import {
  ActionObjectInput,
  flattenToolkitRelatedObjects
} from '../schema/widget/actions/interaction/isToolkitActionSupportedBy';
import { useCurrentUser } from '../auth/AuthModule';
import { matchToolkitActionArgument } from '../schema/widget/actions/interaction/matchToolkitActionArgument';
import { ToolkitAction } from '../toolkit/actions/ToolkitAction';
import { ToolkitObject } from '../toolkit/objects/ToolkitObject';
import { WidgetRepository } from './WidgetRepository';

/**
 * Elenco dei Widget filtrato in base ai permessi dell'utente.
 * Per ora non è una blacklist.
 */
export function useWidgetRepository() {
  const allowedWidgetTypes = useGetWidgetsTypes({
    query: {
      cacheTime: 60 * 60 * 1000,
      refetchOnMount: false
    }
  });

  const auth = useCurrentUser();
  useEffect(() => {
    if (!auth?.id) return;
    allowedWidgetTypes.refetch();
  }, [auth?.id]);

  const widgetTypes = useMemo(
    () =>
      WidgetRepository.listAll().filter(wt => {
        const allowed = allowedWidgetTypes.data?.find(w => w.code === wt.code);

        if (!allowed) return false;

        // Effetto collaterale per aggiornare l'id del widget con quello
        // che torna l'API.
        wt.id = allowed.id;

        return true;
      }),
    [allowedWidgetTypes.data]
  );

  /**
   * Widget selezionabili dall'utente nel drawer di selezione.
   */
  const selectableWidgetTypes = widgetTypes.filter(wt => !wt.isHidden);

  const actions = widgetTypes.map(wt => (wt.actions ? wt.actions : [])).flat();

  // Actions interne di un singolo widget
  const findInternalActions = useCallback(
    (widgetTypeCode: string | null | undefined) => {
      return (
        widgetTypes?.find(wt => wt.code === widgetTypeCode)?.internalActions ??
        []
      );
    },
    [widgetTypes]
  );

  const findAction = useCallback(
    (code: string | null | undefined) => {
      return actions.find(a => a.code === code);
    },
    [actions]
  );

  /**
   * Ottiene l'azione primaria per una coppia di argomenti, ovvero
   * quella da eseguire in D&D A -> B
   */
  const findPrimaryAction = useCallback(
    (active: Maybe<ActionObjectInput>, over: ToolkitObject | null) => {
      if (!active || !over) return null;

      // Proviamo prima con le azioni sull'oggetto primario, se non ce ne
      // sono proviamo con quelle del secondario.
      const activeObjects = flattenToolkitRelatedObjects(active);

      let action: ToolkitAction<any[]> | undefined;
      for (const activeObject of activeObjects) {
        action = actions.find(
          a =>
            a.arguments.length === 2 &&
            matchToolkitActionArgument(a.arguments[0], activeObject) &&
            matchToolkitActionArgument(a.arguments[1], over)
        );

        // Se abbiamo trovato un'azione per quest'oggetto, ritorniamo.
        if (action) return action;
      }
    },
    [actions]
  );

  return {
    widgetTypes,
    selectableWidgetTypes,
    actions,
    findInternalActions,
    findAction,
    findPrimaryAction
  };
}

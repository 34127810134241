import { EnvironmentOutlined } from '@ant-design/icons';
import React from 'react';
import { IWidgetType } from '../../schema/WidgetType';
import { WidgetRepository } from '../../WidgetRepository';
import { WidgetActionGeolocateDam } from './actions/WidgetActionGeolocateDam';
import { WidgetDamGeolocation } from './WidgetDamGeolocation';
import { FvIconDam, FvIconDamPin } from 'client/ui/icon/FvIconsDam';

export const WidgetDamGeolocationType: IWidgetType = {
  code: 'dam-geo',
  name: 'Geolocalizzazione Diga',
  icon: <FvIconDamPin />,
  component: WidgetDamGeolocation,
  description: `Mostra la geolocalizzazione della Diga`,
  actions: [WidgetActionGeolocateDam]
};

WidgetRepository.register(WidgetDamGeolocationType);

import { combineReducers, Store } from 'redux';
import { AuthReducer } from 'client/components/auth/AuthModule';
import { ClientConfigReducer } from 'client/components/schema/config/ClientConfigModule';
import { DeskReducer } from 'client/components/schema/desk/DeskModule';

/**
 * Reducer principale dell'applicazione, che contiene lo stato di ogni
 * reducer parziale.
 * Ogni nuovo `Module` deve essere aggiunto qui.
 */
export const RootReducer = combineReducers({
  auth: AuthReducer,
  config: ClientConfigReducer,
  desk: DeskReducer
});

export type RootState = ReducerState<typeof RootReducer>;
export type RootStore = Store<RootState>;

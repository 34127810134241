import Icon, {
  CustomIconComponentProps
} from '@ant-design/icons/lib/components/Icon';
import React from 'react';

const SvgDefaults = {
  focusable: false,
  viewBox: '6.4 6.4 89.6 89.6',
  fill: 'currentColor',
  width: '1em',
  height: '1em'
};
/**
 * Icone Personalizzate SVG   viewBox="0 0 102.4 102.4"
 *
 */
export const FvIconFileView = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg {...SvgDefaults}>
        <g>
          <path d="M36,85.2H20.8V14.8h56V53.52a.8.8,0,0,0,.8.8h5.6a.8.8,0,0,0,.8-.8V10.8a3.2,3.2,0,0,0-3.2-3.2h-64a3.2,3.2,0,0,0-3.2,3.2V89.2a3.2,3.2,0,0,0,3.2,3.2H36a.8.8,0,0,0,.8-.8V86A.8.8,0,0,0,36,85.2Z" />
          <rect x="28.8" y="25.6" width="40" height="6.4" rx="0.8" />
          <rect x="28.8" y="40" width="20" height="6.4" rx="0.8" />
          <path d="M88.05,74.29h0v0c-5.1-10.78-13-16.32-23.49-16.32S46.14,63.49,41,74.26v0a5,5,0,0,0,0,4.2C46.14,89.25,54,94.8,64.54,94.8S82.93,89.24,88,78.47A4.89,4.89,0,0,0,88.05,74.29ZM64.54,88C57,88,51.47,84.33,47.37,76.37c4.09-8,9.61-11.62,17.17-11.62S77.6,68.4,81.7,76.37C77.61,84.33,72.09,88,64.54,88Z" />
          <circle cx="64.58" cy="76.41" r="5.24" />
        </g>
      </svg>
    )}
    {...props}
  />
);

export const FvIconFolderPersonal = (
  props: Partial<CustomIconComponentProps>
) => (
  <Icon
    component={() => (
      <svg {...SvgDefaults}>
        <g>
          <path d="M81.28,78.81a14.4,14.4,0,1,0-19.36,0A19.67,19.67,0,0,0,52,95a.8.8,0,0,0,.8.82h4.81a.81.81,0,0,0,.8-.75,13.21,13.21,0,0,1,26.38,0,.79.79,0,0,0,.8.75H90.4a.79.79,0,0,0,.8-.82A19.63,19.63,0,0,0,81.28,78.81ZM71.6,76.16a8,8,0,1,1,8-8A8,8,0,0,1,71.6,76.16Z" />
          <path d="M37.25,25.6,49.21,37H84V49.38a.8.8,0,0,0,.8.8h5.6a.8.8,0,0,0,.8-.8V33a3.2,3.2,0,0,0-3.2-3.2H52.1L40.37,18.62a.82.82,0,0,0-.55-.22H14.4a3.2,3.2,0,0,0-3.2,3.2V80.8A3.2,3.2,0,0,0,14.4,84H46.47a.8.8,0,0,0,.8-.8V77.6a.8.8,0,0,0-.8-.8H18.4V25.6Z" />
        </g>
      </svg>
    )}
    {...props}
  />
);

export const FvIconFolderView = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg {...SvgDefaults}>
        <g>
          <path d="M90.78,75.34h0C85.67,64.54,77.77,59,67.27,59s-18.4,5.56-23.51,16.33h0a5,5,0,0,0,0,4.21c5.11,10.77,13,16.32,23.51,16.32s18.4-5.56,23.5-16.33A4.92,4.92,0,0,0,90.78,75.34ZM67.27,89c-7.55,0-13.06-3.65-17.16-11.62,4.08-8,9.61-11.61,17.16-11.61s13.06,3.65,17.16,11.61C80.35,85.39,74.82,89,67.27,89Z" />
          <circle
            cx="67.32"
            cy="77.47"
            r="5.24"
            transform="translate(-35.06 70.29) rotate(-45)"
          />
          <path d="M37.25,25.6,49.21,37H84V56.67a.8.8,0,0,0,.8.8h5.6a.8.8,0,0,0,.8-.8V33a3.2,3.2,0,0,0-3.2-3.2H52.1L40.37,18.62a.82.82,0,0,0-.55-.22H14.4a3.2,3.2,0,0,0-3.2,3.2V80.8A3.2,3.2,0,0,0,14.4,84H35.77a.8.8,0,0,0,.8-.8V77.6a.8.8,0,0,0-.8-.8H18.4V25.6Z" />
        </g>
      </svg>
    )}
    {...props}
  />
);

export const FvIconFolderSearch = (
  props: Partial<CustomIconComponentProps>
) => (
  <Icon
    component={() => (
      <svg {...SvgDefaults}>
        <g>
          <path d="M37.25,24.55,49.21,36H84V48.32a.8.8,0,0,0,.8.8h5.6a.8.8,0,0,0,.8-.8V32a3.2,3.2,0,0,0-3.2-3.2H52.1L40.37,17.57a.82.82,0,0,0-.55-.22H14.4a3.2,3.2,0,0,0-3.2,3.2v59.2A3.2,3.2,0,0,0,14.4,83H46.47a.8.8,0,0,0,.8-.8v-5.6a.8.8,0,0,0-.8-.8H18.4V24.55Z" />
          <path d="M94.16,90.35h0L84.83,81a17.62,17.62,0,1,0-4.36,4.08l9.47,9.47a.78.78,0,0,0,.56.23.8.8,0,0,0,.56-.23l3.1-3.1A.79.79,0,0,0,94.16,90.35ZM70.79,81.6A11.2,11.2,0,1,1,82,70.4,11.19,11.19,0,0,1,70.79,81.6Z" />
        </g>
      </svg>
    )}
    {...props}
  />
);

export const FvIconUserAssignment = (
  props: Partial<CustomIconComponentProps>
) => (
  <Icon
    component={() => (
      <svg {...SvgDefaults}>
        <g>
          <path d="M53.34,78.9,39.17,67.7a.8.8,0,0,0-1.3.63v7.39H12.19a.8.8,0,0,0-.8.8v6a.8.8,0,0,0,.8.8H37.87v7.41a.8.8,0,0,0,1.29.63l14.18-11.2A.8.8,0,0,0,53.48,79,.47.47,0,0,0,53.34,78.9Z" />
          <path d="M87.21,89.65a37.49,37.49,0,0,0-11-25.77,37.73,37.73,0,0,0-12-8.06l-.12,0a24.79,24.79,0,1,0-29,0l-.12,0a37.11,37.11,0,0,0-7.23,4.05.33.33,0,0,0,.19.59h21A30.91,30.91,0,0,1,70.87,69.3a29.85,29.85,0,0,1,8.74,20.39.79.79,0,0,0,.8.78h6a.8.8,0,0,0,.8-.8ZM49.61,52.87a17.21,17.21,0,1,1,12.16-5A17.09,17.09,0,0,1,49.61,52.87Z" />
        </g>
      </svg>
    )}
    {...props}
  />
);

export const FvIconUserAssignmentHightLight = (
  props: Partial<CustomIconComponentProps>
) => (
  <Icon
    component={() => (
      <svg {...SvgDefaults}>
        <g>
          <path d="M87.21,89.65a37.49,37.49,0,0,0-11-25.77,37.73,37.73,0,0,0-12-8.06l-.12,0a24.79,24.79,0,1,0-29,0l-.12,0a37.11,37.11,0,0,0-7.23,4.05.33.33,0,0,0,.19.59h21A30.91,30.91,0,0,1,70.87,69.3a29.85,29.85,0,0,1,8.74,20.39.79.79,0,0,0,.8.78h6a.8.8,0,0,0,.8-.8ZM49.61,52.87a17.21,17.21,0,1,1,12.16-5A17.09,17.09,0,0,1,49.61,52.87Z" />
          <path d="M43.94,55.8a.86.86,0,0,0-.86-.86H14.4a.86.86,0,0,0-.86.86V92.12c0,2.34,2.59,2.67,4.25,1.39l11-9.32a.07.07,0,0,1,.09,0L40,93.51a2.51,2.51,0,0,0,2.69.17,2.68,2.68,0,0,0,1.3-2.37Zm-7.7,24.63-7.39-6.2a.07.07,0,0,0-.09,0l-7.51,6.39a.07.07,0,0,1-.11-.05v-18a.06.06,0,0,1,.06-.06H36.28a.06.06,0,0,1,.06.06V80.38C36.34,80.44,36.28,80.47,36.24,80.43Z" />
        </g>
      </svg>
    )}
    {...props}
  />
);

export const FvIconFolderHightLight = (
  props: Partial<CustomIconComponentProps>
) => (
  <Icon
    component={() => (
      <svg {...SvgDefaults}>
        <g>
          <path d="M37.25,24.55,49.21,36H84V47.32a.8.8,0,0,0,.8.8h5.6a.8.8,0,0,0,.8-.8V32a3.2,3.2,0,0,0-3.2-3.2H52.1L40.37,17.57a.82.82,0,0,0-.55-.22H14.4a3.2,3.2,0,0,0-3.2,3.2v59.2A3.2,3.2,0,0,0,14.4,83H51.47a.8.8,0,0,0,.8-.8v-5.6a.8.8,0,0,0-.8-.8H18.4V24.55Z" />
          <path d="M91.2,55.8a.86.86,0,0,0-.86-.86H61.66a.86.86,0,0,0-.86.86V92.12c0,2.34,2.59,2.67,4.25,1.39l11-9.32a.06.06,0,0,1,.08,0l11.11,9.32a2.51,2.51,0,0,0,2.69.17,2.68,2.68,0,0,0,1.3-2.37ZM83.5,80.43l-7.4-6.2a.06.06,0,0,0-.08,0L68.5,80.62a.06.06,0,0,1-.1-.05v-18a.06.06,0,0,1,.06-.06H83.54a.06.06,0,0,1,.06.06V80.38C83.6,80.44,83.54,80.47,83.5,80.43Z" />
        </g>
      </svg>
    )}
    {...props}
  />
);

export const FvIconFileHightLight = (
  props: Partial<CustomIconComponentProps>
) => (
  <Icon
    component={() => (
      <svg {...SvgDefaults}>
        <g>
          <path d="M49,85.2H20.8V14.8h56V46.2a.8.8,0,0,0,.8.8h5.6a.8.8,0,0,0,.8-.8V10.8a3.2,3.2,0,0,0-3.2-3.2h-64a3.2,3.2,0,0,0-3.2,3.2V89.2a3.2,3.2,0,0,0,3.2,3.2H49a.8.8,0,0,0,.8-.8V86A.8.8,0,0,0,49,85.2Z" />
          <rect x="28.8" y="25.6" width="40" height="6.4" rx="0.8" />
          <rect x="28.8" y="40" width="20" height="6.4" rx="0.8" />
        </g>
        <path d="M91.2,55.8a.86.86,0,0,0-.86-.86H61.66a.86.86,0,0,0-.86.86V92.12c0,2.34,2.59,2.67,4.25,1.39l11-9.32a.06.06,0,0,1,.08,0l11.11,9.32a2.51,2.51,0,0,0,2.69.17,2.68,2.68,0,0,0,1.3-2.37ZM83.5,80.43l-7.4-6.2a.06.06,0,0,0-.08,0L68.5,80.62a.06.06,0,0,1-.1-.05v-18a.06.06,0,0,1,.06-.06H83.54a.06.06,0,0,1,.06.06V80.38C83.6,80.44,83.54,80.47,83.5,80.43Z" />
      </svg>
    )}
    {...props}
  />
);

export const FvIconMailView = (props: Partial<CustomIconComponentProps>) => (
  <Icon
    component={() => (
      <svg {...SvgDefaults}>
        <g>
          <path d="M90.78,74.31h0C85.67,63.51,77.77,58,67.27,58s-18.4,5.56-23.51,16.33h0a5,5,0,0,0,0,4.21c5.11,10.77,13,16.32,23.51,16.32s18.4-5.56,23.5-16.33A4.92,4.92,0,0,0,90.78,74.31ZM67.27,88c-7.55,0-13.06-3.65-17.16-11.62,4.08-8,9.61-11.61,17.16-11.61s13.06,3.65,17.16,11.61C80.35,84.36,74.82,88,67.27,88Z" />
          <circle
            cx="67.32"
            cy="76.44"
            r="5.24"
            transform="translate(-34.34 69.99) rotate(-45)"
          />
        </g>
        <path d="M35.55,82.63a.8.8,0,0,0,.8-.8V77a.8.8,0,0,0-.8-.8H17.63V29.66h0L48.12,53.37a5,5,0,0,0,6.13,0L84.77,29.66V56.32a.8.8,0,0,0,.8.8H90.4a.8.8,0,0,0,.8-.8V22.63a2.86,2.86,0,0,0-2.86-2.86H14.06a2.86,2.86,0,0,0-2.86,2.86V79.77a2.86,2.86,0,0,0,2.86,2.86ZM51.2,48.52,22.5,26.21H79.9Z" />
      </svg>
    )}
    {...props}
  />
);

export const FvIconUserAssignmentView = (
  props: Partial<CustomIconComponentProps>
) => (
  <Icon
    component={() => (
      <svg {...SvgDefaults}>
        <g>
          <path d="M87.21,89.65a37.49,37.49,0,0,0-11-25.77,37.73,37.73,0,0,0-12-8.06l-.12,0a24.79,24.79,0,1,0-29,0l-.12,0a37.12,37.12,0,0,0-4.62,2.35c-8.81,1.1-15.55,6.54-20.09,16.11h0a4.93,4.93,0,0,0,0,4.21c5.11,10.77,13,16.32,23.5,16.32s18.4-5.56,23.51-16.33a5,5,0,0,0,0-4.19h0C54.14,67.7,50,63.08,44.81,60.47h4.12A30.91,30.91,0,0,1,70.87,69.3a29.85,29.85,0,0,1,8.74,20.39.79.79,0,0,0,.8.78h6a.8.8,0,0,0,.8-.8ZM50.93,76.39C46.84,84.36,41.32,88,33.76,88S20.7,84.36,16.6,76.39c4.09-8,9.61-11.61,17.16-11.61S46.83,68.43,50.93,76.39ZM49.61,52.87a17.21,17.21,0,1,1,12.16-5A17.09,17.09,0,0,1,49.61,52.87Z" />
          <path d="M28.57,76.44a5.24,5.24,0,1,0,5.24-5.24A5.24,5.24,0,0,0,28.57,76.44Z" />
        </g>
      </svg>
    )}
    {...props}
  />
);

import {
  AppstoreOutlined,
  BookOutlined,
  FileOutlined,
  FilePdfOutlined,
  FolderOutlined,
  GroupOutlined,
  MailOutlined,
  TeamOutlined,
  UsergroupAddOutlined
} from '@ant-design/icons';
import React from 'react';

interface IWidgetTypeIconProps {
  type?: string;
}

export function WidgetTypeIcon(props: IWidgetTypeIconProps) {
  switch (props.type) {
    case 'folder':
      return <FolderOutlined />;
    case 'mail':
      return <MailOutlined />;
    case 'group':
      return <TeamOutlined />;
    case 'bookmark':
      return <BookOutlined />;
    case 'dam':
      return <AppstoreOutlined />;
    default:
      return <FileOutlined />;
  }
}

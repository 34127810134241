import {
  getGetWidgetsQueryKey,
  useDeleteWidgetsId,
  usePostWidgets,
  usePostWidgetsIdCopy,
  usePutWidgets
} from 'client/api/backend/widgets/widgets';
import { Widget } from 'client/api/backend/schemas';
import { omit } from 'lodash';
import { useCallback } from 'react';
import { queryClient } from 'client/core/network/queryClient';
import { WidgetMarshaller } from './WidgetMarshaller';
import { WidgetProfile } from '../schema/WidgetProfile';
import { logger } from 'client/core/logger/logger';

/**
 * Utilizziamo un wrapper della mutation di aggiornamento e/o creazione del
 * singolo Widget per gestire la conversione in stringa del JSON di configurazione.
 */
export function useWidgetMutations() {
  const WidgetsQueryKey = getGetWidgetsQueryKey();

  /**
   * Aggiornamento ottimistico.
   */
  const updateMutation = usePutWidgets({
    mutation: {
      onMutate: async ({ data: widget }) => {
        // Annulliamo eventuali caricamenti in corso dei widget
        await queryClient.cancelQueries(WidgetsQueryKey);

        // Salviamo nel `context` grazie al return di questa funzione i Widget
        // prima dell'eliminazione
        const previousWidgets =
          queryClient.getQueryData<Widget[]>(WidgetsQueryKey);

        // A questo punto "modifichiamo" a mano il risultato della query,
        // sostituendo il Widget selezionato dalla cache di react-query
        queryClient.setQueryData(
          WidgetsQueryKey,
          (widgets: Widget[] | undefined) =>
            widgets?.map(w => (w.id === widget.id ? widget : w)) ?? []
        );

        return { previousWidgets };
      },
      onError: (error, variables, context: any) => {
        // In caso ci sia stato un errore, prima ripristiniamo i widget completi
        // di quello eliminato..
        queryClient.setQueryData(WidgetsQueryKey, context.previousWidgets);
        // ..e dopo ricarichiamo dal backend
        queryClient.invalidateQueries(WidgetsQueryKey);
      }
      // onSettled: () => {
      //   // ..e dopo ricarichiamo dal backend
      //   queryClient.invalidateQueries(WidgetsQueryKey);
      // }
    }
  });
  const update = useCallback(
    async (data: Widget) => {
      return await updateMutation.mutateAsync({
        data: WidgetMarshaller.marshall(data)
      });
    },
    [updateMutation.mutateAsync]
  );

  /** Creazione */
  const createMutation = usePostWidgets({
    mutation: {
      onSuccess: (data, variables) => {
        if (typeof data !== 'object') {
          queryClient.invalidateQueries(WidgetsQueryKey);
          return;
        }

        queryClient.setQueryData(
          WidgetsQueryKey,
          (widgets: Widget[] | undefined) => [...(widgets ?? []), data]
        );
      }
    }
  });
  const create = useCallback(
    async (data: Widget) => {
      return await createMutation.mutateAsync({
        data: WidgetMarshaller.marshall(data)
      });
    },
    [createMutation.mutateAsync]
  );

  /**
   * Clona un Widget esistente mantenendo la configurazione a livello di token,
   * uffici, ecc.
   */
  const cloneMutation = usePostWidgetsIdCopy({
    mutation: {
      // In caso di successo, al posto di ricaricare i widget, semplicemente
      // inserieremo il nuovo widget nella lista. In caso di errore invece,
      // fetchiamo i widget completi dall'API
      onSuccess: (data, variables) => {
        if (typeof data !== 'object') {
          queryClient.invalidateQueries(WidgetsQueryKey);
          return;
        }

        // logger.log(`Ricevuto cloned widget:`, data);
        queryClient.setQueryData(
          WidgetsQueryKey,
          (widgets: Widget[] | undefined) => [...(widgets ?? []), data]
        );
      }
    }
  });
  const clone = useCallback(
    async (originalId: number, deskId: number, data: WidgetProfile) => {
      return await cloneMutation.mutateAsync({
        id: originalId,
        data: { panelId: deskId, settings: JSON.stringify(data) }
      });
    },
    []
  );

  /**
   * Eliminazione.
   * Per velocizzare la UX, eliminiamo prima il componente in locale (update
   * ottimistico), e solo successivamente inviamo la richiesta alle API. In caso
   * qualcosa vada storto vengono 1) ripristinati i widget tramite la `onError`
   * 2) ricaricati da backend per garantire l'allineamento con la `onSettled`
   */
  const removeMutation = useDeleteWidgetsId({
    mutation: {
      onMutate: async variables => {
        // Annulliamo eventuali caricamenti in corso dei widget
        await queryClient.cancelQueries(WidgetsQueryKey);

        // Salviamo nel `context` grazie al return di questa funzione i Widget
        // prima dell'eliminazione
        const previousWidgets =
          queryClient.getQueryData<Widget[]>(WidgetsQueryKey);

        // A questo punto "modifichiamo" a mano il risultato della query,
        // eliminando il Widget selezionato dalla cache di react-query
        queryClient.setQueryData(
          WidgetsQueryKey,
          (widgets: Widget[] | undefined) =>
            widgets?.filter(w => w.id !== variables.id) ?? []
        );

        return { previousWidgets };
      },
      onError: (error, variables, context: any) => {
        // In caso ci sia stato un errore, prima ripristiniamo i widget completi
        // di quello eliminato..
        queryClient.setQueryData(WidgetsQueryKey, context.previousWidgets);
        // ..e dopo ricarichiamo dal backend
        queryClient.invalidateQueries(WidgetsQueryKey);
      }
      // onSettled: () => {}
    }
  });
  const remove = useCallback(
    async (data: Widget) => {
      await removeMutation.mutateAsync({ id: data.id! });
    },
    [removeMutation.mutateAsync]
  );

  return {
    update,
    create,
    clone,
    remove,
    removeState: removeMutation
  };
}

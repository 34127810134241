import { Panel, Widget } from 'client/api/backend/schemas';
import { cloneDeep, max, maxBy, sortBy } from 'lodash';
import { Layout } from 'react-grid-layout';
import { WidgetPositionHandler } from '../../widget/logic/WidgetPositionHandler';
import {
  WidgetPosition,
  WidgetPositionFloating,
  WidgetPositionGrid
} from '../../widget/schema/WidgetPosition';
import { GridConstants } from '../space/grid/GridConstants';

export class DeskWidgetsLogic {
  static getGridWidgets(widgets: Widget[]) {
    return widgets
      .filter(widget => {
        return widget.profile?.position?.type === 'grid';
      })
      .sort((a, b) => a.id! - b.id!);
  }

  static getFloatingWidgets(widgets: Widget[]) {
    return sortBy(
      widgets.filter(w => w.profile?.position?.type === 'floating'),
      w => WidgetPositionHandler.floatingPosition(w).order ?? w.id
    );
  }

  static getFreeSlots(gridWidgets: Widget[] | undefined) {
    if (!gridWidgets) return [];

    const size = {
      width: GridConstants.WidgetDefaultWidth,
      height: GridConstants.WidgetDefaultHeight
    };

    const scale = {
      x: GridConstants.Cols / size.width,
      y: GridConstants.Rows / size.height
    };

    const spaces = Array(scale.x)
      .fill(null)
      .map(() => Array(scale.y).fill(0));

    const occupied = gridWidgets.map(WidgetPositionHandler.gridPosition);

    let freeSlotWidgets: WidgetPositionGrid[] = [];
    for (let y = 0; y < spaces[0].length; y++) {
      for (let x = 0; x < spaces.length; x++) {
        if (
          !occupied.some(
            w =>
              ((w.x <= x * size.width && w.x + w.w > x * size.width) ||
                (w.x < (x + 1) * size.width &&
                  w.x + w.w >= (x + 1) * size.width)) &&
              ((w.y <= y * size.height && w.y + w.h > y * size.height) ||
                (w.y < (y + 1) * size.height &&
                  w.y + w.h >= (y + 1) * size.height))
          )
        ) {
          console.log(`Found free slot at x:${x},y:${y}`, occupied.map(o => `x:${o.x},y:${o.y}`)); // prettier-ignore
          freeSlotWidgets.push({
            type: 'grid',
            placeholder: true,
            x: x * size.width,
            y: y * size.height,
            w: size.width,
            h: size.height,
            minW: GridConstants.WidgetMinWidth,
            minH: GridConstants.WidgetMinHeight
          });
          return freeSlotWidgets;
        }
      }
    }

    // Se non c'è una posizione libera, nessun +.
    return [];
  }

  static getFreeSlotsLayout(deskId: number, gridWidgets: Widget[] | undefined) {
    return DeskWidgetsLogic.getFreeSlots(gridWidgets).map(
      slot =>
        ({
          ...slot,
          i: `placeholder-add-${deskId}`,
          isResizable: false,
          isDraggable: false
        } as Layout)
    );
  }

  static getFloatingNextOrder(widgets: Widget[]) {
    const positions = widgets
      .filter(w => w.profile?.position?.type === 'floating')
      .map(WidgetPositionHandler.floatingPosition);

    return (max(positions.map(p => p.order ?? -1)) ?? -1) + 1;
  }
}

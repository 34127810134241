/**
 * Generato automaticamente.
 * DeskUI REST API
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query';
import type {
  DeskAssignment,
  GetDocumentaryAssignmentsParams,
  OptionItem,
  GetDocumentaryAssignmentsAssignorsParams,
  OptionItemNested,
  GetDocumentaryAssignmentsFlowsParams,
  GetDocumentaryAssignmentsMassiveAcceptanceParams,
  GetDocumentaryAssignmentsMassiveConcludeParams,
  AssignmentMassiveToModelRequestDTO,
  AssignmentSingleRequestDTO,
  DeskKeyValue,
  GetDocumentaryAssignmentsMonitoringParams,
  GetDocumentaryAssignmentsMonitoringDetailsParams,
  AssignmentMultipleRequestDTO,
  GetDocumentaryAssignmentsPrioritiesParams,
  DeskCheckAssignment,
  GetDocumentaryAssignmentsSingleCheckParams,
  GetDocumentaryAssignmentsIdParams,
  GetDocumentaryAssignmentsIdConcludeParams,
  DeleteDocumentaryAssignmentsIdDeleteParams,
  DeskEvent,
  GetDocumentaryAssignmentsIdEventsParams,
  GetDocumentaryAssignmentsIdReturnParams
} from '.././schemas';
import {
  apiInstance,
  ErrorType
} from '../../../core/network/mutators/apiClient';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Ritorna la lista delle assegnazioni secondo i filtri richiesti
 */
export const getDocumentaryAssignments = (
  params?: GetDocumentaryAssignmentsParams
) => {
  return apiInstance<DeskAssignment[]>({
    url: `/api/v1/documentary/assignments`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryAssignmentsQueryKey = (
  params?: GetDocumentaryAssignmentsParams
) => [`/api/v1/documentary/assignments`, ...(params ? [params] : [])];

export type GetDocumentaryAssignmentsQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryAssignments>
>;
export type GetDocumentaryAssignmentsQueryError = ErrorType<void>;

export const useGetDocumentaryAssignments = <
  TData = AsyncReturnType<typeof getDocumentaryAssignments>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryAssignmentsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryAssignments>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryAssignmentsQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryAssignments>
  > = () => getDocumentaryAssignments(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryAssignments>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna la lista degli assegnatari
 */
export const getDocumentaryAssignmentsAssignors = (
  params?: GetDocumentaryAssignmentsAssignorsParams
) => {
  return apiInstance<OptionItem[]>({
    url: `/api/v1/documentary/assignments/assignors`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryAssignmentsAssignorsQueryKey = (
  params?: GetDocumentaryAssignmentsAssignorsParams
) => [`/api/v1/documentary/assignments/assignors`, ...(params ? [params] : [])];

export type GetDocumentaryAssignmentsAssignorsQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryAssignmentsAssignors>
>;
export type GetDocumentaryAssignmentsAssignorsQueryError = ErrorType<void>;

export const useGetDocumentaryAssignmentsAssignors = <
  TData = AsyncReturnType<typeof getDocumentaryAssignmentsAssignors>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryAssignmentsAssignorsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryAssignmentsAssignors>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryAssignmentsAssignorsQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryAssignmentsAssignors>
  > = () => getDocumentaryAssignmentsAssignors(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryAssignmentsAssignors>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Lista dei valori di priorità
 */
export const getDocumentaryAssignmentsFlows = (
  params?: GetDocumentaryAssignmentsFlowsParams
) => {
  return apiInstance<OptionItemNested[]>({
    url: `/api/v1/documentary/assignments/flows`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryAssignmentsFlowsQueryKey = (
  params?: GetDocumentaryAssignmentsFlowsParams
) => [`/api/v1/documentary/assignments/flows`, ...(params ? [params] : [])];

export type GetDocumentaryAssignmentsFlowsQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryAssignmentsFlows>
>;
export type GetDocumentaryAssignmentsFlowsQueryError = ErrorType<void>;

export const useGetDocumentaryAssignmentsFlows = <
  TData = AsyncReturnType<typeof getDocumentaryAssignmentsFlows>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryAssignmentsFlowsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryAssignmentsFlows>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetDocumentaryAssignmentsFlowsQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryAssignmentsFlows>
  > = () => getDocumentaryAssignmentsFlows(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryAssignmentsFlows>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Presa in carico di N assegnazioni
 */
export const getDocumentaryAssignmentsMassiveAcceptance = (
  params?: GetDocumentaryAssignmentsMassiveAcceptanceParams
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/assignments/massive/acceptance`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryAssignmentsMassiveAcceptanceQueryKey = (
  params?: GetDocumentaryAssignmentsMassiveAcceptanceParams
) => [
  `/api/v1/documentary/assignments/massive/acceptance`,
  ...(params ? [params] : [])
];

export type GetDocumentaryAssignmentsMassiveAcceptanceQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryAssignmentsMassiveAcceptance>
>;
export type GetDocumentaryAssignmentsMassiveAcceptanceQueryError =
  ErrorType<unknown>;

export const useGetDocumentaryAssignmentsMassiveAcceptance = <
  TData = AsyncReturnType<typeof getDocumentaryAssignmentsMassiveAcceptance>,
  TError = ErrorType<unknown>
>(
  params?: GetDocumentaryAssignmentsMassiveAcceptanceParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryAssignmentsMassiveAcceptance>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryAssignmentsMassiveAcceptanceQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryAssignmentsMassiveAcceptance>
  > = () => getDocumentaryAssignmentsMassiveAcceptance(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryAssignmentsMassiveAcceptance>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Conclusione di N assegnazioni
 */
export const getDocumentaryAssignmentsMassiveConclude = (
  params?: GetDocumentaryAssignmentsMassiveConcludeParams
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/assignments/massive/conclude`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryAssignmentsMassiveConcludeQueryKey = (
  params?: GetDocumentaryAssignmentsMassiveConcludeParams
) => [
  `/api/v1/documentary/assignments/massive/conclude`,
  ...(params ? [params] : [])
];

export type GetDocumentaryAssignmentsMassiveConcludeQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryAssignmentsMassiveConclude>
>;
export type GetDocumentaryAssignmentsMassiveConcludeQueryError =
  ErrorType<unknown>;

export const useGetDocumentaryAssignmentsMassiveConclude = <
  TData = AsyncReturnType<typeof getDocumentaryAssignmentsMassiveConclude>,
  TError = ErrorType<unknown>
>(
  params?: GetDocumentaryAssignmentsMassiveConcludeParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryAssignmentsMassiveConclude>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryAssignmentsMassiveConcludeQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryAssignmentsMassiveConclude>
  > = () => getDocumentaryAssignmentsMassiveConclude(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryAssignmentsMassiveConclude>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Assegnazione massiva a modello
 */
export const postDocumentaryAssignmentsMassiveModel = (
  assignmentMassiveToModelRequestDTO: AssignmentMassiveToModelRequestDTO
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/assignments/massive/model`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: assignmentMassiveToModelRequestDTO
  });
};

export type PostDocumentaryAssignmentsMassiveModelMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryAssignmentsMassiveModel>
>;
export type PostDocumentaryAssignmentsMassiveModelMutationBody =
  AssignmentMassiveToModelRequestDTO;
export type PostDocumentaryAssignmentsMassiveModelMutationError =
  ErrorType<unknown>;

export const usePostDocumentaryAssignmentsMassiveModel = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryAssignmentsMassiveModel>,
    TError,
    { data: AssignmentMassiveToModelRequestDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryAssignmentsMassiveModel>,
    { data: AssignmentMassiveToModelRequestDTO }
  > = props => {
    const { data } = props || {};

    return postDocumentaryAssignmentsMassiveModel(data);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryAssignmentsMassiveModel>,
    TError,
    { data: AssignmentMassiveToModelRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Assegnazione massiva a singolo elemento
 */
export const postDocumentaryAssignmentsMassiveSingle = (
  assignmentSingleRequestDTO: AssignmentSingleRequestDTO[]
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/assignments/massive/single`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: assignmentSingleRequestDTO
  });
};

export type PostDocumentaryAssignmentsMassiveSingleMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryAssignmentsMassiveSingle>
>;
export type PostDocumentaryAssignmentsMassiveSingleMutationBody =
  AssignmentSingleRequestDTO[];
export type PostDocumentaryAssignmentsMassiveSingleMutationError =
  ErrorType<unknown>;

export const usePostDocumentaryAssignmentsMassiveSingle = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryAssignmentsMassiveSingle>,
    TError,
    { data: AssignmentSingleRequestDTO[] },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryAssignmentsMassiveSingle>,
    { data: AssignmentSingleRequestDTO[] }
  > = props => {
    const { data } = props || {};

    return postDocumentaryAssignmentsMassiveSingle(data);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryAssignmentsMassiveSingle>,
    TError,
    { data: AssignmentSingleRequestDTO[] },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Monitoraggio assegnazioni
 */
export const getDocumentaryAssignmentsMonitoring = (
  params?: GetDocumentaryAssignmentsMonitoringParams
) => {
  return apiInstance<DeskKeyValue[]>({
    url: `/api/v1/documentary/assignments/monitoring`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryAssignmentsMonitoringQueryKey = (
  params?: GetDocumentaryAssignmentsMonitoringParams
) => [
  `/api/v1/documentary/assignments/monitoring`,
  ...(params ? [params] : [])
];

export type GetDocumentaryAssignmentsMonitoringQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryAssignmentsMonitoring>
>;
export type GetDocumentaryAssignmentsMonitoringQueryError = ErrorType<void>;

export const useGetDocumentaryAssignmentsMonitoring = <
  TData = AsyncReturnType<typeof getDocumentaryAssignmentsMonitoring>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryAssignmentsMonitoringParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryAssignmentsMonitoring>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryAssignmentsMonitoringQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryAssignmentsMonitoring>
  > = () => getDocumentaryAssignmentsMonitoring(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryAssignmentsMonitoring>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Dettaglio monitoraggio assegnazioni
 */
export const getDocumentaryAssignmentsMonitoringDetails = (
  params?: GetDocumentaryAssignmentsMonitoringDetailsParams
) => {
  return apiInstance<DeskAssignment[]>({
    url: `/api/v1/documentary/assignments/monitoring/details`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryAssignmentsMonitoringDetailsQueryKey = (
  params?: GetDocumentaryAssignmentsMonitoringDetailsParams
) => [
  `/api/v1/documentary/assignments/monitoring/details`,
  ...(params ? [params] : [])
];

export type GetDocumentaryAssignmentsMonitoringDetailsQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryAssignmentsMonitoringDetails>
>;
export type GetDocumentaryAssignmentsMonitoringDetailsQueryError =
  ErrorType<void>;

export const useGetDocumentaryAssignmentsMonitoringDetails = <
  TData = AsyncReturnType<typeof getDocumentaryAssignmentsMonitoringDetails>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryAssignmentsMonitoringDetailsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryAssignmentsMonitoringDetails>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryAssignmentsMonitoringDetailsQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryAssignmentsMonitoringDetails>
  > = () => getDocumentaryAssignmentsMonitoringDetails(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryAssignmentsMonitoringDetails>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Assegnazione multipla
 */
export const postDocumentaryAssignmentsMultiple = (
  assignmentMultipleRequestDTO: AssignmentMultipleRequestDTO
) => {
  return apiInstance<DeskAssignment[] | void>({
    url: `/api/v1/documentary/assignments/multiple`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: assignmentMultipleRequestDTO
  });
};

export type PostDocumentaryAssignmentsMultipleMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryAssignmentsMultiple>
>;
export type PostDocumentaryAssignmentsMultipleMutationBody =
  AssignmentMultipleRequestDTO;
export type PostDocumentaryAssignmentsMultipleMutationError =
  ErrorType<unknown>;

export const usePostDocumentaryAssignmentsMultiple = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryAssignmentsMultiple>,
    TError,
    { data: AssignmentMultipleRequestDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryAssignmentsMultiple>,
    { data: AssignmentMultipleRequestDTO }
  > = props => {
    const { data } = props || {};

    return postDocumentaryAssignmentsMultiple(data);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryAssignmentsMultiple>,
    TError,
    { data: AssignmentMultipleRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Lista dei valori di priorità
 */
export const getDocumentaryAssignmentsPriorities = (
  params?: GetDocumentaryAssignmentsPrioritiesParams
) => {
  return apiInstance<OptionItem[]>({
    url: `/api/v1/documentary/assignments/priorities`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryAssignmentsPrioritiesQueryKey = (
  params?: GetDocumentaryAssignmentsPrioritiesParams
) => [
  `/api/v1/documentary/assignments/priorities`,
  ...(params ? [params] : [])
];

export type GetDocumentaryAssignmentsPrioritiesQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryAssignmentsPriorities>
>;
export type GetDocumentaryAssignmentsPrioritiesQueryError = ErrorType<void>;

export const useGetDocumentaryAssignmentsPriorities = <
  TData = AsyncReturnType<typeof getDocumentaryAssignmentsPriorities>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryAssignmentsPrioritiesParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryAssignmentsPriorities>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryAssignmentsPrioritiesQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryAssignmentsPriorities>
  > = () => getDocumentaryAssignmentsPriorities(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryAssignmentsPriorities>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Assegnazione singola
 */
export const postDocumentaryAssignmentsSingle = (
  assignmentSingleRequestDTO: AssignmentSingleRequestDTO
) => {
  return apiInstance<DeskAssignment | void>({
    url: `/api/v1/documentary/assignments/single`,
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    data: assignmentSingleRequestDTO
  });
};

export type PostDocumentaryAssignmentsSingleMutationResult = NonNullable<
  AsyncReturnType<typeof postDocumentaryAssignmentsSingle>
>;
export type PostDocumentaryAssignmentsSingleMutationBody =
  AssignmentSingleRequestDTO;
export type PostDocumentaryAssignmentsSingleMutationError = ErrorType<unknown>;

export const usePostDocumentaryAssignmentsSingle = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof postDocumentaryAssignmentsSingle>,
    TError,
    { data: AssignmentSingleRequestDTO },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof postDocumentaryAssignmentsSingle>,
    { data: AssignmentSingleRequestDTO }
  > = props => {
    const { data } = props || {};

    return postDocumentaryAssignmentsSingle(data);
  };

  return useMutation<
    AsyncReturnType<typeof postDocumentaryAssignmentsSingle>,
    TError,
    { data: AssignmentSingleRequestDTO },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Verifica la fattibilità della richiesta di assegnazione
 */
export const getDocumentaryAssignmentsSingleCheck = (
  params?: GetDocumentaryAssignmentsSingleCheckParams
) => {
  return apiInstance<DeskCheckAssignment>({
    url: `/api/v1/documentary/assignments/single/check`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryAssignmentsSingleCheckQueryKey = (
  params?: GetDocumentaryAssignmentsSingleCheckParams
) => [
  `/api/v1/documentary/assignments/single/check`,
  ...(params ? [params] : [])
];

export type GetDocumentaryAssignmentsSingleCheckQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryAssignmentsSingleCheck>
>;
export type GetDocumentaryAssignmentsSingleCheckQueryError = ErrorType<void>;

export const useGetDocumentaryAssignmentsSingleCheck = <
  TData = AsyncReturnType<typeof getDocumentaryAssignmentsSingleCheck>,
  TError = ErrorType<void>
>(
  params?: GetDocumentaryAssignmentsSingleCheckParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryAssignmentsSingleCheck>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryAssignmentsSingleCheckQueryKey(params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryAssignmentsSingleCheck>
  > = () => getDocumentaryAssignmentsSingleCheck(params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryAssignmentsSingleCheck>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Recupera l'assegnazione identificata
 */
export const getDocumentaryAssignmentsId = (
  id: number,
  params?: GetDocumentaryAssignmentsIdParams
) => {
  return apiInstance<DeskAssignment>({
    url: `/api/v1/documentary/assignments/${id}`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryAssignmentsIdQueryKey = (
  id: number,
  params?: GetDocumentaryAssignmentsIdParams
) => [`/api/v1/documentary/assignments/${id}`, ...(params ? [params] : [])];

export type GetDocumentaryAssignmentsIdQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryAssignmentsId>
>;
export type GetDocumentaryAssignmentsIdQueryError = ErrorType<void>;

export const useGetDocumentaryAssignmentsId = <
  TData = AsyncReturnType<typeof getDocumentaryAssignmentsId>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetDocumentaryAssignmentsIdParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryAssignmentsId>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryAssignmentsIdQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryAssignmentsId>
  > = () => getDocumentaryAssignmentsId(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryAssignmentsId>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Conclude l'assegnazione identificata
 */
export const getDocumentaryAssignmentsIdConclude = (
  id: number,
  params?: GetDocumentaryAssignmentsIdConcludeParams
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/assignments/${id}/conclude`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryAssignmentsIdConcludeQueryKey = (
  id: number,
  params?: GetDocumentaryAssignmentsIdConcludeParams
) => [
  `/api/v1/documentary/assignments/${id}/conclude`,
  ...(params ? [params] : [])
];

export type GetDocumentaryAssignmentsIdConcludeQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryAssignmentsIdConclude>
>;
export type GetDocumentaryAssignmentsIdConcludeQueryError = ErrorType<unknown>;

export const useGetDocumentaryAssignmentsIdConclude = <
  TData = AsyncReturnType<typeof getDocumentaryAssignmentsIdConclude>,
  TError = ErrorType<unknown>
>(
  id: number,
  params?: GetDocumentaryAssignmentsIdConcludeParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryAssignmentsIdConclude>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryAssignmentsIdConcludeQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryAssignmentsIdConclude>
  > = () => getDocumentaryAssignmentsIdConclude(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryAssignmentsIdConclude>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Cancella un assegnazione
 */
export const deleteDocumentaryAssignmentsIdDelete = (
  id: number,
  params?: DeleteDocumentaryAssignmentsIdDeleteParams
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/assignments/${id}/delete`,
    method: 'delete',
    params
  });
};

export type DeleteDocumentaryAssignmentsIdDeleteMutationResult = NonNullable<
  AsyncReturnType<typeof deleteDocumentaryAssignmentsIdDelete>
>;

export type DeleteDocumentaryAssignmentsIdDeleteMutationError =
  ErrorType<unknown>;

export const useDeleteDocumentaryAssignmentsIdDelete = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<typeof deleteDocumentaryAssignmentsIdDelete>,
    TError,
    { id: number; params?: DeleteDocumentaryAssignmentsIdDeleteParams },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const mutationFn: MutationFunction<
    AsyncReturnType<typeof deleteDocumentaryAssignmentsIdDelete>,
    { id: number; params?: DeleteDocumentaryAssignmentsIdDeleteParams }
  > = props => {
    const { id, params } = props || {};

    return deleteDocumentaryAssignmentsIdDelete(id, params);
  };

  return useMutation<
    AsyncReturnType<typeof deleteDocumentaryAssignmentsIdDelete>,
    TError,
    { id: number; params?: DeleteDocumentaryAssignmentsIdDeleteParams },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary Eventi per assegnazione
 */
export const getDocumentaryAssignmentsIdEvents = (
  id: number,
  params?: GetDocumentaryAssignmentsIdEventsParams
) => {
  return apiInstance<DeskEvent[]>({
    url: `/api/v1/documentary/assignments/${id}/events`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryAssignmentsIdEventsQueryKey = (
  id: number,
  params?: GetDocumentaryAssignmentsIdEventsParams
) => [
  `/api/v1/documentary/assignments/${id}/events`,
  ...(params ? [params] : [])
];

export type GetDocumentaryAssignmentsIdEventsQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryAssignmentsIdEvents>
>;
export type GetDocumentaryAssignmentsIdEventsQueryError = ErrorType<void>;

export const useGetDocumentaryAssignmentsIdEvents = <
  TData = AsyncReturnType<typeof getDocumentaryAssignmentsIdEvents>,
  TError = ErrorType<void>
>(
  id: number,
  params?: GetDocumentaryAssignmentsIdEventsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryAssignmentsIdEvents>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryAssignmentsIdEventsQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryAssignmentsIdEvents>
  > = () => getDocumentaryAssignmentsIdEvents(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryAssignmentsIdEvents>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

/**
 * @summary Ritorna l'assegnazione identificata all'assegnante
 */
export const getDocumentaryAssignmentsIdReturn = (
  id: number,
  params?: GetDocumentaryAssignmentsIdReturnParams
) => {
  return apiInstance<void>({
    url: `/api/v1/documentary/assignments/${id}/return`,
    method: 'get',
    params
  });
};

export const getGetDocumentaryAssignmentsIdReturnQueryKey = (
  id: number,
  params?: GetDocumentaryAssignmentsIdReturnParams
) => [
  `/api/v1/documentary/assignments/${id}/return`,
  ...(params ? [params] : [])
];

export type GetDocumentaryAssignmentsIdReturnQueryResult = NonNullable<
  AsyncReturnType<typeof getDocumentaryAssignmentsIdReturn>
>;
export type GetDocumentaryAssignmentsIdReturnQueryError = ErrorType<unknown>;

export const useGetDocumentaryAssignmentsIdReturn = <
  TData = AsyncReturnType<typeof getDocumentaryAssignmentsIdReturn>,
  TError = ErrorType<unknown>
>(
  id: number,
  params?: GetDocumentaryAssignmentsIdReturnParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<typeof getDocumentaryAssignmentsIdReturn>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDocumentaryAssignmentsIdReturnQueryKey(id, params);

  const queryFn: QueryFunction<
    AsyncReturnType<typeof getDocumentaryAssignmentsIdReturn>
  > = () => getDocumentaryAssignmentsIdReturn(id, params);

  const query = useQuery<
    AsyncReturnType<typeof getDocumentaryAssignmentsIdReturn>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!id, ...queryOptions });

  return {
    queryKey,
    ...query
  };
};

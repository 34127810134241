import { ToolkitActionArgument } from 'client/components/toolkit/actions/ToolkitAction';
import { ActionObjectInput } from './isToolkitActionSupportedBy';

export function matchToolkitActionArgument(
  argument: ToolkitActionArgument,
  input: ActionObjectInput
) {
  const typeCodes = argument.type.map(t => t.code);

  if (Array.isArray(input) && !argument.isArray) return false;

  const objects = Array.isArray(input) ? input : [input];
  return objects.every(
    object =>
      typeCodes.includes(object.type) && (argument.allows?.(object) ?? true)
  );
}

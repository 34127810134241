import { DeskAssignment, DeskDossier } from 'client/api/backend/schemas';
import { IAssignmentMetadata } from 'client/components/toolkit/components/metadata/IAssignmentMetadata';
import {
  formatAssignmentEntityDescription,
  formatAssignmentEntityName
} from 'client/components/widget-repository/objects/assignments/formatAssignmentEntityDescription';
import { mapIdPriority } from 'client/components/widget-repository/objects/assignments/mapIdPriority';
import { mapIdState } from 'client/components/widget-repository/objects/assignments/mapIdState';
import { mapIdType } from 'client/components/widget-repository/objects/assignments/mapIdType';
import { convertDossierName } from 'client/components/widget-repository/objects/dossiers/formatDossierNames';
import moment from 'moment';

export function convertAssignmentToMetadatas(
  assignment: DeskAssignment,
  dossiers?: DeskDossier[]
): IAssignmentMetadata {
  const document = assignment.document;
  return {
    documentType: document?.mode,
    shelfmark: document?.shelfmark,
    protocolDate: moment(document?.dateProtocol).format('ll'),
    assignee: formatAssignmentEntityDescription(assignment.assegnee),
    assigneeOffice: assignment.assegnee?.officeDescription,
    assignor: formatAssignmentEntityDescription(assignment.assignor),
    assignorOffice: assignment.assignor?.officeDescription,
    date: moment(assignment.startDate).format('lll'),
    expiresAt: assignment.expireDate
      ? moment(assignment.expireDate).format('ll')
      : '',
    priority: mapIdPriority(assignment.idPriority?.toString()),
    state: mapIdState(assignment.idState?.toString()),
    notes: assignment.note,
    dossiers: dossiers?.map(dossier => {
      return {
        name: convertDossierName(dossier),
        dateOpen: moment(dossier.dateOpen).format('ll')
      };
    })
  };
}

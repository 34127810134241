import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { CheckOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { DeskActivity } from 'client/api/backend/schemas';
import { ActivityToolkitObject } from 'client/components/widget-repository/objects/activities/ActivityToolkitObject';
import { executeCompleteActivity } from './executeCompleteActivity';
import { yup } from 'common/validation/initYup';
import { InferType } from 'yup';
import { ActivityActionCategory } from 'client/components/widget-repository/objects/activities/ActivityActionCategories';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { ActivityCompletion } from 'client/components/widget-repository/objects/activities/ActivityCompletions';
import { ActivityCompletionsSelectInput } from 'client/components/widget-repository/objects/activities/ActivityCompletionsSelectInput';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { without } from 'lodash';

export const WidgetActionCompleteActivity = createToolkitAction({
  name: 'Completa Attività',
  code: 'complete-activity',
  icon: <CheckOutlined />,
  arguments: [
    {
      name: 'Attività da Completare',
      type: [ActivityToolkitObject],
      allows: (obj: ToolkitObject<DeskActivity>) => {
        if (obj.data.actionCategory === ActivityActionCategory.Firma) {
          return !!obj.data.signed;
        }

        if (obj.data.actionCategory === ActivityActionCategory.Parere) {
          return false;
        }

        return true;
      }
    }
  ],
  async execute(
    ctx: IToolkitActionContext,
    activityObject: ToolkitObject<DeskActivity>
  ) {
    const activity = ActivityToolkitObject.get(activityObject)!;

    const completions = without(
      activityObject.data.responses,
      ActivityCompletion.RichiestaModifiche
    );

    ctx.deskContext.actionMessages.prompt({
      ctx,
      args: [activityObject],
      action: WidgetActionCompleteActivity,
      confirmText: 'Completa',
      schema: CompleteActivitySchema,
      onConfirm,
      initialValues: {
        type: completions.length === 1 ? completions[0] : undefined
      },
      form: completions.length > 1 && (
        <>
          <FormFieldsContainer>
            <FormFieldItem
              size="large"
              component={ActivityCompletionsSelectInput}
              availableCompletions={completions}
              widgetId={activity.sourceWidgetId}
              code={activity.data.code!}
              label={null}
              name="type"
              placeholder="Completamento"
            />
          </FormFieldsContainer>
        </>
      )
    });
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: CompleteActivitySchemaType,
  activityObject: ToolkitObject<DeskActivity>
) {
  try {
    await executeCompleteActivity(activityObject, { type: values.type });

    message.success('Attività completata con successo');
  } catch (e: any) {
    message.error(
      getNetworkErrorMessage(e, 'Errore durante il completamento.')
    );
    console.error(e);
    return false;
  }
}

const CompleteActivitySchema = yup.object({
  type: yup.string().required()
});

type CompleteActivitySchemaType = InferType<typeof CompleteActivitySchema>;

import styled from 'styled-components';

export const ContentWrapper = styled.div`
  flex: 1 1 auto;
  min-height: 0;
  overflow-y: auto;
  padding: 0 24px;
  display: block;
  border-top: 1px solid ${props => props.theme.borderColorBase};
`;

import { UpSquareOutlined } from '@ant-design/icons';
import { Widget } from 'client/api/backend/schemas';
import { WidgetRepository } from 'client/components/widget-repository/WidgetRepository';
import React from 'react';

export class WidgetDisplayLogic {
  static getTitleProps(widget: Widget) {
    // TODO Gestire fallback statici
    const widgetType = WidgetRepository.find(widget.profile!.typeCode!);
    return {
      icon: widgetType?.icon,
      title:
        widget.profile?.title ??
        widgetType?.getTitle?.(widget) ??
        `${widgetType?.name ?? 'Widget non trovato'}`
      // `${widgetType?.name ?? 'Widget non trovato'} (${widget.id})`
    };
  }

  static isConfigured(widget: Widget) {
    return !!widget.isConfigured;
    // return widget.profile?.isConfigured || widget.token;
  }
}

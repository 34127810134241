import {
  getDocumentaryDossiersSubtypesId,
  useGetDocumentaryDossiersSubtypes,
  useGetDocumentaryDossiersSubtypesId
} from 'client/api/backend/documentary-dossiers/documentary-dossiers';
import {
  DeskDossier,
  DeskExtendedAttributeType
} from 'client/api/backend/schemas';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import {
  SelectInput,
  SelectInputProps
} from 'client/ui/form/input/SelectInput';
import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { AttributeTypesFormFieldItem } from './AttributeTypesFormFieldItem';

interface IDossierSubtypeMetadataFormInputProps
  extends Omit<SelectInputProps<any>, 'name'> {
  widgetId: number;
  dossierAttributeTypes: DeskExtendedAttributeType[];
  setDossierAttributeTypes: (values: DeskExtendedAttributeType[]) => void;
}

export function DossierSubtypeMetadataFormInput(
  props: IDossierSubtypeMetadataFormInputProps
) {
  const {
    widgetId,
    dossierAttributeTypes,
    setDossierAttributeTypes,
    ...otherProps
  } = props;

  const formik = useFormikContext<DeskDossier>();

  const result = useGetDocumentaryDossiersSubtypesId(
    formik.values.idSubtype!,
    { widgetId },
    { query: { enabled: formik.values.idSubtype != null } }
  );

  const attributes = result.data?.attributes ?? [];

  useEffect(() => {
    if (!isEqual(attributes, dossierAttributeTypes)) {
      setDossierAttributeTypes(attributes);
    }
  }, [attributes]);

  return (
    <FormFieldsContainer>
      {attributes.map((attribute, index) => {
        return (
          <AttributeTypesFormFieldItem
            key={index}
            metadata={attribute}
            index={index}
          />
        );
      })}
    </FormFieldsContainer>
  );
}

import * as React from 'react';
import { Metadata } from 'client/components/toolkit/components/metadata/Metadata';
import { DeskDocument } from 'client/api/backend/schemas';
import { convertDocumentToMetadatas } from '../convert/convertDocumentToMetadatas';
import { DocumentModeMetadata } from './DocumentModeMetadata';
import { FirstLineType } from 'client/ui/first-line/FirstLineType';
import { useGetDocumentaryDocumentsIdDossiers } from 'client/api/backend/documentary-documents/documentary-documents';
import { IndentedMetadataWrapper } from 'client/ui/indented-metadata/IndentedMetadataWrapper';

export interface DocumentMetadatasProps {
  widgetId: number;
  document?: DeskDocument;
}

/**
 * Contenuto del Tab Metadati con indicato il tipo di documento e i metadati
 */
export function DocumentMetadatas(props: DocumentMetadatasProps) {
  const { document } = props;

  if (!document) {
    return null;
  }

  const dossiersResult = useGetDocumentaryDocumentsIdDossiers(document.id!, {
    widgetId: props.widgetId
  });

  const metadata = convertDocumentToMetadatas(document, dossiersResult.data);

  return (
    <div>
      <FirstLineType>
        <DocumentModeMetadata documentType={metadata.documentType} />
      </FirstLineType>
      <Metadata
        label="Numero di Protocollo"
        value={metadata.data?.protocolNumber}
      />
      <Metadata
        label="Data di Protocollo"
        value={metadata.data?.protocolDate}
      />
      <Metadata label="Fascicoli" />
      <IndentedMetadataWrapper>
        {metadata.data?.dossiers?.map((dossier, index) => {
          return <Metadata key={index} label="" value={dossier} />;
        })}
      </IndentedMetadataWrapper>
      <Metadata label="Oggetto" value={metadata.data?.subject} />
      <Metadata label="Segnatura" value={metadata.data?.shelfmark} />
      <Metadata label="Corrispondenti" />
      <IndentedMetadataWrapper>
        {metadata.data?.correspondents?.map((correspondent, index) => {
          return <Metadata key={index} label="" value={correspondent} />;
        })}
      </IndentedMetadataWrapper>
      <Metadata label="Classificazione" />
      <IndentedMetadataWrapper>
        {metadata.data?.classifications?.map((classification, index) => {
          return <Metadata key={index} label="" value={classification} />;
        })}
      </IndentedMetadataWrapper>
      <Metadata label="Firme" />
      <IndentedMetadataWrapper>
        {metadata.data?.signers?.map((signer, index) => {
          return <Metadata key={index} label="" value={signer} />;
        })}
      </IndentedMetadataWrapper>
    </div>
  );
}

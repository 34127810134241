import * as React from 'react';
import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

const RIGHT_MENU_PORTAL = 'page-layout-header--right-menu-portal';

export interface IPageLayoutRightMenuProps {
  children: React.ReactNode;
}

/**
 * Permette di posizionare del contenuto aggiuntivo nella posizione
 * laterale del menu, mantenendo il `Context` di React anche se annidato
 * nell'albero del DOM.
 */
export function RightSideMenuPortal(props: IPageLayoutRightMenuProps) {
  const el = useRef(document.createElement('div'));

  useEffect(() => {
    const portal = document.getElementById(RIGHT_MENU_PORTAL)!;
    portal.appendChild(el.current);

    return () => {
      portal.removeChild(el.current);
    };
  }, [props.children]);

  return ReactDOM.createPortal(props.children, el.current);
}

export const RightSideMenuPortalContainer = () => (
  <div id={RIGHT_MENU_PORTAL} />
);

import {
  GetDocumentaryAssignmentsParams,
  GetDocumentaryDocumentsSearchParams,
  Widget
} from 'client/api/backend/schemas';
import { applyDynamicDateRange } from 'client/components/toolkit/components/filter/dynamic/DynamicDateRanges';
import { AssignmentType } from 'client/components/widget-repository/objects/assignments/mapIdType';

/**
 * Intefaccia specifica dei filtri "di default" impostati da questo widget
 */
export interface IWidgetAssignmentsFilterData
  extends Pick<
    GetDocumentaryAssignmentsParams,
    'subject' | 'assignmentTypeId' | 'assignmentStateId'
  > {
  dynamicStartDate: string;
  dynamicEndDate: string;
}

export function applyWidgetAssignmentsDefaultFilters(
  widget: Widget
): Partial<GetDocumentaryAssignmentsParams> {
  const filters = widget.profile
    ?.filters as IWidgetAssignmentsFilterData | null;
  if (!filters) return {};

  const { dynamicStartDate, dynamicEndDate, ...standardParams } = filters;
  return {
    ...standardParams,
    startDate: applyDynamicDateRange(dynamicStartDate)?.valueOf(),
    endDate: applyDynamicDateRange(dynamicEndDate)?.valueOf()
  };
}

import { PaperClipOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
import { DeskMail } from 'client/api/backend/schemas';
import React from 'react';

export function convertMailToAttachmentMenuItems(
  mail?: DeskMail
): MenuProps['items'] {
  const attachments: MenuProps['items'] = mail?.attachments?.map(
    (attachment, index) => ({
      key: attachment.elementName!,
      icon: <PaperClipOutlined />,
      label: attachment.elementName ?? `Allegato #${'attachment.id'}`
    })
  );

  if (attachments && attachments.length > 0) {
    return [...attachments];
  }
}

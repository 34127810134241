import React from 'react';
import { createToolkitAction } from 'client/components/toolkit/actions/ToolkitAction';
import { IToolkitActionContext } from 'client/components/toolkit/actions/ToolkitActionContext';
import { ToolkitObject } from 'client/components/toolkit/objects/ToolkitObject';
import { yup } from 'common/validation/initYup';
import { FormFieldsContainer } from 'client/ui/form/field/row/FormFieldsContainer';
import { FormFieldItem } from 'client/ui/form/field/FormFieldItem';
import { InferType } from 'yup';
import { message } from 'antd';
import { TextAreaInput } from 'client/ui/form/input/TextAreaInput';
import { FormOutlined } from '@ant-design/icons';
import { getNetworkErrorMessage } from 'client/core/network/errors/getNetworkErrorMessage';
import { MailToolkitObject } from 'client/components/widget-repository/objects/mails/MailToolkitObject';
import { DeskMail } from 'client/api/backend/schemas';
import {
  getGetDocumentaryMailsQueryKey,
  postDocumentaryMailsNote
} from 'client/api/backend/documentary-mails/documentary-mails';
import { queryClient } from 'client/core/network/queryClient';

export const WidgetActionAnnotateMail = createToolkitAction({
  name: 'Modifica note Email',
  code: 'annotate-mail',
  icon: <FormOutlined />,
  showInBar: false,
  arguments: [
    {
      name: 'Mail da annotare',
      type: [MailToolkitObject]
    }
  ],
  async execute(ctx: IToolkitActionContext, mailObject: ToolkitObject) {
    let mail: ToolkitObject<DeskMail> = MailToolkitObject.get(mailObject)!;

    const notes = mail.data.note;

    ctx.deskContext.actionMessages.prompt({
      ctx,
      action: WidgetActionAnnotateMail,
      args: [mail],
      confirmText: notes?.trim() ? 'Aggiorna note' : 'Aggiungi note',
      schema: AnnotateSchema,
      onConfirm,
      initialValues: { notes: notes },
      form: (
        <>
          <FormFieldsContainer>
            <FormFieldItem
              size="large"
              component={TextAreaInput}
              label={null}
              name="notes"
              placeholder="Note"
            />
          </FormFieldsContainer>
        </>
      )
    });
  }
});

async function onConfirm(
  ctx: IToolkitActionContext,
  values: AnnotateSchemaType,
  mail: ToolkitObject<DeskMail>
) {
  try {
    await annotateMail(values, mail);

    message.success('Note aggiornate con successo');
  } catch (e) {
    message.error(
      getNetworkErrorMessage(e, "Errore durante l'aggiornamento delle note")
    );
    console.error(e);
    return false;
  }
}

async function annotateMail(
  values: AnnotateSchemaType,
  mail: ToolkitObject<DeskMail>
) {
  await postDocumentaryMailsNote({
    idMail: mail.id as number,
    note: values.notes,
    widgetId: mail.sourceWidgetId
  });
  // Invalido la cache
  const queryKey = getGetDocumentaryMailsQueryKey()[0];

  queryClient.invalidateQueries({
    predicate: query => {
      return Boolean(
        query.queryKey &&
          Array.isArray(query.queryKey) &&
          query.queryKey[0] === queryKey
      );
    }
  });
}

const AnnotateSchema = yup.object({
  notes: yup.string().notRequired().label('Note')
});

type AnnotateSchemaType = InferType<typeof AnnotateSchema>;

import { ColumnProps } from 'antd/lib/table';
import { Input, Button, DatePicker, Col, Row, Space, Select } from 'antd';
import React, { Key } from 'react';
import CheckboxGroup, { CheckboxOptionType } from 'antd/lib/checkbox/Group';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { SelectOption } from 'client/ui/form/input/SelectInput';

const Container = styled.div`
  padding: 8px;
`;
const CheckboxGroupStyle = styled(CheckboxGroup)`
  width: 100%;
  display: block;
  margin-bottom: 8px;
`;
const DatePickerStyle = styled(DatePicker)`
  width: 188px;
  display: block;
`;
const DatePickerMarginStyle = styled(DatePickerStyle)`
  margin-bottom: 8px;
`;
const InputStyle = styled(Input)`
  width: 188px;
  display: block;
  margin-bottom: 8px;
`;
const ButtonHalfSpace = styled(Button)`
  width: 90px;
`;

export enum FilterInputType {
  Text = 'text',
  Date = 'date',
  CheckBox = 'checkBox',
  DateRange = 'dateRange',
  Number = 'number',
  Enum = 'enum'
}

export function createColumnFilter<T extends {}>(
  inputType: FilterInputType,
  params?: { options?: CheckboxOptionType[] }
): Partial<ColumnProps<T>> {
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <Container>
        <div>
          {inputType === FilterInputType.CheckBox && (
            <CheckboxGroupStyle
              options={params?.options}
              value={...selectedKeys!}
              onChange={e =>
                setSelectedKeys!(e ? e.map(v => v.toString()) : [])
              }
            />
          )}
          {inputType === FilterInputType.Date && (
            <DatePickerMarginStyle
              placeholder="DD/MM/YYYY"
              format="DD/MM/YYYY"
              value={
                selectedKeys!.length > 0
                  ? moment(selectedKeys![0], 'DD/MM/YYYY')
                  : null
              }
              onChange={e =>
                setSelectedKeys!(e ? [e.format('DD/MM/YYYY')] : [])
              }
            />
          )}
          {inputType === FilterInputType.Text && (
            <InputStyle
              placeholder="Cerca"
              value={selectedKeys![0]}
              onChange={e =>
                setSelectedKeys!(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => {
                confirm!({ closeDropdown: true });
              }}
            />
          )}
          {inputType === FilterInputType.Number && (
            <InputStyle
              type="number"
              placeholder="Cerca"
              value={selectedKeys![0]}
              onChange={e =>
                setSelectedKeys!(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => {
                confirm!({ closeDropdown: true });
              }}
            />
          )}
          {inputType === FilterInputType.DateRange && (
            <Space direction="vertical">
              <DatePickerStyle
                placeholder="Da: DD/MM/YYYY"
                format="DD/MM/YYYY"
                value={
                  selectedKeys[0] ? moment(selectedKeys[0], 'YYYY-MM-DD') : null
                }
                onChange={e => {
                  const newKeys: any[] = [...selectedKeys];
                  newKeys[0] = e?.format('YYYY-MM-DD') ?? null;
                  setSelectedKeys!(newKeys);
                }}
              />
              <DatePickerMarginStyle
                placeholder="A: DD/MM/YYYY"
                format="DD/MM/YYYY"
                value={
                  selectedKeys[1] ? moment(selectedKeys[1], 'YYYY-MM-DD') : null
                }
                onChange={e => {
                  const newKeys: any[] = [...selectedKeys];
                  newKeys[1] = e?.format('YYYY-MM-DD') ?? null;
                  setSelectedKeys!(newKeys);
                }}
              />
            </Space>
          )}
          {inputType === FilterInputType.Enum && (
            <Select
              placeholder="Cerca"
              value={selectedKeys![0]}
              onChange={e => setSelectedKeys!(e ? [e] : [])}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
              options={params?.options as SelectOption[]}
            />
          )}
        </div>
        <Space size={8}>
          <ButtonHalfSpace
            onClick={() => {
              clearFilters!();
              confirm();
            }}
            size="small"
          >
            Reset
          </ButtonHalfSpace>
          <ButtonHalfSpace
            type="primary"
            onClick={() => {
              confirm!({ closeDropdown: true });
            }}
            icon={<SearchOutlined />}
            size="small"
          >
            Cerca
          </ButtonHalfSpace>
        </Space>
      </Container>
    ),
    filterIcon: filtered => <SearchOutlined />
  };
}

export interface IDateRangeFilter {
  from?: Date | null;
  to?: Date | null;
}

/**
 * Preso l'array dal filtro di tipo DateRange, restituisce un oggetto con il
 * range di date richiesto.
 * @param array array risultante dal filtro di tipo DateRange su una column.
 * @returns un'oggetto con il range di date nel formato fornito
 */
export function getDateRange(
  array: any[] | undefined | null
): IDateRangeFilter {
  if (!array || array.length === 0) {
    return {};
  }

  return {
    from:
      array.length > 0 && array[0]
        ? moment(array[0], 'YYYY-MM-DD').startOf('day').toDate()
        : null,
    to:
      array.length > 1 && array[1]
        ? moment(array[1], 'YYYY-MM-DD').endOf('day').toDate()
        : null
  };
}

/**
 * Ritorna il valore del filtro richiesto
 * @key chiave del campo del filtro (dataIndex)
 */
export function getFilter(
  filters: Record<string, (boolean | React.Key)[] | null>,
  key: string
): boolean | Key | null {
  return filters[key] ? filters[key]![0] : null;
}
